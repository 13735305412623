import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "../../Dashboard/College1x1StudentDetails/ModalSt";
import Modal from "react-bootstrap/Modal";
import httpService from "../../../services/httpService";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'
import { green } from "@mui/material/colors";
// import ChatModal from "./ModalChat";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import ReScheduleConfirmation from "./ReScheduleConfirmationModal";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import dayjs from 'dayjs';
import './ModalSt.css'
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Color } from "../../../GlobalStyles";
import { get_specialCharReplace } from "../../../services/specialCharReplace";
import Switch from '@mui/material/Switch';
import ReactApexChart from 'react-apexcharts';
import { List, ListItem } from '@mui/material'
import { extractDateFromDateString, calculateEpochTimeInLocalTime, convertLocalDateTimeToEpoch } from "../../../services/specialCharReplace";
export default function ModalSt(props) {
    const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
    const service = new httpService();
    const scheduleService = new CCService()
    const [user_type_id, SetUser_type_id] = useState(null);
    const rowsData = useContext(RowsContext);
    const { show, setShow, headCells, mentor_missed, setMentor_missed_Request, from_table, path, internet_stats, subscribeseriesdatastudent, publishseriesdatastudent, subscribeseriesdata, publishseriesdata, studentsDetailsClick, setStudentsDetailsClick, seriesData, orderHistory, feedbackFields, studentFeedbackFields, mcqFeedbackFields, changedPairs, reconsiderRequest, chatMessages, specialization_role_slots_jd, resumeRequest, mentor_feedback_submitted_Request, mentor_joined_Request, mentor_join_time_Request, mark_absent_Request, student_feedback_submitted_Request, duration_name, student_join_time_Request, mentor_missed_Request, allItems, order_flag_changes, internetSpeedMentor, internetSpeedStudent, origin } = props;
    console.log("all items", specialization_role_slots_jd, resumeRequest, reconsiderRequest, mentor_feedback_submitted_Request, mentor_join_time_Request, mark_absent_Request, student_feedback_submitted_Request, student_join_time_Request, mentor_missed_Request, order_flag_changes);
    console.log("specialization_role_slots_jd", specialization_role_slots_jd, order_flag_changes);
    console.log("changed pairs in modal", reconsiderRequest.filter((item) => JSON.parse(item?.reconsider?.currentValue) !== null));
    // console.log("filtering reconsider", changedPairs?.filter((item) => item.reconsider));JSON.parse(item?.reconsider?.currentValue)
    // const reconsider_request = changedPairs?.filter((item) => item.reconsider)
    console.log("mentor_missed", mentor_missed);
    console.log("internet data available", internetSpeedMentor, internetSpeedStudent);
    console.log("chat messages", chatMessages);
    console.log("allItems in modal", allItems);
    console.log("mentor_missed_Request", mentor_missed_Request);
    console.log("student_feedback_submitted_Request", student_feedback_submitted_Request);
    console.log("mark_absent_Request", mark_absent_Request);
    // const filtered_data = reconsiderRequest.filter((item) => JSON.parse(item?.reconsider?.currentValue) !== null)
    const filtered_student_join_time_data = student_join_time_Request.filter((item) => JSON.parse(item?.student_join_time?.previousValue) == null)
    const [name, setName] = useState(rowsData[from_table?.index]?.name);
    const [special, setSpecial] = useState()
    const [jd, setJD] = useState()
    const [started_at_time, setStarted_at_time] = useState('')
    const [ended_at_time, setEnded_at_time] = useState('');
    const [errMsg, setErrMsg] = useState("")
    console.log("order history", orderHistory);
    console.log("resumeRequest", resumeRequest);
    console.log("mentor_feedback_submitted_Request", mentor_feedback_submitted_Request);
    console.log("mentor_join_time_Request", mentor_join_time_Request);
    console.log("mentor_joined_Request", mentor_joined_Request);
    console.log("just displaying", studentsDetailsClick?.order_details?.duration);
    console.log("just displaying student details", studentsDetailsClick, extractDateFromDateString(studentsDetailsClick?.order_details?.start_time));
    console.log("duration value updation", duration_name?.filter((item_s, index_s) => item_s.duration_name === studentsDetailsClick?.order_details?.duration)?.duration_value);
    const [duration_id, setDuration_id] = useState(duration_name?.filter((item_s, index_s) => item_s.duration_name === studentsDetailsClick?.order_details?.duration)?.duration_value)

    const [copyList, setCopyList] = useState(orderHistory)
    const [is_suspended_toggled, setIs_suspended_toggled] = useState(false)


    const modalHide = () => {
        setShow(!show);
        console.log(!show + "bruh --------");
        setName("")
        setSpecial("")
        setJD("")
        setFormInputs({})
        setErrMsg("")
        setDuration_id()
        setSelectDate(false)
        setSelectTime(false)
        setMentorDropDown(false)
        setMentor_missed_Request(false)
    };

    const [mentorDetails, setMentorDetails] = useState([])
    const [selectDate, setSelectDate] = useState(false)
    const [selectSuspended, setSelectSuspended] = useState(false)
    const [selectTime, setSelectTime] = useState(false)

    const [mentorDropDown, setMentorDropDown] = useState(false)
    const [mentor_name, setMentor_name] = useState()
    const [mentor_id, setMentor_id] = useState();
    const [formInputs, setFormInputs] = useState({ slot_date: extractDateFromDateString(studentsDetailsClick?.order_details?.start_time), start_time: studentsDetailsClick?.order_details?.start_time, end_time: studentsDetailsClick?.order_details?.end_time, mentor_id: studentsDetailsClick?.order_details?.mentor_id, order_flag: "Scheduled", ad_hoc: false })
    const mentorUserDetailsGetter = async () => {
        try {
            const res = await service.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
            console.log("mentorUserDetailsGetter", res.data)
            setMentorDetails([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const data_manipulation_collegeDetails = collegeDetails => {
        let temp_college = collegeDetails;
        console.log("tem data", temp_college);
        temp_college = temp_college.map(mentor => {
            mentor.first_name = mentor?.first_name;
            mentor.last_name = mentor?.last_name;
            mentor.id = mentor?.user_id;
            mentor.email_id = mentor?.email_id
            return mentor;
        });
        console.log("temp data", temp_college);
        setMentor_name(temp_college);
        console.log("college nameee", mentor_name);
    };
    console.log("mentor name", mentor_name);

    // const findChangedPairs = () => {
    //     const changedPairsArray = [];

    //     for (let i = 1; i < orderHistory.length; i++) {
    //       const previousObject = orderHistory[i - 1];
    //       const currentObject = orderHistory[i];
    //     if(previousObject.id === currentObject.id){
    //         for (const key in currentObject) {
    //             if (currentObject[key] !== previousObject[key]) {
    //               changedPairsArray.push({
    //                 key,
    //                 previousValue: previousObject[key],
    //                 currentValue: currentObject[key],
    //               });
    //             }
    //           }
    //         }
    //     }


    //     return changedPairsArray;
    //   };

    // const findChangedPairs = () => {
    //     const changedPairsArray = [];

    //     for (let i = 1; i < orderHistory.length; i++) {
    //       const previousObject = orderHistory[i - 1];
    //       const currentObject = orderHistory[i];

    //       // Check if the 'id' of the current object matches the 'id' of the previous object
    //       if (previousObject.id === currentObject.id) {
    //         for (const key in currentObject) {
    //           if (currentObject[key] !== previousObject[key]) {
    //             changedPairsArray.push({
    //               key,
    //               previousValue: previousObject[key],
    //               currentValue: currentObject[key],
    //             });
    //           }
    //         }
    //       }
    //     }

    //     return changedPairsArray;
    //   };

    // calculate data in kbps 



    console.log("seriesData", publishseriesdata, subscribeseriesdata);
    // const chartSeries = {
    //     series: [
    //         {
    //           type: 'line',
    //           name: 'Publish',
    //           data: publishSeriesData
    //         },
    //         {
    //           type: 'line',
    //           name: 'Subscribe',
    //           data: subscribeSeriesData
    //         }
    //       ]
    // }

    // const chartOptions = {
    //     chart: {
    //         height: 350,
    //         type: 'line',
    //         animations: {
    //             speed: 500
    //         },
    //         colors: ['#d4526e', '#33b2df', '#d4526e', '#33b2df'],
    //         dataLabels: {
    //             enabled: false
    //         },
    //         fill: {
    //             opacity: [0.24, 0.24, 1, 1]
    //         },
    //         forecastDataPoints: {
    //             count: 2
    //         },
    //         stroke: {
    //             curve: 'straight',
    //             width: [0, 0, 2, 2]
    //         },
    //         legend: {
    //             show: true,
    //             customLegendItems: ['Team B', 'Team A'],
    //             inverseOrder: true
    //         },
    //         title: {
    //             text: 'Range Area with Forecast Line (Combo)'
    //         },
    //         markers: {
    //             hover: {
    //                 sizeOffset: 5
    //             }
    //         }
    //     },
    //     // ... (Other options)
    // };

    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Publish',
                data: publishseriesdata,
            },
            {
                name: 'Subscribe',
                data: subscribeseriesdata,
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'line',
                toolbar: {
                    show: false,
                },
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            grid: {
                borderColor: 'transparent',
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 0.5,
                },
            },
            markers: {
                size: 1,
            },
            yaxis: {
                labels: {
                    show: false, // Hide y-axis scale labels
                },
                lines: {
                    show: false, // Hide x-axis lines
                },
            },
            xaxis: {
                categories: publishseriesdata,
                labels: {
                    show: false, // Hide y-axis scale labels
                },
                lines: {
                    show: false, // Hide x-axis lines
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });

    const [chartDataStudent, setChartDataStudent] = useState({
        series: [
            {
                name: 'Publish',
                data: publishseriesdatastudent,
            },
            {
                name: 'Subscribe',
                data: subscribeseriesdatastudent,
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',

                toolbar: {
                    show: false,
                },
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            grid: {
                borderColor: 'transparent',
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 0.5,
                },
            },
            markers: {
                size: 1,
            },
            yaxis: {
                labels: {
                    show: false, // Hide y-axis scale labels
                },
                lines: {
                    show: false, // Hide x-axis lines
                },
            },
            xaxis: {
                categories: publishseriesdatastudent,
                labels: {
                    show: false, // Hide y-axis scale labels
                },
                lines: {
                    show: false, // Hide x-axis lines
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });


    useEffect(() => {
        console.log("Brother")
        console.log("from table inside useEffect of modal input:", from_table)
        const handleEffect = async () => {
            let mentorDetailsDropdown = await mentorUserDetailsGetter()

            console.log("mentor dropdown", mentorDetailsDropdown);
            data_manipulation_collegeDetails(mentorDetailsDropdown);
            console.log("changed pair order history", orderHistory);

        }

        handleEffect();
    }, [])
    console.log("feedback fields...", feedbackFields);


    const get_data_from_form = (name) => {
        console.log("HandleEditUser ~ special", special)
        console.log("🚀HandleEditUser ~ name", name)
        console.log("🚀HandleEditUser ~ id", from_table?.id)
        // name.preventDefault();
        if (path === "/RoleType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim(), undefined, undefined, special.trim(), jd)
                setName("")
                setSpecial("")
                setJD("")
            }
            else {
                console.log("PUT")
                console.log("PUT - special value:", special)
                props.data_getter(name.trim(), from_table?.id, from_table?.index, special, jd)
            }
        }
        else if (path === "/UserType" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim())
                setName("")
            }
            else {
                console.log("PUT")
                props.data_getter(name.trim(), from_table?.id, from_table?.index)
            }
        }
        setShow(false)
    }



    console.log("rows data in modal input file", rowsData);
    console.log("spec_data", props.spec_data);
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs('2023-03-27T15:30'));

    console.log("value for end time", value);
    // function to convert time to milliseconds
    // if start time is 11:45 PM then calculate the milliseconds of 11:30 pm then milliseconds will be 84600000 , if start time is 12:00 am then the milliseconds will be 0 if start time is 11:30 pm and end time is 12:00 am then milliseconds should be 84600000 and 86400000  
    function startTimeToMilliseconds(start_time) {
        const timeRegex = /^(\d{1,2}):(\d{2})\s(am|pm)$/i;
        const matches = start_time.match(timeRegex);

        if (!matches) {
            throw new Error('Invalid time format. Please use "hh:mm AM/PM".');
        }

        let hours = parseInt(matches[1]);
        const minutes = parseInt(matches[2]);
        const isPM = matches[3].toLowerCase() === 'pm';

        if (hours === 12) {
            hours = isPM ? 12 : 0;
        } else {
            hours += isPM ? 12 : 0;
        }

        const milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000);
        return milliseconds;
    }

    // function endTimeToMilliseconds(start_time, end_time) {
    //     const timeRegex = /^(\d{1,2}):(\d{2})\s(am|pm)$/i;
    //     const startMatches = start_time.match(timeRegex);
    //     const endMatches = end_time.match(timeRegex);

    //     if (!startMatches || !endMatches) {
    //         throw new Error('Invalid time format. Please use "hh:mm AM/PM".');
    //     }

    //     let endHours = parseInt(endMatches[1]);
    //     const endMinutes = parseInt(endMatches[2]);
    //     const isEndPM = endMatches[3].toLowerCase() === 'pm';

    //     let startHours = parseInt(startMatches[1]);
    //     const startMinutes = parseInt(startMatches[2]);
    //     const isStartPM = startMatches[3].toLowerCase() === 'pm';

    //     if (endHours === 12) {
    //         endHours = 0; // Treat 12:00 AM as 0 hours
    //     } else {
    //         endHours += isEndPM ? 12 : 0;
    //     }
    //     if (isStartPM > 12 && !isEndPM) {
    //         endHours += 12; // Add 24 hours if start time is in PM and end time is on the next day
    //     }
    //     const endMilliseconds = (endHours * 60 * 60 * 1000) + (endMinutes * 60 * 1000);
    //     return endMilliseconds;
    // }

    function endTimeToMilliseconds(start_time, end_time) {
        console.log("time start", start_time);
        console.log("time start", end_time);
        const timeRegex = /^(\d{1,2}):(\d{2})\s(am|pm)$/i;
        const startMatches = start_time.match(timeRegex);
        const endMatches = end_time.match(timeRegex);

        if (!startMatches || !endMatches) {
            throw new Error('Invalid time format. Please use "hh:mm AM/PM".');
        }

        let endHours = parseInt(endMatches[1]);
        const endMinutes = parseInt(endMatches[2]);
        const isEndPM = endMatches[3].toLowerCase() === 'pm';

        let startHours = parseInt(startMatches[1]);
        const startMinutes = parseInt(startMatches[2]);
        const isStartPM = startMatches[3].toLowerCase() === 'pm';

        if (endHours === 12) {
            endHours = isEndPM ? 12 : 0; // Treat 12:00 AM as 0 hours
        } else {
            endHours += isEndPM ? 12 : 0;
        }

        // Adjust end time if it's on the next day and the start time is in PM
        if (isStartPM && !isEndPM) {
            endHours += 24; // Add 24 hours if start time is in PM and end time is on the next day
        }

        // if (isStartPM && endHours < startHours) {
        //     endHours += 24;
        // }

        const endMilliseconds = (endHours * 60 * 60 * 1000) + (endMinutes * 60 * 1000);
        return endMilliseconds;
    }




    const slotsDataJson = studentsDetailsClick?.order_details?.slots;
    const slotsData = slotsDataJson ? JSON.parse(slotsDataJson) : {};
    const slotNames = slotsData?.timing?.map(timing => timing) ?? [];
    console.log("slot names", slotNames);
    const resumeDataJson = studentsDetailsClick?.order_details?.resume
    const resumeData = resumeDataJson ? JSON.parse(resumeDataJson) : {};
    const resumeName = resumeData.name ?? " ";
    // const resumeClick = resumeData.url ?? " ";
    // console.log("slots selected", slotNames);

    const handleReschedule = () => {
        setSelectDate(true)
        setSelectTime(true)
        setMentorDropDown(true)
        setSelectSuspended(true)
        // setDuration_id(duration_name?.filter((item_s, index_s) => item_s.duration_name === studentsDetailsClick?.order_details?.duration)?.value)
        setFormInputs({ ...formInputs, slot_date: extractDateFromDateString(studentsDetailsClick?.order_details?.start_time), mentor_id: studentsDetailsClick?.order_details?.mentor_id, order_flag: studentsDetailsClick?.order_details?.order_flag, ad_hoc: false })
    }

    function calculateEndTime(startTimeString, durationMinutes) {
        console.log("calculating time", startTimeString, durationMinutes);
        const startTime = new Date(`01/01/2000 ${startTimeString}`);
        const endTime = new Date(startTime.getTime() + durationMinutes * 60000);
        const formattedEndTime = endTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
        return formattedEndTime;
    }
    // console.log();
    const handleSaveReschedule = () => {
        setSelectDate(false)
        setSelectTime(false)
        setMentorDropDown(false)
        setSelectSuspended(false)
        console.log("start time and emd time", started_at_time, ended_at_time);
        const endTimeString = calculateEndTime(started_at_time, duration_name.find((item_s) => item_s.duration_name === studentsDetailsClick?.order_details?.duration)?.duration_value);
        console.log('End Time:', endTimeString);
        console.log("what i'm sending", formInputs?.slot_date, started_at_time, endTimeString);
        const start_time_calculated = convertLocalDateTimeToEpoch(formInputs?.slot_date, started_at_time)
        const end_time_calculated = convertLocalDateTimeToEpoch(formInputs?.slot_date, endTimeString)
        console.log("milliseconds start and end", start_time_calculated, end_time_calculated);
        // setEnded_at_time(endTimeString)
        if (started_at_time) {
            if (studentsDetailsClick?.order_details?.order_flag !== "Initialized") {
                handlePutRequest(start_time_calculated, end_time_calculated, endTimeString)
            } else {
                handlePutRequestForInitialized(start_time_calculated, end_time_calculated, endTimeString)
            }
        }
    }

    const handleChange = (e, index) => {
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);
        if (e.target.name === "start_time") {
            setFormInputs({ ...formInputs, start_time: e.target.value })
            console.log("schedule start date", e.target.value)
        }
        else if (e.target.name === "end_time") {
            setFormInputs({ ...formInputs, end_time: e.target.value })
            console.log("schedule end date", e.target.value)
        }
        else if (e.target.name === "slot_date") {
            setFormInputs({ ...formInputs, slot_date: e.target.value })
            console.log("schedule end date", e.target.value)
        }
        else if (e.target.name === "mentor_id") {
            console.log("mentor id selected", e.target.value);
            setMentor_id(e.target.value);
            setFormInputs({ ...formInputs, mentor_id: e.target.value });
        } else if (e.target.name === "duration_name") {
            setDuration_id(e.target.value)
            console.log("duration selected id", e.target.value)
        }
    }

    console.log("duration_id", duration_id);
    // const endTime = new Date(start_time?.getTime() + duration_id * 60000);
    // console.log("end time calculated", endTime);

    const handlePutRequest = async (start_time_in_milliseconds, end_time_in_milliseconds, endTimeString) => {
        console.log("start time and end time in milliseconds", start_time_in_milliseconds, end_time_in_milliseconds)
        //get request here
        service
            .put(`/rescheduleByAdmin`, {
                "ad_hoc": formInputs.ad_hoc,
                "order_id": parseInt(studentsDetailsClick?.order_details?.order_id),
                "start_time": start_time_in_milliseconds,
                "end_time": end_time_in_milliseconds,
                "mentor_id": formInputs.mentor_id ? formInputs.mentor_id : studentsDetailsClick?.order_details?.mentor_id,
                "order_flag": studentsDetailsClick?.order_details?.order_flag,
            })
            .then((res) => {
                const updatedStudentDetails = { ...studentsDetailsClick };
                updatedStudentDetails.order_details.slot_date = formInputs.slot_date;
                updatedStudentDetails.order_details.start_time = start_time_in_milliseconds
                updatedStudentDetails.order_details.end_time = end_time_in_milliseconds
                // calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.start_time)} - {calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.end_time)
                const mentorToUpdate = mentor_name.find(mentor => mentor.id === formInputs.mentor_id);
                if (mentorToUpdate) {
                    updatedStudentDetails.mentor_details = {
                        first_name: mentorToUpdate?.first_name,
                        last_name: mentorToUpdate?.last_name,
                        email_id: mentorToUpdate?.email_id,
                        ph_no: mentorToUpdate?.ph_no
                    };
                }
                updatedStudentDetails.order_details.mentor_id = formInputs?.mentor_id;
                setStudentsDetailsClick(updatedStudentDetails);
                setErrMsg(res.data.mesage)
            })
            .catch((err) => {
                console.log("Errog Msg: ", err, err.response.data.mesage);
                setErrMsg(err.response.data.mesage)
                // window.alert(err.response.data.message)
            });
    }

    // handle schedule by admin during Initialized condition
    const handlePutRequestForInitialized = async (start_time_in_milliseconds, end_time_in_milliseconds, endTimeString) => {
        console.log("start time and end time in milliseconds", start_time_in_milliseconds, end_time_in_milliseconds)
        //get request here
        if (formInputs.mentor_id && start_time_in_milliseconds) {
            scheduleService
                .put(`/scheduling/byAdmin`, {
                    "order_id": parseInt(studentsDetailsClick?.order_details?.order_id),
                    "start_time": start_time_in_milliseconds,
                    "end_time": end_time_in_milliseconds,
                    "mentor_id": formInputs.mentor_id ? formInputs.mentor_id : studentsDetailsClick?.order_details?.mentor_id,
                    "order_flag": studentsDetailsClick?.order_details?.order_flag,
                })
                .then((res) => {
                    const updatedStudentDetails = { ...studentsDetailsClick };
                    updatedStudentDetails.order_details.slot_date = formInputs.slot_date;
                    updatedStudentDetails.order_details.start_time = start_time_in_milliseconds
                    updatedStudentDetails.order_details.end_time = end_time_in_milliseconds
                    // calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.start_time)} - {calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.end_time)
                    const mentorToUpdate = mentor_name.find(mentor => mentor.id === formInputs.mentor_id);
                    if (mentorToUpdate) {
                        updatedStudentDetails.mentor_details = {
                            first_name: mentorToUpdate?.first_name,
                            last_name: mentorToUpdate?.last_name,
                            email_id: mentorToUpdate?.email_id,
                            ph_no: mentorToUpdate?.ph_no
                        };
                    }
                    updatedStudentDetails.order_details.mentor_id = formInputs?.mentor_id;
                    setStudentsDetailsClick(updatedStudentDetails);
                    setErrMsg(res.data.mesage)
                })
                .catch((err) => {
                    console.log("Errog Msg: ", err, err.response.data.mesage);
                    setErrMsg(err.response.data.mesage)
                    // window.alert(err.response.data.message)
                });
        } else {
            window.alert("Make sure to choose Date, Time and Mentor and Try again.")
        }

    }

    function extractTimeFromDateString(dateString) {
        let date = new Date(dateString).toLocaleString("en-US", { timeZone: 'Asia/Kolkata' })
        const timeRegex = /(\d{1,2}:\d{2}:\d{2} [APap][Mm])/;

        const match = date.match(timeRegex);

        if (match) {
            const extractedTime = match[0];
            return extractedTime;
        } else {
            return "No time found in the given string.";
        }
    }

    // "join session" click time

    const joinTimeOfMentor = studentsDetailsClick?.dataAnalytics100MS?.vCMentorDetails[0];
    const joinTimeOfStudent = studentsDetailsClick?.dataAnalytics100MS?.vCStudentDetails[0];
    console.log("join mentor time ", joinTimeOfMentor);
    console.log("join student time", joinTimeOfStudent);

    const inputDateStringMentor = joinTimeOfMentor?.started_at;
    const inputDateStringStudent = joinTimeOfStudent?.started_at;
    const extractedTimeWithAmPmMentorJoined = extractTimeFromDateString(inputDateStringMentor);
    const extractedTimeWithAmPmStudentJoined = extractTimeFromDateString(inputDateStringStudent);

    console.log(`Extracted Time mentor: ${extractedTimeWithAmPmMentorJoined}`);
    console.log(`Extracted Time student: ${extractedTimeWithAmPmStudentJoined}`);


    // session details

    const mentorVCTimeRanges = studentsDetailsClick?.sessionDetails100MS?.mentorDetails
        .map(activity => {
            const startTime = extractTimeFromDateString(activity.joined_at)
            const endTime = extractTimeFromDateString(activity.left_at)
            return `${startTime} - ${endTime}`;
        })
        .join(', ');


    const studentVCTimeRanges = studentsDetailsClick?.sessionDetails100MS?.studentDetails
        .map(activity => {
            const startTime = extractTimeFromDateString(activity.joined_at)
            const endTime = extractTimeFromDateString(activity.left_at)
            return `${startTime} - ${endTime}`;
        })
        .join(', ');

    const hasOnOffTrueMentor = studentsDetailsClick?.dataAnalytics100MS?.vCMentorDetails.some(activity => activity.onOff);
    console.log("formattedTimeRanges mentor", mentorVCTimeRanges);
    console.log("formattedTimeRanges student", studentVCTimeRanges);
    console.log("hasOnOffTrue mentor", hasOnOffTrueMentor);

    // const hasOnOffTrueStudent = studentsDetailsClick?.dataAnalytics100MS?.vCStudentDetails.some(activity => activity.onOff);

    // camera on

    const studentCameraOnTimeRanges = studentsDetailsClick?.dataAnalytics100MS?.vCStudentDetails
        .filter(activity => activity.trackType !== "track.remove.success")
        .map(activity => {
            const startTime = extractTimeFromDateString(activity.started_at)
            const endTime = extractTimeFromDateString(activity.stopped_at)
            const color = activity.onOff ? 'red' : 'green';
            return (
                <span key={activity.started_at} style={{ color: color, }}>
                    ({startTime} - {endTime}) {' '}
                </span>
            );
        })




    const mentorCameraOnTimeRanges = studentsDetailsClick?.dataAnalytics100MS?.vCMentorDetails
        .filter(activity => activity.trackType !== "track.remove.success")
        .map(activity => {
            const startTime = extractTimeFromDateString(activity.started_at)
            const endTime = extractTimeFromDateString(activity.stopped_at)
            const color = activity.onOff ? 'red' : 'green';
            return (
                <span key={activity.started_at} style={{ color: color }}>
                    ({startTime} - {endTime}) {' '}
                </span>
            );
        })

    console.log("formattedTimeRanges student", studentVCTimeRanges);
    console.log("studentCameraOnTimeRanges student", studentCameraOnTimeRanges);
    console.log("studentCameraOnTimeRanges mentor", mentorCameraOnTimeRanges);

    console.log("form inputs", formInputs);
    console.log("start_times", started_at_time);
    console.log("start_times", started_at_time);
    console.log("start_times", started_at_time);

    const requestSearch = (searched) => {
        console.log("searched text", searched);
        const filteredList = orderHistory.filter((item) => item.state_of_order?.toLowerCase().includes(searched) || item.order_flag?.includes(searched))
        if (filteredList.length === 0) {
            setCopyList([])
        }
        setCopyList(filteredList)
    }

    const handleSuspendedToggle = () => {
        console.log("toggle state currently", is_suspended_toggled);
        const temp_toggle_state = !is_suspended_toggled
        setIs_suspended_toggled(temp_toggle_state)
        setFormInputs({ ...formInputs, ad_hoc: temp_toggle_state === true ? true : false })
    }
    console.log("toggle state currently", is_suspended_toggled);

    const dateTimestamp = (timestamp) => {
        const dateTime = new Date(timestamp);
        const date = dateTime.toLocaleDateString();
        console.log("date in the history timestamp", date);
        const time = dateTime.toLocaleTimeString();
        console.log("time in the history timestamp", time);
        return date
    }

    const timestamp = (timestamp) => {
        const dateTime = new Date(timestamp);

        const time = dateTime.toLocaleTimeString();
        console.log("time in the history timestamp", time);
        return time
    }

    // const groupedDetails = {};

    // (copyList.length > 0 ? copyList : orderHistory).forEach((details) => {
    //     const date = dateTimestamp(details.history_timestamp).toString();

    //     if (!groupedDetails[date]) {
    //         groupedDetails[date] = [];
    //     }

    //     groupedDetails[date].push(details);
    // });

    const groupedFilteredData = {};

    reconsiderRequest.forEach((item) => {
        const date = dateTimestamp(item?.history_timestamp?.currentValue);

        if (!groupedFilteredData[date]) {
            groupedFilteredData[date] = [];
        }

        groupedFilteredData[date].push(item);
    });

    let slotNameExists = false;

    for (let i = 0; i < reconsiderRequest.length; i++) {
        if (reconsiderRequest[i]?.slot_name) {
            slotNameExists = true;
            break; // Exit the loop once a slot_name is found
        }
    }


    return (
        <>
            <Modal
                className="ModalSt-contain"
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => modalHide()}
            >
                {console.log("Value of state name in Modal Input:", name)}
                {console.log("Value of state special in Modal Input:", special)}
                <Modal.Body
                    style={{
                        position: "absolute",
                        top: "-24px",
                        left: "127px",
                        height: "auto",
                        lineHeight: "1.4",
                        padding: "14px 28px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        width: "880px",
                        float: "right",

                        // position: "fixed",
                        // top: 0,
                        // right: "-880px", /* Start off-screen */
                        // height: "auto",
                        // lineHeight: 1.4,
                        // padding: "14px 28px",
                        // backgroundColor: " #fff",
                        // borderRadius: "10px",
                        // width: "880px",
                        // transition: "right 0.3s ease",
                    }}>
                    <div>
                        <CloseIcon className="closeIcon" style={{ float: "right" }} onClick={() => modalHide()} />
                        <div style={{ marginTop: "30px", width: "100%" }}>
                            <p className="st-ticket-number">{studentsDetailsClick?.order_details?.act_type_name}</p>
                            <div style={{ color: "red" }}>
                                {errMsg}
                            </div>
                            {origin !== "supportTicket" && <>
                                {studentsDetailsClick?.order_details?.order_flag === "Scheduled" || studentsDetailsClick?.order_details?.order_flag === "In Process" || studentsDetailsClick?.order_details?.order_flag === "Feedback" || studentsDetailsClick?.order_details?.order_flag === "Done" || studentsDetailsClick?.order_details?.order_flag === "Initialized" ? <>
                                    {selectDate && selectTime && mentorDropDown ? <>
                                        <div>
                                            <button className="edit-save-button" onClick={() => { handleSaveReschedule() }}>Save</button>
                                        </div>
                                    </> : <>
                                        <div>
                                            <button className="edit-save-button" onClick={() => { handleReschedule() }}>Edit</button>
                                        </div>
                                    </>}

                                </> : <></>}</>}


                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '450px' }}>

                                    <p className="label-grey-text pTagsHere" style={{ marginBottom: selectDate ? "1rem" : "" }}>Ad-hoc:</p>
                                    <p className="label-grey-text pTagsHere" style={{ marginBottom: selectDate ? "1rem" : "" }} >Date:</p>
                                    <p className="label-grey-text pTagsHere" style={{ marginBottom: selectDate ? "1rem" : "" }}>Time:</p>
                                    {selectTime ? <><p className="label-grey-text pTagsHere" style={{ marginBottom: selectDate ? "1rem" : "" }}>Duration:</p></> : <></>}
                                    <p className="label-grey-text pTagsHere" >Slots Selected:</p>
                                    <p className="label-grey-text pTagsHere" >Role:</p>
                                    <p className="label-grey-text pTagsHere" >Specialization:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
                                    <Switch
                                        checked={is_suspended_toggled}
                                        onChange={handleSuspendedToggle}
                                        name="toggleSwitch"
                                        disabled={selectSuspended === false ? true : false}
                                    />
                                    {selectDate ? (
                                        <>
                                            <div className="" style={{ width: "200px", height: "40px" }}>
                                                <input name="slot_date" type="date" className="form-control" placeholder="Example input"
                                                    value={formInputs.slot_date ? formInputs.slot_date : extractDateFromDateString(studentsDetailsClick?.order_details?.start_time)}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </>
                                    ) : <><p className="pTagsHere">{extractDateFromDateString(studentsDetailsClick?.order_details?.start_time)}</p></>}
                                    {selectTime ? (<>
                                        <div style={{ display: "flex", marginTop: "0.5rem" }}>
                                            <TimePicker
                                                style={{ marginRight: "1rem" }}
                                                placeholder={calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.start_time)}
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                onChange={e => setStarted_at_time(e.format('LT'))}
                                            />
                                            <TimePicker
                                                style={{}}
                                                placeholder={started_at_time ? calculateEndTime(started_at_time, duration_name.find((item_s) => item_s.duration_name === studentsDetailsClick?.order_details?.duration)?.duration_value) : calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.end_time)}
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                onChange={e => setEnded_at_time(e.format('LT'))}
                                                disabled={true}
                                            />
                                        </div>
                                        <select disabled style={{ width: "200px" }} name="duration_name" className="form-control inputFields" onChange={(e) => handleChange(e)} value={duration_id}>
                                            <option style={{ height: "100px" }} className="options-value" value={duration_id} disabled selected> {duration_name.find((item_s) => item_s.duration_name === studentsDetailsClick?.order_details?.duration)?.duration_name}</option>


                                        </select>
                                    </>) : (<><p className="pTagsHere" style={{ marginLeft: selectDate ? "4px" : "", color: studentsDetailsClick?.order_details?.start_time !== null ? "black" : Color.neutralLightGrey }}>{studentsDetailsClick?.order_details?.start_time !== null ?
                                        `${calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.start_time)} - ${calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.end_time)}`
                                        : "No slots booked"}</p></>)}
                                    <p className="pTagsHere" style={{ marginLeft: selectDate ? "4px" : "" }}>{slotNames.map((item, index) => (
                                        <span key={index}>
                                            {extractDateFromDateString(item.start_time)} ({calculateEpochTimeInLocalTime(item.start_time)} -{" "}
                                            {calculateEpochTimeInLocalTime(item.end_time)})
                                            {index !== slotNames.length - 1 && ", "}
                                        </span>
                                    ))}</p>
                                    <p className="pTagsHere" style={{ marginLeft: selectDate ? "4px" : "" }}>{studentsDetailsClick?.order_details?.role_name}</p>
                                    <p className="pTagsHere" style={{ marginLeft: selectDate ? "4px" : "" }}>{studentsDetailsClick?.order_details?.specialization_name}</p>
                                </div>
                            </div>
                        </div>
                        {studentsDetailsClick?.order_details?.order_flag === "In Process" || studentsDetailsClick?.order_details?.order_flag === "Feedback" || studentsDetailsClick?.order_details?.order_flag === "Done" ? <>
                            {/* <p className="label-grey-text pTagsHere" >“Join Session” click time (mentor): </p> */}
                            <div style={{ width: "100%" }}>
                                {/* <p className="modal-subtitles-style2">Session Details</p> */}
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '600px', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">“Join Session” click time (mentor):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 45px", color: "black" }}>{studentsDetailsClick?.dataAnalytics100MS?.vCMentorDetails.length > 0 ? extractedTimeWithAmPmMentorJoined : "-- No Data Present --"}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '100%', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">Session Details (mentor):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 105px", color: "black" }}>{mentorVCTimeRanges ? mentorVCTimeRanges : "-- No Data Present --"}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '100%', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">“Join Session” click time (student):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 45px", color: "black" }}>{studentsDetailsClick?.dataAnalytics100MS?.vCStudentDetails.length > 0 ? extractedTimeWithAmPmStudentJoined : "-- No Data Present --"}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '100%', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">Session Details (student):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 105px", color: "black", width: "500px" }}>{studentVCTimeRanges ? studentVCTimeRanges : "-- No Data Present --"}</p>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '600px', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">Mic On(Mentor):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 175px", color: "black" }}>{studentsDetailsClick?.dataAnalytics100MS?.aCMentorDetails.length === 0 ? "-- No Data available --" : studentsDetailsClick?.dataAnalytics100MS?.aCMentorDetails.onOff === true ? "No" : "Yes"}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '100%', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere" style={{ width: "200px" }}>Camera on (Mentor):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 140px", color: "black" }}>{studentsDetailsClick?.dataAnalytics100MS?.vCMentorDetails.length === 0 ? "-- No Data available --" : mentorCameraOnTimeRanges}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '600px', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">Mic On(Student):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 170px", color: "black" }}>{studentsDetailsClick?.dataAnalytics100MS.aCStudentDetails.length === 0 ? "-- No Data available --" : studentsDetailsClick?.dataAnalytics100MS?.aCStudentDetails.onOff === true ? "No" : "Yes"}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '100%', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">Camera on (Student):</p>
                                    <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 140px", color: "black" }}>{studentsDetailsClick?.dataAnalytics100MS?.vCStudentDetails.length === 0 ? "-- No Data available --" : studentCameraOnTimeRanges}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '600px', cursor: "pointer" }}>
                                    <p className="label-grey-text textTagsHere">Session Recording:</p>
                                    {studentsDetailsClick?.order_details?.recording_url !== null ? <>
                                        <button
                                            className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 155px", border: "none", height: "15px", color: "#1C8EA8", background: "transparent", textAlign: "left", outline: "none", marginTop: 'auto', fontSize: '16px' }}
                                            onClick={() => {
                                                window.open(studentsDetailsClick?.order_details?.recording_url);
                                            }}
                                        >
                                            Recording
                                        </button>
                                    </> : <>
                                        <p className="providingSpaceForSubDetails textTagsHere" style={{ margin: "8px 8px 8px 140px", color: "black" }}>-- Recording is not available --</p>
                                    </>}
                                </div>
                            </div>

                        </> : <></>}
                        {console.log("initializing....", JSON.stringify(studentsDetailsClick?.order_details?.slots?.timing)?.map(slot => slot.start_time))}
                        <hr />
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "100%" }}>
                                <p className="modal-subtitles-style">Student Details</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '200px' }}>
                                        <p className="label-grey-text pTagsHere">Student Name:</p>
                                        <p className="label-grey-text pTagsHere">Student Email:</p>
                                        <p className="label-grey-text pTagsHere">Student Contact:</p>
                                        <p className="label-grey-text pTagsHere">Student Roll Number:</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.first_name}{" "}{studentsDetailsClick?.student_details?.last_name}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.email_id ? studentsDetailsClick?.student_details?.email_id : "N/A"}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.ph_no ? studentsDetailsClick?.student_details?.ph_no : "N/A"}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.roll_no ? studentsDetailsClick?.student_details?.roll_no : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p className="modal-subtitles-style">Documents</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                    <div className="inner-div-container" style={{ height: 'auto', marginTop: '.5rem', marginBottom: '.5rem', width: "100%" }}>
                                        <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "100%", height: "auto", margin: '0 auto' }}>
                                            <PictureAsPdfIcon style={{ color: "#1C8EA8", marginRight: ".5rem" }} />
                                            <button
                                                className="textTagsHere" style={{ border: "none", height: "15px", color: "#1C8EA8", background: "transparent", textAlign: "left", outline: "none", marginTop: 'auto', fontSize: '16px' }}
                                                onClick={() => {

                                                    window.open(resumeData?.url);

                                                }}
                                            >
                                                {resumeName}
                                            </button>
                                            {/* <button onClick={() => resumeClick} style={{ width: "100%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{resumeName}</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style">Mentor Details</p>
                            {mentorDropDown ? (<>
                                <FormControl
                                    style={{ marginTop: "1rem" }}
                                    sx={{ minWidth: 200, height: 50.4, maxHeight: 50.4 }}
                                    size="small"
                                >
                                    <InputLabel id="demo-simple-select-label">
                                        {studentsDetailsClick?.mentor_details?.first_name} {studentsDetailsClick?.mentor_details?.last_name}
                                    </InputLabel>
                                    <Select
                                        name="mentor_id"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formInputs.mentor_id}
                                        label="MBA College"
                                        style={{ height: 50.4, textAlign: "left" }}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {console.log("college namee", mentor_name)}
                                        {mentor_name?.map(mentor => (
                                            <MenuItem style={{ borderBottom: '1px solid #ccc' }} key={mentor.id} value={mentor.id}>
                                                {mentor?.first_name} {mentor?.last_name}
                                                <br />
                                                ({mentor?.email_id})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>) : (<>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '200px' }}>
                                        <p className="label-grey-text pTagsHere">Mentor Name:</p>
                                        <p className="label-grey-text pTagsHere">Mentor Email:</p>
                                        <p className="label-grey-text pTagsHere">Mentor Contact:</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                        <p className="pTagsHere">{studentsDetailsClick?.mentor_details?.first_name}{" "}{studentsDetailsClick?.mentor_details?.last_name}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.mentor_details?.email_id ? studentsDetailsClick?.mentor_details?.email_id : "N/A"}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.mentor_details?.ph_no ? studentsDetailsClick?.mentor_details?.ph_no : "N/A"}</p>
                                    </div>
                                </div>
                            </>)}

                        </div>
                        <hr />
                        <div>
                            <p className="modal-subtitles-style1">Student Feedback</p>
                            {studentsDetailsClick?.student_feedback !== null ? <>
                                {studentsDetailsClick?.student_feedback?.student_feedback !== null && (
                                    <Card sx={{ maxWidth: "100%", marginRight: "20px", marginBottom: "1.5rem" }}>
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {studentFeedbackFields?.good && studentFeedbackFields?.good.length > 0 && (
                                                    <div>
                                                        {studentFeedbackFields?.good.map((item, index) => (
                                                            <div key={index}>
                                                                <Typography variant="body2" color="text.primary" fontSize={17}>
                                                                    Comment : {get_specialCharReplace(item.comment)?.split('\n')
                                                                        .map((line, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {line}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Question : {item.question}

                                                                </Typography>
                                                                {item.selected.length > 0 && (
                                                                    <ul>
                                                                        {item.selected.map((content, contentIndex) => (
                                                                            <li key={contentIndex}>{content}</li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        ))}
                                                        <Typography variant="body2" color="text.primary" fontSize={17} style={{ marginTop: "1rem" }}>
                                                            Rating :
                                                        </Typography>
                                                        <Box
                                                            className="feedbackRating1"
                                                            sx={{
                                                                "& > legend": { mt: 2 },
                                                            }}
                                                        >
                                                            <Rating
                                                                sx={{ color: "#1C8EA8" }}
                                                                className="feedbackRating"
                                                                name="simple-controlled"
                                                                value={studentFeedbackFields?.rating}
                                                                disabled
                                                                icon={<StarIcon sx={{ color: "#1C8EA8" }} />}
                                                                emptyIcon={<StarBorderIcon sx={{ color: "#1C8EA8" }} />}
                                                            />
                                                        </Box>
                                                    </div>
                                                )}

                                                {studentFeedbackFields?.bad && studentFeedbackFields?.bad.length > 0 && (
                                                    <div>
                                                        {studentFeedbackFields?.bad.map((item, index) => (
                                                            <div key={index}>
                                                                <Typography variant="body2" color="text.primary" fontSize={17}>
                                                                    Comment : {get_specialCharReplace(item.comment)?.split('\n')
                                                                        .map((line, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {line}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Question : {item.question}
                                                                </Typography>
                                                                {item.selected.length > 0 && (
                                                                    <ul>
                                                                        {item.selected.map((content, contentIndex) => (
                                                                            <li key={contentIndex}>{content}</li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        ))}
                                                        <Typography variant="body2" color="text.primary" fontSize={17} style={{ marginTop: "1rem" }}>
                                                            Rating :
                                                        </Typography>
                                                        <Box
                                                            className="feedbackRating1"
                                                            sx={{
                                                                "& > legend": { mt: 2 },
                                                            }}
                                                        >
                                                            <Rating
                                                                sx={{ color: "#1C8EA8" }}
                                                                className="feedbackRating"
                                                                name="simple-controlled"
                                                                value={studentFeedbackFields?.rating}
                                                                disabled
                                                                icon={<StarIcon sx={{ color: "#1C8EA8" }} />}
                                                                emptyIcon={<StarBorderIcon sx={{ color: "#1C8EA8" }} />}
                                                            />
                                                        </Box>
                                                    </div>
                                                )}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </> : <>
                                <p className="feedbackNotProvided">Student Feedback has not been provided yet</p>
                            </>}

                        </div>
                        <div>
                            <p className="modal-subtitles-style1">Student Notes</p>
                            {studentsDetailsClick?.student_feedback !== null ? <>
                                {studentsDetailsClick?.student_feedback?.notes !== null && (
                                    <Card sx={{ maxWidth: "100%", marginRight: "20px", marginBottom: "1.5rem" }}>
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {/* {studentsDetailsClick?.student_feedback?.notes} */}
                                                {get_specialCharReplace(studentsDetailsClick?.student_feedback?.notes)?.split('\n')
                                                    .map((line, index) => (
                                                        <React.Fragment key={index}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </> : <><p className="feedbackNotProvided">Student Notes has not been provided yet</p></>}
                        </div>
                        <div>
                            <p className="modal-subtitles-style1">Mentor Feedback</p>
                            {studentsDetailsClick.mentor_feedback !== null ? (
                                <>
                                    <Card sx={{ maxWidth: "100%", marginRight: "20px", marginBottom: "1.5rem" }}>
                                        {console.log("feedback fields", feedbackFields)}
                                        <CardContent>
                                            {feedbackFields?.responses?.map((item, index) => {
                                                const isType1 = typeof item.description === 'string';
                                                const isType2 = typeof item.description === 'object' && item.description.bullets;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Typography variant="body2" color="text.primary" fontSize={17}>
                                                            {item.field_name} :
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {isType1 ? (
                                                                get_specialCharReplace(item.description)
                                                                    ?.split('\n')
                                                                    .map((line, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {line}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    ))
                                                            ) : isType2 ? (
                                                                <List>
                                                                    {item.description.bullets.map((bullet, index) => (
                                                                        <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'disc', paddingLeft: "0px" }}>
                                                                            {bullet}
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            ) : (
                                                                "No Description Provided"
                                                            )}
                                                        </Typography>
                                                        {isType1 && (
                                                            <>
                                                                <Typography variant="body2" color="text.primary" fontSize={17} style={{ marginTop: "1rem" }}>
                                                                    Rating :
                                                                </Typography>
                                                                <Box
                                                                    className="feedbackRating1"
                                                                    sx={{
                                                                        "& > legend": { mt: 2 },
                                                                    }}
                                                                >
                                                                    <Rating
                                                                        sx={{ color: "#1C8EA8" }}
                                                                        className="feedbackRating"
                                                                        name="simple-controlled"
                                                                        value={item.rating}
                                                                        disabled
                                                                        icon={<StarIcon sx={{ color: "#1C8EA8" }} />}
                                                                        emptyIcon={<StarBorderIcon sx={{ color: "#1C8EA8" }} />}
                                                                    />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            {mcqFeedbackFields === null ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <p className="modal-subtitles-style1" style={{ fontSize: "18px", marginTop: "1rem", marginBottom: "0.5rem" }}>Mentor Mcq Feedback</p>
                                                    {mcqFeedbackFields?.responses?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <Typography variant="body2" color="text.primary" fontSize={17}>
                                                                {item.question} :
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.ans ? item.ans : "No Answer provided"}
                                                            </Typography>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <p className="feedbackNotProvided">Mentor Feedback has not been provided yet</p>
                                </>
                            )}
                        </div>

                        <div style={{ marginBottom: "1.5rem" }}>
                            <p className="modal-subtitles-style1">
                                Chats
                            </p>
                            {chatMessages.length > 0 ? <>
                                {chatMessages.map((message, index) => {
                                    const utcDate = new Date(message?.time);
                                    utcDate.setMinutes(utcDate.getMinutes() + 330);
                                    return (
                                        <>
                                            <div key={index} style={{ display: "flex", width: "100%" }}>
                                                <div style={{ width: "30%" }}>
                                                    [{mentor_name.find(mentor => mentor.id === message.user_id) ? "Mentor" : "Student"}; {utcDate.getUTCDate().toString() + "-" + (utcDate.getUTCMonth() + 1).toString() + "-" + utcDate.getUTCFullYear().toString()}, {utcDate.getUTCHours().toString() + ":" + utcDate.getUTCMinutes().toString()}] :
                                                </div>
                                                <div style={{ width: "65%", float: "right" }}>{message.message.text}</div>
                                            </div>
                                        </>
                                    )
                                }
                                )}
                            </> : <>
                                <p className="feedbackNotProvided">No chat messages available</p>
                            </>}
                        </div>
                        {origin !== "supportTicket" && <>
                            {internet_stats !== null ? <>
                                <div>
                                    <p className="modal-subtitles-style1">Internet Graph (Mentor)</p>
                                    <ReactApexChart options={chartData.options} series={[
                                        {
                                            name: 'Publish',
                                            data: publishseriesdata,
                                        },
                                        {
                                            name: 'Subscribe',
                                            data: subscribeseriesdata,
                                        },
                                    ]} type="line" height={280} width={500} />
                                </div>
                            </> : <><p className="feedbackNotProvided">Internet Graph Not Available</p></>}
                            {internet_stats !== null ? <>
                                <div>
                                    <p className="modal-subtitles-style1">Internet Graph (Student)</p>
                                    <ReactApexChart options={chartDataStudent.options} series={[
                                        {
                                            name: 'Publish',
                                            data: publishseriesdatastudent,
                                        },
                                        {
                                            name: 'Subscribe',
                                            data: subscribeseriesdatastudent,
                                        },
                                    ]} type="line" height={300} width={500} />
                                </div>
                            </> : <><p className="feedbackNotProvided">Internet Graph Not Available</p></>}</>}


                        <div>
                            <p className="modal-subtitles-style1">History</p>
                            {allItems.length > 0 ? <>
                                <div style={{ display: "flex", marginTop: "2rem" }}>
                                    <div className='searchTab' style={{ alignItems: 'center', width: "90%", height: "30px", marginBottom: ".5rem" }}>
                                        <input style={{ border: "none", height: "30px" }}
                                            className='searchingText searchTab'
                                            type='text'
                                            onChange={(e) => requestSearch(e.target.value)}
                                            placeholder='Search for name, roll number, etc'
                                        />
                                    </div>
                                </div>
                                {console.log("order history before", orderHistory)}

                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">

                                        <TableBody>
                                            {(() => {
                                                const uniqueCombinations = new Set();
                                                const uniqueRows = [];

                                                allItems.forEach((item, rowIndex) => {
                                                    const itemData = item;
                                                    const displayedValues = [];

                                                    if (itemData) {
                                                        if (itemData?.order_flag) {
                                                            displayedValues.push(itemData?.order_flag?.currentValue);
                                                        }
                                                        if (itemData?.mentor_feedback_submitted) {
                                                            displayedValues.push("Mentor Feedback Given");
                                                        }
                                                        if (itemData?.mentor_join_time) {
                                                            displayedValues.push("Start Session (by Mentor)");
                                                        }
                                                        if (itemData?.is_absent) {
                                                            displayedValues.push("Marked absent");
                                                        }
                                                        if (itemData?.student_feedback_submitted) {
                                                            displayedValues.push("Student Feedback Given");
                                                        }
                                                        if (itemData?.student_join_time?.previousValue === null) {
                                                            displayedValues.push("Joined Session (by Student)");
                                                        }
                                                        if (itemData?.notification_ids) {
                                                            displayedValues.push("Notifications Updated");
                                                        }
                                                        if (itemData?.reconsider) {
                                                            const reconsiderData = JSON.parse(itemData?.reconsider?.currentValue);
                                                            displayedValues.push(
                                                                <>
                                                                    {reconsiderData?.message} <br />
                                                                    Reconsider request: {reconsiderData?.request} <br />
                                                                    <p
                                                                        style={{
                                                                            color:
                                                                                reconsiderData?.response === "1"
                                                                                    ? "green"
                                                                                    : reconsiderData?.response === "0"
                                                                                        ? "red"
                                                                                        : "text.primary",
                                                                        }}
                                                                    >
                                                                        {reconsiderData?.response === "1"
                                                                            ? "Reconsider request accepted"
                                                                            : reconsiderData?.response === "0"
                                                                                ? "Reconsider request rejected"
                                                                                : "No response provided yet"}
                                                                    </p>
                                                                    <p>
                                                                        {slotNameExists && itemData?.slot_name?.currentValue ? (
                                                                            <>{itemData?.slot_name?.currentValue}</>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </p>
                                                                </>
                                                            );
                                                        }
                                                        if (
                                                            itemData.spe_id?.previousValue != null ||
                                                            itemData.role_id?.previousValue != null ||
                                                            itemData.slots?.previousValue != null ||
                                                            itemData.jd_id?.previousValue != null
                                                        ) {
                                                            displayedValues.push("Student re-entry of data");
                                                        }
                                                        if (itemData?.resume?.previousValue != null) {
                                                            displayedValues.push("Resume Updated");
                                                        }

                                                        // Create a unique combination string
                                                        const combination = `${dateTimestamp(item?.history_timestamp?.currentValue)}_${timestamp(item?.history_timestamp?.currentValue)}_${displayedValues.join()}`;

                                                        // Check if the combination is unique
                                                        if (!uniqueCombinations.has(combination)) {
                                                            uniqueCombinations.add(combination); // Mark the combination as seen

                                                            uniqueRows.push(
                                                                displayedValues.map((value, index) => (
                                                                    <TableRow key={`${rowIndex}-${index}`} style={{ cursor: "pointer" }}>
                                                                        <TableCell
                                                                            align="center"
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                            sx={{ fontSize: 16 }}
                                                                        >
                                                                            {item?.history_timestamp ? dateTimestamp(item?.history_timestamp?.currentValue) : "Date Not Updated"}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center"
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                            sx={{ fontSize: 16 }}
                                                                        >
                                                                            {item?.history_timestamp ? timestamp(item?.history_timestamp?.currentValue) : "Time Not Updated"}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center"
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                            sx={{ fontSize: 16 }}
                                                                        >
                                                                            {value}
                                                                        </TableCell>

                                                                    </TableRow>
                                                                ))
                                                            );
                                                        }

                                                    }
                                                });
                                                return uniqueRows;
                                            })()}
                                            {
                                                mentor_missed_Request && <>
                                                    <TableRow style={{ cursor: "pointer" }}>
                                                        <TableCell
                                                            align="center"
                                                            variant="body2"
                                                            color="text.primary"
                                                            sx={{ fontSize: 16 }}
                                                        >
                                                            {mentor_missed[0]?.history_timestamp ? dateTimestamp(mentor_missed[0]?.history_timestamp) : "Date Not Updated"}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            variant="body2"
                                                            color="text.primary"
                                                            sx={{ fontSize: 16 }}
                                                        >
                                                            {mentor_missed[0]?.history_timestamp ? timestamp(mentor_missed[0]?.history_timestamp) : "Time Not Updated"}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            variant="body2"
                                                            color="text.primary"
                                                            sx={{ fontSize: 16 }}
                                                        >
                                                            Mentor Missed
                                                        </TableCell>

                                                    </TableRow>
                                                </>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </> : <><p className="feedbackNotProvided">Order History Not Available</p></>}

                            <div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}


