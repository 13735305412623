// ***********************************//
/* {This is Considered As LeadershipPosition} */
// ***********************************//
import React from "react";
function AcadAchivement(props) {
    const academicAchievementData =  [
        {
            "basic_info": {
                "tag": "Academic Info"
            },
            "sub_section_detail": {
                "sub_section_id": 674,
                "sub_section_order": 1,
                "is_on": 1
            },
            "bullets": [
                {
                    "bullet_id": 1226,
                    "bullet_content": "Academic Achievements -1",
                    "achievement_year": "Year",
                    "bullet_order": 1
                },
                {
                    "bullet_id": 1231,
                    "bullet_content": "Achievements - 2",
                    "achievement_year": "Year",
                    "bullet_order": 2
                }
            ]
        }
    ]
    let Details = []
    if ((academicAchievementData && academicAchievementData?.length > 0)) {
        academicAchievementData?.map((value, index) =>
            Details.push({
                Role: (
                    <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
                        {value?.basic_info ? value.basic_info.tag : ""}
                    </p>
                ),
                Desc: (
                    <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
                        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                            {
                                academicAchievementData[index]?.bullets?.map((bullet) => {
                                    return bullet.bullet_content?.length > 0 ?
                                        <li style={{ listStyleType: bullet.bullet_content?.length > 0 ? "" : 'none', fontFamily: props.fontS }}>
                                            {bullet?.bullet_content}{bullet.achievement_year ? ", " + bullet?.achievement_year : ""}
                                        </li> : <></>
                                })
                            }
                        </ul>
                    </p>
                )
            }))
    }
    else {
        Details = [
            {
                Role: (
                    <p style={{ textAlign: "centre", fontFamily: props.fontS }}>
                        Sr. Coordinator, Finance Club
                    </p>
                ),
                Desc: (
                    <p style={{ textAlign: "justify" }}>
                        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                                LineHeight: props.miniLheight
                            }}
                        >
                            <li style={{ fontFamily: props.fontS, listStyleType: "none" }}>
                                Organically grew Club’s LinkedIn Page from 176 to 700+ followers, signed MoU with Angel Broking, roped in 3 Live Projects, & oversaw Uni Affiliation with CFA Institute. Recruited & Supervised 3 Jr. Coordinators.
                            </li>
                        </ul>
                    </p>
                ),
            },
        ];
    }
    return (
        <div>
            <table
                className="IntershipBox overlapping-table ResumeTable"
                style={{
                    width: props.TableWidth ? "105%" : "100%",
                    width: props.minwidth ? props.minwidth : props.TableWidth,
                    border: "solid 0.25px #000",
                }}
            // style={{ width: props.widths+"px" }}
            >
                <tr>
                    <th className="Resumetable-th"
                        colspan="6"
                        id="Topic"
                        // style={{
                        //   backgroundColor: isActive ? "salmon" : "",
                        //   color: isActive ? "white" : "",
                        // }}
                        style={{
                            backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
                            color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
                            // fontSize: props.FontSmall,
                            fontSize: props.FontSize,
                            fontFamily: props.fontS,
                            // border:props.styleAchivements?"none":"",
                            textAlign: props.headerPosition,
                            // borderTop: "none",
                            height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding
                            // fontFamily: props.FontFamily ? "TimesNewRoman" : "Arial",
                            // fontFamily: props.Arial,
                        }}
                    >
                        {/* Achivements */}
                        {props.name ? props.name : 'LeadershipPosition'}
                    </th>
                </tr>
                {Details.map((val, key) => {
                    return (
                        <tr key={key}
                            colSpan={4}
                            style={{
                                // fontSize: props.FontSmall,
                                fontSize: props.FontSize,
                                fontFamily: props.fontS,
                                height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,

                                paddingTop: key === 0 ? "0.16rem" : "0rem",
                                paddingBottom: key === Details.length - 1 ? "0.16rem" : "0rem", 
                                // fontFamily: props.FontFamily ? "" : "Arial",
                                // fontFamily: props.Arial,
                            }}
                        >
                            {val.Desc}
                        </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default AcadAchivement;
