import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, CircularProgress, IconButton, Modal, Rating, Typography } from "@mui/material";
import { Color, DivCenter, PrimaryButton, PrimaryButtonOutlined } from "../../../../GlobalStyles";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
import "../JobAllRequests/JobAllRequests.css";
import { SlideInRightModal } from "../AdditionalComponents/SlideInRightModal";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import TimeService from "../../../../functions/epochTime";
import { ellipsisFunction } from "../../../Reusable_functions/TextFunctions/EllipsisFunction";
import { ArrowBackIos, FeaturedPlayList, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { SmallPageLoader } from "../../../reusables/Loader/Loader";
import { DialogueBox } from "../../../Reusable_functions/DialogueBox/DialogueBox";

const JobStudentShortlist = () => {
  const services = new CCService();
  const timeServices = new TimeService();
  const [loading, setLoading] = useState({ small_page_loader: false, activity_loader: false });
  const navigate = useNavigate();
  //modals
  const [dialogue, setDialogue] = useState({ open: false, content: "", actions: { label: "OK", handler: () => {}, color: Color.primary1, variant: "contained" } });
  const [releaseList, setReleaseList] = useState({
    open: false,
    body: "",
    onClose: () => {
      setReleaseList((prev) => ({ ...prev, open: false }));
    },
  });
  const initial_requestData = {
    id: null,
    chat_id: null,
    user_id: null,
    interview_note: {
      note: "",
      rating: 0,
    },
    student_name: "",
    interview_time: "",
    meeting_link: "",
    college_name: "",
    status_name: "",
    rejection_remark: "",
    resume_urls: { resume_details: [] },
    resume_id: null,
  };

  const [allRequestData, setAllRequestData] = useState({
    "List 1": [initial_requestData],
    "List 2": [initial_requestData],
    "List 3": [initial_requestData],
    "Extended List": [initial_requestData],
  });
  console.log("allRequestData:", allRequestData);
  const [shortlistDetailsData, setShortlistDetailsData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const job_id = searchParams.get("job_id");
  console.log("job_id:", job_id);

  //for filtering data
  const [backup_RequestData, setBackup_RequestData] = useState({ ...allRequestData });
  const [filterButtons, setFilterButtons] = useState({
    Selected: false,
    "Not selected": false,
    Absent: false,
    Shortllisted: false,
    "Interview scheduled": false,
    "Interview missed": false,
  });

  //styles
  const styles = {
    buttons: {
      mainpagebutton: {
        background: Color.white,
        color: Color.primary1,
        textTransform: "none",
        gap: "8px",
        width: "auto",
        height: "auto",
        ":&hover": {
          background: Color.white,
          color: Color.primary1,
        },
      },
    },
  };

  useEffect(() => {
    const handleUpdateAllRequestData = () => {
      const findTrueValue = (filterButtons) => {
        for (const key in filterButtons) {
          if (filterButtons.hasOwnProperty(key) && filterButtons[key] === true) {
            return key;
          }
        }
        return null;
      };
      const which_one_is_true = findTrueValue(filterButtons);
      console.log("handleUpdateAllRequestData ~ which_one_is_true:", which_one_is_true);
      if (which_one_is_true) {
        const list1 = backup_RequestData["List 1"].filter((data) => data.status_name === which_one_is_true);
        const list2 = backup_RequestData["List 2"].filter((data) => data.status_name === which_one_is_true);
        const list3 = backup_RequestData["List 3"].filter((data) => data.status_name === which_one_is_true);
        const extended_list = backup_RequestData["Extended List"].filter((data) => data.status_name === which_one_is_true);
        setAllRequestData((prev) => ({
          ...prev,
          "List 1": list1.length ? list1 : [initial_requestData],
          "List 2": list2.length ? list2 : [initial_requestData],
          "List 3": list3.length ? list3 : [initial_requestData],
          "Extended List": extended_list.length ? extended_list : [initial_requestData],
        }));
      } else {
        setAllRequestData(backup_RequestData);
      }
    };
    handleUpdateAllRequestData();
  }, [filterButtons["Selected"], filterButtons["Not selected"], filterButtons["Absent"], filterButtons["Shortlisted"], filterButtons["Interview scheduled"], filterButtons["Interview missed"]]);

  useEffect(() => {
    const handleEffect = async () => {
      setLoading((prev) => ({ ...prev, small_page_loader: true }));
      await handleMainUseEffect();
      setLoading((prev) => ({ ...prev, small_page_loader: false }));
    };
    handleEffect();
  }, []);

  const handleMainUseEffect = async () => {
    const final_data = await shortlistStudent_operations();
    console.log("final_data:", final_data);
    if (Object.values(final_data)[0].length || Object.values(final_data)[1].length || Object.values(final_data)[2].length || Object.values(final_data)[3].length) {
      setAllRequestData(final_data);
      setBackup_RequestData(final_data);
    }
  };

  const shortlistStudent_operations = async () => {
    const { fetched_data, shortlist_details } = await fetchShortlistedStudentsbyJobId();
    const fetched_applicants_data = await fetchApplicantsbyJobId();
    console.log("shortlistStudent_operations ~ fetched datas:", fetched_data, fetched_applicants_data);
    setShortlistDetailsData(shortlist_details);
    //calculating and adding to fetched data competency score
    const { data } = fetched_applicants_data;
    data.forEach((d) => {
      d.competency_score = parseFloat((d.assessment_details.reduce((acc, assessment) => acc + assessment.assessment_score, 0) / d.assessment_details.length).toFixed(4));
    });
    //fake inserting list data for further coding
    let temp_data = [...fetched_data];
    let filtered_applicants_data;
    if (temp_data.length <= data.length) {
      //only keep those objects in data whose user_ids are present in temp_data
      // filtered_applicants_data = data.filter((d) => temp_data.find((td) => td.user_id === d.user_id));
      data.forEach((d) => {
        temp_data.forEach((td) => {
          if (td.user_id === d.user_id) td.competency_score = d.competency_score;
        });
      });
    }
    console.log("data - after filter", temp_data);

    let temp_shortlistData = {
      "List 1": [initial_requestData],
      "List 2": [initial_requestData],
      "List 3": [initial_requestData],
      "Extended List": [initial_requestData],
    };
    if (temp_data.length) {
      temp_data[0].list_name = "List 1";
      temp_data[1].list_name = "List 2";
      const list1 = temp_data.filter((data) => parseInt(data.list_number) === 1);
      if (list1.length) temp_shortlistData["List 1"] = list1;
      const list2 = temp_data.filter((data) => parseInt(data.list_number) === 2);
      if (list2.length) temp_shortlistData["List 2"] = list2;
      const list3 = temp_data.filter((data) => parseInt(data.list_number) === 3);
      if (list3.length) temp_shortlistData["List 3"] = list3;
      const extended_list = temp_data.filter((data) => parseInt(data.list_number) === 100);
      if (extended_list.length) temp_shortlistData["Extended List"] = extended_list;

      return temp_shortlistData;
    } else {
      console.log("fetched data empty");
      return temp_shortlistData;
    }
  };

  const fetchShortlistedStudentsbyJobId = async () => {
    const response = await services.get(`jobs/admin/shortlisted?job_id=${job_id}`);
    console.log("fetchShortlistedStudentsbyJobId ~ response:", response);
    // if (response) return response.get_shortlisted_student;
    if (response) return { fetched_data: response.data, shortlist_details: response.shortlist_details };
    else return [];
  };

  const fetchApplicantsbyJobId = async () => {
    try {
      const response = await services.get(`/jobs/admin/jobUserScore?job_id=${job_id}`);
      console.log("response:", response);
      if (response) return { data: response.data, assessment_details: response.assessment_details };
      else return [];
    } catch (error) {
      console.error("Error fetching applicants:", error);
    }
  };

  const handleFilterButtonClick = (button) => {
    // let temp = JSON.parse(JSON.stringify(filterButtons));
    // setAllRequestData((prev) => ({ "New Requests": temp.new ? [] : prev["New Requests"], "On Hold": temp.on_hold ? [] : prev["On Hold"], "Recent": temp.recent ? [] : prev["Recent"] }));
    if (button === "Selected") {
      setFilterButtons((prev) => ({
        ...prev,
        Selected: !prev["Selected"],
        "Not selected": false,
        Absent: false,
        Shortlisted: false,
        "Interview scheduled": false,
        "Interview missed": false,
      }));
    } else if (button === "Not selected") {
      setFilterButtons((prev) => ({
        ...prev,
        Selected: false,
        "Not selected": !prev["Not selected"],
        Absent: false,
        Shortlisted: false,
        "Interview scheduled": false,
        "Interview missed": false,
      }));
    } else if (button === "Absent") {
      setFilterButtons((prev) => ({
        ...prev,
        Selected: false,
        "Not selected": false,
        Absent: !prev["Absent"],
        Shortlisted: false,
        "Interview scheduled": false,
        "Interview missed": false,
      }));
    } else if (button === "Shortlisted") {
      setFilterButtons((prev) => ({
        ...prev,
        Selected: false,
        "Not selected": false,
        Absent: false,
        Shortlisted: !prev["Shortlisted"],
        "Interview scheduled": false,
        "Interview missed": false,
      }));
    } else if (button === "Interview scheduled") {
      setFilterButtons((prev) => ({
        ...prev,
        Selected: false,
        "Not selected": false,
        Absent: false,
        Shortlisted: false,
        "Interview scheduled": !prev["Interview scheduled"],
        "Interview missed": false,
      }));
    } else if (button === "Interview missed") {
      setFilterButtons((prev) => ({
        ...prev,
        Selected: false,
        "Not selected": false,
        Absent: false,
        Shortlisted: false,
        "Interview scheduled": false,
        "Interview missed": !prev["Interview missed"],
      }));
    }
  };

  function ReleaseNextListModal({ open, onClose, body }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };

    const [modalLoading, setModalLoading] = useState({
      button: false,
    });

    //Modal Button Functions
    const handleReleaseNextList = async (job_id) => {
      setModalLoading((prev) => ({ ...prev, button: true }));
      try {
        const response = await services.post(`/jobs/admin/releaseShortlist?job_id=${job_id}`);
        console.log("handleReleaseNextList ~ response:", response);
        await handleMainUseEffect();
        onClose();
        setDialogue((prev) => ({
          ...prev,
          open: true,
          title: "List Release",
          content: `Next List has been released`,
          actions: { ...prev.actions, handler: () => setDialogue((prev) => ({ ...prev, open: false })) },
        }));
      } catch (error) {
        console.error("Error releasing next list:", error);
        if (error?.response?.status === 400) {
          onClose();
          setDialogue((prev) => ({
            ...prev,
            open: true,
            title: "List Release",
            content: "List was not released. " + error?.response?.data?.message,
            actions: { ...prev.actions, handler: () => setDialogue((prev) => ({ ...prev, open: false })) },
          }));
        } else {
          setDialogue((prev) => ({
            ...prev,
            open: true,
            title: "List Release",
            content: "Error Releasing Next List.",
            actions: { ...prev.actions, handler: () => setDialogue((prev) => ({ ...prev, open: false })) },
          }));
        }
      }
      setModalLoading((prev) => ({ ...prev, button: false }));
    };

    return (
      <Modal open={open} onClose={!modalLoading.button ? onClose : {}}>
        <Box sx={{ ...modalBoxStyle }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Release Next List
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            {body}
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              gap: "10px",
            }}
          >
            <PrimaryButtonOutlined
              disabled={modalLoading.button}
              variant="outlined"
              onClick={() => onClose()}
              sx={{
                textTransform: "none",
                borderColor: Color.neutralMidGrey,
                color: Color.neutralMidGrey,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                },
              }}
            >
              Cancel
            </PrimaryButtonOutlined>
            <PrimaryButton
              disabled={modalLoading.button}
              variant="outlined"
              onClick={() => handleReleaseNextList(job_id)}
              sx={{
                textTransform: "none",
                borderColor: Color.primary1,
                background: Color.primary1,
                color: Color.white,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  background: Color.primary1,
                  borderColor: Color.primary1,
                  color: Color.white,
                },
              }}
            >
              {modalLoading.button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Yes"}
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <Box className="main_box">
      <SideNavigationBar />
      <SmallPageLoader show={loading.small_page_loader} />
      <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
      <Box className="box__mainContent" sx={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", gap: "33px", alignItems: "flex-start" }}>
        <Box sx={{ ...DivCenter, mt: "10px", justifyContent: "space-between", width: "100%" }}>
          <Box sx={{ ...DivCenter, gap: "33px" }}>
            <IconButton onClick={() => navigate(-1)} style={{ height: "30px", width: "30px" }}>
              <ArrowBackIos className="Arrow-back-btn" />
            </IconButton>
            <Typography sx={{ fontSize: "32px", color: "black" }}>{shortlistDetailsData?.company_name ?? "No Company Name"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter }}>
            <Button disabled={backup_RequestData["List 3"][0]?.id} variant="outlined" onClick={() => setReleaseList((prev) => ({ ...prev, open: true, body: "Are you sure you want to release the next list?" }))} sx={styles.buttons.mainpagebutton}>
              <FeaturedPlayList />
              Release Next List <KeyboardDoubleArrowRight />
            </Button>
          </Box>
        </Box>
        <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", gap: "16px", mb: "33px" }}>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Job Type:</Typography>
            <Typography style={{ width: "82%" }}>{shortlistDetailsData?.job_type_name ?? "Job type not available"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Designation:</Typography>
            <Typography style={{ width: "82%" }}>{shortlistDetailsData?.designation ?? "No Designation available"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey, hyphens: "auto" }}>Specialization:</Typography>
            <Typography style={{ width: "82%" }}>{shortlistDetailsData?.specialization ? ellipsisFunction(shortlistDetailsData.specialization, 25) : "No specialization available"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Role:</Typography>
            <Typography style={{ width: "82%" }}>{shortlistDetailsData?.role ?? "No role available"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Requirement:</Typography>
            <Typography style={{ width: "82%" }}>{shortlistDetailsData?.openings ?? "1"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>List-1 count:</Typography>
            <Typography style={{ width: "82%" }}>{backup_RequestData["List 1"][0].id ? backup_RequestData["List 1"].length : "Not out"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>List-2 count:</Typography>
            <Typography style={{ width: "82%" }}>{backup_RequestData["List 2"][0].id ? backup_RequestData["List 2"].length : "Not out"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>List-3 count:</Typography>
            <Typography style={{ width: "82%" }}>{backup_RequestData["List 3"][0].id ? backup_RequestData["List 3"].length : "Not out"}</Typography>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%" }}>
            <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Extended List count:</Typography>
            <Typography style={{ width: "82%" }}>{backup_RequestData["Extended List"][0].id ? backup_RequestData["Extended List"].length : "Not out"}</Typography>
          </Box>
        </Box>
        <Box sx={{ ...DivCenter, gap: "10px" }}>
          <Button
            variant="outlined"
            className="button__filterButtons"
            onClick={() => handleFilterButtonClick("Selected")}
            sx={{
              width: "88px !important",
              background: filterButtons["Selected"] ? Color.primary3 : Color.white,
              color: filterButtons["Selected"] ? Color.white : Color.neutralBlack,
              "&:hover": {
                background: filterButtons["Selected"] ? Color.primary3 : Color.white,
                color: filterButtons["Selected"] ? Color.white : Color.primary1,
              },
            }}
          >
            Selected
          </Button>
          <Button
            variant="outlined"
            className="button__filterButtons"
            onClick={() => handleFilterButtonClick("Not selected")}
            sx={{
              width: "130px !important",
              background: filterButtons["Not selected"] ? Color.primary3 : Color.white,
              color: filterButtons["Not selected"] ? Color.white : Color.neutralBlack,
              "&:hover": {
                background: filterButtons["Not selected"] ? Color.primary3 : Color.white,
                color: filterButtons["Not selected"] ? Color.white : Color.primary1,
              },
            }}
          >
            Not selected
          </Button>
          <Button
            variant="outlined"
            className="button__filterButtons"
            onClick={() => handleFilterButtonClick("Absent")}
            sx={{
              background: filterButtons["Absent"] ? Color.primary3 : Color.white,
              color: filterButtons["Absent"] ? Color.white : Color.neutralBlack,
              "&:hover": {
                background: filterButtons["Absent"] ? Color.primary3 : Color.white,
                color: filterButtons["Absent"] ? Color.white : Color.primary1,
              },
            }}
          >
            Absent
          </Button>
          <Button
            variant="outlined"
            className="button__filterButtons"
            onClick={() => handleFilterButtonClick("Shortlisted")}
            sx={{
              width: "130px !important",
              background: filterButtons["Shortlisted"] ? Color.primary3 : Color.white,
              color: filterButtons["Shortlisted"] ? Color.white : Color.neutralBlack,
              "&:hover": {
                background: filterButtons["Shortlisted"] ? Color.primary3 : Color.white,
                color: filterButtons["Shortlisted"] ? Color.white : Color.primary1,
              },
            }}
          >
            Shortlisted
          </Button>
          <Button
            variant="outlined"
            className="button__filterButtons"
            onClick={() => handleFilterButtonClick("Interview scheduled")}
            sx={{
              width: "170px !important",
              background: filterButtons["Interview scheduled"] ? Color.primary3 : Color.white,
              color: filterButtons["Interview scheduled"] ? Color.white : Color.neutralBlack,
              "&:hover": {
                background: filterButtons["Interview scheduled"] ? Color.primary3 : Color.white,
                color: filterButtons["Interview scheduled"] ? Color.white : Color.primary1,
              },
            }}
          >
            Interview scheduled
          </Button>
          <Button
            variant="outlined"
            className="button__filterButtons"
            onClick={() => handleFilterButtonClick("Interview missed")}
            sx={{
              width: "160px !important",
              background: filterButtons["Interview missed"] ? Color.primary3 : Color.white,
              color: filterButtons["Interview missed"] ? Color.white : Color.neutralBlack,
              "&:hover": {
                background: filterButtons["Interview missed"] ? Color.primary3 : Color.white,
                color: filterButtons["Interview missed"] ? Color.white : Color.primary1,
              },
            }}
          >
            Interview missed
          </Button>
          {/* <IconButton sx={{ color: Color.primary1, height: '40px', width: '40px' }}>+</IconButton> */}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box className="box__header">
            <Typography className="typography__header" style={{ width: "20%" }}>
              Shortlists
            </Typography>
            {/* <Typography className='typography__header' style={{ width: '16.67%' }}>Company</Typography> */}
            <Typography className="typography__header" style={{ width: "20%" }}>
              Competency Score
            </Typography>
            <Typography className="typography__header" style={{ width: "20%" }}>
              Interview Details
            </Typography>
            <Typography className="typography__header" style={{ width: "20%" }}>
              Recruiter Feedback
            </Typography>
            <Typography className="typography__header" style={{ width: "20%" }}>
              Hiring Decision
            </Typography>
          </Box>
          <hr style={{ color: Color.neutralMidGrey }} />
          <Box className="box__postings">
            {Object.keys(allRequestData).length ? (
              Object.keys(allRequestData).map((request_key) => (
                <Box key={request_key} sx={{ width: "100%" }}>
                  <Typography sx={{ color: Color.neutralDarkGrey, fontSize: "16px", padding: "16px 0 16px 0" }}>{request_key}</Typography>
                  {allRequestData[request_key][0].id ? (
                    allRequestData[request_key].map((request_row, index) => (
                      <Box key={index}>
                        <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-evenly", padding: "16px 0 16px 0" }}>
                          <Typography style={{ width: "16.67%", textAlign: "center" }}>
                            <a href={request_row.resume_urls?.resume_details.length > 0 ? request_row.resume_urls?.resume_details[0].url : "#"} target="_blank" style={{ textDecoration: "none" }}>
                              <span style={{ color: Color.primary1 }}>{request_row.student_name}</span>
                            </a>
                            {request_row.shortlisted_by_recruiter === 1 && <span style={{ color: "orange" }}> ( Recruiter)</span>}
                            <br />
                            <span style={{ color: Color.neutralMidGrey }}>{request_row.college}</span>
                          </Typography>
                          <Typography style={{ width: "16.67%", textAlign: "center" }}>{![null, undefined].includes(request_row.competency_score) ? request_row.competency_score : "-"}</Typography>
                          {parseInt(request_row.interview_time) ? (
                            <Typography style={{ width: "16.67%", textAlign: "center" }}>
                              {new Date(parseInt(request_row.interview_time)).toLocaleDateString("en-GB")}
                              <br />
                              {timeServices.convertEpochToTime(parseInt(request_row.interview_time))}
                            </Typography>
                          ) : (
                            <Typography style={{ width: "16.67%", textAlign: "center" }}>{request_row.interview_time}</Typography>
                          )}
                          <Typography style={{ width: "16.67%", textAlign: "center", color: Color.accent }}>{request_key === "Extended List" ? <span style={{ color: "transparent", userSelect: "none" }}>a</span> : <Rating name="Interview Rating" value={request_row.interview_note.rating ?? 0} readOnly sx={{ userSelect: "none" }} />}</Typography>
                          <Typography style={{ width: "16.67%", textAlign: "center", color: request_row.status_name === "Not selected" ? "red" : request_row.status_name === "Selected" ? "#08AB3F" : Color.accent }}>{request_row.status_name ?? "N/A"}</Typography>
                        </Box>
                        <hr />
                      </Box>
                    ))
                  ) : (
                    <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>
                  )}
                </Box>
              ))
            ) : (
              <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box className="box__fillerBox" sx={{ width: "8%" }}></Box>
      <ReleaseNextListModal open={releaseList.open} onClose={releaseList.onClose} body={releaseList.body} />
      <DialogueBox
        open={dialogue.open}
        onClose={dialogue.actions.handler}
        title={dialogue.title}
        actions={dialogue.actions}
        content={
          <>
            <p>{dialogue.content}</p>
          </>
        }
      />
    </Box>
  );
};

export default JobStudentShortlist;
