import React from 'react'
import { Box, Button, CircularProgress, IconButton, InputAdornment, MenuItem, Modal, TextField, Typography, Tooltip } from '@mui/material'
import { modalBoxStyle, DivCenter, Color, styles } from '../../../../GlobalStyles'
import { AddOutlined, Close, EditOutlined, GetApp, Search, UploadFile } from '@mui/icons-material'
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import { useEffect, useState } from 'react'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { scrollToTargetId } from '../../../Reusable_functions/ScrollToTargetId/ScrollToTargetId'
import { SearchFunction } from '../../../Reusable_functions/TextFunctions/SearchFunction'
import TimeService from '../../../../functions/epochTime'
import { DataGrid } from '@mui/x-data-grid'
import Service from '../../../../services/httpService'

function SwapStudentList({ onClose, process_id, setSwappedStudent, filter_student_list, setFilteredStudentList, studentList, swappedRequestDetails, confirmation, setConfirmation, refresh }) {

    const services = new CCService();
    const [searchTerm, setSearchTerm] = useState("");
    
    const [warning, setWarning] = useState({
        open: false,
        onClose: () => setWarning((prev) => ({ ...prev, open: false })),
        heading: "",
    })
    // const postSWAPrequest = async (to, from) => {
    //     try {
    //         const body = {
    //             from_order_id: from,
    //             to_order_id: to
    //         }
    //         const res = await services.post("/os/student/swap/request", body);

    //     } catch (err) {
    //         console.log("error in postSWAPrequest", err)
    //     }
    // }

    const putSwapAccRej = async (to, from) => {
        try {
            const body = {
                "process_id": parseInt(process_id),
                "from_order_id": from,
                'to_order_id': to
            }
            const res = await services.put("/os/superadmin/swap/accRej", body);
            setSwappedStudent(from);
            onClose();
            refresh();
        } catch (err) {
            setWarning((prev) => ({ ...prev, open: true, heading: (err.response.data.message) }))
            console.log("error in putSwapAccRej", err)
        }
    }

    const handleChangeSearch = (e, newValue) => {
        console.log("handleChangeSearch ~ SwapStudentList ~ newValue:", e.target.value, newValue)
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        if (searchTerm && searchTerm.length > 1) {
            // FRONTEND - SEARCH
            const filtered = SearchFunction(studentList,
                ['student_name', 'mentor_name', 'student_email', 'student_ph_no',
                    'spe_name', 'mentor_email', 'mentor_ph_no'], searchTerm);
            console.log("handleChangeSearch ~ filtered:", filtered)
            setFilteredStudentList(filtered);
        }
        else {
            setFilteredStudentList([...studentList]);
        }
    }


    console.log("studentList and filteredStudentList in SwapStudentList:", studentList, " ", filter_student_list)
    const styles = {
        page: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        button_styles: {
            contained: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.primary1,
                color: Color.white,
                borderRadius: '12px',
                textTransform: 'none',
                boxShadow: 'none',
                '&:hover': {
                    background: Color.primary1,
                    color: Color.white,
                    boxShadow: 'none',
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                    boxShadow: 'none',
                }
            },
            outlined: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.white,
                color: Color.primary1,
                borderRadius: '12px',
                border: `1px solid ${Color.primary1}`,
                textTransform: 'none',
                '&:hover': {
                    background: Color.white,
                    color: Color.primary1,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            }
        },
        filter_buttons: {
            minWidth: '100px',
            width: 'auto',
            background: Color.white,
            color: Color.primary1,
            borderRadius: '14px',
            border: `1px solid ${Color.primary1}`,
            textTransform: 'none',
            '&:hover': {
                background: 'rgba(195, 236, 245, 1)',
                color: Color.primary1,
            },
        },
    }
    return (
        <Box>
            <TextField
                name="search_swap_students"
                // label="Search Student"
                placeholder='Search Students'
                variant="outlined"
                value={searchTerm}
                onChange={handleChangeSearch}
                // onKeyDown={handleKeyPress}
                fullWidth
                sx={{ width: '70%',  borderRadius: "12px" , marginTop:"12px"}}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search sx={{ color: Color.neutralMidGrey }} />
                        </InputAdornment>
                    ),
                    sx: { borderRadius: "12px", height: "40px" , marginTop:"12px"}
                }}
            />
            <Box sx={{ ...DivCenter, marginTop: "20px", flexDirection: 'column', width: '100%', p: '0px', justifyContent: 'flex-start', gap: '16px', border: "12px", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", paddingBottom: "12px", boxShadow: '0px 5.72px 10.49px 0px rgba(0, 0, 0, 0.11)' }}>
                <Box sx={{ ...DivCenter, width: '100%', background: '#E4E4E4', height: '62px', borderTopRightRadius: "12px", borderTopLeftRadius: "12px" }}>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1, marginLeft: "12px" }}>Student Details</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>Specialization</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>Date and Time</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>Mentor Details</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>Action</Typography>
                </Box>
                <Box sx={{ width: '100%', borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", maxHeight: "200px", overflowY: 'auto', }}>
                    {
                        filter_student_list.length < 1 ?
                            <Box style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"12px 0px"}}>
                                <Typography> No data available</Typography>
                            </Box>
                            :

                            filter_student_list.map((item, index) =>
                                <Box sx={{ ...DivCenter, backgroundColor: index % 2 === 0 ? "#FBFBFB" : Color.white, justifyContent: 'space-around', width: '100%' }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start" }}>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.student_name}</Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.student_email}</Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.student_ph_no}</Typography>

                                    </div>
                                    <div>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', color: Color.neutralBlack, width: '100%', }}>
                                            {item.spe_name}
                                        </Typography></div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{formatConvertermonthfirst(item.start_time, true, false, true)}</Typography>
                                        {/* <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.start_time}</Typography> */}
                                        {/* <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.student_ph_no}</Typography> */}

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.mentor_name}</Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.mentor_email}</Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{item.mentor_ph_no}</Typography>

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Button
                                            // disabled={student.loading}
                                            variant='outlined'
                                            onClick={() => {
                                                // postSWAPrequest(swappedRequestDetails.id); //removed that logic
                                                setConfirmation((prev) => ({
                                                    ...prev, open: true,
                                                    extraData: {
                                                        ...prev.extraData,
                                                        heading: 'Confirmation',
                                                        setLoading: (toggle) => setConfirmation((prev) => ({ ...prev, loading: { button: toggle } })),
                                                        body: `Are you sure you want to swap this student with ${item.student_name}?`,
                                                        button2: { name: 'Swap', action: () => { putSwapAccRej(swappedRequestDetails.id, item.order_id) } },
                                                        button1: { name: 'Cancel', action: () => confirmation.onClose() },
                                                        // loading: { button: loading.modalButton }
                                                    }
                                                }));

                                            }}
                                            style={{ ...styles.button_styles.outlined, minWidth: "30px", color: Color.primary1, padding: "2px 18px", fontSize: '14px', textTransform: 'none' }}>
                                            Swap
                                        </Button>
                                    </div>
                                </Box>
                            )
                    }
                </Box>
                {
                    warning && <Modal onClose={() => setWarning({ ...warning, open: false })}
                        open={warning.open}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description">
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 250,
                            height: 150,
                            bgcolor: 'background.paper',
                            // border: '2px solid #000',
                            borderRadius: '20px',
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: "column",
                            justifyContent: 'center',
                            backdropFilter: "blur(5px)", width: "300px", height: "200px"
                        }}>
                            <div
                                style={{
                                    fontSize: "20px",
                                    color: "#F5A536",
                                    marginBottom: "12px",
                                }}
                            >
                                Warning
                            </div>
                            <div style={{ textAlign: "center" }}>{warning.heading}</div>
                        </Box>
                    </Modal>
                }
                {/* <DataGrid 
        getRowHeight={() => 'auto'}
        autoHeight
        columns={[
            {
                field: 'Student Details',
                headerName: 'Student Details',
                width: 90,
                flex: 0.2,
                renderCell: (params) => (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 8 }}>
                        
                    </div>

                ),
            },

            {
                field: 'Specialization',
                flex: 0.3,
                // headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
                width: 140,
                renderCell: (params) => (
                    <div style={{ padding: 16, display: "flex", flexDirection: "column", alignItems: "center", }}>
                      
                    </div>
                ),
            },
            {
                field: 'Attachments',
                flex: 0.2,
                // headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
                width: 140,
                renderCell: (params) => (
                    <div onClick={() => { console.log("hetttt"); setViewAttachment((prev) => ({ ...prev, open: true, expenses: params.row.Expenses })) }} style={{ cursor: "pointer", fontSize: 18, color: Color.primary1, padding: 8, marginTop: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <AttachFileIcon /> View Attachments
                    </div>
                    // <Button variant="outlined" sx={{ ...styles.button_styles.outlined, , borderRadius: 30, }} >
                    //     ✔ Mark as Settled
                    // </Button>
                ),
            },
            {
                field: 'Actions',
                // headerClassName: 'super-app-theme--header',
                headerAlign: 'center',

                width: 140,
                flex: 0.2,
                renderCell: (params) => (
                    <div >
                        {params.row.Actions.is_settled === 0 ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button onClick={() => { handlePutSettled(1, params.row.Mentors.mentor_id).then(() => { getOOPE(); }) }} variant="outlined" sx={{ ...styles.button_styles.outlined, fontSize: 18, borderRadius: 30, marginTop: "12px" }} >
                                    ✔ Mark as Settled
                                </Button></div>
                            :
                            <div style={{ fontSize: 18, color: Color.primary1, padding: 8, marginTop: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ fontSize: 20 }}> Settled  </div>
                                <div onClick={() => { handlePutSettled(0, params.row.Mentors.mentor_id).then(() => { getOOPE(); }) }} style={{ cursor: "pointer", height: 40, width: 40, borderRadius: 40, backgroundColor: Color.primary1.concat("22"), textAlign: "center", marginLeft: "18px" }}><CloseIcon fontSize={"large"} /></div>
                            </div>}
                    </div>
                ),
            }]}
        apiRef={apiRef}
        components={{
            Toolbar: CustomToolbar,
        }}
        rows={[...OOPE].map((spe, spe_index) => ({
            id: spe_index,
            // avatar: spe.mentor,
            Mentors: spe.mentor ?? 'Not yet selected',
            Expenses: spe.Expenses,
            Attachments: spe.Attachments,
            Actions: spe.Action
        }))} /> */}

            </Box></Box>

    )
}

export default SwapStudentList