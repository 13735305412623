import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { Color } from "../../GlobalStyles";
import './Blogs.css';
import { send_specialCharReplace, get_specialCharReplace } from "../../services/specialCharReplace";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Popper from '@mui/material/Popper';
import { makeTextBold } from "../Reusable_functions/htmlParser/htmlParser";
import { SmallPageLoader } from "../reusables/Loader/Loader";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Modal,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@mui/material';
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Multiselect from 'multiselect-react-dropdown';
import { Box, TextField, Typography, Autocomplete, Chip } from "@mui/material";
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import { styled } from '@mui/system';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
const ModalContainer = styled('div')({
    position: 'absolute',
    width: 600,
    backgroundColor: 'white',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    border: "1px solid #fff",
    padding: '3rem',
    top: '50%',
    left: '50%',
    borderRadius: "20px",
    transform: 'translate(-50%, -50%)',
});

const ModalInput = styled(TextField)({
    marginBottom: '16px',
    width: '100%',
});

const ModalButton = styled(Button)({
    marginTop: '16px',
});
const EditTheBlogs = (props) => {
    const [header, setHeader] = useState("");
    const navigate = useNavigate()
    const service = new CCService()
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loader, setLoader] = useState({ page_loader: false, endpoint_loader: false })
    const [imagePreview, setImagePreview] = useState()
    const [rowData, setRowData] = useState({
        sub_heading: '',
        description: '',
        image: '',
    });
    const { addOrEditTag, blog_id } = location.state;
    const [entireBlogDetails, setEntireBlogDetails] = useState({ title_of_the_blog: "", sub_title_of_the_blog: "", opening_para: "", master_image: "", category_ids: [], tag_ids: [], reading_time: "", upload_time: "", author_id: [], meta_description: "" })
    const [categoryData, setCategoryData] = useState([])
    const [tagsData, setTagsData] = useState([])
    const [authorsData, setAuthorsData] = useState([])
    const [mentorDetails, setMentorDetails] = useState([])
    const [jd_batch_id, setJd_batch_id] = useState([]);
    const [batch_select_data, setBatch_select_data] = useState([])
    const [editingBlogs, setEditingBlogs] = useState({})
    const [blogsDetails, setBlogsDetails] = useState([{ sub_heading: "", description: "", image: "", index: 0 }])
    console.log("/blog/category", blogsDetails);
    const [show, setShow] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false)
    console.log("success modal", successModalOpen, loader);

    const categoryDataGetter = async () => {
        try {
            const res = await service.get(`/blog/category`);
            console.log("blogs data response", res.blog_category);
            return res.blog_category;
        } catch (err) {
            console.log(err);
        }
    };

    const editingBlogsGetter = async () => {
        try {
            const res = await service.get(`/blog/blog?id=${blog_id}`);
            console.log("blogs data response", res.blog_category, res.category_ids);
            return {
                blogCategory: res.blog_category,
                categoryIds: res.category_ids,
                subSections: res.sub_sections,
                authorIds: res.author_ids
            };
        } catch (err) {
            console.log(err);
        }
    };

    const tagsDataGetter = async () => {
        try {
            const res = await service.get(`/blog/tag`);
            console.log("blogs data response", res.tags);
            return res.tags;
        } catch (err) {
            console.log(err);
        }
    };

    const authorsDataGetter = async () => {
        try {
            const res = await service.get(`/blog/author`);
            console.log("blogs data response", res.blog_author);
            return res.blog_author;
        } catch (err) {
            console.log(err);
        }
    };

    const mentorUserDetailsGetter = async () => {
        try {
            const res = await service.get(`/mentorModule/mentor/user`)
            console.log("mentorUserDetailsGetter", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }


    const handleOpen = () => {
        setOpen(true);
        setEditMode(false);
        setRowData({
            id: '',
            sub_heading: '',
            description: '',
            image: '',
        });
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = () => {
        if (editMode) {
            const updatedRows = rows.map((row) => (row.id === rowData.id ? rowData : row));
            setRows(updatedRows);
        } else {
            setRows([...rows, { ...rowData, id: Date.now() }]);
        }
        setOpen(false);
    };

    const handleEdit = (row) => {
        setOpen(true);
        setEditMode(true);
        setRowData(row);
    };


    function HandleEditUser(props) {

        const handleRedirection = () => {
            if (header === "Data saved successfully") {
                setSuccessModalOpen(false);
                navigate("/Blogs")
            } else {
                setSuccessModalOpen(false);
            }
        }
        return (
            <Modal open={successModalOpen} onClose={() => setSuccessModalOpen(false)} centered style={{ backdropFilter: "blur(5px)" }}>
                <Box
                    sx={{
                        position: "absolute",
                        left: "50%",
                        height: "180px",
                        transform: "translate(-50%, -50%)",
                        lineHeight: "1.4",
                        padding: "14px 28px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        maxWidth: "500px",
                        minWidth: "400px",
                        minHeight: "auto",
                        top: "50%",
                        border: "none",
                    }}
                >
                    <div className="row" style={{ margin: "auto", top: "20%" }}>
                        <div className="headerPart">
                            <div style={{ display: "flex", marginTop: "30px", margin: "0 auto" }}>
                                {/* <CheckCircleOutlineIcon style={{ height: "40px", width: "40px", color: Color.primary1, marginRight: "1rem" }} /> */}
                                <p className="DeleteConfirm1" style={{ fontSize: "24px", fontWeight: "normal", marginTop: ".5rem" }}>
                                    {props.header}
                                </p>
                            </div>
                        </div>

                        <div className="form-group btns">
                            {/* <button
                                type="button"
                                className="Rephrasebutton"
                                onClick={() => {
                                    setSuccessModalOpen(false);
                                }}
                            >
                                Edit again
                            </button> */}
                            <button type="button" className="RephrasebuttonNoBackground" onClick={() => { handleRedirection() }}>
                                {props.primaryButton}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        );
    }


    useEffect(() => {
        const handelEffect = async () => {
            setLoader((prev) => ({ ...prev, page_loader: true }));
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/Login');
            }
            console.log("editing blogs", editingBlogs);
            const blogsData = await categoryDataGetter();
            let temp_bundle = JSON.parse(JSON.stringify(blogsData));
            const tagsData = await tagsDataGetter()
            const authorData = await authorsDataGetter()
            const mentorAuthorData = await mentorUserDetailsGetter()
            mentorAuthorData.forEach(element => {
                element.id = element.user_id;
                element.name = `${element.first_name} ${element.last_name}`;
            });
            setAuthorsData([...authorData, ...(mentorAuthorData || [])]);
            temp_bundle = temp_bundle.map((bundle) => {
                bundle.table = "bundle"
                delete bundle.seq_order
                delete bundle.platform
                delete bundle.blog_ids
                return bundle
            });
            console.log("temp bundle:", temp_bundle)
            setCategoryData(temp_bundle);
            setTagsData(tagsData)

            if (addOrEditTag === "Edit") {
                const EditingBlogs = await editingBlogsGetter()
                console.log("EditingBlogs", EditingBlogs);
                setEditingBlogs(EditingBlogs.blogCategory)
                setEntireBlogDetails({ ...entireBlogDetails, title_of_the_blog: EditingBlogs.blogCategory[0].heading, sub_title_of_the_blog: EditingBlogs.blogCategory[0].description, opening_para: get_specialCharReplace(EditingBlogs.blogCategory[0].opening_para), master_image: EditingBlogs.blogCategory[0].image, category_ids: EditingBlogs.categoryIds, tag_ids: EditingBlogs.blogCategory[0].tag_ids.tag_ids, author_id: EditingBlogs.authorIds, meta_description: EditingBlogs.blogCategory[0].meta_description })
                const find_batch = tagsData.filter((ele) => (EditingBlogs.blogCategory[0].tag_ids.tag_ids).includes(ele.id));
                console.log("find batch", find_batch);
                const idsArray = find_batch.map(item => item.id);
                setSelectedTagIds(idsArray)
                setSelectedOptions(find_batch)
                const find_category = blogsData.filter((ele) => (EditingBlogs.categoryIds).includes(ele.id));
                console.log("find batch", find_category);
                setBatch_select_data(find_category)
                const fetchedData = EditingBlogs.subSections || [];
                console.log("fetched data for sub section", fetchedData);
                const updatedRows = fetchedData.map((item) => ({
                    sub_heading: item.sub_heading || '',
                    description: item.description || '',
                    image: item.image || '',
                    id: item.id || null
                }));
                const averageReadingSpeed = 200;
                const totalWords = fetchedData.reduce((acc, subSection) => acc + subSection.description.split(' ').length, 0);
                const estimatedReadingTime = Math.ceil(totalWords / averageReadingSpeed);
                console.log("estimatedReadingTime", estimatedReadingTime);
                setRows(updatedRows);
            }
            setLoader((prev) => ({ ...prev, page_loader: false }));
        };
        handelEffect();
    }, [])

    const handleChangeEntireBlog = (e, index) => {
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);

        if (e.target.name === "title_of_the_blog") {
            setEntireBlogDetails({ ...entireBlogDetails, title_of_the_blog: e.target.value })
            console.log("activity selected id", e.target.value)
        }
        if (e.target.name === "sub_title_of_the_blog") {
            setEntireBlogDetails({ ...entireBlogDetails, sub_title_of_the_blog: e.target.value })
            console.log("activity selected id", e.target.value)
        }
        if (e.target.name === "meta_description") {
            setEntireBlogDetails({ ...entireBlogDetails, meta_description: e.target.value })
            console.log("activity selected id", e.target.value)
        }
        if (e.target.name === "opening_para") {
            setEntireBlogDetails({ ...entireBlogDetails, opening_para: e.target.value })
            console.log("activity selected id", e.target.value)
        }
        if (e.target.name === "author_id") {
            const authorId = e.target.value;
            setEntireBlogDetails(prevState => ({
                ...prevState,
                author_id: [authorId]
            }));
            console.log("activity selected id", e.target.value);
        }

    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setRowData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        console.log("value of author", value);
    };

    const handleImageUploadSubSection = async (selectedFileName) => {
        const headers = {
            Authorization: `Bearer ${Cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
        };

        const blob_image = new Blob([selectedFileName], {
            type: "image/jpeg",
        });

        const image_data = new FormData();
        image_data.append(
            "image$$" + `${selectedFileName}`,
            blob_image,
            `${selectedFileName.name}`
        );

        try {
            const imageRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/blog/blog/upload`,
                image_data,
                { headers }
            );

            if (
                imageRes &&
                imageRes.data &&
                imageRes.data.fileInfoArray &&
                imageRes.data.fileInfoArray.length > 0
            ) {
                if (imageRes.data.fileInfoArray[0].image_name !== "undefined") {
                    const imageUrl = imageRes.data.fileInfoArray[0].url;
                    console.log("Image uploaded:", imageUrl);
                    return imageUrl;
                } else {
                    console.error("Invalid image upload response: no URL");
                    return null;
                }
            } else {
                console.error("Invalid response format for image upload.");
                return null;
            }
        } catch (err) {
            console.error("Error uploading image:", err);
            window.alert(err.response.data.message)
            return null;
        }
    };

    // //For closign the modal and values dont chan
    const handleFormSubmission = async () => {
        const headers = {
            Authorization: `Bearer ${Cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
        };
        const blob_image = new Blob([image], {
            type: "image/jpeg",
        });
        const image_data = new FormData();
        image_data.append(
            "image$$" + `${imageName}`,
            blob_image,
            `${image?.name}`
        );

        try {
            const imageRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/blog/blog/upload`,
                image_data,
                { headers }
            );

            let imageUrl = '';
            if (imageRes && imageRes.data && imageRes.data.fileInfoArray && imageRes.data.fileInfoArray.length > 0) {
                if (imageRes.data.fileInfoArray[0].image_name !== "undefined") {
                    imageUrl = imageRes.data.fileInfoArray[0].url;
                } else {
                    imageUrl = entireBlogDetails.master_image;
                }
                console.log("Image uploaded:", imageRes.data);
            } else {
                console.error("Invalid response format for image upload.");
            }

            const contentSubSections = rows.map(section => {
                const { id, ...sectionWithoutId } = section;
                if (sectionWithoutId.image === '') {
                    const { image, ...rest } = sectionWithoutId;
                    return rest;
                }
                return sectionWithoutId;
            }).map(subSection => {
                if (subSection.description) {
                    subSection.description = send_specialCharReplace(subSection.description);
                }
                if (subSection.sub_heading && subSection.sub_heading !== '') {
                    return { ...subSection, sub_heading: subSection.sub_heading };
                }
                const { sub_heading, ...restWithoutSubHeading } = subSection;
                return restWithoutSubHeading;
            });


            const contentSubSectionsPut = rows.map(section => {
                const { id, image, ...sectionWithoutIdAndImage } = section;

                // Include 'id' and conditionally 'image' if it is not an empty string
                if (String(id).length <= 3) {
                    if (image !== '') {
                        return { id, image, ...sectionWithoutIdAndImage };
                    } else {
                        return { id, ...sectionWithoutIdAndImage };
                    }
                }

                return sectionWithoutIdAndImage;
            }).map(subSection => {
                // Replace special characters in description if it exists
                if (subSection.description) {
                    subSection.description = send_specialCharReplace(subSection.description);
                }

                // Conditionally add sub_heading only if it is not an empty string
                if (subSection.sub_heading && subSection.sub_heading !== '') {
                    return { ...subSection, sub_heading: subSection.sub_heading };
                }

                // Remove sub_heading key if it's an empty string
                const { sub_heading, ...restWithoutSubHeading } = subSection;
                return restWithoutSubHeading;
            });



            const averageReadingSpeed = 200;

            // Calculate the total number of words in all sub-section descriptions
            const totalWords = (addOrEditTag === "Edit" ? contentSubSectionsPut : contentSubSections).reduce((acc, subSection) => acc + subSection.description.split(' ').length, 0);

            // Calculate the estimated reading time for all descriptions
            const estimatedReadingTime = Math.ceil(totalWords / averageReadingSpeed);

            console.log("contentSubSections", contentSubSections, contentSubSectionsPut);
            let data;
            if (addOrEditTag === "Edit") {
                data = {
                    "id": blog_id,
                    ...(selectedTagIds && { "tag_ids": selectedTagIds }),
                    ...(entireBlogDetails.title_of_the_blog && { "heading": entireBlogDetails.title_of_the_blog }),
                    ...(imageUrl && { "image": imageUrl }),
                    ...(entireBlogDetails.sub_title_of_the_blog && {
                        "description": entireBlogDetails.sub_title_of_the_blog
                    }),
                    ...(entireBlogDetails.opening_para && {
                        "opening_para": send_specialCharReplace(entireBlogDetails.opening_para)
                    }),
                    "reading_time": estimatedReadingTime,
                    "upload_time": Date.now(),
                    ...(contentSubSectionsPut && { "sub_sections": contentSubSectionsPut }),
                    ...(entireBlogDetails.category_ids && { "category_ids": entireBlogDetails.category_ids }),
                    ...(entireBlogDetails.author_id && { "author_ids": entireBlogDetails?.author_id }),
                    ...(entireBlogDetails.meta_description && {
                        "meta_description": entireBlogDetails.meta_description
                    })
                };
            } else {
                data = {
                    ...(selectedTagIds && { "tag_ids": selectedTagIds }),
                    ...(entireBlogDetails.title_of_the_blog && { "heading": entireBlogDetails.title_of_the_blog }),
                    ...(imageUrl && { "image": imageUrl }),
                    ...(entireBlogDetails.sub_title_of_the_blog && {
                        "description": entireBlogDetails.sub_title_of_the_blog
                    }),
                    ...(entireBlogDetails.opening_para && {
                        "opening_para": send_specialCharReplace(entireBlogDetails.opening_para)
                    }),
                    "reading_time": estimatedReadingTime ? estimatedReadingTime : null,
                    "upload_time": Date.now(),
                    ...(contentSubSections && { "sub_sections": contentSubSections }),
                    ...(entireBlogDetails.category_ids && { "category_ids": entireBlogDetails.category_ids }),
                    ...(entireBlogDetails.author_id && { "author_ids": entireBlogDetails?.author_id }),
                    ...(entireBlogDetails.meta_description && {
                        "meta_description": entireBlogDetails.meta_description
                    })
                };
            }

            if (addOrEditTag === "Edit") {
                setLoader((prev) => ({ ...prev, endpoint_loader: true }));
                await service
                    .put("/blog/blog", data)
                    .then((res) => {
                        if (res.data.success) {
                            setHeader("Data saved successfully")
                            setSuccessModalOpen(true)
                        } else { console.log("Failed Operation") };
                        setLoader((prev) => ({ ...prev, endpoint_loader: false }));
                    })
                    .catch((err) => {
                        console.log("Errog Msg: ", err);
                        setLoader((prev) => ({ ...prev, endpoint_loader: false }));
                        window.alert(err.response.data.message)
                    });
            } else {
                setLoader((prev) => ({ ...prev, endpoint_loader: true }));
                await service
                    .post("/blog/blog", data)
                    .then((res) => {
                        if (res.data.success) {
                            setHeader("Data saved successfully")
                            setSuccessModalOpen(true)
                        } else {
                            console.log("Failed Operation");
                        }
                        setLoader((prev) => ({ ...prev, endpoint_loader: false }));
                    })
                    .catch((err) => {
                        console.log("Errog Msg: ", err);
                        if (err?.response.status === 400) {
                            setHeader(err?.response.data.message)
                            setSuccessModalOpen(true)
                        }
                        setLoader((prev) => ({ ...prev, endpoint_loader: false }));
                        window.alert(err.response.data.message)
                    });
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            window.alert(error.response.data.message)
        }
    }

    const onSelect_batch = (e) => {
        console.log("Added an item to multi select - batch -", e)
        setBatch_select_data(e);
        let temp = JSON.parse(JSON.stringify(e));
        temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
        setJd_batch_id(temp)
        setEntireBlogDetails({ ...entireBlogDetails, category_ids: temp })
        console.log("temp inside onSelect:", temp)
    }

    // multiselect on remove method
    const onRemove_batch = (e) => {
        console.log("Removed an item to multi select")
        setBatch_select_data(e);
        let temp = JSON.parse(JSON.stringify(e));
        temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
        setJd_batch_id(temp)
        setEntireBlogDetails({ ...entireBlogDetails, category_ids: temp })
        console.log("temp inside onSelect:", temp)
    }



    const [image, setSelectedImage] = useState(null);
    const [imageUpdated, setImageUpdated] = useState(false);
    const [imageName, setImageName] = useState("");
    const handleImageUpload = (e) => {
        console.log("ImageUpload", e.target.files[0].name, e.target.files[0]);
        setSelectedImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]))
        const file = e.target.files[0];
        const fileSize = file.size / 1024 / 1024; // Size in MB
        if (fileSize > 5) {
            setHeader("Image size exceeds 10 MB");
            setSuccessModalOpen(true);
            return;
        }
        setImageName(e.target.files[0].name);
        setImageUpdated(true);
    };

    console.log("blogDetails", blogsDetails, show);
    const goBack = () => {
        console.log("Back Button is Clicked Now");
        navigate(-1);
    };
    console.log("entire blogsss", entireBlogDetails);
    console.log("rowsnsbjvd", rowData, rows);


    useEffect(() => {
        const fetchTagId = async () => {
            try {
                const response = await service.post(`${process.env.REACT_APP_RD_API_URL}/blog/tag`, { name: inputValue });
                const newTag = { id: response.data.tag_id, name: inputValue };
                setTagsData([...tagsData, newTag]);
                setSelectedOptions([...selectedOptions, newTag]);
                setSelectedTagIds([...selectedTagIds, response.data.tag_id]);
                setInputValue('');
            } catch (error) {
                console.error('Error fetching tag ID:', error);
                window.alert(error.response.data.message)
            }
        };
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && inputValue.trim() !== '') {
                fetchTagId();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [inputValue, tagsData, selectedOptions, selectedTagIds]);

    const handleDelete = async (id) => {
        console.log("id jdsnvjhfvb", id);
        let updatedRows
        if (String(id).length <= 3) {
            try {
                const response = await service.delete(`/blog/blog/subsection?blog_id=${blog_id}&sub_section_id=${id}`);
                console.log('API response:', response);
                updatedRows = rows.filter((row) => row.id !== id);
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        } else {
            updatedRows = rows.filter((row) => row.id !== id);
        }
        setRows(updatedRows);
    };

    console.log("authorsData", authorsData);

    return (
        <Box sx={{}}>
            <SideNavigationBar />
            <SmallPageLoader show={loader.page_loader || loader.endpoint_loader} />
            <div className='OngoingEventsCard' style={{ marginLeft: "12rem", marginTop: "3rem" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "35px", width: "35px", marginRight: "0.5rem", marginBottom: ".5rem" }} onClick={() => goBack()} />
                <div style={{ position: 'relative' }}>
                    <p className='topHeadings' style={{ marginBottom: "2rem" }}>{addOrEditTag} Blogs</p></div>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", bgcolor: "white", padding: "0rem 1rem 1rem 0rem" }}>
                    <Typography style={{ fontSize: "18px" }}>Title of the Blog</Typography>
                    <TextField
                        sx={{ width: '60%' }}
                        id="newAcad__degree"
                        name="title_of_the_blog"
                        onChange={(e) => handleChangeEntireBlog(e)}
                        value={entireBlogDetails.title_of_the_blog}
                        label="Title"
                    />
                    <Typography style={{ fontSize: "18px" }}>Sub Title of the blog</Typography>
                    <TextField
                        sx={{ width: '60%' }}
                        id="newAcad__degree"
                        name="sub_title_of_the_blog"
                        onChange={(e) => handleChangeEntireBlog(e)}
                        label="Sub Title"
                        value={entireBlogDetails.sub_title_of_the_blog}
                    />
                    <Typography style={{ fontSize: "18px" }}>Opening Paragraph</Typography>
                    <TextField
                        sx={{ width: '60%' }}
                        id="newAcad__degree"
                        name="opening_para"
                        onChange={(e) => handleChangeEntireBlog(e)}
                        label="Opening Paragraph"
                        value={entireBlogDetails.opening_para}
                    />
                    <Box>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ width: "60%" }}>
                                <Typography gutterBottom style={{ fontSize: "18px", marginTop: ".5rem", marginBottom: "1rem" }}>{addOrEditTag === "Edit" ? "Master Image" : "Upload master picture"}</Typography>
                                {addOrEditTag === "Edit" && <img src={entireBlogDetails.master_image} style={{ width: "200px", height: "200px" }} />}
                                <Box
                                    sx={{
                                        border: "1px dashed #2AA8C4",
                                        height: "65px",
                                        padding: "9px",
                                        borderRadius: "10px",
                                        backgroundColor: "rgba(56, 193, 223, 0.08)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: ".5rem",
                                        alignContent: "center",
                                        width: "100%"
                                    }}
                                >
                                    <label
                                        style={{
                                            border: "none",
                                            display: "flex",
                                            gap: "0.5rem",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "center",
                                        }}
                                        for="files"
                                        class="btn"
                                    >
                                        <CloudUploadOutlinedIcon
                                            for="files"
                                            style={{ width: "40px", height: "40px", color: "#2AA8C4" }}
                                        />{" "}
                                        {imageName === ""
                                            ? "Select an Image" : imageName}
                                    </label>
                                    <TextField
                                        id="files"
                                        type="file"
                                        onChange={handleImageUpload}
                                        style={{
                                            marginTop: "0",
                                            visibility: "hidden",
                                            width: "0%",
                                            justifyContent: "center",
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                {imagePreview && (
                                    <div>
                                        <img src={imagePreview} alt="Selected" style={{ maxWidth: "200px", maxHeight: "150px" }} />
                                    </div>
                                )}
                            </Box>
                        </Box>

                    </Box>

                    <Modal open={open} onClose={handleClose}>
                        <ModalContainer style={{ display: "flex", flexDirection: "column" }}>
                            <Typography style={{ fontSize: "20px", textAlign: 'center', marginBottom: '1.5rem' }}>Sub Section</Typography>
                            <Typography style={{ marginBottom: '.5rem' }}>Sub Heading</Typography>
                            <ModalInput style={{ marginBottom: '1.5rem' }}
                                name="sub_heading"
                                label="Sub Heading"
                                value={rowData.sub_heading}
                                onChange={handleChange}
                            />
                            <Typography style={{ marginBottom: '.5rem' }}>Description</Typography>
                            <ReactQuill style={{ marginBottom: '1.5rem', maxHeight: "250px", overflowY: 'auto', overFlow: "hidden" }}
                                value={rowData.description}
                                onChange={(value) => setRowData((prevData) => ({ ...prevData, description: value }))}
                            />
                            <Box>
                                <label htmlFor="files1" className="btn" style={{ border: "1px solid #ccc", borderRadius: "5px", width: '100%' }}>
                                    <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4", marginRight: "1rem" }} />
                                    {rowData.image ? rowData.image.includes("undefined/") ? rowData.image.split("undefined/")[1]?.split("/")[0] : null : "Select an Image"}
                                </label>

                                <input
                                    type="file"
                                    accept="image/*"
                                    id="files1"
                                    style={{
                                        marginTop: "0",
                                        visibility: "hidden",
                                        width: "0%",
                                    }}
                                    onChange={async (e) => {
                                        const selectedFile = e.target.files[0];
                                        const fileSize = selectedFile.size / 1024 / 1024; // Size in MB
                                        if (fileSize > 5) {
                                            setHeader("Image size exceeds 10 MB");
                                            setSuccessModalOpen(true);
                                            return;
                                        }
                                        else {
                                            try {
                                                const imageUrl = await handleImageUploadSubSection(selectedFile);

                                                setRowData((prevData) => ({ ...prevData, image: imageUrl }));
                                            } catch (error) {
                                                console.error("Error handling image upload:", error);
                                            }
                                        }
                                    }}
                                />

                            </Box>
                            <ModalButton
                                onClick={handleSubmit}
                                className="addmodal__save-button" style={{ color: "white", width: 'auto', height: "35px" }}
                            >
                                {editMode ? 'Update Section' : 'Add Section'}
                            </ModalButton>
                        </ModalContainer>
                    </Modal>
                    <TableContainer component={Paper} style={{ width: "60%", marginTop: "1.5rem" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sub Heading</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Image</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {editMode ? (
                                                <span>{row.sub_heading}</span>
                                            ) : (
                                                <span>
                                                    {row.sub_heading.length > 30
                                                        ? `${row.sub_heading.slice(0, 30)}...`
                                                        : row.sub_heading}
                                                </span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editMode ? (
                                                <span>{makeTextBold(get_specialCharReplace(row.description))}</span>
                                            ) : (
                                                <span>
                                                    {
                                                        // row.description.length > 50
                                                        //     ? `${get_specialCharReplace(row.description).slice(0, 50)}...`
                                                        //     : 
                                                        makeTextBold(get_specialCharReplace(row.description))}
                                                </span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editMode ? (
                                                <span>{row.image && row.image.includes("undefined/") ? row.image.split("undefined/")[1]?.split("/")[0] : null}</span>
                                            ) : (
                                                <span>{row.image && row.image.includes("undefined/") ? row.image.split("undefined/")[1]?.split("/")[0] : null}</span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => handleEdit(row)}
                                            >
                                                <EditOutlinedIcon />
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => handleDelete(row.id)}
                                            >
                                                <DeleteIcon sx={{ color: Color.primary1 }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <AddCircleOutlineIcon style={{ width: "60px", height: "60px", cursor: "pointer" }} onClick={handleOpen} />

                    <div className="form-group" style={{ width: "60%", height: "40px" }}>
                        <label className="inputLabel" style={{ marginBottom: ".5rem", fontSize: "18px" }}>Category</label>
                        <Multiselect
                            displayValue="name"
                            selectedValues={batch_select_data}
                            onRemove={(e) => onRemove_batch(e)}
                            onSelect={(e) => { onSelect_batch(e) }}
                            options={categoryData} sx={{ height: "40px" }}
                            override={{
                                optionContainer: {
                                    position: 'absolute',
                                    top: 'calc(100% + 10px)',
                                    left: '0',
                                    width: '100%',
                                    zIndex: '1',
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                }
                            }}
                        />
                    </div>
                    <div className="form-group" style={{ width: '60%' }}>
                        <Box>
                            <Typography sx={{ fontSize: '18px', fontWeight: '400', marginBottom: ".5rem" }}>Tags</Typography>
                            <div>
                                <div>
                                    <Autocomplete
                                        multiple
                                        id="multiselect-autocomplete"
                                        options={tagsData}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedOptions}
                                        onChange={(event, newValue) => {
                                            setSelectedOptions(newValue);
                                            const tagIds = newValue.map((tag) => tag.id);
                                            setSelectedTagIds(tagIds);
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select options" variant="outlined" />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip label={option.name} {...getTagProps({ index })} />
                                            ))
                                        }
                                        PopperComponent={({ children, anchorEl, ...popperProps }) => (
                                            <Popper {...popperProps} placement="top-start" anchorEl={anchorEl}>
                                                {children}
                                            </Popper>
                                        )}
                                    />
                                </div>
                            </div>
                        </Box>
                    </div>
                    <div className="form-group" style={{ width: '60%', marginTop: 0 }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: '400', marginBottom: ".5rem" }}>Author</Typography>
                        <FormControl
                            style={{ marginTop: "1rem" }}
                            sx={{ minWidth: "100%", height: 50.4, maxHeight: 50.4 }}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-label">
                                Author
                            </InputLabel>
                            <Select
                                name="author_id"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={entireBlogDetails?.author_id}
                                label="Author"
                                style={{ height: 50.4, textAlign: "left", width: "100%" }}
                                onChange={(e) => handleChangeEntireBlog(e)}
                            >
                                {authorsData?.map(author => (
                                    <MenuItem style={{ borderBottom: '1px solid #ccc' }} key={author.id} value={author.user_id === null ? author.id : author.user_id}>
                                        {author?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Typography style={{ fontSize: "18px" }}>Meta Description</Typography>
                    <TextField
                        sx={{ width: '60%' }}
                        id="newAcad__degree"
                        name="meta_description"
                        onChange={(e) => handleChangeEntireBlog(e)}
                        label="Meta Description"
                        value={entireBlogDetails.meta_description}
                    />
                    <button
                        className="addmodal__save-button"
                        onClick={() => handleFormSubmission()}
                    >
                        Submit
                    </button>
                </Box>
            </div>
            <HandleEditUser
                open={successModalOpen}
                header={header}
                primaryButton={"Ok"}
                onClose={() => setSuccessModalOpen(false)}
            />
        </Box>
    );
}
export default EditTheBlogs
