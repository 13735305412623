import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
// import Cookies from 'js-cookie';
import '../../../index.css';
import './Topbar.css';
import Modal from 'react-bootstrap/Modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


export default function Layout(props, { handleEffect }) {
    const [image, setImage] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png');
    const heading1 = props.heading1;
    const heading2 = props.heading2;
    const [showModal, setShowModal] = useState(false);



    //         let path = window.location.pathname.replace('/','')


    // useEffect(() => {
    //     function newPath(){
    //         console.log('initating path change');
    //         // let path = window.location.pathname.replace('/','')
    //     }
    //     newPath()},
    //     [path])

    function HandleEditUser(props) {
        return (
            <Modal
                className="myprofile_modal"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)" }}
            >
                <Modal.Header >
                </Modal.Header>
                <Modal.Body style={{
                    backgroundColor: "white", position: 'absolute',
                    // top: '50%',
                    left: '50%',
                    height: 'auto',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: ' #fff',
                    borderRadius: '10px',
                    maxWidth: '850px',
                    minWidth: '800px'
                }}>
                    <div>
                        <p>Hello</p>
                    </div>

                </Modal.Body>

            </Modal>
        )
    }
    // useEffect(() => {
    //     const handleEffect = async () => {
    //         const headers = {
    //             Authorization: `Bearer ${(Cookies.get('token'))}`
    //         }
    //         const imageGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/picture`, { headers });
    //         console.log(imageGet.data.url);
    //         setImage(imageGet.data.url);
    //     };
    //     handleEffect();


    // }, [])
    const navigate = useNavigate()
    const location = useLocation();
    const [backButton, setbackButton] = useState(null);

    useEffect(() => {
        const displayBackButton = () => {
            console.log("Hahaha Hehehe", backButton);
            if (window.location.pathname.includes("/BatchData") || window.location.pathname.includes("/ProcessData") || window.location.pathname.includes("/CollegeData")) {
                console.log("Hahaha Im up Hehehe", backButton);
                setbackButton(<ArrowBackIcon className="Arrow-back-btn" onClick={() => goBack()} />)
                console.log("Hahaha Im down Hehehe", backButton);
            }
            else { setbackButton(null) }

        }
        // call your function here
        displayBackButton();
    }, [location]);


    const goBack = () => {
        console.log("Back Button is Clicked Now")
        navigate(-1);
    }

    const handleClick = () => {
        // Open the URL in a new tab
        window.open('https://analytics.careercarve.in/', '_blank');
    };

    return (
        <div className="topbar-main-container">
            <div className="topbar-container-no-logo" >
                {!(window.location.pathname.includes("/Dashboard")) ? (
                    <div className="topbar-heading-no-logo" style={{ marginTop: 15 }} >
                        <ArrowBackIcon className="Arrow-back-btn" onClick={() => goBack()} />
                    </div>
                ) : (<div className="topbar-heading-no-logo" style={{ marginTop: 15 }} >
                    <p></p>
                </div>)}

                <div className="topbar-user-profile-image-no-logo" style={{ marginTop: 13 }}>
                    <button onClick={() => { handleClick() }} className='batch-button'>Analytics</button>
                </div>
            </div>

        </div>
    );
}