export const makeTextBold = (inputText, output, styles) => {
  if ([null, undefined].includes(inputText)) return inputText;
  else {
    const strongTagStart = "<strong>";
    const strongTagEnd = "</strong>";
    const bTagStart = "<b>";
    const bTagEnd = "</b>";

    let outputText = "";
    let currentIndex = 0;

    const noStrongTags = inputText.indexOf(strongTagStart) === -1;

    if (noStrongTags) {
      let temp = inputText.replace(/<p>/g, "").replace(/<\/p>/g, "");
      if (styles)
        if (styles.fontFamily || styles.fontSize) {
          return <span dangerouslySetInnerHTML={{ __html: temp }} style={{ fontFamily: "Roboto", fontSize: "16px" }} />;
        } else return <span dangerouslySetInnerHTML={{ __html: temp }} />;
      else return <span dangerouslySetInnerHTML={{ __html: temp }} />;
    }

    while (currentIndex < inputText.length) {
      const startIndex = inputText.indexOf(strongTagStart, currentIndex);

      if (startIndex === -1) {
        outputText += inputText.substring(currentIndex);
        break;
      }

      outputText += inputText.substring(currentIndex, startIndex);

      const endIndex = inputText.indexOf(strongTagEnd, startIndex);

      if (endIndex === -1) {
        outputText += inputText.substring(startIndex);
        break;
      }

      outputText += bTagStart + inputText.substring(startIndex + strongTagStart.length, endIndex) + bTagEnd;

      currentIndex = endIndex + strongTagEnd.length; // Move currentIndex after the </strong> tag
    }

    outputText = outputText.replace(/<p>/g, "").replace(/<\/p>/g, "");
    if (styles)
      if (styles.fontFamily || styles.fontSize) {
        const style_css = "<b style='font-family:" + styles.fontFamily + "; font-size:" + styles.fontSize + ";'>";
        outputText = outputText.replace(/<b>/g, style_css);
      }

    if (output) return outputText;
    else {
      if (styles)
        if (styles.fontFamily || styles.fontSize) {
          return <span dangerouslySetInnerHTML={{ __html: outputText }} style={{ fontFamily: "Roboto", fontSize: "16px" }} />;
        } else return <span dangerouslySetInnerHTML={{ __html: outputText }} />;
      else return <span dangerouslySetInnerHTML={{ __html: outputText }} />;
    }
  }
};
