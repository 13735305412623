import React, { useEffect, useState } from "react";
import ServiceCM from '../../../services/cartmoduleServices';
import Service from '../../../services/httpService';
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
import Papa from 'papaparse';
import { saveAs } from "file-saver";
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Modal from "react-bootstrap/Modal";

import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../../reusables/Loader/Loader";

import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext';
const DefaultJD = () => {
    const navigate = useNavigate();

    const [rowsData, setRowsData] = useState([{ batch_type_id: null, role_type_id: null, role_name: "", batch_name: "", default_jd_name: "" }])
    const [allowed_jdData, setAllowed_jdData] = useState([])
    const [jdData, setJdData] = useState([])
    const [defaultJdData, setDefaultJdData] = useState([])
    const [roleData, setRoleData] = useState([])
    const [batchData, setBatchData] = useState([])
    const [formInputs, setFormInputs] = useState({ batch_type_id: null, role_type_id: null, role_name: "", batch_name: "", default_jd_id: "" })
    const [function_id, setFunction_id] = useState({ role_id: null, batch_id: null })
    //get current date and time
    const currentDate = new Date();

    const [loading, setLoading] = useState(true);

    const [from_table, setFrom_table] = useState();

    const [JDfiles, setJDFiles] = useState([]);

    const serviceCM = new ServiceCM()
    const serviceSA = new Service()
    let count = 0;
    const headCells = [
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'JD'
        },
        {
            id: 'heading2',
            numeric: 'right',
            disablePadding: false,
            label: 'Role Type'
        },
        {
            id: 'heading3',
            numeric: 'right',
            disablePadding: false,
            label: 'Batch Type'
        },
    ];

    const JDGetter = async () => {
        try {
            const res = await serviceSA.get('/job_desc')
            console.log("JD RESPONSE:", res.data)
            setJdData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const defaultJDGetter = async () => {
        try {
            const res = await serviceSA.get('/defaultJd')
            console.log("Default JD RESPONSE:", res.data)
            setRowsData(res.data)
            setDefaultJdData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const roleTypeGetter = async () => {
        try {
            const res = await serviceSA.get('/role?is_admin=1')
            console.log("Role Type RESPONSE:", res.data)
            setRoleData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const batchTypeGetter = async () => {
        try {
            const res = await serviceSA.get('/batchType')
            console.log("Batch Type RESPONSE:", res.data)
            setBatchData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }

            let temp_JD = await JDGetter();
            let temp_defaultJD = await defaultJDGetter();
            let temp_role_type = await roleTypeGetter();
            let temp_batch_type = await batchTypeGetter();

            console.log("odonil", { temp_JD }, { temp_defaultJD }, { temp_role_type }, { temp_batch_type })
            makingRowsData(temp_role_type, temp_batch_type, temp_JD, temp_defaultJD);
            let data = cleaningData(temp_JD)

            setJdData(data)
            setLoading(false)
        }

        handleEffect();
    }, [])

    useEffect(() => {
        const setting_data = () => {
            console.log({ from_table })
            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setFormInputs({ act_type_id: "Select Activity Type", name: "", description: "", duration: "", price: "" })
            }
            else {

                console.log("Inside else of useEffect")
                console.log({ rowsData })
                setFormInputs({
                    ...formInputs,
                    batch_type_id: rowsData[from_table?.index]?.batch_type_id,
                    role_type_id: rowsData[from_table?.index]?.role_type_id,
                    batch_type_name: rowsData[from_table?.index]?.batch_name,
                    role_type_name: rowsData[from_table?.index]?.role_name,
                    default_jd_id: rowsData[from_table?.index]?.default_jd_id
                })
                // setAllowed_jdData(allowedJDoptions(jdData, rowsData[from_table?.index]?.role_type_id, rowsData[from_table?.index]?.batch_type_id))
            }

        }
        setting_data();
    }, [from_table])

    const cleaningData = (data) => {

        let temp_data = JSON.parse(JSON.stringify(data))
        temp_data.forEach(value => {
            value.batch_type_id = JSON.parse(value.batch_type_id).ids

            return temp_data
        })
        return temp_data
    }

    const allowedJDoptions = (data, role_id, batch_id) => {
        console.log({ data, role_id, batch_id })
        let filteredData = [...data]; // shallow copy
        console.log(role_id)
        console.log(batch_id)
        console.log(filteredData
            .filter((obj) => {
                return (obj.role_id === role_id)
            })
            .filter((obj) => {
                return (obj.batch_type_id === role_id)
            })
        )
        return filteredData.filter((obj) => {
            console.log(obj.role_id === role_id && obj.batch_type_id.includes(batch_id))
            return (obj.role_id === role_id && obj.batch_type_id.includes(batch_id));
        });
        console.log(filteredData);
        // return filteredData;
    }


    const makingRowsData = (role, batch, jd, default_jd) => {
        console.log("Role data:", role)
        console.log("Batch data:", batch)
        console.log("JD data:", jd)
        console.log("Default JD data:", default_jd)
        let temp_default_jd = JSON.parse(JSON.stringify(default_jd));
        let temp_jd = JSON.parse(JSON.stringify(jd));
        let temp_role = JSON.parse(JSON.stringify(role));
        let temp_batch = JSON.parse(JSON.stringify(batch));


        temp_default_jd.forEach((row) => {
            row.role_name = temp_role?.map(role => role.id === row.role_type_id ? role.name : "").filter(value => value !== "")[0]
            row.batch_name = temp_batch?.map(batch => batch.id === row.batch_type_id ? batch.name : "").filter(value => value !== "")[0]
            // row.default_jd_name = temp_jd?.map(jd => jd.id === row.default_jd_id ? jd.company_name : "").filter(value => value !== "")[0]
            // delete row.role_type_id
            // delete row.default_jd_id
            return row
        });
        console.log("temp rows:", temp_default_jd)
        setRowsData(temp_default_jd)

    }

    const handleDeleteMul = (index, id) => {
        console.log("delete ~ id:", id)
        let list_rowsData = [...rowsData];
        let list_defaultJDData = [...defaultJdData];


        serviceSA
            .delete("/defaultJd", {
                data: { "batch_type_id": id[0].id_batch, "role_type_id": id[0].id_role }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list_rowsData = list_rowsData.filter((item) => !(id[0].id_batch === item.batch_type_id && id[0].id_role === item.role_type_id));
                setRowsData(list_rowsData)
                list_defaultJDData = list_defaultJDData.filter((item) => !(id[0].id_batch === item.batch_type_id && id[0].id_role === item.role_type_id));
                setDefaultJdData(list_defaultJDData)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
    }

    const data_getter = (name, id, index, activity) => {

        console.log("Dude here's your name:", name);
        console.log("Dude here's your id:", id);
        console.log("Dude here's your index:", index);

        if (name === "delete_mul") {
            console.log("inside delete mul if")
            handleDeleteMul(index, id)
        }
        if (activity === "add") {
            setFormInputs({})
        }
        setFrom_table({
            ...from_table, id: {
                ...id,
                id_batch: typeof id?.id_batch === "number" ? id?.id_batch : undefined,
                id_role: typeof id?.id_role === "number" ? id?.id_role : undefined,
            }, index: typeof index === "number" ? index : undefined
        })
    }

    const Modal_SKU = (show, setShow, mode) => {

        const modalSubmit = () => {
            if (from_table.index === undefined)
                handlePostRequest();
            else
                handlePutRequest();

            setShow(false)
        }

        const onHide = () => {
            setShow(false)
        }

        let temp_role_id = null;
        let temp_batch_id = null;

        const handleChange = (e, index) => {

            console.log("name from form  is:", e.target.name);
            console.log("value from form  is:", e.target.value);
            const { name, value } = e.target

            let temp_role = JSON.parse(JSON.stringify(roleData));
            let temp_batch = JSON.parse(JSON.stringify(batchData));

            if (name === "role_type_select") {
                console.log("role_type_select")
                temp_role_id = value;
                setFormInputs({ ...formInputs, role_type_id: parseInt(value), role_type_name: temp_role?.map(role => role.id === parseInt(value) ? role.name : "").filter(value => value !== "")[0] })
            }

            else if (name === "batch_type_select") {
                console.log("batch_type_select")
                temp_batch_id = value;
                setFormInputs({ ...formInputs, batch_type_id: parseInt(value), batch_type_name: temp_batch?.map(role => role.id === parseInt(value) ? role.name : "").filter(value => value !== "")[0] })
            }
            else if (name === "default_jd_select") {
                console.log("default_jd_select")
                setFormInputs({ ...formInputs, default_jd_id: parseInt(value) })
            }

        }

        const handleDefaultJDClick = (e) => {
            let temp_default_jd = JSON.parse(JSON.stringify(defaultJdData));
            let temp_jd = JSON.parse(JSON.stringify(jdData));
            let allowedJD = allowedJDoptions(temp_jd, formInputs.role_type_id, formInputs.batch_type_id)
            console.log({ allowedJD })
            setAllowed_jdData(allowedJD)
        }

        return (
            <Modal
                show={show}
                onHide={() => onHide()}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '95%',
                    minWidth: '500px',
                    minHeight: '300px'
                }}>
                    <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>

                        <div className='right-side-buttons-sku row'>
                            <div className="headerComp" >
                                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                <p className="compTitle">Default JD</p>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Role Type<span>*</span></label>
                                {/* <input name="durations_name" type="text" className="form-control" placeholder="Example input" value={formInputs.duration_name} onChange={(e) => handleChange(e)} /> */}
                                <select name="role_type_select" disabled={mode === "View" ? true : false} id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={formInputs?.role_type_id}>
                                    <option value={""} selected>Choose Role Type</option>
                                    {roleData?.map((role, i) => (
                                        <option key={i} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Batch Type<span>*</span></label>
                                {/* <input name="durations_name" type="text" className="form-control" placeholder="Example input" value={formInputs.duration_name} onChange={(e) => handleChange(e)} /> */}
                                <select name="batch_type_select" disabled={mode === "View" ? true : false} id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={formInputs?.batch_type_id}>
                                    <option value={""} selected>Choose Batch Type</option>
                                    {batchData?.map((batch, i) => (
                                        <option key={i} value={batch.id}>{batch.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Defualt JD Type<span>*</span></label>
                                {/* <input name="durations_values" type="text" className="form-control" placeholder="Example input" value={formInputs.duration} onChange={(e) => handleChange(e)} /> */}
                                <div onClick={(e) => handleDefaultJDClick(e)}>
                                    <select name="default_jd_select" id="selectList" disabled={mode === "View" ? true : false} className="form-control inputFields" onChange={(e) => handleChange(e)} value={formInputs?.default_jd_id}>
                                        <option value={""} selected>Choose Default JD</option>
                                        {allowed_jdData?.map((data, i) => (
                                            <option key={i} value={data.id}>{data.company_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {mode === "View" ? <></> : <div className=" btn-comp1 " style={{ marginTop: "4%" }}>
                                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
                            </div>}

                        </div>

                    </form>
                </Modal.Body>
            </Modal>

        )
    }

    const headers = {
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
    };


    const handlePostRequest = async () => {
        let data = { ...formInputs }
        data.price = parseInt(data.price)
        console.log("Post data:", data);

        let temp_default_jd = JSON.parse(JSON.stringify(defaultJdData));
        let temp_jd = JSON.parse(JSON.stringify(jdData));
        let temp_role = JSON.parse(JSON.stringify(roleData));
        let temp_batch = JSON.parse(JSON.stringify(batchData));

        await serviceSA
            .post("/defaultJd", {
                "batch_type_id": data.batch_type_id,
                "role_type_id": data.role_type_id,
                "default_jd_id": data.default_jd_id
            })
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")

                let temp = JSON.parse(JSON.stringify(defaultJdData));
                let temp_rowsData = JSON.parse(JSON.stringify(rowsData));

                temp = ([{
                    batch_type_id: formInputs.batch_type_id,
                    role_type_id: formInputs.role_type_id,
                    default_jd_id: formInputs.default_jd_id,
                    default_jd_name: temp_jd?.map(jd => jd.id === formInputs.default_jd_id ? jd.company_name : "").filter(value => value !== "")[0],
                }, ...temp])

                console.log("Post temp value:", temp)
                setDefaultJdData(temp)
                temp_rowsData = ([{
                    batch_type_id: formInputs.batch_type_id,
                    role_type_id: formInputs.role_type_id,
                    default_jd_id: formInputs.default_jd_id,
                    default_jd_name: temp_jd?.map(jd => jd.id === formInputs.default_jd_id ? jd.company_name : "").filter(value => value !== "")[0],
                    role_name: temp_role?.map(role => role.id === formInputs.role_type_id ? role.name : "").filter(value => value !== "")[0],
                    batch_name: temp_batch?.map(batch => batch.id === formInputs.batch_type_id ? batch.name : "").filter(value => value !== "")[0],
                    // duration: formInputs.duration,
                    // price: parseInt(formInputs.price)
                }, ...temp_rowsData])
                setRowsData(temp_rowsData)


                // data_manupulator_sku(temp, roleData)
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }

    const handlePutRequest = async () => {
        console.log("PUT Request")
        let data = { ...formInputs }
        data.id = from_table?.id
        // delete data.image_url
        console.log(data)

        let temp_default_jd = JSON.parse(JSON.stringify(defaultJdData));
        let temp_jd = JSON.parse(JSON.stringify(jdData));
        let temp_role = JSON.parse(JSON.stringify(roleData));
        let temp_batch = JSON.parse(JSON.stringify(batchData));

        serviceSA
            .put("/defaultJd", {
                "batch_type_id": data.batch_type_id,
                "role_type_id": data.role_type_id,
                "default_jd_id": data.default_jd_id
            })
            .then((res) => {

                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")



                let temp = JSON.parse(JSON.stringify(defaultJdData));
                let temp_rowsData = JSON.parse(JSON.stringify(rowsData));

                temp[from_table?.index].batch_type_id = formInputs.batch_type_id
                temp[from_table?.index].role_type_id = formInputs.role_type_id
                temp[from_table?.index].default_jd_id = formInputs.default_jd_id
                temp[from_table?.index].default_jd_name = temp_jd?.map(jd => jd.id === formInputs.default_jd_id ? jd.company_name : "").filter(value => value !== "")[0]


                console.log("Put temp value:", temp)
                setDefaultJdData(temp)

                temp_rowsData[from_table?.index].batch_type_id = formInputs.batch_type_id
                temp_rowsData[from_table?.index].role_type_id = formInputs.role_type_id
                temp_rowsData[from_table?.index].default_jd_id = formInputs.default_jd_id
                temp_rowsData[from_table?.index].default_jd_name = temp_jd?.map(jd => jd.id === formInputs.default_jd_id ? jd.company_name : "").filter(value => value !== "")[0]
                temp_rowsData[from_table?.index].role_name = temp_role?.map(role => role.id === formInputs.role_type_id ? role.name : "").filter(value => value !== "")[0]
                temp_rowsData[from_table?.index].batch_name = temp_batch?.map(batch => batch.id === formInputs.batch_type_id ? batch.name : "").filter(value => value !== "")[0]
                setRowsData(temp_rowsData)

                // data_manupulator_sku(temp, roleData)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }


    const handleDelete = async (jd_id, index) => {
        console.log("delete", jd_id)
        let list = [...rowsData];

        console.log("listtttt1", list);
        serviceSA
            .delete("/duration", {
                data: { "jd_id": jd_id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);

                console.log(err.response.data.message)
                window.alert(err.response.data.message)

            });
    }

    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", rowsData);
        // Simulate a button click after 2 seconds
        const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


    }



    return (<div>
        <RowsContext.Provider value={rowsData}>
            <TopBar />
            <SideNavigationBar />
            {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
                {console.log("from_table.index", from_table?.index)}
                {console.log({ formInputs })}
                {console.log({ rowsData })}
                {console.log({ jdData })}
                {console.log({ allowed_jdData })}
                {console.log({ defaultJdData })}
                {console.log({ batchData })}

                <EnhancedTable mainFile_modal={Modal_SKU} data_getter={data_getter} fileDownload={fileDownload} headCells={headCells} count={count} path={window.location.pathname} />
            </>}

        </RowsContext.Provider>

    </div>
    )
}


export default DefaultJD