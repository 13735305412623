import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from 'antd';

function createData(spe_name, left_out_students) {
    return { spe_name, left_out_students };
}



export default function BasicTable({ data }) {
    console.log("in basic table:", data);
    const rows = data.map((item, index) => createData(item.spe_name, item.left_out_students));
    return (
        <TableContainer component={Paper} style={{ boxShadow:'none'}}>
            <Table sx={{ minWidth: 250, boxShadow:'none' }} size="small" aria-label="a dense table">
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.spe_name} 
                        >
                            <TableCell component="th" scope="row" sx={{ marginBottom:'12px', }}>
                              <Typography style={{fontWeight:500}}>{row.spe_name}</Typography>  
                            </TableCell>
                            <TableCell align="right">{row.left_out_students}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
