import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import EnhancedTable from '../../reusables/TableComponent/TableComponent'
import Service from '../../../services/httpService'
import { saveAs } from "file-saver";
import Modal from "react-bootstrap/Modal";
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import CloseIcon from '@mui/icons-material/Close';
import CollegeData from '../../../CollegeDataServices/httpCollege'
import { useLocation } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Papa from 'papaparse';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../../reusables/Loader/Loader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExcelJS from 'exceljs';

// import Service from "../../../services/httpService";
const UserType = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const collegeData = new CollegeData()
  const services = new Service()

  const [rowsData, setRowsData] = useState([])
  const [formInputs, setFormInputs] = useState([{ id: null, first_name: "", last_name: "", email: "", ph_no: "", linkedin: "", college_id: null }])
  const [from_table, setFrom_table] = useState();
  const location = useLocation();

  const [csvData, setCsvData] = useState(null)
  // const [count, setCount] = useState()
  const service = new Service()

  //get current date and time
  const currentDate = new Date();

  //mentor approval flag
  const [approvedFlag, setApprovedFlag] = useState("NOTA")

  // obtain college data
  const [mentor_college_name, setMentor_college_name] = useState();
  const [CollegeListData, setCollegeListData] = useState([])
  const [CollegeListData1, setCollegeListData1] = useState()

  let count = 0;
  const headCells = [
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'linkedIn',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'college',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Approve/Reject',
    },


  ];

  // college data getter
  const collegeDataGetter = async () => {

    try {
      const res = await services.get('/mentor_college')
      console.log("Response from backend is11:", res.data)
      setCollegeListData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }



  const data_getter = (name, id, index, activity) => {
    // if (id !== undefined || id !== null || id !== 0) {
    // handleAcceptRequest(id,"Accepted")
    // }
    setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
  }

  // get request
  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {
      //college getter
      let student = await studentDataGetter();

      const college = await collegeDataGetter();

      // forming the data
      console.log("odonil", { student })
      data_manupulator_college(college);
      setLoading(false)
    }

    handleEffect();
  }, [])

  const studentDataGetter = async () => {
    try {
      const res = await service.get(`${process.env.REACT_APP_RD_API_URL}/superAdmin/mentor?flag=${approvedFlag}`)
      console.log("College RESPONSE:", res.data)
      setRowsData([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    const setting_data = () => {

      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //setting values for Edit Modal
      console.log("Inside else of useEffect")
      console.log({ rowsData })
      setFormInputs({ ...formInputs, email: rowsData[from_table?.index]?.email, first_name: rowsData[from_table?.index]?.first_name, last_name: rowsData[from_table?.index]?.last_name, ph_no: rowsData[from_table?.index]?.ph_no, linkedin: rowsData[from_table?.index]?.linkedin, college_id: rowsData[from_table?.index]?.college_id })
      setMentor_college_name(rowsData[from_table?.index]?.college_name)

    }
    setting_data();
  }, [from_table])

  const data_manupulator_college = (college) => {

    let temp = college;
    temp = temp.map(bat => {
      bat.key = bat.college_name;
      bat.id = bat.id;
      return bat;
    });
    console.log("temp rows college:", temp)
    setCollegeListData1(temp)
  }

  const goBack = () => {
    console.log("Back Button is Clicked Now")
    navigate(-1);
  }



  const handlePutRequest = async (id, mentor_college_id) => {
    console.log("")
    //get request here
    service
      .put(`${process.env.REACT_APP_RD_API_URL}/superAdmin/mentor`, {
        id: id,
        first_name: formInputs.first_name,
        last_name: formInputs.last_name,
        email: formInputs.email,
        ph_no: formInputs.ph_no,
        linkedin: formInputs.linkedin,
        college_name: mentor_college_name
      })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = JSON.parse(JSON.stringify(rowsData));
        temp[from_table?.index].first_name = formInputs.first_name
        temp[from_table?.index].last_name = formInputs.last_name
        temp[from_table?.index].email = formInputs.email
        temp[from_table?.index].ph_no = formInputs.ph_no
        temp[from_table?.index].linkedin = formInputs.linkedin
        temp[from_table?.index].college_name = mentor_college_name
        console.log("Put temp value:", temp)
        setRowsData(temp)

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
  }
  const handleAcceptRequest = async (id, index) => {
    console.log("accepted id", id)
    let list = [...rowsData];
    //get request here
    service
      .put(`${process.env.REACT_APP_RD_API_URL}/superAdmin/mentor/action`, {
        id: id,
        approved_flag: "Accepted"
      })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        setApprovedFlag("Accepted")
        list.splice(index, 1);
        setRowsData(list)

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
  }

  const handleRejectRequest = async (id, index) => {
    console.log("accepted id", id)
    let list = [...rowsData];
    //get request here
    service
      .put(`${process.env.REACT_APP_RD_API_URL}/superAdmin/mentor/action`, {
        id: id,
        approved_flag: "Rejected"
      })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        setApprovedFlag("Rejected")
        // let temp = JSON.parse(JSON.stringify(rowsData));
        list.splice(index, 1);
        setRowsData(list)
        // console.log("Put temp value:", temp)
        // setRowsData(temp)

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
  }



  const Modal_Durations = (show, setShow) => {

    const modalSubmit = () => {
      handlePutRequest(rowsData[from_table?.index]?.id, mentor_college_name);
      setShow(false)
    }
    // const AcceptActivate = () => {
    //   handleAcceptRequest(rowsData[from_table?.index]?.id,from_table.index)
    //   setShow(false)

    // }

    // const RejectActivate = () => {
    //   handleRejectRequest(rowsData[from_table?.index]?.id,from_table.index)
    //   setShow(false)

    // }

    const onHide = () => {
      setShow(false)
    }





    const handleChange = (e, index) => {
      //Logs for entering values
      console.log("name from form  is:", e.target.name);
      console.log("code from form  is:", e.target.value);

      //Conditions for storing values
      if (e.target.name === "email") {
        setFormInputs({ ...formInputs, email: e.target.value })
      }
      else if (e.target.name === "first_name") {
        setFormInputs({ ...formInputs, first_name: e.target.value })
      }
      else if (e.target.name === "last_name") {
        setFormInputs({ ...formInputs, last_name: e.target.value })
      }
      else if (e.target.name === "ph_no") {
        setFormInputs({ ...formInputs, ph_no: e.target.value })
      }
      else if (e.target.name === "linkedin") {
        setFormInputs({ ...formInputs, linkedin: e.target.value })
      }
      else if (e.target.name === "college-select") {
        setMentor_college_name(e.target.value)
        setFormInputs({ ...formInputs, college_id: mentor_college_name })
        console.log("batch selected id", e.target.value)
      }
    }
    console.log("mentor_college_id", mentor_college_name);


    //render
    return (
      <Modal
        show={show}
        onHide={() => onHide()}
        className="FileUploadModal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
      >
        <Modal.Body style={{
          position: 'absolute',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          lineHeight: '1.4',
          padding: '14px 28px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          maxWidth: '95%',
          minWidth: '500px',
          minHeight: '300px'
        }}>
          <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>
            <div className='right-side-buttons-college row'>
              <div className="headerComp" >
                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                <p className="compTitle">Mentor Data</p>
              </div>
              <div className="form-group">
                <label className="inputLabel">First Name<span>*</span></label>
                <input name="first_name" type="text" className="form-control" placeholder="Example input" value={formInputs.first_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Last Name<span>*</span></label>
                <input name="last_name" type="text" className="form-control" placeholder="Example input" value={formInputs.last_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Email<span>*</span></label>
                <input name="email" type="text" className="form-control" placeholder="Example input" value={formInputs.email} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Phone Number<span>*</span></label>
                <input name="ph_no" type="text" className="form-control" placeholder="Example input" value={formInputs.ph_no} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">LinkedIn<span>*</span></label>
                <input name="linkedin" type="text" className="form-control" placeholder="Example input" value={formInputs.linkedin} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group ">
                <label className="inputLabel">College<span>*</span></label>
                <select name="college-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={mentor_college_name}>
                  <option className="options-value" value={""} disabled selected>Select College</option>
                  {CollegeListData1?.map((bat, i) => (
                    <option key={i} value={bat.key}>{bat.key}</option>
                  ))}
                </select>
              </div>
              {/* <div className="Activation-mentor" style={{display:'flex', justifyContent:'space-around', marginTop:'2px' }}>
              <div className="form-group" style={{marginTop:"2px"}}>
                <button type="button" className="btn" style={{border:'1px solid grey'}} onClick={() => AcceptActivate()}>Activate</button>
              </div>
              <div className="form-group" style={{marginTop:"2px"}}>
                <button type="button" className="btn" style={{border:'1px solid grey'}} onClick={() => RejectActivate()}>Reject </button>
              </div>
              </div> */}

              <div className=" btn-comp " style={{ marginTop: "4%", marginLeft: '0' }}>
                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    )
  }

  const confirmApproveReject = (acceptRejectModal, setAcceptRejectModal) => {


    const AcceptActivate = () => {
      handleAcceptRequest(rowsData[from_table?.index]?.id, from_table?.index)
      setAcceptRejectModal(false)

    }

    const RejectActivate = () => {
      handleRejectRequest(rowsData[from_table?.index]?.id, from_table?.index)
      setAcceptRejectModal(false)

    }

    const onHide = () => {
      setAcceptRejectModal(false)
    }

    return (
      <Modal
        show={acceptRejectModal}
        onHide={() => onHide()}
        className="myprofile_modal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Body style={{
          position: 'absolute',
          left: '50%',
          height: 'auto',
          transform: 'translate(-50%, -50%)',
          lineHeight: '1.4',
          padding: '14px 28px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          maxWidth: '603px',
          minWidth: '400px',
          minHeight: '200px',
          // border: "2px solid black"
        }}>

          <form style={{ display: 'flex', flexDirection: 'col', justifyContent: 'space-between' }}>
            <div className='row'>
              <div className="headerPart" >
                <CloseIcon className="closeIcon" onClick={((e) => { setAcceptRejectModal(false); })} />
                <p className="DeleteConfirm" style={{ fontWeight: 'normal', fontSize: '20px' }}>Approve/Reject</p>
              </div>
              <div className="form-group msgContain">
                <p className="message">Choose whether you want to approve or reject the mentor</p>
              </div>
              <div className="form-group btns" style={{ marginTop: '2%' }}>
                <button type="button" className="btn" style={{ border: '1px solid #545454', color: '#08AB3F', borderRadius: "5px" }} onClick={() => AcceptActivate()}>Approve</button>
                <button type="button" className="btn" style={{ border: '1px solid #545454', color: '#FF0000', borderRadius: "5px" }} onClick={() => RejectActivate()}>Reject </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    )
  }







  const fileDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    const hardcodedRow = ['id', 'first_name', 'last_name', 'email_id', 'ph_no', 'linkedin','college_name', 'approved_flag', 'college_id'];
    worksheet.addRow([...hardcodedRow]);
    (rowsData).forEach((row, index) => {
      const rowData = [...Object.values(row)];
      worksheet.addRow(rowData);
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert Buffer to Blob
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a downloadable file
      const currentDate = new Date();
      const filename =
        `${window.location.pathname}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`
      saveAs(blob, filename);
    });
    }

  return (<div >
    <RowsContext.Provider value={rowsData}>
      <TopBar />
      <SideNavigationBar />
      {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div> : <>
        {console.log({ rowsData })}
        <div style={{ marginTop: "5rem", width: '89%', marginLeft: "auto" }}>
          <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
        </div>
        <EnhancedTable CollegeListData={CollegeListData} approved_flag="NOTA" data_getter={data_getter} confirmApproveReject={confirmApproveReject} mainFile_modal={Modal_Durations} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} />
      </>}
    </RowsContext.Provider>
  </div>
  )
}

export default UserType