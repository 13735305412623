import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
// import Service from "../../../services/httpService";
import ApexCharts from 'apexcharts';
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { BatchTrackerSkeleton } from "../../reusables/SkeletonLoader/SkeletonLoader";
import ReactApexChart from 'react-apexcharts';
import { Color } from "../../../GlobalStyles";
import ExcelJS from 'exceljs';
import { CircularProgress } from "@mui/material";
const ResumesDigitalAnalytics = (props) => {
  const navigate = useNavigate();
  const service = new CCService();
  const location = useLocation()
  const resumesDigitalAnalyticsData = location.state?.resumesDigitalAnalyticsData || props.resumesDigitalAnalyticsData;
  const fromResumesTemplatePath = location.state?.fromResumesTemplatePath || props.fromResumesTemplatePath;
  const college_name = location.state?.college_name || props.college_name;
  const batch_name = location.state?.batch_name || props.batch_name;
  const process_id = location.state?.process_id || props.process_id
  const [downloadLoader, setDownloadLoader] = useState(false)
  const [filteredresumesDigitalAnalyticsData, setFilteredresumesDigitalAnalyticsData] = useState([]);
  const [experiencedRAGData, setExperiencedRAGData] = useState({ ER: null, EA: null, EG: null });
  const [freshersRAGData, setFreshersRAGData] = useState({ FR: null, FA: null, FG: null });
  const [percentages, setPercentages] = useState({});
  const [batchProcessDataGetter, setBatchProcessDataGetter] = useState([]);
  const [interviewWiseSkeletonLoader, setInterviewWiseSkeletonLoader] = useState(false);
  console.log("batch analytics data", resumesDigitalAnalyticsData);
  const currentDate = new Date();

  const [resumesDigitalCompletionScore, setResumesDigitalCompletionScore] = useState({ data: [], categories: [] })
  const [horizontalWhiteSpaceData, setHorizontalWhiteSpaceData] = useState({ data: [], categories: [] });
  const [verticalWhiteSpaceData, setVerticalWhiteSpaceData] = useState({ data: [], categories: [] });
  const [overallWhiteSpaceData, setOverallWhiteSpaceData] = useState({ data: [], categories: [] });

  const [workexData, setWorkexData] = useState([]);
  const [workexCategories, setWorkexCategories] = useState([]);
  const [freshersData, setFreshersData] = useState([]);
  const [freshersCategories, setFreshersCategories] = useState([]);

  const [chartData, setChartData] = useState({
    data: [],
    categories: []
  });

  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      if (resumesDigitalAnalyticsData.length > 0) {
        console.log("waht is here", resumesDigitalAnalyticsData);

        setInterviewWiseSkeletonLoader(true);
        const parsedData = JSON.parse(resumesDigitalAnalyticsData[0].complition_score);
        const categories = Object.keys(parsedData);
        const data = Object.values(parsedData);
        setResumesDigitalCompletionScore((prev) => ({ ...prev, data: data, categories: categories }))

        const parsedWhitespace = JSON.parse(resumesDigitalAnalyticsData[0].whitespace);

        // Reverse the order of keys and values
        const reverseOrder = (dataObj) => {
          const categories = Object.keys(dataObj).reverse(); // This should return an array
          const data = categories.map(key => dataObj[key]);
          return { categories, data };
        };


        // Set horizontal and vertical white space data
        const horizontalData = reverseOrder(parsedWhitespace.horizontalWhiteSpace);
        const verticalData = reverseOrder(parsedWhitespace.verticalWhiteSpace);
        const overAllData = reverseOrder(parsedWhitespace.overallWhitespace)
        console.log("what is vertical and horizontal", horizontalData, verticalData);

        setHorizontalWhiteSpaceData(horizontalData);
        setVerticalWhiteSpaceData(verticalData);
        setOverallWhiteSpaceData(overAllData)
        const qualityScore = JSON.parse(resumesDigitalAnalyticsData[0]?.quality_score)
        setWorkexData(Object.values(qualityScore.workex));
        setWorkexCategories(Object.keys(qualityScore.workex));
        setFreshersData(Object.values(qualityScore.Freshers));
        setFreshersCategories(Object.keys(qualityScore.Freshers));

        const parsedStrugglersChart = JSON.parse(resumesDigitalAnalyticsData[0]?.section_wize_struggles)
        const strugglers_chart = reverseOrder(parsedStrugglersChart)
        console.log("strugglers", strugglers_chart, strugglers_chart);
        setChartData((prev) => ({ ...prev, data: strugglers_chart.data, categories: strugglers_chart.categories }));
        setInterviewWiseSkeletonLoader(false);
      }

    };

    handleEffect();
  }, []);

  console.log("freshers and experienced rag data", experiencedRAGData, freshersRAGData);
  console.log("percentages in", percentages);

  // decimal to percentage
  function DecimalToPercentage(data) {
    const percentage = data * 100;
    return Math.round(percentage);
  }







  const StrugglersChartData = ({ resumesDigitalCompletionScore }) => {
    useEffect(() => {
      const options = {
        series: [
          {
            name: 'Struggles',
            data: resumesDigitalCompletionScore?.data,
          }
        ],
        chart: {
          id: 'struggles',
          group: 'social',
          type: 'line',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: resumesDigitalCompletionScore?.categories
        },
        colors: ['#805989'],
        legend: {
          show: true,
          position: "top",
          showForSingleSeries: true,
          fontSize: "14px",
          customLegendItems: ["Refinement Opportunities"],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2); // Format Y-axis labels for better readability
            }
          }
        }
      };

      const chart = new ApexCharts(document.querySelector("#strugglers-chart"), options);
      chart.render();

      // Cleanup the chart when component unmounts
      return () => {
        chart.destroy();
      };
    }, [resumesDigitalCompletionScore]);

    return <div id="strugglers-chart"></div>;
  };

  const BarChart = ({ resumesDigitalCompletionScore }) => {
    useEffect(() => {
      const options = {
        series: [
          {
            name: 'Completion Score',
            data: resumesDigitalCompletionScore?.data,
          }
        ],
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: resumesDigitalCompletionScore?.categories,
          labels: {
            formatter: function (val, index) {
              return `${val}%`;
            },
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
        },
        colors: ["#c7a8c8"],
        legend: {
          show: true,
          position: "top",
          showForSingleSeries: true,
          fontSize: "14px",
          customLegendItems: ["No. of Students"],
        },
        yaxis: {
          title: {
            show: false,
            text: '',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " students";
            },
          },
        },
      };

      const chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();

      // Cleanup the chart when component unmounts
      return () => {
        chart.destroy();
      };
    }, [resumesDigitalCompletionScore]);

    return <div id="chart"></div>;
  };

  const resumesDigitalAnalyticsDataDownloadCSV = async (process_id) => {

  };
  ;
  console.log("datasss", Object.values(freshersRAGData), Object.values(experiencedRAGData));

  console.log("batch process data getter", batchProcessDataGetter);

  const HorizontalBarChartRTL = ({ data, categories }) => {
    useEffect(() => {
      const options = {
        series: [
          {
            data: data.reverse(),
          },
        ],
        chart: {
          type: 'bar',
          height: 375,
          marginTop: "10px",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#62326e", "#855186", "#916f99", "#b292b3", "#bababa"],
        xaxis: {
          marginBottom: "10px",
          categories: categories.reverse(),
          axisBorder: {
            show: false,  // Remove the x-axis border line
          },
          axisTicks: {
            show: false,  // Remove the x-axis ticks
          },
        },
        yaxis: {
          show: false,
          axisBorder: {
            show: false,  // Remove the x-axis border line
          },
          axisTicks: {
            show: false,  // Remove the x-axis ticks
          },
        },
        grid: {
          show: false,  // Hide grid lines
        },
        title: {
          show: false,
          text: 'Work-ex',
          floating: true,
          offsetY: 355,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      };

      const chart = new ApexCharts(document.querySelector("#chart-rtl"), options);
      chart.render();

      // Cleanup the chart when the component unmounts
      return () => {
        chart.destroy();
      };
    }, [data, categories]);

    return <div id="chart-rtl"></div>;
  };

  const HorizontalBarChartLTR = ({ data, categories }) => {
    useEffect(() => {
      const options = {
        series: [
          {
            data: data.reverse(),
          },
        ],
        chart: {
          type: 'bar',
          height: 375,
          marginTop: "10px",
          toolbar: {
            show: false,
          },
        },
        colors: categories.map((_, index) => {
          // Assign a color based on the index of the category
          const colors = ["#62326e", "#855186", "#916f99", "#b292b3", "#bababa"];
          return colors[index % colors.length]; // Use modulo to cycle colors if there are more categories than colors
        }),
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          marginBottom: "10px",
          categories: categories.reverse(),
          axisBorder: {
            show: false,  // Remove the x-axis border line
          },
          axisTicks: {
            show: false,  // Remove the x-axis ticks
          },
        },
        yaxis: {
          show: false,
          reversed: true, // Reverses the y-axis to simulate a left-to-right direction
          axisBorder: {
            show: false,  // Remove the x-axis border line
          },
          axisTicks: {
            show: false,  // Remove the x-axis ticks
          },
        },
        grid: {
          show: false,  // Hide grid lines
        },
        title: {
          show: false,
          text: 'Freshers',
          floating: true,
          offsetY: 355,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      };

      const chart = new ApexCharts(document.querySelector("#chart-ltr"), options);
      chart.render();

      // Cleanup the chart when the component unmounts
      return () => {
        chart.destroy();
      };
    }, [data, categories]);

    return <div id="chart-ltr"></div>;
  };


  const InflationChart = ({ whiteSpaceData }) => {
    useEffect(() => {
      const options = {
        series: [{
          name: 'Horizontal White Space',
          data: whiteSpaceData.data
        }],
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: ["#c7a8c8"],
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
              horizontal: false,
              endingShape: 'rounded',
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: whiteSpaceData.categories,
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          },
          title: {
            text: "No. of students",
            style: {
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },

      };

      const chart = new ApexCharts(document.querySelector("#overall-my-custom-chart"), options);
      chart.render();

      return () => {
        chart.destroy(); // Clean up chart on component unmount
      };
    }, [whiteSpaceData]);

    return <div id="overall-my-custom-chart"></div>;
  };

  const HorizontalWhiteSpaceChart = ({ whiteSpaceData }) => {
    useEffect(() => {
      const options = {
        series: [{
          name: 'Horizontal White Space',
          data: whiteSpaceData.data
        }],
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: ["#c7a8c8"],
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
              horizontal: false,
              endingShape: 'rounded',
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: whiteSpaceData.categories,
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          },
          title: {
            text: "No. of students",
            style: {
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },

      };

      const chart = new ApexCharts(document.querySelector("#horizontal-my-custom-chart"), options);
      chart.render();

      return () => {
        chart.destroy(); // Clean up chart on component unmount
      };
    }, [whiteSpaceData]);

    return <div id="horizontal-my-custom-chart"></div>;
  };

  const VerticalWhiteSpaceChart = ({ whiteSpaceData }) => {
    useEffect(() => {
      const options = {
        series: [{
          name: 'Vertical White Space',
          data: whiteSpaceData.data
        }],
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: ["#c7a8c8"],
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
              horizontal: false,
              endingShape: 'rounded',
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: whiteSpaceData.categories,
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          },
          title: {
            text: "No. of students",
            style: {
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
      };

      const chart = new ApexCharts(document.querySelector("#vertical-my-custom-chart"), options);
      chart.render();

      return () => {
        chart.destroy(); // Clean up chart on component unmount
      };
    }, [whiteSpaceData]);

    return <div id="vertical-my-custom-chart"></div>;
  };

  const CommonYAxis = ({ categories }) => {
    const reversedCategories = [...categories].reverse();

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80px',  // Increased width for better visibility
        padding: '0 10px',  // Add padding to ensure the text does not touch the edges
      }}>
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {reversedCategories.map((category, index) => (
            <li key={index} style={{ margin: '2.5rem 0', textAlign: 'right', whiteSpace: 'nowrap' }}>
              {category}
            </li>
          ))}
        </ul>
        <div>
          <Typography sx={{ marginTop: "1rem", fontSize: "12px", textAlign: 'right', whiteSpace: 'nowrap', color: Color.rdcolor }}>Resume Diamond Score Analysis</Typography>
        </div>
      </div>
    );
  };

  console.log("resumesDigitalCompletionScore", resumesDigitalCompletionScore);

  const generateExcel = async (process_id) => {
    try {
      setDownloadLoader(true)
      // Fetch data from the endpoint using the process_id
      const res = await service.get(`/analytics/user/alResumeuserget?process_id=${process_id}`);
      const data = res.data;
      console.log("analytics data response:", data);

      if (!data || data.length === 0) {
        console.log("No data available to generate the Excel file.");
        return;
      }

      // Filter out any entries that do not have necessary data
      const filteredData = data.filter(item => Object.keys(item).length > 1);

      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Mentor Feedback');

      // Create the header row with the required headers
      const headerRow = [
        'Sr. No', 'First Name', 'Last Name', 'Student Full Name', 'Roll No', 'Email ID',
        'Total Work-Ex Months', 'Master Resume Word-Count', 'Completion %age',
        'Completion %age Range', 'Resume Quality (Diamond Score)', 'Resume Quality Comment',
        'Vertical Whitespace %age', 'Horizontal Whitespace %age', 'Overall Whitespace %age',
        'Vertical Whitespace Range', 'Horizontal Whitespace Range', 'Overall Whitespace Range', 'Alert Score', 'Master Resume URL'
      ];
      worksheet.addRow(headerRow);

      // Add data rows
      filteredData.forEach((item, index) => {
        const rowData = [
          index + 1,  // Sr. No
          item.first_name, item.last_name, item.name, item.roll_no, item.email_id,
          item.workex_total, item.word_count, item.percentage,
          item.completion_bins, item.resume_quality, item.quality_bins,
          item.verticalWhiteSpace, item.horizontalWhiteSpace, item.overallWhitespace,
          item.verticalWhiteSpace_bins, item.horizontalWhiteSpace_bins, item.overallWhitespace_bins, item['alert score'], item.url
        ];
        worksheet.addRow(rowData);
      });

      // Generate the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 
        `${college_name}_${batch_name}_"Resume Analytics"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      link.click();
      setDownloadLoader(false)
    } catch (err) {
      setDownloadLoader(false)
      console.error("Error generating Excel file:", err);
      window.alert(err.response.data.message)
    }
  };





  return (
    <div>
      {interviewWiseSkeletonLoader ? (
        <>
          <BatchTrackerSkeleton />
        </>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", marginBottom: "2rem", width: fromResumesTemplatePath ? "80%" : "100%", margin: "0 auto" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", marginTop: "1.5rem" }}>
              <Box sx={{ height: "2px", width: "100%", background: "#E88F09" }}></Box>
              <Typography variant="h1" sx={{ color: "#E88F09", textAlign: "center", fontWeight: "600", width: "800px", letterSpacing: "2.5px" }}>
                Resumes.Digital
              </Typography>
              <Box sx={{ height: "2px", width: "100%", background: "#E88F09" }}></Box>
            </Box>
            {fromResumesTemplatePath && (
              <Box>
                <button
                  className="submitBtn"
                  type="button"
                  style={{ height: "44px", bottom: 0, width: "200px", paddingLeft: "0.5rem", paddingRight: '0.5rem', background: Color.rdcolor }}
                  onClick={() => generateExcel(process_id)}
                >
                  {downloadLoader ? <CircularProgress sx={{ color: "#fff"}} size={15} /> : "Download CSV"}
                </button>
              </Box>
            )}

            <Box borderRadius={5} sx={{ border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }}>
              <Typography variant="h2" style={{ fontWeight: "bold", textAlign: "center", marginBottom: "1rem" }}>
                {college_name} | {batch_name}
              </Typography>
              <Typography variant="body1" style={{ color: "#FF3131", textAlign: "center", fontSize: "10px" }}>
                DISCLAIMER:
              </Typography>
              <Typography sx={{ textAlign: "center", fontSize: "10px" }}>All information in these reports are provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind. All quantifications or comments in all reports have no bearing on actual performance of any individual or group. These scores may vary from 1 resume to another. The company will not be liable to you or anyone else for any decision made or action taken in reliance on the information given by these reports or for any consequential, special or similar damages, even if advised of the possibility of such damages.</Typography>
            </Box>
            <Box
              height="auto"
              borderRadius={5}
              sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)" }}
            >
              <Typography variant="h4" sx={{ textAlign: "left", width: "800px", marginBottom: ".5rem" }}>
                Resume Completion Stats
              </Typography>
              <BarChart resumesDigitalCompletionScore={resumesDigitalCompletionScore} />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }} borderRadius={5}>
              <Box><Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", marginBottom: ".5rem" }}>
                Quality of Resumes
              </Typography>
                <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".3rem", marginBottom: ".5rem" }}>
                  Resume diamond scores of the entire batch have been divided into 5 segments for freshers and work-ex students separately
                </Typography>
              </Box>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <HorizontalBarChartLTR data={freshersData} categories={freshersCategories} />
                <Box sx={{ width: 'auto' }}><CommonYAxis categories={freshersCategories} /></Box>
                <HorizontalBarChartRTL data={workexData} categories={workexCategories} />
              </div>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }} borderRadius={5}>
              <Box><Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", marginBottom: ".5rem" }}>
                Sections where the batch is struggling
              </Typography>
                <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".3rem", marginBottom: ".5rem" }}>
                  Sections displayed below have the highest degree of bullet errors in terms of missing information, hygiene discrepancies, quantification etc.
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <StrugglersChartData resumesDigitalCompletionScore={chartData} />

                <div id="html-dist"></div>
              </div>
            </Box>

            <Box display="flex" gap="1.5rem" width="100%">
              <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)", width: "100%" }}>
                <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", marginBottom: ".5rem" }}>
                  Overall Whitespace
                </Typography>
                <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".3rem", marginBottom: ".5rem" }}>
                  On an average, what is the overall whitespace found on resumes of the batch
                </Typography>
                <Box style={{ marginTop: "3rem", position: "relative" }}>
                  <InflationChart whiteSpaceData={overallWhiteSpaceData} />
                </Box>
              </Box>
            </Box>

            <Box display="flex" gap="1.5rem" width="100%">
              <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)", width: "50%" }}>
                <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", width: "800px", marginBottom: ".5rem" }}>
                  Horizontal Whitespace
                </Typography>
                <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".3rem", marginBottom: ".5rem" }}>
                  On an average, the horizontal whitespace percentage found on resumes of the batch. Horizontal whitespace is empty sentence area after each bullet
                </Typography>
                <HorizontalWhiteSpaceChart whiteSpaceData={horizontalWhiteSpaceData} />
              </Box>
              <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)", width: "50%" }}>
                <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", width: "800px", marginBottom: ".5rem" }}>
                  Vertical Whitespace
                </Typography>
                <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".3rem", marginBottom: ".5rem" }}>
                  On an average, the vertical whitespace percentage found on resumes of the batch. Vertical whitespace is empty page area on the page after all bullets
                </Typography>
                <VerticalWhiteSpaceChart whiteSpaceData={verticalWhiteSpaceData} />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default ResumesDigitalAnalytics;
