import React from 'react'

function CollegeAdminLevels() {
  return (
    <div style={{marginBlock:"1rem"}}>
      Animeshh
    </div>
  )
}

export default CollegeAdminLevels
