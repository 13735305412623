import React, { useState, useEffect } from 'react'
import { Container, Typography, Card } from "@mui/material";
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import CardContent from '@mui/material/CardContent';
import Menu from "@mui/material/Menu";
import { Color } from '../../../GlobalStyles'
import Service from '../../../services/httpService';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import CircularProgress from '@mui/material/CircularProgress';

const MentorStatus = (props) => {
  const service = new Service()
  const navigate = useNavigate();
  const { activityTypeData, roleTypeData } = props
  console.log("activity types", activityTypeData, roleTypeData);
  const [mentorData, setMentorData] = useState([])
  const [mentorApprovalAwaitedData, setMentorApprovalAwaitedData] = useState([])
  const [mentorAllData, setMentorAllData] = useState([])
  const [mentorNotActivatedData, setMentorNotActivatedData] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Brother mentor")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      let mentor = await mentorDataGetter();
      let approvalAwaited = await mentorApprovalAwaited();
      let allDetails = await mentorAllDetails();
      let notActivatedList = await mentorNotActivated()
      setMentorData(mentor)
      setMentorApprovalAwaitedData(approvalAwaited)
      setMentorAllData(allDetails)
      setMentorNotActivatedData(notActivatedList)
      setLoading(false)
    }
    handleEffect();
  }, [])

  console.log("bhoomika", mentorData, mentorApprovalAwaitedData, mentorAllData, mentorNotActivatedData);

  const mentorDataGetter = async () => {
    try {
      const res = await service.get("/getMentorStatus")
      console.log("mentor details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const mentorApprovalAwaited = async () => {
    try {
      const res = await service.get("/mentor/approvalAwaited")
      console.log("mentor details response:", res.mentorApprovalAwaited)
      return res.mentorApprovalAwaited
    } catch (err) {
      console.log(err)
    }
  }

  const mentorAllDetails = async () => {
    try {
      const res = await service.get(`/mentor/allDetails`)
      console.log("student details response:", res.mentorList)
      return res.mentorList
    } catch (err) {
      console.log(err)
    }
  }

  const mentorNotActivated = async () => {
    try {
      const res = await service.get(`/mentor/notActivated`)
      console.log("student details response:", res.getNotActivatedMentor)
      return res.getNotActivatedMentor
    } catch (err) {
      console.log(err)
    }
  }
  console.log("mentor all data", mentorAllData);
  console.log("mentor all data", mentorNotActivatedData);
  console.log("mentor all data for approval awaiteds", mentorApprovalAwaitedData);
  const handleNavigation = (index) => {
    if (index === 0) {
      navigate("/MentorDetails", { state: { detailsToShow: mentorAllData, activityTypeData: activityTypeData, roleTypeData: roleTypeData, mentorApprovalAwaitedData: mentorApprovalAwaitedData, mentorNotActivatedData: mentorNotActivatedData ,  searching:"Active"} })
    } else if (index === 1) {
      navigate("/MentorDetails", { state: { detailsToShow: mentorNotActivatedData, searching:"Not activated" } })
    } else if (index === 2) {
      navigate("/MentorData", { state: { detailsToShow: mentorApprovalAwaitedData} })
    }
  }

  return (
    <div className="cardsInsideFlex">
      {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto', marginLeft: "40%" }}><CircularProgress size={24} color="inherit" /></div> : <>
        {Object.entries(mentorData).map(([key, value], index) => {
          let displayValue = value;
          let textColor = "";
          if (index === 1) {
            const valueAtIndex0 = mentorData[Object.keys(mentorData)[0]];
            displayValue = value - valueAtIndex0;
          }
          if (index === 0) {
            textColor = Color.green;
          } else if (index === 1) {
            textColor = Color.primary1;
          } else {
            textColor = Color.accent;
          }
          return (
            <Card
              key={key}
              sx={{
                width: 200,
                marginRight: '1rem',
                marginLeft: '.2rem',
                marginBottom: '.2rem',
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
                borderRadius: "10px",
                border: 'none'
              }}
            >
              <CardContent onClick={() => handleNavigation(index)} style={{ cursor: "pointer" }} >
                <Typography sx={{ fontSize: 16 }} align="center" variant="body1">
                  {index === 0 ? 'Activated' : index === 1 ? "Not Activated" : index === 2 ? "Approval Awaited" : key}
                </Typography>
                <p className='topHeadings1' style={{ color: textColor }} align="center" variant="body1">
                  {displayValue}
                </p>
              </CardContent>
            </Card>
          );
        })}
      </>}

    </div>
  )
}

export default MentorStatus
