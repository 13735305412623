import axios from "axios";
import {
    saveAs
} from "file-saver";
import Cookies from 'js-cookie';
import React, {
    useEffect,
    useState
} from "react";
import { useNavigate } from "react-router-dom";
import Service from '../../../services/httpService';
import Loader from "../../reusables/Loader/Loader";
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
import TopBar from '../../reusables/TopBar/Topbar';
import {
    UserTypeContext as RowsContext
} from '../RowsContext/RowsContext';
import FeedbackAndMcq from "./FeedbackAndMcq";
const ActivityType = () => {
    const navigate = useNavigate();
    const [showFeedbackModal, setShowFeedbackModal] = useState({modalOpen :false, act_type_id : null, act_type_name : ""})
    const [rowsData, setRowsData] = useState([{
        id: null,
        act_type_name: "",
        type: "",
        description: ""
    }])
    const [selectedFiles, setSelectedFiles] = useState([]);
    //get current date and time
    const currentDate = new Date();

    const service = new Service()
    let count = 0;
    const headCells = [{
        id: 'heading1',
        numeric: 'left',
        disablePadding: false,
        label: 'Activity Type Name',
    },
    {
        id: 'heading2',
        numeric: 'right',
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'heading3',
        numeric: 'right',
        disablePadding: false,
        label: 'Description',
    }
    ];
    const [loading, setLoading] = useState(true);

    const handleFeedbackNavigation = (act_type_id, act_type_name) => {
        setShowFeedbackModal((prev) => ({ ...prev, modalOpen: true, act_type_id: act_type_id, act_type_name:act_type_name}));
    }

    const handleDeleteMul = (id, index) => {
        console.log("delete", id)
        let list = [...rowsData];

        console.log("listtttt1", list);


        console.log('hey bhooms')
        service
            .delete("/activityType", {
                data: {
                    "activity_type_ids": id
                }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list = list.filter((item) => id.indexOf(item.id) < 0);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);

                console.log(err.response.data.message)
                window.alert(err.response.data.message)

            });
    }

    const data_getter = (id, name, type, description, index, files, studentFiles, action, user_type, fileType, fileIndex, imageFiles, durations) => {
        console.log(name, "You are inside the data_getter");
        console.log("ID is :", id);
        console.log("Name is :", name);
        console.log("TYpe is:", type);
        console.log("descriptijn is:", description);
        console.log("index is :", index)
        console.log("files", files);
        console.log("studentFiles", studentFiles);
        console.log("image files in AT", imageFiles);
        console.log("Duration", durations);
        //id here is the name, name is the id , type is the index

        if (action === "edit" || action === "view") {
            return (rowsData[index])
        } else if (action === "create") {
            return ({})
        } else if (action === "delete") {
            handleDeleteFile(rowsData[index], user_type, fileType, fileIndex, index)
        } else if (action === "imageDelete") {
            handleImageDelete(rowsData[index], index)
        } else if (name === "delete_mul") {
            handleDeleteMul(id, index)
        } else if (id === undefined) {
            console.log("ID received opening delete request")
            handleDelete(name, type); //delete requset here

        } else if (id === "" && index === "") {

            console.log("All conditions passed , Sending the request")
            handleFormSubmission(name, type, description, files, studentFiles, imageFiles, durations); //POST requset here
        } else if (action === "put") {
            console.log("handlePutRequest opened");
            handlePutRequest(id, name, type, description, index, files, studentFiles, imageFiles, durations);

        }






    }





    // get request
    useEffect(() => {
        console.log("Get request")

        function handleEffect() {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            service
                .get("/activityType")
                .then((res) => {
                    console.log();
                    console.log("Response from backend is", JSON.stringify(res, null, 2))
                    setRowsData(res.data)
                    setLoading(false)
                    // const previous_mentor_data = res.data[rowsData.].mentor_material
                    // const previous_student_data =res.data[0].student_material
                    // console.log("mentor data",previous_mentor_data);
                    // console1.log("Student data",previous_student_data);

                    console.log("Rows data from the GET Response is", rowsData);
                })
                .catch((err) => {
                    console.log(err)
                })
                
        }
        handleEffect();
    }, [])


    // delete file request
    const handleDeleteFile = (data, type, fileType, fileIndex, index) => {
        console.log("dattttaaaa", data);
        let temp = type === "mentor" ? "mentor_material" : "student_material";
        // let temp_data = fileType==="pdf"?data[temp].pdf:fileType==="audio"?data[temp].audio:data[temp].video
        // console.log("dataaaaaaaaaa", JSON.parse(data[temp]), fileType, type)
        if (data !== undefined) {
            if (JSON.parse(data[temp])[fileType].length > fileIndex && (JSON.parse(data[temp])[fileType][fileIndex]?.version_id) !== undefined && (JSON.parse(data[temp])[fileType][fileIndex]?.version_id) !== null) {
                service
                    .delete("/activityType/file", {
                        data: {
                            "activity_type_id": data.id,
                            "material": type,
                            "file_type": fileType,
                            "version_id": JSON.parse(data[temp])[fileType][fileIndex].version_id
                        }
                    })
                    .then((res) => {
                        console.log("Response from backend is", res);
                        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                        let list = [...rowsData]
                        let temp_data = JSON.parse(list[index][temp])
                        temp_data[fileType] = temp_data[fileType].filter((row, index) => index !== fileIndex)
                        list[index][temp] = JSON.stringify(temp_data)
                        setRowsData(list)
                        console.log("listtttt2", list);

                    })
                    .catch((err) => {
                        console.log("Errog Msg: ", err);

                        console.log(err.response.data.message)
                        window.alert(err.response.data.message)

                    });
            }
            else {
                let list = [...rowsData]
                let temp_data = JSON.parse(list[index][temp])
                temp_data[fileType] = temp_data[fileType].filter((row, index) => index !== fileIndex)
                list[index][temp] = JSON.stringify(temp_data)
                setRowsData(list)
            }
        }

    }


    const handleImageDelete = async (data, index) => {
        console.log("deleting the image file now", data.id)
        let list = [...rowsData];
        service
            .delete("/activityType/image", {
                data: {
                    "activity_type_id": data.id
                }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list[index].image_url = null
                setRowsData(list)
                console.log("Sented the image delete response")


            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
    }





    // post request
    const handleFormSubmission = async (name, type, description, files, studentFiles, imageFiles, durations) => {
        console.log("Durtions inside post req:", durations)
        console.log("Inside the post Request", files);
        let temp_mentor_data = {
            "pdf": [],
            "audio": [],
            "video": []
        }
        let temp_student_data = {
            "pdf": [],
            "audio": [],
            "video": []
        }
        let temp = []
        let temp_image_data = null
        service
            .post("/activityType/data", {
                act_type_name: name,
                type: type,
                description: description,
                durations: durations
            }) //POST
            .then(async (res) => {
                console.log("POST-Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                temp = [{
                    id: res.data.activity_type_id,
                    act_type_name: name,
                    type: type,
                    allowed_durations: JSON.stringify({ durations }),
                    description: description,
                    mentor_material: files,
                    student_material: studentFiles,
                    image_url: imageFiles
                }, ...rowsData]

                const response_id = res.data.activity_type_id

                // image upload
                if (imageFiles !== undefined && imageFiles !== null) {
                    const blob_image = new Blob([imageFiles], {
                        type: "image/jpeg"
                    });

                    const data = new FormData();
                    data.append("image$$" + `${imageFiles?.name}`, blob_image, {
                        filepath: blob_image,
                        contentType: 'multipart/form-data',
                    });

                    const headers = {
                        "Content-Type": "multipart/form-data"
                    }
                    await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/activityType/file?activity_type_id=${response_id}`, data, {
                        headers
                    })
                        .then((res) => {
                            console.log({
                                res
                            });
                            temp_image_data = res.data.filesInfo[0].AWSpath
                        })
                        .catch((err) => {
                            console.log("Errog Msg: ", err);
                            window.alert(err.response.data.message)
                        });
                }



                console.log({
                    files
                });
                console.log({
                    studentFiles
                });



                // mentor files upload
                for (var i in files) {
                    for (var j = 0; j < files[i].length; j++) {
                        let pdfFile;
                        if (i === "pdf") {
                            pdfFile = new Blob([files[i][j]], {
                                type: 'application/pdf'
                            });
                        } else if (i === "audio") {
                            pdfFile = new Blob([files[i][j]], {
                                type: 'audio/mpeg'
                            });
                        } else {
                            pdfFile = new Blob([files[i][j]], {
                                type: 'video/mp4'
                            });
                        }
                        const data = new FormData();
                        // const name = "mentor_"+`${i}`;
                        data.append("mentor$$" + `${files[i][j].name}`, pdfFile, {
                            filepath: pdfFile,
                            contentType: 'multipart/form-data',
                        });

                        // console.log("file data", response_id, files[i]);
                        const headers = {
                            "Content-Type": "multipart/form-data"

                        }
                        await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/activityType/file?activity_type_id=${response_id}`, data, {
                            headers
                        })
                            .then((res) => {
                                console.log({
                                    res
                                });
                                temp_mentor_data[i].push({
                                    "name": res.data.filesInfo[0].file_name,
                                    "version_id": res.data.filesInfo[0].version_id
                                })
                            })
                            .catch((err) => {
                                console.log("Errog Msg: ", err);
                                // if (err.response.status === 400) {
                                //     console.log("400 Error message")
                                // }
                                window.alert(err.response.data.message)
                            });
                    }
                }
                // studentFiles upload
                for (var i in studentFiles) {
                    for (var j = 0; j < studentFiles[i].length; j++) {
                        let pdfFile;
                        if (i === "pdf") {
                            pdfFile = new Blob([studentFiles[i][j]], {
                                type: 'application/pdf'
                            });
                        } else if (i === "audio") {
                            pdfFile = new Blob([studentFiles[i][j]], {
                                type: 'audio/mpeg'
                            });
                        } else {
                            pdfFile = new Blob([studentFiles[i][j]], {
                                type: 'video/mp4'
                            });
                        }
                        const data = new FormData();
                        // const name = "mentor_"+`${i}`;
                        data.append("student$$" + `${studentFiles[i][j].name}`, pdfFile, {
                            filepath: pdfFile,
                            contentType: 'multipart/form-data',
                        });

                        console.log("file data", response_id, studentFiles[i]);
                        const headers = {
                            "Content-Type": "multipart/form-data"

                        }
                        await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/activityType/file?activity_type_id=${response_id}`, data, {
                            headers
                        })
                            .then((res) => {
                                console.log({
                                    res
                                });
                                console.log({
                                    res
                                });
                                temp_student_data[i].push({
                                    "name": res.data.filesInfo[0].file_name,
                                    "version_id": res.data.filesInfo[0].version_id
                                })
                            })
                            .catch((err) => {
                                console.log("Errog Msg: ", err);
                                // if (err.response.status === 400) {
                                //     console.log("400 Error message")
                                // }
                                window.alert(err.response.data.message)
                            });
                    }

                }

                // let temp_data = { ...files }
                // Object.keys(temp_data).map(key => {
                //     temp_data[key].map((item, index) => {
                //         temp_data[key][index] = { "name": item.name }
                //     })
                // })
                temp[0].image_url = temp_image_data
                temp[0].mentor_material = JSON.stringify(temp_mentor_data)
                // temp_data = { ...studentFiles }
                // Object.keys(temp_data).map(key => {
                //     temp_data[key].map((item, index) => {
                //         temp_data[key][index] = { "name": item.name }
                //     })
                // })
                // temp = [{ id: res.data.activity_type_id, act_type_name: name, description: description, type: type, mentor_material: files, student_material: studentFiles }, ...rowsData]
                temp[0].student_material = JSON.stringify(temp_student_data)

                setRowsData(temp)



                console.log("response id is:", res.success);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                // if (err.response.status === 400) {
                //     console.log("400 Error message")
                //     window.alert("400 Error message")
                // }

            });

    }




    // files upload


    // file upload



    const handlePutRequest = async (id, name, type, description, index, updatedMentorForm, updatedStudentForm, updatedImage, durations) => {
        console.log("handlePutRequest ~ durations", durations)

        let put_mentor_data = JSON.parse(rowsData[index].mentor_material)
        let put_student_data = JSON.parse(rowsData[index].student_material)
        let temp_image_data = rowsData[index].image_url
        console.log({ put_mentor_data })
        service
            .put("/activityType/data", {
                activity_type_id: id,
                act_type_name: name,
                type: type,
                durations: durations,
                description: description
            }) //PUT
            .then(async (res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                // return (res.data.success)
                let temp = [...rowsData]
                temp[index].act_type_name = name
                temp[index].description = description
                temp[index].type = type
                temp[index].allowed_durations = JSON.stringify({ durations })


                // image upload
                if (updatedImage !== undefined && updatedImage !== null) {
                    const data = new FormData();
                    data.append("image$$" + `${updatedImage?.name}`, updatedImage, {
                        filepath: updatedImage,
                        contentType: 'multipart/form-data',
                    });

                    const headers = {
                        "Content-Type": "multipart/form-data"
                    }
                    await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/activityType/file?activity_type_id=${id}`, data, {
                        headers
                    })
                        .then((res) => {
                            console.log({
                                res
                            });
                            temp_image_data = res.data.filesInfo[0].AWSpath
                            // image url is can be generated only with the get request!!!!! this doen't work
                        })
                        .catch((err) => {
                            console.log("Errog Msg: ", err);
                            window.alert(err.response.data.message)
                        });
                }


                // mentor files upload
                for (var i in updatedMentorForm) {
                    for (var j = 0; j < updatedMentorForm[i].length; j++) {
                        let pdfFile;
                        if (i === "pdf") {
                            pdfFile = new Blob([updatedMentorForm[i][j]], {
                                type: 'application/pdf'
                            });
                        } else if (i === "audio") {
                            pdfFile = new Blob([updatedMentorForm[i][j]], {
                                type: 'audio/mpeg'
                            });
                        } else {
                            pdfFile = new Blob([updatedMentorForm[i][j]], {
                                type: 'video/mp4'
                            });
                        }
                        const data = new FormData();
                        // const name = "mentor_"+`${i}`;
                        data.append("mentor$$" + `${updatedMentorForm[i][j].name}`, pdfFile, {
                            filepath: pdfFile,
                            contentType: 'multipart/form-data',
                        });


                        const headers = {
                            "Content-Type": "multipart/form-data"

                        }
                        await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/activityType/file?activity_type_id=${id}`, data, {
                            headers
                        })
                            .then((res) => {
                                console.log({
                                    res
                                });
                                put_mentor_data[i].push({ version_id: res.data.filesInfo[0].version_id, name: res.data.filesInfo[0].file_name })
                            })
                            .catch((err) => {
                                console.log("Errog Msg: ", err);
                                // if (err.response.status === 400) {
                                //     console.log("400 Error message")
                                // }
                                window.alert(err.response.data.message)
                            });
                    }

                }

                // studentFiles
                for (var i in updatedStudentForm) {
                    for (var j = 0; j < updatedStudentForm[i].length; j++) {
                        let pdfFile;
                        if (i === "pdf") {
                            pdfFile = new Blob([updatedStudentForm[i][j]], {
                                type: 'application/pdf'
                            });
                        } else if (i === "audio") {
                            pdfFile = new Blob([updatedStudentForm[i][j]], {
                                type: 'audio/mpeg'
                            });
                        } else {
                            pdfFile = new Blob([updatedStudentForm[i][j]], {
                                type: 'video/mp4'
                            });
                        }
                        const data = new FormData();
                        // const name = "mentor_"+`${i}`;
                        data.append("student$$" + `${updatedStudentForm[i][j].name}`, pdfFile, {
                            filepath: pdfFile,
                            contentType: 'multipart/form-data',
                        });

                        console.log("file data", id, updatedStudentForm[i]);
                        const headers = {
                            "Content-Type": "multipart/form-data"
                        }
                        await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/activityType/file?activity_type_id=${id}`, data, {
                            headers
                        })
                            .then((res) => {
                                console.log({
                                    res
                                });
                                console.log("version idssss", res.data.filesInfo[0].version_id);

                                put_student_data[i].push({ version_id: res.data.filesInfo[0].version_id, name: res.data.filesInfo[0].file_name })

                            })
                            .catch((err) => {
                                console.log("Errog Msg: ", err);
                                // if (err.response.status === 400) {
                                //     console.log("400 Error message")
                                // }
                                window.alert(err.response.data.message)
                            });
                    }
                }



                // let temp_row_data = {
                //     ...JSON.parse(rowsData[index].mentor_material)
                // }
                // let temp_data = {
                //     ...updatedMentorForm
                // }
                // Object.keys(temp_data).map(key => {
                //     temp_data[key].map((item) => {
                //         temp_row_data[key].push({
                //             "name": item.name,
                //             "vesion_id": put_mentor_data
                //         })
                //     })
                // })
                temp[index].mentor_material = JSON.stringify(put_mentor_data)



                // let temp_rows_data = {
                //     ...JSON.parse(rowsData[index].student_material)
                // }
                // let temps_data = {
                //     ...updatedStudentForm
                // }
                // Object.keys(temps_data).map(key => {
                //     temps_data[key].map((item) => {
                //         temp_rows_data[key].push({
                //             "name": item.name,
                //             "version_id": put_student_data
                //         })
                //     })
                // })
                temp[index].student_material = JSON.stringify(put_student_data)
                temp[index].image_url = temp_image_data

                // temp_data = { ...updatedStudentForm }
                // Object.keys(temp_data).map(key => {
                //     temp_data[key].map((item, index) => {
                //         temp_data[key][index] = { "name": item.name }
                //     })
                // })
                // temp[index].student_material = JSON.stringify(temp_data)
                setRowsData(temp)


            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                // if (err.response.status === 400) {
                //     console.log("400 Error message")
                // }
            });
    }

    // delete request
    const handleDelete = async (id, index) => {
        console.log("delete", id)
        let list = [...rowsData];

        console.log("listtttt1", list);


        console.log('hey bhooms')
        service //DELETE
            .delete("/activityType", {
                data: {
                    "activity_type_ids": [id]
                }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                // console.log("Errog Msg: ", err);
                // if (err?.response.status === 400) {
                //     console.log("400 Error message")
                // }
            });
    }

    const Delete = async (id, index) => {
        console.log("delete", id)
        let list = [...rowsData];

        console.log("listtttt1", list);


        console.log('hey bhooms')
        service
            .delete("/activityType/image", {
                data: {
                    "activity_type_id": id
                }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                // if (err?.response.status === 400) {
                //     console.log("400 Error message")
                // }
            });
    }



    const handleFileDelete = async (id, index) => {
        console.log("delete", id)
        let list = [...rowsData];

        console.log("listtttt1", list);


        console.log('hey bhooms')
        service
            .delete("/superadmin/activityType", {
                data: {
                    "activity_type_id": id
                }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                // if (err?.response.status === 400) {
                //     console.log("400 Error message")
                // }
            });
    }



    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", rowsData);
        // Simulate a button click after 2 seconds
        const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


    }

    console.log("Rows data form speilixation  Page is:", rowsData);


    return (
        <div>
            {console.log({ rowsData })}
            <RowsContext.Provider value={rowsData} >
                <TopBar />
                <SideNavigationBar />
                {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
                <
                    EnhancedTable path={
                        window.location.pathname
                    }
                    handleFeedbackNavigation={handleFeedbackNavigation}
                    data_getter={
                        data_getter
                    }
                    headCells={
                        headCells
                    }
                    count={
                        count
                    }
                    fileDownload={
                        fileDownload
                    }
                /> 
                </>}
                </RowsContext.Provider>
            {showFeedbackModal.modalOpen && 
            <FeedbackAndMcq
            open={showFeedbackModal.modalOpen}
            setOpen={() => setShowFeedbackModal((prev) => ({...prev, modalOpen:false}))} act_type_id={showFeedbackModal.act_type_id} act_type_name={showFeedbackModal.act_type_name}/>}
            
        </div>
    )
}

export default ActivityType