import { Close, NotificationsOutlined } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, IconButton, Modal, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Color } from "../../GlobalStyles";
// import { convertEpochToNormalTime, ddmmm } from "../../../../services/specialCharReplace";
// import { motion } from "framer-motion";
function GroupSessionDateAndTime(props) {

    let previousdate = new Date().toISOString().slice(0, 10);

    const navigate = useNavigate();
    let previousTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

    let timeCheck = previousTime.split(":");
    if (timeCheck[0].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }
    if (timeCheck[1].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }

    const [value, setValue] = useState(dayjs(previousdate));
    const [postLaterValue, setPostLaterValue] = useState(dayjs(previousdate));
    const [postLaterTime, setPostLaterTime] = useState("12:00");

    const [ampm, setAmpm] = useState("AM");

    const [time, setTime] = useState(previousTime);

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [alertModal, setAlertModal] = useState({
        open: false,
        onClose: () => setAlertModal((prev) => ({ ...prev, open: false })),
    });

    const [postLater, setPostLater] = useState(false);
    const [selectedType, setSelectedType] = useState("deadline");
    const [postLaterAmPm, setPostLaterAmPm] = useState("AM");



    const handleAmPm = (val) => {
        let timeCheck = time.split(":");
        if (val === "PM" && parseInt(timeCheck[0]) < 12) {
            let newTime = `${(parseInt(timeCheck[0]) + 12) % 24}:${timeCheck[1]}`;
            setTime(newTime);
            setAmpm("PM");
        } else {
            let hour = parseInt(timeCheck[0]) % 12;
            hour = JSON.stringify(hour).length === 2 ? hour : `0${hour}`;
            let newTime = `${hour}:${timeCheck[1]}`;
            console.log("NEW TIME", newTime);
            setAmpm("AM");
            setTime(newTime);
        }
    };

    const handlePostLaterAMPM = (val) => {
        let timeCheck = postLaterTime.split(":");
        if (val === "PM" && parseInt(timeCheck[0]) < 12) {
            let newTime = `${(parseInt(timeCheck[0]) + 12) % 24}:${timeCheck[1]}`;
            setPostLaterTime(newTime);
            setPostLaterAmPm("PM");
        } else {
            let hour = parseInt(timeCheck[0]) % 12;
            hour = JSON.stringify(hour).length === 2 ? hour : `0${hour}`;
            let newTime = `${hour}:${timeCheck[1]}`;

            setPostLaterAmPm("AM");
            setPostLaterTime(newTime);
        }
    };

    const handleScheduleLater = () => {
        const date = new Date(`${value["$d"].getFullYear()}-${value["$d"].getUTCMonth() + 1}-${value["$d"].getDate()} ${time}`);
        const epochTime = date.getTime();
        props.setGroupSessionsDetails((prev) => {
            return {
                ...prev,
                date_time: epochTime,
            };

        })
        props.handleModalClose()
    };

    useEffect(() => {
        if (parseInt(time.substring(0, 2)) > 11) {
            setAmpm("PM");
        } else {
            setAmpm("AM");
        }
    }, [time, value]);

    return (
        <Box>
            <Modal open={props.modalOpen} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ width: "50%", background: "#fff", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "10px", padding: "16px" }}>
                    {/* BOX 1 FOR DEADLINE */}
                    <Accordion expanded={selectedType === "deadline" ? true : false} onChange={() => setSelectedType((prev) => (prev === "deadline" ? "" : "deadline"))} sx={{ margin: 0, borderRadius: "8px", border: "none" }}>
                        <AccordionSummary>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography sx={{ fontSize: "18px" }}>Choose Date and Time</Typography>
                                <Typography sx={{ color: Color.primary1, fontSize: "16px" }}>
                                    {value["$d"].getDate()} {months[value["$d"].getUTCMonth()]} {time} {ampm}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ width: "45%", background: "#165983", borderRadius: "0px 0px 0px 0px", padding: "12px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                                        <Box>
                                            <Typography sx={{ color: "#fff", fontSize: "20px" }}>{value["$d"].getFullYear()}</Typography>
                                            <Typography></Typography>
                                            <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                                                {days[value["$d"].getDay()]}, {months[value["$d"].getUTCMonth()]} {value["$d"].getDate()}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <TextField
                                                type="time"
                                                value={time}
                                                InputProps={{
                                                    sx: {
                                                        height: "74px",
                                                        width: "fit-content",
                                                        m: 0,

                                                        fontSize: "30px",
                                                        color: "#fff",
                                                        borderRadius: "10px 0px 0px 10px",

                                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            border: "none",
                                                            borderRadius: "5px 5px 0 0",
                                                        },
                                                    },
                                                    endAdornment: null,
                                                }}
                                                onChange={(e) => setTime(e.target.value)}
                                                sx={{
                                                    background: "#165983",
                                                    border: "1px solid #fff",
                                                    borderRight: "none",
                                                    borderRadius: "15px 0px 0px 15px",
                                                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                                                        filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                                                        color: "#165983",
                                                    },
                                                    "& .MuiFilledInput-root": {
                                                        background: "rgb(232, 241, 250)",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                    "&:hover fieldset": {
                                                        border: "none",
                                                        borderColor: "none!important",
                                                    },
                                                }}
                                            />
                                            <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                                                <Typography onClick={() => handleAmPm("AM")} sx={{ fontSize: "14px", padding: "5px", background: ampm === "AM" ? "#fff" : "#165983", color: ampm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                                                    AM
                                                </Typography>
                                                <Typography onClick={() => handleAmPm("PM")} sx={{ fontSize: "14px", padding: "5px", background: ampm === "PM" ? "#fff" : "#165983", color: ampm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                                                    PM
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "55%" }}>
                                        <Box sx={{ height: "320px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "12px", color: "#165983" }}>{day}</Typography>} disablePast />
                                            </LocalizationProvider>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px", width: "100%" }}>
                        <Button sx={{ width: "40%", padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }} onClick={() => props.handleModalClose()}>
                            Cancel
                        </Button>
                        <Button sx={{ width: "40%", padding: "8px 15px", borderRadius: "15px", background: Color.primary1, color: "#fff", border: `1px solid ${Color.primary1}`, "&:hover": { background: Color.primary1, color: "#fff" } }} onClick={() => handleScheduleLater()}>
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default GroupSessionDateAndTime;
