import React, { useEffect, useState } from 'react'
import { SmallPageLoader } from '../../../reusables/Loader/Loader';
import { DivCenter, Color, styles } from '../../../../GlobalStyles';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { ArrowRight, PlayArrow, Search, TrendingFlat } from '@mui/icons-material';
import { MentorStatsListView, StudentStatsListView } from './StatsListViews';
import StudentMentorDetailsModule from './StudentMentorDetailsModule';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace';
import { AvailableSlotsModal, ChooseMentorModal, SwapModal } from './ReleaseNextListModals';
import { DialogueBox } from '../../../Reusable_functions/DialogueBox/DialogueBox';

const ReleaseNextList = ({ releaseNextListData, getStatLists, process_id, onStepChange }) => {
    const service = new CCService();
    const [loading, setLoading] = useState({ page: false });
    const [dialogue, setDialogue] = useState({
        open: false,
        onClose: () => { setDialogue((prev) => ({ ...prev, open: false })); },
        content: "",
        actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
    });
    const [SMDcurrentPage, setSMDCurrentPage] = useState(1);
    const [swapped_student, setSwappedStudent] = useState();
    //data varibale
    const [listCount, setListCount] = useState([]);
    const [studentStatList, setStudentStatList] = useState([]);
    const [mentorStatList, setMentorStatList] = useState([]);
    const [waitingSwapped, setWaitingSwapped] = useState([]);
    const [markAsDone, setMarkAsDone] = useState(false);
    const [datesFilter, setDatesFilter] = useState([]);
    console.log("ReleaseNextList ~ waitingSwapped:", waitingSwapped)
    const [studentMentorDetails, setStudentMentorDetails] = useState([]);
    console.log("listCount:", listCount);
    //other data variable
    const [searchTerm, setSearchTerm] = useState('');
    //MODAL data
    const [chooseMentor, setChooseMentor] = useState({
        open: false,
        onClose: () => setChooseMentor((prev) => ({ ...prev, open: false })),
        mentorSlotDetails: {},
        mentorData: [],
        extraData: {},
    });
    const [swappingStudents, setSwappingStudents] = useState({
        open: false,
        onClose: () => setSwappingStudents((prev) => ({ ...prev, open: false })),
        studentDetails: [],
        swappedRequestDetails: {},
        studentList: [],
        filter_student_list: [],
        setFilteredStudentList: (newData) => setSwappingStudents((prev) => ({ ...prev, filter_student_list: [...newData] })),
        extraData: {
            heading: "",
            order_id: "",
            student_name: ""
        },

    })
    const [availableSlots, setAvailableSlots] = useState({
        open: false,
        onClose: () => setAvailableSlots((prev) => ({ ...prev, open: false })),
        mentorSlotDetails: {},
        availableSlotsData: [],
        extraData: {},
    });

    //FUNCTIONS

    const getSwapList = async () => {
        try {
            const res = await service.get("")
        } catch (err) {
            console.log("err:", err);
        }
    }

    //[GET] useeffect
    useEffect(() => {
        handleMainEffect(releaseNextListData);
    }, [])
    console.log("releaseNextListData:", releaseNextListData);
    const handleMainEffect = async (releaseNextListData) => {
        setLoading((prev) => ({ ...prev, page: true }));
        const studentStat_response = await releaseNextListData;
        const dates_for_filter = JSON.parse(studentStat_response[5][0].session_date_slot_count_mapping).session_date_slot_count_mapping
        console.log("handleMainEffect ~ studentStat_response[5]", dates_for_filter)
        console.log("handleMainEffect ~ studentStat_response:", studentStat_response)
        setListCount(studentStat_response[0]);
        setStudentStatList(studentStat_response[1].sort((a, b) => a.list_no - b.list_no));
        setMentorStatList(studentStat_response[2]);
        console.log("handleMainEffect ~ studentStat_response[3]:", studentStat_response[3], studentStat_response[4])
        const mark_done = studentStat_response[5][0].status_name === "Done";
        console.log("handleMainEffect ~ studentStat_response[5]:", mark_done)
        setMarkAsDone(mark_done)
        setWaitingSwapped([...studentStat_response[3], ...studentStat_response[4]]);
        const studentMentorDetails_response = await getStudentMentorDetails(SMDcurrentPage);
        setDatesFilter([...datesFilter, ...dates_for_filter])
        setStudentMentorDetails(studentMentorDetails_response);
        chooseMentor.onClose();
        availableSlots.onClose();
        setLoading((prev) => ({ ...prev, page: false }));
    }

    const getStudentMentorDetails = async (page = 1, search_query = "", filter = "All") => {
        if (page) {
            try {
                console.log("get order:", page, search_query, filter)
                const filter_str = filter !== 'All' ? `&filterDate=${filter}` : ''
                const serch_str = search_query !== '' ? `&searchQuery=${search_query}` : ''
                const response = await service.get(`/os/superadmin/osOrderTable?process_id=${process_id}&page=${page}&limit=100${serch_str}${filter_str}`)
                // : await service.get(`/os/superadmin/osOrderTable?process_id=${process_id}&page=${page}&limit=100`);
                console.log("getStatLists ~ response:", response);
                if (response?.data) return response.data;
                else return [];
            } catch (error) {
                console.error('Error while fetching stat data:', error);
            }
        }
    }


    const handleKeyPress = (event) => {

    }
    const handleChangeSearch = () => {

    }


    console.log("swaaping students:", swappingStudents);
    return (
        <>{loading.page ?
            <SmallPageLoader show={loading.page} />
            : <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                <Box sx={{ ...DivCenter, gap: '16px', width: '100%' }}>
                    <Box sx={{ width: '50%' }}>
                        <Typography style={{ textAlign: 'left', fontSize: '24px', fontWeight: 500 }}>Scheduling Status </Typography>
                    </Box>

                    <Box sx={{ ...DivCenter, justifyContent: 'flex-end', width: '50%', gap: '8px' }}>
                        <Button variant='text' onClick={() => onStepChange(null, 5, 0)} sx={{ color: Color.primary1, fontSize: '14px', fontWeight: 500, textTransform: 'none' }}>
                            Edit scheduling rules
                        </Button>
                        <TextField
                            name="search_mentors"
                            // label="Search Student"
                            placeholder='Search by mentor name or specialization'
                            variant="outlined"
                            onKeyPress={handleKeyPress}
                            value={searchTerm}
                            onChange={handleChangeSearch}
                            fullWidth
                            sx={{ width: '50%', borderRadius: "12px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: Color.neutralMidGrey }} />
                                    </InputAdornment>
                                ),
                                sx: { borderRadius: "12px", height: "58px" }
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ ...DivCenter, justifyContent: 'flex-start', width: '100%', gap: '8px' }}>

                    {
                        listCount.reverse().map((item, index) => {
                            return (<>
                                <Typography style={{ fontStyle: 'italic' }}>{index + 1}{index == 0 ? "st" : index == 1 ? "nd" : index == 2 ? "rd" : "th"} list (<span style={{ color: Color.accent }}>{item?.student_count ?? 'N/A'}</span>) released on {formatConvertermonthfirst(item.list_timestamp, true, false)}</Typography>
                                {index !== listCount.length - 1 &&
                                    <TrendingFlat />
                                }
                            </>)
                        })
                    }
                    {/* <Typography style={{ fontStyle: 'italic' }}>1st list (<span style={{ color: Color.accent }}>{listCount[0]?.student_count ?? 'N/A'}</span>) released on {formatConvertermonthfirst(listCount[0]?.list_timestamp, true, false)}</Typography> */}
                    {/* {listCount[1]?.list_no === 2 ? <>
                        <TrendingFlat />
                        <Typography style={{ fontStyle: 'italic' }}>2nd list (<span style={{ color: Color.accent }}>{listCount[1]?.student_count ?? 'N/A'}</span>) released on {formatConvertermonthfirst(listCount[1]?.list_timestamp, true, false)}</Typography>
                    </> : <></>}
                    {listCount[2]?.list_no === 3 ? <>
                        <TrendingFlat />
                        <Typography style={{ fontStyle: 'italic' }}>3rd list (<span style={{ color: Color.accent }}>{listCount[2]?.student_count ?? 'N/A'}</span>) released on {formatConvertermonthfirst(listCount[2]?.list_timestamp, true, false)}</Typography>
                    </> : <></> } */}
                </Box>
                <Box sx={{ ...DivCenter, }}>
                    <Box sx={{ width: '35%', height: 'auto' }}>
                        <StudentStatsListView studentStatList={studentStatList} waitingSwapped={waitingSwapped} process_id={process_id} />
                    </Box>
                    <Box sx={{ width: '65%', minHeight: '400px', display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <MentorStatsListView mentorStatList={mentorStatList} />
                    </Box>
                </Box>
                <Box sx={{ ...DivCenter, justifyContent: 'flex-start', width: '100%', gap: '8px' }}>
                    <StudentMentorDetailsModule
                        markAsDone={markAsDone}
                        process_id={process_id}
                        swapped_student={swapped_student}
                        setSwappedStudent={setSwappedStudent}
                        datesFilter={datesFilter}
                        studentMentorDetails={studentMentorDetails}
                        setStudentMentorDetails={setStudentMentorDetails}
                        setChooseMentor={setChooseMentor}
                        setSwappingStudents={setSwappingStudents}
                        getStudentMentorDetails={getStudentMentorDetails}
                        mentorStatList={mentorStatList}
                        handleMainEffect={(a) => handleMainEffect(a)}
                        getStatLists={getStatLists}
                        setDialogue={setDialogue}
                    />
                </Box>

                {/* MODALS */}
                {chooseMentor.open && <ChooseMentorModal
                    open={chooseMentor.open}
                    onClose={chooseMentor.onClose}
                    mentorSlotDetails={chooseMentor.mentorSlotDetails}
                    mentorData={chooseMentor.mentorData}
                    extraData={chooseMentor.extraData}
                    setAvailableSlots={setAvailableSlots}
                    handleMainEffect={(a) => handleMainEffect(a)}
                    getStatLists={getStatLists}
                />}
                {
                    swappingStudents.open &&
                    <SwapModal
                        process_id={process_id}
                        setSwappedStudent={setSwappedStudent}
                        open={swappingStudents.open}
                        onClose={swappingStudents.onClose}
                        extraData={swappingStudents.extraData}
                        swappedRequestDetails={swappingStudents.swappedRequestDetails}
                        studentDetails={swappingStudents.studentDetails}
                        filter_student_list={swappingStudents.filter_student_list}
                        setFilteredStudentList={swappingStudents.setFilteredStudentList}
                        studentList={swappingStudents.studentList}
                        refresh={() => handleMainEffect(releaseNextListData)}
                    />
                }
                {chooseMentor.open && <ChooseMentorModal
                    open={chooseMentor.open}
                    onClose={chooseMentor.onClose}
                    mentorSlotDetails={chooseMentor.mentorSlotDetails}
                    mentorData={chooseMentor.mentorData}
                    extraData={chooseMentor.extraData}
                    setAvailableSlots={setAvailableSlots}
                    handleMainEffect={(a) => handleMainEffect(a)}
                    getStatLists={getStatLists}
                />}
                {availableSlots.open && <AvailableSlotsModal
                    open={availableSlots.open}
                    onClose={availableSlots.onClose}
                    mentorSlotDetails={availableSlots.mentorSlotDetails}
                    availableSlotsData={availableSlots.availableSlotsData}
                    extraData={availableSlots.extraData}
                    handleMainEffect={(a) => handleMainEffect(a)}
                    getStatLists={getStatLists}
                    dialogue={dialogue}
                    setDialogue={setDialogue}
                />}
                {dialogue.open && <DialogueBox
                    open={dialogue.open}
                    onClose={dialogue.onClose}
                    title={dialogue.title}
                    actions={dialogue.actions}
                    content={<>
                        <p>{dialogue.content}</p>
                    </>}
                />}

            </Box>
        }</>
    )
}

export default ReleaseNextList