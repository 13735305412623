import { AddOutlined, ArrowBackIos, Clear, Close, Done, EditOutlined, FiberManualRecord, GetApp, KeyboardArrowRight, MarkChatUnreadOutlined, PlayArrow, Search, UploadFile } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, IconButton, InputAdornment, Modal, TextField, } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Color, DivCenter, MuiStyles, modalBoxStyle } from '../../../../GlobalStyles';
import { Typography } from 'antd';
// import { EditStudentSpecilization } from './StudentSummayModals';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import { ActivityLoader } from '../../../reusables/Loader/Loader';
import { OfflineSessionConfirmationModal } from '../OfflineSessionModals';
import { SearchFunction } from '../../../Reusable_functions/TextFunctions/SearchFunction';
import { scrollToTargetId } from '../../../Reusable_functions/ScrollToTargetId/ScrollToTargetId';
import BasicTable from '../../../Reusable_functions/Table/BasicTable';
import Papa from "papaparse";
const MentorDetailsModule = ({
	mentorShownInterestData,
	setMentorShownInterestData,
	mentorDateSlotMapping,
	// setMentorDateSlotMapping,
	skuDetails, mentorSummary, specializationData, handleMentorShownInterestEffect, process_id }) => {
	// const [mentorShownInterestData, setMentorShownInterestData] = useState([
	//     {
	//         "mentor_id": 4247,
	//         "first_name": "OFC_student4",
	//         "last_name": "Offline",
	//         "email_id": "forobiv344@sfpixel.com",
	//         "ph_no": "1234567890",
	//         "arrival_city": "Loreal ipsum",
	//         "departure_city": "Loreal ipsum",
	//         "status": "Interested",
	//         "comment": "Loreal ipsum",
	//         "spe_ids": [3, 5, 6, 7],
	//         "mapped_spe_ids": [{ spe_id: 3, session_count: 5 }, { spe_id: 5, session_count: 5 }],
	//         "spe_names": ['Finance', 'IT', 'HR', 'Analysis'],
	//         "mapped_spe_names": ['Finance', 'IT']
	//     },
	//     {
	//         "mentor_id": 4247,
	//         "first_name": "OFC_student5",
	//         "last_name": "Offline",
	//         "email_id": "abhra@sfpixel.com",
	//         "ph_no": "1234567890",
	//         "arrival_city": "Loreal ipsum",
	//         "departure_city": "Loreal ipsum",
	//         "status": "Interested",
	//         "comment": "Loreal ipsum",
	//         "spe_ids": [3, 5, 6, 7],
	//         "mapped_spe_ids": [{ spe_id: 3, session_count: 5 }, { spe_id: 5, session_count: 5 }],
	//         "spe_names": ['Finance', 'IT', 'HR', 'Analysis'],
	//         "mapped_spe_names": ['Finance', 'IT']
	//     },
	// ])
	//DATA variables
	const service = new CCService();
	const [loading, setLoading] = useState({ activity: false, modalButton: false });
	const [searchTerm, setSearchTerm] = useState('');
	const [filterButtons, setFilterButtons] = useState({
		'All': true,
		'Accepted': false,
		'Rejected': false,
		'Yet to Approve': false,
	});

	//data
	const [filteredMentorShownInterestData, setFilteredMentorShownInterestData] = useState([...mentorShownInterestData]);
	const [allocated, setAllocated] = useState()
	const fileInputRef = useRef(null);
	console.log(" mentorShownInterestData in abcd:", filteredMentorShownInterestData);
	//MODALS

	const [confirmation, setConfirmation] = useState({
		open: false,
		onClose: () => setConfirmation((prev) => ({ ...prev, open: false })),
		extraData: {
			heading: '',
			body: '',
			button1: {},
			button2: {},
			loading: { button: false }
		}
	});
	const [comment, setComment] = useState({
		open: false,
		onClose: () => setComment((prev) => ({ ...prev, open: false })),
		extraData: {
			heading: '',
			body: '',
		}
	})
	const [totalInterviews, setTotalInterviews] = useState(0);
	const [editSpecilization, setEditSpecilization] = useState({
		open: false,
		onClose: () => setEditSpecilization((prev) => ({ ...prev, open: false })),
		extraData: {
			details: {
				first_name: '', last_name: null,
				provided_spe_name: mentorShownInterestData.spe_name,
				skuDetails: {},
				total_interviews: '',
				// sku_name: mentorShownInterestData.sku_name, college_name: mentorShownInterestData.college_code,
				// batch_name: mentorShownInterestData.user_group_name, batch_type: mentorShownInterestData.name,
				specialization_count: []
			},
		}
	});
	const [addMentor, setAddMentor] = useState({
		open: false,
		onClose: () => setAddMentor((prev) => ({ ...prev, open: false })),
		extraData: {}
	});

	//FUNCTIONS

	// [GET]
	//all mentors [GET]
	const getAllMentors = async (searchQuery = '') => {
		try {
			// setModalLoading((prev) => ({ ...prev, searchMentor: true }));
			const response = await service.get(`/superAdmin/mentorsNoCheck?limit=10&page=1&college_name=[]&company_name=[]&role_name=[]&designation=[]&searchQuery=${searchQuery}`);
			if (response?.mentors) {
				return response.mentors
			}
			// setModalLoading((prev) => ({ ...prev, searchMentor: false }));
		} catch (error) {
			console.error("Error while searching mentor:", error);
			// setModalLoading((prev) => ({ ...prev, searchMentor: false }));
		}
	}
	const downloadCSV = () => {

		const temp = filteredMentorShownInterestData.map((item) => {
			return {
				...item,
				mapped_spe_name: item?.mapped_spe.map((item, index) => {
					return (item?.spe_name)
				}),
				mapped_spe_slot_count: item?.mapped_spe.map((item, index) => {
					return (item?.slot_count)
				}),
				spe_name: item?.spe.map((item, index) => {
					return (item?.spe_name)
				})
				// resume_name: item?.resume ? item?.resume?.name : [],
				// resume_url: item.resume ? item?.resume?.url : [],
				// other_docs: other_docs_flat,
			}

		})

		temp.map((item) => {
			delete item["mapped_spe"];
			delete item["spe"];
		})
		console.log("temp in mentor module:", temp);
		const csvData = Papa.unparse(temp);

		const blob = new Blob([csvData], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = `Mentor_Summary.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	const handleCSVUpload = (event) => {
		let bulkUploadData = [];
		console.log("event", event);
		console.log("csv upload at mentor data");
		let parsedData = {};
		if (event && event.target) {
			let file = event.target?.files[0];
			let reader = new FileReader();
			reader.readAsText(file);
			reader.onload = async () => {
				setLoading((prev) => ({ ...prev, activity: true }));
				parsedData = Papa.parse(reader.result, { delimiter: ",", dynamicTyping: true }).data;

				bulkUploadData = parsedData?.map((item, index) => {
					if (index > 0) {
						return ({ process_id: process_id, mentor_email: item[0], comment: null, arrival_city: item[1], departure_city: item[2] })
					}
				}).slice(1, -1);
				console.log("bulkdata:", bulkUploadData);
				if (bulkUploadData.length > 0 || bulkUploadData.includes("null") || bulkUploadData.includes("undefined")) {
					console.log("in the upload body:", { "process_id": parseInt(process_id), "bulkData": [...bulkUploadData] });
					await service.post("/os/superadmin/mentorBulkUpload", { mentorData: [...bulkUploadData] });
				}

				const response = await handleMentorShownInterestEffect();

				setMentorShownInterestData([...response]);
				setFilteredMentorShownInterestData([...response]);
				console.log("res in handlecsv:", response)
				setLoading((prev) => ({ ...prev, activity: false }));

			};
		}
	}
	//FILTER
	useEffect(() => {
		handleFilterEffect();
	}, [filterButtons['All'], filterButtons['Yet to submit'], filterButtons['9 Classes'], filterButtons['8 Classes'], filterButtons['7 Classes'], filterButtons['HR'], filterButtons['Finance']]);

	const handleFilterEffect = () => {
		let flag = '';

		for (const key in filterButtons) {
			if (filterButtons[key] === true) flag = key;
		}
		handleFilterButton(flag, false);
	}

	const handleFilterButton = (flag, scroll) => {
		let temp_filteredData = [...mentorShownInterestData];
		const temp_filter = {};
		for (let key in filterButtons) {
			if (key === flag) temp_filter[key] = true;
			else temp_filter[key] = false;
		}
		setFilterButtons(temp_filter);
		if (flag === 'All') {
			temp_filteredData = [...mentorShownInterestData];
		} else if (flag === 'Accepted') {
			temp_filteredData = temp_filteredData.filter((student) => student.status === 'Accepted');
		} else if (flag === 'Rejected') {
			temp_filteredData = temp_filteredData.filter((student) => student.status === 'Rejected');
		} else if (flag === 'Yet to Approve') {
			temp_filteredData = temp_filteredData.filter((student) => student.status === null || student.status === "Interested");
		}

		setFilteredMentorShownInterestData(temp_filteredData);
		scrollToTargetId('MentorsList');
	}

	const handleChangeSearch = (e, newValue) => {
		const searchTerm = e.target.value;
		setSearchTerm(searchTerm);
		if (searchTerm) {
			const filtered = SearchFunction(mentorShownInterestData, ['first_name', 'last_name', 'email_id', 'ph_no', 'spe_name', 'student_availability'], searchTerm);
			setFilteredMentorShownInterestData(filtered);
		}
		else {
			setFilteredMentorShownInterestData([...mentorShownInterestData]);
		}
	}

	const handleTakeAction = async (flag, mentor) => {
		setLoading((prev) => ({ ...prev, activity: true }));
		// const get_specialization = await getSpecialization();
		setConfirmation((prev) => ({
			...prev, open: true,
			extraData: {
				...prev.extraData,
				heading: 'Confirmation',
				setLoading: (toggle) => setConfirmation((prev) => ({ ...prev, loading: { button: toggle } })),
				body: flag ? 'Are you sure you want to accept this Mentor?' : 'Are you sure you want to reject this Mentor?',
				button1: { name: flag ? 'Accept' : 'Reject', action: () => handleConfirmation(flag, mentor) },
				button2: { name: 'Cancel', action: () => confirmation.onClose() },
				loading: { button: loading.modalButton }
			}
		}));
		setLoading((prev) => ({ ...prev, activity: false, }));
	}

	const handleConfirmation = async (flag, mentor) => {
		setLoading((prev) => ({ ...prev, modalButton: true, loading: { button: true } }));
		try {

			const body = {
				"process_id": parseInt(process_id),
				"mentor_id": mentor.mentor_id,
				"status": flag ? "Accepted" : "Rejected",
				"count_data": [],
				// "total_interviews": totalInterviews
			}

			await service.put(`os/superadmin/slots`, body);
			const res = await handleMentorShownInterestEffect();
			setFilteredMentorShownInterestData(res);
			confirmation.onClose();
			setLoading((prev) => ({ ...prev, modalButton: false }));
		} catch (error) {
			console.error("Error posting Mentor status:", error);
			setLoading((prev) => ({ ...prev, modalButton: false, loading: { button: false } }));
		}
	}

	const handleEditMappedSpecialization = (mentor, mentorCities = null, formInputs = null) => {
		console.log(" mentor.total_interviews:", mentor);
		const available_spe = [];
		for (const key in mentorSummary) {
			if (mentorSummary[key].student_count - mentorSummary[key].approved_slot_count) {
				available_spe.push({ spe_name: mentorSummary[key].spe_name, left_out_students: mentorSummary[key].student_count - mentorSummary[key].approved_slot_count });
			}
		}
		//slots count and other stuff for the Modal
		let total_spe_slots_count = 0;
		if (mentor?.mapped_spe.length) {
			total_spe_slots_count = mentor.mapped_spe.reduce((acc, obj) => {
				return acc + parseInt(obj['slot_count']);
			}, 0);
		}

		const specialization_count = specializationData.map((spe) => ({
			spe_id: spe.id,
			spe_name: spe.name,
			slot_count: mentor.mapped_spe.find((map_spe) => parseInt(map_spe.spe_id) === parseInt(spe.id))?.slot_count ?? 0,
		}));

		//from mapping data
		console.log("mentordate:", mentorDateSlotMapping);
		const total_interviews_mapping = mentorDateSlotMapping.reduce((acc, obj) => {
			return acc + parseInt(obj['per_mentor_slot_count']);
		}, 0)
		setTotalInterviews(mentor.total_interviews)
		setEditSpecilization((prev) => ({
			...prev,
			open: true,
			extraData: {
				...prev.extraData,
				heading: "Edit Mentor Mapped Specialization",
				mentor: mentor,
				skuDetails: skuDetails.length ? skuDetails[0] : {
					"college_code": "",
					"user_group_name": "",
					"name": "",
					"sku_name": ""
				},
				available_spe: available_spe,
				total_interviews: mentor.total_interviews,
				interviews_allotted: total_spe_slots_count,
				specialization_count: specialization_count ?? [{ spe_name: '', slot_count: 0 }],
				mentor_cities: mentorCities,
				comment: formInputs
			}
		}))
	}

	//open Add Mentor Modal
	const handleClickAddMentor = () => {
		setAddMentor((prev) => ({
			...prev,
			open: true,
			extraData: { ...prev.extraData, heading: 'Add a mentor' }
		}));
	}



	//Styles
	const styles = {
		filter_buttons: {
			minWidth: '100px',
			width: 'auto',
			background: Color.white,
			color: Color.primary1,
			borderRadius: '14px',
			border: `1px solid ${Color.primary1}`,
			textTransform: 'none',
			'&:hover': {
				background: 'rgba(195, 236, 245, 1)',
				color: Color.primary1,
			},
		},
		button: {
			contained: {
				minWidth: '100px',
				width: 'auto',
				height: '44px',
				background: Color.primary1,
				color: Color.white,
				borderRadius: '12px',
				textTransform: 'none',
				'&:hover': {
					background: Color.primary1,
					color: Color.white,
				},
				'&:disabled': {
					background: 'rgba(28, 142, 168, 0.4)',
					color: Color.white,
				}
			},
			outlined: {
				minWidth: '100px',
				width: 'auto',
				height: '44px',
				background: Color.white,
				color: Color.primary1,
				borderRadius: '12px',
				border: `1px solid ${Color.primary1}`,
				textTransform: 'none',
				'&:hover': {
					background: Color.white,
					color: Color.primary1,
				},
				'&:disabled': {
					borderColor: 'rgba(28, 142, 168, 0.4)',
					color: 'rgba(28, 142, 168, 0.4)',
				}
			}
		},
	}

	//SHOW MEODALS
	function CommentModal({ open, onClose }) {
		return (
			<Modal open={open} onClose={onClose}>
				<Box sx={{ ...modalBoxStyle, padding: "0.1rem", minWidth: '758px', height: '250px', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Box sx={{ ...DivCenter, width: '100%', height: '20%', justifyContent: 'space-between' }}>
						<Typography style={{ textAlign: 'center', fontSize: '32px', width: '100%', fontWeight: 'bold' }}>{comment.extraData.heading}</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Box>
					<Box sx={{ ...DivCenter, width: '100%', height: '80%', justifyContent: 'center', alignItems: 'center' }}>
						<Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, width: '100%', fontSize: '14px' }}>{comment.extraData.body}</Typography>
					</Box>
				</Box>
			</Modal>
		)
	}

	//EDIT SPE
	function EditMappedSpecialization({ open, onClose, extraData, mentorSummary, totalInterviews, setTotalInterviews }) {

		const [formInputs, setFormInputs] = useState([...extraData.specialization_count]);
		const [temp, settemp] = useState(totalInterviews);
		const [allottedInterviews, setAllottedInterviews] = useState(extraData?.interviews_allotted);
		const [modalLoading, setModalLoading] = useState({ button: false });
		//FUNCTIONS
		const handleChangeSpeCount = ({ target }, spe_index) => {
			const { value } = target;
			let temp_formInput = [...formInputs];
			temp_formInput[spe_index].slot_count = value;
			setFormInputs(temp_formInput);
			let count = 0;
			for (const index in temp_formInput) {
				if (temp_formInput[index].slot_count !== '')
					count += parseInt(temp_formInput[index].slot_count) ?? 0;
				else count += 0;
			}
			// if (count > totalInterviews) {
			// 	setFormErrors((prev) => ({ ...prev, count: 'Allotted Interviews is more than Total Interviews allowed per mentor' }));
			// } else {
			// 	setFormErrors((prev) => ({ ...prev, count: '' }));
			// }
			setAllottedInterviews(count);
		}

		const handleChangeTotalInterviews = ({ target }) => {
			const { value } = target;
			// if (allottedInterviews > value) {
			// 	setFormErrors((prev) => ({ ...prev, count: 'Total Interviews is less than Allotted Interviews allowed per mentor' }));
			// } else {
			// 	setFormErrors((prev) => ({ ...prev, count: '' }));
			// }
			settemp(value);
			// setEditSpecilization((prev)=>({...prev, extraData:{...prev.extraData, total_interviews:value }}))
		}

		const handleSubmit = async () => {
			console.log("  in handle submit func alloted total", allottedInterviews, totalInterviews);
			try {
				setModalLoading((prev) => ({ ...prev, button: true }));
				const count_data = formInputs.map((spe) => ({ spe_id: spe.spe_id, slot_count: [NaN].includes(parseInt(spe.slot_count)) ? 0 : parseInt(spe.slot_count) }));
				let body = {}
				if (!extraData.mentor_cities) {
					body = {
						"process_id": process_id,
						"mentor_id": extraData.mentor.mentor_id,
						"count_data": count_data,
						"status": "Accepted",
						"total_interviews": temp
					};
					await service.put(`/os/superadmin/slots`, body);
				} else {
					body = {
						"process_id": process_id,
						"mentor_id": extraData.mentor.mentor_id,
						"count_data": count_data,
						"comment": extraData.comment.length ? extraData.comment : undefined,
						"arrival_city": extraData.mentor_cities.arrival_city.selected,
						"departure_city": extraData.mentor_cities.departure_city.selected
					};
					await service.post(`/os/superadmin/interestedMentors/searchAndAddMentor`, body);
				}
				//Update mentor shown interest data
				// let temp_MSID = [...mentorShownInterestData];
				// const msid = temp_MSID.find((temp) => temp.mentor_id === extraData.mentor.mentor_id);
				// msid.status = "Accepted";
				// msid.mapped_spe = formInputs.filter((input) => !['', 0].includes(input.slot_count));
				// setMentorShownInterestData(temp_MSID);
				const res = await handleMentorShownInterestEffect();
				console.log("in in in  nbnbnbnb res:", res);
				setFilteredMentorShownInterestData([...res]);
				onClose();
				setModalLoading((prev) => ({ ...prev, button: false }));
			} catch (error) {
				setModalLoading((prev) => ({ ...prev, button: false }));
				console.error("Error submitting spe slot count:", error);
			}
		}
		console.log("filteredMentorShownInterestData:", mentorSummary)
		return (
			<Modal open={open} onClose={modalLoading.button ? {} : onClose}>
				<Box sx={{ ...modalBoxStyle, padding: 0, paddingRight: 3, paddingLeft: 3, minWidth: '758px', minHeight: '700px', height: '70%', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'flex-start', alignItems: 'center' }}>
						{(!extraData.mentor?.first_name) &&
							<IconButton onClick={() => { handleClickAddMentor(); onClose(); }}>
								<ArrowBackIos sx={{ fontSize: '22px' }} />
							</IconButton>}
						<Typography style={{ textAlign: 'center', fontSize: '24px', width: '100%', fontWeight: 500 }}>{extraData.heading}</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Box>
					<Box sx={{ width: '100%', height: '5%' }}><Typography style={{ textAlign: 'center', fontWeight: 500, fontSize: '16px' }}>Mentor: {extraData.mentor?.first_name ? (extraData.mentor?.first_name + " " + (extraData.mentor.last_name ?? '')) : extraData.mentor.name}</Typography></Box>
					<Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '75%', justifyContent: 'center', alignItems: 'flex-start' }}>
						<Box sx={{ ...DivCenter, width: '100%', height: '25%', justifyContent: 'space-between', alignItems: 'flex-start', }}>
							<Box sx={{ ...DivCenter, alignItems: 'flex-start', flexDirection: 'column', width: '50%', gap: '12px', }}>
								<Typography style={{ fontWeight: 500, fontSize: '14px' }}>Mentor's Specialization:
									{/* {mentorSummary
										.filter(spe => parseInt(spe.left_out_students) > 0)
										.map((item, index) => (
											<React.Fragment key={index}>
												{item.spe_name}{index === mentorSummary.filter(spe => parseInt(spe.left_out_students) > 0).length - 1 ? '' : ', '}
											</React.Fragment>
										)) */
										extraData?.mentor?.spe?.map((item, index) => (<React.Fragment key={index}>
											{" "} {item?.spe_name}{index === extraData.mentor?.spe?.length - 1 ? '' : ', '}
										</React.Fragment>))
									}

								</Typography>

								<Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '12px' }}>
									<Typography style={{ fontWeight: 500, fontSize: '14px' }}>Total interviews :</Typography>
									<TextField

										variant="outlined"
										size="small"
										color="warning"
										style={{ marginRight: 10 }}
										value={temp}
										onChange={(e) => handleChangeTotalInterviews(e)} sx={{ width: '25%', borderColor: Color.primary1 }}
										type='number'
									/>

								</Box>
								<Typography style={{ fontWeight: 500, fontSize: '14px' }}>Interviews allotted : {allottedInterviews}</Typography>
							</Box>
							<Box sx={{ ...DivCenter, alignItems: 'flex-end', flexDirection: 'column', width: '50%', }}>
								<Typography style={{ fontSize: '18px', }}>{extraData.skuDetails.sku_name ?? 'SKU'}</Typography>
								<Typography style={{ color: Color.neutralMidGrey, fontSize: '12px' }}>{(extraData.skuDetails.college_code ?? 'College') + ", " + (extraData.skuDetails.user_group_name ?? 'Batch') + ", " + (extraData.skuDetails.name ?? "Batch Type") + " "}<FiberManualRecord sx={{ fontSize: '6px' }} /></Typography>
							</Box>
						</Box>
						{/* {formErrors.count.length ? <Typography style={{ fontWeight: 500, fontSize: '12px', color: 'red', paddingLeft: '15px' }}>*{formErrors.count}</Typography> : <></>} */}
						<hr style={{ width: '100%', padding: 0, alignItems: "center" }} />
						<Box style={{ ...DivCenter, flexDirection: "row", alignItems: "flex-start" }}>
							<Box sx={{ ...DivCenter, flexDirection: "column", alignItems: 'flex-start', height: '45%', marginRight: 6, justifyContent: 'space-between', width: '50%', }}>
								{formInputs.map((spe, spe_index) =>
									<Box sx={{ ...DivCenter, width: '100%', marginBottom: '12px', justifyContent: 'flex-start' }}>
										<Box sx={{ justifyContent: 'flex-start', width: '100%' }}>
											<Typography style={{ width: '100%', fontWeight: 500, fontSize: '16px' }}>{spe.spe_name}</Typography>
										</Box>
										<Box sx={{ ...DivCenter, justifyContent: 'flex-start', }}>
											<TextField variant="outlined"
												size="small" type='number' value={spe.slot_count} onChange={(e) => handleChangeSpeCount(e, spe_index)} sx={{ width: '80%', }} />
										</Box>
									</Box>)}
							</Box>
							<Box>
								<Typography style={{ fontSize: "16px", fontWeight: 500, marginBottom: '12px', alignSelf: "center" }}> Students without mentors </Typography>
								<BasicTable data={mentorSummary} />
							</Box>

						</Box>
					</Box>
					<Box sx={{ ...DivCenter, width: '100%', height: '10%', gap: '32px' }}>
						<Button disabled={modalLoading.button} onClick={onClose} sx={{ ...styles.button.outlined, width: '40%' }}>Cancel</Button>
						<Button
							//  disabled={modalLoading.button || formErrors.count.length || totalInterviews !== allottedInterviews}
							disabled={modalLoading.button}
							onClick={() => handleSubmit()} sx={{ ...styles.button.contained, width: '40%' }}>
							{modalLoading.button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Submit"}
						</Button>
					</Box>
				</Box>
			</Modal>
		)
	}

	//ADD A MENTOR
	function AddMentorModal({ allottedInterviews, setAllottedInterviews, open, onClose, extraData }) {

		const [modalLoading, setModalLoading] = useState({ modal: false, searchMentor: false });
		const [selectedMentor, setSelectedMentor] = useState({});
		const [searchMentor, setSearchMentor] = useState('');
		const [page, setPage] = useState(1);
		const [allMentors, setAllMentors] = useState([]); //all data
		//data
		const [mentorData, setMentorData] = useState([]); //filtered data
		const [mentorCities, setMentorCities] = useState({
			departure_city: { selected: '', options: ['Bengaluru', 'Kolkata', 'Malda'] },
			arrival_city: { selected: '', options: ['Bengaluru', 'Kolkata', 'Malda'] },
		});
		const [formInputs, setFormInputs] = useState('');
		//Modal Functions
		useEffect(() => {
			handleAddMentorEffect();
		}, []);

		const handleAddMentorEffect = async () => {
			setModalLoading((prev) => ({ ...prev, searchMentor: true }));

			//[GET] all mentor searches 
			const mentor_data = await getAllMentors();

			const already_added_mentor_ids = mentorShownInterestData.map((mentor) => mentor.mentor_id);
			const mentor_other_than_already_added = mentor_data.filter((mentor) => !already_added_mentor_ids.includes(mentor.user_id));

			const mentor_data_with_loading = mentor_other_than_already_added.map(item => ({ ...item, loading: false }));
			setAllMentors(mentor_data_with_loading);
			setMentorData(mentor_data_with_loading);

			setModalLoading((prev) => ({ ...prev, searchMentor: false }));
		}

		const handleChangeSearch = ({ target }) => {
			const { value } = target;
			if (!value) {
				setMentorData(allMentors);
			}
			setSearchMentor(value);
		}
		const handleSearchMentor = async () => {
			setModalLoading((prev) => ({ ...prev, searchMentor: true }));
			const mentors = await getAllMentors(searchMentor);
			setMentorData([...mentors.map(item => ({ ...item, loading: false }))]);
			setModalLoading((prev) => ({ ...prev, searchMentor: false }));
		}

		const handleKeyPress = (event) => {
			if (event.key === 'Enter') {
				handleSearchMentor();
			}
		};

		//mentor when clicked on or chosen
		const handleMentorClick = async (mentor, index) => {
			setSelectedMentor(mentor);
			let temp_mentorData = [...mentorData];

			if (index) temp_mentorData[index].loading = true;
			//[GET] selected mentor's departure cities and arrival cities
			const mentor_interest = await getMentorInterest(mentor.user_id);
			console.log("handleMentorClick ~ mentor_interest:", mentor_interest)
			setMentorCities((prev) => ({
				...prev,
				arrival_city: { options: JSON.parse(mentor_interest.arrival_cities).arrival_cities },
				departure_city: { options: JSON.parse(mentor_interest.departure_cities).departure_cities },
			}));
			setPage(2);
			if (index) temp_mentorData[index].loading = false;
			setMentorData(temp_mentorData);
		}

		//[GET]s
		const getMentorInterest = async (mentor_id) => {
			try {
				const response = await service.get(`/os/superadmin/mentorInterest?process_id=${process_id}`);
				return response.data[0];
			} catch (error) {
				console.error('Error while getting mentor interest', error);
			}
		}



		//handling mentor city selection
		const handleChangeMentorCities = (e, value, flag) => {
			setMentorCities((prev) => ({ ...prev, [flag]: { ...prev[flag], selected: value } }));
		}

		const handleRemoveCities = (index, flag) => {
			let temp_mentorCities = { ...mentorCities };
			temp_mentorCities[flag] = temp_mentorCities[flag].filter((_, i) => i !== index);
			setMentorCities(temp_mentorCities);
			// setMentorCities((prev) => ({ ...prev, [flag]: { ...prev[flag], selected:  } }));
		}

		const handleChangeFormInputs = (e) => {
			setFormInputs(e.target.value);
		}
		const handleSubmitMentorInterest = () => {
			 const mentor_spe= selectedMentor.specialization.map((item, index)=>{
				return {
					spe_name : item
				}
			 })
			const sending_mentor = {
				...selectedMentor,
				mentor_id: selectedMentor.user_id,
				mapped_spe : [],
				spe: [...mentor_spe],
			}
			
			addMentor.onClose();
			handleEditMappedSpecialization(sending_mentor, mentorCities, formInputs);
		}

		return (
			<Modal open={open} onClose={onClose}>
				<Box sx={{ ...modalBoxStyle, width: '50%', minWidth: '600px', height: '70%', minHeight: '650px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto', gap: '16px' }}>
					<Box sx={{ ...DivCenter, width: '100%', height: '5%', justifyContent: 'space-between' }}>
						{!(page === 1) &&
							<IconButton onClick={() => { setPage(1); }}>
								<ArrowBackIos sx={{ fontSize: '22px' }} />
							</IconButton>}
						<Typography style={{ ...DivCenter, justifyContent: 'center', fontSize: '24px', width: '100%', fontWeight: 500 }}>
							{extraData.heading}
						</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Box>
					{page === 1 ?
						<Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '95%', justifyContent: 'flex-start', }}>
							<Box sx={{ ...DivCenter, width: '100%', p: '32px 0 16px 0' }}>
								<TextField
									name="search_mentors"
									// label="Search Student"
									placeholder={selectedMentor.name ?? 'Search Mentors'}
									variant="outlined"
									onKeyPress={handleKeyPress}
									value={searchMentor}
									onChange={handleChangeSearch}
									fullWidth
									sx={{ width: '90%', borderRadius: "12px" }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Search sx={{ color: Color.neutralMidGrey }} />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="start">
												<PlayArrow sx={{ color: Color.neutralMidGrey }} />
											</InputAdornment>
										),
										sx: { borderRadius: "12px", height: "58px" }
									}}
								/>
								<Box sx={{ ...DivCenter, width: '10%', height: "58px" }}>
									<IconButton onClick={() => handleSearchMentor()}>
										<Search sx={{ color: Color.neutralMidGrey }} />
									</IconButton>
								</Box>
							</Box>
							<Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '16px' }}>
								{modalLoading.searchMentor ?
									<CircularProgress size={24} sx={{ color: Color.primary1 }} />
									: mentorData.length ?
										mentorData.map((mentor, index) =>
											<Box
												onClick={() => handleMentorClick(mentor, index)}
												sx={{
													...DivCenter, width: '100%', borderBottom: '1px solid black', p: '16px 0', cursor: 'pointer',
													// '&:hover': {
													//     background: Color.primary_highlight
													// }
												}}>
												<Typography style={{
													width: '90%',
													textAlign: 'left',
													fontSize: '14px',
													fontWeight: 400,
													lineHeight: '14px',
												}}>{mentor.name}</Typography>
												<Box sx={{ width: '5%' }}>{mentor.loading ? <CircularProgress size={18} sx={{ color: Color.primary1Color }} /> : <></>}</Box>
												<Box sx={{ width: '5%' }}><KeyboardArrowRight /></Box>
											</Box>
										) : <Typography style={{
											width: '100%', textAlign: 'left',
											fontSize: '14px',
											fontWeight: 400,
											lineHeight: '14px',
											color: Color.neutralMidGrey,
										}} >No mentors to show</Typography>}
							</Box>
						</Box>
						: page === 2 ?
							<Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '95%', justifyContent: 'flex-start', gap: '32px' }}>
								<Box sx={{ ...DivCenter, flexDirection: 'column', gap: '16px', width: '100%' }}>
									<Typography style={{ fontSize: '20px', fontWeight: 500, textAlign: 'left', width: '100%' }}>Departure city<span style={{ color: Color.red }}>*</span></Typography>
									<Autocomplete
										name='departure_city'
										sx={{ width: "100%", minHeight: '45px', height: 'auto', background: Color.white }}
										placeholder='Enter the departure city'
										options={mentorCities.departure_city.options}
										freeSolo
										value={mentorCities.departure_city.selected}
										onChange={(e, value) => {
											handleChangeMentorCities(e, value, 'departure_city')
										}
										}
										renderTags={(value, getTagProps) =>
											<Box sx={{ ...DivCenter, gap: '8px' }}>
												{value.map((option, index) => (
													<Box sx={{
														...DivCenter, justifyContent: 'space-evenly',
														p: '12px', cursor: 'default', width: 'auto', minWidth: '100px', height: '45px',
														borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.primary1
													}}>
														<Box sx={{ ...DivCenter, width: '10%' }}>
															<Close
																sx={{ fontSize: '14px', cursor: 'pointer' }}
																onClick={() => { handleRemoveCities(index, 'departure_city') }} />
														</Box>
														<Box sx={{ ...DivCenter, width: '90%' }}>
															<Typography style={{ width: '100%', textAlign: 'center' }}>{option}</Typography>
														</Box>
													</Box>
												))}
											</Box>
										}
										renderInput={params => (
											<TextField {...params} name='mentor_cities' placeholder='Enter the preferred cities' sx={{
												"& .MuiOutlinedInput-root": {
													borderRadius: "12px",
												},
											}} />
										)}
									/>
								</Box>
								<Box sx={{ ...DivCenter, flexDirection: 'column', gap: '16px', width: '100%' }}>
									<Typography style={{ fontSize: '20px', fontWeight: 500, textAlign: 'left', width: '100%' }}>Arrival city<span style={{ color: Color.red }}>*</span></Typography>
									<Autocomplete
										name='arrival_city'
										sx={{ width: "100%", minHeight: '45px', height: 'auto', background: Color.white }}
										placeholder='Enter the arrival city'
										options={mentorCities.arrival_city.options}
										freeSolo
										value={mentorCities.arrival_city.selected}
										onChange={(e, value) => {
											handleChangeMentorCities(e, value, 'arrival_city')
										}
										}
										renderTags={(value, getTagProps) =>
											<Box sx={{ ...DivCenter, gap: '8px' }}>
												{value.map((option, index) => (
													<Box sx={{
														...DivCenter, justifyContent: 'space-evenly',
														p: '12px', cursor: 'default', width: 'auto', minWidth: '100px', height: '45px',
														borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.primary1
													}}>
														<Box sx={{ ...DivCenter, width: '10%' }}>
															<Close
																sx={{ fontSize: '14px', cursor: 'pointer' }}
																onClick={() => { handleRemoveCities(index, 'arrival_city') }} />
														</Box>
														<Box sx={{ ...DivCenter, width: '90%' }}>
															<Typography style={{ width: '100%', textAlign: 'center' }}>{option}</Typography>
														</Box>
													</Box>
												))}
											</Box>
										}
										renderInput={params => (
											<TextField {...params} name='mentor_cities' placeholder='Enter the preferred cities' sx={{
												"& .MuiOutlinedInput-root": {
													borderRadius: "12px",
												},
											}} />
										)}
									/>
								</Box>
								<Box sx={{ ...DivCenter, flexDirection: 'column', gap: '16px', width: '100%' }}>
									<Typography style={{ fontSize: '20px', fontWeight: 500 }}>Any additional info you would like to share about this trip. We will reach out to you with an update soon<span style={{ color: Color.red }}>*</span></Typography>
									<TextField multiline rows={4} value={formInputs} onChange={(e) => handleChangeFormInputs(e)} sx={{ width: '100%' }} />
								</Box>
								<Box sx={{ ...DivCenter, gap: '16px', width: '100%' }}>
									<Button variant='outlined' onClick={() => onClose()} sx={{ ...styles.button.outlined }}>Cancel</Button>
									<Button disabled={!mentorCities.arrival_city.selected || !mentorCities.departure_city.selected} variant='contained' onClick={() => handleSubmitMentorInterest()} sx={{ ...styles.button.contained }}>Submit</Button>
								</Box>
							</Box>
							: <></>}
					<ActivityLoader show={modalLoading.activity} />
				</Box>
			</Modal>)
	}

	return (
		<Box sx={{ pt: '48px', width: '100%', }}>
			<ActivityLoader show={loading.activity} />
			<Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
				<TextField
					name="search_colleges"
					// label="Search Student"
					placeholder='Search Mentors'
					variant="outlined"
					value={searchTerm}
					onChange={handleChangeSearch}
					fullWidth
					sx={{ width: '90%', borderRadius: "12px" }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search sx={{ color: Color.neutralMidGrey }} />
							</InputAdornment>
						),
						sx: { borderRadius: "12px", height: "58px" }
					}}
				/>
				<Box sx={{ width: '10%', minWidth: '121px' }}>
					<IconButton onClick={() => handleClickAddMentor()}><AddOutlined /></IconButton>
					<IconButton onClick={() => downloadCSV()}><GetApp /></IconButton>
					<IconButton > <input
						type="file"
						style={{ display: 'none' }}
						onChange={(e) => handleCSVUpload(e)}
						ref={fileInputRef}
					/><UploadFile onClick={() => fileInputRef.current.click()} /></IconButton>
				</Box>
			</Box>
			<Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '8px', mb: '24px' }}>
				{Object.keys(filterButtons).map((filter) => <Button onClick={() => handleFilterButton(filter, true)} sx={{
					...styles.filter_buttons,
					background: filterButtons[filter] ? 'rgba(195, 236, 245, 1)' : Color.white,
				}}>{filter}</Button>)}
			</Box>
			<Box id="MentorsList" sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
				<Box sx={{ ...DivCenter, width: '20%' }}><Typography style={{ fontSize: '22px', color: Color.primary1, }}>Mentor details</Typography></Box>
				<Box sx={{ ...DivCenter, width: '20%' }}><Typography style={{ fontSize: '22px', color: Color.primary1, textAlign: "center" }}>Specialization <br />areas</Typography></Box>
				<Box sx={{ ...DivCenter, width: '20%' }}><Typography style={{ fontSize: '22px', color: Color.primary1, textAlign: "center" }}>Mapped specialization</Typography></Box>
				<Box sx={{ ...DivCenter, width: '20%' }}><Typography style={{ fontSize: '22px', color: Color.primary1, }}>Departure / Arrival city</Typography></Box>
				<Box sx={{ ...DivCenter, width: '20%' }}><Typography style={{ fontSize: '22px', color: Color.primary1, }}>Take action</Typography></Box>
			</Box >
			{
			
			(filteredMentorShownInterestData.length < 1) || (filteredMentorShownInterestData[0]?.email_id.length) < 1 ?
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					No data available
				</Box> :
				filteredMentorShownInterestData.map((mentor, mentor_index) =>
					<Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: mentor_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
						<Box sx={{ ...DivCenter, flexDirection: 'column', gap: '8px', width: '20%' }}>
							<Typography style={{ color: Color.primary1, fontSize: '18px' }}>{mentor.first_name + " " + (mentor.last_name ?? '')}</Typography>
							<Typography style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{mentor.email_id}</Typography>
							<Typography style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{mentor.ph_no}</Typography>
						</Box>
						<Box sx={{ ...DivCenter, width: '20%' }}>
							<Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px', padding: '10px' }}>
								{mentor?.spe.length ?
									mentor?.spe?.map((s, spe_index) => s?.spe_name + (spe_index === mentor.spe?.length - 1 ? "" : ", "))
									: <>No Spes</>}
							</Typography>
						</Box>
						<Box sx={{ ...DivCenter, width: '20%' }}>
							<Typography style={{ ...DivCenter, textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px', gap: '8px', padding: '10px' }}>
								{mentor?.mapped_spe?.length ?
									mentor.mapped_spe.map((spe, spe_index) => spe?.spe_name + (spe_index === mentor.mapped_spe.length - 1 ? "" : ", "))
									: <>No Mapped Spes</>}
								<EditOutlined onClick={() => handleEditMappedSpecialization(mentor)} sx={{ height: '18px', width: '18px', color: Color.neutralMidGrey, cursor: 'pointer' }} />
							</Typography>
						</Box>
						<Box sx={{ ...DivCenter, width: '20%' }}>
							<Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>
								{mentor.departure_city} / {mentor.arrival_city}
							</Typography>
						</Box>
						<Box sx={{ ...DivCenter, flexDirection: 'column', width: '20%', }}>
							<Box sx={{ ...DivCenter, gap: '16px' }}>
								{mentor.status === 'Interested' ? <>
									<IconButton onClick={() => handleTakeAction(1, mentor)}><Done sx={{ height: '41px', width: '41px', color: Color.green }} /></IconButton>
									<div style={{ height: '20px', width: '1px', background: Color.neutralBlack }} />
									<IconButton onClick={() => handleTakeAction(0, mentor)}><Clear sx={{ height: '41px', width: '41px', color: Color.red }} /></IconButton>
								</> : <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
									<div>
										<Typography style={{ fontSize: '20px', color: mentor.status === 'Accepted' ? Color.green : Color.red, background: mentor.status === 'Accepted' ? 'rgba(60, 171, 77, 0.1)' : 'rgba(255, 0, 0, 0.1)', padding: '10px 15px', borderRadius: 70 }}>{mentor.status}</Typography>
									</div>
									<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										{mentor.status !== "Accepted" && <IconButton onClick={() => handleTakeAction(1, mentor)}><Done sx={{ height: '41px', width: '41px', color: Color.green }} /></IconButton>}
										{mentor.status !== "Rejected" && <IconButton onClick={() => handleTakeAction(0, mentor)}><Clear sx={{ height: '41px', width: '41px', color: Color.red }} /></IconButton>}
									</div>
								</div>}
							</Box>
							{mentor.comment ?
								<Button onClick={() => setComment((prev) => ({ ...prev, open: true, extraData: { ...prev.extraData, heading: `Comments by Mentor ${mentor.first_name}`, body: mentor.comment } }))} type='text' sx={{ ...DivCenter, gap: '8px', textTransform: 'none', color: Color.primary1 }}>
									<MarkChatUnreadOutlined sx={{ color: Color.primary1 }} />
									View comment
								</Button>
								: <></>}
						</Box>
					</Box>)
			}

			{/* MODALS */}
			{confirmation.open ? <OfflineSessionConfirmationModal
				open={confirmation.open}
				onClose={confirmation.onClose}
				extraData={confirmation.extraData}
			/> : <></>}
			{comment.open ? <CommentModal open={comment.open} onClose={comment.onClose} /> : <></>}
			{editSpecilization.open ? <EditMappedSpecialization open={editSpecilization.open} onClose={editSpecilization.onClose} mentorSummary={mentorSummary} extraData={editSpecilization.extraData} totalInterviews={totalInterviews} setTotalInterviews={setTotalInterviews} /> : <> </>}
			{addMentor.open ? <AddMentorModal open={addMentor.open} onClose={addMentor.onClose} extraData={addMentor.extraData} /> : <></>}
		</Box>
	)
}

export default MentorDetailsModule