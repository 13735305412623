import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Card, CircularProgress, Divider, IconButton, Modal, TextField, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Color, DivCenter, PrimaryButton, PrimaryButtonOutlined } from "../../../../GlobalStyles";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
import { SlideInRightModal } from "../AdditionalComponents/SlideInRightModal";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import { ellipsisFunction } from "../../../Reusable_functions/TextFunctions/EllipsisFunction";
import TimeService from "../../../../functions/epochTime";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import "./JobPostings.css";
import "../JobAllRequests/JobAllRequests.css";
import { FunnelComponent } from "../../../Reusable_functions/Shapes.js/Shapes";
import Loader, { SmallPageLoader } from "../../../reusables/Loader/Loader";
import { FeaturedPlayList, KeyboardDoubleArrowRight, Warning } from "@mui/icons-material";
import { DialogueBox } from "../../../Reusable_functions/DialogueBox/DialogueBox";

const ViewJobPostingState = () => {
  const services = new CCService();
  const timeServices = new TimeService();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const job_id = parseInt(searchParams.get("job_id"));
  const [shortListAllModal, setShortListAllModal] = useState(false);
  const location = useLocation();
  const { company_name } = location.state;

  const [loading, setLoading] = useState(false);

  const initial_jobDetails = {
    id: null,
    job_type: "",
    job_status: "",
    openings: null,
    list_timing: { first_list: null, second_list: null, third_list: null },
    specialization_name: "",
    designation: "",
    role_name: "",
    job_published_time: "",
    closed_timing: null,
    admissible_student_count: null,
  };

  const initial_funnelData = {
    job_id: 1,
    applicants_count: 2,
    applicants_status: "Applied",
  };

  const initial_statusModuleData = {
    applications: {
      active: true,
      number: "01",
      name: "Applications",
      status: "Completed",
      button_text: "Applicants",
    },
    assessment_test: {
      active: true,
      number: "02",
      name: "Assessment Test",
      status: "Ongoing",
      button_text: "Process",
    },
    shortlisted_candidate: {
      active: true,
      number: "03",
      name: "Shortlisted Candidate",
      status: "Ongoing",
      button_text: "View List",
    },
    schedule_interviews: {
      active: true,
      number: "04",
      name: "Schedule Interviews",
      status: "Not started",
      button_text: "View List",
    },
    final_hiring: {
      active: true,
      number: "05",
      name: "Final Hiring",
      status: "Not started",
      button_text: "View List",
    },
  };

  const [jobDetails, setJobDetails] = useState({ ...initial_jobDetails });
  // console.log("ViewJobPostingState ~ jobDetails:", jobDetails)
  const [statusModule, setStatusModule] = useState({ ...initial_statusModuleData });

  const [viewRequest, setVeiwRequest] = useState({
    open: false,
    onClose: () => {
      setVeiwRequest((prev) => ({ ...prev, open: false }));
    },
    data: { job_id: "", company_name: "", on_boarding_date: "", category: "", role: "", specialization: "", industry: "", email: "", contact: "", linkedin: "", company_desc: "" },
  });

  const [timeConfimationModal, setTimeConfirmationModal] = useState({
    open: false,
    onClose: () => {
      setTimeConfirmationModal((prev) => ({ ...prev, open: false }));
    },
    extraData: {
      "Application Due Date": { data_key: "closed_timing", value: "" },
      "List-1 Date": { data_key: "first_list", value: "" },
      "List-2 Date": { data_key: "second_list", value: "" },
      "List-3 Date": { data_key: "third_list", value: "" },
    },
    // extraData: {
    //     "Application Due Date": { key: 'closed_timing', value: '' },
    //     "List-1 Date": { key: 'first_list', value: '' },
    //     "List-2 Date": { key: 'second_list', value: '' },
    //     "List-3 Date": { key: 'third_list', value: '' },
    // }
  });

  const [releaseList, setReleaseList] = useState({
    open: false,
    body: "",
    onClose: () => {
      setReleaseList((prev) => ({ ...prev, open: false }));
    },
  });
  const [dialogue, setDialogue] = useState({ open: false, content: "", actions: { label: "OK", handler: () => {}, color: Color.primary1, variant: "contained" } });

  //styles
  const styles = {
    buttons: {
      mainpagebutton: {
        background: Color.white,
        color: Color.primary1,
        textTransform: "none",
        gap: "8px",
        width: "auto",
        height: "auto",
        ":&hover": {
          background: Color.white,
          color: Color.primary1,
        },
      },
    },
  };

  const convertEpochToDateString = (epochTime) => {
    const date = new Date(epochTime);
    const formattedDate = date.toLocaleDateString("en-CA"); // 'en-CA' for DD-MM-YYYY format
    // console.log("formattedDate:", formattedDate);
    return formattedDate;
  };

  //convert date to epoch
  function convertDateToEpoch(dateString) {
    const date = new Date(dateString);
    return date.getTime();
  }

  useEffect(() => {
    handleMainUseEffect();
  }, []);

  const handleMainUseEffect = async () => {
    const final_data = await handleJobStatsOperations();
    // console.log({ final_data });
  };

  const handleJobStatsOperations = async () => {
    setLoading(true);
    const { fetched_data } = await fetchJobStatsData();
    // console.log("fetched_data:", fetched_data,);

    let temp_job_details = { ...initial_jobDetails };
    // console.log("temp_job_details:", temp_job_details, fetched_data);

    temp_job_details.id = fetched_data.id;
    temp_job_details.job_type = fetched_data.job_type_name;
    temp_job_details.job_status = fetched_data.job_status;
    temp_job_details.designation = fetched_data.designation;
    temp_job_details.openings = fetched_data.openings;
    temp_job_details.applied_count = fetched_data?.applied_count; //FIXME
    temp_job_details.admissible_student_count = fetched_data?.admissible_student_count; //FIXME

    temp_job_details.hired_count = fetched_data?.hired_count; //FIXME
    temp_job_details.list_timing = fetched_data.list_timing;
    temp_job_details.specialization_name = fetched_data.spe_name;
    temp_job_details.role_name = fetched_data.role_name;
    temp_job_details.job_published_time = fetched_data.job_published_time;
    temp_job_details.job_accepted_time = fetched_data.job_accepted_time;
    temp_job_details.closed_timing = fetched_data.closed_timing === 0 ? "" : fetched_data.closed_timing;
    // console.log("handleJobStatsOperations ~ temp_job_details:", temp_job_details)

    setJobDetails(temp_job_details);

    setTimeConfirmationModal((prev) => ({
      ...prev,
      extraData: {
        ...prev.extraData,
        "Application Due Date": { data_key: "closed_timing", value: temp_job_details.closed_timing },
        "List-1 Date": { data_key: "first_list", value: temp_job_details.list_timing?.first_list ?? null },
        "List-2 Date": { data_key: "second_list", value: temp_job_details.list_timing?.second_list ?? null },
        "List-3 Date": { data_key: "third_list", value: temp_job_details.list_timing?.third_list ?? null },
      },
    }));

    //status module
    const currentTime_epoch = Date.now();
    // console.log("currentTime_epoch:", currentTime_epoch);
    // console.log("job_accepted_time:", fetched_data.job_accepted_time, "closed_timing:", fetched_data.closed_timing,
    // "test_timing:", fetched_data.test_timing, "list_timing:", fetched_data.list_timing, "currentTime_epoch:", currentTime_epoch);
    let temp_status_module = { ...initial_statusModuleData };

    if (fetched_data.job_status === "Completed") {
      temp_status_module.applications.status = "Completed";
      temp_status_module.assessment_test.status = "Completed";
      temp_status_module.shortlisted_candidate.status = "Completed";
      temp_status_module.schedule_interviews.status = "Completed";
      temp_status_module.final_hiring.status = "Completed";
      setStatusModule(temp_status_module);
      setLoading(false);

      return;
    }

    if (!["Accept", "Paused", "Closed"].includes(fetched_data.job_status)) {
      temp_status_module.applications.status = "Not started";
      temp_status_module.assessment_test.status = "Not started";
      temp_status_module.shortlisted_candidate.status = "Not started";
      temp_status_module.schedule_interviews.status = "Not started";
      temp_status_module.final_hiring.status = "Not started";
      setStatusModule(temp_status_module);
      setLoading(false);

      return;
    }

    if (fetched_data.job_accepted_time < currentTime_epoch && currentTime_epoch < fetched_data.closed_timing) {
      temp_status_module.applications.status = "Ongoing";
    } else if (currentTime_epoch > fetched_data.closed_timing) {
      temp_status_module.applications.status = "Completed";
    }

    if (fetched_data.job_accepted_time < currentTime_epoch && currentTime_epoch < fetched_data.test_timing) {
      temp_status_module.assessment_test.status = "Ongoing";
    } else if (currentTime_epoch > fetched_data.test_timing) {
      temp_status_module.assessment_test.status = "Completed";
    }

    // if (fetched_data.list_timing.first_interview_time === null || currentTime_epoch < fetched_data.list_timing.first_interview_time) {
    // } else if (fetched_data.list_timing.first_interview_time < currentTime_epoch && currentTime_epoch < fetched_data.list_timing.third_list) {

    // } else if (currentTime_epoch > fetched_data.list_timing.third_list) {

    // }

    if (currentTime_epoch > fetched_data.list_timing.third_list) {
      temp_status_module.shortlisted_candidate.status = "Completed";
      temp_status_module.shortlisted_candidate.active = true;
    } else if (currentTime_epoch < fetched_data.list_timing.first_list) {
      temp_status_module.shortlisted_candidate.status = "Not started";
      temp_status_module.shortlisted_candidate.active = false;
    } else {
      temp_status_module.shortlisted_candidate.status = "Ongoing";
      temp_status_module.shortlisted_candidate.active = true;
    }

    if (fetched_data.list_timing.last_interview_time !== null && fetched_data.list_timing.last_interview_time !== undefined) temp_status_module.schedule_interviews.status = "Completed";
    else if (currentTime_epoch > fetched_data.list_timing.first_interview_time && ![null, undefined].includes(fetched_data.list_timing.first_interview_time)) temp_status_module.schedule_interviews.status = "On going";
    else if (fetched_data.list_timing.first_interview_time === null || fetched_data.list_timing.first_interview_time === undefined) temp_status_module.schedule_interviews.status = "Not Started";
    else temp_status_module.schedule_interviews.status = "Not Started";

    if (fetched_data.list_timing.last_interview_time !== null && fetched_data.list_timing.last_interview_time !== undefined) temp_status_module.final_hiring.status = "On going";
    else if (fetched_data.job_status === "Completed") temp_status_module.final_hiring.status = "Completed";
    else if (fetched_data.job_status === "Closed") temp_status_module.final_hiring.status = "Closed";
    else temp_status_module.final_hiring.status = "Not Started";

    console.log("CHECKING LAST SCHEDULED", temp_status_module);
    setStatusModule(temp_status_module);

    // let temp_fetched_funnel_data = {
    //     "applications": {},
    //     "assessment_test": {},
    //     "shortlisted_candidate": {},
    //     "schedule_interviews": {},
    //     "final_hiring": {},
    // };

    // setFunnelData(temp_fetched_funnel_data);

    setLoading(false);
  };

  const handleShortlistAll = async () => {
    try {
      const postRequest = await services.post(`/jobs/admin/shortlistAll?job_id=${job_id}`);
      console.log("ANkjhdskf", postRequest);

      setShortListAllModal(false);
    } catch (e) {
      window.alert("List already released with normal flow");
      setShortListAllModal(false);
    }
  };
  // const handleJobStatsOperations = async () => {
  //     setLoading(true);
  //     const fetched_data = await fetchJobStatsData();
  //     console.log("fetched_data:", fetched_data);
  //     if (fetched_data.data.length)
  //         setJobDetails(fetched_data.data[0]);
  //     if (fetched_data?.status_module)
  //         setStatusModule(fetched_data.status_module);
  //     if (fetched_data.funnel.length) {
  //         let temp_fetched_funnel_data = {
  //             "applications": {},
  //             "assessment_test": {},
  //             "shortlisted_candidate": {},
  //             "schedule_interviews": {},
  //             "final_hiring": {},
  //         };
  //         const applied = fetched_data.funnel.find((data) => data.applicants_status === 'applied');
  //         temp_fetched_funnel_data.applications = applied ? applied : {};

  //         const assessment_test = fetched_data.funnel.find((data) => data.applicants_status === 'assessment_test');
  //         temp_fetched_funnel_data.assessment_test = assessment_test ? assessment_test : {};

  //         const shortlisted = fetched_data.funnel.find((data) => data.applicants_status === 'shortlisted');
  //         temp_fetched_funnel_data.applications = shortlisted.length ? shortlisted : {};

  //         const schedule_interviews = fetched_data.funnel.find((data) => data.applicants_status === 'schedule_interviews');
  //         temp_fetched_funnel_data.applications = schedule_interviews.length ? schedule_interviews : {};

  //         const final_hiring = fetched_data.funnel.find((data) => data.applicants_status === 'final_hiring');
  //         temp_fetched_funnel_data.applications = final_hiring.length ? final_hiring : {};

  //         setFunnelData(temp_fetched_funnel_data);
  //     }
  //     setLoading(false);
  // }

  const fetchJobStatsData = async () => {
    try {
      // const fetched_data = await services.get(`/jobs/admin/recruiterCompanies/liveJobState?job_id=${job_id}`);
      // console.log("fetched_data:", fetched_data);
      const data_fetch = await services.get(`/jobs/student/jobById?job_id=${job_id}`);
      // console.log("data_fetch:", data_fetch);
      // return fetched_data;
      return { fetched_data: data_fetch.data[0] };
    } catch (err) {
      console.error("Error fetching job stats:", err);
    }
  };

  const handleViewJD = (job_id) => {
    // const sending_data = allRequestData[request_key].find(data => data.job_id === job_id);
    // console.log("sending_data:", sending_data);
    setVeiwRequest((prev) => ({
      ...prev,
      open: true,
      data: {
        ...prev.data,
        company_name: company_name,
        // date: timeServices.convertEpochToDate(parseInt(sending_data.job_published_time)) ?? 'No data',
        // time: timeServices.convertEpochToTime(parseInt(sending_data.job_published_time)) ?? 'No data',
        // category: sending_data.category ?? 'No data',
        // role: sending_data.roles ?? 'No data',
        // specialization: sending_data.spe ?? 'No data',
        // status: sending_data.job_status ?? 'No data',
        job_id: job_id,
        // buttons: true
      },
    }));
  };

  const handleChangeTiming = ({ target }, key) => {
    const { name, value } = target;
    // console.log("handleChangeTiming ~ name, value:", name, value);
    setTimeConfirmationModal((prev) => ({
      ...prev,
      open: true,
      extraData: {
        ...prev.extraData,
        [key]: {
          data_key: name,
          value: value,
        },
      },
    }));
  };

  function TimeConfirmationModal({ open, onClose, extraData }) {
    // console.log("TimeConfirmationModal ~ extraData:", extraData)
    const placeOmodalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 350,
      height: 200,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      borderRadius: "20px",
      boxShadow: 24,
      p: 4,
    };

    const handleYesTimeChange = async () => {
      try {
        const putBody = {
          job_id: job_id,
          [extraData["Application Due Date"].data_key]: convertDateToEpoch(extraData["Application Due Date"].value),
          list_timing: {
            [extraData["List-1 Date"].data_key]: convertDateToEpoch(extraData["List-1 Date"].value),
            [extraData["List-2 Date"].data_key]: convertDateToEpoch(extraData["List-2 Date"].value),
            [extraData["List-3 Date"].data_key]: convertDateToEpoch(extraData["List-3 Date"].value),
          },
        };
        // console.log("handleYesTimeChange ~ putBody:", putBody)
        const data_fetch = await services.put(`jobs/admin/updateJobTimings`, putBody);
        // console.log("handleYesTimeChange ~ data_fetch:", data_fetch);
        setJobDetails((prev) => ({
          ...prev,
          closed_timing: convertDateToEpoch(extraData["Application Due Date"].value),
          list_timing: {
            first_list: convertDateToEpoch(extraData["List-1 Date"].value),
            second_list: convertDateToEpoch(extraData["List-2 Date"].value),
            third_list: convertDateToEpoch(extraData["List-3 Date"].value),
          },
        }));
        onClose();
      } catch (err) {
        console.error("Error fetching job stats:", err);
      }
    };

    const handleCancelTimeChange = () => {
      onClose();
    };

    return (
      <Modal open={open} onClose={onClose} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
        <Box sx={{ ...placeOmodalStyle, ...DivCenter, flexDirection: "column", gap: "40%" }}>
          <Typography sx={{ fontSize: "20px" }}>Do you want to set this time?</Typography>
          <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
            <PrimaryButtonOutlined variant="oultined" onClick={() => handleCancelTimeChange()}>
              No
            </PrimaryButtonOutlined>
            <PrimaryButton onClick={() => handleYesTimeChange()}>Yes</PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  function ReleaseNextListModal({ open, onClose, body }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };

    const [modalLoading, setModalLoading] = useState({
      button: false,
    });

    //Modal Button Functions
    const handleReleaseNextList = async (job_id) => {
      setModalLoading((prev) => ({ ...prev, button: true }));
      try {
        const response = await services.post(`/jobs/admin/releaseShortlist?job_id=${job_id}`);
        console.log("handleReleaseNextList ~ response:", response);
        await handleMainUseEffect();
        onClose();
        setDialogue((prev) => ({
          ...prev,
          open: true,
          title: "List Release",
          content: `Next List has been released`,
          actions: { ...prev.actions, handler: () => setDialogue((prev) => ({ ...prev, open: false })) },
        }));
      } catch (error) {
        console.error("Error releasing next list:", error);
        if (error?.response?.status === 400) {
          onClose();
          setDialogue((prev) => ({
            ...prev,
            open: true,
            title: "List Release",
            content: "List was not released. " + error?.response?.data?.message,
            actions: { ...prev.actions, handler: () => setDialogue((prev) => ({ ...prev, open: false })) },
          }));
        } else {
          setDialogue((prev) => ({
            ...prev,
            open: true,
            title: "List Release",
            content: "Error Releasing Next List.",
            actions: { ...prev.actions, handler: () => setDialogue((prev) => ({ ...prev, open: false })) },
          }));
        }
      }
      setModalLoading((prev) => ({ ...prev, button: false }));
    };

    return (
      <Modal open={open} onClose={!modalLoading.button ? onClose : {}}>
        <Box sx={{ ...modalBoxStyle }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Release Next List
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            {body}
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              gap: "10px",
            }}
          >
            <PrimaryButtonOutlined
              disabled={modalLoading.button}
              variant="outlined"
              onClick={() => onClose()}
              sx={{
                textTransform: "none",
                borderColor: Color.neutralMidGrey,
                color: Color.neutralMidGrey,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                },
              }}
            >
              Cancel
            </PrimaryButtonOutlined>
            <PrimaryButton
              disabled={modalLoading.button}
              variant="outlined"
              onClick={() => handleReleaseNextList(job_id)}
              sx={{
                textTransform: "none",
                borderColor: Color.primary1,
                background: Color.primary1,
                color: Color.white,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  background: Color.primary1,
                  borderColor: Color.primary1,
                  color: Color.white,
                },
              }}
            >
              {modalLoading.button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Yes"}
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      {loading ? (
        <SmallPageLoader show={loading} />
      ) : (
        <Box className="main_box">
          <SideNavigationBar />
          <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
          <Box className="box__mainContent" sx={{ width: "76%", height: "100%", display: "flex", flexDirection: "column", gap: "33px", alignItems: "flex-start" }}>
            <Box sx={{ ...DivCenter, mt: "10px", justifyContent: "space-between", width: "100%" }}>
              <Box sx={{ ...DivCenter, mt: "10px", gap: "33px" }}>
                <IconButton onClick={() => navigate(-1)} style={{ height: "30px", width: "30px" }}>
                  <ArrowBackIosIcon className="Arrow-back-btn" />
                </IconButton>
                <Typography sx={{ fontSize: "32px", color: "black" }}>{company_name ?? "No Company Name"}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, gap: "20px" }}>
                <Button
                  // disabled={backup_RequestData["List 3"][0]?.id}
                  variant="outlined"
                  onClick={() => setReleaseList((prev) => ({ ...prev, open: true, body: "Are you sure you want to release the next list?" }))}
                  sx={styles.buttons.mainpagebutton}
                >
                  <FeaturedPlayList />
                  Release Next List <KeyboardDoubleArrowRight />
                </Button>

                <Button
                  // disabled={backup_RequestData["List 3"][0]?.id}
                  variant="outlined"
                  onClick={() => setShortListAllModal(true)}
                  sx={styles.buttons.mainpagebutton}
                >
                  <FeaturedPlayList />
                  Shortlist all <KeyboardDoubleArrowRight />
                </Button>
              </Box>
            </Box>

            <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", gap: "16px", mb: "33px" }}>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Posted On:</Typography>
                <Typography style={{ width: "82%" }}>{timeServices.convertEpochToTime(parseInt(jobDetails.job_accepted_time)) + ", " + timeServices.convertEpochToDate(parseInt(jobDetails.job_accepted_time))}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Job Type:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.job_type}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Designation:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.designation}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey, hyphens: "auto" }}>Specialization:</Typography>
                <Typography style={{ width: "82%" }}>{ellipsisFunction(jobDetails.specialization_name, 25)}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Role:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.role_name}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Requirements:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.openings}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Application Due Date:</Typography>
                <Box sx={{ width: "82%" }}>
                  {console.log("COMPLETE JOB", jobDetails)}
                  <input name="closed_timing" value={convertEpochToDateString(jobDetails.closed_timing)} onChange={(e) => handleChangeTiming(e, "Application Due Date")} style={{ color: Color.primary1, border: "none" }} type="date" disabled={jobDetails.job_status === "Completed" ? true : false} />
                </Box>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>List-1 Date:</Typography>
                {jobDetails.list_timing?.first_list ? (
                  <Box sx={{ width: "82%" }}>
                    <input name="first_list" disabled={jobDetails.job_status === "Completed"} value={convertEpochToDateString(jobDetails.list_timing.first_list)} onChange={(e) => handleChangeTiming(e, "List-1 Date")} style={{ color: Color.primary1, border: "none" }} type="date" />
                  </Box>
                ) : (
                  <Typography sx={{ width: "82%" }}>Job is Not accepted by admin yet.</Typography>
                )}
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>List-2 Date:</Typography>
                {jobDetails.list_timing?.second_list ? (
                  <Box sx={{ width: "82%" }}>
                    <input name="second_list" disabled={jobDetails.job_status === "Completed"} value={convertEpochToDateString(jobDetails.list_timing.second_list)} onChange={(e) => handleChangeTiming(e, "List-2 Date")} style={{ color: Color.primary1, border: "none" }} type="date" />
                  </Box>
                ) : (
                  <Typography sx={{ width: "82%" }}>Job is Not accepted by admin yet.</Typography>
                )}
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>List-3 Date:</Typography>
                {jobDetails.list_timing?.third_list ? (
                  <Box sx={{ width: "82%" }}>
                    <input name="third_list" disabled={jobDetails.job_status === "Completed"} value={convertEpochToDateString(jobDetails.list_timing.third_list)} onChange={(e) => handleChangeTiming(e, "List-3 Date")} style={{ color: Color.primary1, border: "none" }} type="date" />
                  </Box>
                ) : (
                  <Typography sx={{ width: "82%" }}>Job is Not accepted by admin yet.</Typography>
                )}
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Applied:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.applied_count}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Admissable:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.admissible_student_count}</Typography>
              </Box>
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Typography style={{ width: "18%", color: Color.neutralMidGrey }}>Hired:</Typography>
                <Typography style={{ width: "82%" }}>{jobDetails.hired_count}</Typography>
              </Box>
              {/* <Box sx={{ ...DivCenter, width: '100%' }}>
                      <Typography style={{ width: '18%', color: Color.neutralMidGrey }}>Close Date:</Typography>
                      <Box sx={{ width: '82%' }}><input value={jobDetails.list_timing} style={{ color: Color.primary1, border: 'none' }} type='date' /></Box>
                </Box> */}
            </Box>
            <Box sx={{ mb: "33px" }}>
              <Button sx={{ width: "197px", height: "44px", fontSize: "18px", textTransform: "none", color: Color.neutralBlack, borderColor: Color.primary_gradient, borderRadius: "10px" }} variant="outlined" onClick={() => handleViewJD(job_id)}>
                View JD
              </Button>
            </Box>
            <Box sx={{ ...DivCenter, mb: "33px" }}>
              {Object.keys(statusModule).map((module, module_index) => (
                <Box sx={{ ...DivCenter, alignItems: "flex-start", height: "100%", mb: "33px" }}>
                  <Box sx={{ ...DivCenter, flexDirection: "column", height: "100%" }}>
                    <Box sx={{ ...DivCenter, width: "100%", height: "50%", flexDirection: "column" }}>
                      {statusModule[module].status === "Completed" ? <CheckCircleIcon sx={{ color: Color.primary1 }} /> : statusModule[module].status === "Ongoing" ? <RadioButtonCheckedIcon sx={{ color: Color.primary1 }} /> : <RadioButtonUncheckedIcon sx={{ color: Color.primary1 }} />}
                      <Typography sx={{ textAlign: "center", fontSize: "34.51px", fontFamily: "Times New Roman", fontWeight: 700, color: statusModule[module].active ? Color.neutralBlack : Color.neutralMidGrey }}>{statusModule[module].number}</Typography>
                      <Typography sx={{ textAlign: "center", fontSize: "16px", fontWeight: 400, color: statusModule[module].active ? Color.neutralBlack : Color.neutralMidGrey }}>{statusModule[module].name}</Typography>
                      <Typography sx={{ textAlign: "center", fontSize: "14px", fontWeight: 400 }}>
                        <span style={{ color: Color.neutralMidGrey }}>Status:</span> <span style={{ color: statusModule[module].status === "Ongoing" ? "#F5A536" : statusModule[module].status === "Completed" ? "green" : "black" }}>{statusModule[module].status}</span>
                      </Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, height: "50%" }}>
                      {statusModule[module].active && ["01", "02", "03"].includes(statusModule[module].number) && (
                        <Button
                          variant="outlined"
                          disabled={!statusModule[module].active}
                          onClick={() => {
                            if (module_index === 2)
                              navigate(`/StudentShortlist?job_id=${job_id}`, {
                                state: {
                                  company_name: company_name,
                                  designation: jobDetails.designation,
                                  job_type: jobDetails.job_type,
                                  role_name: jobDetails.role_name,
                                  specialization_name: jobDetails.specialization_name,
                                  openings: jobDetails.openings,
                                },
                              });
                            else if (module_index === 1)
                              navigate(`/JobStudentProcess?job_id=${job_id}`, {
                                state: {
                                  company_name: company_name,
                                  designation: jobDetails.designation,
                                  job_type: jobDetails.job_type,
                                  role_name: jobDetails.role_name,
                                  specialization_name: jobDetails.specialization_name,
                                  openings: jobDetails.openings,
                                },
                              });
                            else
                              navigate(`/JobStudentApplicants?job_id=${job_id}`, {
                                state: {
                                  company_name: company_name,
                                  designation: jobDetails.designation,
                                  job_type: jobDetails.job_type,
                                  role_name: jobDetails.role_name,
                                  specialization_name: jobDetails.specialization_name,
                                  openings: jobDetails.openings,
                                },
                              });
                          }}
                          sx={{ mt: "12px", borderRadius: "22px", border: "1px solid var(--Primary, #165983)", textTransform: "none" }}
                        >
                          {statusModule[module].button_text}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ ...DivCenter, width: "110px" }}>{module_index !== Object.keys(statusModule).length - 1 && <hr style={{ width: "80px" }} />}</Box>
                </Box>
              ))}
            </Box>
            {/* <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '225px' }}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column' }}>
                        <Typography style={{ color: Color.neutralMidGrey }}>Eligible</Typography>
                        <Typography style={{ fontSize: '30px', color: Color.primary1 }}>{funnelData.applicants_count}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column' }}>
                        <Typography style={{ color: Color.neutralMidGrey }}>Qualified</Typography>
                        <Typography style={{ fontSize: '30px', color: Color.primary1 }}>{funnelData.applicants_count}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column' }}>
                        <Typography style={{ color: Color.neutralMidGrey }}>Shortlisted</Typography>
                        <Typography style={{ fontSize: '30px', color: Color.primary1 }}>{funnelData.applicants_count}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column' }}>
                        <Typography style={{ color: Color.neutralMidGrey }}>Hired</Typography>
                        <Typography style={{ fontSize: '30px', color: Color.primary1 }}>{funnelData.applicants_count}</Typography>
                    </Box>
                </Box>
                <Box sx={{ ...DivCenter, height: 'auto', width: '100%' }}>
                    <FunnelComponent />
                </Box> */}
          </Box>
          <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
          <SlideInRightModal open={viewRequest.open} onClose={viewRequest.onClose} data={viewRequest.data} />
          <TimeConfirmationModal open={timeConfimationModal.open} onClose={timeConfimationModal.onClose} extraData={timeConfimationModal.extraData} />
          <ReleaseNextListModal open={releaseList.open} onClose={releaseList.onClose} body={releaseList.body} />
          <DialogueBox
            open={dialogue.open}
            onClose={dialogue.actions.handler}
            title={dialogue.title}
            actions={dialogue.actions}
            content={
              <>
                <p>{dialogue.content}</p>
              </>
            }
          />
        </Box>
      )}

      <Modal open={shortListAllModal} onClose={() => setShortListAllModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ background: "#fff", padding: "20px", borderRadius: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Warning sx={{ color: "#DD8E39", fontSize: "80px" }} />
          </Box>
          <Typography sx={{ marginBlock: "20px" }}>Are you sure you want to release all the applicable student </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <Button onClick={() => setShortListAllModal(false)}>Cancel</Button>

            <Button onClick={() => handleShortlistAll()} sx={{ background: Color.primary1, color: "#fff", padding: "8px 15px", "&:hover": { background: Color.primary1, color: "#fff" } }}>
              Release{" "}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ViewJobPostingState;
