import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./ModalStStyles.css";
import Modal from "react-bootstrap/Modal";
// import httpService from "../../../services/httpService";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext'
import { green } from "@mui/material/colors";
import ChatModal from "./ModalChat";
import ReScheduleConfirmation from "./ReScheduleConfirmationModal";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { get_specialCharReplace, send_specialCharReplace } from "../../../services/specialCharReplace";
import { calculateEpochTimeInLocalTime, extractDateFromDateString } from "../../../services/specialCharReplace";
import ModalSt from "../../Dashboard/College1x1StudentDetails/ModalSt";
import CircularProgress from '@mui/material/CircularProgress';

export default function ModalSupportTicket(props) {
    const service = new CCService();
    const [user_type_id, SetUser_type_id] = useState(null);
    const rowsData = useContext(RowsContext);
    const [openChat, setOpenChat] = useState(false);
    const [openRescheduleModal, setRescheduleModal] = useState(false);

    const [selectStatus, setSelectStatus] = useState(false)
    const [selectExecutive, setSelectExecutive] = useState(false)
    const [ExecutiveDropDown, setExecutiveDropDown] = useState(false)
    const [toggleChatName, setToggleChatName] = useState(false)

    const [executive_name, setExecutive_name] = useState()
    const [executive_id, setExecutive_id] = useState();
    const [adminNotes, setAdminNotes] = useState(false)

    const [rowsD, setRowsD] = useState(rowsData);
    const { show, setShow, headCells, from_table, path, supportTicketDetails, setSupportTickerDetails, ticket_id, category_name, supportTicketModalDetails, supportTicketCategoryNameDetails, setSupportTickerCategoryNameDetails, setSupportTickerModalDetails, chatDetails, userDetails, setChatDetails } = props;
    console.log("ticket user and chat details", supportTicketModalDetails, chatDetails, userDetails);
    // let filteredFeedbackDetails = supportTicketResolveFeedbackDetails.filter((item) => ticket_id === item.ticket_id)

    // console.log("supportTicketResolveFeedbackDetails", supportTicketResolveFeedbackDetails, filteredFeedbackDetails);
    const [name, setName] = useState(rowsData[from_table?.index]?.name);
    const [special, setSpecial] = useState()
    const [jd, setJD] = useState()
    const [formInputs, setFormInputs] = useState({ executive_id: null, status: supportTicketModalDetails[0]?.ticket_details?.ticket_status, notes: supportTicketModalDetails[0]?.ticket_details.notes })



    // for all the activity details
    const [activityLoading, setActivityLoading] = useState(false);
    const [feedbackFields, setFeedbackFields] = useState({})
    const [mcqFeedbackFields, setMcqFeedbackFields] = useState({})
    const [studentFeedbackFields, setStudentFeedbackFields] = useState({})
    const [duration_name, setDuration_name] = useState()
    const [activityDetailsClick, setActivityDetailsClick] = useState({})
    const [orderHistory, setOrderHistory] = useState([])
    // feedback fields
    const [mentorFeedbackFields, setMentorFeedbackFields] = useState([]);
    const [changedPairs, setChangedPairs] = useState([]);
    const [mentorFeedbackMCQs, setMentorFeedbackMCQs] = useState([]);
    // const [selectedOptions, setSelectedOptions] = useState(false)

    const [reconsiderRequest, setReconsiderRequest] = useState([])
    const [specialization_role_slots_jd, setSpecialization_role_slots_jd] = useState([])
    const [resumeRequest, setResumeRequest] = useState([])
    const [mentor_feedback_submitted_Request, setMentor_feedback_submitted_RequestRequest] = useState([])
    const [mentor_joined_Request, setMentor_joined_Request] = useState([])
    const [mentor_join_time_Request, setMentor_join_time_Request] = useState([])
    const [student_join_time_Request, setStudent_join_time_Request] = useState([])
    const [student_feedback_submitted_Request, setStudent_feedback_submitted_Request] = useState([])
    const [mark_absent_Request, setMark_absent_Request] = useState([])
    const [mentor_missed_Request, setMentor_missed_Request] = useState(false)
    const [order_flag_changes, setOrder_flag_changes] = useState([])
    const [allItems, setAllItems] = useState([])
    const [notification_ids, setNotification_ids] = useState([])
    const [internet_stats, setInternet_stats] = useState({})
    const [mentor_missed, setmentor_missed] = useState([])
    const [chatMessages, setChatMessages] = useState([])
    const [activityDetailsOpen, setActivityDetailsOpen] = useState(false)
    const callModalToOpen = () => {
        console.log("Modal open fucntion called")
        setActivityDetailsOpen(true);
    }

    const findChangedPairs = (orderHistoryDetails) => {
        const updatedData = [...orderHistoryDetails]; // Create a copy of the data array
        let prevValue = updatedData[0].history_timestamp;

        for (let i = 1; i < updatedData.length; i++) {
            const currentTimestamp = updatedData[i].history_timestamp;
            updatedData[i].history_timestamp = prevValue;
            prevValue = currentTimestamp; // Store the original value for the next iteration
        }
        updatedData[0].history_timestamp = updatedData[0].timestamp;
        console.log("manipulated pairs", updatedData);
        orderHistoryDetails = updatedData;

        console.log("orderHistory", orderHistoryDetails);
        let temp_order_history = orderHistoryDetails?.slice(-1)
        setmentor_missed(temp_order_history)
        console.log("the last two order history", temp_order_history);
        const changedPairsArray = [];
        for (let i = 1; i < orderHistoryDetails.length; i++) {
            const previousObject = orderHistoryDetails[i - 1];
            const currentObject = orderHistoryDetails[i];
            if (previousObject.id === currentObject.id) {
                const change = {};
                for (const key in currentObject) {
                    if (currentObject[key] !== previousObject[key]) {
                        change[key] = {
                            previousValue: previousObject[key],
                            currentValue: currentObject[key],
                        };
                    }
                }
                if (!change.hasOwnProperty("history_timestamp")) {
                    const lastIndex = i - 1;
                    if (lastIndex >= 0) {
                        change.history_timestamp = {
                            previousValue: orderHistoryDetails[lastIndex].history_timestamp,
                            currentValue: currentObject.history_timestamp,
                        };
                    }
                }
                if (Object.keys(change).length > 0) {
                    change.id = currentObject.id;
                    changedPairsArray.push(change);
                }
            }
        }
        return changedPairsArray;
    };

    const orderHistoryGetter = async (order_id) => {
        try {
            const res = await service.get(`/superAdmin/order_history?order_id=${order_id}`)
            console.log("order details response:", res.order_history)
            return res.order_history
        } catch (err) {
            console.log(err)
        }
    }
    const handleActivityDetailsClick = async (order_id, end_time) => {
        setActivityLoading(true);
        try {
            const res = await service.get(`/superAdmin/activityDetailsOfAStudent?order_id=${order_id}`)
            console.log("student details response:", res.data)
            const temp_data = {
                ...res.data,
                order_details: {
                    ...res.data.order_details,
                    order_id: order_id
                }
            };
            if (new Date().getTime() - new Date(res.data?.order_details.slot_date).getTime() + 19800000 >= end_time + 900000 && res.data?.order_details.order_flag === "Scheduled") {
                setMentor_missed_Request(true)
            }
            if (res.data?.mentor_feedback?.fields_feedback != null || res.data?.mentor_feedback != null) {
                const feedback_fields = JSON.parse(res.data?.mentor_feedback?.fields_feedback)
                setFeedbackFields(feedback_fields)
            }

            if (res.data?.mentor_feedback?.mcq_feedback != null || res.data?.mentor_feedback != null) {
                const mcq_feedback_fields = JSON.parse(res.data?.mentor_feedback?.mcq_feedback)
                setMcqFeedbackFields(mcq_feedback_fields)
            }

            if (res.data?.student_feedback?.student_feedback != null || res.data?.student_feedback != null) {
                const student_feedback_fields = JSON.parse(res.data?.student_feedback?.student_feedback)
                console.log("student_feedback_fields", student_feedback_fields);
                setStudentFeedbackFields(student_feedback_fields)
            }
            if (res.data.chat !== null) {
                const messages = JSON.parse(res.data?.chat.chat).messages;
                setChatMessages(messages)
            }
            setActivityDetailsClick(temp_data)
            let orderHistoryDetails = await orderHistoryGetter(order_id)
            console.log("order history details in table", orderHistoryDetails);
            let pairChanged = findChangedPairs(orderHistoryDetails)
            console.log("pair last timestamp", pairChanged[pairChanged.length - 1]?.history_timestamp);
            // setOrderHistory(orderHistoryDetails)
            // const temp_order_history = orderHistoryDetails?.filter()
            setChangedPairs(pairChanged);
            console.log("changed pair", pairChanged);
            const reconsider_request = pairChanged?.filter((item) => item.reconsider)
            const spe_role_request = pairChanged
                ?.filter((item) =>
                (item.spe_id ||
                    item.role_id ||
                    item.slots ||
                    item.jd_id)
                );
            const resume_request = pairChanged
                ?.filter((item) => item.resume);
            const mentor_feedback_request = pairChanged?.filter((item) => item.mentor_feedback_submitted)
            const mentor_joined_request = pairChanged?.filter((item) => item.mentor_joined)
            const mentor_join_time_request = pairChanged?.filter((item) => item.mentor_join_time)
            const is_absent_request = pairChanged?.filter((item) => item.is_absent)
            const student_feedback_request = pairChanged?.filter((item) => item.student_feedback_submitted)
            const student_join_time_request = pairChanged?.filter((item) => item.student_join_time)
            const notifications_request = pairChanged?.filter((item) => item.notification_ids)
            const filtered_student_join_time_data = student_join_time_Request.filter((item) => JSON.parse(item?.student_join_time?.previousValue) == null)

            const order_flag_changed = pairChanged?.filter((item) => item.order_flag)
            console.log("order flag changes", order_flag_changed, spe_role_request, resume_request);
            const allItems = [];
            // Combine all the arrays into a single array
            allItems.push(...order_flag_changed);
            allItems.push(...spe_role_request);
            allItems.push(...resume_request);
            allItems.push(...mentor_feedback_request);
            allItems.push(...mentor_join_time_request);
            allItems.push(...is_absent_request);
            allItems.push(...student_feedback_request);
            allItems.push(...student_join_time_request);
            allItems.push(...reconsider_request);
            allItems.push(...notifications_request);
            console.log("all items array before", allItems);
            allItems.sort((a, b) => {
                const timestampA = new Date(a.history_timestamp?.currentValue).getTime();
                const timestampB = new Date(b.history_timestamp?.currentValue).getTime();
                return timestampA - timestampB;
            });

            console.log("all items array after", allItems);
            setAllItems(allItems)
            setReconsiderRequest(reconsider_request)
            setSpecialization_role_slots_jd(spe_role_request)
            setResumeRequest(resume_request)
            setMentor_feedback_submitted_RequestRequest(mentor_feedback_request)
            setMentor_joined_Request(mentor_joined_request)
            setMentor_join_time_Request(mentor_join_time_request)
            setMark_absent_Request(is_absent_request)
            setStudent_join_time_Request(student_join_time_request)
            setStudent_feedback_submitted_Request(student_feedback_request)
            setOrder_flag_changes(order_flag_changed)
            setNotification_ids(notifications_request)
            console.log("reconsider Request pair", reconsider_request);
            callModalToOpen()
            // sortByTimestamp(orderHistoryDetails,reconsider_request,spe_role_request,resume_request,mentor_feedback_request,mentor_joined_request,mentor_join_time_request,is_absent_request,student_feedback_request,student_join_time_request)
        } catch (err) {
            console.log(err)
        }
        setActivityLoading(false);
    }

    const modalHide = () => {
        setToggleChatName(false)
        setOpenChat(false);
        setShow(!show);
        console.log(!show + "bruh --------");
        setName("")
        setSpecial("")
        setJD("")
        setFormInputs({})
        setExecutiveDropDown(false)
        setSelectExecutive(false)
        setSelectStatus(false)
        setAdminNotes(false)
    };

    console.log("form inputs", formInputs);

    const handleChange = (e, index) => {
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);

        if (e.target.name === "status_name") {
            console.log("header_desc")
            setFormInputs({ ...formInputs, status: e.target.value })
        }
        else if (e.target.name === "mentor_id") {
            console.log("mentor id selected", e.target.value);
            setExecutive_id(e.target.value);
            setFormInputs({ ...formInputs, executive_id: e.target.value });
        } else if (e.target.name === "admin_notes") {
            setFormInputs({ ...formInputs, notes: e.target.value });
        }
    }

    const handleSaveButton = () => {
        setSelectStatus(false)
        setSelectExecutive(false)
        setExecutiveDropDown(false)
        setAdminNotes(false)
        handlePutRequest()
    }

    const handleEditButton = () => {
        const updatedFormInputs = {
            status: supportTicketModalDetails[0]?.ticket_details?.ticket_status,
            notes: supportTicketModalDetails[0]?.ticket_details.notes,
            executive_id: null
        };

        const trimmedExecutiveName = (supportTicketModalDetails[0]?.ticket_details?.executive_name || '').trim();

        supportTicketCategoryNameDetails?.forEach(mentor => {
            if (mentor.first_name === trimmedExecutiveName) {
                updatedFormInputs.executive_id = mentor.user_id;
            }
        });

        setFormInputs(updatedFormInputs);


        setSelectStatus(true)
        setSelectExecutive(true)
        setExecutiveDropDown(true)
        setAdminNotes(true)
    }


    useEffect(() => {
        console.log("Brother")
        console.log("from table inside useEffect of modal input:", from_table)
        const handleEffect = async () => {
        }
        handleEffect();
    }, [])
    console.log("updated Support Details before:", supportTicketDetails);


    const handlePutRequest = () => {
        let putData = {};
        if (formInputs.executive_id) {
            putData = {
                "ticket_id": ticket_id,
                "status": formInputs.status ? formInputs.status : supportTicketModalDetails[0]?.ticket_details?.ticket_status,
                "executive_id": formInputs.executive_id,
                "notes": send_specialCharReplace(formInputs.notes)
            }
        } else {
            putData = {
                "ticket_id": ticket_id,
                "status": formInputs.status ? formInputs.status : supportTicketModalDetails[0]?.ticket_details?.ticket_status,
                "notes": send_specialCharReplace(formInputs.notes)
            }
        }

        //get request here
        service
            .put(`/supportTicket/ticket`, putData)
            .then((res) => {
                const updatedStudentDetails = [...supportTicketModalDetails];
                updatedStudentDetails[0].ticket_details = updatedStudentDetails[0].ticket_details || {};
                updatedStudentDetails[0].ticket_details.ticket_status = formInputs.status ? formInputs.status : supportTicketModalDetails[0]?.ticket_details?.ticket_status;
                updatedStudentDetails[0].ticket_details.notes = formInputs.notes ? formInputs.notes : supportTicketModalDetails[0]?.ticket_details?.notes;
                const mentorToUpdate = supportTicketCategoryNameDetails.find(mentor => mentor.user_id === formInputs.executive_id);

                if (mentorToUpdate) {
                    updatedStudentDetails[0].ticket_details.executive_name = mentorToUpdate.first_name;
                }

                const updatedSupportDetails = supportTicketDetails.map(ticket => {
                    if (ticket.ticket_id === ticket_id) {
                        const updatedTicket = { ...ticket };
                        updatedTicket.ticket_status = formInputs.status || updatedTicket.ticket_status;
                        if (mentorToUpdate) {
                            updatedTicket.executive_name = mentorToUpdate.first_name;
                        }
                        return updatedTicket;
                    }
                    return ticket;
                });


                console.log("updatedStudentDetails:", updatedStudentDetails);
                console.log("updated Support Details after:", updatedSupportDetails);

                setSupportTickerModalDetails(updatedStudentDetails);
                setSupportTickerDetails(updatedSupportDetails)


            })
            .catch((err) => {
                console.log("error message", err);
                window.alert(err.response.data.errMess)
            });
    }



    const get_data_from_form = (name) => {
        console.log("HandleEditUser ~ special", special)
        console.log("🚀HandleEditUser ~ name", name)
        console.log("🚀HandleEditUser ~ id", from_table?.id)

        if (path === "/RoleType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim(), undefined, undefined, special.trim(), jd)
                setName("")
                setSpecial("")
                setJD("")
            }
            else {
                console.log("PUT")
                console.log("PUT - special value:", special)
                props.data_getter(name.trim(), from_table?.id, from_table?.index, special, jd)
            }
        }

        else if (path === "/UserType" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim())
                setName("")
            }
            else {
                console.log("PUT")
                props.data_getter(name.trim(), from_table?.id, from_table?.index)
            }
        }

        setShow(false)
    }


    const OpenChatting = (e) => {
        setToggleChatName(true)
        setOpenChat(true);
    }

    const CloseChatting = (e) => {
        setToggleChatName(false)
        setOpenChat(false);
    }

    console.log("TogglechatName", toggleChatName);

    console.log("rows data in modal input file", rowsData);
    console.log("spec_data", props.spec_data);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log('inside handlepress')
            get_data_from_form(name)
        }
    }

    const MarkResolve = () => {
        setRescheduleModal(true);

    }



    const description = supportTicketModalDetails[0]?.ticket_details.description;
    const descriptionWithLineBreaks = get_specialCharReplace(description)
    console.log("description replaced", descriptionWithLineBreaks);
    const notes = supportTicketModalDetails[0]?.ticket_details.notes;
    const notesWithLineBreaks = get_specialCharReplace(notes)
    function convertEpochToGMTString(epochTimestamp) {
        const date = new Date(epochTimestamp);
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short'
        };

        return date.toLocaleString('en-US', options);
    }

    const file = supportTicketModalDetails[0]?.ticket_details.files;
    console.log('files', file);

    console.log("creation of date", new Date(supportTicketModalDetails[0]?.ticket_details?.time_of_creation).getDate());
    return (
        <>
            <Modal
                className="ModalSt-contain"
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                // style={{ backdropFilter: "blur(5px)" }}
                onHide={() => modalHide()}
            >
                {console.log("Value of state name in Modal Input:", name)}
                {console.log("Value of state special in Modal Input:", special)}
                <Modal.Body
                    style={{
                        position: "absolute",
                        top: "-24px",
                        left: "127px",
                        height: "auto",
                        lineHeight: "1.4",
                        padding: "14px 28px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        width: "880px",
                        float: "right",
                    }}>
                    <div>
                        <CloseIcon className="closeIcon" style={{ float: "right" }} onClick={() => setShow(false)} />

                        <div style={{ marginTop: "30px", width: "50%" }}>
                            <p className="st-ticket-number">Ticket Number : {ticket_id}</p>
                            <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex" }}>

                                {selectStatus && selectExecutive && ExecutiveDropDown ? <>
                                    <button
                                        className={`st-chat-now`}
                                        onClick={() => { handleSaveButton() }}
                                        style={{ width: "45%", marginTop: ".5rem", marginBottom: "0.5rem" }}
                                    >
                                        Save
                                    </button>
                                </> : <>
                                    <button
                                        className={`st-chat-now`}
                                        onClick={() => { handleEditButton() }}
                                        style={{ width: "45%", marginTop: ".5rem", marginBottom: "0.5rem" }}
                                    >
                                        Edit
                                    </button>
                                </>}
                                {openChat ? <>
                                    <div style={{cursor:"pointer"}}>
                                        <button className="st-close-chat" type="button" style={{cursor:"pointer"}} onClick={(e) => { CloseChatting(e) }}>Close chat</button>
                                    </div>
                                </> : <>
                                    <div style={{cursor:"pointer"}}>
                                        <button className="st-close-chat" type="button"  onClick={(e) => { OpenChatting(e) }}>Chat now</button>
                                    </div>
                                </>}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                    <p className="label-grey-text pTagsHere">Date:</p>
                                    <p className="label-grey-text pTagsHere">Time:</p>
                                    <p className="label-grey-text pTagsHere">Category:</p>
                                    <p className="label-grey-text pTagsHere">Status:</p>
                                    <p className="label-grey-text pTagsHere" style={{ marginTop: selectStatus ? "10px" : "4px" }}>Origin:</p>
                                    <p className="label-grey-text pTagsHere" style={{ marginTop: selectStatus ? "10px" : "4px" }}>Raised By:</p>
                                    <p style={{ marginTop: "10px" }} className="label-grey-text pTagsHere">Assigned to:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                    <p style={{ marginLeft: "20px" }} className="pTagsHere">
                                        {extractDateFromDateString(supportTicketModalDetails[0]?.ticket_details?.time_of_creation)}
                                    </p>
                                    {console.log("date in gmt", convertEpochToGMTString(supportTicketModalDetails[0]?.ticket_details?.time_of_creation))}
                                    <p style={{ marginLeft: "20px" }} className="pTagsHere">
                                        {calculateEpochTimeInLocalTime(supportTicketModalDetails[0]?.ticket_details?.time_of_creation)}

                                    </p>
                                    <p style={{ marginLeft: "20px" }} className="pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.category_name}</p>
                                    {selectStatus ? <>
                                        <select name="status_name" id="selectList" style={{ height: "35px", marginLeft: "1rem" }} className="form-control inputFields" onChange={(e) => handleChange(e)} value={formInputs.status ? formInputs.status : supportTicketModalDetails[0]?.ticket_details?.ticket_status}>
                                            <option className="options-value" value={""} disabled selected>Select Status</option>
                                            <option className="options-value" value={"Open"}  >Open</option>
                                            <option className="options-value" value={"Closed"}  >Closed</option>
                                        </select>
                                    </> : <><p style={{ marginLeft: "20px", color: "green" }} className="pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.ticket_status ? supportTicketModalDetails[0]?.ticket_details?.ticket_status : "-"}</p></>}
                                    <p style={{ marginLeft: "20px" }} className="pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.ticket_source}</p>
                                    <p style={{ marginLeft: "20px" }} className="pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.user_type ? supportTicketModalDetails[0]?.ticket_details?.user_type : "-"}</p>
                                    {ExecutiveDropDown ? <>
                                        <FormControl
                                            style={{}}
                                            sx={{ minWidth: 200, height: 40.4, maxHeight: 40.4, marginLeft: "1rem" }}
                                            size="small"
                                        >
                                            <InputLabel id="demo-simple-select-label">
                                                Assign To
                                            </InputLabel>
                                            <Select
                                                name="mentor_id"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formInputs.executive_id}
                                                label="Executive"
                                                style={{ height: 50.4, textAlign: "left" }}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                {supportTicketCategoryNameDetails?.map(mentor => (
                                                    <MenuItem style={{ borderBottom: '1px solid #ccc' }} key={mentor.user_id} value={mentor.user_id}>
                                                        {mentor.first_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </> : <>
                                        <p style={{ marginLeft: "20px" }}><button style={{
                                            background: "rgba(56, 193, 223, 0.2)", borderRadius: "67px", border: "none", width: "186px",
                                            height: "44px"
                                        }}>{supportTicketModalDetails[0]?.ticket_details?.executive_name ? supportTicketModalDetails[0]?.ticket_details?.executive_name : "-"}</button></p>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <hr />
                        {supportTicketModalDetails[0]?.user_details === null ? <>
                            <div style={{ width: "50%" }}>
                                <p className="modal-subtitles-style">Student Details</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p className="label-grey-text pTagsHere">Student Name:</p>
                                        <p className="label-grey-text pTagsHere">Student Email:</p>
                                        <p className="label-grey-text pTagsHere">Student Contact:</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.student_details?.first_name}{" "}{supportTicketModalDetails[0]?.student_details?.last_name}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.student_details?.email_id}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.student_details?.ph_no}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div style={{ width: "50%" }}>
                                <p className="modal-subtitles-style">Mentor Details</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p className="label-grey-text pTagsHere" >Mentor Name:</p>
                                        <p className="label-grey-text pTagsHere">Mentor Email:</p>
                                        <p className="label-grey-text pTagsHere">Mentor Contact:</p>

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.mentor_details?.first_name}{" "}{supportTicketModalDetails[0]?.mentor_details?.last_name}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.mentor_details?.email_id}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.mentor_details?.ph_no}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div style={{ width: "50%" }}>
                                <p>Activity Details</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p className="label-grey-text pTagsHere" >Activity Type:</p>
                                        <p className="label-grey-text pTagsHere">Date:</p>
                                        <p className="label-grey-text pTagsHere">Time:</p>
                                        <p className="label-grey-text pTagsHere">Role:</p>
                                        <p className="label-grey-text pTagsHere">Specilization:</p>

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.activity_details?.act_type_name}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.activity_details?.slot_date}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.activity_details?.slot_name}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.activity_details?.role_name}</p>
                                        <div style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.activity_details?.specialization_name}</div>
                                    </div>
                                </div>
                                <button className={`st-chat-now`} style={{ width: 'auto', color: 'white', paddingLeft: "1rem", paddingRight: "1rem", marginTop: "16px" }} onClick={() => handleActivityDetailsClick(supportTicketModalDetails[0]?.ticket_details?.order_id, supportTicketModalDetails[0]?.activity_details?.end_time)}>{activityLoading ? <CircularProgress size={24} color="inherit" /> : 'Load Activity Details'}</button>


                            </div>
                            <hr />

                        </> : <>
                            <div style={{ width: "50%", marginBottom: "1.5rem" }}>
                                <p className="modal-subtitles-style">User Details</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p className="label-grey-text pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.user_type === "mentor" ? "Mentor Name" : "Student Name"}:</p>
                                        <p className="label-grey-text pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.user_type === "mentor" ? "Mentor Email" : "Student Email"}:</p>
                                        <p className="label-grey-text pTagsHere">{supportTicketModalDetails[0]?.ticket_details?.user_type === "mentor" ? "Mentor Contact" : "Student Contact"}:</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px" }}>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.user_details?.first_name}{" "}{supportTicketModalDetails[0]?.user_details?.last_name}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.user_details?.email_id}</p>
                                        <p style={{ marginLeft: "30px" }} className="pTagsHere">{supportTicketModalDetails[0]?.user_details?.ph_no}</p>
                                    </div>
                                </div>
                            </div>
                        </>}

                        <div>
                            <p className="modal-subtitles-style">Description</p>
                            <Card sx={{ maxWidth: "100%", marginRight: "20px", marginTop: ".5rem" }}>
                                <CardContent style={{ height: "auto" }}>
                                    <p>
                                        {console.log("description replaced", supportTicketModalDetails[0]?.ticket_details.description.replace(/\|\|n/g, "\n"))}
                                        {supportTicketModalDetails[0]?.ticket_details.description ? get_specialCharReplace(supportTicketModalDetails[0]?.ticket_details.description)

                                            .split('\n')
                                            .map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            )) : "Description is not provided"}
                                    </p>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ display: "flex", marginTop: ".5rem" }}>
                            <p className="modal-subtitles-style" style={{ textAlign: "center", marginTop: ".5rem" }}>Attachment</p>
                            <div >
                                <button
                                    className="textTagsHere" style={{ border: "none", color: "#1C8EA8", background: "transparent", textAlign: "left", outline: "none", marginLeft: "1.2rem", marginTop: "0.5rem" }}
                                    onClick={() => {
                                        if (file?.files.length > 0) {
                                            window.open(file?.files[0]?.url);
                                        }
                                    }}
                                >
                                    {file?.files.length === 0 ? "-- No attachments --" : file?.files[0]?.name}
                                </button>
                            </div>
                        </div>
                        {adminNotes ? <>
                            <div style={{ marginTop: ".5rem" }}>
                                <p className="modal-subtitles-style">Admin Notes</p>
                                <Card sx={{ maxWidth: "100%", marginRight: "20px", marginTop: ".5rem", height: "auto" }}>
                                    <CardContent>
                                        <textarea name="admin_notes" autoFocus placeholder="Write Notes" onChange={(e) => handleChange(e)} style={{ border: "none", outline: 'none', width: "100%", height: "auto" }} variant="body2" color="text.secondary" value={formInputs.notes} rows="5" />
                                    </CardContent>
                                </Card>
                            </div>
                        </> : <>
                            <div style={{ marginTop: ".5rem", height: "auto" }}>
                                <p className="modal-subtitles-style">Admin Notes</p>
                                <Card sx={{ maxWidth: "100%", marginRight: "20px", marginTop: ".5rem", height: "auto" }}>
                                    <CardContent>
                                        <Typography variant="body2" color="text.secondary" style={{ height: "auto" }}>
                                            {supportTicketModalDetails[0]?.ticket_details.notes ? get_specialCharReplace(supportTicketModalDetails[0]?.ticket_details.notes)

                                                .split('\n')
                                                .map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                )) : "Admin Notes Not Provided Yet"}

                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </>}

                        <hr />
                        {/* <div>
                            <p className="modal-subtitles-style">Chats</p>
                            <p>[Student; 23rd March, 7:15 pm]: I have an issue I am not able to resolve</p>
                            <p>[Mentor; 23rd March, 7:16 pm]: I cannot resolve that for you</p>
                            <button className="st-close-chat">Chat Closed:Support Contact</button>
                        </div> */}
                    </div>

                </Modal.Body>
            </Modal>
            {openChat ? <>
                <ChatModal show={openChat} setShow={setOpenChat} supportTicketModalDetails={supportTicketModalDetails} supportTicketDetails={supportTicketDetails} ticket_id={ticket_id} category_name={category_name} userDetails={userDetails} chatDetails={chatDetails} setChatDetails={setChatDetails} supportTicketCategoryNameDetails={supportTicketCategoryNameDetails} />

            </> : <></>}
            {/* <ReScheduleConfirmation modalConfirmationShow={openRescheduleModal} setModalConfirmationShow={setRescheduleModal} modalChat={setOpenChat} modalSt={setShow} /> */}
            <ModalSt setMentor_missed_Request={setMentor_missed_Request} mentor_missed={mentor_missed} studentFeedbackFields={studentFeedbackFields} mcqFeedbackFields={mcqFeedbackFields} feedbackFields={feedbackFields} studentsDetailsClick={activityDetailsClick} setStudentsDetailsClick={setActivityDetailsClick} show={activityDetailsOpen} setShow={setActivityDetailsOpen} orderHistory={orderHistory} changedPairs={changedPairs} reconsiderRequest={reconsiderRequest} specialization_role_slots_jd={specialization_role_slots_jd} chatMessages={chatMessages} setChatMessages={setChatMessages} resumeRequest={resumeRequest} mentor_feedback_submitted_Request={mentor_feedback_submitted_Request} mentor_join_time_Request={mentor_join_time_Request} mentor_joined_Request={mentor_joined_Request} mark_absent_Request={mark_absent_Request} student_feedback_submitted_Request={student_feedback_submitted_Request} duration_name={duration_name} student_join_time_Request={student_join_time_Request} mentor_missed_Request={mentor_missed_Request} allItems={allItems} order_flag_changes={order_flag_changes} notification_ids={notification_ids} internet_stats={internet_stats} origin={"supportTicket"} />
        </>
    );
}

