import axios from "axios";
import React, { useEffect, useState } from "react";
import '../../reusables/ModalInput/ModalInputStyles.css';
import CollegeData from '../../../CollegeDataServices/httpCollege'

import EnhancedTable from '../../reusables/TableComponent/TableComponent';
//modal
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Loader from "../../reusables/Loader/Loader";
import { saveAs } from "file-saver";
import Cookies from 'js-cookie';

import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";

const UserType = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [college_id, setcollage_id] = useState();
    const [college_name, setcollage_name] = useState();

    const collegeData = new CollegeData()
    const [rowsData, setRowsData] = useState([])
    const [formInputs, setFormInputs] = useState([{ id: null, college_name: "", college_code: "", is_deleted: 0 }])

    //from table index
    const [from_table, setFrom_table] = useState();
    let count = 0;
    const headCells = [
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'College Name',
        },
        {
            id: 'heading2',
            numeric: 'right',
            disablePadding: false,
            label: 'College Code',
        },
        {
            id: 'heading2',
            numeric: 'right',
            disablePadding: false,
            label: 'Batch Details',
        }

    ];

    const handleDeleteMul = (id, index) => {
        console.log("delete", index)
        console.log("deleting this:", rowsData[index]?.id)
        let list = [...rowsData];

        console.log("listtttt1", list);


        collegeData
            .delete("/college", {
                data: { "ids": id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list = list.filter((item) => id.indexOf(item.id) < 0);
                // setFormInputs({ ...formInputs, is_deleted: 1 })
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
    }

    const data_getter = (name, id, index, activity) => {
        console.log("Dude here's your name:", name);
        console.log("Dude here's your id:", id);
        console.log("Dude here's your index:", index);

        if (name === "delete_mul") {
            console.log("inside delete mul if")
            handleDeleteMul(id, index)
        }
        else if (name === "BatchData") {
            console.log("inside delete mul if")
            handleBatchDataPage(id)
        }
        else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
            handleDelete(id, index);
        }
        if (activity === "add") {
            console.log("UserType ~ activity", activity)
            setFormInputs({})
        }


        setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
    }

    // delete single data
    const handleDelete = async (id, index) => {
        console.log("delete", id)
        let list = [...rowsData];

        console.log("listtttt1", list);

        // const idsArray = list.map(obj => obj.id);
        collegeData
            .delete("/college", {
                data: { "ids": [id] }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
    }



    const collegeDataGetter = async () => {
        try {
            const res = await collegeData.get('/college')
            console.log("College RESPONSE:", res.data)
            setRowsData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    // get request
    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {

            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let college = await collegeDataGetter();

            // forming the data
            console.log("odonil", { college })
            data_manupulator_duration(college);
            setLoading(false)
        }

        handleEffect();
    }, [])


    const handleBatchDataPage = (id, college_name) => {
        console.log("rows from college is", id, college_name);
        // setcollage_id(id);
        // setcollage_name(college_name);
        navigate(`${"/BatchData?college_id=" + id}`, { state: { college_id: id, college_name: college_name } })
    }

    const handleCOllegeReportPage = (id, college_name) => {
        console.log("rows from college is", id, college_name);
        // setcollage_id(id);
        // setcollage_name(college_name);
        navigate(`${"/CollegeInfoPage?college_id=" + id}`, { state: { college_id: id, college_name: college_name } })
    }



    useEffect(() => {
        const setting_data = () => {

            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setFormInputs({})
            }
            else {
                //setting values for Edit Modal
                console.log("Inside else of useEffect")
                console.log({ rowsData })
                setFormInputs({ ...formInputs, id: rowsData[from_table?.index]?.id, college_name: rowsData[from_table?.index]?.college_name, college_code: rowsData[from_table?.index]?.college_code, is_deleted: rowsData[from_table?.index]?.is_deleted })
            }

        }
        setting_data();
    }, [from_table])

    const data_manupulator_duration = (college) => {
        console.log("college data:", college)
        let temp_rowsData = JSON.parse(JSON.stringify(college));

        //show data - send to Table Component
        temp_rowsData.forEach((row) => {

            delete row.is_deleted

        });
        console.log("temp rows:", temp_rowsData)
        setRowsData(temp_rowsData)

    }



    //MODAL
    const Modal_Durations = (show, setShow) => {



        const modalSubmit = () => {
            console.log("from table in college data", from_table)
            if (from_table.index === undefined)
                handlePostRequest();
            else
                handlePutRequest();

            setShow(false)
        }

        const onHide = () => {
            setShow(false)
        }


        const handleChange = (e, index) => {
            //Logs for entering values
            console.log("name from form  is:", e.target.name);
            console.log("code from form  is:", e.target.value);

            //Conditions for storing values
            if (e.target.name === "college_name") {
                console.log("college_name")
                setFormInputs({ ...formInputs, college_name: e.target.value })
            }
            else if (e.target.name === "college_code") {
                console.log("college_code")
                setFormInputs({ ...formInputs, college_code: e.target.value })
            }
        }


        //render
        return (
            <Modal
                show={show}
                onHide={() => onHide()}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '95%',
                    minWidth: '500px',
                    minHeight: '300px'
                }}>
                    <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>

                        <div className='right-side-buttons-college row'>
                            <div className="headerComp" >
                                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                <p className="compTitle">College Data</p>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">College Name<span>*</span></label>
                                <input name="college_name" type="text" className="form-control" placeholder="Example input" value={formInputs.college_name} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">College Code<span>*</span></label>
                                <input name="college_code" type="text" className="form-control" placeholder="Example input" value={formInputs.college_code} onChange={(e) => handleChange(e)} />

                            </div>

                            <div className=" btn-comp " style={{ marginTop: "4%", marginLeft: '0' }}>
                                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
                            </div>

                        </div>

                    </form>
                </Modal.Body>
            </Modal>

        )
    }



    // post request
    const handlePostRequest = async () => {
        let data = {
            college_name: formInputs.college_name,
            college_code: formInputs.college_code
        }

        console.log("User TYpe id not found, sending teh post request", data);
        await collegeData
            .post("/college", data)
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")


                let temp = JSON.parse(JSON.stringify(rowsData));
                temp = ([{
                    id: res.data.id,
                    college_name: formInputs.college_name,
                    college_code: formInputs.college_code,
                    // is_deleted: formInputs?.is_deleted
                }, ...temp])
                setFormInputs({ ...formInputs, id: res.data.id })
                setRowsData(temp)
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }

    const handlePutRequest = async () => {
        console.log("")
        //get request here
        collegeData
            .put("/college", {
                id: rowsData[from_table?.index].id,
                college_name: formInputs.college_name,
                college_code: formInputs.college_code
            })
            .then((res) => {
                // console.log("Response from backend is", res.data);
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                let temp = JSON.parse(JSON.stringify(rowsData));
                temp[from_table?.index].college_name = formInputs.college_name
                temp[from_table?.index].college_code = formInputs.college_code
                // temp[from_table?.index].is_deleted = rowsData[from_table?.index].is_deleted
                console.log("Put temp value:", temp)
                setRowsData(temp)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }





    return (<div >
        <RowsContext.Provider value={rowsData}>
            <TopBar />
            <SideNavigationBar />
            {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div> : <>
                {console.log("from_table.index", from_table?.index)}
                {console.log({ formInputs })}
                {console.log({ rowsData })}

                <EnhancedTable handleBatchDataPage={handleBatchDataPage} handleCOllegeReportPage={handleCOllegeReportPage} mainFile_modal={Modal_Durations} data_getter={data_getter} headCells={headCells} count={count} path={window.location.pathname} />
            </>}

        </RowsContext.Provider>

    </div>
    )
}
// value={{rowsData, handleFormSubmission : (e,user_type_name) => handleFormSubmission(e,user_type_name)}}

export default UserType