import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Card, Divider, IconButton, Modal, TextField, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Color, DivCenter } from "../../../../GlobalStyles";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
import { SlideInRightModal } from "../AdditionalComponents/SlideInRightModal";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import { ellipsisFunction } from "../../../Reusable_functions/TextFunctions/EllipsisFunction";
import TimeService from "../../../../functions/epochTime";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./JobPostings.css";
import "../JobAllRequests/JobAllRequests.css";
import { DeleteOutlineOutlined, ModeEditOutlineOutlined, MoreHoriz } from "@mui/icons-material";
import Loader, { SmallPageLoader } from "../../../reusables/Loader/Loader";
const JobPostings = () => {
  const services = new CCService();
  const timeServices = new TimeService();
  const [loading, setLoading] = useState({ page_loader: true, activity_laoder: false });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const company_id = parseInt(searchParams.get("company_id"));
  // const location = useLocation();
  // console.log("location:", location);
  // const { company_name } = location.state;
  const [company_name, setCompany_name] = useState("N/A");
  //dot_dot_dot edit button
  const [displayOption, setDisplayOptions] = useState(null);
  console.log("displayOption:", displayOption);

  const initial_requestData = {
    job_id: null,
    job_published_time: "",
    company_name: "",
    roles: "",
    spe: "",
    job_status: "",
  };
  const initial_jobPostingsData = {
    job_id: null,
    employment_type: "Summner Intern",
    job_published_time: "27th September 2015",
    company_name: "ABC Company",
    roles: "Gig master",
    spe: "Big Things",
    job_status: "Active",
  };
  const [filterButtons, setFilterButtons] = useState([]);
  console.log("filterButtons:", filterButtons);

  const [jobPostingsData, setJobPostingsData] = useState([{ ...initial_jobPostingsData }]);
  console.log("jobPostingsData:", jobPostingsData);

  const [allRequestData, setAllRequestData] = useState({
    "New Requests": [initial_requestData],
    "On Hold": [initial_requestData],
    Recent: [initial_requestData],
  });
  const [backup_RequestData, setBackup_RequestData] = useState([...jobPostingsData]);
  const [viewRequest, setVeiwRequest] = useState({
    open: false,
    onClose: () => {
      setVeiwRequest((prev) => ({ ...prev, open: false }));
    },
    data: { job_id: "", company_name: "", on_boarding_date: "", category: "", role: "", specialization: "", industry: "", email: "", contact: "", linkedin: "", company_desc: "" },
  });

  const [onHoldRemarks, setOnHoldRemarks] = useState({ flag: "", show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });

  const handleMainUseEffect = async () => {
    setLoading((prev) => ({ ...prev, page_loader: true }));
    const final_allRequestData = await allRequestDataOperations();
    console.log("final_allRequestData:", final_allRequestData);
    // const temp = [
    //     {
    //         "job_id": 1,
    //         "job_published_time": "1701328843000",
    //         "company_name": "RedBull",
    //         "responsibilities": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    //         "roles": "Business Analytics",
    //         "spe": "@@@@@@@@@@@@@@@@@MP##############",
    //         "job_status": "Accept"
    //     },
    //     {
    //         "job_id": 4,
    //         "job_published_time": null,
    //         "company_name": "RedBull",
    //         "responsibilities": null,
    //         "roles": null,
    //         "spe": null,
    //         "job_status": "Closed"
    //     },
    //     {
    //         "job_id": 6,
    //         "job_published_time": null,
    //         "company_name": "RedBull",
    //         "responsibilities": "<p>RIch Text Editor</p>",
    //         "roles": "Tech B2B Sales",
    //         "spe": "Technology",
    //         "job_status": "Accept"
    //     },
    //     {
    //         "job_id": 8,
    //         "job_published_time": "1701329143323",
    //         "company_name": "RedBull",
    //         "responsibilities": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    //         "roles": "Business Analytics",
    //         "spe": "@@@@@@@@@@@@@@@@@MP##############",
    //         "job_status": "Expired"
    //     },
    //     {
    //         "job_id": 9,
    //         "job_published_time": "1701689465521",
    //         "company_name": "RedBull",
    //         "responsibilities": null,
    //         "roles": null,
    //         "spe": "Consulting",
    //         "job_status": "In review"
    //     }
    // ]
    setJobPostingsData(final_allRequestData);
    setBackup_RequestData(final_allRequestData); //back up data to restore to previous state after being filtered
    setLoading((prev) => ({ ...prev, page_loader: false }));
  };
  useEffect(() => {
    handleMainUseEffect();
  }, []);

  useEffect(() => {
    const handleUpdateAllRequestData = () => {
      if (filterButtons.length === 0) {
        setJobPostingsData(backup_RequestData);
      } else {
        const filteredData = backup_RequestData.filter((job) => filterButtons.includes(job.job_status.trim()));
        console.log({ filteredData, filterButtons });
        setJobPostingsData(filteredData);
      }
    };
    handleUpdateAllRequestData();
  }, [filterButtons]);

  const allRequestDataOperations = async () => {
    const all_request_data = await fetchAllRequestData();
    console.log("all_request_data:", all_request_data);
    return all_request_data;
    // // Function
    // const remakeAllRequestData = (data) => {
    //     let temp_data = [...data];
    //     let final_data = { "New Requests": [], "On Hold": [], "Recent": [] };
    //     const temp_new_req = temp_data.filter((temp) => temp.job_status === "In review");
    //     final_data["New Requests"] = temp_new_req.length ? temp_new_req : [{ job_id: null }];
    //     const temp_on_hold = temp_data.filter((temp) => temp.job_status === "On hold");
    //     final_data["On Hold"] = temp_on_hold.length ? temp_on_hold : [{ job_id: null }];
    //     const temp_recent = temp_data.filter((temp) => temp.job_status === "Accept");
    //     final_data["Recent"] = temp_recent.length ? temp_recent : [{ job_id: null }];
    //     return final_data;
    // }

    // //forming/remaking the data
    // const remade_allRequestData = remakeAllRequestData(all_request_data);
    // console.log("remade_allRequestData:", remade_allRequestData);
    // return remade_allRequestData;
  };

  const fetchAllRequestData = async () => {
    try {
      const response = await services.get(`/jobs/admin/allJobs?company_id=${company_id}`);
      console.log("response:", response);
      if (response) {
        setCompany_name(response.company_name[0]?.company_name ?? "N/A");
        return response.details;
      } else return [];
    } catch (err) {
      console.error("Error fetching all request data:", err);
    }
  };

  const handleViewRequestClick = (job_id) => {
    const sending_data = jobPostingsData.find((data) => data.job_id === job_id);
    console.log("sending_data:", sending_data);
    setVeiwRequest((prev) => ({
      ...prev,
      open: true,
      data: {
        ...prev.data,
        company_name: sending_data.company_name,
        date: timeServices.convertEpochToDate(parseInt(sending_data.job_published_time)) ?? "No data",
        time: timeServices.convertEpochToTime(parseInt(sending_data.job_published_time)) ?? "No data",
        category: sending_data.category ?? "No data",
        role: sending_data.roles ?? "No data",
        specialization: sending_data.spe ?? "No data",
        status: sending_data.job_status ?? "No data",
        job_id,
        buttons: false,
      },
    }));
  };

  const handleFilterButtonClick = (button) => {
    // let temp = JSON.parse(JSON.stringify(filterButtons));
    // setAllRequestData((prev) => ({ "New Requests": temp.live ? [] : prev["New Requests"], "On Hold": temp.closed ? [] : prev["On Hold"], "Recent": temp.expired ? [] : prev["Recent"] }));
    // if (button === 'Live') {
    //     setFilterButtons((prev) => ({ ...prev, new: !prev.live }));
    // } else if (button === 'Closed') {
    //     setFilterButtons((prev) => ({ ...prev, on_hold: !prev.closed }));
    // } else if (button === 'Expired') {
    //     setFilterButtons((prev) => ({ ...prev, recent: !prev.expired }));
    // } else if (button === 'In Review') {
    //     setFilterButtons((prev) => ({ ...prev, recent: !prev.expired }));
    // }

    let temp = [...filterButtons];
    if (filterButtons.includes(button)) {
      setFilterButtons(temp.filter((data) => data !== button));
    } else {
      temp.push(button);
      setFilterButtons(temp);
    }
  };

  const handleAcceptClick = async () => {
    try {
      const body = {
        job_id: viewRequest.data.job_id,
        action: "Accept",
        job_status: "In review",
        remarks: onHoldRemarks.content,
      };
      console.log("body:", body);
      const accept_put_request = await putAcceptOrOnHoldRequest(body);
      console.log("accept_put_request:", accept_put_request);
    } catch (err) {
      console.error("Error while accepting request", err);
    }
  };

  const putAcceptOrOnHoldRequest = async (body) => {
    const response = await services.put(`/jobs/admin/recruiterCompanies/request`, body);
    console.log("response:", response);
    return response;
  };

  function OnHoldRemarksModal({ open, onClose }) {
    const [formInput, setFormInput] = useState(onHoldRemarks.content);
    console.log({ formInput });
    const handleOnHoldRemarksSubmit = () => {
      handleOnHoldClick();
    };
    const handleOnHoldClick = async () => {
      //calling put request
      try {
        const body = {
          job_id: viewRequest.data.job_id,
          action: "On hold",
          job_status: "In review",
          remarks: formInput,
        };
        console.log("body:", body);
        const onHold_put_request = await putAcceptOrOnHoldRequest(body);
        console.log("onHold_put_request:", onHold_put_request);
        setOnHoldRemarks((prev) => ({ ...prev, show: false, content: formInput }));
      } catch (err) {
        console.error("Error while on hold-ing request", err);
      }
    };
    const onholdmarksStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 604,
      height: 354,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      borderRadius: "20px",
      boxShadow: 24,
      p: 4,
    };
    return (
      <Modal
        open={open}
        onClose={onClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...onholdmarksStyle }}>
            {onHoldRemarks.flag === "Accept" ? (
              <>
                <Box>
                  <Typography fontSize={32}>Are you sure you want to accept this request?</Typography>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "90%" }}>
                  <Button variant="text" onClick={() => onHoldRemarks.onClose()}>
                    No
                  </Button>
                  <Button variant="contained" onClick={() => handleAcceptClick()}>
                    Yes
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ ...DivCenter, width: "100%", height: "10%", justifyContent: "space-between" }}>
                  <Typography fontSize={32}>Why keep this request on Hold?</Typography>

                  <IconButton onClick={() => onClose()}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "30%" }}>
                  <Typography fontSize={18}>Your comments will be seen to the recruiter</Typography>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "50%" }}>
                  {console.log("formInput:", formInput)}
                  <TextField
                    multiline
                    rows={4}
                    value={formInput}
                    onChange={(event) => {
                      setFormInput(event.target.value);
                    }}
                    sx={{ width: "80%", height: "100%" }}
                  ></TextField>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "15%" }}>
                  <Button variant="contained" sx={{ height: "100%", width: "40%", boxShadow: "none", bgcolor: Color.primary_gradient, color: "white", textTransform: "none", "&:hover": { bgcolor: Color.primary_gradient, boxShadow: "none" } }} onClick={() => handleOnHoldRemarksSubmit()}>
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    );
  }

  const style_status = {
    borderRadius: "30px",
    display: "flex",
    height: "26px",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  };
  const colorDecider = (status, field) => {
    if (field === "color") {
      if (status === "Accept") return "#1EBE70";
      else if (status === "Expired") return "red";
      else if (status === "Closed") return Color.neutralBlack;
      else if (status === "In review") return "#F5A536";
    } else {
      if (status === "Accept") return "rgba(30, 190, 112, 0.10)";
      else if (status === "Expired") return "rgba(255, 14, 14, 0.10)";
      else if (status === "Closed") return "#F2F2F2";
      else if (status === "In review") return "rgba(245, 165, 54, 0.10)";
    }
  };

  const handleCloseJobClick = async (job_id) => {
    // navigate("/Jobs", { state: { job_id: job_id } });
    try {
      const response = await services.put(`/jobs/admin/recruiterCompanies/closeJob?job_id=${job_id}`);
      console.log({ close_job_response: response });
      handleMainUseEffect();
    } catch (err) {
      console.log("Error in closing the job: ", err);
    }
  };

  return (
    <>
      {loading.page_loader ? (
        <SmallPageLoader show={loading} />
      ) : (
        <Box className="main_box">
          <SideNavigationBar />
          <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
          <Box className="box__mainContent" sx={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", gap: "33px", alignItems: "flex-start" }}>
            <Box sx={{ ...DivCenter, mt: "10px" }}>
              <IconButton onClick={() => navigate(-1)} style={{ height: "30px", width: "30px" }}>
                <ArrowBackIosIcon className="Arrow-back-btn" />
              </IconButton>
              <Typography sx={{ fontSize: "32px", color: "black" }}>{company_name ?? "NO COMPANY NAME"}</Typography>
            </Box>
            <Typography sx={{ fontSize: "24px", color: "black" }}>Job Postings</Typography>
            <Box sx={{ ...DivCenter, gap: "10px" }}>
              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleFilterButtonClick("Accept")}
                sx={{
                  background: filterButtons.includes("Accept") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Accept") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Accept") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Accept") ? Color.white : Color.primary1,
                  },
                }}
              >
                Live
              </Button>
              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleFilterButtonClick("Closed")}
                sx={{
                  background: filterButtons.includes("Closed") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Closed") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Closed") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Closed") ? Color.white : Color.primary1,
                  },
                }}
              >
                Closed
              </Button>
              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleFilterButtonClick("Expired")}
                sx={{
                  background: filterButtons.includes("Expired") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Expired") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Expired") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Expired") ? Color.white : Color.primary1,
                  },
                }}
              >
                Expired
              </Button>
              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleFilterButtonClick("In review")}
                sx={{
                  width: "100px !important",
                  background: filterButtons.includes("In review") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("In review") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("In review") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("In review") ? Color.white : Color.primary1,
                  },
                }}
              >
                In Review
              </Button>
              {/* <IconButton sx={{ color: Color.primary1, height: '40px', width: '40px' }}>+</IconButton> */}
            </Box>
            {jobPostingsData.map((job) => (
              <Card className="card__jobPostings">
                <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
                  <Box sx={{ ...DivCenter, justifyContent: "flex-start", gap: "24px", height: "33.33%" }}>
                    <Typography fontSize={"16px"}>{job.job_type_name ?? "No employment type"}</Typography>
                    {/* <Divider orientation="vertical" flexItem sx={{ color: '#E6E6E6' }} /> */}
                    <div style={{ height: "25px", width: "1px", background: "#E6E6E6" }} />
                    <Typography fontSize={"16px"} sx={{ ...style_status, color: colorDecider(job.job_status, "color"), bgcolor: colorDecider(job.job_status, "bgcolor") }}>
                      {job.job_status.toUpperCase()}
                    </Typography>
                  </Box>
                  <Box>
                    {job.job_status === "Accept" && (
                      <IconButton sx={{ display: "flex", flexDirection: "column" }} onClick={() => (displayOption === job.job_id ? setDisplayOptions(null) : setDisplayOptions(job.job_id))}>
                        <MoreHoriz />
                        <Box
                          sx={{
                            position: "absolute",
                            right: 30,
                            border: "1px solid #E6E6E6",
                            top: 22,
                            paddingBlock: "5px",
                            boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            borderRadius: "10px",
                            display: displayOption === job.job_id ? "" : "none",
                          }}
                        >
                          <Box
                            sx={{
                              paddingInline: "15px",
                              display: "flex",
                              gap: "5px",
                              marginBottom: "5px",
                            }}
                            onClick={() => handleCloseJobClick(job.job_id)}
                          >
                            <CloseIcon />
                            <Typography>Close</Typography>
                          </Box>
                        </Box>
                      </IconButton>
                    )}
                  </Box>
                </Box>
                <Box sx={{ ...DivCenter, justifyContent: "flex-start", gap: "18px", fontSize: "14px", height: "16.665%" }}>
                  <Typography fontSize={"14px"} sx={{ color: Color.neutralMidGrey }}>
                    {ellipsisFunction(job.designation, 25) ?? "No designation specified"}
                  </Typography>
                  <div style={{ height: "100%", width: "1px", background: "#E6E6E6" }} />
                  <Typography fontSize={"14px"} sx={{ color: Color.neutralMidGrey }}>
                    {ellipsisFunction(job.roles, 25) ?? "No role specified"}
                  </Typography>
                  {/* <Divider orientation="vertical" flexItem sx={{ background: '#E6E6E6' }} /> */}
                  <div style={{ height: "100%", width: "1px", background: "#E6E6E6" }} />
                  <Typography fontSize={"14px"} sx={{ color: Color.neutralMidGrey }}>
                    {ellipsisFunction(job.spe, 25) ?? "No specialization specified"}
                  </Typography>
                </Box>
                <Box sx={{ ...DivCenter, justifyContent: "flex-start", gap: "8px", height: "16.665%" }}>
                  <Typography fontSize={"14px"} sx={{ color: Color.neutralMidGrey }}>
                    Posted on:
                  </Typography>
                  <Typography fontSize={"14px"} sx={{ color: Color.neutralMidGrey }}>
                    {timeServices.convertEpochToTime(parseInt(job.job_published_time)) + ", " + timeServices.convertEpochToDate(parseInt(job.job_published_time))}
                  </Typography>
                </Box>
                <Box sx={{ ...DivCenter, justifyContent: "flex-start", gap: "24px", width: "100%", height: "33.33%" }}>
                  <Button className="button__viewDetails" variant="contained" onClick={() => (["Incomplete", "In review", "On hold"].includes(job.job_status) ? handleViewRequestClick(job.job_id) : navigate(`/JobPostingState?job_id=${job.job_id}`, { state: { company_name } }))} sx={{ width: "20%", fontSize: "20px", textTransform: "none" }}>
                    View Details
                  </Button>
                </Box>
              </Card>
            ))}
            {console.log("jobPostingsData:", jobPostingsData)}
          </Box>
          <Box className="box__fillerBox" sx={{ width: "8%" }}></Box>
          <SlideInRightModal open={viewRequest.open} onClose={viewRequest.onClose} data={viewRequest.data} onHoldRemarks={onHoldRemarks} setOnHoldRemarks={setOnHoldRemarks} />
          <OnHoldRemarksModal open={onHoldRemarks.show} onClose={onHoldRemarks.onClose} />
        </Box>
      )}
    </>
  );
};

export default JobPostings;
