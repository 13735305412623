import React from 'react'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar'
import CreateTable from './CreateStudentTable'
import { useLocation } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';

const College1x1StudentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { is_b2c } = location.state;
  console.log("College1x1StudentDetails ~ is_b2c:", is_b2c)
  const [searchParams, setSearchParams] = useSearchParams();
  let college_data_id = location.state?.college_data_id
  let college_data_name = location.state?.college_data_name || searchParams.get("college_name")
  let batchName = location.state?.batchName
  let batchid = location.state?.batchid
  let process_id = location.state?.process_id
  let searched = location.state?.searched
  let sku_name = location.state?.sku_name
  let isAnomalies = location.state?.isAnomalies
  let studentAnomaliesDetails = location.state?.studentAnomaliesDetails
  console.log("searched in material ui", searched,studentAnomaliesDetails);
  return (
    <div>
      <SideNavigationBar />

      <CreateTable
        college_data_id={college_data_id}
        process_id={process_id}
        college_data_name={college_data_name}
        batchName={batchName}
        batchid={batchid}
        sku_name={sku_name}
        isAnomalies={isAnomalies}
        searched={searched}
        is_b2c={is_b2c}
        studentAnomaliesDetails={studentAnomaliesDetails}
      />
    </div>
  )
}

export default College1x1StudentDetails
