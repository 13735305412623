// import Service from "./httpService";
// const services = new Service();
// console.log("servicess", services);

class TimeService {
  days = () => ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  getEpochMillis = function (dateStr) {
    var r = /^\s*(\d{4})-(\d\d)-(\d\d)\s+(\d\d):(\d\d):(\d\d)\s+UTC\s*$/,
      m = ("" + dateStr).match(r);
    return m ? Date.UTC(m[1], m[2] - 1, m[3], m[4], m[5], m[6]) : undefined;
  };

  getUTCPresentDateAndTimeInMS = () =>
    new Date().getTime() + this.convertToMS(5, 30);

  getUTCPresentTimeInMS = () =>
    this.getUTCPresentDateAndTimeInMS() - this.getUTCPresentDateInMS();

  getUTCPresentDate = () =>
    new Date(this.getUTCPresentDateAndTimeInMS()).toISOString().slice(0, 10);

  getUTCPresentDateInMS = () => Date.parse(this.getUTCPresentDate());

  // // Assuming the convertToMS function takes hours and minutes and converts them to milliseconds
  // convertToMS = (hours, minutes) => (hours * 60 + minutes) * 60 * 1000;

  getPresentDateAndTimeInMS = () =>
    new Date().getTime() +
    new Date().getTimezoneOffset() * 60 * 1000 +
    this.convertToMS(5, 30);
  getPresentTimeInMS = () =>
    this.getPresentDateAndTimeInMS() - this.getPresentDateInMS();
  getPresentDate = () =>
    new Date(this.getPresentDateAndTimeInMS()).toISOString().slice(0, 10);
  getPresentDateInMS = () =>
    Date.parse(this.getPresentDate()) +
    new Date().getTimezoneOffset() * 60 * 1000;
  getPresentDay = () => new Date(this.getPresentDateAndTimeInMS()).getDay();
  getPresentYear = () =>
    new Date(this.getPresentDateAndTimeInMS()).getFullYear();
  convertToMS = (hr, mm = 0) => {
    return hr * 3600 * 1000 + mm * 60 * 1000;
  };
  convertTimeToMS = (hr, mm = 0, ss = 0) => {
    return hr * 3600 * 1000 + mm * 60 * 1000 + ss * 1000;
  };

  addDaysToPresentDate = days =>
    new Date(this.getPresentDateAndTimeInMS() + days * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10);
  addDaysToPresentDateInMS = days =>
    Date.parse(this.addDaysToPresentDate(days)) +
    new Date().getTimezoneOffset() * 60 * 1000;
  addYearsToPresentYear = yrs => this.getPresentYear() + yrs;

  getRelativeTime(ms) {
    // get the current time in milliseconds
    const now = new Date().getTime();

    // calculate the difference in milliseconds between now and the given time
    const diff = now - ms;

    // convert milliseconds to seconds, minutes, hours, and days
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // determine the appropriate time unit (seconds, minutes, hours, or days)
    let unit = "second";
    let value = seconds;
    if (days > 0) {
      unit = "day";
      value = days;
    } else if (hours > 0) {
      unit = "hour";
      value = hours;
    } else if (minutes > 0) {
      unit = "minute";
      value = minutes;
    }

    // handle pluralization of unit
    if (value !== 1) {
      unit += "s";
    }

    // construct and return the relative time string
    return `${value} ${unit} ago`;
  }
  convertEpochToDate(epochTime) {
    if (epochTime) {
      // Create a new Date object with the epoch time in milliseconds
      const date = new Date(epochTime);

      // Define the days of the week and months
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get the day of the week, month, and time from the date object
      const dayOfWeek = daysOfWeek[date.getDay()];
      const month = months[date.getMonth()];
      const hours = date.getHours();
      const minutes = date.getMinutes();
      console.log("minutes", minutes);

      // Determine if it's AM or PM
      const amOrPm = hours < 12 ? "AM" : "PM";
      console.log("am or pm", amOrPm);

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;
      console.log("formattedHours", formattedHours);

      // Construct the formatted date string
      const formattedDate = `${dayOfWeek} ${month} ${date.getDate()}`;

      // Return the formatted date
      return formattedDate;
    } else return 'No Date';
  }

  convertEpochToTime(epochTime) {
    if (epochTime) {
      // Create a new Date object with the epoch time in milliseconds
      const date = new Date(epochTime);

      // Define the days of the week and months
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get the day of the week, month, and time from the date object
      const dayOfWeek = daysOfWeek[date.getDay()];
      const month = months[date.getMonth()];
      const hours = date.getHours();
      const minutes = date.getMinutes();
      console.log("day of week", dayOfWeek);
      console.log("monthh", month);

      // Determine if it's AM or PM
      const amOrPm = hours < 12 ? "AM" : "PM";

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Construct the formatted date string
      const formattedDate = `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amOrPm}`;

      // Return the formatted date
      return formattedDate;
    } else return 'No Time';
  }
}
export function checkDate(dateString) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const date = new Date(dateString);

  if (date.toDateString() === today.toDateString()) {
    return "today";
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return "tomorrow";
  } else {
    return "Not today or tomorrow";
  }
}

export default TimeService;
