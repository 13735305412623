import React from 'react';

const formateConverter = (date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const d = new Date(date);
    return (monthNames[d.getMonth(date)] === undefined ? " " :  " " + monthNames[d.getMonth(date)]+" ");
}

export default formateConverter
