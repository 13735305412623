import React from "react";
import { useState, useEffect } from "react";
function Intership(props) {
    const name = props.name;
    const section_detail = {
        
        "section_name": "Profile Summary",
        
    }
    const { profileSummarySubSection } = props
    console.log("section_detail", section_detail)
    console.log("Role", profileSummarySubSection)
    const profileSummaryBullets = [
        {bullet_id: 1210, bullet_content: 'Profile Summary bullet 1', achievement_year: '2000', bullet_order: 0},
        {bullet_id: 1211, bullet_content: 'Profile Summary bullet 2', achievement_year: '2000', bullet_order: 1},
        {bullet_id: 1215, bullet_content: 'Profile Summary bullet 3', achievement_year: '2000', bullet_order: 2}
    ]
    console.log("profileSummaryBullets", profileSummaryBullets)

    let years = []
    let Details = []
    // console.log("profileSummarySubSection",profileSummarySubSection.length)
    if ((profileSummaryBullets && profileSummaryBullets?.length > 0)) {
        let temp_bullet = profileSummaryBullets[0].bullets === undefined ? profileSummaryBullets : profileSummaryBullets[0].bullets
        temp_bullet.map((value) => {
            console.log("value",value);
            Details.push({
                Desc: (
                    <p style={{ textAlign: "justify", marginBottom: '0px',paddingLeft:props.minipadding }}>
                        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                            <li style={{ listStyleType: profileSummaryBullets ? "" : 'none', fontFamily: props.fontS, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize}}>
                            {value.bullet_content?value.bullet_content:""}
                            </li>
                        </ul>
                    </p>
                ),
            })
        })
        
    }
    else{
        Details.push({
            Desc: (
                <p style={{ textAlign: "justify", marginBottom: '0px', padding:props.minipadding? props.minipadding : "0.16rem" }}>
                <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                // listStyleType: props.Bullet ? "square" : "",
                                listStyleType:"none",
                                lineHeight: props.LineHeight,
                            }}
                        >
        <li></li> 
        </ul>    
        </p>
        ),
    })
    }
    console.log(Details)
    // console.log(section_detail.s)
    return (
        <div>
            {console.log("FontSize",props.fontS)}
            <table
                className="IntershipBox New ResumeTable "
                // style={{ width: props.TableWidth ? "105%" : "100%" }}
                
                style={{
                    width: props.minwidth ? props.minwidth : props.TableWidth,
                    // marginRight: '2%', margin:'auto'
                    // width:props.minwidth+"%",
                }}
            // style={{ width: props.widths+"px" }}
            >
                <tr>
                    <th className="Resumetable-th"
                        id="SIP"
                        colspan="6"
                        style={{
                            backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"]:"#D9D9D9",
                            color: "black",
                            fontSize: props.FontSize,
                            fontFamily: props.fontS,
                            // border: "0.1px solid rgb(0 0 0 / 100%)",
                            textAlign: props.headerPosition,
                            height: props.miniheight,
                            // height: '25px',
                            fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                            padding: props.minipadding,
                            lineHeight: props.LineHeight
                        }}
                    >
                        {section_detail?.section_name ? section_detail.section_name : "Profile Summary"}
                    </th>
                </tr>



                {Details.map((val, key) => {
                    return (
                            <tr className="ResumeTable-td"
                                colSpan={4}
                                key={key}
                                style={{
                                    // fontSize: props.FontSmall,
                                    fontSize: props.FontSize,
                                    fontFamily: props.fontS,
                                    color: "black",
                                    // border: "0.1px solid rgb(0 0 0 / 100%)",
                                    // borderLeft:"0.1px solid rgb(0 0 0 / 100%)",
                                    // borderRight:"0.1px solid rgb(0 0 0 / 100%)",
                                    height: "13.4px", 
                                    fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                                    //  padding: props.minipadding
                                    // fontFamily: props.FontFamily ? "" : "Arial",
                                    // fontFamily: props.Arial,
                                }}
                            >
                                {val.Desc}
                            </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default Intership;