import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ModalConfirmation from '../ModalInput/ModalConfirmation';
import ModalInput from '../ModalInput/ModalInput';
// import { RowsContext } from '../../pages/UserType/UserType';
// import { RowsContext } from '../../pages/ProgramType/ProgramType';
import { useRef } from 'react';
import { formatConvertermonthfirst } from '../../../functions/date/formatCovertermonthfirst';
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext';
import ModalView from "../ModalInput/ModalView";
import ModalInputUpload from './../ModalInput/ModalInputUpload';
import { useNavigate } from 'react-router-dom';


// Order thingy
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export function EnhancedTableHead(props) {

  const rowsData = useContext(RowsContext)
  console.log("rows data in table header:", rowsData)
  const { onSelectAllClick, order, orderBy, numSelected, setSelected, rowCount, onRequestSort, headCells, path, handleCsvUpload, process_data } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? 'none' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ paddingRight: 'none', paddingLeft: 'none', fontSize: '18px', color: '#727272' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ marginLeft: -2, width: '100%' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



function EnhancedTableToolbar(props, { csvData }) {
  const { activityTypeData, durationData, mentorData, collegeData, batchData, numSelected, selected, setSelected, headCells, count, spec_data, jd_data, batchTypeData, activityData, programData, path, modalConfirmationShow, setModalConfirmationShow, handleCsvUpload, college_name, process_data } = props;
  console.log("Process data obtained in the Enhanced Table Tool bar", process_data);
  const rowsData = useContext(RowsContext)
  const [show, setShow] = React.useState(false);
  const navigate = new useNavigate();

  // useEffect(() => {
  //   rowsData = Papa.parse(csvData, { header: true }).data;
  //   setRows(rowsData);
  // }, [csvData]);

  // const [modalConfirmationShow, setModalConfirmationShow] = React.useState(false);

  const handleCloseModal = () => setShow(false)
  const callModalInput = (e, headCells) => {
    console.log("Yaay its here", path)
    console.log("Headcells in Modal call: ", headCells)
    if (path === "/JD" || path === "/Durations" || path === "/CollegeData" || path === "/StudentData" || path === "/CollegeInfoPage" || path === "/BatchData" || path === "/GroupSession" || path === "/ProcessData" || path === "/SKU" || path === "/Bundle" || path === "/Coupon" || path === "/Durations" || path === "/DefaultJD") {
      console.log("GOD")
      props.data_getter("", undefined, undefined, "add")
    }
    setShow(true);
  }


  let temp = path.replace('/', '').replace(/([a-z])([A-Z])/, '$1 $2')

  const callModalInputForDelAll = (e) => {
    setModalConfirmationShow(true)
    props.setIs_delete_all(true)
    console.log("number of selected", selected.length)
    // setSelected([])
    // numSelected = selected.length
    console.log('confirmation', modalConfirmationShow);
  }

  const fileInputRef = useRef(null);


  return (
    <RowsContext.Provider value={rowsData}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          bgcolor: '',
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: 'lightgrey'
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            {
              path.includes("/BatchData") ? college_name + " | " + temp :
                path.includes("/ProcessData") ? process_data[0] + " | " + process_data[1] + " | " + temp :
                  path.includes("/StudentData") ? process_data[0] + " | " + process_data[1] + " | " + temp :
                    path.includes("/CollegeInfoPage") ? "College Admins" : temp
            }
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon onClick={(e) => callModalInputForDelAll(e)} />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            {show === true && path === "/RoleType"
              ? (<ModalInput spec_data={spec_data} jd_data={jd_data} path={path} headCells={headCells} tempFunction={props.tempFunction} data_getter={props.data_getter} show={show} setShow={setShow} onHide={() => setShow(false)} />)
              : show === true && path === "/ActivityType"
                ? <ModalInputUpload spec_data={spec_data} jd_data={jd_data} action="create" path={path} headCells={headCells} tempFunction={props.tempFunction} data_getter={props.data_getter} show={show} setShow={setShow} onHide={() => setShow(false)} />
                : show === true && (path === "/JD" || path === "/Durations" || path === "/CollegeData" || path === "/StudentData" || path === "/CollegeInfoPage" || path === "/BatchData" || path === "/GroupSession" || path === "/ProcessData" || path === "/SKU" || path === "/Bundle" || path === "/Coupon" || path === "/Durations" || path === "/DefaultJD")
                  ? props.mainFile_modal(show, setShow, "Add")
                  : <ModalInput headCells={headCells} path={path} tempFunction={props.tempFunction} data_getter={props.data_getter} show={show} setShow={setShow} onHide={() => setShow(false)} />
            }


            {
              (path === '/CollegeData' || path === '/BatchData' || path === "/GroupSession") && (
                <>
                  <Tooltip title="Add Data" >
                    <IconButton color='#999999' onClick={(e) => callModalInput(e)}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download">
                    <IconButton onClick={() => props.fileDownload()}>
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) || (
                <>
                  {
                    path.includes("/MentorData") || path.includes("/MentorDetails") ?
                      <></> : (<Tooltip title="Add Data" >
                        <IconButton color='#999999' onClick={(e) => callModalInput(e)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>)}
                  <Tooltip title="Download">
                    <IconButton onClick={() => props.fileDownload()}>
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  {/* csv upload here */}
                  <Tooltip title="Upload Files">
                    <IconButton>
                      {/* <input type="file" onChange={(e) => props.handleCsvUpload(e)} />
                      <UploadFileIcon /> */}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => props.handleCsvUpload(e)}
                        ref={fileInputRef}
                      />
                      <UploadFileIcon onClick={() => fileInputRef.current.click()} />
                    </IconButton>
                  </Tooltip>
                  {path === "/StudentData" ? <>
                    <Tooltip title="Download format">
                      <IconButton onClick={() => props.downloadCSVFormat()}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </> : <>
                    <Tooltip title="Filter List">
                      <IconButton>
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                  }

                </>
              )
            }
          </>
        )}
      </Toolbar>
    </RowsContext.Provider>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function EnhancedTable(props) {
  console.log("specialization datan in table compoennt", props.spec_data);
  console.log("jd data in table component", props.jd_data);
  const navigate = useNavigate();
  const [DeleteAllState, setDeleteAllState] = useState(false)
  const rowsData = useContext(RowsContext);
  const { spec_data, jd_data, path, csvData, handleBatchDataPage, navigateToResumesSettings, handleStudentsDetailsClick, handleCOllegeReportPage, activityTypeData, durationData, mentorData, collegeData, batchData, college_name, activityData, handleProcessDataPage, CollegeListData, handleStudentDataPage, process_data, batchTypeData, programData, handleSetProcessDeadline, handleFeedbackNavigation } = props;
  console.log("Collage name in Table comp", college_name)
  console.log({ path })
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('heading1');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  // const [rowsPerPage, setRowsPerPage] = React.useState( path === '/JD' ? 10 : 20);


  useEffect(() => {
    function setPagesrows() {
      console.log('outside useeffect', rowsData.length)
      if (path === "/JD") {
        setRowsPerPage(10, '10')
      }

      else {
        if (rowsPerPage !== 5 || rowsPerPage !== 10 || rowsPerPage !== 20) {
          setRowsPerPage(rowsData?.length, 'All')
        }
        else {
          setRowsPerPage()
        }
      }

    }
    setPagesrows()
  }
    , [rowsData])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  // csv files upload


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = path !== "/Durations" ? rowsData.map((n) => n.id) : rowsData.map((n) => n.duration_value);
      console.log(newSelected);
      setSelected(newSelected);
      setDeleteAllState(true)
      return;
    }
    setDeleteAllState(false)
    setSelected([]);
  };

  const handleClick = (event, heading1) => {
    console.log({ heading1 })
    const selectedIndex = selected.indexOf(heading1);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, heading1);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  console.log({ selected });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    if ((event.target.value) === "All")
      setRowsPerPage(rowsData.length);
    else
      setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (heading1) => selected.indexOf(heading1) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  const [show, setShow] = React.useState(false);
  const [acceptRejectModal, setAcceptRejectModal] = useState(false)
  // const [modalConfirmationShow, setModalConfirmationShow] = React.useState(false)
  const [showModalView, setshowModalView] = React.useState(false);

  const [active, setActive] = useState()
  const handleCloseModal = () => setShow(false)
  const [to_modal_json, setTo_modal_json] = useState({});
  const [datatoModalView, setdatatoModalView] = useState({});
  const [action, setAction] = useState("")

  const callModalInput1 = (e, id, index, from) => {

    console.log("TableComponent-callModalInput1:", { id }, { index })
    setTo_modal_json({ ...to_modal_json, id: id, index: index })

    if (from === "edit") {
      setAcceptRejectModal(true)
      if (path === "/MentorData") {
        props.data_getter("", id, index)
        setAction("edit")
      }
    }

  }

  const callModalInput = (e, id, index, from) => {


    setTo_modal_json({ ...to_modal_json, id: id, index: index })

    if (from === "edit") {
      setShow(true);
      //for the Edit Modal
      if (path === "/JD" || path === "/Durations" || path === "/CollegeData" || path === "/StudentData" || path === "/CollegeInfoPage" || path === "/MentorData" || path === "/MentorDetails" || path === "/BatchData" || path === "/GroupSession" || path === "/ProcessData" || path === "/SKU" || path === "/Bundle" || path === "/Coupon" || path === "/Durations" || path === "/DefaultJD")
        props.data_getter("", id, index, "edit")
      setAction("edit")

      if (path === "/CollegeData") {
        props.data_getter("", id, index)
        setAction("edit")
      }
      if (path === "/DefaultJD")
        props.data_getter("", id, index, "edit")
    }
    else if (from === 'view') {

      const ViewContext = React.createContext();
      //for the data to be appeared on the view Modal
      console.log("Opening the Eye");

      if (path === "/JD" || path === "/Bundle" || path === "/MentorDetails" || path === "/SKU" || path === "/Coupon" || path === "/Durations" || path === "/DefaultJD" || path === "/GroupSession" || path.includes("/BatchData") || path.includes("/ProcessData") || path.includes("/StudentData") || path.includes("/MentorData") || path.includes("/CollegeInfoPage")) {
        props.data_getter("View", id, index)
        console.log("View is passed", id, index)
      }

      setshowModalView(true)
      setAction("view")
      if (path === "/ActivityType") { setShow(true) }

    }
    // else if (from === "delete") {
    //   setDeleteAllModal(true)
    //   console.log("item to delte is :", id)
    //   props.data_getter("", id, index, "delete")
    // }
    else {
      setDeleteAllModal(true)

      props.data_getter("", id, index, "delete")
    }
  }

  const activeFlagCheck = (e, index) => {
    console.log(e.target.checked)
    let temp =
      setActive(e.target.checked)
  }


  const [is_delete_all, setIs_delete_all] = useState(false)
  const [deleteAllModal, setDeleteAllModal] = useState(false)

  let all_ids = []
  let all_index1 = []
  const [all_index, setAll_index] = useState([])
  // let mul_index = []

  useEffect(() => {
    const handleEffect = () => {

      rowsData?.map((item, index) => {
        console.log("index of selected item:", index)
        console.log("rowsData?.map ~ selected.indexOf(item.id):", selected.indexOf(item.id))
        if (path !== "/Durations") {
          if (selected.indexOf(item.id) > -1) {
            all_index1.push(index)
          }

        } else {
          if (selected.indexOf(item.duration_value) > -1) {
            all_index1.push(index)
          }
        }

      })

      setAll_index(all_index1)
    }
    handleEffect()
  }, [selected])

  // const navigate = useNavigate();







  return (
    <RowsContext.Provider value={rowsData}>
      <Box sx={{ width: '90%', ml: path === "/CollegeInfoPage" ? "0px" : '12%' }}>
        <Paper sx={{ width: '90%', mb: 2, mt: !props.noMarginTop ? 12 : 0 }}>
          <EnhancedTableToolbar activityTypeData={activityTypeData} durationData={durationData} mentorData={mentorData} collegeData={collegeData} batchData={batchData} selected={selected} setSelected={setSelected} process_data={process_data} college_name={college_name} mainFile_modal={props.mainFile_modal} confirmApproveReject={props.confirmApproveReject} handleCsvUpload={props.handleCsvUpload} file_Getter={props.file_Getter} spec_data={spec_data} jd_data={jd_data} path={path} batchTypeData={batchTypeData} CollegeListData={CollegeListData} activityData={activityData} programData={programData} data_getter={props.data_getter} numSelected={selected.length} headCells={props.headCells} count={props.count} fileDownload={props.fileDownload} downloadCSVFormat={props.downloadCSVFormat} modalConfirmationShow={deleteAllModal} setModalConfirmationShow={setDeleteAllModal} setIs_delete_all={setIs_delete_all} />

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
              size={dense ? 'medium' : 'small'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                setSelected={setSelected}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowsData?.length}
                headCells={props.headCells}
                path={props.path}
              />
              <TableBody>
                {rowsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemSelected = (path === '/Durations' ? isSelected(row.duration_value) : path === '/StudentData' ? isSelected(row.email) : path === '/DefaultJD' ? isSelected(index) : isSelected(row.id))
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          aria-checked={isItemSelected}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, (path === '/Durations' ? row.duration_value : path === '/StudentData' ? row.email : path === '/DefaultJD' ? index : row?.id))}

                          key={path === '/Durations' ? row.duration_value : path === '/DefaultJD' ? index : row.id}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {path === "/RoleType" &&
                        Object.values(row).filter((item, index) => (index !== 0)).map((cell, i) => {
                          console.log("Table cell is :", { cell })
                          return (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align='left'
                              padding="none"
                              sx={{ fontSize: '16px' }}
                            >
                              {i === 1 ? spec_data.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.name :
                                i === 2 ? jd_data.filter((item, index) => item.id === parseInt(cell))[0]?.name ?? "N/A" :
                                  cell === null ? "NA" : cell}
                            </TableCell>)
                        })}
                      {(path === "/UserType" || path === "/BlogsTags" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") &&
                        Object.values(row).filter((item, index) => (index !== 0)).map((cell, i) => {
                          return (
                            <>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                padding="none"
                                sx={{ fontSize: '16px' }}
                              >
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            </>
                          )
                        })
                      }

                      {(path === "/BlogsCategory") &&
                        Object.values(row).filter((item, index) => (index !== 0 && index !== 2 && index !== 3 && index !== 4)).map((cell, i) => {
                          return (
                            <>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                padding="none"
                                sx={{ fontSize: '16px' }}
                              >
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            </>
                          )
                        })
                      }
                      {(path === "/BlogsAuthor") &&
                        Object.values(row).filter((item, index) => (typeof item === "string")).map((cell, i) => {
                          return (
                            <>
                              {console.log("author check", cell)}
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                padding="none"
                                sx={{ fontSize: '16px' }}
                              >
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            </>
                          )
                        })
                      }
                      {(path === "/ActivityType") && (
                        <>
                          {Object.values(row)
                            .filter((item, index) => ![0, 3, 5, 6, 7, 8].includes(index))
                            .map((cell, i) => (
                              <TableCell
                                key={i} // Adding key here to help React identify elements
                                component="th"
                                id={labelId}
                                scope="row"
                                align="left"
                                padding="none"
                                style={{ width: "max(200px, 50%)", fontSize: '16px' }}
                              >
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            ))}
                          <TableCell
                            colSpan={2}
                            component="th"
                            id={labelId}
                            scope="row"
                            align="left"
                            padding="none"
                            sx={{ fontSize: '16px' }}
                          >
                            <button onClick={() => handleFeedbackNavigation(row.id, row.act_type_name)} className="batch-button">
                              Edit Feedback
                            </button>
                          </TableCell>
                        </>
                      )}



                      {(path === "/StudentData") && Object.values(row).filter((item, index) => (index !== 0 && index !== 1 && index !== 7)).map((cell, i) => {
                        return (
                          <>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align='left'
                              padding="none" style={{ width: "max(200px, 50%)", fontSize: '16px' }}>
                              {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                            </TableCell>
                          </>
                        );
                      })}


                      {(path === "/CollegeInfoPage") &&
                        Object.values(row).filter((item, index) => (index !== 4 && index !== 5 && index !== 6 && index !== 8)).map((cell, i) => {

                          return (
                            <>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                padding="none" style={{ width: "max(200px, 50%)", fontSize: '16px' }}>
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            </>
                          )
                        })
                      }
                      {(path === "/MentorDetails") && (
                        <>
                          {Object.entries(row).filter(([key, cell], index) => ![0, 5, 6, 7, 8, 9, 10, 11, 12, 14].includes(index)).map(([key, cell]) => {
                            if (key === "linkedin") {
                              // Render button
                              return (
                                <TableCell
                                  key={key} // Make sure to include a unique key for each element in a list
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align="left"
                                  padding="none"
                                  style={{ width: "max(200px, 50%)", fontSize: '16px' }}
                                >
                                  <button
                                    key={key} // Make sure to include a unique key for each element in a list
                                    style={{
                                      border: "none",
                                      color: "#1C8EA8",
                                      background: "transparent",
                                      textAlign: "left",
                                      outline: "none",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const mentorLinkedInLink = cell;
                                      if (mentorLinkedInLink.startsWith("http://") || mentorLinkedInLink.startsWith("https://")) {
                                        window.open(mentorLinkedInLink);
                                      } else {
                                        window.open("https://" + mentorLinkedInLink);
                                      }
                                    }}
                                  >
                                    LinkedIn
                                  </button>
                                </TableCell>
                              );
                            } else {
                              // Render cell content
                              return (
                                <TableCell
                                  key={key} // Make sure to include a unique key for each element in a list
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align="left"
                                  padding="none"
                                  style={{ width: "max(200px, 50%)", fontSize: '16px' }}
                                >
                                  {typeof cell === "string" ? cell.substring(0, 30) + (cell.length > 30 ? "..." : "") : cell}
                                </TableCell>
                              );
                            }
                          })}
                          <TableCell colSpan={2}
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px' }}
                          >
                            <button onClick={() => { handleStudentsDetailsClick(row.user_id) }} className='batch-button'>Edit Details</button>
                          </TableCell>
                        </>
                      )}

                      {(path === "/MentorData") &&
                        <>
                          {Object.entries(row).filter(([key, item], index) => (index !== 0 && index !== 7 && index !== 8)).map(([key, cell], i) => {
                            return (
                              <>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='left'
                                  padding="none"
                                  style={{ width: "max(200px, 50%)", fontSize: '16px' }}
                                >
                                  {key === "linkedin" ? (
                                    <button
                                      key={key} // Make sure to include a unique key for each element in a list
                                      style={{
                                        border: "none",
                                        color: "#1C8EA8",
                                        background: "transparent",
                                        textAlign: "left",
                                        outline: "none",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const mentorLinkedInLink = cell;
                                        if (mentorLinkedInLink.startsWith("http://") || mentorLinkedInLink.startsWith("https://")) {
                                          window.open(mentorLinkedInLink);
                                        } else {
                                          window.open("https://" + mentorLinkedInLink);
                                        }
                                      }}
                                    >
                                      LinkedIn
                                    </button>
                                  ) : (
                                    typeof cell === "string" ? cell.substring(0, 15).concat(cell.length > 15 ? "..." : "") : cell
                                  )}
                                </TableCell>
                              </>
                            )
                          })}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px', textAlign: "center" }}
                          >
                            <EditIcon
                              onClick={(e) => callModalInput1(e, row.id, index, "edit")}
                              sx={{
                                color: '#BFBFBF',
                                height: '30px',
                                borderRadius: "100%",
                                width: '30px',
                                padding: '5px',
                                opacity: '50%',
                                bgcolor: 'none',
                                ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
                              }}
                            />
                          </TableCell>
                        </>
                      }

                      {/* !@abhra -  changed the mapping to obj.keys rather than obj.values */}
                      {(path === "/ProcessData") &&
                        <>
                          {Object.keys(row).filter((item, index) => (item === "sku_name" || item === "end_date" || item === "start_date")).map((cell, i) => {
                            return (
                              <>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='left'
                                  padding="none" style={{ width: "max(200px, 50%)", fontSize: '16px' }}>
                                  {row[cell] !== null ? (cell === "start_date" || cell === "end_date") ? formatConvertermonthfirst(row[cell]) : row[cell] : "N/A"}
                                </TableCell>
                              </>
                            )
                          })}
                          < TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px' }
                            }
                            style={{ borderTop: index === 0 ? "1px solid rgba(224, 224, 224, 1)" : "" }}>
                            {!row.is_offline ? <></> : !row.specialization_deadline ?
                              <button onClick={() => { handleSetProcessDeadline(row.id) }} className='batch-button'>Start Process</button>
                              : <button onClick={() => { navigate(`/OfflineSessions/StudentSummary?process_id=${row.id}`); }} className='batch-button'>Go to Activity</button>
                            }
                          </TableCell>
                          {console.log("rows info", row)}
                          {row.type === "RDS" ? <>
                            <TableCell colSpan={2}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='left'
                              padding="none"
                              sx={{ fontSize: '16px' }}
                            >
                              <button onClick={() => { navigateToResumesSettings(row.id) }} className='batch-button'>Resume Setting</button>
                            </TableCell>
                          </> : <>
                            <TableCell colSpan={2}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='left'
                              padding="none"
                              sx={{ fontSize: '16px' }}
                            >
                              {/* <button onClick={() => { navigateToResumesSettings(row.id) }} className='batch-button'>Resume Setting</button> */}
                            </TableCell>
                          </>

                          }

                        </>


                      }

                      {(path === "/JD" || path === "/SKU" || path === "/Bundle" || path === "/Coupon") &&
                        Object.values(row).filter((item, index) => (path === "/SKU" || index !== 0)).map((cell, i) => {
                          return (
                            <>
                              <TableCell
                                component="th"
                                id={labelId}
                                align='left'
                                padding="none" style={{ width: "max(200px, 50%)", fontSize: '16px' }}>
                                {console.log(cell)}
                                {/* {typeof cell === "string" ? cell.concat(",") : cell} */}
                                {/* {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length >= 30 ? "..." : "") : cell} */}
                                {/* {console.log("check last 4 characters:",cell.slice(-4))} */}
                                {path === "/SKU" && typeof cell === "string"
                                  ? cell.substring(0, 30).concat(cell.length >= 30 ? "..." : "")
                                  : typeof cell === "string" && (cell.substring(0, 4) === "http" && cell.substring(cell.length - 4))
                                    ? null
                                    : cell}
                              </TableCell>
                            </>
                          )
                        })
                      }

                      {(path === "/Durations") &&
                        Object.values(row).filter((item, i) => (item !== 0)).map((cell, i) => {
                          console.log(cell, ":heyy cells")
                          return (
                            <>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                padding="none"
                                sx={{ fontSize: '16px' }}
                              >
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            </>
                          )
                        })


                      }
                      {(path === "/CollegeData") &&
                        <>
                          {Object.values(row).filter((item, index) => (index !== 0 && index !== 3)).map((cell, i) => {

                            return (
                              <>
                                {console.log("row is :", row)}
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  align='left'
                                  padding="none"
                                  sx={{ fontSize: '16px' }}
                                >

                                  {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                                </TableCell>

                              </>
                            )
                          }
                          )}

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px' }}
                          >
                            <button onClick={() => { handleBatchDataPage(row.id, row.college_name) }} className='batch-button'>Batch</button>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px' }}
                          >
                            <button onClick={() => { handleCOllegeReportPage(row.id, row.college_name) }} className='batch-button'>Report</button>
                          </TableCell>
                        </>


                      }


                      {path === "/BatchData" &&
                        <>
                          {Object.values(row).filter((item, index) => (index !== 0 && index !== 5 && index !== 6 && index !== 7)).map((cell, i) => {
                            return (
                              <>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  align='left'
                                  padding="none" style={{ width: "max(200px, 50%)", fontSize: '16px' }}>
                                  {i === 2 ? programData.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.name : i === 3 ? batchTypeData.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.name : typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                                </TableCell>
                              </>
                            )
                          })}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px', marginRight: '10px' }}
                            style={{ borderTop: index === 0 ? "1px solid rgba(224, 224, 224, 1)" : "" }}
                          >
                            <button onClick={() => handleStudentDataPage(row.id, row.user_group_name)} className='batch-button'>Student</button>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            padding="none"
                            sx={{ fontSize: '16px' }}
                            style={{ borderTop: index === 0 ? "1px solid rgba(224, 224, 224, 1)" : "" }}
                          >
                            <button onClick={() => { handleProcessDataPage(row.id, row.user_group_name) }} className='batch-button'>Process</button>
                          </TableCell>
                        </>
                      }

                      {path === "/GroupSession" &&
                        <>
                          {Object.values(row).filter((item, index) => (index !== 0 && index !== 8 && index !== 9 && index !== 10)).map((cell, i) => {
                            return (
                              <>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  align='left'
                                  padding="none" style={{ width: "max(200px, 50%)", fontSize: '16px' }}>
                                  {i === 0 ? activityTypeData.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.act_type_name : i === 2 ? mentorData.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.first_name : i === 3 ? collegeData.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.college_name : i === 4 ? batchData.filter((item_s, index_s) => item_s.id === parseInt(cell))[0]?.user_group_name : typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                                </TableCell>
                              </>
                            )
                          })}

                        </>
                      }


                      {(path === "/DefaultJD") &&
                        Object.values(row).filter((item, i) => (typeof item !== "number")).map((cell, i) => {
                          console.log(cell, ":heyy cells")
                          return (
                            <>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                padding="none"
                                sx={{ fontSize: '16px' }}
                              >
                                {typeof cell === "string" ? cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell}
                              </TableCell>
                            </>
                          )
                        })


                      }

                      <TableCell align="right" style={{ width: '150px', borderTop: index === 0 ? "1px solid rgba(224, 224, 224, 1)" : "" }}>

                        {
                          path === "/Durations" ?
                            <VisibilityOffIcon sx={{ color: '#BFBFBF', fontSize: '25px', height: '35px', opacity: '30%', width: '35px', padding: '5px' }} /> :
                            (DeleteAllState || selected.length > 0) ?
                              <VisibilityOffIcon sx={{ color: '#BFBFBF', fontSize: '25px', height: '35px', opacity: '30%', width: '35px', padding: '5px' }} /> :
                              <RemoveRedEyeOutlinedIcon onClick={(e) => path.includes("/StudentData") ? callModalInput(e, row.email, index, "view") : path === "/DefaultJD" ? callModalInput(e, { id_batch: row.batch_type_id, id_role: row.role_type_id }, index, "view") : path === "/MentorDetails" ? callModalInput(e, row.user_id, index, "view") :path === "/JD" ? callModalInput(e, row.id, page * rowsPerPage + index, "view") : callModalInput(e, row.id, index, "view")}
                                sx={{ color: '#BFBFBF', fontSize: '25px', height: '35px', width: '35px', padding: '5px', bgcolor: 'none', ':hover': { bgcolor: 'none', opacity: '100%' } }} />}
                        {(DeleteAllState || selected.length > 0) ? null : <EditIcon onClick={(e) => path === "/DefaultJD" ? callModalInput(e, { id_batch: row.batch_type_id, id_role: row.role_type_id }, index, "edit") : path === "/MentorDetails" ? callModalInput(e, row.user_id, index, "edit") :path === "/JD" ? callModalInput(e, row.id, page * rowsPerPage + index, "edit") : callModalInput(e, row.id, index, "edit")} sx={{ color: '#BFBFBF', height: '30px', borderRadius: "100%", width: '30px', padding: '5px', opacity: '50%', bgcolor: 'none', ':hover': { bgcolor: '#D9D9D9', opacity: '100%' } }} />}
                        {(DeleteAllState || selected.length > 0 || path.includes("/MentorData") || path.includes("/MentorDetails") || path.includes('/BlogsCategory') || path.includes('/BlogsAuthor') || path.includes("/BlogsTags")) ? null : <DeleteIcon onClick={(e) => path.includes("/StudentData") ? callModalInput(e, row.email, index, "delete") : path.includes("/CollegeInfoPage") ? callModalInput(e, row.ca_userid, index, "delete") : path.includes("/ ") ? callModalInput(e, row.email, index, "delete") : path === "/DefaultJD" ? callModalInput(e, { id_batch: row.batch_type_id, id_role: row.role_type_id }, index, "delete") : callModalInput(e, row.id, index, "delete")} sx={{ color: '#FF0000', height: '30px', borderRadius: "100%", width: '30px', padding: '5px', bgcolor: 'none', opacity: '50%', ':hover': { bgcolor: '#D9D9D9', opacity: '70%' } }} />}


                      </TableCell >
                    </TableRow >

                  );
                })}
                {
                  emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )
                }
              </TableBody >
            </Table >
          </TableContainer >

          <TablePagination
            // rowsPerPageOptions={[rowsData !== undefined ? ((rowsData.length > 5 ? rowsData.length : 5), (rowsData.length > 10 ? rowsData.length : 10), (rowsData.length > 20 ? rowsData.length : 20)) : null]}
            rowsPerPageOptions={[5, 10, 20, { label: 'All', value: (rowsData?.length) }]}
            component="div"
            sx={{ '.MuiTablePagination-selectLabel': { marginBottom: 0 }, '.MuiTablePagination-displayedRows': { marginBottom: 0 } }}
            count={(rowsData)?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {console.log('rows pagination', rowsData?.length)}
        </Paper >

      </Box>
      {console.log("spec_data", spec_data)}
      {
        show === true && path === "/RoleType" ?
          (<ModalInput spec_data={spec_data} jd_data={jd_data} path={path} data_getter={props.data_getter} from_table={to_modal_json} headCells={props.headCells} tempFunction={props.tempFunction} show={show} setShow={setShow} onHide={() => setShow(false)} />)
          : show === true && (path === "/JD" || path === "/Durations" || path === "/CollegeData" || path === "/StudentData" || path === "/CollegeInfoPage" || path === "/MentorData" || path === "/MentorDetails" || path === "/GroupSession" || path === "/BatchData" || path === "/ProcessData" || path === "/SKU" || path === "/Bundle" || path === "/Coupon" || path === "/DefaultJD") ?
            props.mainFile_modal(show, setShow, "Edit")
            : acceptRejectModal === true && (path === "/MentorData") ?
              props.confirmApproveReject(acceptRejectModal, setAcceptRejectModal, "Edit")
              : (<ModalInput path={path} data_getter={props.data_getter} from_table={to_modal_json} headCells={props.headCells} tempFunction={props.tempFunction} show={show} setShow={setShow} onHide={() => setShow(false)} />)
      }
      {
        show === true && path === "/ActivityType" ?
          <>
            <ModalInputUpload file_Getter={props.file_Getter} path={window.location.pathname} action={action} from_table={to_modal_json} headCells={props.headCells} data_getter={props.data_getter} show={show} setShow={setShow} onHide={() => setShow(false)} />
          </> : <></>
      }
      {console.log("asdfghjkl", deleteAllModal)}
      {deleteAllModal === true ? (<ModalConfirmation all_index={all_index} all_ids={selected} setSelected={setSelected} setDeleteAllState={setDeleteAllState} is_delete_all={is_delete_all} setIs_delete_all={setIs_delete_all} data_getter={props.data_getter} from_table={to_modal_json} modalConfirmationShow={deleteAllModal} path={props.path} setModalConfirmationShow={setDeleteAllModal} onHide={() => setDeleteAllModal(false)} sx={{ zIndex: 1 }} />) : null}


      {
        showModalView === true && (path === "/JD" || path === "/SKU" || path === "/Bundle" || path.includes("/BatchData") || path === "/GroupSession" || path.includes("/ProcessData") || path === "/Durations" || path === "/Coupon" || path.includes("/CollegeInfoPage") || path === "/DefaultJD" || path.includes("/StudentData")) ?
          <>
            {/* (<ModalInputUpload data_getter={props.data_getter} path={path} action="view" from_table={to_modal_json} headCells={props.headCells} show={show} setShow={setShow} onHide={() => setShow(false)} />)  */}
            {props.mainFile_modal(showModalView, setshowModalView, "View")}
          </> : path !== "/ActivityType" ? (<ModalView spec_data={spec_data} data_getter={props.data_getter} path={path} from_table={to_modal_json} headCells={props.headCells} showModalView={showModalView} setshowModalView={setshowModalView} onHide={() => setshowModalView(false)} />) : <></>


      }
    </RowsContext.Provider >
  );
}