import React from "react";
import "./Switch.css";
import cx from 'classnames';

// const Switch = ({ id, isToggled, onToggle, disable }) => {
const Switch = (props) => {
    const id = props.id;
    const isToggled = props.isToggled;
    const onToggle = props.onToggle;
    const disable = props.disable;
    // console.log(isToggled)
    let rounded = true
    const sliderCX = cx('slider', {
        'round': rounded
    })
    const handleChange = (event) => {
        let eleId = event.target.id
        onToggle(eleId)
        // let sectionId = event.target.
    }
    return (
        <label className="switch">
            <input className="switch-input" type="checkbox" id={id} checked={isToggled}
                onChange={handleChange} disabled={disable}
            // disabled={disable}
            />
            {/* <input type="button" name="sendNewSms" class="inputButton" disabled="disabled" id="sendNewSms" value=" Send " /> */}
            {disable === true && (<span className={sliderCX}
                // style={{borderRadius:"200%"}}
                // style={{ backgroundColor: "lightgrey" }}
            // disabled={disable}

            />)}

            {disable === false && (<span className={sliderCX}
                // style={{borderRadius:"200%"}}
                // style={{ cursor: "pointer" }}
            // disabled={disable}
            />)}
        

        </label>
    );
}
export default Switch;