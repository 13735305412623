import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import CardActions from '@mui/material/CardActions';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Service from '../../../services/httpService';
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import Divider from '@mui/material/Divider';
import Loader from '../../reusables/Loader/Loader';
import CircularProgress from '@mui/material/CircularProgress';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import GroupChatModal from '../../GroupChatModal/GroupChatModal';
const DashboardOngoingEvents = () => {
    const [loading, setLoading] = useState(true);
    const service = new Service()
    const navigate = useNavigate();
    const [cardDetails, setCardDetails] = useState([])
    const [expandedCardDetails, setExpandedCardDetails] = useState({})
    const [groupChat, setGroupChat] = useState(false);
    const [process_id, setProcess_id] = useState(null)
    const handleExpand = async (event, isAllDataExpanded, isExpanded, is1x1Expanded, notScheduledExpanded, index, process_id) => {
        console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded, process_id);
        event.stopPropagation();

        let tempExpandedCardDetails = await expandedCardDetailsDataGetter(process_id, index);

        const updatedCardDetails = cardDetails.map((element, cardIndex) => {
            if (cardIndex === index) {
                return {
                    ...element,
                    done_count: tempExpandedCardDetails.done_count,
                    feedback_count: tempExpandedCardDetails.feedback_count,
                    in_process_count: tempExpandedCardDetails.in_process_count,
                    initialized_count: tempExpandedCardDetails.initialized_count,
                    initialized_expired_bookings_count: tempExpandedCardDetails.initialized_expired_bookings_count,
                    mentor_absent_count: tempExpandedCardDetails.mentor_absent_count,
                    purchased_count: tempExpandedCardDetails.purchased_count,
                    scheduled_count: tempExpandedCardDetails.scheduled_count,
                    student_absent_count: tempExpandedCardDetails.student_absent_count
                };
            }
            return element;
        });

        setCardDetails(updatedCardDetails);
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                isAllDataExpanded: !isAllDataExpanded,
                isExpanded: !isExpanded,
            };
            return updatedBundleInfo;
        });
        setExpandedCardDetails(tempExpandedCardDetails);
        console.log("details", process_id, tempExpandedCardDetails, updatedCardDetails);
    };
    console.log("details111", cardDetails);

    const handleGroupChatOpen = (e, process_id) => {
        console.log("process id", process_id);
        
        e.stopPropagation()
        setProcess_id(process_id)
        setGroupChat(true);
    };

    const handleGroupChatClose = () => {
        setGroupChat(false);
    };

    const closeIs1x1Expanded = (event, is1x1Expanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                is1x1Expanded: !is1x1Expanded,
            };
            return updatedBundleInfo;
        });
    }

    const closeNotScheduledExpanded = (event, notScheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                notScheduledExpanded: !notScheduledExpanded,
            };
            return updatedBundleInfo;
        });
    }

    const closeIsExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                isExpanded: !isExpanded,
                // is1x1Expanded: false,
                // notScheduledExpanded: false
            };
            return updatedBundleInfo;
        });
    }

    const closeRescheduledExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, rescheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                rescheduledExpanded: !rescheduledExpanded,
            };
            return updatedBundleInfo;
        });
    }

    // useeffect'
    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let student = await studentDataGetter();
            console.log("not filtered data", student);
            let filtered_student_list = student.filter(item => item.sku_name !== "Resumes.Digital 1 year")
            console.log("filtered data", filtered_student_list);
            filtered_student_list?.forEach(element => {
                element.process_id = element.process_id;
                element.isExpanded = false;
                element.is1x1Expanded = false;
                element.notScheduledExpanded = false;
                element.isAllDataExpanded = false;
                element.rescheduledExpanded = false;
                element.loadingButton = false;
            });
            setCardDetails(filtered_student_list)
            setLoading(false);
            // forming the data
            console.log("odonil", { student })
        }
        handleEffect();
    }, [])

    const studentDataGetter = async () => {
        try {
            const res = await service.get("/allCollegeOngoingEvents")
            console.log("Card details response:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const expandedCardDetailsDataGetter = async (process_id, index) => {
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                loadingButton: true
            };
            return updatedBundleInfo;
        });
        try {
            const res = await service.get(`/CollegeOngoingEventsById?process_id=${process_id}`)
            console.log("Card details response:", res.data)
            setCardDetails(prevCardInfo => {
                const updatedBundleInfo = [...prevCardInfo];
                updatedBundleInfo[index] = {
                    ...updatedBundleInfo[index],
                    loadingButton: false
                };
                return updatedBundleInfo;
            });
            return res.data

        } catch (err) {
            console.log(err)
        }
    }
    const navigateToStudentDetails = (process_id, college_id, college_name, batchId, batchName, sku_name, searched) => {
        navigate("/College1x1StudentDetails", { state: { college_data_id: college_id, college_data_name: college_name, batchName: batchName, batchid: batchId, process_id: process_id, sku_name: sku_name, searched: searched } })
    }

    console.log("crad details", cardDetails);
    console.log("expanded card details", Object.entries(expandedCardDetails));
    return (
        <div className="cardsInsideFlex">
            {loading ? (
                <Loader show={loading} />
            ) : (
                <>
                    {cardDetails?.map((details, index) => {

                        return (
                            <>
                                <Box sx={{ width: 255, marginRight: '1rem', marginLeft: '.2rem', marginBottom: '.2rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: details.isAllDataExpanded ? "auto" : 222, cursor: "pointer" }}
                                    onClick={details?.isAllDataExpanded === true ? () => { } : () => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "All")}
                                >
                                    <CardContent className="cardContainer" disableGutters style={{ width: index === 0 ? "255px" : '255px' }}>
                                        <Typography variant="body2" sx={{ fontSize: 20, marginBottom: "1rem" }} gutterBottom>
                                            {details.sku_name.substring(0, 15).concat(details.sku_name.length > 15 ? "..." : "")}
                                        </Typography>
                                        <Typography variant="h5" component="div"></Typography>
                                        <div className="container">
                                            <Typography variant="body2" class='providingSpace1' >College:</Typography>
                                            <Typography variant="body2" class='providingSpace'>{details.college_name.substring(0, 15).concat(details.college_name.length > 15 ? "..." : "")}</Typography>
                                        </div>
                                        <div className="container">
                                            <Typography variant="body2" class='providingSpace1'>Batch:</Typography>
                                            <Typography variant="body2" class='providingSpace'>{details.user_group_name}</Typography>
                                        </div>
                                        <div className="container">
                                            <Typography variant="body2" class='providingSpace1'>Total Students:</Typography>
                                            <Typography variant="body2" class='providingSpace'>{details.total_student}</Typography>
                                        </div>
                                        {details.isAllDataExpanded && (
                                            <>
                                                {/* {expandedCardDetails.map(items => {
                                           
                                            return ( */}
                                                <>
                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Key Updates</Typography>
                                                        <button onClick={(e) => closeIsExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.isExpanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {
                                                        details.isExpanded && (
                                                            <>
                                                                <div>
                                                                    <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Done")}>
                                                                        <Typography variant="body2" color={Color.primary1}>1x1 Done:</Typography>
                                                                        <Typography variant="body2" class="providingSpaceForSubDetails">{details.done_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Mentor Absent")}>
                                                                        <Typography variant="body2" color={Color.primary1}>Mentor Absent:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.mentor_absent_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Scheduled")}>
                                                                        <Typography variant="body2" color={Color.primary1}>Scheduled:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">{details.scheduled_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Not Scheduled")}>
                                                                        <Typography variant="body2" color={Color.primary1}>Not Scheduled:</Typography>
                                                                        {/* // {order_flag = purchased and number of false} */}
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">{details.purchased_count + details.initialized_expired_bookings_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Mentor Awaited")}>
                                                                        <Typography variant="body2" color={Color.primary1}>Mentor Awaited:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.initialized_count - details.initialized_expired_bookings_count}</Typography>
                                                                    </div>

                                                                    <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Ongoing")}>
                                                                        <Typography variant="body2" color={Color.primary1}>Ongoing:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.in_process_count}</Typography>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                    <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />
                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>1x1 Done</Typography>
                                                        <button onClick={(e) => closeIs1x1Expanded(e, details.is1x1Expanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.is1x1Expanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {
                                                        details.is1x1Expanded && (
                                                            <>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Awaited Mentor Feedback")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Awaited Mentor Feedback: </Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.feedback_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Received Mentor Feedback")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Received Mentor Feedback:</Typography>
                                                                    {/* ask ashish */}
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.done_count - details.student_absent_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Student Absent")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Student Absent:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.student_absent_count}</Typography>
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                    <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />

                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Not Scheduled</Typography>
                                                        <button onClick={(e) => closeNotScheduledExpanded(e, details.notScheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.notScheduledExpanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {
                                                        details.notScheduledExpanded && (
                                                            <>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "No Action Students")}>
                                                                    <Typography variant="body2" color={Color.primary1}>No Action Students: </Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.purchased_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "Expired Bookings")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Expired Bookings:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.initialized_expired_bookings_count}</Typography>
                                                                </div>
                                                            </>
                                                        )
                                                    }


                                                    <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />

                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Rescheduled</Typography>
                                                        <button onClick={(e) => closeRescheduledExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, details.rescheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.rescheduledExpanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {details.rescheduledExpanded && (
                                                        <>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rescheduled by student: </Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rescheduled by mentor:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Mentor’s Pending reschedule request:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Student’s Pending reschedule request:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rejected Requests by student:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rejected Requests by mentor:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                        </>
                                                    )}
                                                </>


                                            </>
                                        )}
                                        {details.isAllDataExpanded === false && (
                                            <CardActions sx={{ justifyContent: 'flex-end', bottom: 0, }}>
                                                <button
                                                    className="submitBtn"
                                                    type="button"
                                                    style={{ height: "44px", bottom: 0, }}
                                                    onClick={(e) => handleExpand(e, details.isAllDataExpanded, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index, details.process_id)}
                                                >
                                                    {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : "Load Details"}
                                                </button>
                                            </CardActions>
                                        )}
                                        {/* <CardActions sx={{ justifyContent: 'flex-end', bottom: 0, }}>
                                            <button
                                                className="submitBtn"
                                                type="button"
                                                style={{ height: "44px", bottom: 0, background: Color.white, border: `1px solid ${Color.primary1}`, color: Color.primary1 }}
                                                onClick={(e) => handleGroupChatOpen(e, details.process_id)}
                                            >
                                                {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : <Typography><TextsmsOutlinedIcon sx={{ color: Color.primary1 }} /> Mentor Chat Room </Typography>}
                                            </button>
                                        </CardActions> */}
                                    </CardContent>
                                </Box>
                            </>
                        )
                        
                    })}
                </>
            )}
            {groupChat && <GroupChatModal modalOpen={groupChat} handleModalClose={handleGroupChatClose} process_id={process_id} />}
        </div>
    )
}

export default DashboardOngoingEvents