import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from "@mui/material";
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CollegeData from '../../../CollegeDataServices/httpCollege';
import { Color } from '../../../GlobalStyles';
import ServiceCM from '../../../services/cartmoduleServices';
import Service from '../../../services/httpService';
import GroupSession from '../../GroupSessions/GroupSession';
import Loader from '../../reusables/Loader/Loader';
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import '../OngoingEvents/OngoingEvents.css';
import PlaceOSettings from './placeOSettings';
import ProcessCard from './ProcessCard';
import ResumesDigitalCard from './ResumesDigitalCard';
import OfflineCard from './OfflineCard';

export default function OngoingEvents(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let college_id = location.state?.college_id
  let college_name = location.state?.college_name || ""
  let batchName = location.state?.batchName
  let batchId = location.state?.batchId
  const serviceCM = new ServiceCM()
  const [roleTypes, setRoleTypes] = useState([]);
  console.log("batch name", college_name, batchId);
  const [loading, setLoading] = useState(true);
  const [from_table, setFrom_table] = useState();
  const [rowsData, setRowsData] = useState([{ id: null, user_group_id: null, activity_type_id: null, duration_name: "", is_deleted: 0 }])
  const [selectSkuId, setSelectSkuId] = useState({ id: null, name: "" });
  const service = new Service()
  const [cardDetails, setCardDetails] = useState([])
  const [resumesDetails, setResumesDetails] = useState([])
  const [offlineDetails, setOfflineDetails] = useState([])
  const [expandedCardDetails, setExpandedCardDetails] = useState({})
  const [formInputs, setFormInputs] = useState({ start_date: null, end_date: null, recording: 1, timeZone: 'Asia/Kolkata' })
  const collegeData = new CollegeData()
  const [show, setShow] = useState(false)
  const [skuData, setSKUData] = useState([])
  const [jd_input_name, setJd_input_name] = useState();
  const [jd_select_id, setJd_select_id] = useState();
  const [jd_program_id, setJd_program_id] = useState();
  const [activity, setActivity] = useState("");
  const [is_toggled, setIs_toggled] = useState(1)
  const [selectedProcessIndex, setSelectedProcessIndex] = useState(null);
  const [origin, setOrigin] = useState("")

  const skuGetter = async () => {
    try {
      const res = await serviceCM.get('sku')
      console.log("Response from backend is11:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  // useeffect'
  console.log("selectedProcessIndex", selectedProcessIndex);
  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //college getter
      let student = await processDataPastGetter();
      let student_count = await studentDataGetter();
      let sku_data = await skuGetter()
      console.log("skuData", sku_data);
      const roles = await roleTypeGetter();
      setRoleTypes(roles)
      let resumes_digital = student
      console.log("resumes_digital", resumes_digital);
      resumes_digital?.forEach(element => {
        element.process_id = element.id;
        element.loadingButton = false;
      });
      resumes_digital.forEach((process) => {
        const matchingStudentList = student_count.find((item) => item.process_id === process.process_id)
        if (matchingStudentList) {
          process.total_student = matchingStudentList.total_student
          process.sku_name = matchingStudentList.sku_name

        }
      })
      console.log("resumes_digital", resumes_digital, resumes_digital.filter(item => item.sku_name === "Resumes.Digital 1 year"));
      setResumesDetails(resumes_digital.filter(item => item.sku_name === "Resumes.Digital 1 year"))
      setOfflineDetails(resumes_digital.filter(item => item.is_offline === 1))
      setSKUData(sku_data)
      let filtered_student_list = student;
      filtered_student_list?.forEach(element => {
        element.process_id = element.id;
        element.isExpanded = false;
        element.is1x1Expanded = false;
        element.notScheduledExpanded = false;
        element.isAllDataExpanded = false;
        element.rescheduledExpanded = false;
        element.loadingButton = false;
      });

      filtered_student_list.forEach((process) => {
        const matchingStudentList = student_count.find((item) => item.process_id === process.process_id)
        if (matchingStudentList) {
          process.total_student = matchingStudentList.total_student
          process.sku_name = matchingStudentList.sku_name
        }
      })
      setCardDetails(filtered_student_list.filter(item => item.sku_name !== "Resumes.Digital 1 year" && item.is_offline === 0))
      setLoading(false);

      console.log("odonil", { student })
    }

    handleEffect();
  }, [])

  console.log("cardDetails", cardDetails);
  console.log("resumesDetails", resumesDetails);



  const navigateToStudentDetails = (process_id, sku_name, searched) => {
    navigate("/College1x1StudentDetails", { state: { college_data_id: college_id, college_data_name: college_name, batchName: batchName, batchId: batchId, process_id: process_id, sku_name: sku_name, searched: searched } })
  }

  const studentDataGetter = async () => {
    try {
      const res = await service.get(`/batchWiseProcess?batch_id=${batchId}`)
      console.log("batch card details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const processDataPastGetter = async () => {
    try {
      const res = await collegeData.get(`/process/byUserGroup?userGroupId=${batchId}`)
      console.log("batch card details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }


  const expandedCardDetailsDataGetter = async (process_id, index) => {
    setCardDetails(prevCardInfo => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        loadingButton: true
      };
      return updatedBundleInfo;
    });
    try {
      const res = await service.get(`/CollegeOngoingEventsById?process_id=${process_id}`)
      console.log("Card details response:", res.data)
      setCardDetails(prevCardInfo => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          loadingButton: false
        };
        return updatedBundleInfo;
      });
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const goBack = () => {
    console.log("Back Button is Clicked Now")
    navigate(-1);
  }

  const navigateToResumesSettings = (process_id) => {
    navigate("/ResumeTemplate?process_id=" + process_id, { state: { process_id: process_id, college_name: college_name, batch_name: batchName, batch_id: batchId } })
  }

  const navigateToOfflineActivity = (process_id) => {
    navigate(`/OfflineSessions/StudentSummary?process_id=${process_id}`)
  }

  const roleTypeGetter = async () => {
    try {
      const res = await service.get(`/role?is_admin=1`);
      console.log("roleTypeGetter", res.data);
      return res.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };



  return (
    <div>
      <SideNavigationBar />
      {loading ? (<div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div>) : (<>
        <div className='OngoingEventsCard' style={{ marginLeft: "10rem", marginTop: "2rem" }}>
          <div className="topHeadings" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="topHeadings" style={{ display: "flex", justifyContent: "space-between" }}>
              <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
              <p style={{ color: Color.neutralMidGrey }}>{college_name}</p>
              <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
              <p>{batchName}</p>
            </div>
          </div>
          {/* online 1x1 */}

          <ProcessCard origin="normal" skuData={skuData} roleTypes={roleTypes} setSKUData={setSKUData} college_name={college_name} batchId={batchId} batchName={batchName} from_table={from_table} setSelectSkuId={setSelectSkuId} setFormInputs={setFormInputs} selectedProcessIndex={selectedProcessIndex} setIs_toggled={setIs_toggled} cardDetails={cardDetails} setExpandedCardDetails={setExpandedCardDetails} setCardDetails={setCardDetails} activity={activity} is_toggled={is_toggled} show={show} formInputs={formInputs} selectSkuId={selectSkuId} navigateToStudentDetails={navigateToStudentDetails} expandedCardDetailsDataGetter={expandedCardDetailsDataGetter} />

          {/* resumes digital */}
          <ResumesDigitalCard origin="resumes" roleTypes={roleTypes} navigateToResumesSettings={navigateToResumesSettings} skuData={skuData} setSKUData={setSKUData} college_name={college_name} batchId={batchId} batchName={batchName} from_table={from_table} setSelectSkuId={setSelectSkuId} setFormInputs={setFormInputs} selectedProcessIndex={selectedProcessIndex} setIs_toggled={setIs_toggled} resumesDetails={resumesDetails} setExpandedCardDetails={setExpandedCardDetails} setResumesDetails={setResumesDetails} activity={activity} is_toggled={is_toggled} show={show} formInputs={formInputs} selectSkuId={selectSkuId} navigateToStudentDetails={navigateToStudentDetails} expandedCardDetailsDataGetter={expandedCardDetailsDataGetter} />

          {/* offline */}
          <OfflineCard origin="offline" skuData={skuData} setSKUData={setSKUData} roleTypes={roleTypes} navigateToOfflineActivity={navigateToOfflineActivity}  college_name={college_name} batchId={batchId} batchName={batchName} from_table={from_table} setSelectSkuId={setSelectSkuId} setFormInputs={setFormInputs} selectedProcessIndex={selectedProcessIndex} setIs_toggled={setIs_toggled} offlineDetails={offlineDetails} setExpandedCardDetails={setExpandedCardDetails} setOfflineDetails={setOfflineDetails} activity={activity} is_toggled={is_toggled} show={show} formInputs={formInputs} selectSkuId={selectSkuId} expandedCardDetailsDataGetter={expandedCardDetailsDataGetter}/>
          {/* placeo settings */}
          <PlaceOSettings batch_id={batchId} batch_name={batchName} college_name={college_name} />
          {/* group sessions */}
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
            <GroupSession />
          </Box>
        </div>
      </>)}
    </div>
  );
}