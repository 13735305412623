import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./ModalInputStyles.css";
import Modal from "react-bootstrap/Modal";
import httpService from "../../../services/httpService";
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'

export default function HandleEditUser(props) {
    const service = new httpService();
    const [user_type_id, SetUser_type_id] = useState(null);
    const rowsData = useContext(RowsContext);
    const [rowsD, setRowsD] = useState(rowsData);
    const { show, setShow, headCells, from_table, path, } = props;
    const [name, setName] = useState(rowsData[from_table?.index]?.name);
    const [special, setSpecial] = useState()
    const [jd, setJD] = useState()
    const modalHide = () => {
        setShow(!show);
        console.log(!show + "bruh --------");
        setName("")
        setSpecial("")
        setJD("")
    };
   
    
    useEffect(() => {
        console.log("Brother")
        console.log("from table inside useEffect of modal input:", from_table)
        function handleEffect() {
            if (from_table?.index !== undefined || rowsData[from_table?.index]?.name !== "") {
                console.log("Inside if cond of useEffect",)
                setName(rowsData[from_table?.index]?.name)
                setSpecial(rowsData[from_table?.index]?.specialization_id)
                setJD(rowsData[from_table?.index]?.default_jd)
            }
            else if (from_table?.index === undefined || rowsData[from_table?.index]?.name === "") {
                console.log("Inside else if cond of useEffect")
                setName("")
            }

            console.log("bhoomikaaaaa", from_table?.index);
        }
        handleEffect();
    }, [from_table])






    //Handle Change for Add Button
    const handleChangeAddButton = (e, index) => {

        //Logs for entering values
        console.log("Name is: ", name);
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);


        //Conditions for storing values
        if (e.target.name === "specialization-input") {
            setName(e.target.value)
            console.log("add-specialization-input")
        }
        else if (e.target.name === "specialization-select") {
            setSpecial(e.target.value)
            console.log("add-specialization-select")

        } else if (e.target.name === "normal-input") {
            setName(e.target.value)
            console.log("add-normal-input")
        } else if (e.target.name === "jd-select") {
            setJD(parseInt(e.target.value))
            console.log("add-normal-input")
        }
    };


    //Handle Change for Edit Button
    const handleChange = (e, index) => {

        //Logs for entering values
        console.log("Name is: ", name);
        console.log("name from form  is:", e.target);
        console.log("value from form  is:", e.target.value);

        //Conditions for storing values
        if (e.target.name === "specialization-input") {
            setName(e.target.value)
            console.log("add-specialization-input")
        } else if (e.target.name === "specialization-select") {
            setSpecial(e.target.value)
            console.log("add-specialization-select")

        } else if (e.target.name === "jd-select") {
            setJD(parseInt(e.target.value))
            console.log("add-normal-input")
        } else if (e.target.name === "normal-input") {
            setName(e.target.value)
            console.log("add-normal-input")
        }

    };




    const get_data_from_form = (name) => {
        console.log("HandleEditUser ~ special", special)
        console.log("🚀HandleEditUser ~ name", name)
        console.log("🚀HandleEditUser ~ id", from_table?.id)
        // name.preventDefault();

        if (path === "/RoleType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim(), undefined, undefined, special.trim(), jd)
                setName("")
                setSpecial("")
                setJD("")
            }
            else {
                console.log("PUT")
                console.log("PUT - special value:", special)
                props.data_getter(name.trim(), from_table?.id, from_table?.index, special, jd)
            }
        }

        else if (path === "/UserType" || path === "/BlogsTags" || path === "/BlogsAuthor" || path === "/BlogsCategory" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim())
                setName("")
            }
            else {
                console.log("PUT")
                props.data_getter(name.trim(), from_table?.id, from_table?.index)
            }
        }

        setShow(false)
    }

    console.log("rows data in modal input file", rowsData);
    console.log("spec_data", props.spec_data);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log('inside handlepress')
            get_data_from_form(name)
        }
    }

    return (
        <Modal
            className="myprofile_modal"
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backdropFilter: "blur(5px)" }}
            onHide={() => modalHide()}
        >
            {console.log("Value of state name in Modal Input:", name)}
            {console.log("Value of state special in Modal Input:", special)}
            <Modal.Body
                style={{
                    position: "absolute",
                    // top: '50%',
                    left: "50%",
                    height: "auto",
                    transform: "translate(-50%, -50%)",
                    lineHeight: "1.4",
                    padding: "14px 28px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    maxWidth: "603px",
                    minWidth: "400px",
                }}
            >
                <form
                    // onSubmit={handleKeyPress}
                    style={{
                        display: "flex",
                        flexDirection: "col",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="row">
                        <div className="headerComp">
                            <CloseIcon className="closeIcon" onClick={() => modalHide()} />
                            <p className="headerText">Input Form</p>
                        </div>

                        {console.log("123456789", props.path)}
                        {(props.path === "/UserType" || path === "/BlogsTags" || path === "/BlogsAuthor" || path === "/BlogsCategory" || props.path === "/ProgramType" || props.path === "/BatchType" || props.path === "/SpecilizationType") ?
                            <>
                                {(from_table?.index !== undefined && from_table?.index < rowsData.length) ?
                                    Object.keys(rowsData[from_table?.index]).filter(filtering => filtering === "name").map((row, index) => (
                                        <div className="form-group">
                                            <label className="labelContent">
                                                {headCells[0].label}
                                                <span>*</span>
                                            </label>
                                            <input
                                                name="normal-input"
                                                autoFocus
                                                type="text"
                                                className="form-control inputFields"
                                                placeholder="Enter here"
                                                value={name}
                                                onKeyDown={(e) => handleKeyPress(e)}
                                                onChange={(e) => handleChange(e, index)}
                                                style={{ paddingLeft: "30px" }}
                                            />
                                        </div>
                                    ))
                                    :
                                    headCells.map((head, index) => (
                                        <div className="form-group">
                                            <label className="labelContent">
                                                {head.label}
                                                <span>*</span>
                                            </label>
                                            <input
                                                autoFocus
                                                onKeyDown={(e) => handleKeyPress(e)}
                                                type="text"
                                                name="normal-input"
                                                className="form-control inputFields"
                                                placeholder="Enter here"
                                                value={name}
                                                onChange={(e) => handleChangeAddButton(e)}
                                                style={{ paddingLeft: "30px" }}
                                            />


                                        </div>
                                    ))}
                            </>
                            : <></>
                        }
                        {props.path === "/RoleType" ?
                            <>
                                {console.log("Role-Edit-from-table:", from_table)}
                                {(from_table?.index !== undefined && from_table?.index < rowsData.length) ?
                                    <>
                                        {console.log("Mister", Object.keys(rowsData[from_table?.index]).filter(filtering => filtering === "name" || filtering === "specialization_id" || filtering === "default_jd"))}
                                        {Object.keys(rowsData[from_table?.index]).filter(filtering => filtering === "name" || filtering === "specialization_id" || filtering === "default_jd").map((row, i) => (
                                            <div className="form-group">
                                                <label className="labelContent">
                                                    {headCells[i].label}
                                                    <span>*</span>
                                                </label>
                                                {console.log("value of abhra", i)}
                                                {i === 1 ?
                                                    <select name="specialization-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e, i)} value={special}>
                                                        <option value={""} selected>Select Specialization</option>
                                                        {props.spec_data?.map((spec, i) => (
                                                            <option key={i} value={spec.id}>{spec.name}</option>
                                                        ))}
                                                    </select>
                                                    : i === 2 ?
                                                        <select name="jd-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e, i)} value={jd}>
                                                            <option value={null} selected>Select JD</option>
                                                            {props.jd_data?.map((jd, i) => (
                                                                <option key={i} value={jd.id}>{jd.name}</option>
                                                            ))}
                                                        </select> :
                                                        <input
                                                            autoFocus
                                                            name="specialization-input"
                                                            type="text"
                                                            className="form-control inputFields"
                                                            placeholder="Enter here"
                                                            value={name}
                                                            onChange={(e) => handleChange(e, i)}
                                                            style={{ paddingLeft: "30px" }}

                                                        />

                                                }

                                            </div>
                                        ))}
                                    </>
                                    :

                                    headCells.map((head, index) => (
                                        <div className="form-group">
                                            <label className="labelContent">
                                                {(head).label}
                                                <span>*</span>
                                            </label>
                                            {index === 1 ?
                                                <select name="specialization-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChangeAddButton(e)} value={special}>
                                                    <option value="" selected>Select Specialization</option>
                                                    {props.spec_data?.map((spec, index) => (
                                                        <option key={index} value={spec.id}>{spec.name}</option>
                                                    ))}
                                                </select>
                                                : index === 2 ?
                                                    <select name="jd-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChangeAddButton(e)} value={jd}>
                                                        <option value={""} selected>Select JD</option>
                                                        {props.jd_data?.map((jd, i) => (
                                                            <option key={i} value={jd.id}>{jd.name}</option>
                                                        ))}
                                                    </select> :
                                                    <input
                                                        type="text"
                                                        name="specialization-input"
                                                        className="form-control inputFields"
                                                        placeholder="Enter here"
                                                        value={name}
                                                        onChange={(e) => handleChangeAddButton(e)}
                                                        style={{ paddingLeft: "30px" }}
                                                    />
                                            }

                                        </div>
                                    ))}
                            </>
                            : <></>

                        }





                        <div className="form-group btnContain">
                            {console.log("qwerty", { props })}
                            <button
                                className="submitBtn"
                                type="button"
                                onClick={(e) => {
                                    get_data_from_form(name);
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
