import React, { useEffect, useState } from 'react'
import Service from '../../../../services/httpService'
import { Box } from '@mui/material'
import { Color } from '../../../../GlobalStyles'
import { useNavigate } from 'react-router-dom';

const AnomaliesDashboard = () => {
    const services = new Service()
    const [anomaliesDetails, setAnomaliesDetails] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        const handleEffect = async () => {
            const anomaliesDashboard = await anomaliesDashboardGetter()
            setAnomaliesDetails(anomaliesDashboard)
        }
        handleEffect();
    }, [])

    const anomaliesDashboardGetter = async () => {
        try {
            const res = await services.get(`/anomalies/ongoingActivities`)
            return res.data
        } catch (err) {
            window.alert(err.response.data.message)
        }
    }
    console.log("Object.keys(anomaliesDetails)", Object.entries(anomaliesDetails));

    const handleAnomaliesItemClick = async (value) => {
        try {
            if (value !== null) {
                const res = await services.get(`/getOrderDetails?order_ids=[${value}]`)
                if (res.data.length) {
                    navigate("/College1x1StudentDetails", { state: { searched: "All", isAnomalies: true, studentAnomaliesDetails: res.data } })
                }
            } else {
                window.alert("No data to show")
            }

        } catch (err) {
            window.alert(err.response.data.message)
        }
    }

    const handleClick = () => {
        navigate("/AnomaliesPastBookings")
    }

    return (
        <Box style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1rem", marginBottom: "2rem" }}>
            {Object.entries(anomaliesDetails).map(([key, value], index) => {
                let parsedValue;
                try {
                    parsedValue = JSON.parse(value);
                } catch (error) {
                    parsedValue = value;
                }

                const isArray = Array.isArray(parsedValue);
                const displayValue = parsedValue === null ? 0 : isArray ? parsedValue.length : parsedValue;

                console.log("things", isArray, displayValue);

                return (
                    <Box
                        key={index} // Always use a unique key in map
                        sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", paddingLeft: ".5rem", marginRight: ".5rem" }}
                        onClick={() => handleAnomaliesItemClick(parsedValue)}
                    >
                        <button
                            className="submitBtn"
                            type="button"
                            style={{
                                width: "auto",
                                paddingLeft: ".5rem",
                                paddingRight: ".5rem",
                                background: "transparent",
                                border: `1px solid ${Color.primary1}`,
                                color: Color.blueBlack,
                                textTransform: "capitalize"
                            }}
                        >
                            {key.replaceAll("_", " ")} : {displayValue}
                        </button>
                    </Box>
                );
            })}


            <Box className="topbar-user-profile-image-no-logo">
                <button onClick={() => { handleClick() }} className='batch-button'>Past Activities</button>
            </Box>
        </Box>
    )
}

export default AnomaliesDashboard
