import { Box, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, Checkbox, Modal } from "@mui/material";
import { Add, Download, UploadFile, Done, Menu, Update } from "@mui/icons-material";
import React, { useState } from "react";
import AddModal from "./Modals/AddModal";
import DetailModal from "./Modals/DetailModal";
import AddEpisodeModal from "./Modals/AddEpisodeModal";
import { useEffect } from "react";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
import { useLocation } from "react-router-dom";
import { get_specialCharReplace } from "../../services/specialCharReplace";
import EpisodeDetails from "./Modals/EpisodeDetails";
import AddEpisodeFromStation from "./Modals/AddEpisodeFromStation";

function Podcast() {
  const services = new CCService();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("station");
  const [showModal, setShowModal] = useState(false);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [selectedDetailModal, setSelectedDetailModal] = useState({
    station_id: null,
    name: "",
    description: "",
    search_keywords: [],
    image: "",
    specialization: [],
    recommendation_keywords: [],
    episode_link: [],
    station_or_classification: "",
    roles: [],
    activities: [],
  });
  const [showEpisodeModal, setShowEpisodeModal] = useState(false);

  const [allEpisode, setAllEpisodes] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [addEpisodeModal, setAddEpisodeModal] = useState(false);
  const [stationData, setStationData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [classificationData, setClassificationData] = useState([
    {
      station_id: null,
      name: "",
      description: "",
      specialization: { specializations: [] },
      roles: [],
      activities: [],
    },
  ]);
  const [displayData, setDisplayData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [episodeModal, setEpisodeModal] = useState(false);
  const [addEpisodeForStation, setAddEpisodeFormData] = useState(false);
  const [content_type, setContent_type] = useState("Station");
  const handleTabChange = (e, value) => {
    if (value === "classification") {
      setRenderData(classificationData);
    } else if (value === "display") {
      setRenderData(displayData);
    } else if (value === "station") {
      setRenderData(stationData);
    }

    setSelectedTab(value);
  };

  const handlecheckbox = (e) => {
    if (e.target.checked) {
      setRenderData(allEpisode);
      setSelectedTab("episode");
    } else {
      setRenderData(stationData);
      setSelectedTab("stations");
    }
  };

  const handleDragEnd = async (e) => {
    let temp_display = displayData;
    const source = e.source.index;
    const destination = e.destination.index;

    const element = temp_display[source]; //Grab the element which is moved
    temp_display.splice(source, 1); ///Remove the element
    temp_display.splice(destination, 0, element); //Add the element at correct postion

    temp_display = temp_display.map((item, index) => {
      return {
        ...item,
        order_by: index + 1,
      };
    });

    const body = {
      order_by_array: temp_display.map((item, index) => {
        return {
          collection_id: item.collection_id,
          order_by: item.order_by,
        };
      }),
    };

    const putDisplayData = await services.put("/podcast/collectionView/editOrder", body);
    if (putDisplayData.status === 200) {
      setRenderData(temp_display);
      setDisplayData(temp_display);
    }
  };

  const Render = () => {
    if (selectedTab === "station") {
      {
        console.log("StationDATA", stationData);
      }
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "25%",
                paddingInline: "2.5rem",
              }}
            >
              Station Name
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "40%",
                paddingInlineEnd: "2.5rem",
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "35%",
                paddingInlineEnd: "2.5rem",
              }}
            >
              Linked to
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            {renderData.length > 0 &&
              renderData.map((station, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    // justifyContent: "space-between",
                    paddingBottom: "1.5rem",
                    borderBottom: "1px solid #E6E6E6",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDetailModalVisibility(station.station_id)}
                >
                  <Box>
                    <img src={station.image} alt={station.name} style={{ width: "75px", height: "75px" }} />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "25%",
                      paddingInline: "2.5rem",
                    }}
                  >
                    {station.name}
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontSize: "16px",
                      width: "40%",
                      paddingInlineEnd: "2.5rem",
                    }}
                  >
                    {station.description.slice(0, 100)}...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      width: "35%",
                      paddingInlineEnd: "2.5rem",
                    }}
                  >
                    <Box display={"flex"} gap={"10px"}>
                     
                      {console.log("StationInsideRender", station)}

                      <Typography sx={{ fontSize: "16px" }}>
                        {station.activityName}
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"10px"}>
                  
                      <Typography sx={{ fontSize: "16px" }}>
                        {station.roleName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      {station.specialization.map((item) => (
                        <Typography
                          sx={{ fontSize: "16px", textAlign: "center" }}
                        >
                          {item.specialization}
                        </Typography>
                      ))}
                    </Box>
                  </Box> */}
                </Box>
              ))}
          </Box>
        </Box>
      );
    } else if (selectedTab === "classification") {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "25%",
                paddingInline: "2.5rem",
              }}
            >
              Station Name
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "40%",
                paddingInlineEnd: "2.5rem",
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "35%",
                paddingInlineEnd: "2.5rem",
              }}
            >
              Linked to
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            {classificationData.length > 0 &&
              renderData.map((station, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1.5rem",
                    borderBottom: "1px solid #E6E6E6",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDetailModalVisibility(station.station_id)}
                >
                  <Box>
                    <img src={station.image} alt={station.name} style={{ width: "75px", height: "75px" }} />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "25%",
                      paddingInline: "2.5rem",
                    }}
                  >
                    {station.name}
                  </Typography>

                  {/* <Typography
                    sx={{
                      fontSize: "16px",
                      width: "40%",
                      paddingInlineEnd: "2.5rem",
                    }}
                  >
                    {station.description}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      width: "35%",
                      paddingInlineEnd: "2.5rem",
                    }}
                  >
                    <Box display={"flex"} gap={"10px"}>
                      {console.log("StationInsideRender", station)}

                      <Typography sx={{ fontSize: "16px" }}>
                        {station.activityName}
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"10px"}>
                      <Typography sx={{ fontSize: "16px" }}>
                        {station.roleName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      {station.specialization.map((item) => (
                        <Typography
                          sx={{ fontSize: "16px", textAlign: "center" }}
                        >
                          {item.specialization}
                        </Typography>
                      ))}
                    </Box>
                  </Box> */}
                </Box>
              ))}
          </Box>
        </Box>
      );
    } else if (selectedTab === "display") {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {displayData.length > 0 && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable__display">
                {(provider, snapshot) => (
                  <Box {...provider.droppableProps} ref={provider.innerRef}>
                    {renderData.map((collection, index) => (
                      <Draggable draggableId={JSON.stringify(index)} index={index}>
                        {(provider, snapshot) => (
                          <Box
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                            sx={{
                              display: "flex",
                              gap: "1rem",
                              alignItems: "center",
                              paddingBottom: "1rem",
                              borderBottom: "1px solid #E6E6E6",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDetailModalVisibility(collection.collection_id)}
                          >
                            <IconButton
                              {...provider.dragHandleProps}
                              sx={{
                                color: "#1C8EA8",
                                "&:hover": { backgroundColor: "none" },
                              }}
                            >
                              <Menu />
                            </IconButton>
                            <Typography>{collection.collection_name}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Box>
      );
    } else if (selectedTab === "episode") {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "30%",
                paddingInline: "2.5rem",
              }}
            >
              Episode Name
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "40%",
                paddingInlineEnd: "2.5rem",
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                width: "30%",
                paddingInlineEnd: "2.5rem",
              }}
            >
              Linked to
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            {allEpisode.length > 0 &&
              renderData.map((episode, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "1.5rem",
                    borderBottom: "1px solid #E6E6E6",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDetailModalVisibility(episode.episode_id)}
                >
                  <Box>
                    <img src={episode.image} alt={episode.name} style={{ width: "75px", height: "75px" }} />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "30%",
                      paddingInline: "2.5rem",
                    }}
                  >
                    {episode.name}
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontSize: "16px",
                      width: "40%",
                      paddingInlineEnd: "2.5rem",
                    }}
                  >
                    {episode.about}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      width: "30%",
                      paddingInlineEnd: "2.5rem",
                    }}
                  >
                    <Box display={"flex"} gap={"10px"}>
                      {activityList.length > 0 &&
                        episode.activities.map((item) => {
                          let temp = activityList?.find(
                            (ele) => ele.id === item
                          );
                          console.log("activityname", temp, item, activityList);
                          return (
                            <Typography sx={{ fontSize: "16px" }}>
                              {temp.act_type_name}
                            </Typography>
                          );
                        })}
                    </Box>
                    <Box display={"flex"} gap={"10px"}>
                      {episode.roles.map((item) => {
                        let temp = roleList.find((ele) => ele.id === item);
                        console.log("roleList", item, temp);
                        return (
                          <Typography sx={{ fontSize: "16px" }}>
                            {temp ? temp.name : ""}
                          </Typography>
                        );
                      })}
                    </Box>
                    <Box display={"flex"}>
                      {episode.specialization.map((item) => (
                        <Typography
                          sx={{ fontSize: "16px", textAlign: "center" }}
                        >
                          {item.specialization}
                        </Typography>
                      ))}
                    </Box>
                  </Box> */}
                </Box>
              ))}
          </Box>
        </Box>
      );
    }
  };

  const handleDetailModalVisibility = async (index) => {
    if (selectedTab === "station" || selectedTab === "classification") {
      const stationData = await services.get(`/podcast/stationById?station_id=${index}`);
      setSelectedDetailModal(cleanDetailModalData(stationData.data));
      setShowDetailModal(true);
    } else if (selectedTab === "episode") {
      const episodeData = await services.get(`/podcast/episodeById?episode_id=${index}`);
      setSelectedDetailModal(cleanDetailModalData(episodeData.episode));
      setShowDetailModal(true);
    } else if (selectedTab === "display") {
      const selectedDisplay = await services.get(`/podcast/collectionViewById?collection_id=${index}`);
      setSelectedDetailModal(selectedDisplay.data);
      setShowDetailModal(true);
      console.log("DisplayData", selectedDisplay);
    }
  };

  const handleEditEpisodeIcon = async (id) => {
    setShowDetailModal(false);

    const episodeData = await services.get(`/podcast/episodeById?episode_id=${id}`);

    if (episodeData.success) {
      const item = {
        roles: [null, "null", "undefined", undefined].includes(episodeData.episode.role_ids) ? [] : episodeData.episode.role_ids.split(",").map((ele) => parseInt(ele)),
        activities: [null, "null", "undefined", undefined].includes(episodeData.episode.activity_ids) ? [] : episodeData.episode.activity_ids.split(",").map((ele) => parseInt(ele)),
        specialization: [null, "null", "undefined", undefined].includes(episodeData.episode.specialization) ? [] : JSON.parse(episodeData.episode.specialization).specializations,
        about: episodeData.episode.about,
        image: [null, "null", "undefined", undefined].includes(episodeData.episode.image) ? "" : episodeData.episode.image,
        recording: [null, "null", "undefined", undefined].includes(episodeData.episode.image) ? "" : episodeData.episode.recording,
        name: episodeData.episode.name,
        recommendation_keywords: [null, "null", "undefined", undefined].includes(episodeData.episode.recommendation_keywords) ? [] : JSON.parse(episodeData.episode.recommendation_keywords).recommendation_keywords,
        keywords: [null, "null", "undefined", undefined].includes(episodeData.episode.keywords) ? [] : JSON.parse(episodeData.episode.keywords).keywords,
        episode_id: episodeData.episode.episode_id,
        mentor_details: episodeData.episode.mentor_details,
      };
      // setSelectedTab("episode");
      // setRenderData(allEpisode);
      setSelectedDetailModal(item);
      // setShowDetailModal(true);
      setShowEpisodeModal(true);
    }

    // setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setSelectedDetailModal({
      station_id: null,
      name: "",
      description: "",
      search_keywords: [],
      image: "",
      specialization: [],
      recommendation_keywords: [],
      episode_link: [],
      station_or_classification: "",
      roles: [],
      activities: [],
    });
    setShowDetailModal(false);
  };

  const cleanData = (data) => {
    console.log("cleanDeta", data);
    const cleanItem = data.map((item) => {
      return {
        station_id: item.station_id,
        name: item.name,
        //FIXME:
        description: get_specialCharReplace(item.description),
        image: [null, "null", "undefined", undefined].includes(item.image) ? null : item.image,
        station_or_classification: item.station_or_classification,
        roles: [null, "null", "undefined", undefined].includes(item.role_ids) ? [] : item.role_ids.split(",").map((ele) => parseInt(ele)),
        activities: [null, "null", "undefined", undefined].includes(item.activity_ids) ? [] : item.activity_ids.split(",").map((ele) => parseInt(ele)),
        specialization: [null, "null", "undefined", undefined].includes(item.specialization) ? [] : JSON.parse(item.specialization).specializations,
        activityName: [null, "null", "undefined", undefined].includes(item.activity_names) ? "" : item.activity_names,
        roleName: [null, "null", "undefined", undefined].includes(item.role_names) ? "" : item.role_names,
        content_type: item.content_type,
      };
    });

    console.log("cleanedData", cleanItem);

    return cleanItem;
  };
  const cleanDetailModalData = (data) => {
    if (selectedTab === "station" || selectedTab === "classification") {
      const item = {
        roles: [null, "null", "undefined", undefined].includes(data.role_ids) ? [] : data.role_ids.split(",").map((ele) => parseInt(ele)),
        activities: [null, "null", "undefined", undefined].includes(data.activity_ids) ? [] : data.activity_ids.split(",").map((ele) => parseInt(ele)),
        specialization: [null, "null", "undefined", undefined].includes(data.specialization) ? [] : JSON.parse(data.specialization).specializations,
        description: get_specialCharReplace(data.description),
        episode_link: [null, "null", "undefined", undefined].includes(data.episode_link) ? [] : data.episode_link,
        image: [null, "null", "undefined", undefined].includes(data.image) ? "" : data.image,
        is_delete: [null, "null", "undefined", undefined].includes(data.is_delete) ? "" : data.is_delete,
        name: data.name,
        recommendation_keywords: [null, "null", "undefined", undefined].includes(data.recommendation_keywords) ? [] : JSON.parse(data.recommendation_keywords).recommendation_keywords,
        search_keywords: [null, "null", "undefined", undefined].includes(data.search_keywords) ? [] : JSON.parse(data.search_keywords).search_keywords,
        station_id: data.station_id,
        station_or_classification: data.station_or_classification,
      };

      return item;
    } else {
      //For episode

      const item = {
        roles: [null, "null", "undefined", undefined].includes(data.role_ids) ? [] : data.role_ids.split(",").map((ele) => parseInt(ele)),
        activities: [null, "null", "undefined", undefined].includes(data.activity_ids) ? [] : data.activity_ids.split(",").map((ele) => parseInt(ele)),
        specialization: [null, "null", "undefined", undefined].includes(data.specialization) ? [] : JSON.parse(data.specialization).specializations,
        about: data.about,
        image: [null, "null", "undefined", undefined].includes(data.image) ? "" : data.image,
        recording: [null, "null", "undefined", undefined].includes(data.recording) ? "" : data.recording,
        name: data.name,
        recommendation_keywords: [null, "null", "undefined", undefined].includes(data.recommendation_keywords) ? [] : JSON.parse(data.recommendation_keywords).recommendation_keywords,
        keywords: [null, "null", "undefined", undefined].includes(data.keywords) ? [] : JSON.parse(data.keywords).keywords,
        episode_id: data.episode_id,
        mentor_details: data.mentor_details,
      };
      return item;
    }
  };

  const cleanEpisodeData = (data) => {
    const cleanItem = data.map((item) => {
      return {
        episode_id: item.episode_id,
        name: item.name,
        about: item.about,
        image: item.image,
        roles: [null, "null", "undefined", undefined].includes(item.role_ids) ? [] : item.role_ids.split(",").map((ele) => parseInt(ele)),
        activities: [null, "null", "undefined", undefined].includes(item.activity_ids) ? [] : item.activity_ids.split(",").map((ele) => parseInt(ele)),
        specialization: [null, "null", "undefined", undefined].includes(item.specialization) ? [] : JSON.parse(item.specialization).specializations,
        recommendation_keywords: [null, "null", "undefined", undefined].includes(item.recommendation_keywords) ? [] : JSON.parse(item.recommendation_keywords).recommendation_keywords,
        keywords: [null, "null", "undefined", undefined].includes(item.keywords) ? [] : JSON.parse(item.keywords).keywords,
        content_type: item.content_type,
      };
    });

    return cleanItem;
  };

  //Updated Display data when edited or new display is added to array
  const updateDisplayData = (data, type) => {
    if (type === "update") {
      let tempArray = displayData;
      //DISPLAY DATA
      tempArray = tempArray.map((item) => {
        if (item.collection_id === data.collection_id) {
          return {
            ...item,
            collection_name: data.collection_name,
          };
        } else {
          return item;
        }
      });
      setDisplayData(tempArray);
      setRenderData(tempArray);

      //DETAIL MODAL CHANGE
      setSelectedDetailModal((prev) => {
        return {
          ...prev,
          collection_name: data.collection_name,
        };
      });
    } else if (type === "create") {
      let temp_data = displayData;
      temp_data.push(data);
      setDisplayData(temp_data);
      setRenderData(temp_data);
    } else if (type === "addEpisodes") {
      setSelectedDetailModal((prev) => {
        return {
          ...prev,
          list_to_show: data,
        };
      });
    }
  };

  //Create And update station
  const updateStationData = (data, type) => {
    if (type === "update") {
      if (selectedTab === "station") {
        // station Update
        const tempData = stationData.map((item) => {
          if (item.station_id === data.station_id) {
            return {
              station_id: data.station_id,
              name: data.name,
              description: data.description,
              station_or_classification: item.station_or_classification,
              roles: data.roles,
              activities: data.activities,
              specialization: data.specialization,
            };
          } else {
            return item;
          }
        });
        console.log("station data after update", tempData);
        setStationData(tempData);
        setRenderData(tempData);

        // Detail Modal Update
        const detailModal = {
          station_id: data.station_id,
          activities: data.activities,
          description: data.description,
          episode_link: selectedDetailModal.episode_link,
          image: data.image === null ? selectedDetailModal.image : data.image,
          is_delete: selectedDetailModal.is_delete,
          name: data.name,
          recommendation_keywords: data.recommendation_keywords,
          roles: data.roles,
          search_keywords: data.search_keywords,
          specialization: data.specialization,
          station_or_classification: data.station_or_classification,
        };

        setSelectedDetailModal(detailModal);
        console.log("modal completed --> station");
      } else if (selectedTab === "classification") {
        const tempData = classificationData.map((item) => {
          if (item.station_id === data.station_id) {
            return {
              station_id: data.station_id,
              name: data.name,
              description: data.description,
              station_or_classification: item.station_or_classification,
              roles: data.roles,
              activities: data.activities,
              specialization: data.specialization,
            };
          } else {
            return item;
          }
        });
        setClassificationData(tempData);
        // setRenderData(tempData);
        setShowModal(false);
      }
    } else if (type === "create") {
      console.log("newStationCreated", data);
      if (selectedTab === "station") {
        let temp_data = stationData;
        temp_data.push(data);
        console.log("TEmpData after append", temp_data);
        setStationData(temp_data);
        setRenderData(temp_data);
      } else if (selectedTab === "classification") {
        let temp_data = classificationData;
        temp_data.push(data);
        console.log("TEmpData after append", temp_data);

        setClassificationData(temp_data);
        setRenderData(temp_data);
      }
    }

    setShowModal(false);
  };

  //UPDATING DATA FOR DETAIL MODALS
  const updateSelectedDetailModalData = (data) => {
    console.log("DATA IMAGE", data);
    const tempData = {
      station_id: data.station_id,
      name: data.name,
      description: data.description,
      search_keywords: data.search_keywords,
      image: ["", null, "null"].includes(data.image) ? selectedDetailModal.image : URL.createObjectURL(data.image),
      specialization: data.specialization,
      recommendation_keywords: data.recommendation_keywords,
      episode_link: selectedDetailModal.episode_link,
      station_or_classification: selectedDetailModal.station_or_classification,
      roles: data.roles,
      activities: data.activities,
    };
    setSelectedDetailModal(tempData);
    setShowModal(false);
  };

  const updateEpisodeDetailModal = (data) => {
    console.log("EpisodeDataModal", data, allEpisode);
    const item = {
      roles: data.roles,
      activities: data.activities,
      specialization: data.specialization,
      about: data.about_episode,
      image: data.image === "" ? selectedDetailModal.image : data.image,
      recording: data.recording === "" ? selectedDetailModal.recording : data.recording,
      name: data.name,
      recommendation_keywords: data.recommendation_keywords,
      keywords: data.keywords,
      episode_id: data.episode_id,
      mentor_details: [
        {
          mentor_id: data.mentor_id,
          about_mentor: data.about_mentor,
          mentorName: data.mentorName,
        },
      ],
    };
    setSelectedDetailModal(item);
    let temp_all_episodes = allEpisode.map((ep, index) => {
      if (ep.episode_id === data.episode_id) {
        return {
          about: data.about_episode,
          activities: data.activities,
          content_type: location.pathname === "/Podcast" ? "Podcast" : "Session",
          episode_id: data.episode_id,
          image: data.image === "" ? ep.image : data.image,
          keywords: data.keywords,
          name: data.name,
          recommendation_keywords: data.recommendation_keywords,
          roles: data.roles,
          specialization: data.specialization,
        };
      } else {
        return ep;
      }
    });

    setAllEpisodes(temp_all_episodes);
    // setRenderData(temp_all_episodes);
  };
  const UpdateAddEpisode = (data, episode_data) => {
    if (selectedTab === "station") {
      let temp_data = selectedDetailModal;
      temp_data.episode_link.push(data.episode_data);
      setSelectedDetailModal(temp_data);

      //UPDATES EPISODE LIST
      let newEpisodeData = cleanEpisodeData([episode_data]);
      let episodeData = allEpisode;
      episodeData.push(newEpisodeData[0]);
      setAllEpisodes(episodeData);
    } else if (selectedTab === "classification") {
      let temp_data = selectedDetailModal;
      data.map((item) => temp_data.episode_link.push(item));

      setSelectedDetailModal(temp_data);
    }
  };

  const handleDragAndDropChange = (data) => {
    setSelectedDetailModal((prev) => {
      return {
        ...prev,
        episode_link: data,
      };
    });
  };

  const handleSearchField = (e) => {
    if (selectedTab === "episode") {
      setSearchField(e.target.value);
      let tempData = allEpisode.filter((item) => item.name.startsWith(e.target.value));
      setRenderData(tempData);
    } else if (selectedTab === "classification") {
      setSearchField(e.target.value);
      let tempData = classificationData.filter((item) => item.name.startsWith(e.target.value));
      setRenderData(tempData);
    } else if (selectedTab === "display") {
      setSearchField(e.target.value);
      let tempData = displayData.filter((item) => item.collection_name.startsWith(e.target.value));
      console.log("TempData", tempData);
      setRenderData(tempData);
    } else if (selectedTab === "station") {
      setSearchField(e.target.value);
      let tempData = stationData.filter((item) => item.name.startsWith(e.target.value));
      setRenderData(tempData);
    }
  };

  const sortByOrder = (key) => {
    return function (a, b) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    };
  };

  useEffect(() => {
    const getData = async () => {
      setSelectedTab("station");
      setRenderData([]);
      const stationClassificationData = await services.get("/podcast/stations");

      console.log("stationClassificationData", stationClassificationData);
      const episodeData = await services.get("/podcast/episodes");
      const displayData = await services.get("/podcast/collectionView");
      const cleanedData = cleanData(stationClassificationData.allStation);
      const content_type = location.pathname === "/Podcast" ? "Podcast" : "Session";

      console.log("itemReceives", stationClassificationData);
      let station_data = cleanedData.filter((item) => item.station_or_classification === "station" && content_type === item.content_type);

      let classification_data = cleanedData.filter((item) => item.station_or_classification === "classification" && content_type === item.content_type);
      let clean_episode_data = cleanEpisodeData(episodeData.allEpisodes);
      let episode_data = clean_episode_data.filter((item) => item.content_type === content_type);
      let display_data = displayData.data.filter((item) => item.content_type === content_type);

      display_data.sort(sortByOrder("order_by"));

      setStationData(station_data);
      setRenderData(station_data);
      setClassificationData(classification_data);
      setAllEpisodes(episode_data);
      setDisplayData(display_data);
      console.log("datarenderring:", station_data);
    };

    const getallMentors = async () => {
      const res = await services.get("/mentorModule/mentor/user");
      setMentorDetails(res.data);
    };

    const getallSpecialization = async () => {
      const res = await services.get("/superAdmin/specialization?is_admin=1");
      setSpecializationList(res.data);
    };

    const getallRoles = async () => {
      const res = await services.get("/superAdmin/role");
      setRoleList(res.data);
    };

    const getallActivity = async () => {
      const res = await services.get("superAdmin/activityType");
      console.log("ActivityList", res.data);
      setActivityList(res.data);
    };

    getallMentors();
    getallActivity();
    getallRoles();
    getallSpecialization();
    getData();
  }, [location.pathname]);
  return (
    <Box>
      <SideNavigationBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2.5rem",
          marginX: "10rem",
          marginTop: "6rem",
        }}
      >
        <Typography sx={{ fontSize: "32px" }}>{location.pathname === "/Podcast" ? "Podcasts" : "Sessions"}</Typography>
        <TextField placeholder="Search the mentor name, episode etc" value={searchField} onChange={(e) => handleSearchField(e)} />
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Checkbox checked={selectedTab === "episode"} onChange={(e) => handlecheckbox(e)} />
          <Typography>Show only episodes</Typography>
        </Box>
        <ToggleButtonGroup color="primary" value={selectedTab} exclusive onChange={(e, value) => handleTabChange(e, value)}>
          <ToggleButton
            value="station"
            sx={{
              width: "100%",
              borderRadius: "20px 0px 0px 20px",
              textTransform: "none",
            }}
          >
            {" "}
            <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
              {selectedTab === "station" && <Done />} <Typography>Stations</Typography>
            </Box>
          </ToggleButton>

          <ToggleButton value="classification" sx={{ width: "100%", textTransform: "none" }}>
            <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
              {selectedTab === "classification" && <Done />} <Typography>Classifications</Typography>
            </Box>
          </ToggleButton>
          <ToggleButton
            value="display"
            sx={{
              width: "100%",
              borderRadius: "0px 20px 20px 0px",
              textTransform: "none",
            }}
          >
            {" "}
            <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
              {selectedTab === "display" && <Done />} <Typography>Display</Typography>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
        {selectedTab !== "episode" && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setShowModal(true)}>
              <Add />
            </IconButton>
            <IconButton>
              <Download />
            </IconButton>
            <IconButton>
              <UploadFile />
            </IconButton>
          </Box>
        )}
        <Render />
      </Box>
      {
        <Modal open={showModal} onClose={() => setShowModal(false)} sx={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
          <AddModal
            type={selectedTab}
            close={() => {
              setShowModal(false);
            }}
            mentorDetails={mentorDetails}
            activityList={activityList}
            specializationList={specializationList}
            roleList={roleList}
            selectedDetailModal={selectedDetailModal}
            updateStationData={updateStationData}
            updateSelectedDetailModalData={updateSelectedDetailModalData}
            updateDisplayData={updateDisplayData}
            displayData={displayData}
          />
        </Modal>
      }
      {
        <Modal open={showDetailModal} onClose={(event, reason) => reason !== "backdropClick" && setShowDetailModal(false)} sx={{ display: "flex", justifyContent: "flex-end" }} onBackdropClick={() => console.log("backDrop")}>
          <DetailModal type={selectedTab} close={() => handleCloseDetailModal(false)} onEdit={() => setShowModal(true)} onAddEpisode={() => setAddEpisodeModal(true)} selectedDetailModal={selectedDetailModal} activityList={activityList} specializationList={specializationList} roleList={roleList} handleDragAndDropChange={handleDragAndDropChange} handleEditEpisodeIcon={handleEditEpisodeIcon} />
        </Modal>
      }

      {
        <Modal open={addEpisodeModal} onClose={() => setAddEpisodeModal(false)} sx={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
          <AddEpisodeModal type={selectedTab} close={() => setAddEpisodeModal(false)} stationId={selectedDetailModal.station_id} mentorDetails={mentorDetails} activityList={activityList} specializationList={specializationList} selectedDetailModal={selectedDetailModal} roleList={roleList} UpdateAddEpisode={UpdateAddEpisode} stationData={stationData} updateEpisodeDetailModal={updateEpisodeDetailModal} updateDisplayData={updateDisplayData} />
        </Modal>
      }

      <Modal open={showEpisodeModal} onClose={(event, reason) => reason !== "backdropClick" && setShowEpisodeModal(false)} sx={{ display: "flex", justifyContent: "flex-end" }} onBackdropClick={() => console.log("backDrop")}>
        <EpisodeDetails type={"episode"} close={() => setShowEpisodeModal(false)} onEdit={() => setShowModal(true)} onAddEpisode={() => setAddEpisodeFormData(true)} selectedDetailModal={selectedDetailModal} activityList={activityList} specializationList={specializationList} roleList={roleList} handleDragAndDropChange={handleDragAndDropChange} handleEditEpisodeIcon={handleEditEpisodeIcon} />
      </Modal>

      <Modal open={episodeModal} onClose={() => setEpisodeModal(false)} sx={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
        <AddEpisodeModal type={"episode"} close={() => setEpisodeModal(false)} stationId={selectedDetailModal.station_id} mentorDetails={mentorDetails} activityList={activityList} specializationList={specializationList} selectedDetailModal={selectedDetailModal} roleList={roleList} UpdateAddEpisode={UpdateAddEpisode} stationData={stationData} updateEpisodeDetailModal={updateEpisodeDetailModal} updateDisplayData={updateDisplayData} />
      </Modal>

      {
        <Modal open={addEpisodeForStation} onClose={() => setAddEpisodeFormData(false)} sx={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
          <AddEpisodeFromStation type={"episode"} close={() => setAddEpisodeFormData(false)} stationId={selectedDetailModal.station_id} mentorDetails={mentorDetails} activityList={activityList} specializationList={specializationList} selectedDetailModal={selectedDetailModal} roleList={roleList} UpdateAddEpisode={UpdateAddEpisode} stationData={stationData} updateEpisodeDetailModal={updateEpisodeDetailModal} updateDisplayData={updateDisplayData} />
        </Modal>
      }
    </Box>
  );
}

export default Podcast;
