import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import EnhancedTable from "../reusables/TableComponent/TableComponent";
import Service from "../../services/httpService";
import TopBar from '../reusables/TopBar/Topbar'
import SideNavigationBar from '../reusables/SideNavigationBar/SideNavigationBar';
import { saveAs } from "file-saver";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { UserTypeContext as RowsContext} from "../pages/RowsContext/RowsContext";
import Papa from 'papaparse';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CCService from "../../services/httpServiceWithoutSuperAdmin";

const BlogsCategory = () => {
  const navigate = useNavigate();

  const [rowsData, setRowsData] = useState([{ id: null, name: "" }])
  const [csvData, setCsvData] = useState(null)
  // const [count, setCount] = useState()
  const service = new CCService()

  //get current date and time
  const currentDate = new Date();

  let count = 0;
  const headCells = [
    // {
    //   id: 'heading1',
    //   numeric: 'left',
    //   disablePadding: true,
    //   label: 'Sl. No.',
    // },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Category',
    },
  ];

  // handle csv upload
  const handleCsvUpload = async (event) => {
    console.log("csv upload");
    let parsedData = []
    if (event && event.target) {
      let file = event.target.files[0]
      let reader = new FileReader();
      reader.readAsText(file)
      reader.onload = () => {
        parsedData = Papa.parse(reader.result, { delimiter: ',', dynamicTyping: true }).data;
        console.log("parsed data", parsedData);
        console.log("parsed data", JSON.stringify(parsedData[0][1]));
        const dataToSend = parsedData.map(row => [row[1]]);
        const filteredArr = dataToSend.slice(0, -1);
        console.log("data to send", dataToSend, filteredArr);

        console.log({ dataToSend });

        // console.log("data inside bulk upload",dataToSend);
        service
          .post("/blog/category", {
            "table_name": "user_type",
            "schema": "name",
            "data":
              filteredArr

          })
          .then((res) => {
            console.log("Response from backend is", JSON.stringify(res, null, 2));
            res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
            const jsonData = filteredArr.map((arr) => {
              return { name: arr[0] };
            });

            setRowsData(rowsData => [...jsonData, ...rowsData]); // add new data to the existing array
            window.location.reload(false)
          })
          .catch((err) => {
            console.log("Errog Msg: ", err);
            window.alert(err.response.data.message)
          });

      };


    }
  };

  const handleDeleteMul = (id, index) => {
    console.log("delete", id)
    let list = [...rowsData];
    console.log("listtttt1", list);
    console.log('hey bhooms')
    service
      .delete("/UserType", {
        data: { "ids": id }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list = list.filter((item) => id.indexOf(item.id) < 0);
        setRowsData(list)
        console.log("listtttt2", list);
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        console.log(err.response.data.message)
        window.alert(err.response.data.message)

      });
  }
  const data_getter = (name, id, index) => {
    console.log("Dude here's your name:", name);
    console.log("Dude here's your id:", id, index);
    if (name === "delete_mul") {
      handleDeleteMul(id, index)
    }
    else if (name !== undefined && name !== null && (id === undefined || id === null || id === 0)) {
      handleFormSubmission(name);
    } else if (name !== undefined && name !== "" && name !== null && id !== undefined && id !== null && id !== 0) {
      handlePutRequest(name, id, index);
    } else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
      handleDelete(id, index);
    }
  }
  // get request
  useEffect(() => {
    console.log("Brother")
    function handleEffect() {
      if (Cookies.get('token') === null || Cookies.get('token') === undefined ) {
        navigate('/');
    }
      service
        .get("/blog/category")
        .then((res) => {
          console.log("Response from backend is", JSON.stringify(res, null, 2))
          setRowsData(res.blog_category)
        })
        .catch((err) => {
          console.log(err)
        }
        )
    }
    handleEffect();
  }, [])

  const headers = {
    Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
  };

  // post request
  const handleFormSubmission = async (user_type_name) => {
    // let count = 0;
    // console
    //form wont
    // e.preventDefault();
    //post request here
    let temp_rows = [...rowsData];
    // temp_rows[temp_rows.length].user_type_name = user_type_name;

    console.log("User TYpe id not found, sending teh post request", temp_rows);
    // props.rows = temp_rows

    service
      .post("/blog/category", { name: user_type_name, "seq_order": 1,
      "platform": ["CareerCarve", "Resumes Digital"] })
      .then((res) => {
        // console.log("Response from backend is", res.data);
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        // SetUser_type_id(res.data.user_type_id);

        setRowsData([{ id: res.data.blog_category_id, name: user_type_name }, ...rowsData])

        console.log("response id is:", res.data.blog_category_id);

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        console.log(err.response.data.message)
        window.alert(err.response.data.message)
      });

    // setShow(false)

  }
  const handlePutRequest = async (user_type_name, user_type_id, index) => {
    //get request here
    service
      .put("/blog/category", { id: user_type_id, name: user_type_name,"seq_order": 1,
      "platform": ["CareerCarve", "Resumes Digital"] })
      .then((res) => {
        // console.log("Response from backend is", res.data);
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = [...rowsData]
        temp[index].name = user_type_name
        setRowsData(temp)
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        console.log(err.response.data.message)
        window.alert(err.response.data.message)
      });
  }

  // delete request
  const handleDelete = async (user_type_id, index) => {
    console.log("delete", user_type_id)
    let list = [...rowsData];

    console.log("listtttt1", list);


    console.log('hey bhooms')
    service
      .delete("/UserType", {
        data: { "ids": [user_type_id] }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list.splice(index, 1);
        setRowsData(list)
        console.log("listtttt2", list);

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);

        console.log(err.response.data.message)
        window.alert(err.response.data.message)

      });
  }

  const tempFunction = (data) => {
    console.log(data);
  }

  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is:  ", rowsData);
    // Simulate a button click after 2 seconds
    const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


  }

  const goBack = () => {
    console.log("Back Button is Clicked Now")
    navigate(-1);
  }

  return (<div >
    <RowsContext.Provider value={rowsData}>
      <TopBar/>
      <SideNavigationBar/>
      <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
      {console.log({ rowsData })}
      <EnhancedTable data_getter={data_getter} handleCsvUpload={handleCsvUpload} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} />
    </RowsContext.Provider>
    {/* <input type="file" onChange={handleCsvUpload} /> */}
  </div>
  )
}
// value={{rowsData, handleFormSubmission : (e,user_type_name) => handleFormSubmission(e,user_type_name)}}

export default BlogsCategory