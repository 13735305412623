import React, {useState} from 'react'; 
import {FaArrowCircleUp} from 'react-icons/fa'; 
// import { Button } from './Styles'; 
  
const ScrollButton = () => { 
  const [visible, setVisible] = useState(false) 
  
  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setVisible(true) 
    }  
    else if (scrolled <= 300) { 
      setVisible(false) 
    } 
  }; 
  
  const scrollToTop = () => { 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth' 
    }); 
  }; 
  
  window.addEventListener('scroll', toggleVisible); 
  
  return ( 
    <button style={{position: "fixed",  
        width: "100%", 
        left: "45%", 
        bottom: "10rem", 
        height: "20px", 
        fontSize: "3rem", 
        zIndex: "1", 
        border:"none",
        cursor: "pointer", 
        background:"transparent",
        color: "#41B8D5" }}> 
     <FaArrowCircleUp onClick={scrollToTop}  
     style={{display: visible ? 'inline' : 'none'}} /> 
    </button> 
  ); 
} 
  
export default ScrollButton; 