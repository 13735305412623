import { Autocomplete, Box, Button, Chip, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Color, DivCenter } from '../../../../GlobalStyles'
import { Close, DateRange } from '@mui/icons-material'
import ReactQuill from 'react-quill'
import { DeadlineCalendarModal, DeadlineTimeCalendarModal } from '../../../Reusable_functions/DeadlineTimeCalendarModal/DeadlineTimeCalendarModal'
import { DecideInterviewsModal } from './MentorInterestModals'
import { formatConvertermonthfirst, get_specialCharReplace } from '../../../../services/specialCharReplace'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { makeTextBold } from '../../../Reusable_functions/htmlParser/htmlParser'
import { DialogueBox } from '../../../Reusable_functions/DialogueBox/DialogueBox'
import DOMPurify from 'dompurify'

const MentorInterest = ({ mentorInterestData, setMentorInterestData, mentorCities, process_id, onStepChange }) => {
    console.log("MentorInterest ~ mentorInterestData:", mentorInterestData)

    const service = new CCService();
    const [pageState, setPageState] = useState({
        editable: 1,
    })
    const [loading, setLoading] = useState({ activity: false });

    const [publishFlag, setPublishFlag] = useState(true);
    const [formInputs, setFormInputs] = useState({
        session_date_slot_count_mapping: [...mentorInterestData.session_date_slot_count_mapping],
        mentor_cities: [...mentorInterestData.mentor_cities],
        likely_schedule: mentorInterestData.likely_schedule,
        trip_details: mentorInterestData.trip_details,
        mentor_interest_deadline: mentorInterestData.mentor_interest_deadline,
        mentor_compensation: mentorInterestData.mentor_compensation,
        departure_cities: [...mentorInterestData.departure_cities],
        arrival_cities: [...mentorInterestData.arrival_cities],
    })

    //set deadline modal
    const [deadlineModalData, setDeadlineModalData] = useState({
        open: false,
        onClose: () => setDeadlineModalData((prev) => ({ ...prev, open: false })),
        value: null,
        flag: '',
        extraData: {
            heading: "",
            action_button: "",
            handleAction: {}
        }
    });
    const [deadlineTimeModalData, setDeadlineTimeModalData] = useState({
        open: false,
        onClose: () => setDeadlineTimeModalData((prev) => ({ ...prev, open: false })),
        value: null,
        flag: '',
        extraData: {
            heading: "",
            action_button: "",
            handleAction: {}
        }
    });

    //MODALS
    const [decideInterviews, setDecideInterviews] = useState({
        open: false,
        onClose: () => setDecideInterviews((prev) => ({ ...prev, open: false })),
        current: { session_date: null, per_mentor_slot_count: null },
        allData: [],
        extraData: {
            process_id: null
        }
    });


    console.log("MentorInterest ~ formInputs:", formInputs)

    const styles = {
        page: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        button_styles: {
            contained: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.primary1,
                color: Color.white,
                borderRadius: '12px',
                textTransform: 'none',
                '&:hover': {
                    background: Color.primary1,
                    color: Color.white,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            },
            outlined: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.white,
                color: Color.primary1,
                borderRadius: '12px',
                border: `1px solid ${Color.primary1}`,
                textTransform: 'none',
                '&:hover': {
                    background: Color.white,
                    color: Color.primary1,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            }
        }
    }

    //FUNCTIONS
    useEffect(() => {
        const handleMentorInterestEffect = () => {
            if (mentorInterestData.session_date_slot_count_mapping.length !== 0)
                setPublishFlag(false);
        }
        handleMentorInterestEffect();
    }, [])

    const handleChangeMentorCities = (e, value, name) => {
        console.log("handleChangeMentorCities ~ name:", name, value)
        setFormInputs((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const handleRemoveCities = (index, name) => {
        let temp_formInputs = { ...formInputs };
        temp_formInputs[name] = temp_formInputs[name].filter((_, i) => i !== index);
        console.log("handleRemoveCities ~ temp_formInputs:", temp_formInputs)
        setFormInputs(temp_formInputs);
    }

    const handleDeadlineCalendarModal = (flag) => {
        console.log("goes in here:", flag)
        if (flag === 'session_date_slot_count_mapping') {
            console.log("noupdates in here:", flag)
            setDecideInterviews((prev) => ({ ...prev, allData: formInputs[flag], }));
            setDeadlineModalData((prev) => ({ ...prev, open: true, flag: flag }));
        } else {
            console.log("updates in here:", flag)
            setDeadlineTimeModalData((prev) => ({ ...prev, open: true, flag: flag }));
            setDeadlineModalData((prev) => ({ ...prev, flag: flag }));
        }
    }


    //After Saving a Date in the Calendar
    const handleDeadlineCalendarSave = (value, time) => {
        console.log("session_date_slot_count_mapping in handleDeadlineCalendarSave:", deadlineModalData)
        if (deadlineModalData.flag === 'session_date_slot_count_mapping') {
            console.log("handleDeadlineCalendarSave ~ value, time:", value, time);
            const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
            console.log("handleDeadlineCalendarSave ~ date:", date)
            const epochTime = date.getTime();
            console.log("handleDeadlineCalendarSave ~ epochTime:", epochTime,);
            setDeadlineModalData((prev) => ({ ...prev, open: false, value: epochTime, }));
            setDecideInterviews((prev) => ({
                ...prev, open: true,
                current: { ...prev.current, session_date: epochTime, per_mentor_slot_count: null },
                // allData: formInputs[deadlineModalData.flag]
                // allData: formInputs[deadlineModalData.flag]
            }));
        } else {
            const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
            const epochTime = date.getTime();
            console.log("epoch time for flag: ", deadlineModalData.flag, epochTime)
            setDeadlineTimeModalData((prev) => ({ ...prev, open: false, }));
            setFormInputs((prev) => ({ ...prev, mentor_interest_deadline: epochTime }));
        }
    }

    const handleDecideInterviews_Submit = (date, interviewCount) => {
        let temp_decide_interviews_allData = [...decideInterviews.allData];
        const filtered_Alldata = temp_decide_interviews_allData.filter((item,index)=>{
          return(  item.session_date !== date )
        })
        filtered_Alldata.push({ session_date: date, per_mentor_slot_count: interviewCount });
        setFormInputs((prev) => ({
            ...prev,
            session_date_slot_count_mapping: filtered_Alldata,
        }));
        setDecideInterviews((prev) => ({
            ...prev, open: false, allData: filtered_Alldata
        }));
    }

    const handleDecideInterviews_AddMore = (date, interviewCount) => {
        console.log("in handle add more:", date)
        setDecideInterviews((prev) => ({
            ...prev, open: false,
            current:{
                session_date:null, per_mentor_slot_count:null
            },
            allData: [
                ...prev.allData,
                { session_date: date, per_mentor_slot_count: interviewCount }
            ]
        }));
        setDeadlineModalData((prev) => ({ ...prev, open: true, }));
    }

    //Cross button 
    const handleRemoveSession = (session_index) => {
        let temp_formInputs = [...formInputs.session_date_slot_count_mapping];
        temp_formInputs.splice(session_index, 1);

        // temp_formInputs.filter((_, index) => index !== session_index);
        setFormInputs((prev) => ({ ...prev, session_date_slot_count_mapping: temp_formInputs }))
        setDecideInterviews((prev) => ({
            ...prev,
            allData: temp_formInputs
        }))
    }

    const handleRemoveModalSession = (session_index) => {
        let temp_allData = [...decideInterviews.allData];
        temp_allData.splice(session_index, 1);

        // temp_formInputs.filter((_, index) => index !== session_index);
        setDecideInterviews((prev) => ({
            ...prev,
            allData: temp_allData
        }))
    }

    const handleReviewPage = async () => {
        setLoading((prev) => ({ ...prev, activity: true }));
        try {

            setPageState((prev) => ({ ...prev, editable: 0 }));
            setLoading((prev) => ({ ...prev, activity: false }));
            // setPageState((prev) => ({ ...prev, editable: 1 }));
        } catch (error) {
            console.error("Error while submitting Mentor Interest:", error);
            setLoading((prev) => ({ ...prev, activity: false }));
        }
    }

    const handleEditPage = () => {
        setPageState((prev) => ({ ...prev, editable: 1 }));
    }

    const handleSubmitMentorInterest = async () => {
        setLoading((prev) => ({ ...prev, activity: true }));

        try {
            const body = {
                ...formInputs,
                process_id: process_id
            }
            await service.put(`/os/superadmin/properties`, body);
            setMentorInterestData((prev) => ({
                ...prev,
                session_date_slot_count_mapping: [...formInputs.session_date_slot_count_mapping],
                mentor_cities: [...formInputs.mentor_cities],
                likely_schedule: formInputs.likely_schedule,
                trip_details: formInputs.trip_details,
                mentor_interest_deadline: formInputs.mentor_interest_deadline,
                mentor_compensation: formInputs.mentor_compensation,
                departure_cities: [...formInputs.departure_cities],
                arrival_cities: [...formInputs.arrival_cities],
            }))
            console.log({ publishFlag });
            if (publishFlag)
                await service.put(`/os/superadmin/properties/publish`, { "process_id": process_id });
            else {
                const body = {
                    ...formInputs,
                    process_id: process_id
                }
                await service.put(`/os/superadmin/properties`, body);
            }
            onStepChange(null, 3, 0);
            setLoading((prev) => ({ ...prev, activity: false }));
            // setPageState((prev) => ({ ...prev, editable: 1 }));
        } catch (error) {

            console.error("Error while submitting Mentor Interest:", error);
            setLoading((prev) => ({ ...prev, activity: false }));
        }
    }


    // const disabledays = (value, time) => {
    //     console.log("disabledays ~ day", value, time);
    //     const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
    //     console.log("handleDeadlineCalendarSave ~ date:", date)
    //     const epochTime = date.getTime();
    //     console.log("disabledays ~ day:", value, decideInterviews.allData.map((date) => date.session_date).includes(epochTime));
    //     return decideInterviews.allData.map((date) => date.session_date).includes(epochTime);
    // }

    // const isDisabledDate = (date) => {
    //     const epochDate = new Date(Date.UTC(date["$d"].getFullYear(), date["$d"].getUTCMonth(), date["$d"].getDate(), 0));
    //     console.log("epochdate:", epochDate.getDay(),
    //         epochDate.getMonth(),
    //         epochDate.getDate(),
    //         epochDate.getFullYear())
    //     const disabledDates = decideInterviews.allData;

    //     disabledDates.forEach((date) => {
    //         const d = new Date(date.session_date);
    //         console.log("normaldate:", date, d, d.getDay(),
    //             d.getMonth(),
    //             d.getDate(),
    //             d.getFullYear())
    //         if (d.getDay() === epochDate.getDay() &&
    //             d.getMonth() === epochDate.getMonth() &&
    //             d.getDate() === epochDate.getDate() &&
    //             d.getFullYear() === epochDate.getFullYear()) {
    //             return true;
    //         } else return false;
    //     })

    //     // // Extract day, month, date, and year
    //     // const day = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
    //     // const month = date.getMonth(); // 0 for January, 1 for February, etc.
    //     // const dateOfMonth = date.getDate();
    //     // const year = date.getFullYear();

    //     // console.log("isDisabledDate:", epochDate, disabledDates, disabledDates.some(timestamp => timestamp.session_date === epochDate.getTime()))
    //     // return disabledDates.some(timestamp => timestamp.session_date === epochDate.getTime());
    // }

    const quillRef = useRef(null);

    const handleChange = (value, key) => {
        setFormInputs({
            ...formInputs, [key]: value
        });
    };

    useEffect(() => {
        const quill = quillRef.current.getEditor();

        const handlePaste = (key) => (e) => {
            e.preventDefault();

            const clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text');

            // Sanitize the pasted data to strip all HTML tags

            pastedData = DOMPurify.sanitize(pastedData, {
                ALLOWED_TAGS: [], // No tags allowed
                ALLOWED_ATTR: {}  // No attributes allowed
            });
            console.log("form input sanitized pasted data:", pastedData)
            let range = quill.getSelection();

            // If range is null, set it to the end of the editor content
            if (range === null) {
                range = {
                    index: quill.getLength(),
                    length: 0
                };
            }

            // Update the state with the new content
            const updatedValue = quill.root.innerHTML;
            console.log("form input sanitized updated data:", updatedValue)
            setFormInputs((prevFormInputs) => ({
                ...prevFormInputs,
                [key]: prevFormInputs.likely_schedule && prevFormInputs.likely_schedule?.length>1? prevFormInputs.likely_schedule  + " " + pastedData  : pastedData
            }));
        };

        // Add the paste event listener
        const pasteHandler = handlePaste('likely_schedule');
        quill.root.addEventListener('paste', pasteHandler);

        // Clean up the event listener on component unmount
        return () => {
            quill.root.removeEventListener('paste', pasteHandler);
        };
    }, []);

    console.log("MentorInterest ~ decideInterviews:", decideInterviews);
    console.log("MentorInterest ~ deadlineModalData:", deadlineModalData);

    return (
        <>
            {pageState.editable ?
                <Box sx={{ ...styles.page, p: '20px 90px', gap: '32px', background: 'rgba(178, 219, 228, 0.1)' }}>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ fontSize: '24px', fontWeight: 600 }}>Mentor interest details</Typography>
                        <Typography style={{ fontSize: '20px', }}>Provide following details for mentors to show interest</Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Choose exact date(s) of session(s) happening on-campus<span style={{ color: Color.red }}>*</span></Typography>
                        <Box
                            onClick={() => handleDeadlineCalendarModal("session_date_slot_count_mapping")}
                            sx={{
                                background: Color.white,
                                display: 'flex',
                                alignItems: 'center',
                                border: `1px solid ${Color.neutralMidGrey}`, // Set background color
                                borderRadius: 4, // Add border radius
                                padding: '8px', // Add padding
                                // cursor: deadlineModalData.value ? 'default' : 'pointer', // Simulate clickable behavior
                                cursor: 'pointer', // Simulate clickable behavior
                                width: '100%'
                            }}>
                            <Box sx={{ ...DivCenter, justifyContent: 'flex-start', flexGrow: 1, color: Color.neutralMidGrey, gap: '8px', flexWrap: 'wrap' }}>
                                {formInputs.session_date_slot_count_mapping.length ?
                                    <>
                                        {formInputs.session_date_slot_count_mapping.map((session, session_index) =>
                                            <Box onClick={(e) => e.stopPropagation()} sx={{ ...DivCenter, justifyContent: 'space-between', p: '8px 12px', cursor: 'default', width: '15%', minWidth: '272px', height: '45px', borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.neutralMidGrey }}>
                                                <Close
                                                    sx={{ fontSize: '16px', cursor: 'pointer' }}
                                                    onClick={() => handleRemoveSession(session_index)} />
                                                {formatConvertermonthfirst(session.session_date)} ({session.per_mentor_slot_count} interviews)
                                            </Box>
                                        )}
                                        {/* <Typography style={{ fontSize: '14px', color: Color.neutralMidGrey, p: '0 5px' }}>Specify the deadline</Typography> */}
                                    </>
                                    :
                                    "Choose session date(s)"
                                }
                            </Box>
                            <IconButton aria-label="Pick Date"
                                // disabled={deadlineModalData.value}
                                onClick={() => {
                                    handleDeadlineCalendarModal("session_date_slot_count_mapping")
                                }}>
                                <DateRange />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ ...styles.page, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ fontSize: '20px' }}>Total interviews per mentor</Typography>
                        <Typography style={{ fontSize: '20px' }}>
                            {/* Sum of all the interview counts */}
                            {formInputs['session_date_slot_count_mapping'].reduce((acc, obj) => {
                                return acc + parseInt(obj['per_mentor_slot_count']);
                            }, 0) ?? 'N/A'}
                        </Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Preferred city mentor comes from<span style={{ color: Color.red }}>*</span></Typography>
                        <Autocomplete
                            name='mentor_cities'
                            sx={{ width: "100%", minHeight: '45px', height: 'auto', background: Color.white }}
                            placeholder='Enter the preferred cities'
                            multiple
                            options={mentorCities}
                            freeSolo
                            value={formInputs.mentor_cities}
                            onChange={(e, value) => {
                                handleChangeMentorCities(e, value, 'mentor_cities')
                            }
                            }
                            renderTags={(value, getTagProps) =>
                                <Box sx={{ ...DivCenter, gap: '8px' }}>
                                    {value.map((option, index) => (
                                        <Box sx={{
                                            ...DivCenter, justifyContent: 'space-evenly',
                                            p: '12px', cursor: 'default', width: 'auto', minWidth: '100px', height: '45px',
                                            borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.primary1
                                        }}>
                                            <Box sx={{ ...DivCenter, width: '10%' }}>
                                                <Close
                                                    sx={{ fontSize: '14px', cursor: 'pointer' }}
                                                    onClick={() => { handleRemoveCities(index, 'mentor_cities') }} />
                                            </Box>
                                            <Box sx={{ ...DivCenter, width: '90%' }}>
                                                <Typography style={{ width: '100%', textAlign: 'center' }}>{option}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            }
                            renderInput={params => (
                                <TextField {...params} name='mentor_cities' placeholder='Enter the preferred cities' sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "12px",
                                    },
                                }} />
                            )}
                        />
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Provide day by day likely schedule<span style={{ color: Color.red }}>*</span></Typography>
                        <ReactQuill
                            ref={quillRef}
                            style={{ marginBottom: '1.5rem', width: '100%', background: Color.white }}
                            value={formInputs.likely_schedule}
                            onChange={(value) => handleChange(value, 'likely_schedule')}
                        />
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Provide other details of the trip<span style={{ color: Color.red }}>*</span></Typography>
                        <ReactQuill
                            style={{ marginBottom: '1.5rem', width: '100%', background: Color.white }}
                            value={formInputs.trip_details}
                            onChange={(value) => handleChange(value, "trip_details")}
                        />
                    </Box>
                    <Box sx={{ ...styles.page, flexDirection: 'row', gap: '16px' }}>
                        <Box id='MentorShowInterestDeadline' sx={{ ...styles.page, gap: '16px' }}>
                            <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Deadline for mentor to show interest<span style={{ color: Color.red }}>*</span></Typography>
                            <Box
                                onClick={() => formInputs.mentor_interest_deadline ? {} : handleDeadlineCalendarModal("mentor_interest_deadline")}
                                sx={{
                                    background: Color.white,
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `1px solid ${Color.neutralMidGrey}`, // Set background color
                                    borderRadius: 4, // Add border radius
                                    padding: '8px', // Add padding
                                    cursor: formInputs.mentor_interest_deadline ? 'default' : 'pointer', // Simulate clickable behavior
                                    // cursor: 'pointer', // Simulate clickable behavior
                                    width: '100%'
                                }}>
                                <Box sx={{ ...DivCenter, justifyContent: 'flex-start', flexGrow: 1, color: Color.neutralMidGrey, gap: '8px', flexWrap: 'wrap' }}>
                                    {formInputs.mentor_interest_deadline ?
                                        <>
                                            <Box sx={{ ...DivCenter, justifyContent: 'space-between', p: '8px 12px', cursor: 'default', width: '50%', minWidth: '150px', height: 'auto', minHeight: '45px', borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.neutralMidGrey }}>
                                                <Close
                                                    sx={{ fontSize: '16px', cursor: 'pointer' }}
                                                    onClick={() => setFormInputs((prev) => ({ ...prev, mentor_interest_deadline: null }))} />
                                                {formatConvertermonthfirst(formInputs.mentor_interest_deadline, true)}
                                            </Box>

                                        </>
                                        :
                                        "Specify the deadline"
                                    }
                                </Box>
                                <IconButton aria-label="Pick Date"
                                    disabled={formInputs.mentor_interest_deadline}
                                    onClick={() => { handleDeadlineCalendarModal("mentor_interest_deadline") }}>
                                    <DateRange />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ ...styles.page, gap: '16px' }}>
                            <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Mentor compensation for the entire trip (in INR)<span style={{ color: Color.red }}>*</span></Typography>
                            <TextField placeholder='Specify the compensation for the trip' value={formInputs.mentor_compensation} onChange={(e) => setFormInputs((prev) => ({ ...prev, mentor_compensation: e.target.value }))} sx={{ width: '100%', background: Color.white }} />
                        </Box>
                    </Box>
                    <Box sx={{ ...styles.page, flexDirection: 'row', gap: '16px' }}>
                        <Box sx={{ ...styles.page, gap: '16px' }}>
                            <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Departure cities<span style={{ color: Color.red }}>*</span></Typography>
                            <Autocomplete
                                name='departure_cities'
                                sx={{ width: "100%", minHeight: '45px', height: 'auto', background: Color.white }}
                                multiple
                                // placeholder='Type Skills...'
                                options={[]}
                                freeSolo
                                value={formInputs.departure_cities}
                                onChange={(e, value) => {
                                    handleChangeMentorCities(e, value, 'departure_cities')
                                }
                                }
                                renderTags={(value, getTagProps) =>
                                    <Box sx={{ ...DivCenter, gap: '8px' }}>
                                        {value.map((option, index) => (
                                            <Box sx={{
                                                ...DivCenter, justifyContent: 'space-evenly',
                                                p: '12px', cursor: 'default', width: 'auto', minWidth: '100px', height: '45px',
                                                borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.primary1
                                            }}>
                                                <Box sx={{ ...DivCenter, width: '10%' }}>
                                                    <Close
                                                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                                                        onClick={() => { handleRemoveCities(index, 'departure_cities') }} />
                                                </Box>
                                                <Box sx={{ ...DivCenter, width: '90%' }}>
                                                    <Typography style={{ width: '100%', textAlign: 'center' }}>{option}</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                }
                                renderInput={params => (
                                    <TextField {...params} name='departure_cities' placeholder='Cities mentors will be travelling from' sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "12px",
                                        },
                                    }} />
                                )}
                            />
                        </Box>
                        <Box sx={{ ...styles.page, gap: '16px' }}>
                            <Typography style={{ fontSize: '20px', fontWeight: 500 }}>Arrival cities<span style={{ color: Color.red }}>*</span></Typography>
                            <Autocomplete
                                name='arrival_cities'
                                sx={{ width: "100%", minHeight: '45px', height: 'auto', background: Color.white }}
                                multiple
                                // placeholder='Type Skills...'
                                options={[]}
                                freeSolo
                                value={formInputs.arrival_cities}
                                onChange={(e, value) => {
                                    handleChangeMentorCities(e, value, 'arrival_cities')
                                }
                                }
                                renderTags={(value, getTagProps) =>
                                    <Box sx={{ ...DivCenter, gap: '8px' }}>
                                        {value.map((option, index) => (
                                            <Box sx={{
                                                ...DivCenter, justifyContent: 'space-evenly',
                                                p: '12px', cursor: 'default', width: 'auto', minWidth: '100px', height: '45px',
                                                borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.primary1
                                            }}>
                                                <Box sx={{ ...DivCenter, width: '10%' }}>
                                                    <Close
                                                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                                                        onClick={() => { handleRemoveCities(index, 'arrival_cities') }} />
                                                </Box>
                                                <Box sx={{ ...DivCenter, width: '90%' }}>
                                                    <Typography style={{ width: '100%', textAlign: 'center' }}>{option}</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                }
                                renderInput={params => (
                                    <TextField {...params} name='arrival_cities' placeholder='Cities mentors will be returning to' sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "12px",
                                        },
                                    }} />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ ...styles.page, alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
                        <Button onClick={() => handleReviewPage()}
                            sx={{ ...styles.button_styles.contained, width: '50%', fontSize: '20px', borderRadius: '12px', height: '68px' }}>
                            Review
                        </Button>
                    </Box>
                    <DeadlineTimeCalendarModal
                        open={deadlineTimeModalData.open}
                        onClose={() => { deadlineTimeModalData.onClose() }}
                        extraData={{
                            ...deadlineModalData.extraData,
                            heading: "Choose Deadline",
                            action_button: "Save",
                            handleAction: (a, b) => handleDeadlineCalendarSave(a, b),
                        }}
                    // shouldDisableDate={isDisabledDate}
                    />
                    <DeadlineCalendarModal
                        shouldDisableDates={decideInterviews.allData ?? []}
                        open={deadlineModalData.open}
                        onClose={deadlineModalData.onClose}
                        extraData={{
                            ...deadlineModalData.extraData,
                            heading: "Choose Deadline",
                            action_button: "Save",
                            handleAction: (a, b) => handleDeadlineCalendarSave(a, b),
                        }}
                        // shouldDisableDate={isDisabledDate}
                        disablePast={true}
                    />
                    <DecideInterviewsModal
                        open={decideInterviews.open}
                        onClose={decideInterviews.onClose}
                        current={decideInterviews.current}
                        onDelete={handleRemoveModalSession}
                        action_function={{ handleDecideInterviews_Submit, handleDecideInterviews_AddMore }}
                        allData={decideInterviews.allData}
                        extraData={{ setDeadlineModalData }}
                    />
                </Box>
                : <Box sx={{ ...styles.page, p: '20px 90px 50px 90px', gap: '32px', background: 'rgba(178, 219, 228, 0.1)' }}>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ fontSize: '26px', fontWeight: 600 }}>Review Mentor Preference Form</Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Box sx={{ ...styles.page, gap: '16px' }}>
                            <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Exact date(s) of session happening on-campus</Typography>
                            <Box
                                sx={{
                                    ...DivCenter,
                                    justifyContent: 'flex-start',
                                    padding: '8px', // Add padding
                                    width: '100%',
                                    gap: '8px'
                                }}>
                                {formInputs.session_date_slot_count_mapping.map((session) =>
                                    <Box sx={{ border: `1px solid ${Color.primary1}`, borderRadius: '61px', background: 'rgba(56, 193, 223, 0.3)', p: '10px' }}>
                                        <Typography style={{ color: Color.neutralMidGrey }}>
                                            {formatConvertermonthfirst(session.session_date)} ({session.per_mentor_slot_count} interviews)
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ ...styles.page, flexDirection: 'row', justifyContent: 'flex-start', gap: '8px' }}>
                            <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Total interviews per mentor:</Typography>
                            <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>
                                {/* Sum of all the interview counts */}
                                {formInputs['session_date_slot_count_mapping'].reduce((acc, obj) => {
                                    return acc + parseInt(obj['per_mentor_slot_count']);
                                }, 0) ?? 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Preferred cities of mentors</Typography>
                        <Box sx={{
                            ...DivCenter,
                            justifyContent: 'flex-start',
                            padding: '8px', // Add padding
                            width: '100%',
                            gap: '8px'
                        }}>
                            {formInputs.mentor_cities.map((city) =>
                                <Box sx={{ border: `1px solid ${Color.primary1}`, borderRadius: '61px', background: 'rgba(56, 193, 223, 0.3)', p: '10px' }}>
                                    <Typography style={{ color: Color.primary1 }}>
                                        {city}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Day by day likely schedule</Typography>
                        <Typography style={{ color: Color.neutralMidGrey, fontWeight: 500 }}>{makeTextBold(formInputs.likely_schedule)}</Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Other details of trip</Typography>
                        <Typography style={{ color: Color.neutralMidGrey, fontWeight: 500 }}>{makeTextBold(formInputs.trip_details)}</Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Deadline for mentor to show interest</Typography>
                        <Typography style={{ fontSize: '18px', color: Color.neutralDarkGrey, fontWeight: 600 }}>{formatConvertermonthfirst(formInputs.mentor_interest_deadline, true)}</Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.neutralDarkGrey, fontSize: '20px', fontWeight: 500 }}>Mentor compensation for the trip</Typography>
                        <Typography style={{ fontSize: '20px', color: Color.neutralDarkGrey, fontWeight: 500 }}>₹ {formInputs.mentor_compensation}/-</Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.darkerGrey, fontSize: '20px', fontWeight: 600 }}>Departure cities</Typography>
                        <Typography style={{ fontSize: '20px', color: Color.neutralDarkGrey, fontWeight: 500 }}>
                            {formInputs.departure_cities.map((departure, index) => departure + (index === formInputs.departure_cities.length - 1 ? "" : ", "))}
                        </Typography>
                    </Box>
                    <Box sx={{ ...styles.page, gap: '16px' }}>
                        <Typography style={{ color: Color.darkerGrey, fontSize: '20px', fontWeight: 600 }}>Arrival cities</Typography>
                        <Typography style={{ fontSize: '20px', color: Color.neutralDarkGrey, fontWeight: 500 }}>
                            {formInputs.arrival_cities.map((arrival, index) => arrival + (index === formInputs.arrival_cities.length - 1 ? "" : ", "))}
                        </Typography>
                    </Box>
                    <Box sx={{ ...styles.page, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '16px', mt: '50px' }}>
                        <Button
                            onClick={() => handleEditPage()}
                            sx={{ ...styles.button_styles.contained, width: '25%', fontSize: '20px', borderRadius: '12px', height: '55px' }}>
                            Edit Mentor Interest
                        </Button>
                        <Button
                            onClick={() => handleSubmitMentorInterest()}
                            sx={{ ...styles.button_styles.contained, width: '25%', fontSize: '20px', borderRadius: '12px', height: '55px' }}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            }
        </>
    )
}

export default MentorInterest