import { Autocomplete, Box, Checkbox, Chip, IconButton, MenuItem, OutlinedInput, Select, TextField, ToggleButtonGroup, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./addEpisode.css";
import React, { useState } from "react";
import { ToggleButton } from "react-bootstrap";
import { useEffect } from "react";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Cookies from "js-cookie";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { send_specialCharReplace } from "../../../services/specialCharReplace";

function AddEpisodeModal({ type, close, stationId, mentorDetails, activityList, specializationList, roleList, UpdateAddEpisode, stationData, selectedDetailModal, updateEpisodeDetailModal, updateDisplayData }) {
  const location = useLocation();
  const content_type = location.pathname === "/Podcast" ? "Podcast" : "Session";
  const [addEpisodeForm, setAddEpisodeForm] = useState({
    station_id: stationId,
    name: "",
    about_episode: "",
    mentor_id: null,
    about_mentor: "",
    episode_order: null,
    search_keywords: [],
    image: "",
    activities: [],
    specialization: [],
    roles: [],
    recording: "",
    recommendation_keywords: [],
  });
  const [checked, setChecked] = useState(true);
  const [image, setSelectedImage] = useState(null);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [video, setSelectedVideo] = useState(null);
  const [videoUpdated, setVideoUpdated] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [stationEpisodes, setStationEpisodes] = useState([
    [
      {
        episode_id: null,
        episode_order: null,
        mentors: [],
        episode_image: null,
        episode_name: "Choose a Station",
      },
    ],
    [
      {
        episode_id: null,
        episode_order: null,
        mentors: [],
        episode_image: null,
        episode_name: "Choose a Station",
      },
    ],
    [
      {
        episode_id: null,
        episode_order: null,
        mentors: [],
        episode_image: null,
        episode_name: "Choose a Station",
      },
    ],
  ]);

  const [addEpisodeToClassification, setAddEpisodeToClassification] = useState([
    {
      station_id: null,
      episode_id: null,
    },
    {
      station_id: null,
      episode_id: null,
    },
    {
      station_id: null,
      episode_id: null,
    },
  ]);

  const [displayForm, setDisplayForm] = useState([null, null, null]);
  console.log("Selected Detail Modal", selectedDetailModal);
  const [editEpisodeForm, setEidtEpisodeForm] = useState({
    episode_id: selectedDetailModal.episode_id ? selectedDetailModal.episode_id : null,
    name: selectedDetailModal.name,
    about_episode: selectedDetailModal.about ? selectedDetailModal.about : "",
    mentor_id: selectedDetailModal.mentor_details?.length > 0 ? selectedDetailModal.mentor_details[0].mentor_id : null,
    about_mentor: selectedDetailModal.mentor_details && selectedDetailModal.mentor_details.length > 0 ? selectedDetailModal.mentor_details[0].about_mentor : null,
    mentorName: selectedDetailModal.mentor_details && selectedDetailModal.mentor_details.length > 0 ? selectedDetailModal.mentor_details[0].mentorName : null,
    keywords: selectedDetailModal.keywords ? selectedDetailModal.keywords : [],
    image: ["", null, undefined].includes(selectedDetailModal.image) ? "" : selectedDetailModal.image.split("/")[selectedDetailModal.image.split("/").length - 1],
    activities: selectedDetailModal.activities ? selectedDetailModal.activities : [],
    specialization: selectedDetailModal.specialization ? selectedDetailModal.specialization : [],
    roles: selectedDetailModal.roles ? selectedDetailModal.roles : [],
    recommendation_keywords: selectedDetailModal.recommendation_keywords ? selectedDetailModal.recommendation_keywords : [],
    video: ["", null, undefined].includes(selectedDetailModal.recording) ? "" : selectedDetailModal.recording.split("/")[selectedDetailModal.recording.split("/").length - 1],
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    mentor: "",
    about_episode: "",
    about_mentor: "",
    search_keywords: "",
    video: "",
    images: "",
  });

  const services = new CCService();

  const validateForm = () => {
    let flag = true;
    if (addEpisodeForm.name === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          name: "Name is required",
        };
      });

      flag = false;
    }
    if (addEpisodeForm.mentor_id === null) {
      setFormErrors((prev) => {
        return {
          ...prev,
          mentor: "Mentor is required",
        };
      });

      flag = false;
    }
    if (addEpisodeForm.about_episode === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          about_episode: "About Episode is required",
        };
      });

      flag = false;
    }
    if (addEpisodeForm.about_mentor === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          about_mentor: "About mentor is required",
        };
      });

      flag = false;
    }
    if (addEpisodeForm.search_keywords.length === 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          search_keywords: "Keywords is required",
        };
      });

      flag = false;
    }
    if (image === null) {
      setFormErrors((prev) => {
        return {
          ...prev,
          image: "Image is required",
        };
      });

      flag = false;
    }
    if (video === null) {
      setFormErrors((prev) => {
        return {
          ...prev,
          video: "Video is required",
        };
      });

      flag = false;
    }

    return flag;
  };

  const ValidateEditForm = () => {
    let flag = true;
    console.log("jjkdshkjf", editEpisodeForm, formErrors);
    if (editEpisodeForm.name === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          name: "Name is required",
        };
      });

      flag = false;
    }
    if (editEpisodeForm.mentor_id === null) {
      setFormErrors((prev) => {
        return {
          ...prev,
          mentor: "Mentor is required",
        };
      });

      flag = false;
    }
    if (editEpisodeForm.about_episode === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          about_episode: "About episode is required",
        };
      });

      flag = false;
    }

    if (editEpisodeForm.about_episode.length > 2000) {
      setFormErrors((prev) => {
        return {
          ...prev,
          about_episode: "About episode can not be more than 2000 characters",
        };
      });

      flag = false;
    }
    if (editEpisodeForm.about_mentor === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          about_mentor: "About Mentor is required",
        };
      });

      flag = false;
    }

    if (editEpisodeForm.keywords.length === 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          search_keywords: "Keywords is required",
        };
      });

      flag = false;
    }
    return flag;
  };
  const handleFormChange = async (e) => {
    const { name, value } = e.target;
    setFormErrors({
      name: "",
      mentor: "",
      about_episode: "",
      about_mentor: "",
      search_keywords: "",
      video: "",
      images: "",
    });
    if (name === "name") {
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          name: value,
        };
      });
    } else if (name === "about_episode") {
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          about_episode: value,
        };
      });
    } else if (name === "mentor_id") {
      const res = await services.get(`/mentorModule/mentor/detail?user_id=${value}`);
      console.log("mentorResponse", res);
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          mentor_id: value,
        };
      });
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          about_mentor: res.data.about_mentor,
        };
      });
    } else if (name === "about_mentor") {
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          about_mentor: value,
        };
      });
    } else if (name === "activities") {
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          activities: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "specialization") {
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          specialization: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "roles") {
      setAddEpisodeForm((prev) => {
        return {
          ...prev,
          roles: typeof value === "string" ? value.split(",") : value,
        };
      });
    }
  };

  const cleanDetailModalData = (data) => {
    const item = {
      roles: [null, "null", "undefined", undefined].includes(data.role_ids) ? [] : data.role_ids.split(",").map((ele) => parseInt(ele)),
      activities: [null, "null", "undefined", undefined].includes(data.activity_ids) ? [] : data.activity_ids.split(",").map((ele) => parseInt(ele)),
      specialization: [null, "null", "undefined", undefined].includes(data.specialization) ? [] : JSON.parse(data.specialization).specializations,
      description: data.description,
      episode_link: [null, "null", "undefined", undefined].includes(data.episode_link) ? [] : data.episode_link,
      image: [null, "null", "undefined", undefined].includes(data.image) ? "" : data.image,
      is_delete: [null, "null", "undefined", undefined].includes(data.is_delete) ? "" : data.is_delete,
      name: data.name,
      recommendation_keywords: [null, "null", "undefined", undefined].includes(data.recommendation_keywords) ? [] : JSON.parse(data.recommendation_keywords).recommendation_keywords,
      search_keywords: [null, "null", "undefined", undefined].includes(data.search_keywords) ? [] : JSON.parse(data.search_keywords).search_keywords,
      station_id: data.station_id,
      station_or_classification: data.station_or_classification,
    };

    console.log("DetailModalData", item);
    return item;
  };

  const handleClassificationSelection = async (e, index) => {
    const { name, value } = e.target;
    if (name === "station_id") {
      let temp = addEpisodeToClassification.map((ele, i) => {
        if (i === index) {
          return {
            station_id: value,
            episode_id: null,
          };
        } else {
          return ele;
        }
      });

      setAddEpisodeToClassification(temp);

      const stationData = await services.get(`/podcast/stationById?station_id=${value}`);
      console.log("StationData", stationData);
      const temp_data = cleanDetailModalData(stationData.data);
      let temp_episode = stationEpisodes.map((item, i) => {
        if (i === index) {
          return temp_data.episode_link;
        } else {
          return item;
        }
      });

      setStationEpisodes(temp_episode);
    } else if (name === "episode_id") {
      let temp = addEpisodeToClassification.map((ele, i) => {
        if (i === index) {
          return {
            station_id: ele.station_id,
            episode_id: value,
          };
        } else {
          return ele;
        }
      });

      setAddEpisodeToClassification(temp);
    }
  };

  const handleSubmitClassification = async (e) => {
    //FILTER WHICH EPISODE AND STATIONS ARE FILLED IN THE FORM
    let temp_episode = stationEpisodes.map((element, index) => {
      if (addEpisodeToClassification[index].station_id === null || addEpisodeToClassification[index].episode_id === null) {
        return null;
      } else {
        let data = element.filter((item) => item.episode_id === addEpisodeToClassification[index].episode_id);
        return data[0];
      }
    });
    temp_episode = temp_episode.filter((item) => item !== null);

    //FILTER FOR THE ADD EPISODE DATA AND EXTRACT EPISODE AND STATION ID
    let filtered_episodes = addEpisodeToClassification.filter((item) => item.station_id !== null && item.episode_id !== null);

    //FIXME: APPEND IN ARRAY
    let backend_data = filtered_episodes.map((item, index) => {
      return {
        episode_id: item.episode_id,
        episode_order: selectedDetailModal.episode_link.length + index,
      };
    });

    let newArray = selectedDetailModal.episode_link.map((item) => {
      return {
        episode_id: item.episode_id,
        episode_order: item.episode_order,
      };
    });
    newArray = newArray.concat(backend_data);
    const body = {
      station_id: stationId,
      name: selectedDetailModal.name,
      description: selectedDetailModal.description,
      search_keywords: selectedDetailModal.search_keywords,
      activity: selectedDetailModal.activities.toString(),
      specialization: selectedDetailModal.specialization,
      role: selectedDetailModal.roles.toString(),
      episode_link: newArray,
      recommendation_keywords: selectedDetailModal.recommendation_keywords,
    };

    try {
      const putRes = await services.put("/podcast/stations", body);

      if (putRes.status === 200) {
        UpdateAddEpisode(temp_episode);
        close();
      }
    } catch (err) {
      window.alert(err.response.message);
    }
  };

  const handleSubmitButton = async () => {
    if (type === "station") {
      if (validateForm()) {
        const body = {
          station_id: stationId,
          episode_order: selectedDetailModal.episode_link.length + 1,
          mentor_details: {
            mentors: [
              {
                mentor_id: addEpisodeForm.mentor_id,
                about_mentor: send_specialCharReplace(addEpisodeForm.about_mentor),
              },
            ],
          },
          name: addEpisodeForm.name,
          about_episode: addEpisodeForm.about_episode,
          keywords: addEpisodeForm.search_keywords,
          activity: addEpisodeForm.activities.toString(),
          specialization: addEpisodeForm.specialization,
          role: addEpisodeForm.roles.toString(),
          recommendation_keywords: addEpisodeForm.recommendation_keywords,
          content_type: content_type,
        };

        try {
          const postResponse = await services.post("/podcast/episodes", body);
          if (postResponse.status === 200) {
            //New Episode added to station
            const headers = {
              Authorization: `Bearer ${Cookies.get("token")}`,
              "Content-Type": "multipart/form-data",
            };
            const blob_image = new Blob([image], {
              type: "image/jpeg",
            });

            const blob_video = new Blob([video], {
              type: location.pathname === "/Podcast" ? "audio/mp3" : "video/mp4",
            });

            const data = new FormData();
            data.append("image$$" + `${editEpisodeForm.image}`, blob_image, `${editEpisodeForm.image}`);
            data.append("video$$" + `${editEpisodeForm.video}`, blob_video, `${editEpisodeForm.video}`);

            const imageRes = await axios.put(`${process.env.REACT_APP_API_URL}/podcast/episodes/imageUpload?episode_id=${postResponse.data.episode_id}`, data, { headers });

            close();

            const mentor_detail = mentorDetails.filter((ele) => ele.user_id === addEpisodeForm.mentor_id)[0];
            const temp_data = {
              episode_data: {
                episode_id: postResponse.data.episode_id,
                episode_order: selectedDetailModal.episode_link.length,
                mentors: [
                  {
                    mentor_id: addEpisodeForm.mentor_id,
                    mentor_name: `${mentor_detail.first_name} ${mentor_detail.last_name}`,
                  },
                ],
                episode_image: URL.createObjectURL(image),
                episode_name: addEpisodeForm.name,
              },
            };

            const episodeData = {
              episode_id: postResponse.data.episode_id,
              name: addEpisodeForm.name,
              about: addEpisodeForm.about_episode,
              keywords: JSON.stringify({
                keywords: addEpisodeForm.search_keywords,
              }),
              recording: URL.createObjectURL(video),
              image: URL.createObjectURL(image),
              specialization: JSON.stringify({
                specializations: addEpisodeForm.specialization,
              }),
              recommendation_keywords: JSON.stringify({
                recommendation_keywords: addEpisodeForm.recommendation_keywords,
              }),
              role_ids: addEpisodeForm.roles.toString(),
              activity_ids: addEpisodeForm.activities.toString(),
            };

            UpdateAddEpisode(temp_data, episodeData);
          }
        } catch (err) {
          window.alert(err.response.message);
        }
      } else {
        console.log("FORMERROR OCCURED");
      }
    } else if (type === "episode") {
      if (ValidateEditForm()) {
        const body = {
          episode_id: editEpisodeForm.episode_id,
          name: editEpisodeForm.name,
          about_episode: editEpisodeForm.about_episode,
          mentor_details: {
            mentors: [
              {
                mentor_id: editEpisodeForm.mentor_id,
                about_mentor: send_specialCharReplace(editEpisodeForm.about_mentor),
              },
            ],
          },
          keywords: editEpisodeForm.keywords,
          activity: editEpisodeForm.activities.toString(),
          specialization: editEpisodeForm.specialization,
          role: editEpisodeForm.roles.toString(),
          recommendation_keywords: editEpisodeForm.recommendation_keywords,
          content_type: content_type,
        };

        try {
          const postResponse = await services.put("/podcast/episodes", body);
          if (postResponse.status === 200) {
            const headers = {
              Authorization: `Bearer ${Cookies.get("token")}`,
              "Content-Type": "multipart/form-data",
            };
            const blob_image = new Blob([image], {
              type: "image/jpeg",
            });

            const blob_video = new Blob([video], {
              type: "video/mp4",
            });
            const data = new FormData();
            if (imageUpdated && videoUpdated) {
              data.append("image$$" + `${editEpisodeForm.image}`, blob_image, `${editEpisodeForm.image}`);
              data.append("video$$" + `${editEpisodeForm.video}`, blob_video, `${editEpisodeForm.video}`);
            } else if (imageUpdated) {
              data.append("image$$" + `${editEpisodeForm.image}`, blob_image, `${editEpisodeForm.image}`);
            } else if (videoUpdated) {
              data.append("video$$" + `${editEpisodeForm.video}`, blob_video, `${editEpisodeForm.video}`);
            }

            if (imageUpdated || videoUpdated) {
              const imageRes = await axios.put(`${process.env.REACT_APP_API_URL}/podcast/episodes/imageUpload?episode_id=${editEpisodeForm.episode_id}`, data, { headers });
            }
            updateEpisodeDetailModal(editEpisodeForm);
            close();
          }
        } catch (err) {
          window.alert("Some error is occured", err.response.message);
        }
      } else {
        console.log("FORMERROR OCCURED");
      }
    }
  };

  const handleEditEpisodeForm = async (e, newValue) => {
    const { name, value } = e.target;

    setFormErrors({
      name: "",
      mentor: "",
      about_episode: "",
      about_mentor: "",
      search_keywords: "",
      video: "",
      images: "",
    });

    if (name === "name") {
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          name: value,
        };
      });
    } else if (name === "about_episode") {
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          about_episode: value,
        };
      });
    } else if (![null, undefined].includes(newValue?.user_id)) {
      const res = await services.get(`/mentorModule/mentor/detail?user_id=${newValue.user_id}`);
      const mentor = mentorDetails.filter((item) => item.user_id === newValue.user_id)[0];
      console.log("mentorResponse", res);
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          mentor_id: newValue.user_id,
          about_mentor: res.data?.about_mentor === null ? "" : res.data.about_mentor,
          mentorName: `${mentor.first_name} ${mentor.last_name}`,
        };
      });
    } else if (name === "about_mentor") {
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          about_mentor: value,
        };
      });
    } else if (name === "activities") {
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          activities: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "specialization") {
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          specialization: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "roles") {
      setEidtEpisodeForm((prev) => {
        return {
          ...prev,
          roles: typeof value === "string" ? value.split(",") : value,
        };
      });
    }
  };

  const handleAddDisplay = async () => {
    if (selectedDetailModal.station_or_classification === "episode") {
      let temp_episode = stationEpisodes.map((element, index) => {
        if (addEpisodeToClassification[index].station_id === null || addEpisodeToClassification[index].episode_id === null) {
          return null;
        } else {
          let data = element.filter((item) => item.episode_id === addEpisodeToClassification[index].episode_id);
          return data[0];
        }
      });
      temp_episode = temp_episode.filter((item) => item !== null);

      //FILTER FOR THE ADD EPISODE DATA AND EXTRACT EPISODE AND STATION ID
      let filtered_episodes = addEpisodeToClassification.filter((item) => item.station_id !== null && item.episode_id !== null);

      filtered_episodes = filtered_episodes.map((item) => item.episode_id);

      //FIXME: APPEND IN ARRAY
      let temp_array = selectedDetailModal.list_to_show.map((item) => {
        return item.id;
      });

      temp_array = temp_array.concat(filtered_episodes);

      const body = {
        collection_id: selectedDetailModal.collection_id,
        content_type: selectedDetailModal.content_type,
        collection_name: selectedDetailModal.collection_name,
        station_or_classification: selectedDetailModal.station_or_classification,
        content_ids: temp_array.toString(),
        order_by: selectedDetailModal.order_by,
        is_active: selectedDetailModal.is_active,
      };

      try {
        const putResponse = await services.put("/podcast/collectionView", body);
        if (putResponse.status === 200) {
          let newListToShow = selectedDetailModal.list_to_show;
          temp_episode = temp_episode.map((item) => {
            return {
              id: item.episode_id,
              name: item.episode_name,
              image: item.episode_image,
              mentor_name: item.mentors.map((mentor) => mentor.mentor_name),
            };
          });

          newListToShow = newListToShow.concat(temp_episode);
          updateDisplayData(newListToShow, "addEpisodes");
        }
      } catch (e) {
        window.alert("Error:", e.response.message);
      }
    } else if (selectedDetailModal.station_or_classification === "station" || selectedDetailModal.station_or_classification === "classification") {
      //FIXME: EXTRA GET REQUEST
      let temp_array = displayForm.filter((item) => item !== null);
      let backend_data = selectedDetailModal.list_to_show.map((item) => item.id);
      backend_data = backend_data.concat(temp_array);
      // console.log("BAckednd ", backend_data);
      let frontEndData = temp_array.map((item, i) => stationData.filter((ele) => ele.station_id === item)[0]);
      const body = {
        collection_id: selectedDetailModal.collection_id,
        content_type: selectedDetailModal.content_type,
        collection_name: selectedDetailModal.collection_name,
        station_or_classification: selectedDetailModal.station_or_classification,
        content_ids: backend_data.toString(),
        order_by: selectedDetailModal.order_by,
        is_active: selectedDetailModal.is_active,
      };
      try {
        const putResponse = await services.put("/podcast/collectionView", body);

        if (putResponse.status === 200) {
          //FRONTEND UPDATES
          let newListToShow = selectedDetailModal.list_to_show;
          frontEndData = frontEndData.map((item) => {
            return {
              id: item.station_id,
              name: item.name,
              image: item.image,
            };
          });

          newListToShow = newListToShow.concat(frontEndData);
          updateDisplayData(newListToShow, "addEpisodes");
          close();
        }
      } catch (err) {
        window.alert("Error:", err.response.message);
      }
    }
  };

  const handleImageUpload = (e) => {
    console.log("handlrImage", e.target.files[0].name);
    setEidtEpisodeForm((prev) => {
      return {
        ...prev,
        image: e.target.files[0].name,
      };
    });
    setSelectedImage(e.target.files[0]);
    setImageUpdated(true);
  };
  const handleVideoUpload = (e) => {
    setSelectedVideo(e.target.files[0]);
    setEidtEpisodeForm((prev) => {
      return {
        ...prev,
        video: e.target.files[0].name,
      };
    });
    console.log("video uploaded", e.target.files[0]);
    setVideoUpdated(true);
  };

  const handleAddStationToDisplay = (e, index) => {
    const { name, value } = e.target;
    let temp_array = displayForm;
    temp_array = temp_array.map((item, i) => {
      if (i === index) {
        return value;
      } else {
        return item;
      }
    });

    setDisplayForm(temp_array);
  };
  return (
    //Add episodes
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      {type === "station" && (
        <Box className="addEpisode_main-div" sx={{ width: "716px", borderRadius: "20px" }}>
          <Box sx={{ padding: "24px 86px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => close()}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography sx={{ fontSize: "32px", marginLeft: "110px" }}>Add Episode</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  Name<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField sx={{ width: "100%" }} name="name" value={addEpisodeForm.name} onChange={(e) => handleFormChange(e)} error={formErrors.name === "" ? false : true} helperText={formErrors.name} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  Mentor-1<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select fullWidth error={formErrors.mentor === "" ? false : true} helperText={formErrors.mentor} name="mentor_id" MenuProps={{ style: { height: "200px" } }} value={addEpisodeForm.mentor_id} onChange={(e) => handleFormChange(e)}>
                  {mentorDetails.map((mentor) => (
                    <MenuItem key={mentor} value={mentor.user_id}>
                      {`${mentor.first_name} ${mentor.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  About episode<span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField sx={{ width: "100%" }} multiline rows={4} name="about_episode" value={addEpisodeForm.about_episode} onChange={(e) => handleFormChange(e)} error={formErrors.about_episode === "" ? false : true} helperText={formErrors.about_episode} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  About mentor<span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField sx={{ width: "100%" }} multiline rows={4} name="about_mentor" value={addEpisodeForm.about_mentor} onChange={(e) => handleFormChange(e)} error={formErrors.about_mentor === "" ? false : true} helperText={formErrors.about_mentor} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Keywords</Typography>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  onChange={(e, value) =>
                    setAddEpisodeForm((prev) => {
                      return {
                        ...prev,
                        search_keywords: value,
                      };
                    })
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        // variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} error={formErrors.search_keywords === "" ? false : true} helperText={formErrors.search_keywords} />}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  {location.pathname === "/Podcast" ? "Audio" : "Recording"}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <Box
                  sx={{
                    border: "1px dashed #2AA8C4",
                    height: "65px",
                    padding: "9px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(56, 193, 223, 0.08)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      border: "none",
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                    for="station_video"
                    class="btn"
                  >
                    <CloudUploadOutlinedIcon
                      for="files"
                      style={{
                        width: "40px",
                        height: "40px",
                        color: "#2AA8C4",
                      }}
                    />{" "}
                    {editEpisodeForm.video !== "" ? editEpisodeForm.video : "Select a Video"}
                  </label>
                  <TextField
                    id="station_video"
                    type="file"
                    onChange={handleVideoUpload}
                    style={{
                      marginTop: "0",
                      visibility: "hidden",
                      width: "0%",
                    }}
                  />
                </Box>
              </Box>{" "}
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  Image<span style={{ color: "red" }}>*</span>
                </Typography>

                <Box
                  sx={{
                    border: "1px dashed #2AA8C4",
                    height: "65px",
                    padding: "9px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(56, 193, 223, 0.08)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      border: "none",
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                    for="station_image"
                    class="btn"
                  >
                    <CloudUploadOutlinedIcon
                      for="files"
                      style={{
                        width: "40px",
                        height: "40px",
                        color: "#2AA8C4",
                      }}
                    />{" "}
                    Select an Image
                  </label>
                  <TextField
                    id="station_image"
                    type="file"
                    onChange={handleImageUpload}
                    style={{
                      marginTop: "0",
                      visibility: "hidden",
                      width: "0%",
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} />
                <Typography>Link to</Typography>
              </Box>
              {checked && (
                <>
                  <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Activity</Typography>
                    <Select
                      fullWidth
                      name="activities"
                      multiple
                      value={addEpisodeForm.activities}
                      MenuProps={{ style: { height: "200px" } }}
                      onChange={(e) => handleFormChange(e)}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => {
                            console.log("Value", value);
                            let temp = activityList.find((item) => item.id === value);
                            return <Chip key={value} label={temp.act_type_name} />;
                          })}
                        </Box>
                      )}
                    >
                      {activityList.map((activity) => (
                        <MenuItem key={activity} value={activity.id}>
                          {`${activity.act_type_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>specialization</Typography>
                    <Select
                      fullWidth
                      name="specialization"
                      multiple
                      value={addEpisodeForm.specialization}
                      MenuProps={{ style: { height: "200px" } }}
                      onChange={(e) => handleFormChange(e)}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {specializationList.map((specialization) => (
                        <MenuItem key={specialization} value={specialization.name}>
                          {`${specialization.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Role</Typography>
                    <Select
                      fullWidth
                      name="roles"
                      multiple
                      value={addEpisodeForm.roles}
                      MenuProps={{ style: { height: "200px" } }}
                      onChange={(e) => handleFormChange(e)}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => {
                            let temp = roleList.find((item) => item.id === value);
                            return <Chip key={value} label={temp.name} />;
                          })}
                        </Box>
                      )}
                    >
                      {roleList.map((role) => (
                        <MenuItem key={role} value={role.id}>
                          {`${role.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Recomendation Keywords</Typography>
                    <Autocomplete
                      multiple
                      options={[]}
                      freeSolo
                      onChange={(e, value) =>
                        setAddEpisodeForm((prev) => {
                          return {
                            ...prev,
                            recommendation_keywords: value,
                          };
                        })
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            // variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </>
              )}
              <button className="addmodal__save-button" onClick={(e) => handleSubmitButton()}>
                Add to Episode
              </button>
            </Box>
          </Box>
        </Box>
      )}

      {type === "classification" && (
        <Box
          className="addEpisode_main-div"
          sx={{
            width: "716px",
            borderRadius: "20px",
            height: "fit-content",
          }}
        >
          <Box sx={{ padding: "26px 56px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {addEpisodeToClassification.map((item, index) => (
                <Box sx={{ display: "flex", gap: "2rem" }}>
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                      Select Station<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select fullWidth name="station_id" MenuProps={{ style: { height: "200px" } }} value={addEpisodeToClassification.station_id} onChange={(e) => handleClassificationSelection(e, index)}>
                      {stationData.map((station) => (
                        <MenuItem key={station} value={station.station_id}>
                          {`${station.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                      Select Episode<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select fullWidth name="episode_id" MenuProps={{ style: { height: "200px" } }} value={addEpisodeToClassification.episode_id} onChange={(e) => handleClassificationSelection(e, index)}>
                      {item.station_id !== null ? (
                        stationEpisodes[index].length > 0 ? (
                          stationEpisodes[index].map((episode) => (
                            <MenuItem key={episode} value={episode.episode_id}>
                              {`${episode.episode_name}`}
                            </MenuItem>
                          ))
                        ) : (
                          <Typography sx={{ paddingInline: "10px", fontSize: "16px" }}>No episode Available</Typography>
                        )
                      ) : (
                        <Typography sx={{ paddingInline: "10px", fontSize: "16px" }}>Please Select the Station</Typography>
                      )}
                    </Select>
                  </Box>
                </Box>
              ))}

              <Box>
                <button className="addmodal__save-button" onClick={(e) => handleSubmitClassification(e)}>
                  Add to Episode
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {type === "episode" && (
        <Box className="addEpisode_main-div" sx={{ width: "716px", borderRadius: "20px" }}>
          <Box sx={{ padding: "24px 86px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => close()}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography sx={{ fontSize: "32px", marginLeft: "110px" }}>Edit Episode</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  Name<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField sx={{ width: "100%" }} name="name" value={editEpisodeForm.name} onChange={(e) => handleEditEpisodeForm(e)} error={formErrors.name === "" ? false : true} helperText={formErrors.name} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  Mentor-1<span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <Select fullWidth error={formErrors.mentor === "" ? false : true} helperText={formErrors.mentor} name="mentor_id" MenuProps={{ style: { height: "200px" } }} value={editEpisodeForm.mentor_id} onChange={(e) => handleEditEpisodeForm(e)}>
                  {mentorDetails.map((mentor) => (
                    <MenuItem key={mentor} value={mentor.user_id}>
                      {`${mentor.first_name} ${mentor.last_name}`}
                    </MenuItem>
                  ))}
                </Select> */}
                {console.log(
                  "ANimeshhhh",
                  mentorDetails.find((item) => item.user_id === editEpisodeForm.mentor_id),
                  editEpisodeForm.mentor_id
                )}
                <Autocomplete
                  value={mentorDetails.find((item) => item.user_id === editEpisodeForm.mentor_id)}
                  onChange={(e, newValue) => handleEditEpisodeForm(e, newValue)}
                  fullWidth
                  autoComplete
                  getOptionLabel={(options) => `${options.first_name} ${options.last_name}`}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={mentorDetails}
                  sx={{ width: 300 }}
                  autoHighlight
                  clearIcon={null}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  About episode<span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField sx={{ width: "100%" }} multiline rows={4} name="about_episode" value={editEpisodeForm.about_episode} onChange={(e) => handleEditEpisodeForm(e)} error={formErrors.about_episode === "" ? false : true} helperText={formErrors.about_episode} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                  About mentor<span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField sx={{ width: "100%" }} multiline rows={4} name="about_mentor" value={editEpisodeForm.about_mentor} onChange={(e) => handleEditEpisodeForm(e)} error={formErrors.about_mentor === "" ? false : true} helperText={formErrors.about_mentor} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Keywords</Typography>
                <Autocomplete
                  multiple
                  options={editEpisodeForm.keywords}
                  freeSolo
                  error={formErrors.search_keywords === "" ? false : true}
                  value={editEpisodeForm.keywords}
                  onChange={(e, value) =>
                    setEidtEpisodeForm((prev) => {
                      return {
                        ...prev,
                        keywords: value,
                      };
                    })
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        // variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} error={formErrors.search_keywords === "" ? false : true} helperText={formErrors.search_keywords} />}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>{location.pathname === "/Podcast" ? "Audio" : "Recording"}</Typography>

                <Box
                  sx={{
                    border: "1px dashed #2AA8C4",
                    height: "65px",
                    padding: "9px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(56, 193, 223, 0.08)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      border: "none",
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                    for="episode_video"
                    class="btn"
                  >
                    <CloudUploadOutlinedIcon
                      for="files"
                      style={{
                        width: "40px",
                        height: "40px",
                        color: "#2AA8C4",
                      }}
                    />{" "}
                    {editEpisodeForm.video !== "" ? editEpisodeForm.name : "Select a Video"}
                  </label>
                  <TextField
                    id="episode_video"
                    type="file"
                    onChange={handleVideoUpload}
                    style={{
                      marginTop: "0",
                      visibility: "hidden",
                      width: "0%",
                    }}
                  />
                </Box>
              </Box>{" "}
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Image</Typography>

                <Box
                  sx={{
                    border: "1px dashed #2AA8C4",
                    height: "65px",
                    padding: "9px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(56, 193, 223, 0.08)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <label
                    style={{
                      border: "none",
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                    for="episode_image"
                    class="btn"
                  >
                    <CloudUploadOutlinedIcon
                      for="files"
                      style={{
                        width: "40px",
                        height: "40px",
                        color: "#2AA8C4",
                      }}
                    />

                    {editEpisodeForm.image !== "" ? editEpisodeForm.name : "Select a Image"}
                  </label>
                  <TextField
                    id="episode_image"
                    type="file"
                    onChange={handleImageUpload}
                    style={{
                      marginTop: "0",
                      visibility: "hidden",
                      width: "0%",
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Activity</Typography>
                <Select
                  fullWidth
                  name="activities"
                  multiple
                  value={editEpisodeForm.activities}
                  MenuProps={{ style: { height: "200px" } }}
                  onChange={(e) => handleEditEpisodeForm(e)}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        console.log("Value", value);
                        let temp = activityList.find((item) => item.id === value);
                        return <Chip key={value} label={temp.act_type_name} />;
                      })}
                    </Box>
                  )}
                >
                  {activityList.map((activity) => (
                    <MenuItem key={activity} value={activity.id}>
                      {`${activity.act_type_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>specialization</Typography>
                <Select
                  fullWidth
                  name="specialization"
                  multiple
                  value={editEpisodeForm.specialization}
                  MenuProps={{ style: { height: "200px" } }}
                  onChange={(e) => handleEditEpisodeForm(e)}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {specializationList.map((specialization) => (
                    <MenuItem key={specialization} value={specialization.name}>
                      {`${specialization.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Role</Typography>
                <Select
                  fullWidth
                  name="roles"
                  multiple
                  value={editEpisodeForm.roles}
                  MenuProps={{ style: { height: "200px" } }}
                  onChange={(e) => handleEditEpisodeForm(e)}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        let temp = roleList.find((item) => item.id === value);
                        return <Chip key={value} label={temp === undefined ? "" : temp.name} />;
                      })}
                    </Box>
                  )}
                >
                  {roleList.map((role) => (
                    <MenuItem key={role} value={role.id}>
                      {`${role.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Recomendation Keywords</Typography>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  value={editEpisodeForm.recommendation_keywords}
                  onChange={(e, value) =>
                    setAddEpisodeForm((prev) => {
                      return {
                        ...prev,
                        recommendation_keywords: value,
                      };
                    })
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        // variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <button className="addmodal__save-button" onClick={(e) => handleSubmitButton()}>
                Update Episode
              </button>
            </Box>
          </Box>
        </Box>
      )}

      {type === "display" ? (
        selectedDetailModal.station_or_classification === "episode" ? (
          <Box
            className="addEpisode_main-div"
            sx={{
              width: "716px",
              borderRadius: "20px",
              height: "fit-content",
            }}
          >
            {}
            <Box sx={{ padding: "26px 56px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {addEpisodeToClassification.map((item, index) => (
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <Box sx={{ width: "100%" }}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                        Select Station<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Select fullWidth name="station_id" MenuProps={{ style: { height: "200px" } }} value={addEpisodeToClassification.station_id} onChange={(e) => handleClassificationSelection(e, index)}>
                        {stationData.map((station) => (
                          <MenuItem key={station} value={station.station_id}>
                            {`${station.name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                        Select Episode<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Select fullWidth name="episode_id" MenuProps={{ style: { height: "200px" } }} value={addEpisodeToClassification.episode_id} onChange={(e) => handleClassificationSelection(e, index)}>
                        {item.station_id !== null ? (
                          stationEpisodes[index].length > 0 ? (
                            stationEpisodes[index].map((episode) => (
                              <MenuItem key={episode} value={episode.episode_id}>
                                {`${episode.episode_name}`}
                              </MenuItem>
                            ))
                          ) : (
                            <Typography sx={{ paddingInline: "10px", fontSize: "16px" }}>No episode Available</Typography>
                          )
                        ) : (
                          <Typography sx={{ paddingInline: "10px", fontSize: "16px" }}>Please Select the Station</Typography>
                        )}
                      </Select>
                    </Box>
                  </Box>
                ))}

                <Box>
                  <button className="addmodal__save-button" onClick={(e) => handleAddDisplay(e)}>
                    Add to Display
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            className="addEpisode_main-div"
            sx={{
              width: "716px",
              borderRadius: "20px",
              height: "fit-content",
            }}
          >
            {console.log("DISPLLAYTYPE", type)}
            <Box sx={{ padding: "26px 56px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {displayForm.map((item, index) => (
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <Box sx={{ width: "100%" }}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                        Select Station<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Select fullWidth name="station_id" MenuProps={{ style: { height: "200px" } }} value={addEpisodeToClassification.station_id} onChange={(e) => handleAddStationToDisplay(e, index)}>
                        {stationData.map((station) => (
                          <MenuItem key={station} value={station.station_id}>
                            {`${station.name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                ))}

                <Box>
                  <button className="addmodal__save-button" onClick={(e) => handleAddDisplay(e)}>
                    Add to Display
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      ) : (
        <></>
      )}
    </Box>
  );
}

export default AddEpisodeModal;
