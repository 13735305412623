import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, IconButton, Rating, TextField, Typography } from "@mui/material";
import { Color, DivCenter } from "../../../../GlobalStyles";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
import "../JobAllRequests/JobAllRequests.css";
import { SlideInRightModal } from "../AdditionalComponents/SlideInRightModal";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import TimeService from "../../../../functions/epochTime";
import { ellipsisFunction } from "../../../Reusable_functions/TextFunctions/EllipsisFunction";
import { ArrowBackIos } from "@mui/icons-material";
import { SmallPageLoader } from "../../../reusables/Loader/Loader";
import Papa from "papaparse";
const JobStudentApplicants = () => {
  const services = new CCService();
  const timeServices = new TimeService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ page_loading: false, small_page_loading: false });
  const initial_requestData = {
    user_id: null,
    first_name: "",
    last_name: "",
    college_name: "",
    assessment_details: [
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
    ],
  };

  const initial_assessment_details = {
    assessment_id: null,
    assessment_name: "",
  };

  const [allRequestData, setAllRequestData] = useState([{ ...initial_requestData }]);
  const [assessmentDetails, setAssessmentDetails] = useState([{ ...initial_assessment_details }]);
  console.log("allRequestData:", allRequestData);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { company_name, designation, job_type, role_name, specialization_name, openings } = location.state;
  const job_id = searchParams.get("job_id");
  console.log("job_id:", job_id);
  const [searchQuery, setSearchQuery] = useState("");

  //for filtering data
  const [backup_RequestData, setBackup_RequestData] = useState({ ...allRequestData });
  const [filterButtons, setFilterButtons] = useState({ Selected: false, "Not selected": false, Absent: false });

  // useEffect(() => {
  //     const handleUpdateAllRequestData = () => {
  //         const findTrueValue = (filterButtons) => {
  //             for (const key in filterButtons) {
  //                 if (filterButtons.hasOwnProperty(key) && filterButtons[key] === true) {
  //                     return key;
  //                 }
  //             }
  //             return null;
  //         }
  //         const which_one_is_true = findTrueValue(filterButtons);
  //         if (which_one_is_true) {
  //             setAllRequestData(() => ({
  //                 "List 1": backup_RequestData["List 1"].filter((data) => data.status_name === which_one_is_true),
  //                 "List 2": backup_RequestData["List 2"].filter((data) => data.status_name === which_one_is_true),
  //                 "List 3": backup_RequestData["List 3"].filter((data) => data.status_name === which_one_is_true),
  //                 "Extended List": backup_RequestData["Extended List"].filter((data) => data.status_name === which_one_is_true),
  //             }));
  //         } else {
  //             setAllRequestData(backup_RequestData);
  //         }
  //     }
  //     handleUpdateAllRequestData();
  // }, [filterButtons['Selected'], filterButtons['Not selected'], filterButtons['Absent']])

  useEffect(() => {
    const handleMainUseEffect = async () => {
      setLoading((prev) => ({ ...prev, small_page_loading: true }));
      const { temp_data, assessment } = await shortlistStudent_operations();

      setAllRequestData(temp_data);
      setBackup_RequestData(temp_data);
      setAssessmentDetails(assessment);
      setLoading((prev) => ({ ...prev, small_page_loading: false }));
    };
    handleMainUseEffect();
  }, []);

  const shortlistStudent_operations = async () => {
    const { data, assessment_details } = await fetchApplicantsbyJobId();
    let assessment = [...assessment_details];

    data.forEach((d) => {
      d.competency_score = (d.assessment_details.reduce((acc, assessment) => acc + assessment.assessment_score, 0) / d.assessment_details.length).toFixed(4);
    });

    let tempData = data.map((stu, index) => {
      let test_data = assessment_details.map((test, index) => {
        let testDetails = stu.assessment_details.find((item) => item.test_type_id === test.test_type_id);
        return [null, undefined].includes(testDetails)
          ? {
              no_of_test: null,
              test_type_id: null,
              assessment_name: null,
              assessment_score: null,
            }
          : {
              no_of_test: testDetails.no_of_test,
              test_type_id: testDetails.test_type_id,
              assessment_name: testDetails.assessment_name,
              assessment_score: testDetails.assessment_score,
            };
      });

      return {
        ...stu,
        assessment_details: test_data,
      };
    });

    console.log("TEST DATA", tempData);

    let temp_data = tempData.filter((item) => item.user_id);
    // temp_data = temp_data.map((item, index) => {
    //   return {
    //     ...item,
    //     is_shortlisted: ids.includes(item.user_id) ? 1 : 0,
    //     is_selected: false,
    //   };
    // });

    temp_data.sort((a, b) => b.competency_score - a.competency_score);
    return { temp_data, assessment };
  };

  const fetchApplicantsbyJobId = async () => {
    try {
      const response = await services.get(`/jobs/admin/jobUserScore?job_id=${job_id}`);

      if (response) return { data: response.data, assessment_details: response.assessment_details, shortlistedStudent: [] };
      else return [];
    } catch (error) {
      console.error("Error fetching applicants:", error);
    }
  };

  // const handleFilterButtonClick = (button) => {
  //     // let temp = JSON.parse(JSON.stringify(filterButtons));
  //     // setAllRequestData((prev) => ({ "New Requests": temp.new ? [] : prev["New Requests"], "On Hold": temp.on_hold ? [] : prev["On Hold"], "Recent": temp.recent ? [] : prev["Recent"] }));
  //     if (button === 'Selected') {
  //         setFilterButtons((prev) => ({ ...prev, 'Selected': !prev['Selected'], 'Not selected': false, 'Absent': false }));
  //     } else if (button === 'Not selected') {
  //         setFilterButtons((prev) => ({ ...prev, 'Selected': false, 'Not selected': !prev['Not selected'], 'Absent': false }));
  //     } else if (button === 'Absent') {
  //         setFilterButtons((prev) => ({ ...prev, 'Selected': false, 'Not selected': false, 'Absent': !prev['Absent'] }));
  //     }
  // }

  const downloadCSV = () => {
    const temp = allRequestData.map((item) => {
      let assessment_key = assessmentDetails.map((assessment) => {
        let test = item.assessment_details.find((item) => item.test_type_id === assessment.test_type_id);
        return {
          [assessment.assessment_name]: test === undefined ? "N/A" : `${test.assessment_score} (${test.no_of_test})`,
        };
      });
      const outputObject = assessment_key.reduce((result, currentObj) => {
        const key = Object.keys(currentObj)[0]; // Assuming each object has only one key
        const value = currentObj[key];
        result[key] = value;
        return result;
      }, {});

      return {
        [`Candidate Name`]: `${item.first_name} ${item.last_name}`,
        College: item.college,
        ...outputObject,
        Competency: item.competency_score,
        Resume: item.resume_urls,
      };
    });

    console.log("AssessmentKey", temp, allRequestData, assessmentDetails);
    const csvData = Papa.unparse(temp);
    console.log("CSV", temp, allRequestData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${company_name} Applicants.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {loading.small_page_loading ? (
        <SmallPageLoader show={loading.small_page_loading} />
      ) : (
        <Box className="main_box">
          <SideNavigationBar />
          <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
          <Box className="box__mainContent" sx={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", gap: "33px", alignItems: "flex-start" }}>
            <Box sx={{ ...DivCenter, mt: "10px", gap: "33px" }}>
              <IconButton onClick={() => navigate(-1)} style={{ height: "30px", width: "30px" }}>
                <ArrowBackIos className="Arrow-back-btn" />
              </IconButton>
              <Typography sx={{ fontSize: "32px", color: "black" }}>{company_name ?? "No Company Name"}</Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <TextField placeholder="Search by Student Name/College Name." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ width: "40%", borderRadius: "20px" }} InputProps={{ style: { height: "50px", borderRadius: "20px" } }} />
              <Button onClick={() => downloadCSV()}>Download CSV</Button>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box className="box__header">
                <Typography className="typography__header" style={{ width: `${100 / (assessmentDetails.length + 2)}%` }}>
                  Name
                </Typography>
                {assessmentDetails.map((assessment) => (
                  <Typography className="typography__header" style={{ width: `${100 / (assessmentDetails.length + 2)}%` }}>
                    {assessment.assessment_name}
                  </Typography>
                ))}
                <Typography className="typography__header" style={{ width: `${100 / (assessmentDetails.length + 2)}%` }}>
                  Competency
                </Typography>
              </Box>
              <hr style={{ color: Color.neutralMidGrey }} />
              <Box className="box__postings">
                <Box sx={{ width: "100%" }}>
                  {allRequestData.filter((stu) => stu.first_name?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) || stu.college?.toLowerCase().includes(searchQuery.toLocaleLowerCase())).length ? (
                    allRequestData.filter((stu) => stu.first_name?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) || stu.college?.toLowerCase().includes(searchQuery.toLocaleLowerCase()))[0].user_id ? (
                      allRequestData
                        .filter((stu) => stu.first_name?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) || stu.college?.toLowerCase().includes(searchQuery.toLocaleLowerCase()))
                        .map((request_row, index) => (
                          <Box key={index}>
                            <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-evenly", padding: "16px 0 16px 0" }}>
                              <Typography style={{ width: `${100 / (request_row.assessment_details.length + 2)}%`, textAlign: "center" }}>
                                <a href={request_row.resume_urls} target="_blank" style={{ textDecoration: "none" }}>
                                  <span style={{ color: Color.primary1 }}>{request_row.first_name + " " + request_row.last_name}</span>
                                </a>
                                <br />
                                <span style={{ color: Color.neutralMidGrey }}>{request_row.college ?? "N/A"}</span>
                              </Typography>
                              {request_row.assessment_details.map((assessment) => (
                                <Typography style={{ width: `${100 / (request_row.assessment_details.length + 2)}%`, textAlign: "center" }}>
                                  {![null, undefined].includes(assessment?.assessment_score) ? (
                                    <span>
                                      <span style={{ color: Color.neutralDarkGrey }}>{assessment.assessment_score}</span>
                                      <span style={{ color: Color.neutralMidGrey }}> ({assessment.no_of_test})</span>
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </Typography>
                              ))}
                              <Typography style={{ width: `${100 / (request_row.assessment_details.length + 2)}%`, textAlign: "center" }}>{request_row.competency_score}</Typography>
                            </Box>
                            <hr />
                          </Box>
                        ))
                    ) : (
                      <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>
                    )
                  ) : (
                    <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="box__fillerBox" sx={{ width: "8%" }}></Box>
        </Box>
      )}
    </>
  );
};

export default JobStudentApplicants;
