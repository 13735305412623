// CustomDialog.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomizedSmallDialogs = ({ open, onClose, title, content, actions, isFullScreen, fullWidth, maxWidth, backgroundColor, borderRadius }) => {
    return (

        <Dialog open={open} onClose={onClose} fullScreen={isFullScreen?true:false} fullWidth={fullWidth} maxWidth={maxWidth} PaperProps={{
            style: {
                width: '100%',
                borderRadius: borderRadius?borderRadius:"20px",
                backgroundColor: backgroundColor?backgroundColor:"#FFF"
            },
        }}>
            <DialogTitle>{title}
                <Button
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                    onClick={onClose}
                    color="inherit"
                    aria-label="close"
                >
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                {content}</DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    {actions.map((action, index) => (
                        <Button key={index}
                            onClick={action.handler}
                            // color={action.color}
                            variant={action.variant}
                            style={{ width: "49%", height: "45px", background: action.variant === "contained" ? action.color : "transparent", color: action.variant === "contained" ? "#FFF" : action.color, borderColor: action.color, borderRadius: "10px" }}
                        >
                            {action.label}
                        </Button>
                    ))}
                </Box>
            </DialogActions>
        </Dialog>

    );
};


export default CustomizedSmallDialogs;
