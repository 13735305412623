import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import EnhancedTable from '../../reusables/TableComponent/TableComponent'
import Service from '../../../services/httpService'
import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext'
import { saveAs } from "file-saver";
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../../reusables/Loader/Loader";
const Role = () => {
  const navigate = useNavigate();

  const service = new Service()
  const [rowsData, setRowsData] = useState([{ specialization_id: null, name: "", id: null, default_jd: "" }])
  const [spec, setSpec] = useState([{ id: null, name: "" }])
  const [jdData, setJdData] = useState([{ id: null, name: "" }])
  const [big_data, setBig_data] = useState();
  let count = 0;

  //get current date and time
  const currentDate = new Date();
  const [loading, setLoading] = useState(true);

  //headCells
  const headCells = [
    {
      id: 'heading1',
      numeric: 'left',
      disablePadding: false,
      label: 'Role Name'
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Specialization Name'
    },
    {
      id: 'heading3',
      numeric: 'right',
      disablePadding: false,
      label: 'JD'
    },
  ];

  const handleDeleteMul = (id, index) => {
    console.log("delete", id)
    let list = [...rowsData];

    console.log("listtttt1", list);


    console.log('hey bhooms')
    service
      .delete("/role", {
        data: { "ids": id }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list = list.filter((item) => id.indexOf(item.id) < 0);
        setRowsData(list)
        console.log("listtttt2", list);

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);

        console.log(err.response.data.message)
        window.alert(err.response.data.message)

      });
  }

  // const data_getter = (name, id, index, specialization_id) => {
  const data_getter = (name, id, index, specialization_id, default_jd) => {
    // console.log("Dude here's your object:", data);
    console.log("Dude here's your name:", name);
    console.log("Dude here's your id:", id);
    console.log("Dude here's your index:", index);
    console.log("Dude here's your special:", specialization_id);
    console.log("Dude here's your default_jd:", default_jd);

    if (name === "delete_mul") {
      handleDeleteMul(id, index)
    }
    else if (name !== undefined && name !== null && (id === undefined || id === null || id === 0)) {
      handleFormSubmission(name, specialization_id, default_jd);
    } else if (name !== undefined && name !== null && name !== "" && id !== undefined && id !== null && id !== 0) {
      handlePutRequest(name, id, index, specialization_id, default_jd);
    } else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
      handleDelete(id, index);
    }
  }


  //get and prepopulating the data from the backend to the front-end table
  useEffect(() => {
    function handleEffect() {
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      service
        .get('/role?is_admin=1')
        .then((res) => {
          console.log("Role data:", res.data)
          setRowsData(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
        })
      service
        .get('/specialization?is_admin=1')
        .then((res) => {
          console.log("Specialization data:", res.data)
          setSpec(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
      service
        .get('/job_desc')
        .then((res) => {
          console.log("JD data:", res.data)
          let temp_jd = JSON.parse(JSON.stringify(res.data))
          temp_jd = temp_jd.map(item => ({
            id: item.id,
            name: item.company_name
          }))
          console.log("jd data reformed:", temp_jd)
          setJdData(temp_jd)
        })
        .catch((err) => {
          console.log(err)
        })
      // let data = rowsData.map((row) =>{
      //   setRowsData([...rowsData, {id: row.id, name: row.name, specialization_id ==== }])
      // })

    }
    handleEffect()
  }, [])

  // post request
  const handleFormSubmission = async (user_type_name, specialization_id, default_jd) => {
    let temp_rows = [...rowsData];
    console.log("User TYpe id not found, sending teh post request", temp_rows);

    let body = {};
    if (default_jd !== null)
      body = {
        name: user_type_name,
        specialization_id: specialization_id,
        default_jd: default_jd
      }
    else
      body = {
        name: user_type_name,
        specialization_id: specialization_id,
      }


    service
      .post("/role", body)
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        setRowsData([{ id: res.data.id, name: user_type_name, specialization_id: specialization_id, default_jd: default_jd }, ...rowsData])
        console.log("response id is:", res.data.id);
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        console.log(err.response.data.message)
        window.alert(err.response.data.message)
      });
  }

  const handlePutRequest = async (user_type_name, user_type_id, index, specialization_id, default_jd) => {
    let body = {};
    if (default_jd !== null)
      body = {
        id: user_type_id,
        name: user_type_name,
        specialization_id: specialization_id,
        default_jd: default_jd
      }
    else
      body = {
        id: user_type_id,
        name: user_type_name,
        specialization_id: specialization_id,
      }


    service
      .put("/role", body)
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = [...rowsData]
        temp[index].name = user_type_name
        temp[index].specialization_id = specialization_id
        temp[index].default_jd = default_jd
        setRowsData(temp)
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        console.log(err.response.data.message)
        window.alert(err.response.data.message)
      });
  }

  // delete request
  const handleDelete = async (user_type_id, index) => {
    console.log("delete", user_type_id)
    let list = [...rowsData];
    console.log("listtttt1", list);
    console.log('hey bhooms')

    service
      .delete("/role", {
        data: { "ids": [user_type_id] }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list.splice(index, 1);
        setRowsData(list)
        console.log("listtttt2", list);
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        console.log(err.response.data.message)
        window.alert(err.response.data.message)
      });
  }

  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is:  ", rowsData);
    // Simulate a button click after 2 seconds
    const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


  }

  return (
    <>
      <RowsContext.Provider value={rowsData}>
        <TopBar />
        <SideNavigationBar />
        {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
          <EnhancedTable headCells={headCells} data_getter={data_getter} fileDownload={fileDownload} spec_data={spec} jd_data={jdData} path={window.location.pathname} />

        </>}
      </RowsContext.Provider>
    </>
  )
}

export default Role