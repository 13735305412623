import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import '../OngoingEvents/OngoingEvents.css'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Service from "../../../services/httpService";
import ActivatedStatus from '../ActivatedStatus/ActivatedStatus'
import Modal from "react-bootstrap/Modal";
import CloseIcon from '@mui/icons-material/Close';
import Multiselect from 'multiselect-react-dropdown';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CollegeData from "../../../CollegeDataServices/httpCollege";
export default function UpcomingPrograms(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const collegeData = new CollegeData()
  // const service = new Service()
  const { college_id, college_name } = props
  console.log("college_name", college_name);
  // let college_id = location.state?.college_id
  // let college_name = location.state?.college_name || ""
  const [BatchData, setBatchData] = useState([])
  let college_data_id = location.state?.college_id
  let college_data_name = location.state?.college_name || ""
  const [loading, setLoading] = useState(true);
  const [specializationData, setSpecializationData] = useState([])
  const [specialization_ids, setSpecialization_ids] = useState([])
  const service = new Service()
  const [show, setShow] = useState(false)
  const [rowsData, setRowsData] = useState([{ id: null, user_group_name: "", acad_year: "", program_type_id: null, batch_type_id: null, college_id: "", specializations_allowed: [] }])
  const [selectedBatchIndex, setSelectedBatchIndex] = useState(null);

  //from table index
  const [from_table, setFrom_table] = useState();

  // getter of batch and program
  const [roleData, setRoleData] = useState([])
  const [batchTypeData, setbatchTypeData] = useState([])
  const [programData, setProgramData] = useState([])

  // data manipulator of batch and program
  const [role, setRole] = useState()
  const [batchType, setBatchType] = useState()
  const [program, setProgram] = useState()

  // ids of batch and program
  const [jd_select_id, setJd_select_id] = useState();
  const [jd_batch_id, setJd_batch_id] = useState();
  const [jd_program_id, setJd_program_id] = useState();

  const [jd_input_name, setJd_input_name] = useState(BatchData[from_table?.index]?.user_group_name);

  const [batch_select_data, setBatch_select_data] = useState()
  const [program_select_data, setProgram_select_data] = useState()
  let count = 0;

  const currentYear = new Date().getFullYear();
  const isAfterAugust = new Date().getMonth() >= 8;
  const firstAcademicYear = 2021;
  const academicYear =
    currentYear >= firstAcademicYear &&
      (currentYear > firstAcademicYear || isAfterAugust)
      ? `${currentYear}-${(currentYear + 1).toString().slice(2)}`
      : `${currentYear - 1}-${currentYear.toString().slice(2)}`;
  const previousYear = `${currentYear - 1}-${currentYear.toString().slice(2)}`;
  const nextYear = `${currentYear + 1}-${(currentYear + 2).toString().slice(2)}`;
  const options =
    currentYear >= firstAcademicYear && isAfterAugust
      ? [academicYear, nextYear, `${currentYear + 2}-${(currentYear + 3).toString().slice(2)}`]
      : [
        `${currentYear - 1}-${currentYear.toString().slice(2)}`,
        `${currentYear}-${(currentYear + 1).toString().slice(2)}`,
        `${currentYear + 1}-${(currentYear + 2).toString().slice(2)}`,
      ];

  //activity
  const [activity, setActivity] = useState("");

  const [selectedYear, setSelectedYear] = useState(BatchData[from_table?.index]?.acad_year);
  //get current date and time
  const currentDate = new Date();
  const [specialization_select_data, setSpecialization_select_data] = useState([])
  const batchGetter = async () => {

    try {
      const res = await service.get('/batchType')
      console.log("Response from backend is11:", res.data)
      setbatchTypeData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }
  const programGetter = async () => {
    try {
      const res = await service.get('/programType')
      console.log("Response from backend is:", res.data)
      setProgramData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  // specialization
  const specializationGetter = async () => {
    try {
      const res = await service.get('/specialization?is_admin=1')
      console.log("Response from backend for specialization:", res.data)
      let temp = res.data;
      temp = temp.map(bat => {
        bat.key = bat.name;
        bat.id = bat.id;

        delete bat.name;
        return bat;
      });
      setSpecializationData(temp)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }



  useEffect(() => {
    if (selectedBatchIndex === null) {
      const college = BatchData[selectedBatchIndex];
      console.log("Inside if of useEffect")
      setJd_input_name()
      setSelectedYear()
      setJd_batch_id()
      setJd_program_id()
      setBatch_select_data([])
      setSpecialization_ids([])
      setSpecialization_select_data()
      console.log("specializationData", specializationData);
      setSpecializationData(specializationData)
    } else {
      const batchInfo = BatchData[selectedBatchIndex];
      setJd_input_name(batchInfo.user_group_name);
      setSelectedYear(batchInfo.acad_year);
      setJd_program_id(batchInfo.program_type_id);
      setJd_batch_id(batchInfo.batch_type_id);
      const temp_ids = typeof batchInfo.specializations_allowed === 'string' ? JSON.parse(batchInfo.specializations_allowed) : batchInfo.specializations_allowed
      // Assuming batchInfo.specializations_allowed contains the data for specialization
      const find_batch = specializationData.filter((ele) => temp_ids?.ids ? temp_ids?.ids?.includes(ele.id) : temp_ids?.includes(ele.id));
      console.log("find batch", find_batch);
      setSpecialization_select_data(find_batch)
    }
  }, [selectedBatchIndex, BatchData]);


  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {

      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //batch data getter
      // const batchData = await batchDataGetter();
      const batchData = await batchDataGetter();
      const additional_batch_data = await batchAdditionalDataGetter()
      // const matchingBatchData = batchData
      batchData.forEach((item) => {
        const matchingBatchData = additional_batch_data.find((filter) => filter.program_id === item.id)
        if (matchingBatchData) {

          item.activated_students = matchingBatchData.activated_students
          item.non_activated_students = matchingBatchData.non_activated_students
        }
      })
      console.log("batchData",batchData);
      setBatchData(batchData)
      //batch getter 
      const batch = await batchGetter();
      //program getter 
      const program = await programGetter();
      // specialization
      const specialization = await specializationGetter();
      // forming the data
      console.log("odonil", { batchData }, { role }, { batch }, { program }, { specialization })
      // data_manupulator_jd(batchData, role, batch, program);
      data_manupulator_batch(batch);
      data_manupulator_program(program);
      console.log("odonil", { batchData })
    }

    handleEffect();
  }, [])

  const handleEditModal = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBatchIndex(index); // Set the selected batch index
    setActivity("edit")
    // Set the modal form fields based on the selected batch
    if (index !== null) {
      const batchInfo = BatchData[index];
      console.log("batch info", batchInfo);
      setJd_input_name(batchInfo.user_group_name);
      setSelectedYear(batchInfo.acad_year);
      setJd_program_id(batchInfo.program_type_id);
      setJd_batch_id(batchInfo.batch_type_id);
      // Assuming batchInfo.specializations_allowed contains the data for specialization
      const temp_ids = typeof batchInfo.specializations_allowed === 'string' ? JSON.parse(batchInfo.specializations_allowed) : batchInfo.specializations_allowed
      // setSpecialization_select_data(temp_ids);
      const find_batch = specializationData.filter((ele) => temp_ids?.ids ? temp_ids?.ids?.includes(ele.id) : temp_ids?.includes(ele.id));
      console.log("find batch", find_batch);
      setSpecialization_select_data(find_batch)
      setSpecializationData(specializationData)
    } else {
      // Reset the modal form fields if index is null
      setJd_input_name("");
      setSelectedYear("");
      setJd_program_id(null);
      setJd_batch_id(null);
      setSpecialization_select_data([]);
    }

    setShow(true);
  }


  const batchDataGetter = async () => {
    try {
      const res = await collegeData.get(`/userGroup/byCollege?collegeId=${college_id}`)
      console.log("1x1 data RESPONSE:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }
  const batchAdditionalDataGetter = async () => {
    try {
      const res = await service.get(`/programs?college_id=${college_id}`)
      console.log("1x1 data RESPONSE:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }
  console.log("batch data", BatchData);

  const handle1x1BatchDetails = (batchName, batchId) => {
    navigate("/College1x1BatchDetails?batchId=" + batchId, { state: { college_id: college_id, college_name: college_name, batchName: batchName, batchId: batchId } })
  }
  const handleActivatedStatusDetails = (batchName, batchId) => {
    navigate(`${"/StudentData?user_group_id=" + batchId}`, { state: { college_id: college_id, college_name: college_name, batch_name: batchName, batch_id: batchId } })
  }

  const handleProcessDataPage = (id, name) => {
    console.log("rows in Batch data page is", { id, name, college_data_name });
    navigate(`${"/ProcessData?user_group_id=" + id}`, { state: { batch_id: id, batch_name: name, college_name: college_data_name, } })
  }

  const handleStudentDataPage = (id, name) => {
    console.log("rows in Batch data page is", { id, name, college_data_name });
    navigate(`${"/StudentData?user_group_id=" + id}`, { state: { batch_id: id, batch_name: name, college_name: college_data_name, } })
  }

  const data_manupulator_batch = (batch) => {

    let temp = batch;
    temp = temp.map(bat => {
      bat.key = bat.name;
      bat.id = bat.id;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setBatchType(temp)
  }

  console.log("batch key", batchType);
  const data_manupulator_program = (program) => {

    let temp = program;
    temp = temp.map(pro => {
      pro.key = pro.name;
      pro.id = pro.id;
      return pro;
    });
    console.log("temp rows program:", temp)
    setProgram(temp)
  }

  const modalSubmit = async () => {
    console.log("Just submitted:", jd_input_name, jd_select_id, jd_batch_id, jd_program_id)
    let temp_jdData
    if (activity === "add")
      temp_jdData = await handleFormSubmission(jd_input_name, selectedYear, jd_program_id, jd_batch_id, college_id, specialization_ids);
    else {
      const temp_program_data = BatchData[selectedBatchIndex]
      temp_jdData = await handlePutRequest(temp_program_data.id, jd_input_name, selectedYear, jd_program_id, jd_batch_id, college_id, specialization_ids);

    }
    console.log({ temp_jdData })
    setShow(false)
  }

  const onHide = () => {
    setShow(false)
    setJd_input_name()
    setSelectedYear()
    setJd_batch_id()
    setJd_program_id()
  }

  const handleChange = (e, index) => {
    console.log("name from form  is:", e.target.name);
    console.log("value from form  is:", e.target.value);

    if (e.target.name === "jd-input") {
      setJd_input_name(e.target.value)
      console.log("add-specialization-input")
    }

    else if (e.target.name === "batch-select") {
      setJd_batch_id(e.target.value)
      console.log("batch selected id", e.target.value)

    }
    else if (e.target.name === "program-select") {
      setJd_program_id(e.target.value)
      console.log("program selected id", e.target.value)
    }
    else if (e.target.name === "academicYear") {
      setSelectedYear(e.target.value);
    }

  }

  const onSelect_specialization = (e) => {
    console.log("Added an item to multi select - batch -", e)
    setSpecialization_select_data(e);
    let temp = JSON.parse(JSON.stringify(e));
    temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
    setSpecialization_ids(temp)
    console.log("temp inside onSelect:", temp)
  }

  // multiselect on remove method
  const onRemove_specialization = (e) => {
    console.log("Removed an item to multi select")
    setSpecialization_select_data(e);
    let temp = JSON.parse(JSON.stringify(e));
    temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
    //   data.id = data.id;
    //   return data;
    // });
    setSpecialization_ids(temp)
    console.log("temp inside onSelect:", temp)
  }



  // post request
  const handleFormSubmission = async (user_group_name, acad_year, program_type_id, batch_type_id, college_id) => {
    let temp_rows = [...rowsData];
    let temp_batchData = JSON.parse(JSON.stringify(BatchData));
    let data = {
      user_group_name: user_group_name,
      acad_year: acad_year,
      program_type_id: parseInt(program_type_id),
      batch_type_id: parseInt(batch_type_id),
      college_id: college_id,
      specializations_allowed: specialization_ids
    }

    console.log("User TYpe id not found, sending teh post request", temp_rows);
    await collegeData
      .post("/userGroup", data)
      .then((res) => {
        // const res = collegeData.post("/userGroup", data)
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        console.log(res)
        console.log(res?.data)
        let temp = JSON.parse(JSON.stringify(rowsData));
        let temp_program = programData;
        let temp_batch = batchType

        temp = ([{
          id: res?.data?.id,
          user_group_name: user_group_name,
          acad_year: acad_year,
          program_type_id: parseInt(jd_program_id),
          batch_type_id: parseInt(jd_batch_id),
          college_id: college_id,
          specializations_allowed: specialization_ids
        }, ...temp])
        console.log(".then ~ temp", temp)
        setRowsData(temp)


        temp_batchData = ([{
          id: res?.data?.id,
          user_group_name: user_group_name,
          acad_year: acad_year,
          program_type_id: parseInt(jd_program_id),
          batch_type_id: parseInt(jd_batch_id),
          college_id: college_id,
          specializations_allowed: specialization_ids
        }, ...temp_batchData])

        setBatchData(temp_batchData)
        console.log("batch data right now", temp_batchData);
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
    return temp_batchData
  }

  const handlePutRequest = async (id, jd_input_name, selectedYear, jd_program_id, jd_batch_id, college_id, specialization_ids) => {
    // Get the current batch data
    const temp_batchData = { ...BatchData[selectedBatchIndex] };

    try {
      // Perform the PUT request
      const res = await collegeData.put("/userGroup", {
        id: id,
        user_group_name: jd_input_name,
        acad_year: selectedYear,
        program_type_id: parseInt(jd_program_id),
        batch_type_id: parseInt(jd_batch_id),
        college_id: college_id,
        specializations_allowed: specialization_ids,
      });

      console.log("Response from backend is", JSON.stringify(res, null, 2));

      // Update rowsData state
      let updatedRowsData = [
        {
          id: res?.data?.id,
          user_group_name: jd_input_name,
          acad_year: selectedYear,
          program_type_id: parseInt(jd_program_id),
          batch_type_id: parseInt(jd_batch_id),
          college_id: college_id,
          specializations_allowed: specialization_ids,
        },
        ...rowsData,
      ];
      setRowsData(updatedRowsData);

      // Update the temp_batchData
      temp_batchData.user_group_name = jd_input_name;
      temp_batchData.acad_year = selectedYear;
      temp_batchData.program_type_id = parseInt(jd_program_id);
      temp_batchData.batch_type_id = parseInt(jd_batch_id);
      temp_batchData.college_id = college_id;
      temp_batchData.specializations_allowed = specialization_ids;

      // Update the BatchData state
      let updatedBatchData = [...BatchData];
      updatedBatchData[selectedBatchIndex] = temp_batchData;
      setBatchData(updatedBatchData);

      return temp_batchData;
    } catch (err) {
      console.log("Error Msg: ", err);
      window.alert(err.response.data.message)
    }
  };

  const handleAddModal = (e, index) => {
    if (index === null) {
      setJd_input_name()
      setSelectedYear()
      setJd_batch_id()
      setJd_program_id()
      setBatch_select_data([])
      setSpecialization_ids([])
      setSpecialization_select_data()
      console.log("specializationData", specializationData);
      setSpecializationData(specializationData)
      setActivity("add")
      let temp = JSON.parse(JSON.stringify(rowsData));
      temp = ([{
        id: null, user_group_name: "", acad_year: "", program_type_id: null, batch_type_id: null, college_id: "", specializations_allowed: []
      }, ...temp])
      console.log(".then ~ temp", temp)
      setRowsData(temp)
    }
    e.preventDefault();
    e.stopPropagation();
    // setSelectedCollegeIndex(index);
    setShow(true);

  };

  return (
    <div className='OngoingEventsCard' style={{ marginTop: "2rem" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "85%" }}>
        <Typography style={{ marginBottom: "2rem" }} sx={{ fontSize: 20 }}>Programs</Typography>
        <AddIcon onClick={(e) => handleAddModal(e, null)} sx={{
          height: '30px',
          borderRadius: "100%",
          width: '30px',
          padding: '5px',
          opacity: '50%', alignItems: "center", justifyContent: "center",
          ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
        }} />
      </Box>
      <div className="cardsInsideFlex">
        {BatchData.map((batchInfo, i) => {
          return (
            <Box key={i} sx={{ width: 255, marginRight: '1rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px" }}>
              <CardContent className="cardContainer" disableGutters>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontSize: 20 }} gutterBottom>
                    {batchInfo?.user_group_name}
                  </Typography>
                  <EditIcon
                    onClick={(e) => handleEditModal(e, i)}
                    sx={{
                      color: '#BFBFBF',
                      height: '30px',
                      borderRadius: "100%",
                      width: '30px',
                      padding: '5px',
                      opacity: '50%',
                      ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
                    }}
                  />
                </Box>
                <Typography variant="h5" component="div"></Typography>
                <div className="container">
                  <Typography variant="body2" color={Color.primary1}>Activated Students:</Typography>
                  <Typography variant="body2" class="providingSpaceForSubDetails">{batchInfo?.activated_students ? batchInfo?.activated_students : 0}</Typography>
                </div>
                <div className="container">
                  <Typography variant="body2" color={Color.primary1}>Not Activated Students:</Typography>
                  <Typography variant="body2" class="providingSpaceForSubDetails">{batchInfo?.non_activated_students ? batchInfo?.non_activated_students : 0}</Typography>
                </div>
                <CardActions sx={{ paddingBottom: "0px" }}>
                  <button
                    className="submitBtn"
                    type="button"
                    style={{ height: "44px" }}
                    onClick={() => handle1x1BatchDetails(batchInfo?.user_group_name, batchInfo?.id)}
                  >
                    View Activities
                  </button>

                </CardActions>
                <CardActions sx={{ paddingBottom: "0px" }}>
                  <button
                    className="submitBtn"
                    type="button"
                    style={{ height: "44px" }}
                    // onClick={() => handle1x1BatchDetails(batchInfo?.program_name, batchInfo?.id)}
                    onClick={() => handleActivatedStatusDetails(batchInfo?.user_group_name, batchInfo?.id)}
                  >
                    Students
                  </button>

                </CardActions>

              </CardContent>
              <Modal
                show={show}
                onHide={() => setShow(false)}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "80%", maxWidth: "95%", minHeight: "80%", maxHeight: "95%" }}
              >
                <Modal.Body style={{
                  position: 'absolute',
                  left: '60%',
                  height: 'auto',
                  transform: 'translate(-50%, -50%)',
                  lineHeight: '1.4',
                  padding: '14px 28px',
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  maxWidth: '95%',
                  minWidth: '500px',
                  minHeight: '400px'
                }}>
                  <form className="Entire-form" style={{ justifyContent: 'center' }}>
                    {/* right side components */}
                    <div className='row full-div' >
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                        <p className="compTitle" style={{ fontSize: "32px", textAlign: "center", background: 'transparent' }}>Batch/User Group</p>
                      </div>

                      <div className="form-group">
                        <label className="inputLabel">Batch Name<span>*</span></label>
                        <input name="jd-input" type="text" className="form-control" placeholder="Example input" value={jd_input_name} onChange={(e) => handleChange(e)} />
                      </div>
                      <div className="form-group">
                        <label className="inputLabel" htmlFor="academicYear">Select academic year:</label>
                        <select id="academicYear" name="academicYear" className="form-control inputFields" value={selectedYear} onChange={(e) => handleChange(e)}>
                          <option className="options-value" value={""} disabled selected>Select academic year</option>
                          {options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group ">
                        <label className="inputLabel">Program Type<span>*</span></label>
                        {/* <input type="text" className="form-control inputFields" placeholder="Example input" /> */}
                        <select name="program-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={jd_program_id}>
                          <option className="options-value" value={""} disabled selected>Select Program Type</option>
                          {program?.map((pro, i) => (
                            <option key={i} value={pro.id}>{pro.key}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group ">
                        <label className="inputLabel">Batch Type<span>*</span></label>
                        <select name="batch-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={jd_batch_id}>
                          <option className="options-value" value={""} disabled selected>Select Batch Type</option>
                          {batchType?.map((bat, i) => (
                            <option key={i} value={bat.id}>{bat.key}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="inputLabel">Specialization Type<span>*</span></label>
                        <Multiselect
                          displayValue="key"
                          selectedValues={specialization_select_data}
                          onRemove={(e) => onRemove_specialization(e)}
                          onSelect={(e) => { onSelect_specialization(e) }}
                          options={specializationData}
                        />
                      </div>
                      <div className="form-group ">
                        <div className=" btn-comp " style={{ marginTop: "4%" }}>
                          <button type="button" onClick={modalSubmit} className="btn submit-btn">Submit</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </Box>)
        })}
      </div>
    </div>
  )
}