import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, Modal, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Color, DivCenter, modalBoxStyle, styles } from '../../../../GlobalStyles'
import { AddOutlined, AttachFile, Clear, Close, DateRange, DeleteOutlined, Done, EditOutlined, FilePresent, FilePresentOutlined, FilePresentSharp, FilePresentTwoTone, GetApp, LinkOff, MarkChatUnreadOutlined, Search, UploadFile } from '@mui/icons-material'
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import { DeadlineTimeCalendarModal } from '../../../Reusable_functions/DeadlineTimeCalendarModal/DeadlineTimeCalendarModal'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { ActivityLoader } from '../../../reusables/Loader/Loader'
import PrimaryTextfield from '../../../reusables/Components/Components'
import { SearchFunction } from '../../../Reusable_functions/TextFunctions/SearchFunction'
import '../StudentSummary/StudentSummary.css'
import Cookies from 'js-cookie'
import axios from 'axios'
import { scrollToTargetId } from '../../../Reusable_functions/ScrollToTargetId/ScrollToTargetId'
import { DialogueBox } from '../../../Reusable_functions/DialogueBox/DialogueBox'
const service = new CCService();


export const LaunchStudentAvailabilityPage1 = ({ process_id, onStepChange, student_availability_deadline, setMentorInterestData }) => {
    //MODALS
    //set deadline modal
    const [dialogue, setDialogue] = useState({
        open: false,
        actions: [
            { label: 'OK', handler: () => { dialogue.onClose() }, color: Color.white, variant: "contained" },
        ],
        title: 'Confirmation',
        content: `Deadline updated sucessfully`,
    });
    const [deadlineModalData, setDeadlineModalData] = useState({
        open: false,
        onClose: () => setDeadlineModalData((prev) => ({ ...prev, open: false })),
        value: student_availability_deadline ?? null,
        extraData: {
            heading: "",
            action_button: "",
            handleAction: {}
        }
    });


    //FUNCTIONS
    const handleDeadlineCalendarModal = () => {
        setDeadlineModalData((prev) => ({ ...prev, open: true }));
    }

    const handleDeadlineTimeCalendarSave = async (value, time) => {
        console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time)
        const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
        const epochTime = date.getTime()
        console.log("handleDeadlineTimeCalendarSave ~ epochTime:", epochTime, date.getHours());
        setDeadlineModalData((prev) => ({ ...prev, open: false, value: epochTime, }));
    }
    const handleStudentDeadlineSubmit = async () => {
        try {
            await service.put(`os/superadmin/properties`, {
                process_id: process_id,
                student_availability_deadline: deadlineModalData.value
            });
            setMentorInterestData((prev) => ({ ...prev, student_availability_deadline: deadlineModalData.value }))
            onStepChange(null, null, 1);
            setDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { label: 'OK', handler: () => { dialogue.onClose() }, color: Color.white, variant: "contained" },
                ],
                title: 'Confirmation',
                content: `Deadline updated sucessfully`,
            }))
        } catch (error) {
            console.error("Error putting student deadline- properties:", error);
        }
    }


    return (
        <Box sx={{ ...styles.page, width: '100%', p: '20px 90px', gap: '32px', }}>
            <Box sx={{ ...DivCenter, flexDirection: 'column', gap: '16px', }}>
                <Typography style={{ width: '100%', fontSize: '24px', fontWeight: 500 }}>Launch Student Availability</Typography>
                <Typography style={{ width: '100%', fontSize: '18px', }}>Set deadline for students to upload their availability<span style={{ color: Color.red }}>*</span></Typography>
            </Box>
            <Box sx={{ ...DivCenter, gap: '16px', width: "100%" }}>
                <Box
                    onClick={() => deadlineModalData.value ? {} : handleDeadlineCalendarModal()}
                    sx={{
                        background: Color.white,
                        display: 'flex',
                        alignItems: 'center',
                        border: `1px solid ${Color.neutralMidGrey}`, // Set background color
                        borderRadius: 4, // Add border radius
                        padding: '8px', // Add padding
                        cursor: deadlineModalData.value ? 'default' : 'pointer', // Simulate clickable behavior
                        width: '80%'
                    }}>
                    <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', flexGrow: 1, color: Color.neutralMidGrey, gap: '8px', flexWrap: 'wrap' }}>
                        {deadlineModalData.value ?
                            <>
                                <Box onClick={(e) => e.stopPropagation()} sx={{ ...DivCenter, justifyContent: 'space-between', p: '8px 12px', cursor: 'default', width: '15%', minWidth: '220px', height: '45px', borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.neutralMidGrey }}>
                                    <Close
                                        sx={{ fontSize: '16px', cursor: 'pointer' }}
                                        onClick={() => setDeadlineModalData((prev) => ({ ...prev, value: null }))} />
                                    {formatConvertermonthfirst(deadlineModalData.value, true)}
                                </Box>
                                {/* <Typography style={{ fontSize: '14px', color: Color.neutralMidGrey, p: '0 5px' }}>Specify the deadline</Typography> */}
                            </>
                            :
                            "Specify the deadline"
                        }
                    </Box>
                    <IconButton aria-label="Pick Date"
                        disabled={deadlineModalData.value}
                        onClick={() => {
                            handleDeadlineCalendarModal()
                        }}>
                        <DateRange />
                    </IconButton>
                </Box>
                <Button
                    onClick={() => handleStudentDeadlineSubmit()}
                    sx={{ ...styles.button_styles.contained, width: '20%', minWidth: '200px', height: '55px', fontSize: '20px' }}>Submit</Button>
            </Box>
            {dialogue.open && <DialogueBox
                open={dialogue.open}
                onClose={dialogue.onClose}
                title={dialogue.title}
                actions={dialogue.actions}
                content={<>
                    <p>{dialogue.content}</p>
                </>}
            />}
            <DeadlineTimeCalendarModal
                open={deadlineModalData.open}
                onClose={deadlineModalData.onClose}
                extraData={{
                    heading: "Choose Deadline",
                    action_button: "Save",
                    handleAction: (a, b) => handleDeadlineTimeCalendarSave(a, b)
                }}
            />
            <Button variant='outlined' onClick={() => onStepChange(null, null, 1)}>Change Page</Button>
        </Box>
    )
}

export const LaunchStudentAvailabilityPage2 = ({
    mentorShownInterestData,
    setMentorShownInterestData,
    getMentorShownInterest,
    mentorInterestData,
    process_id,
    mentorSummary,
    orderDetails,
    setOrderDetails,
    college_details,
    pageData,
    getStudentDetails,
    specilizationCountData, onStepChange }) => {
    console.log("onStepChange:", onStepChange)
    console.log("college_details in launcgpage 2:", college_details)
    // const [orderDetails, setOrderDetails] = useState([
    //     {
    //         "id": 1,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 3,
    //         "spe_name": "Finance",
    //         "student_availability": null,
    //         "student_availability_proof": null
    //     },
    //     {
    //         "id": 2,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 3,
    //         "spe_name": "Finance",
    //         "student_availability": 9,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 3,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 1,
    //         "spe_name": "HR",
    //         "student_availability": 2,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 4,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 2,
    //         "spe_name": "Sales & Marketing",
    //         "student_availability": 4,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 5,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 4,
    //         "spe_name": "IT",
    //         "student_availability": 6,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 6,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 5,
    //         "spe_name": "Analytics",
    //         "student_availability": 13,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 7,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 5,
    //         "spe_name": "Analytics",
    //         "student_availability": 12,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 8,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 6,
    //         "spe_name": "Consulting",
    //         "student_availability": 12,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    // ]);
    console.log("LaunchStudentAvailabilityPage2:", { orderDetails }, { specilizationCountData });
    const [loading, setLoading] = useState({ button: false });
    const [classesData, setClassesData] = useState({});
    const [speJSON, setSpeJSON] = useState({});
    const [topSix, setTopSix] = useState([]);

    //FUNCTIONS
    useEffect(() => {
        handleEffect();
    }, [])

    const handleEffect = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        const response = await service.get(`/os/superadmin/order?process_id=${process_id}`);
        const data = remakeStudentAvailabilityData(response.data);
        console.log("handleEffect ~ data:", data)
        setClassesData(data);
        // const fetched_student_availability_deadline = await fetchStudentAvailabilityDeadline();
        // setStudent_availability_deadline(fetched_student_availability_deadline.student_availability_deadline);
        setLoading((prev) => ({ ...prev, page: false }));
    }

    function sortJsonObject(obj) {
        const sortedKeys = Object.keys(obj).sort((a, b) => parseInt(b) - parseInt(a));
        const sortedObject = {};
        for (const key of sortedKeys) {
            sortedObject[key] = obj[key];
        }
        console.log("sortJsonObject ~ sortedObject:", sortedObject)
        return sortedObject;
    }

    console.log("top six outside", topSix);
    const remakeStudentAvailabilityData = (data) => {
        const main_data = [...data];
        console.log("data in launch:", data);
        // 1. Remove students with no specialization
        const actual_studentsData = main_data.filter((student) => student.spe_id !== null);

        // 2. Sort data by student_availability in descending order
        const sorted_studentsData = actual_studentsData.sort((a, b) => b.student_availability - a.student_availability);

        // 3. Make base_json
        let spe_json = {};
        sorted_studentsData.forEach((data) => {
            const { spe_name } = data;
            spe_json[spe_name ?? ''] = 0;
        });
        spe_json = { 'No of Classes': 0, ...spe_json, 'Total': 0 };
        setSpeJSON(spe_json);
        // 4. Make class count module
        const main_json = {};

        // 5. Separate top 6 and remaining students
        const nullStudentsData = sorted_studentsData.filter((student) => student.student_availability === null);
        const notNullStudentsData = sorted_studentsData.filter((student) => student.student_availability !== null);
        const unique_arr = [];
        const uniqueStudentData = notNullStudentsData.reduce((acc, student) => {
            // Check if the current student is not already in the accumulator
            if (!unique_arr.includes(student.student_availability)) {
                // Add the current student to the accumulator if it's not already present
                unique_arr.push(student.student_availability);
                acc.push(student);
            }
            return acc;
        }, []);
        console.log("unique:", uniqueStudentData);
        const topSix = uniqueStudentData.slice(0, 6); // Get top 6 availabilities
        let index_of_last_top_six = 0;
        const till_top_6 = notNullStudentsData.filter((ele, index) => {
            if (ele.student_availability >= topSix[topSix.length - 1].student_availability) {
                index_of_last_top_six++;
            }
            return ele.student_availability >= topSix[topSix.length - 1].student_availability;
        })

        const remainingStudents = notNullStudentsData.slice(index_of_last_top_six);
        console.log("top 6:", topSix);
        topSix.map((item,index)=>{
            if(index<3)
            setTopSix((prev)=>[...prev, item?.student_availability])
        })
        console.log("top 6 till :",)
        // 6. Process top 6 availabilities
        till_top_6.forEach((data, index) => {
            const { student_availability } = data;
            main_json[student_availability] = { ...spe_json, 'No of Classes': student_availability };
        });
        console.log("main jsn:", main_json);
        till_top_6.forEach((student) => {
            const { student_availability, spe_name } = student;
            // Find the corresponding availability group in main_json
            const availabilityGroup = main_json[student_availability] || {};

            // Check if the spe_name already exists in the availability group
            if (availabilityGroup[spe_name]) {
                availabilityGroup[spe_name]++; // Increment existing count
            } else {
                availabilityGroup[spe_name] = 1; // Create new key-value pair for spe_name with count 1
            }

            // Update main_json with the modified availability group
            main_json[student_availability] = availabilityGroup;
        });


        // 7. Calculate counts for remaining availabilities
        const remainingAvailability = till_top_6.at(-1)?.student_availability - 1;  // Get the lowest availability from remaining
        console.log("remaining :", till_top_6.at(-1));
        const remainingCount = remainingStudents.length;
        main_json[`${remainingAvailability} or less`] = { ...spe_json, 'No of Classes': `${remainingAvailability ?? 0} or less` };

        remainingStudents.forEach((student) => {
            const { student_availability, spe_name } = student;
            // Find the corresponding availability group in main_json
            const availabilityGroup = main_json[`${remainingAvailability} or less`] || {};
            // Check if the spe_name already exists in the availability group
            if (availabilityGroup[spe_name]) {
                availabilityGroup[spe_name]++; // Increment existing count
            } else {
                availabilityGroup[spe_name] = 1; // Create new key-value pair for spe_name with count 1
            }
            // Update main_json with the modified availability group
            main_json[`${remainingAvailability} or less`] = availabilityGroup;
        });

        main_json['Not yet submitted'] = { ...spe_json, 'No of Classes': 'Not yet submitted' };

        nullStudentsData.forEach((student) => {
            const { student_availability, spe_name } = student;
            // Find the corresponding availability group in main_json
            const availabilityGroup = main_json['Not yet submitted'] || {};
            // Check if the spe_name already exists in the availability group
            if (availabilityGroup[spe_name]) {
                availabilityGroup[spe_name]++; // Increment existing count
            } else {
                availabilityGroup[spe_name] = 1; // Create new key-value pair for spe_name with count 1
            }
            // Update main_json with the modified availability group
            main_json['Not yet submitted'] = availabilityGroup;
        })


        // 8. Calculate 'Total' for each key in main_json
        for (const availability in main_json) {
            const availabilityData = main_json[availability];
            const totalCount = Object.values(availabilityData)
                .reduce((sum, value) => {
                    console.log(".reduce ~ value:", value)
                    // Check if value is a number before adding
                    if (typeof value === 'number') {
                        return sum + value;
                    }
                    return sum; // Ignore string values
                }, 0)
                - (typeof availabilityData["No of Classes"] === "number" ? (availabilityData["No of Classes"] || 0) : 0);   // only when no of classes is a number then subtract
            availabilityData.Total = totalCount;
        }


        const returning = Object.values(main_json).sort((a, b) => {
            const aValue = Number(a['No of Classes']);
            const bValue = Number(b['No of Classes']);

            // Check if both values are numbers before sorting
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return bValue - aValue; // Sort in descending order
            } else {
                // If one or both values are not numbers, maintain the original order
                return 0;
            }
        });
        return returning;
    }


    function sumValuesByKey(jsonArray, key) {
        let sum = 0;
        for (let i = 0; i < jsonArray.length; i++) {
            const jsonObj = jsonArray[i];
            if (jsonObj.hasOwnProperty(key)) {
                sum += parseFloat(jsonObj[key]) || 0;
            }
        }
        return sum;
    }

    // const fetchStudentAvailabilityDeadline = async () => {
    //     try {
    //         const response = await service.get(`/os/superadmin/properties?process_id=${process_id}&reqParams=["student_availability_deadline"]`)
    //         return response?.data ?? null;
    //     } catch (error) {
    //         console.error("Error fetching student availability:", error);
    //     }
    // }


    return (
        <Box sx={{ ...styles.page, width: '100%', p: '20px 90px', gap: '32px', }}>
            <Box sx={{ ...styles.page, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 500 }}>Student Availability</Typography>
                <Typography style={{ ...DivCenter, gap: '8px', fontSize: '18px', }}>
                    Deadline : {mentorInterestData.student_availability_deadline ? formatConvertermonthfirst(mentorInterestData.student_availability_deadline, false, true) :
                        "No deadline specified"}
                    <EditOutlined onClick={() => onStepChange(null, null, 0)} sx={{ height: '20px', width: '20px', color: Color.primary1, cursor: 'pointer' }} />
                </Typography>
            </Box>
            <Box sx={{ ...styles.page, flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                <Typography style={{ fontSize: '24px', }}>Summary of responses</Typography>
                <Button onClick={() => scrollToTargetId('StudentsList')} variant='text' sx={{ color: Color.primary1, textTransform: 'none' }}>View students</Button>
            </Box>
            <Box sx={{ ...styles.page, gap: '16px', width: '100%', }}>
                <Box sx={{ ...DivCenter, width: '100%', gap: '16px', minHeight: '76px', background: 'rgba(249, 253, 254, 1)' }}>
                    {/* <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>No of Classes</Typography> */}
                    {Object.keys(speJSON).map((spe_name, spe_index) =>
                        <Typography key={spe_index} style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>{spe_name}</Typography>
                    )}
                </Box>
                <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', width: '100%', gap: '24px' }}>
                    {classesData.length > 0 && classesData.map((availability, availability_index) =>
                        <Box key={availability_index} sx={{ ...DivCenter, flexDirection: 'row', justifyContent: 'flex-start', width: '100%', gap: '16px', background: availability_index % 2 ? 'rgba(251, 251, 251, 1)' : '', minHeight: '76px', }}>
                            {Object.keys(availability).map((spe, spe_index) =>
                                <Typography key={spe_index} style={{ color: Color.primary1, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                    {/* {availability[spe] === 0 ? '-' : availability[spe]} */} {availability[spe]}
                                </Typography>
                            )}
                        </Box>
                    )}
                    <Box sx={{ ...DivCenter, flexDirection: 'row', justifyContent: 'flex-start', width: '100%', gap: '16px', minHeight: '76px', background: classesData.length % 2 ? 'rgba(251, 251, 251, 1)' : '', }}>
                        <Typography style={{ color: Color.primary1, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                            Total
                        </Typography>
                        {Object.keys(speJSON).filter((spe) => spe !== 'No of Classes').map((spe, spe_index) =>
                            <Typography style={{ color: Color.primary1, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                {sumValuesByKey(classesData, spe)}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>

            <StudentAvailabilityModule
                topSix={topSix}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                getStudentDetails={getStudentDetails}
                process_id={process_id}
                pageData={pageData}
                college_details={college_details}
                mentorInterestData={mentorInterestData}
            />

        </Box>)
}

export const StudentAvailabilityModule = ({ topSix, orderDetails, setOrderDetails, getStudentDetails, process_id, pageData, college_details, mentorInterestData }) => {

    const [loading, setLoading] = useState({ activity: false });

    //data variables
    const [searchTerm, setSearchTerm] = useState('');
    const initialFilterButtons = {
        'All': false,
        'Yet to submit': true,
        'HR': false,
        'Finance': false,
    };

   
    const [filterButtons, setFilterButtons] = useState({...initialFilterButtons});


    const filtered_order_details = orderDetails.filter((item, index) => item?.spe_id !== null && item?.resume !== null)
    console.log("ordered details:", filtered_order_details);
    const [filteredOrderDetails, setFilteredOrderDetails] = useState([...filtered_order_details]);
    const slotCount = (mentorInterestData.session_date_slot_count_mapping.map((slot) => slot.session_date));
    //MODALS
    const [SAModalData, setSAModalData] = useState({
        open: false,
        onClose: () => setSAModalData((prev) => ({ ...prev, open: false })),
        extraData: {}
    });


    //FUNCTIONS
    useEffect(()=>{
        topSix.map(num => {
            console.log("hereee 3 useEffect", num, topSix)
            setFilterButtons((prev)=>({ ...prev, [`${num} Classes`] : false}));
        });
    },[topSix])
    useEffect(() => {
        handleFilterEffect();
    }, [filterButtons['All'], filterButtons['Yet to submit'], filterButtons['9 Classes'], filterButtons['8 Classes'], filterButtons['7 Classes'], filterButtons['HR'], filterButtons['Finance']]);

    const handleFilterEffect = () => {
        let flag = '';
        for (const key in filterButtons) {
            if (filterButtons[key] === true) flag = key;
        }
        handleFilterButton(flag, false);
    }

    const handleChangeSearch = (e, newValue) => {
        console.log("handleChangeSearch ~ newValue:", e.target.value, newValue)
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        if (searchTerm) {
            const filtered = SearchFunction(filtered_order_details, ['first_name', 'last_name', 'email_id', 'ph_no', 'spe_name', 'student_availability'], searchTerm);
            console.log("handleChangeSearch ~ filtered:", filtered)
            setFilteredOrderDetails(filtered);
        }
        else {
            setFilteredOrderDetails([...filtered_order_details]);
        }
    }


    const handleAvailabilityModal = (student) => {
        console.log("handleAvailabilityModal ~ student:", student)
        setSAModalData((prev) => ({
            ...prev,
            open: true,
            extraData: {
                ...prev.extraData,
                first_name: student.first_name,
                last_name: student.last_name,
                sku_name: pageData.sku_name,
                student: student
            }
        }))
    }

    const handleFilterButton = (flag, scroll) => {
        let temp_filteredData = [...filtered_order_details];
        const temp_filter = {};
        for (let key in filterButtons) {
            if (key === flag) temp_filter[key] = true;
            else temp_filter[key] = false;
        }
        setFilterButtons(temp_filter);
        if (flag === 'All') {
            temp_filteredData = [...filtered_order_details];
        } else if (flag === 'Yet to submit') {
            temp_filteredData = temp_filteredData.filter((student) => student.student_availability === null);
        } else if (flag === 'HR') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'HR');
        } else if (flag === 'Finance') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Finance');
        } else {
            temp_filteredData = temp_filteredData.filter((student) => student.student_availability === parseInt(flag.split(' ')[0]));
        }
        setFilteredOrderDetails(temp_filteredData);
        if (scroll) scrollToTargetId('StudentsList');
    }


    function AddStudentAvailability({ open, onClose, extraData, college_details }) {
        //Data Variables
        const [formInputs, setFormInputs] = useState({
            no_classes: 0,
            student_availability: extraData.student?.student_availability,
            student_availability_proof: extraData.student?.student_availability_proof
        });
        const [loading, setLoading] = useState({ upload: false, button: false, })

        //FUNCTIONS
        const handleChangeStudentAvailability = ({ target }) => {
            const { name, value } = target;
            setFormInputs((prev) => ({ ...prev, [name]: value }));
        }

        const handleNoClassesChange = ({ target }) => {
            const { checked } = target;
            console.log("handleNoClassesChange ~ checked:", checked)
            setFormInputs((prev) => ({
                ...prev,
                no_classes: checked ? 1 : 0,
                student_availability: checked ? '' : prev.student_availability,
                student_availability_proof: checked ? null : prev.student_availability_proof
            }));
        }

        const handleFileChange = async (event) => {
            const files = event.target.files;
            const { name } = event.target;
            console.log("files...", files);
            if (files) {
                setLoading((prev) => ({ ...prev, upload: true }));
                try {
                    const fileUrl_response = await uploadFile(files, name);
                    console.log("fileUrl_response:", fileUrl_response);
                    let temp_formInput = { ...formInputs };
                    if (fileUrl_response?.student_availability_proof) {
                        temp_formInput.student_availability_proof = fileUrl_response.student_availability_proof
                    }
                    setFormInputs(temp_formInput);
                    setLoading((prev) => ({ ...prev, upload: false }));
                } catch (error) {
                    console.error("Error handling image upload:", error);
                    setLoading((prev) => ({ ...prev, upload: false }));
                }
            }
        };

        const uploadFile = async (selectedFileName, key) => {
            console.log("selectedFileName", selectedFileName[0]);
            const headers = {
                Authorization: `Bearer ${Cookies.get("token")}`,
                "Content-Type": "multipart/form-data",
            };

            const blob_image = new Blob([selectedFileName[0]], {
                type: 'application/pdf'
            });

            const image_data = new FormData();
            if (key.toLowerCase() === 'other_docs')
                image_data.append(
                    key + ("_" + formInputs.other_docs.length + 1) + `${selectedFileName[0].name}`,
                    blob_image,
                    `${selectedFileName[0].name}`
                );
            else image_data.append(
                key + `${selectedFileName[0].name}`,
                blob_image,
                `${selectedFileName[0].name}`
            );

            try {
                console.log("image data:", image_data)
                const imageRes = await axios.post(
                    `${process.env.REACT_APP_API_URL}/os/superadmin/order/file?user_id=${extraData.student.user_id}`,
                    image_data,
                    { headers }
                );
                console.log("uploadFile ~ imageRes:", imageRes)
                if (imageRes?.data) return imageRes.data;
                else return null;

            } catch (err) {
                console.error("Error uploading image:", err);
                // Handle the error as needed
                return null; // or throw an error
            }

        }


        const handleFileRemove = () => {
            setFormInputs((prev) => ({ ...prev, student_availability_proof: null }))
        }

        const handleSubmitStudentAvailability = async () => {

            try {
                let body;
                if (formInputs.no_classes) {
                    body = {
                        "order_id": extraData.student.id,
                        // "spe_id": extraData.student.spe_id,
                        "student_availability": 0,
                    }
                } else {
                    body = {
                        "order_id": extraData.student.id,
                        // "spe_id": extraData.student.spe_id,
                        "student_availability": parseInt(formInputs.student_availability),
                        "student_availability_proof": formInputs?.student_availability_proof ?? undefined
                    }
                }
                await service.put(`/os/superadmin/studentAvailibility`, body);
                onClose();
                const data = await getStudentDetails();
                const filtered_data = data.filter((item, index) => item?.spe_id !== null && item?.resume !== null);
                setOrderDetails(filtered_data);
                setFilterButtons({
                    'All': true,
                    'Yet to submit': false,
                    '9 Classes': false,
                    '8 Classes': false,
                    '7 Classes': false,
                    'HR': false,
                    'Finance': false
                });
                setFilteredOrderDetails(filtered_data);

            } catch (error) {
                console.error("Error while putting student availability:", error);
            }
        }

        console.log("college details", college_details);
        return (
            <Modal open={open} onClose={loading.button ? {} : onClose}>
                <Box sx={{ ...modalBoxStyle, justifyContent: 'flex-start', alignItems: 'space-between', height: 'auto', minHeight: '250px' }}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', height: '20%', justifyContent: 'flex-start', }}>
                        <Typography style={{ width: '100%', textAlign: 'center', fontSize: '24px' }}>
                            Student details
                        </Typography>
                        <Typography style={{ width: '100%', textAlign: 'center', fontSize: '16px' }}>
                            {extraData.first_name + " " + (extraData.last_name ?? "")}
                        </Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '80%', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '16px' }}>
                        <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <FormControlLabel control={<Checkbox checked={formInputs.no_classes}
                                onChange={handleNoClassesChange} sx={{ color: Color.neutralMidGrey }} />} label={<span style={{ color: Color.neutralBlack }}>I have no classes</span>} sx={{ width: '100%' }} />
                            <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '12px' }}>Check this box, if you’ve no classes.</Typography>
                        </Box>
                        {!formInputs.no_classes ?
                            <>
                                <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'row', alignItems: 'flex-start' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '50%', gap: '8px' }}>
                                        <Typography style={{ width: '100%', fontSize: '16px' }}>No of classes ({slotCount.map((session_date, session_index) => formatConvertermonthfirst(session_date, false, false, false) + (slotCount.length - 1 === session_index ? "" : ", "))}):</Typography>
                                        <TextField name='student_availability' type='number' value={formInputs.student_availability} onChange={handleChangeStudentAvailability} />
                                    </Box>
                                    <Box sx={{ ...DivCenter, alignItems: 'flex-end', flexDirection: 'column', width: '50%', }}>
                                        <Typography style={{ fontSize: '18px', }}>{extraData.sku_name ?? 'SKU'}</Typography>
                                        <Typography style={{ color: Color.neutralMidGrey, fontSize: '12px' }}>{(college_details.college_code ?? 'College') + ", " + (college_details.user_group_name ?? 'Batch') + ", " + (college_details.name ?? "Batch Type")}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', gap: '16px' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Upload the schedule (Optional)</Typography>
                                    {!formInputs.student_availability_proof?.name ?
                                        <Box onClick={() => {
                                            document.getElementById('fileInput').click();
                                        }} sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '200px', borderRadius: '8px', background: 'rgba(244, 244, 244, 1)', cursor: 'pointer', gap: '8px' }}>
                                            {loading.upload ?
                                                <CircularProgress size={24} sx={{ color: Color.primary1 }} />
                                                : <>
                                                    <FilePresentOutlined sx={{ width: '33px', height: '33px', color: 'rgba(90, 90, 90, 1)' }} />
                                                    <input
                                                        name='student_availability_proof'
                                                        type="file"
                                                        accept=".pdf"
                                                        multiple
                                                        onChange={(e) => handleFileChange(e)}
                                                        style={{ display: 'none' }}
                                                        id="fileInput"
                                                    />
                                                    <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Upload the schedule</Typography>
                                                </>}
                                        </Box>
                                        : <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', height: '200px', borderRadius: '8px', cursor: 'pointer', gap: '8px' }}>
                                            <Box className='box__fileUpload' sx={{ width: '100%', }}>
                                                <a href={formInputs.student_availability_proof?.url ? formInputs.student_availability_proof?.url : "#"} target="_blank" style={{ textDecoration: "none" }}>
                                                    <Typography sx={{ ml: '5px', textTransform: 'none', color: Color.neutralBlack, fontSize: '14px' }}>
                                                        {formInputs.student_availability_proof.name}
                                                    </Typography>
                                                </a>
                                                <DeleteOutlined onClick={() => handleFileRemove()} sx={{ cursor: 'pointer' }} />
                                            </Box>
                                        </Box>}
                                </Box>
                            </>
                            : <></>
                        }
                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between' }}>
                            <Button variant='outlined' onClick={onClose} sx={{ ...styles.button_styles.outlined, width: '45%' }}>Cancel</Button>
                            <Button variant='contained' onClick={handleSubmitStudentAvailability} sx={{ ...styles.button_styles.contained, width: '45%' }}>Submit</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        )
    }


    return (
        <Box sx={{ pt: '48px', width: '100%', }}>
            <ActivityLoader show={loading.activity} />
            <Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
                <TextField
                    name="search_colleges"
                    // label="Search Student"
                    placeholder='Search Students'
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleChangeSearch}
                    fullWidth
                    sx={{ width: '90%', borderRadius: "12px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: Color.neutralMidGrey }} />
                            </InputAdornment>
                        ),
                        sx: { borderRadius: "12px", height: "58px" }
                    }}
                />
                <Box sx={{ width: '10%', minWidth: '121px' }}>
                    <IconButton><AddOutlined /></IconButton>
                    <IconButton><GetApp /></IconButton>
                    <IconButton><UploadFile /></IconButton>
                </Box>
            </Box>
            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '8px', mb: '24px' }}>
                {Object.keys(filterButtons).map((filter) => <Button onClick={() => { handleFilterButton(filter, true); }} sx={{
                    ...styles.filter_buttons,
                    background: filterButtons[filter] ? 'rgba(195, 236, 245, 1)' : Color.white,
                }}>{filter}</Button>)}
            </Box>
            <Box id="StudentsList" sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
                <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '23.33%' : '25%', justifyContent: 'center' }}>
                    <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Student</Typography>
                </Box>
                <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '53.33%' : '25%', }}>
                    <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Specialization</Typography>
                </Box>
                {!filterButtons['Yet to submit'] ? <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '53.33%' : '25%', }}>
                    <Typography style={{ fontSize: '22px', color: Color.primary1, }}>No of Classes</Typography>
                </Box> : <></>}
                {filterButtons['Yet to submit'] ?
                    <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '23.33%' : '25%', justifyContent: 'center' }}>
                        <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Update</Typography>
                    </Box>
                    : <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '23.33%' : '25%', justifyContent: 'center' }}>
                        <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Proof</Typography>
                    </Box>}
            </Box >
            {filteredOrderDetails.map((student, student_index) =>
                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: student_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'center', gap: '8px', width: filterButtons['Yet to submit'] ? '23.33%' : '25%' }}>
                        <Typography style={{ color: Color.primary1, fontSize: '18px' }}>{student.first_name + " " + (student.last_name ?? '')}</Typography>
                        <Typography style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.email_id}</Typography>
                        <Typography style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.ph_no}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '53.33%' : '25%' }}>
                        <Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>
                            {student.spe_name}
                        </Typography>
                    </Box>
                    {!filterButtons['Yet to submit'] ?
                        student.student_availability !== null ? <Box sx={{ ...DivCenter, flexDirection: 'column', width: '25%' }}>
                            <Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.student_availability}</Typography>
                            <Typography onClick={() => handleAvailabilityModal(student)} style={{ textAlign: 'center', color: Color.primary1, fontSize: '16px', cursor: 'pointer' }}>Edit</Typography>
                        </Box>
                            : <Box sx={{ ...DivCenter, flexDirection: 'column', width: '25%' }}>
                                <Button onClick={() => handleAvailabilityModal(student)} sx={{ ...styles.button_styles.outlined, background: 'rgba(195, 236, 245, 1)', '&:hover': { background: 'rgba(147, 211, 231, 1)', } }}>
                                    Add availability
                                </Button>
                            </Box>
                        : <></>}
                    {filterButtons['Yet to submit'] ?
                        <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'center', width: filterButtons['Yet to submit'] ? '23.33%' : '25%', }}>
                            <Box sx={{ ...DivCenter, gap: '16px' }}>
                                {!student.student_availability ? <>
                                    <Button onClick={() => handleAvailabilityModal(student)} sx={{ ...styles.button_styles.outlined, background: 'rgba(195, 236, 245, 1)', '&:hover': { background: 'rgba(147, 211, 231, 1)', } }}>Add availability</Button>
                                </>
                                    : <Box sx={{ ...DivCenter, }}>
                                        <Typography style={{ fontSize: '20px', color: Color.neutralMidGrey, background: '', padding: '3px 5px' }}>
                                            {student.student_availability}
                                        </Typography>
                                        <span>
                                            <Tooltip title='Edit Availability' placement='top'>
                                                <IconButton onClick={() => handleAvailabilityModal(student)}>
                                                    <EditOutlined sx={{ fontSize: '20px', color: Color.neutralMidGrey, }} />
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                    </Box>}
                            </Box>
                        </Box>
                        : <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'center', width: filterButtons['Yet to submit'] ? '23.33%' : '25%', }}>
                            {/* <IconButton onClick={window.open(student.student_availability_proof)}><AttachFile /></IconButton> */}
                            {student?.student_availability > 0 && student?.student_availability_proof?.url ?
                                <Tooltip title='Open Attachment' placement='top'>
                                    <a href={student?.student_availability_proof?.url ? student.student_availability_proof.url : "#"} target="_blank" style={{ textDecoration: "none" }}>
                                        <span style={{ color: Color.neutralMidGrey }}><AttachFile /></span>
                                    </a>
                                </Tooltip>
                                : <Tooltip title='No attachment available' placement='top'><span style={{ color: Color.neutralMidGrey, transform: 'rotate(90deg)' }}><LinkOff /></span></Tooltip>}
                        </Box>}
                </Box>)
            }

            {/* MODALS */}

            <AddStudentAvailability
                open={SAModalData.open}
                onClose={SAModalData.onClose}
                extraData={SAModalData.extraData}
                college_details={college_details}
            />

            {/* {confirmation.open ? <OfflineSessionConfirmationModal
                open={confirmation.open}
                onClose={confirmation.onClose}
                extraData={confirmation.extraData}
            /> : <></>}
            {comment.open ? <CommentModal open={comment.open} onClose={comment.onClose} /> : <></>}
            {editSpecilization.open ? <EditMappedSpecialization open={editSpecilization.open} onClose={editSpecilization.onClose} extraData={editSpecilization.extraData} /> : <> </>} */}
        </Box >
    )
}