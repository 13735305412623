import React, { useEffect, useState } from "react";
import ServiceCM from '../../../services/cartmoduleServices';
import Service from '../../../services/httpService';
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
import Papa from 'papaparse';
import { saveAs } from "file-saver";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Multiselect from 'multiselect-react-dropdown';
import Loader from "../../reusables/Loader/Loader";
import AddIcon from '@mui/icons-material/Add';
import Modal from "react-bootstrap/Modal";
import axios from "axios"
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/Delete';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirmation from "../../reusables/ModalInput/ModalConfirmation";
import { IconButton } from "@mui/material";
import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Bundle = () => {
    const navigate = useNavigate();

    const [rowsData, setRowsData] = useState([])
    const [skuData, setSkuData] = useState([])
    const [bundleData, setBundleData] = useState([])
    const [formInputs, setFormInputs] = useState({ name: "", image_url: "", skus: [], actual_price: 0, discounted_price: 0, header_desc: "", footer_desc: [], items: [] })
    //get current date and time
    const currentDate = new Date();

    const [from_table, setFrom_table] = useState();
    const [loading, setLoading] = useState(true);

    const [JDfiles, setJDFiles] = useState([]);

    //image upload
    const [imageFiles, setImageFiles] = useState(null)
    const [imageName, setImageName] = useState('');
    const [updatedImage, setUpdatedImage] = useState(null)
    const [showImageDeleteModal, setShowImageDeleteModal] = useState(false)
    const [complimentary_select_data, setComplimentary_select_data] = useState([])
    const [complimentary, setComplimentary] = useState()
    const serviceCM = new ServiceCM()
    const serviceSA = new Service()
    let count = 0;
    const headCells = [
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'Bundle Name'
        },
        {
            id: 'heading3',
            numeric: 'right',
            disablePadding: false,
            label: 'Actual Price'
        },
        {
            id: 'heading4',
            numeric: 'right',
            disablePadding: false,
            label: 'Discounted Price'
        }

    ];

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }

            let bundle = await bundleGetter();
            let sku = await SKUGetter();
            let complimentary = await complimentaryGetter()

            console.log("odonil", { bundle }, { sku })
            data_manupulator_bundle(bundle);
            data_manupulator_complimentary(complimentary);
            setLoading(false)
        }

        handleEffect();
    }, [])

    const data_manupulator_complimentary = (batch) => {

        let temp = batch;
        temp = temp.map(bat => {
            bat.key = bat.name;
            bat.id = bat.id;

            // delete bat.name;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setComplimentary(temp)
    }

    useEffect(() => {
        const setting_data = () => {

            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setFormInputs({ name: "", image_url: "", skus: [{ id: 0, quantity: 0 }], actual_price: "", discounted_price: "", header_desc: "", footer_desc: [""], items: [""] })
                // setImageFiles()
                setComplimentary_select_data([])

            }
            else {

                console.log("Inside else of useEffect")
                console.log({ rowsData })
                setFormInputs({ name: bundleData[from_table?.index]?.name, image_url: bundleData[from_table?.index]?.image_url, skus: JSON.parse(bundleData[from_table?.index]?.skus)?.skus, actual_price: bundleData[from_table?.index]?.actual_price, discounted_price: bundleData[from_table?.index]?.discounted_price, header_desc: bundleData[from_table?.index]?.header_desc, footer_desc: JSON.parse(bundleData[from_table?.index]?.footer_desc)?.footer_desc, items: JSON.parse(bundleData[from_table?.index]?.complimentary_items)?.items })
                setImageFiles(bundleData[from_table?.index]?.image_url)
                // const find_batch = batch.filter((ele) => user_id.includes(ele.id));
                // console.log("find batch", find_batch);
                console.log("JSON.parse(bundleData[from_table?.index]?.complimentary_items)?.items", JSON.parse(bundleData[from_table?.index]?.complimentary_items)?.items);
                // setComplimentary_select_data(JSON.parse(bundleData[from_table?.index]?.complimentary_items)?.items)
                let temp_data = JSON.parse(bundleData[from_table?.index]?.complimentary_items)?.items;

                const matchingItems = complimentary.filter(item => temp_data.includes(item.name));
                console.log("matching items", matchingItems);

                if (matchingItems.length > 0) {
                    setComplimentary_select_data(matchingItems);
                }

            }

        }
        setting_data();
    }, [from_table])
    console.log("selected values", complimentary_select_data);
    const onSelect_batch = (e) => {
        console.log("Added an item to multi select - batch -", e)
        let temp = e;
        temp = temp.filter(data => typeof data.id === "number").map(obj => obj.key)
        setComplimentary_select_data(e);
        setFormInputs({ ...formInputs, items: temp })
        console.log("temp inside onSelect:", temp)
    }

    const onRemove_batch = (e) => {
        console.log("Removed an item to multi select")
        let temp = e;
        temp = temp.filter(data => typeof data.id === "number").map(obj => obj.key)
        setFormInputs({ ...formInputs, items: temp })
        setComplimentary_select_data(e);
        console.log("temp inside onSelect:", temp)

    }



    const bundleGetter = async () => {
        try {
            const res = await serviceCM.get('/bundle')
            console.log("Duration RESPONSE:", res.data)
            setRowsData([...res.data])
            setBundleData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const SKUGetter = async () => {
        try {
            const res = await serviceCM.get('/sku')
            console.log("Duration RESPONSE:", res.data)
            setSkuData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const complimentaryGetter = async () => {
        try {
            const res = await serviceCM.get('/complimentary')
            console.log("Duration RESPONSE:", res.data)
            // setRowsData([...res.data])
            // setBundleData([...res.data])

            return res.data
        } catch (err) {
            console.log(err)
        }
    }




    const data_manupulator_bundle = (bundle) => {
        console.log("SKU data:", bundle)
        // console.log("Activity data:", activity)
        let temp_rowsData = JSON.parse(JSON.stringify(bundle));
        // let temp_activity = JSON.parse(JSON.stringify(activity));


        temp_rowsData = temp_rowsData.map((row) => {
            // row.act_type_id = temp_activity?.map(act => act.id === row.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]

            // delete row.act_type_id
            delete row.is_deleted
            delete row.skus
            delete row.footer_desc
            delete row.header_desc
            delete row.complimentary_items
            return row
        });
        console.log("temp rows:", temp_rowsData)
        setRowsData(temp_rowsData)

    }


    const handleDeleteMul = (index, id) => {
        console.log("deleting this:", rowsData[index]?.duration_name)
        let list_rowsData = [...rowsData];
        let list_bundleData = [...bundleData];



        serviceCM
            .delete("/bundle", {
                data: { "ids": id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list_rowsData = list_rowsData.filter((item) => id.indexOf(item.id) < 0);
                setRowsData(list_rowsData)
                list_bundleData = list_bundleData.filter((item) => id.indexOf(item.id) < 0);
                setBundleData(list_bundleData)


            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
    }

    const data_getter = (name, id, index, activity) => {

        console.log("Dude here's your name:", name);
        console.log("Dude here's your id:", id);
        console.log("Dude here's your index:", index);

        if (name === "delete_mul") {
            console.log("inside delete mul if")
            handleDeleteMul(index, id)
        }
        if (activity === "add") {
            setFormInputs({})
        }
        setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
    }



    const Modal_bundle = (show, setShow, mode) => {

        // useEffect(() => {
        //     console.log("Brother")
        //     const handleEffect = async () => {

        //         setFormInputs({...formInputs, activity_type_id: skuData[from_table?.index].activity_type_id})

        //     }

        //     handleEffect();
        // }, [from_table?.index])

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }

        const modalSubmit = () => {
            if (from_table.index === undefined)
                handlePostRequest();
            else
                handlePutRequest();

            setShow(false)
        }



        const handleFileInput = (e) => {
            const f = e.target.files;
            let filesArray = [...JDfiles];
            for (let i = 0; i < f.length; i++) {
                filesArray.push(f[i]);
            }
            setJDFiles(filesArray);
            // setFormInputs({ ...formInputs, image_url: filesArray });
        };

        const handleRemoveFile = (fileIndex) => {
            console.log("Removed id:", rowsData[from_table?.index]?.id)

            try {
                let res = serviceCM.delete("/job_name/file",
                    {
                        data: {
                            jd_id: rowsData[from_table?.index]?.id
                        }
                    })


                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                const filesArray = [...JDfiles];
                filesArray.splice(fileIndex, 1);
                setJDFiles(filesArray);

            } catch (err) {
                console.log("Errog Msg: ", err);

            }

        };

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }


        const onHide = () => {
            setShow(false)
        }


        const handleChange = (e, index) => {

            console.log("name from form  is:", e.target.name);
            console.log("value from form  is:", e.target.value);
            const { name, value } = e.target

            if (name === "name") {
                console.log("activity_type_id")
                setFormInputs({ ...formInputs, name: value })
            }
            else if (name === "image_url") {
                console.log("desc")
                setFormInputs({ ...formInputs, image_url: value })
            }
            else if (name === "sku_id") {
                let temp_sku = [...formInputs.skus];
                temp_sku[index] = { ...temp_sku[index], id: value };
                setFormInputs(prevState => ({ ...prevState, skus: temp_sku }));
            }
            else if (name === "sku_quantity") {
                let temp_sku = [...formInputs.skus];
                temp_sku[index] = { ...temp_sku[index], quantity: value };
                setFormInputs(prevState => ({ ...prevState, skus: temp_sku }));
            }
            else if (name === "actual_price") {
                console.log("actual_price")
                setFormInputs({ ...formInputs, actual_price: value })
            }
            else if (name === "discounted_price") {
                console.log("discounted_price")
                setFormInputs({ ...formInputs, discounted_price: value })
            }
            else if (name === "header_desc") {
                console.log("header_desc")
                setFormInputs({ ...formInputs, header_desc: value })
            }
            else if (name === "footer_desc") {
                console.log("footer_desc")
                let temp_footer_desc = [...formInputs.footer_desc];
                temp_footer_desc[index] = value;
                console.log("hahaha", temp_footer_desc)
                setFormInputs(prevState => ({ ...prevState, footer_desc: temp_footer_desc }));
            }
        }


        const handleAddSKU = () => {
            // formInputs.skus.push({ id: 0, quantity: 0 })
            let tempFormInputs = {
                ...formInputs,
                skus: [...formInputs.skus, { id: 0, quantity: 0 }]
            }
            setFormInputs(tempFormInputs)
        }
        const handleDeleteSKU = (index) => {
            let tempFormInputs = formInputs.skus.filter((s, i) => i !== index);

            // create a new state with the updated hobbies array
            let temp_FI = {
                ...formInputs,
                skus: tempFormInputs
            };

            // update the state using setMyState
            setFormInputs(temp_FI);
        }
        const handleAddDesc = () => {
            let tempFormInputs = {
                ...formInputs,
                footer_desc: [...formInputs.footer_desc, ""]
            }
            setFormInputs(tempFormInputs)
        }
        const handleDeleteDesc = (index) => {
            let tempFormInputs = formInputs.footer_desc.filter((s, i) => i !== index);

            // create a new state with the updated hobbies array
            let temp_FI = {
                ...formInputs,
                footer_desc: tempFormInputs
            };

            // update the state using setMyState
            setFormInputs(temp_FI);
        }




        const handleRemoveImage = () => {
            // setShowDeleteModal(true)
            setShowImageDeleteModal(true)

        };
        const handleImageUpload = (event) => {
            const file = event.target.files[0];
            console.log({ file })
            // const reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onloadend = () => {
            //     setImageFiles(reader.result);
            // };
            setImageFiles(file);
            setImageName(file.name);

            // if (action === "edit") {
            //     // const y ={...updatedForm.mentor_materail, [fileType]: e.target.files}\\
            //     let temp_mm = { ...updatedImage }
            //     temp_mm = event.target.files[0];
            //     setUpdatedImage(temp_mm);
            //     console.log("updated image", temp_mm);


            // }


        };


        return (
            <Modal
                show={show}
                onHide={() => onHide()}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '95%',
                    minWidth: '800px',
                    minHeight: 'fit-content',
                    paddingBottom: '50px',
                    paddingTop: '50px',
                }}>
                    <form className="Entire-form1" >

                        <div className='right-side-buttons-bd row'>
                            <div className="headerComp" >
                                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                            </div>

                            <div className="form-group">
                                <label className="inputLabel" sx={{ wdith: '80%' }}>Name<span>*</span></label>
                                <input name="name" type="text" disabled={mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.name} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group" style={{ marginTop: '0', paddingTop: '0' }}>
                                {/* <div className="cloud-comp-image" > */}
                                <div className={imageFiles ? "cloud-comp-exist" : "cloud-comp-image"} style={{ height: "auto", marginTop: "3%", width: '100%', }}>


                                    <div className="inner-div-container" style={{ height: 'auto', marginTop: '.5rem', marginBottom: '.5rem' }}>


                                        {imageFiles ? <>
                                            <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "80%", height: "55%", margin: '0 auto' }}>
                                                <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{imageName ? imageName : imageFiles?.split("/")?.at(-1).toString()}</p>
                                                <DeleteIcon className="deleteIcon" onClick={handleRemoveImage}></DeleteIcon>
                                            </div>
                                        </> : <></>}


                                        {/* {imageFiles ? <>
                                                <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "80%", height: "55%", margin: '0 auto' }}>
                                                    <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{imageName ? imageName : imageFiles?.split("/")?.at(-1).toString()}</p>
                                                </div>
                                            </> : <></>} */}
                                        {imageFiles === null ? <>
                                            <div className="imageFiles-dont-exist" >
                                                <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                                <label className="btn-image" style={{ border: 'none' }} for="files" class="btn">Select an Image</label>
                                                <input className="form-group " disabled={mode === "View" ? true : false} id="files" type="file" multiple onChange={handleImageUpload} style={{ marginTop: '0', visibility: 'hidden' }} />
                                            </div>
                                        </> : <></>}

                                        {/* {imageFiles === null ? <>
                                                <div className="imageFiles-exist" >
                                                    <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                                    <label className="btn-image" style={{ border: 'none' }} for="files" class="btn">Image Upload</label>
                                                </div>
                                            </> : <></>} */}

                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">SKUs<span>*</span></label>
                                {formInputs?.skus?.map((sku, index) => (
                                    <div sx={{ display: 'flex', flexDirection: 'column', marginTop: '2%' }}>
                                        {/* <label className="inputLabel">Quantity<span>*</span></label> */}
                                        <div className="column" style={{ display: "flex", marginTop: '7px' }}>

                                            <div className='column' sx={{ marginRight: '10px' }}>
                                                <select name="sku_id" id="selectList" disabled={mode === "View" ? true : false} className="form-control inputFields column" onChange={(e) => handleChange(e, index)} value={sku?.id} sx={{ marginRight: '20px' }}>
                                                    <option value={""} selected>SKUs</option>
                                                    {skuData?.map((s, i) => (
                                                        <option key={i} value={s.id}>{s.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='column' sx={{ marginRight: '10px' }}>
                                                <select name="sku_quantity" id="selectList" disabled={mode === "View" ? true : false} className="form-control inputFields column" onChange={(e) => handleChange(e, index)} value={sku?.quantity} sx={{ marginLeft: '20px' }}>
                                                    <option value={""} selected>Quantity</option>
                                                    <option value={1} >1</option>
                                                    <option value={2} >2</option>
                                                    <option value={3} >3</option>
                                                    <option value={4} >4</option>
                                                    <option value={5} >5</option>
                                                </select>
                                            </div>
                                            {mode === "View" ? <></> : (
                                                <div sx={{ marginLeft: '50%' }}>
                                                    <IconButton onClick={(e) => handleDeleteSKU(index)}>
                                                        <DeleteOutlineOutlinedIcon />
                                                    </IconButton>
                                                </div>)
                                            }
                                            {mode === "View" ? <></> : (index === formInputs?.skus?.length - 1 && (
                                                <IconButton onClick={(e) => handleAddSKU()}>
                                                    <AddIcon />
                                                </IconButton>)
                                            )}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                            {/* {mode === "View" ? <></> : <div className=" btn-comp2 ">
                                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} style={{ justifyContent: 'flex-start', marginLeft: "0"    }}>Submit</button>
                            </div>} */}
                        </div>




                        <div className='right-side-buttons-jd row'>
                            <div className="headerComp" >
                                <p className="compTitlejd">Bundle</p>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Actual Price<span>*</span></label>
                                <input name="actual_price" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.actual_price} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Discounted Price<span>*</span></label>
                                <input name="discounted_price" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.discounted_price} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel" >Header Description</label>
                                <input name="header_desc" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.header_desc === "undefined" ? "No header provided" : formInputs.header_desc} onChange={(e) => handleChange(e)} />
                            </div>



                            <div style={{ display: 'flex' }}>
                                <div className="form-group">
                                    <label className="inputLabel">Footer Description<span>*</span></label>
                                    {formInputs?.footer_desc?.map((foot, index) => (
                                        <div style={{ display: "flex" }}>
                                            <input name="footer_desc" type="text" disabled={mode === "View" ? true : false} className="form-control" id="selectList1" placeholder="Example input" value={foot} onChange={(e) => handleChange(e, index)} sx={{ marginTop: ' 2%' }} />

                                            {mode === "View" ? <></> :
                                                <IconButton onClick={(e) => handleDeleteDesc(index)}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>}
                                            {mode === "View" ? <></>
                                                : (index === formInputs?.footer_desc?.length - 1 && (
                                                    <IconButton onClick={(e) => handleAddDesc()}>
                                                        <AddIcon />
                                                    </IconButton>
                                                ))}
                                        </div>
                                    ))}

                                </div>
                            </div>

                            <div className="form-group ">
                                <label className="inputLabel">Complimentary Items<span>*</span></label>
                                <Multiselect
                                    displayValue="key"
                                    selectedValues={complimentary_select_data}
                                    onRemove={(e) => { onRemove_batch(e) }}
                                    onSelect={(e) => { onSelect_batch(e) }}
                                    options={complimentary}
                                />
                            </div>

                            {mode === "View" ? <></> : <div className=" btn-comp2 ">
                                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} style={{ justifyContent: 'flex-start', marginLeft: "0" }}>Submit</button>
                            </div>}

                        </div>




                    </form>
                    <ModalConfirmation imageFiles={imageFiles} setImageFiles={setImageFiles} imageName={imageName} setImageName={setImageName} is_delete_all={false} formData={formInputs} setUpdatedImage={setUpdatedImage} updatedImage={updatedImage} data_getter={data_getter} action="imageDelete" modalConfirmationShow={showImageDeleteModal} setModalConfirmationShow={setShowImageDeleteModal} onHide={() => setShowImageDeleteModal(false)} />

                </Modal.Body>
            </Modal >

        )
    }

    const headers = {
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
    };


    const handlePostRequest = async () => {
        let data;
        if (formInputs.header_desc === "") {
            data = {
                name: formInputs.name,
                image_url: formInputs.image_url,
                skus: formInputs.skus,
                actual_price: formInputs.actual_price,
                discounted_price: formInputs.discounted_price,
                // header_desc: formInputs.header_desc,
                footer_desc: formInputs.footer_desc,
                complimentary_items: formInputs.items

            };
        } else {
            data = {
                name: formInputs.name,
                image_url: formInputs.image_url,
                skus: formInputs.skus,
                actual_price: formInputs.actual_price,
                discounted_price: formInputs.discounted_price,
                header_desc: formInputs.header_desc,
                footer_desc: formInputs.footer_desc,
                complimentary_items: formInputs.items

            };
        }
        let temp = JSON.parse(JSON.stringify(bundleData));
        let temp_image_data = null
        delete data.image_url
        console.log("User TYpe id not found, sending teh post request", data);
        await serviceCM
            .post("/bundle", data)
            .then(async (res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")

                temp = ([{
                    id: res?.data?.id,
                    name: formInputs.name,
                    skus: JSON.stringify({ skus: formInputs.skus }),
                    actual_price: formInputs.actual_price,
                    discounted_price: formInputs.discounted_price,
                    header_desc: formInputs.header_desc,
                    footer_desc: JSON.stringify({ footer_desc: formInputs.footer_desc }),
                    complimentary_items: JSON.stringify({ items: formInputs.items }),
                    image_url: null
                }, ...temp])

                // image upload
                if (imageFiles !== undefined && imageFiles !== null) {
                    console.log({ imageFiles })
                    const blob_image = new Blob([imageFiles], {
                        type: "image/png"
                    });
                    console.log("blog image:", blob_image)
                    const data = new FormData();
                    data.append("bundle_image", blob_image, {
                        filepath: blob_image,
                        contentType: 'multipart/form-data',
                    });

                    const headers = {
                        "Content-Type": "multipart/form-data"
                    }
                    console.log({ data })
                    await axios.post(`${process.env.REACT_APP_API_URL}/cartModule/bundle/image?id=${res?.data?.id}`, data, {
                        // serviceCM.post(`/bundle/image?id=${res?.data?.id}`, data, {
                        headers
                    })
                        .then((res_image) => {
                            console.log({
                                res_image
                            });
                            temp_image_data = res_image.data.file_url
                            temp = ([{
                                id: res?.data?.id,
                                name: formInputs.name,
                                skus: JSON.stringify({ skus: formInputs.skus }),
                                actual_price: formInputs.actual_price,
                                discounted_price: formInputs.discounted_price,
                                header_desc: formInputs.header_desc,
                                footer_desc: JSON.stringify({ footer_desc: formInputs.footer_desc }),
                                complimentary_items: JSON.stringify({ items: formInputs.items }),
                                image_url: temp_image_data
                            }, ...temp])

                        })
                        .catch((err) => {
                            console.log("Errog Msg: ", err);
                            window.alert(err.response.data.message)
                        });
                }
                console.log("Put temp value:", temp)
                setBundleData(temp)
                data_manupulator_bundle(temp)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });




    }

    const handlePutRequest = async () => {
        console.log("PUT Request")
        let body;
        if (formInputs.header_desc === "") {
            body = {
                name: formInputs.name,
                image_url: formInputs.image_url,
                skus: formInputs.skus,
                actual_price: formInputs.actual_price,
                discounted_price: formInputs.discounted_price,
                // header_desc: formInputs.header_desc,
                footer_desc: formInputs.footer_desc,
                complimentary_items: formInputs.items

            };
        } else {
            body = {
                name: formInputs.name,
                image_url: formInputs.image_url,
                skus: formInputs.skus,
                actual_price: formInputs.actual_price,
                discounted_price: formInputs.discounted_price,
                header_desc: formInputs.header_desc,
                footer_desc: formInputs.footer_desc,
                complimentary_items: formInputs.items

            };
        }


        let temp_image_data = null
        let temp = JSON.parse(JSON.stringify(bundleData));
        body.id = from_table?.id
        delete body.image_url
        console.log(body)

        // body.push({
        //     id: from_table?.id
        // })
        serviceCM
            .put("/bundle", body)
            .then(async (res) => {

                console.log("Response from backend is", JSON.stringify(res, null, 2));
                // res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")



                // let temp = JSON.parse(JSON.stringify(rowsData));

                temp[from_table?.index].name = formInputs.name
                temp[from_table?.index].skus = JSON.stringify({ skus: formInputs.skus })
                temp[from_table?.index].actual_price = formInputs.actual_price
                temp[from_table?.index].discounted_price = formInputs.discounted_price
                temp[from_table?.index].header_desc = formInputs.header_desc
                temp[from_table?.index].footer_desc = JSON.stringify({ footer_desc: formInputs.footer_desc })
                temp[from_table?.index].complimentary_items = JSON.stringify({ items: formInputs.items })


                // image upload
                if (imageFiles !== undefined && imageFiles !== null) {
                    console.log({ imageFiles })
                    const blob_image = new Blob([imageFiles], {
                        type: "image/png"
                    });
                    console.log("blog image:", blob_image)
                    const data = new FormData();
                    data.append("bundle_image", blob_image, {
                        filepath: blob_image,
                        contentType: 'multipart/form-data',
                    });
                    // const data = 

                    const headers = {
                        "Content-Type": "multipart/form-data"
                    }
                    // console.log({ data })
                    await axios.post(`${process.env.REACT_APP_API_URL}/cartModule/bundle/image?id=${from_table?.id}`, data, {
                        headers
                    })
                        .then((res_image) => {
                            console.log({
                                res_image
                            });
                            temp_image_data = res_image.data.file_url
                            temp[from_table?.index].name = formInputs.name
                            temp[from_table?.index].skus = JSON.stringify({ skus: formInputs.skus })
                            temp[from_table?.index].actual_price = formInputs.actual_price
                            temp[from_table?.index].discounted_price = formInputs.discounted_price
                            temp[from_table?.index].header_desc = formInputs.header_desc
                            temp[from_table?.index].footer_desc = JSON.stringify({ footer_desc: formInputs.footer_desc })
                            temp[from_table?.index].complimentary_items = JSON.stringify({ items: formInputs.items })
                            temp[from_table?.index].image_url = temp_image_data

                        })
                        .catch((err) => {
                            console.log("Errog Msg: ", err);
                            window.alert(err.response.data.message)
                        });
                }


                console.log("Put temp value:", temp)
                setBundleData(temp)
                data_manupulator_bundle(temp)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                // window.alert(err.response.data.message)
            });
    }


    // const handleDelete = async (jd_id, index) => {
    //     console.log("delete", jd_id)
    //     let list = [...rowsData];

    //     console.log("listtttt1", list);
    //     serviceCM
    //         .delete("/duration", {
    //             data: { "ids": id }
    //         })
    //         .then((res) => {
    //             console.log("Response from backend is", res);
    //             res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
    //             list.splice(index, 1);
    //             setRowsData(list)
    //             console.log("listtttt2", list);

    //         })
    //         .catch((err) => {
    //             console.log("Errog Msg: ", err);

    //             console.log(err.response.data.message)
    //             window.alert(err.response.data.message)

    //         });
    // }

    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", rowsData);
        // Simulate a button click after 2 seconds
        const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


    }

    const goBack = () => {
        console.log("Back Button is Clicked Now")
        navigate(-1);
      }


    return (<div >
        <RowsContext.Provider value={rowsData}>
            <TopBar />
            <SideNavigationBar />

            {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
                {console.log("from_table.index", from_table?.index)}
                {console.log("sku value in formInputs", formInputs?.skus)}
                {console.log("footer desc value in formInputs", formInputs?.footer_desc)}
                {console.log({ formInputs })}
                {console.log({ rowsData })}
                {console.log({ skuData })}
                {console.log({ bundleData })}
                <ArrowBackIcon className="Arrow-back-btn" style={{height:"30px", width:"30px", marginRight:"0.5rem"}} onClick={() => goBack()} />

                <EnhancedTable mainFile_modal={Modal_bundle} data_getter={data_getter} fileDownload={fileDownload} headCells={headCells} count={count} path={window.location.pathname} />
            </>}

        </RowsContext.Provider>

    </div>
    )
}


export default Bundle