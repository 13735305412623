import React, { useState } from 'react'
import Education from './Education'
import Intership from './Intership'
import ProfessionalExp from './ProffesionalExp'
import Achivements from './Achivements'
import Responsibilty from './Responsibilty'
import ResponsibiltyRLTable from './ResponsibiltyRLTable'
import Size from './Size.json'
import Header from './Header'
import InternshipFreeFlowing from './InternshipFreeFlowing'
import ACPFreeFlowinNew from './ACPFreeFlowinNew'
import Intership2 from './Intership2';
import ResponsibiltyFreeFlowing from './ResponsibiltyFreeFlowing'
import ACPFReeFlowingTable from './ACPFReeFlowingTable'
import AchivementsFreeFlowing from './AchivementsFreeFlowing'
import ProffesionalExpFreeFlowing from './ProffesionalExpFreeFlowing'
import Color from './Colors.json'
import LeadershipPosition from './LeadershipPosition'
import LeadershipPositionFreeFlowing from './LeadershipPositionFreeFlowing'
import ExtracurricularFreeFlowingRlNoLbox from './ExtracurricularFreeFlowingRlNoLbox'
import ExtraCurricular from './ExtraCurricular'
import ResponsibilityFreeFlowingRlNoLeftBox from './ResponsibilityFreeFlowingRlNoLeftBox'
import ExtraCurricularFreeFlowing from './ExtraCurricularFreeFlowing'
import ProfileSummaryFreeFlowing from './ProfileSummaryFreeFlowing'
import AcadAchivement from './AcadAchivement'
import AchivementSingle from './AchivementSingle'
import AcadAchivementFreeFlowing from './AcadAchivementFreeFlowing'
import AchivementRightAlligned2 from './AchivementRightAlligned2'
import AcadAchivementRightAlligned from './AcadAchivementRightAlligned'
import ResponsibiltyRLNoLeftBox from './ResponsibiltyRLNoLeftBox'
import ResponsibiltySingle from './ResponsibiltySingle'
import LeadershipPositionTabularSingle from './LeadershipPositionTabularSingle'
import AcadAchivementRightAllignedNoRoleTable from './AcadAchivementRightAllignedNoRoleTable'
import AcadAchivementSingle from './AcadAchivementSingle'
import AchivementExtendedTabular from './AchivementExtendedTabular'
import AchivememtExtendedFreeFlowing from './AchivememtExtendedFreeFlowing'
import ProfileSummary from './ProfileSummary'
import NonAiSingly from './NonAiSingly'
import InternshipTableWithoutRole from './InternshipTableWithoutRole'
// import ResumeLayout from './ResumeLayout.json'
function MiniatureResume(props) {
  const {sectionDetails, setSectionDetails} = props;
  console.log("sectionDetails", sectionDetails);
  const filteredSections = sectionDetails?.filter((section) => section.section_type_id === 2);
  const sectionNames2 = sectionDetails?.map((section) => section.section_name);
  console.log("sd", sectionNames2)
  const sectionNames = filteredSections?.map((section) => section.section_name);
  console.log("filteredSections", sectionNames);
  // console.log("names",name_id4);
  const sectionTypeIds = new Set(sectionDetails?.map((section) => section.section_type_id));
  const sectionNamesByType = {};
  for (const id of sectionTypeIds) {
    const filteredSections = sectionDetails?.filter((section) => section.section_type_id === id);
    const sectionNames = filteredSections.map((section) => section.section_name);
    sectionNamesByType[id] = sectionNames;
    console.log("sectionNamesByType", sectionNamesByType);
  }

// console.log("is_Primary",sectionTypeIdObjects);
var primarySections = sectionDetails?.filter(section => {
  return section.is_primary === 1||section.is_primary===0;
  }).map(section => {
  return {
  section_type_id: section.section_type_id,
  is_primary: section.is_primary,
  section_name: section.section_name
  }
  });
  
  console.log("primarySections",primarySections);
var formattedSections = sectionDetails?.map(section => {
  return {
  section_type_id: section.section_type_id,
  is_primary: section.is_primary
  }
  });
  console.log("formattedSections",formattedSections);

  
console.log("namin8",props.section_detail.section_name);
  const template_name = props.baseTemplate;
  const { userData, data, profileSummaryBullets,selectedValue, profileSummarySubSection, Academicdetail, projectData, sipData, extracurricularData, leadershipData, certficationData, workExData, image, picturePosition, logoPosition, showPicture, showAdress, showLogo, academicAchievementData, file,fontS ,ribbonColor,LineHeight,TableWidth,Bullet,FontSize,headerPosition, baseTemplate} = props 
  { console.log("cc", certficationData) }
  console.log("academics data from miniature resume", Academicdetail);
  console.log("selectedValue in miniature", selectedValue);
  let options = "Large"
  let className = "ResumeContent-Resume"
  console.log({fontS});
  const ResumeLayout = [
    {
      "id": 1,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <ProfileSummaryFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={false} profileSummaryBullets={data} profileSummarySubSection={profileSummarySubSection} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <NonAiSingly name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={true} Non_AI_all_subSection={profileSummarySubSection} Non_AI_all_bullets={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <NonAiSingly name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={false} Non_AI_all_bullets={data} Non_AI_all_subSection={profileSummarySubSection}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <NonAiSingly name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={false} Non_AI_all_bullets={data} Non_AI_all_subSection={profileSummarySubSection} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 2,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <AcadAchivementFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingRightAlligned", "Component": <AchivementRightAlligned2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <AcadAchivement name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "TabularRightAlligned", "Component": <AcadAchivementRightAlligned name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <AcadAchivementSingle name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingRightAlligned", "Component": <AcadAchivementRightAllignedNoRoleTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <AcadAchivement name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "TabularRightAlligned", "Component": <AcadAchivementRightAlligned name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data} minimaxWidth={Size[options].maxWidth} academicAchievementData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 3,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <InternshipFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} minimaxWidth={Size[options].maxWidth} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": < Intership2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <InternshipTableWithoutRole name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} minimaxWidth={Size[options].maxWidth} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": < Intership2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 4,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <InternshipFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} minimaxWidth={Size[options].maxWidth} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": < Intership2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <InternshipTableWithoutRole name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} minimaxWidth={Size[options].maxWidth} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": < Intership2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    // ACP ------------>Rendering
    {
      "id": 5,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <ACPFreeFlowinNew name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} minimaxWidth={Size[options].maxWidth}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingExtended", "Component": <AchivememtExtendedFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Achivements name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <ACPFReeFlowingTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingExtended", "Component": <AchivementExtendedTabular name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Achivements name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 6,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <ResponsibiltyFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingRightAlligned", "Component": <ResponsibilityFreeFlowingRlNoLeftBox name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Responsibilty name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "TabularRightAlligned", "Component": <ResponsibiltyRLTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <ResponsibiltySingle name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingRightAlligned", "Component": <ResponsibiltyRLNoLeftBox name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Responsibilty name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "TabularRightAlligned", "Component": <ResponsibiltyRLTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
      ]
    },
    {
      "id": 7,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <LeadershipPositionFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} leadershipData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Achivements name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <LeadershipPositionTabularSingle name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Achivements name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 8,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <ExtraCurricularFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} extracurricularData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingRightAlligned", "Component": <ExtracurricularFreeFlowingRlNoLbox name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <ExtraCurricular name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} extracurricularData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "TabularRightAlligned", "Component": <ResponsibiltyRLTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <AchivementSingle name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingRightAlligned", "Component": <ResponsibiltyRLNoLeftBox name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <ExtraCurricular name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} extracurricularData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "TabularRightAlligned", "Component": <ResponsibiltyRLTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} certficationData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 9,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <Education name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={true} Academicdetail={Academicdetail} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Education name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={false} Academicdetail={Academicdetail} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <Education name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={true} Academicdetail={Academicdetail} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Education name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={false} Academicdetail={Academicdetail} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },{
      "id": 10,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <InternshipFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} minimaxWidth={Size[options].maxWidth} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": < Intership2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <InternshipTableWithoutRole name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} minimaxWidth={Size[options].maxWidth} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": < Intership2 name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} sipData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    {
      "id": 11,
      "Free Flowing": [
        { "section_template": "Free Flowing", "Component": <ACPFreeFlowinNew name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} minimaxWidth={Size[options].maxWidth}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingExtended", "Component": <AchivememtExtendedFreeFlowing name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Achivements name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>}
      ],
      "Tabular": [
        { "section_template": "Free Flowing", "Component": <ACPFReeFlowingTable name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>},
        { "section_template": "FreeFlowingExtended", "Component": <AchivementExtendedTabular name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data}fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}  headerPosition={headerPosition}/>},
        { "section_template": "Tabular", "Component": <Achivements name={props.section_detail.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} projectData={data} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>}
      ]
    },
    
  ]
 
  const Render = () => {
        let item = props.section_detail
        let resumeSections = props.selectedValue
        console.log("itemmmm", item);
        console.log("resumeSections", resumeSections);
        let temp_list = ResumeLayout.filter((section) => (section.id === item.section_type_id))
        let temp_resume_list = ResumeLayout.filter((section) => (section.id === resumeSections.id))
        console.log("temp listtt", temp_list);
        console.log("temp temp_resume_list", temp_resume_list);
        
        let lay_out = <></>
        if (temp_list.length > 0) {
          lay_out = temp_list[0][template_name]?.filter((sec_tem) => ((sec_tem.section_template === item.value && item.on_off_locked === true) ))[0]?.Component || <></>
        }
        console.log("component", lay_out)
        if (item.section_type_id === 9 && template_name === 'Tabular')
          return (
            <div>
              <Education name={item.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={false} Academicdetail={Academicdetail} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>
            </div>)
        else if (item.section_type_id === 9 && template_name === 'Free Flowing')
          return (
            <div>
              <Education name={item.section_name} minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} styleEducation={true} Academicdetail={Academicdetail} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize} headerPosition={headerPosition}/>
            </div>)
        return (lay_out)
  }
  return (
    <div>
      {props.count==0&&<Header minwidth={Size[options].width} miniheight={Size[options].height}  minipadding={Size[options].padding} NameFontSize={Size[options].NameFontSize} imageWidth={Size[options].imageWidth} imageHeight={Size[options].imageHeight}
      userData={userData} image={image} picturePosition={picturePosition} logoPosition={logoPosition}
        headerImages={props.headerImages} showPicture={showPicture} showAdress={showAdress} showLogo={showLogo} file={file} fontS={fontS} ribbonColor={ribbonColor} LineHeight={LineHeight} TableWidth={TableWidth} Bullet={Bullet} FontSize={FontSize}
      />}
    <Render/>
    </div>
  )
}

export default MiniatureResume;
