import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Service from '../../services/httpService';
import Cookies from 'js-cookie';
import { Color } from '../../GlobalStyles';
import SideNavigationBar from '../reusables/SideNavigationBar/SideNavigationBar';
import CardContent from '@mui/material/CardContent';

const Analytics = () => {

    // useEffect(() => {
    //     console.log("Brother")
    //     const handleEffect = async () => {
    //       console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
    //       if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
    //         navigate('/');
    //       }
    //       //college getter
    //       let college = await mentorDataGetter();
    //       setCollegeData(college)
    //       // forming the data
    //       console.log("college details", { college })
    //     }
    //     handleEffect();
    //   }, [])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);

    const handleDashboardClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClick = () => {
        setProfileMenuOpen(true);
        setAnchorEl(null);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(false);
    };

    const handleMenuItemClick = (option) => {
        console.log(`Option "${option}" clicked`);
        handleProfileMenuClose();
    };
    return (
        <div>
            <SideNavigationBar />
            <Box display="flex" justifyContent="center" alignItems="center">
            <React.Fragment>
                <Button variant="contained" onClick={handleDashboardClick}>
                    Dashboard
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('My account')}>
                        My account
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Logout')}>Logout</MenuItem>
                </Menu>
                <Menu
                    anchorEl={anchorEl}
                    open={profileMenuOpen}
                    onClose={handleProfileMenuClose}
                >
                    <MenuItem onClick={() => handleMenuItemClick('Option 1')}>Option 1</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Option 2')}>Option 2</MenuItem>
                </Menu>
            </React.Fragment>
            </Box>
        </div>
    )
}

export default Analytics
