import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Dashboard from "../components/pages/Dashboard/Dashboard"
import { useSearchParams } from "react-router-dom";
import AppleSignin from 'react-apple-signin-auth';
// import UpcomingBooking from "../../screens/Mentors/UpcomingBooking/UpcomingBooking";
// import Validations from "../../screens/Validations/Validations";
import "../Login/Login.css";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import { AppleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// firebase apple login
// import * as firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, OAuthProvider, signInWithCredential, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

// import { useHistory } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);



  const firebaseConfig = {
    apiKey: "AIzaSyBbrNdmcQY1vAhXy3Zg0gHUJ3zWJCuAbRI",
    authDomain: "careercarvelogin.firebaseapp.com",
    projectId: "careercarvelogin",
    storageBucket: "careercarvelogin.appspot.com",
    messagingSenderId: "151843323088",
    appId: "1:151843323088:web:000a09c7a3d8067ae072d5"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);

  const provider = new OAuthProvider('apple.com');

  // const signInWithApple = () => {
  // provider.addScope('email');
  // provider.addScope('name');
  // console.log("apple sign in button clicked");
  // signInWithPopup(auth, provider)
  //   .then(async (result) => {
  //     // The signed-in user info.
  //     const user = result?.user;
  //     console.log("apple user deatils", user);
  //     // Apple credential
  //     const credential = OAuthProvider?.credentialFromResult(result);
  //     const accessToken = credential.accessToken;
  //     console.log("apple accessToken deatils", accessToken);
  //     console.log("user name", result.user.displayName);
  //     console.log("user email", result.user.email);

  //     const idToken = credential.idToken;
  //     console.log("apple idToken deatils", idToken);
  //     if (idToken !== null && idToken !== undefined) {
  //       if (result.user.email !== null && result.user.email !== undefined) {
  //         try {
  //           const res = await axios.post(
  //             "https://backend.resumes.digital/api/appleAuth",
  //             { id_token: idToken }
  //           );
  //           if (res.status === 200) {
  //             console.log("login with apple successful");
  //             navigate("/Dashboard")
  //           }
  //           console.log(res);
  //         } catch (e) {
  //           if (result.user.email === null || result.user.email === undefined) {
  //             toast.error("Email ID is required ")
  //           }
  //           console.log(e);
  //           console.log("Could not login the user");
  //         }
  //       }

  //     }
  //   })
  //   .catch((error) => {
  //     // Handle Errors here.
  //     console.log("apple sign in error", error);
  //     const errorCode = error.code;
  //     console.log("apple sign in error", errorCode);

  //     const errorMessage = error.message;
  //     console.log("apple sign in error", errorMessage);

  //     // The email of the user's account used.
  //     const email = error.customData.email;
  //     console.log("apple sign in email", email);

  //     // The credential that was used.
  //     const credential = OAuthProvider.credentialFromError(error);
  //     console.log("apple sign in credential", credential);
  //   });
  // };

  // handle sign in click
  async function handleSignInClick() {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, new OAuthProvider("apple.com"));
      // The signed-in user info.
      const user = result?.user;
      console.log("apple user deatils", user);
      // Apple credential
      const credential = OAuthProvider?.credentialFromResult(result);
      const accessToken = credential.accessToken;
      console.log("apple accessToken deatils", accessToken);
      console.log("user name", result.user.displayName);
      console.log("user email", result.user.email);

      const idToken = credential.idToken;
      console.log("apple idToken deatils", idToken);
      if (idToken !== null && idToken !== undefined) {
        if (result.user.email !== null && result.user.email !== undefined) {
          const res = await axios.post(
            `${process.env.REACT_APP_RD_API_URL}/appleAuth`,
            { id_token: idToken }
          );
          if (res.status === 200 && res.data.user_type === 'admin') {
            console.log("login with apple successful");
            navigate("/Dashboard");
            Cookies.set("token", res.headers.jwt, { expires: 182.5 });
          }
          console.log(res);
        } else {
          if (result.user.email === null || result.user.email === undefined) {
            toast.error("Email ID is required ")
          }
        }
      }
    } catch (error) {
      console.log("apple sign in error", error);
      const errorCode = error.code;
      console.log("apple sign in error", errorCode);

      const errorMessage = error.message;
      console.log("apple sign in error", errorMessage);

      const email = error.customData.email;
      console.log("apple sign in email", email);

      const credential = OAuthProvider.credentialFromError(error);
      console.log("apple sign in credential", credential);
    } finally {
      setIsLoading(false);
    }
  }

  const errRef = useRef();
  const clientId =
    "331878265619-n1b1ia44qjlp90amsdg2trohjqt9m51o.apps.googleusercontent.com";
  // apple login seach params
  const [searchParams, setSearchParams] = useSearchParams();
  const appleCode = searchParams.get("code")
  console.log("code", appleCode);

  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  console.log("isSubmit", formErrors);

  const [appleResponse, setAppleResponse] = useState()

  const [errMsg, setErrMsg] = useState("");
  //for validation
  console.log("isSubmit", errMsg);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const [isSubmit, setIsSubmit] = useState(false);
  console.log("isSubmit", isSubmit);
  // const [showPassword, setShowPassword] = useState(false);
  // const [id_token, setId_token] = useState(null)



  const handleSubmit = async e => {
    e.preventDefault();
    const formError = validate(formValues);
    setFormErrors(formError);
    if (Object.keys(formError).length === 0) {
      try {
        setIsSubmit(true);
        const res = await axios.post(
          `${process.env.REACT_APP_RD_API_URL}/login`,
          {
            email: formValues.email,
            password: formValues.password,
          }
        );
        console.log({ res });
        console.log(res.data.message);
        if (res.status === 200 && res.data.user_type === 'admin') {
          navigate("/Dashboard", {
            state: { formValues: formValues },
          });
          // alert.box(<h1>DASHBOARD</h1>);
          console.log("Dashboard");
          setErrMsg("Login successful.");
          const cookies = res.headers.jwt;
          console.log({ cookies });
          Cookies.set("token", res.headers.jwt, { expires: 182.5 });
        }
        else if (res.data.user_type !== 'admin') {
          setErrMsg(<p>You are not registered as an admin.</p>);
          window.alert("You are not registered as an admin.")
        }

        console.log(res);
      } catch (err) {
        if (
          err?.response.status === 401 &&
          err?.response.data.message === "user not registered"

        ) {
          setErrMsg(<p>You are not registered yet, sign up.</p>);
          console.log(err);
          window.alert("user not registered")
        } else if (
          err?.response.status === 401 &&
          err?.response.data.message === "unauthorized"
        ) {
          setErrMsg(<p>Wrong Password</p>);
          console.log(err);
          window.alert("Wrong Password")
        } else if (
          err?.response.status === 401 &&
          err?.response.data.message === "wrong password"
        ) {
          setErrMsg(<p>Wrong Password</p>);
          console.log(err);
        } else if (
          err?.response.status === 500 &&
          err?.response.data.message === "Unknown Error Occurred"
        )
          setErrMsg(<p>You are not a mentor</p>);
        else {
          console.log(err?.response.data.message);
          setErrMsg(<p>Wrong Password</p>);
        }
        errRef.current.focus();

      }
    }
  };

  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
    setErrMsg("");
    console.log("email", formValues.email);
    console.log("password", formValues.password);
  };

  // validate password
  const validate = values => {
    const errors = {};
    // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  // useeffect of
  // useEffect(() => {
  //   const handleEffect = async () => {
  //     const headers = {
  //       Authorization: `Bearer ${Cookies.get("token")}`,
  //     };
  //     if (Cookies.get('token') !== null || Cookies.get('token') !== undefined ) {
  //       navigate('/Dashboard');
  //   }

  //   console.log("cookies", headers);


  //   };
  //   handleEffect();
  // }, []);
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/Dashboard");
    }
  }, []);
  const text_alignment = "center";
  const googleButtonWidth = 465;



  return (
    <div className="login-Container">
      <ToastContainer position="top-center" />
      <div className="login-Resumes_Image">
        <img className="imageFlexible"
          src="backgroundImage.png"
          alt="background"
          style={{
            backgroundRepeat: "none",
            float: "left",
            margin: "0",
            width: "100%",
            height: "100%",
            position: 'static'
          }}
        />
        <img className="imageFlexible"
          src="logo_welcome.png"
          alt="background"
          style={{
            position: "absolute",
            height: "150px",
            width: "150px",
            top: "max(30%,200px)",
            left: "max(20%, 150px)",

          }}
        />
        <img
          src="CareerCarve.png"
          alt="background"
          style={{
            position: "absolute",
            top: "max(52%,350px)",
            left: "max(13%,150px)",
          }}
        />
        <p
          style={{
            position: "absolute",
            top: "max(62%, 430px)",
            left: "max(13.5%, 150px)",
            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>

      <div className="login-Main-content">
        <p className="login-name">Login</p>
        <div className="login-l-form">
          <form action="" class="login-form">
            <div className="outlined-basic">
              <TextField
                className=""
                onChange={handleChange}
                value={formValues.email}
                name="email"
                id="outlined-basic"
                label="Email "
                variant="outlined"
                sx={{ width: 400, mb: 3 }}
                InputProps={{ sx: { height: 50.4 } }}
                style={{ marginTop: "20px", textAlign: 'center', borderRadius: '5px' }}
              />
              <br />
              {/* <TextField
              onChange={handleChange}
              value={formValues.password}
              name="password"
              id="outlined-basic"
              label="Password"
              variant="outlined"
              sx={{ width: 400, mb: 3 }}
              InputProps={{ sx: { height: 50.4 } }}
            /> */}
              <FormControl
                sx={{ width: 400, mb: 3 }}
                InputProps={{ sx: { height: 50.4 } }}
                variant="outlined"
                name="password"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  onChange={handleChange}
                  value={formValues.password}
                  id="outlined-adornment-password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <button className="Login-button" style={{ textAlign: 'center', marginBottom: '1.5rem' }} onClick={e => handleSubmit(e)}>
                Login
              </button>

              <div className="loginOrlogin">
                <div className="line-login"></div>
                <p style={{ marginTop: '0', paddingLeft: '5px', paddingRight: '5px' }}>or</p>
                <div className="line-login"></div>
              </div>
            </div>
            {/* <button type="button" class="login-with-google-btn">
              Login with Google
            </button> */}
            {/* google auth login */}
            <div>
              <div style={{ margin: 'auto' }} className="signInButton" id="signInGoogleButton">
                <GoogleOAuthProvider width={googleButtonWidth} clientId={clientId} text="Login with Google">
                  <GoogleLogin width={googleButtonWidth} text="Login with Google"
                    logo_alignment={text_alignment}
                    style={{ border: '1px solid #545454' }}
                    onSuccess={async credentialResponse => {
                      console.log("Google Auth Sucess.");
                      console.log(
                        "token that is sent to bcknd:",
                        credentialResponse.credential
                      );
                      console.log(
                        "token that is sent to bcknd:",
                        jwt_decode(credentialResponse.credential)
                      );
                      try {
                        const response = await axios.post(
                          `${process.env.REACT_APP_RD_API_URL}/googleAuth`,
                          { id_token: credentialResponse.credential }
                        );
                        console.log({ response });
                        if (response.status === 200 && response.data.user_type === 'admin') {
                          Cookies.set("token", response.headers.jwt, {
                            expires: 182.5,
                          });
                          navigate("/Dashboard", {
                            state: { formValues: formValues },
                          });
                          console.log("DASHBOARD");
                          setErrMsg("Login successful.");
                        }
                      } catch (err) {
                        console.log(err);
                        if (
                          err?.response.status === 400 &&
                          err?.response.data.message === "Unverified account"
                        ) {
                          setErrMsg("This email is Unverified.");
                        } else if (
                          err?.response.status === 400 &&
                          err?.response.data.message ===
                          "Alternate email already existing."
                        ) {
                          setErrMsg("Alternate email already existing.");
                        } else if (
                          err?.response.status === 400 &&
                          err?.response.data.message ===
                          "Email already exists as alternate."
                        ) {
                          setErrMsg(
                            "This email already exists as an secondary email. Please login with your primary email."
                          );
                        } else if (
                          err?.response.status === 500 &&
                          err?.response.data.message ===
                          "Alternate email entry doesn't exists"
                        ) {
                          setErrMsg("Alternate email entry doesn't exists.");
                        }
                      }
                    }}
                    onError={() => {
                      window.alert("Login Failed");
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>

            <div style={{ marginTop: '2rem', textAlign: "center" }}>
              {/* <AppleSignin
                authOptions={{
                  clientId: 'com.careercarve.careercarveportal',
                  scope: 'name email',
                  usePopup: true,
                  redirectURI: 'https://main.d1i8z57a7c5ffp.amplifyapp.com/',
                }}
                onSuccess={singInApple}
                onError={(error) => console.error(error)}
                className="apple-auth-btn"
                noDefaultStyle={false}
                iconProp={{ style: { marginTop: '10px' } }}
                buttonExtraChildren="Continue with Apple"
                render={(props) => <button {...props}>Continue with Apple</button>}
              /> */}
              <button className="apple-sign-up-button" onClick={handleSignInClick} disabled={isLoading}>
                <img className="appleIcon" src="apple.svg" />
                Sign in with Apple</button>
              {/* <Button
                icon={<AppleOutlined />}
                size="large" 
                onClick={signInWithApple}
              >
                Sign in with Apple
              </Button> */}
            </div>
          </form>
        </div>
        <p
          ref={errRef}
          className="alert-errMsg-button"
          aria-live="assertive">{errMsg}</p>
      </div>
    </div>
  );
};

export default Login;