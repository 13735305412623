import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Download, UploadFile, Done } from "@mui/icons-material";
import "./detailModal.css";
import "./modal.css";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Menu, Edit } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
function DetailModal({ type, close, onEdit, onAddEpisode, selectedDetailModal, activityList, specializationList, roleList, handleDragAndDropChange, handleEditEpisodeIcon }) {
  const [checked, setChecked] = useState(selectedDetailModal.is_active === 1 ? true : false);
  const [isVisible, setIsVisible] = useState(selectedDetailModal.visible_in_home === 1 ? true : false);

  const location = useLocation();
  const [showEpisodeModal, setShowEpisodeModal] = useState(false);
  const swapElement = (arr, source, destination) => {
    let tempArray = arr;
    let element = tempArray[source];
    tempArray.splice(source, 1);
    tempArray.splice(destination, 0, element);
    tempArray = tempArray.map((item, index) => {
      return {
        ...item,
        episode_order: index + 1,
      };
    });

    return tempArray;
  };

  const services = new CCService();

  //USING PARSE FUNCTION TO PARSE DATA
  const handleDragEnd = async (e) => {
    let arr = selectedDetailModal.episode_link;
    let temp_episodeLink = swapElement(arr, e.source.index, e.destination.index);
    const body = {
      station_id: selectedDetailModal.station_id,
      name: selectedDetailModal.name,
      description: selectedDetailModal.description,
      search_keywords: selectedDetailModal.search_keywords,
      activity: selectedDetailModal.activities.toString(),
      specialization: selectedDetailModal.specialization,
      role: selectedDetailModal.roles.toString(),
      recommendation_keywords: selectedDetailModal.recommendation_keywords,
      episode_link: temp_episodeLink.map((item) => {
        return {
          episode_id: item.episode_id,
          episode_order: item.episode_order,
        };
      }),
      station_or_classification: type,
    };
    const putReq = await services.put("/podcast/stations", body);
    if (putReq.status === 200) {
      // handleDragAndDropChange(temp_episodeLink);
    }
  };

  const swapListToShow = (arr, source, destination) => {
    let tempArray = arr;
    let element = tempArray[source];
    tempArray.splice(source, 1);
    tempArray.splice(destination, 0, element);
    return tempArray;
  };
  const handleDndForDisplay = async (e) => {
    let arr = selectedDetailModal.list_to_show;

    let temp_episodeLink = swapListToShow(arr, e.source.index, e.destination.index);

    const body = {
      collection_id: selectedDetailModal.collection_id,
      content_type: selectedDetailModal.content_type,
      collection_name: selectedDetailModal.collection_name,
      is_active: selectedDetailModal.is_active,
      station_or_classification: selectedDetailModal.station_or_classification,
      content_ids: temp_episodeLink.map((item) => item.id).toString(),
    };

    const putRequest = await services.put("/podcast/collectionView", body);
  };
  const handleSwitchChange = async (e) => {
    setChecked(e.target.checked);
    const body = {
      collection_id: selectedDetailModal.collection_id,
      content_type: selectedDetailModal.content_type,
      collection_name: selectedDetailModal.collection_name,
      is_active: e.target.checked === true ? 1 : 0,
      visible_in_home: isVisible === true ? 1 : 0,
      station_or_classification: selectedDetailModal.station_or_classification,
    };

    const putRequest = await services.put("/podcast/collectionView", body);

    console.log("THI SIS CALLED 1", e.target.checked);
  };

  const handleActiveChange = async (e) => {
    setIsVisible(e.target.checked);
    const body = {
      collection_id: selectedDetailModal.collection_id,
      content_type: selectedDetailModal.content_type,
      collection_name: selectedDetailModal.collection_name,
      is_active: checked === true ? 1 : 0,
      visible_in_home: e.target.checked === true ? 1 : 0,
      station_or_classification: selectedDetailModal.station_or_classification,
    };

    const putRequest = await services.put("/podcast/collectionView", body);

    console.log("THI SIS CALLED 2", e.target.checked);
  };
  const IOSSwitch = styled((props) => <Switch disableRipple onChange={props.handleChange} checked={props.value} {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#1C8EA8",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box className="detailmodal_main-div" sx={{ width: "716px" }}>
      {type === "episode" && (
        <Box sx={{ padding: "24px 40px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {console.log("selectedModal", selectedDetailModal)}

              <Typography sx={{ fontSize: "32px" }}>{selectedDetailModal.name}</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <IconButton onClick={() => onAddEpisode()}>
                  <Edit sx={{ color: "#1C8EA8" }} />
                </IconButton>
                <IconButton onClick={() => close()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <button
              className="detailmodal__edit-button"
              onClick={() => onAddEpisode()}
            >
              Edit
            </button> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Mentor Name:</Typography>
                <Box sx={{ width: "80%", display: "flex" }}>
                  {selectedDetailModal.mentor_details.map((item) => (
                    <Typography sx={{ fontSize: "16px", width: "80%" }}>{item.mentorName}</Typography>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Description::</Typography>
                <Box sx={{ width: "80%", display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", width: "80%" }}>{selectedDetailModal.about}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Picture:</Typography>
                {[null, "null", ""].includes(selectedDetailModal.image) ? (
                  <Typography sx={{ fontSize: "14px", color: "red" }}>No image Available</Typography>
                ) : (
                  <a href={selectedDetailModal.image} style={{ width: "85%", textDecoration: "none" }} target="_blank">
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#1C8EA8",
                      }}
                    >
                      {selectedDetailModal.name.replaceAll("_", " ")}.jpg
                    </Typography>
                  </a>
                )}
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>{location.pathname === "/Podcast" ? "Audio" : "Video"}</Typography>
                <a href={selectedDetailModal.recording} target="_blank" style={{ width: "80%", textDecoration: "none" }} rel="noreferrer">
                  <Typography
                    sx={{
                      fontSize: "16px",

                      color: "#1C8EA8",
                    }}
                  >
                    {location.pathname === "/Podcast" ? `${selectedDetailModal.name}.mp3` : `${selectedDetailModal.name}.mp4`}
                  </Typography>
                </a>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Duration:</Typography>
                <Typography sx={{ fontSize: "16px", width: "80%" }}>22 mins</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Keywords:</Typography>
                <Box display={"flex"} gap={"1rem"} sx={{ flexWrap: "wrap" }} width={"80%"}>
                  {selectedDetailModal.keywords.map((item) => (
                    <Typography
                      sx={{
                        fontSize: "16px",
                        backgroundColor: "#E6E6E6",
                        borderRadius: "61px",
                        padding: "10px",
                      }}
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Linked to:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    gap: "10px",
                  }}
                >
                  {selectedDetailModal.activities.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.activities.map((item) => {
                        let temp = activityList.find((ele) => ele.id === item);
                        return (
                          <Box
                            sx={{
                              display: "inline",
                              backgroundColor: "#E6E6E6",
                              padding: "5px",
                              marginBottom: "60px",
                              marginInline: "5px",
                            }}
                          >
                            {temp.act_type_name}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {selectedDetailModal.roles.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.roles.map((item) => {
                        let temp = roleList.find((ele) => ele.id === item);
                        return (
                          <Box
                            sx={{
                              display: "inline",
                              backgroundColor: "#E6E6E6",
                              padding: "5px",
                              marginBottom: "60px",
                              marginInline: "5px",
                            }}
                          >
                            {temp ? temp.name : ""}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {selectedDetailModal.specialization.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.specialization.map((item) => (
                        <Box
                          sx={{
                            display: "inline",
                            backgroundColor: "#E6E6E6",
                            padding: "5px",
                            marginBottom: "60px",
                            marginInline: "5px",
                          }}
                        >
                          {item}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
          </Box>
        </Box>
      )}

      {type === "station" && (
        <Box sx={{ padding: "24px 40px", marginBottom: "40px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "32px" }}>{selectedDetailModal.name}</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <IconButton onClick={() => onEdit()}>
                  <Edit sx={{ color: "#1C8EA8" }} />
                </IconButton>
                <IconButton onClick={() => close()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <button
              className="detailmodal__edit-button"
              onClick={() => onEdit()}
            >
              Edit
            </button> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Description:</Typography>
                <Typography sx={{ fontSize: "16px", width: "85%" }}>{selectedDetailModal.description}</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Picture:</Typography>
                <a href={selectedDetailModal.image} style={{ width: "85%", textDecoration: "none" }} target="_blank">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#1C8EA8",
                    }}
                  >
                    {/* {selectedDetailModal.image.split("/")[selectedDetailModal.image.split("/").length - 1]} */}
                    {selectedDetailModal.name.replaceAll("_", " ")}.jpg
                  </Typography>
                </a>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Keywords:</Typography>
                <Box display={"flex"} gap={"1rem"}>
                  {selectedDetailModal.search_keywords.map((item) => (
                    <Typography
                      sx={{
                        fontSize: "16px",
                        backgroundColor: "#E6E6E6",
                        borderRadius: "61px",
                        padding: "10px",
                      }}
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Linked to:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "85%",
                    gap: "10px",
                  }}
                >
                  {selectedDetailModal.activities.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.activities.map((item) => {
                        let temp = activityList.find((ele) => ele.id === item);
                        return (
                          <Box
                            sx={{
                              display: "inline",
                              backgroundColor: "#E6E6E6",
                              padding: "5px",
                              marginBottom: "60px",
                              marginInline: "5px",
                            }}
                          >
                            {temp.act_type_name}
                          </Box>
                          // <Typography sx={{ fontSize: "16px" }}>

                          // </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {selectedDetailModal.roles.length > 0 && (
                    <Box
                      gap={"10px"}
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.roles.map((item) => {
                        let temp = roleList.find((ele) => ele.id === item);
                        return (
                          <Box
                            sx={{
                              display: "inline",
                              backgroundColor: "#E6E6E6",
                              padding: "5px",
                              marginBottom: "60px",
                              marginInline: "5px",
                            }}
                          >
                            {temp ? temp.name : ""}
                          </Box>
                          // <Typography
                          //   sx={{
                          //     fontSize: "14px",
                          //     display: "inline",
                          //     backgroundColor: "#E6E6E6",
                          //     // borderRadius: "61px",
                          //     padding: "5px",
                          //     marginBlock: "20px",
                          //     marginInline: "5px",
                          //   }}
                          // >

                          // </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {selectedDetailModal.specialization.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.specialization.map((item) => (
                        <Box
                          sx={{
                            display: "inline",
                            backgroundColor: "#E6E6E6",
                            padding: "5px",
                            marginBottom: "60px",
                            marginInline: "5px",
                          }}
                        >
                          {item}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "20px" }}>Episodes</Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={() => onAddEpisode()}>
                  <Add />
                </IconButton>
                <IconButton>
                  <Download />
                </IconButton>
                <IconButton>
                  <UploadFile />
                </IconButton>
              </Box>
            </Box>
            {console.log("SelectedDetailModal", selectedDetailModal)}
            {/* <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable__station">
                {(provider, snapshot) => (
                  <Box
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {selectedDetailModal.episode_link.map((episode, index) => (
                      <Draggable
                        draggableId={JSON.stringify(episode.episode_order)}
                        index={index}
                      >
                        {(provider, snapshot) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBlock: "0.5rem",
                            }}
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={episode.episode_image}
                                alt={episode.episode_image}
                                style={{ width: "53px", height: "33px" }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.1rem",
                                }}
                              >
                            
                                <Typography sx={{ fontSize: "16px" }}>
                                  {episode.episode_name}
                                </Typography>
                                <Box display={"flex"} gap={"10px"}>
                                  {episode.mentors.map((mentor) => (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#8B8B8B",
                                      }}
                                    >
                                      {mentor.mentor_name}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                            <IconButton
                              sx={{
                                color: "#1C8EA8",
                                "&:hover": { backgroundColor: "none" },
                              }}
                              {...provider.dragHandleProps}
                            >
                              <Menu />
                            </IconButton>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </DragDropContext> */}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable__station">
                {(provider, snapshot) => (
                  <Box
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {selectedDetailModal.episode_link.map((episode, index) => (
                      <Draggable draggableId={JSON.stringify(index)} index={index}>
                        {(provider, snapshot) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBlock: "0.5rem",
                            }}
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <img src={episode.episode_image} alt={episode.episode_image} style={{ width: "53px", height: "33px" }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.1rem",
                                }}
                              >
                                {/* <Typography sx={{ fontSize: '16px' }}>{episode.episode_name}</Typography>
<Typography sx={{ fontSize: '14px', color: '#8B8B8B' }}>
{episode.mentor_name}
</Typography> */}
                                <Typography sx={{ fontSize: "16px" }}>{episode.episode_name}</Typography>
                                <Box display={"flex"} gap={"10px"}>
                                  {episode.mentors.map((mentor) => (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#8B8B8B",
                                      }}
                                    >
                                      {mentor.mentor_name}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => handleEditEpisodeIcon(episode.episode_id)}
                                sx={{
                                  color: "#1C8EA8",
                                  "&:hover": { backgroundColor: "none" },
                                }}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                sx={{
                                  color: "#1C8EA8",
                                  "&:hover": { backgroundColor: "none" },
                                }}
                                {...provider.dragHandleProps}
                              >
                                <Menu />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      )}

      {type === "classification" && (
        //         <Box sx={{ padding: "24px 40px", marginBottom: "40px" }}>
        //           <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        //             <Box
        //               sx={{
        //                 display: "flex",
        //                 alignItems: "center",
        //                 justifyContent: "space-between",
        //               }}
        //             >
        //               <Typography sx={{ fontSize: "32px" }}>
        //                 {selectedDetailModal.name}
        //               </Typography>
        //               <IconButton onClick={() => close()}>
        //                 <CloseIcon />
        //               </IconButton>
        //             </Box>
        //             <button
        //               className="detailmodal__edit-button"
        //               onClick={() => onEdit()}
        //             >
        //               Edit
        //             </button>
        //             <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        //               <Box sx={{ display: "flex" }}>
        //                 <Typography
        //                   sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}
        //                 >
        //                   Description:
        //                 </Typography>
        //                 <Typography sx={{ fontSize: "16px", width: "85%" }}>
        //                   {selectedDetailModal.description}
        //                 </Typography>
        //               </Box>
        //               <Box sx={{ display: "flex" }}>
        //                 <Typography
        //                   sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}
        //                 >
        //                   Picture:
        //                 </Typography>
        //                 <a
        //                   href={selectedDetailModal.image}
        //                   style={{ width: "85%", textDecoration: "none" }}
        //                   target="_blank"
        //                 >
        //                   <Typography
        //                     sx={{
        //                       fontSize: "16px",
        //                       color: "#1C8EA8",
        //                     }}
        //                   >
        //                     {selectedDetailModal.name}.jpg
        //                   </Typography>
        //                 </a>
        //               </Box>
        //               <Box sx={{ display: "flex", alignItems: "center" }}>
        //                 <Typography
        //                   sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}
        //                 >
        //                   Keywords:
        //                 </Typography>
        //                 <Box display={"flex"} gap={"1rem"}>
        //                   {selectedDetailModal.search_keywords.map((item) => (
        //                     <Typography
        //                       sx={{
        //                         fontSize: "16px",
        //                         backgroundColor: "#E6E6E6",
        //                         borderRadius: "61px",
        //                         padding: "10px",
        //                       }}
        //                     >
        //                       {item}
        //                     </Typography>
        //                   ))}
        //                 </Box>
        //               </Box>
        //               <Box sx={{ display: "flex" }}>
        //                 <Typography
        //                   sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}
        //                 >
        //                   Linked to:
        //                 </Typography>
        //                 <Box
        //                   sx={{
        //                     display: "flex",
        //                     flexDirection: "column",
        //                     width: "85%",
        //                   }}
        //                 >
        //                   <Box display={"flex"} gap={"10px"}>
        //                     {selectedDetailModal.activities.map((item) => {
        //                       let temp = activityList.find((ele) => ele.id === item);
        //                       return (
        //                         <Typography sx={{ fontSize: "16px" }}>
        //                           {temp.act_type_name},
        //                         </Typography>
        //                       );
        //                     })}
        //                   </Box>
        //                   <Box display={"flex"} gap={"10px"}>
        //                     {selectedDetailModal.roles.map((item) => {
        //                       let temp = roleList.find((ele) => ele.id === item);
        //                       return (
        //                         <Typography sx={{ fontSize: "16px" }}>
        //                           {temp.name},
        //                         </Typography>
        //                       );
        //                     })}
        //                   </Box>{" "}
        //                   <Box sx={{ display: "flex", gap: "10px" }}>
        //                     {selectedDetailModal.specialization.map((item) => (
        //                       <Typography sx={{ fontSize: "16px" }}>{item},</Typography>
        //                     ))}
        //                   </Box>
        //                 </Box>
        //               </Box>
        //             </Box>
        //             <Box
        //               sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}
        //             ></Box>
        //             <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        //               <Typography sx={{ fontSize: "20px" }}>Episodes</Typography>
        //               <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        //                 <IconButton onClick={() => onAddEpisode()}>
        //                   <Add />
        //                 </IconButton>
        //                 <IconButton>
        //                   <Download />
        //                 </IconButton>
        //                 <IconButton>
        //                   <UploadFile />
        //                 </IconButton>
        //               </Box>
        //             </Box>

        //             <DragDropContext onDragEnd={handleDragEnd}>
        //               <Droppable droppableId="droppable__classification">
        //                 {(provider, snapshot) => (
        //                   <Box
        //                     {...provider.droppableProps}
        //                     ref={provider.innerRef}
        //                     sx={{
        //                       display: "flex",
        //                       flexDirection: "column",
        //                     }}
        //                   >
        //                     {selectedDetailModal.episode_link.map((episode, index) => (
        //                       <Draggable
        //                         draggableId={JSON.stringify(episode.episode_order)}
        //                         index={index}
        //                       >
        //                         {(provider, snapshot) => (
        //                           <Box
        //                             sx={{
        //                               display: "flex",
        //                               justifyContent: "space-between",
        //                               marginBlock: "0.5rem",
        //                             }}
        //                             ref={provider.innerRef}
        //                             {...provider.draggableProps}
        //                           >
        //                             <Box
        //                               sx={{
        //                                 display: "flex",
        //                                 gap: "1rem",
        //                                 alignItems: "center",
        //                               }}
        //                             >
        //                               <img
        //                                 src={episode.episode_image}
        //                                 alt={episode.episode_image}
        //                                 style={{ width: "53px", height: "33px" }}
        //                               />
        //                               <Box
        //                                 sx={{
        //                                   display: "flex",
        //                                   flexDirection: "column",
        //                                   gap: "0.1rem",
        //                                 }}
        //                               >
        //                                 {/* <Typography sx={{ fontSize: '16px' }}>{episode.episode_name}</Typography>
        // <Typography sx={{ fontSize: '14px', color: '#8B8B8B' }}>
        // {episode.mentor_name}
        // </Typography> */}
        //                                 <Typography sx={{ fontSize: "16px" }}>
        //                                   {episode.episode_name}
        //                                 </Typography>
        //                                 <Box display={"flex"} gap={"10px"}>
        //                                   {episode.mentors.map((mentor) => (
        //                                     <Typography
        //                                       sx={{
        //                                         fontSize: "14px",
        //                                         color: "#8B8B8B",
        //                                       }}
        //                                     >
        //                                       {mentor.mentor_name}
        //                                     </Typography>
        //                                   ))}
        //                                 </Box>
        //                               </Box>
        //                             </Box>
        //                             <IconButton
        //                               sx={{
        //                                 color: "#1C8EA8",
        //                                 "&:hover": { backgroundColor: "none" },
        //                               }}
        //                               {...provider.dragHandleProps}
        //                             >
        //                               <Menu />
        //                             </IconButton>
        //                           </Box>
        //                         )}
        //                       </Draggable>
        //                     ))}
        //                   </Box>
        //                 )}
        //               </Droppable>
        //             </DragDropContext>
        //           </Box>
        //         </Box>
        <Box sx={{ padding: "24px 40px", marginBottom: "40px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "32px" }}>{selectedDetailModal.name}</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <IconButton onClick={() => onEdit()}>
                  <Edit sx={{ color: "#1C8EA8" }} />
                </IconButton>
                <IconButton onClick={() => close()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <button className="detailmodal__edit-button">Edit</button> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Description:</Typography>
                <Typography sx={{ fontSize: "16px", width: "85%" }}>{selectedDetailModal.description}</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Picture:</Typography>
                <a href={selectedDetailModal.image} style={{ width: "85%", textDecoration: "none" }} target="_blank">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#1C8EA8",
                    }}
                  >
                    {/* {selectedDetailModal.image.split("/")[selectedDetailModal.image.split("/").length - 1]} */}
                    {selectedDetailModal.name.replaceAll("_", "")}.jpg
                  </Typography>
                </a>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Keywords:</Typography>
                <Box display={"flex"} gap={"1rem"}>
                  {selectedDetailModal.search_keywords.map((item) => (
                    <Typography
                      sx={{
                        fontSize: "16px",
                        backgroundColor: "#E6E6E6",
                        borderRadius: "61px",
                        padding: "10px",
                      }}
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "15%" }}>Linked to:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "85%",
                    gap: "10px",
                  }}
                >
                  {selectedDetailModal.activities.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.activities.map((item) => {
                        let temp = activityList.find((ele) => ele.id === item);
                        return (
                          <Box
                            sx={{
                              display: "inline",
                              backgroundColor: "#E6E6E6",
                              padding: "5px",
                              marginBottom: "60px",
                              marginInline: "5px",
                            }}
                          >
                            {temp.act_type_name}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {selectedDetailModal.roles.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.roles.map((item) => {
                        let temp = roleList.find((ele) => ele.id === item);
                        return (
                          <Box
                            sx={{
                              display: "inline",
                              backgroundColor: "#E6E6E6",
                              padding: "5px",
                              marginBottom: "60px",
                              marginInline: "5px",
                            }}
                          >
                            {temp ? temp.name : ""}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {selectedDetailModal.specialization.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #1C8EA8",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {selectedDetailModal.specialization.map((item) => (
                        <Box
                          sx={{
                            display: "inline",
                            backgroundColor: "#E6E6E6",
                            padding: "5px",
                            marginBottom: "60px",
                            marginInline: "5px",
                          }}
                        >
                          {item}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "20px" }}>Episodes</Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={() => onAddEpisode()}>
                  <Add />
                </IconButton>
                <IconButton>
                  <Download />
                </IconButton>
                <IconButton>
                  <UploadFile />
                </IconButton>
              </Box>
            </Box>
            {/* 
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable__classification">
                {(provider, snapshot) => (
                  <Box
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {selectedDetailModal.episode_link.map((episode, index) => (
                      <Draggable draggableId={JSON.stringify()} index={index}>
                        {(provider, snapshot) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBlock: "0.5rem",
                            }}
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={episode.episode_image}
                                alt={episode.episode_image}
                                style={{ width: "53px", height: "33px" }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.1rem",
                                }}
                              >
                              
                                <Typography sx={{ fontSize: "16px" }}>
                                  {episode.episode_name}
                                </Typography>
                                <Box display={"flex"} gap={"10px"}>
                                  {episode.mentors.map((mentor) => (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#8B8B8B",
                                      }}
                                    >
                                      {mentor.mentor_name}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                            <IconButton
                              sx={{
                                color: "#1C8EA8",
                                "&:hover": { backgroundColor: "none" },
                              }}
                              {...provider.dragHandleProps}
                            >
                              <Menu />
                            </IconButton>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </DragDropContext> */}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable__station">
                {(provider, snapshot) => (
                  <Box
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {selectedDetailModal.episode_link.map((episode, index) => (
                      <Draggable draggableId={JSON.stringify(index)} index={index}>
                        {(provider, snapshot) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBlock: "0.5rem",
                            }}
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <img src={episode.episode_image} alt={episode.episode_image} style={{ width: "53px", height: "33px" }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.1rem",
                                }}
                              >
                                {/* <Typography sx={{ fontSize: '16px' }}>{episode.episode_name}</Typography>
<Typography sx={{ fontSize: '14px', color: '#8B8B8B' }}>
{episode.mentor_name}
</Typography> */}
                                <Typography sx={{ fontSize: "16px" }}>{episode.episode_name}</Typography>
                                <Box display={"flex"} gap={"10px"}>
                                  {episode.mentors.map((mentor) => (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#8B8B8B",
                                      }}
                                    >
                                      {mentor.mentor_name}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => handleEditEpisodeIcon(episode.episode_id)}
                                sx={{
                                  color: "#1C8EA8",
                                  "&:hover": { backgroundColor: "none" },
                                }}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                sx={{
                                  color: "#1C8EA8",
                                  "&:hover": { backgroundColor: "none" },
                                }}
                                {...provider.dragHandleProps}
                              >
                                <Menu />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      )}

      {type === "display" && (
        <Box sx={{ padding: "24px 40px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "32px" }}>{selectedDetailModal.collection_name}</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <IconButton onClick={() => onEdit()}>
                  <Edit sx={{ color: "#1C8EA8" }} />
                </IconButton>
                <IconButton onClick={() => close()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <button
              className="detailmodal__edit-button"
              onClick={() => onEdit()}
            >
              Edit
            </button> */}
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Typography>Is Active on Podcasts/Session</Typography>
                <IOSSwitch handleChange={(e) => handleSwitchChange(e)} value={checked} />
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Typography>Is Visible on homepage</Typography>
                <IOSSwitch handleChange={(e) => handleActiveChange(e)} value={isVisible} />
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "20px" }}>Episodes</Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={() => onAddEpisode()}>
                  <Add />
                </IconButton>

                <IconButton>
                  <Download />
                </IconButton>
                <IconButton>
                  <UploadFile />
                </IconButton>
              </Box>
            </Box>
            <DragDropContext onDragEnd={handleDndForDisplay}>
              <Droppable droppableId="droppable__display">
                {(provider, snapshot) => (
                  <Box
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {selectedDetailModal.list_to_show.map((item, index) => (
                      <Draggable draggableId={JSON.stringify(index)} index={index}>
                        {(provider, snapshot) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBlock: "0.5rem",
                            }}
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <img src={item.image} alt={item.image} style={{ width: "53px", height: "33px" }} />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.1rem",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>{item.name}</Typography>
                                {selectedDetailModal.station_or_classification === "episode" && (
                                  <Box display={"flex"} gap={"10px"}>
                                    {item.mentor_name.map((mentor) => (
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        {mentor}
                                      </Typography>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <IconButton
                              sx={{
                                color: "#1C8EA8",
                                "&:hover": { backgroundColor: "none" },
                              }}
                              {...provider.dragHandleProps}
                            >
                              <Menu />
                            </IconButton>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DetailModal;
