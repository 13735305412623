import { Autocomplete, Box, Checkbox, Chip, IconButton, MenuItem, OutlinedInput, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { Done, ArrowBack } from "@mui/icons-material";
import "./modal.css";
import { useState } from "react";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import Cookies from "js-cookie";
import axios from "axios";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { send_specialCharReplace } from "../../../services/specialCharReplace";
import { useLocation } from "react-router-dom";

function AddModal({ type, close, selectedDetailModal, mentorDetails, activityList, specializationList, roleList, updateStationData, updateSelectedDetailModalData, updateDisplayData, displayData }) {
  const location = useLocation();
  const content_type = location.pathname === "/Podcast" ? "Podcast" : "Session";

  const [addstationForm, setStationForm] = useState({
    station_id: selectedDetailModal.station_id ? selectedDetailModal.station_id : null,
    name: selectedDetailModal.name ? selectedDetailModal.name : "",
    description: selectedDetailModal.description ? selectedDetailModal.description : "",
    search_keywords: selectedDetailModal.search_keywords ? selectedDetailModal.search_keywords : [],
    image: ["", null, undefined].includes(selectedDetailModal.image) ? "" : selectedDetailModal.image.split("/")[selectedDetailModal.image.split("/").length - 1],
    activities: selectedDetailModal.activities ? selectedDetailModal.activities : [],
    specialization: selectedDetailModal.specialization ? selectedDetailModal.specialization : [],
    roles: selectedDetailModal.roles ? selectedDetailModal.roles : [],
    recommendation_keywords: selectedDetailModal.recommendation_keywords ? selectedDetailModal.recommendation_keywords : [],
    station_or_classification: selectedDetailModal.station_or_classification ? selectedDetailModal.station_or_classification : type,
  });

  const [addDisplayForm, setDisplayForm] = useState({
    name: selectedDetailModal.collection_name ? selectedDetailModal.collection_name : "",
    collection_id: selectedDetailModal.collection_id ? selectedDetailModal.collection_id : null,
    station_or_classification: selectedDetailModal.station_or_classification ? selectedDetailModal.station_or_classification : "episode",
    content_type: location.pathname === "/Podcast" ? "Podcast" : "Session",
  });
  const [image, setSelectedImage] = useState(null);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [imageName, setImageName] = useState("");

  const [formErrors, setFormErrors] = useState({
    name: "",
    description: "",
    search_keywords: "",
    image: "",
  });

  const [checked, setChecked] = useState(addstationForm.station_id === null ? false : true);
  const services = new CCService();

  const handleDisplaySubmit = async () => {
    if (addDisplayForm.collection_id === null) {
      //POST NEW DISPLAY
      const body = {
        content_type: addDisplayForm.content_type,
        collection_name: addDisplayForm.name,
        station_or_classification: addDisplayForm.station_or_classification,
        order_by: displayData.length,
        is_active: 1,
      };
      const postResponse = await services.post("/podcast/collectionView", body);
      if (postResponse.status === 200) {
        const temp_data = {
          collection_id: postResponse.data.collection_id,
          content_type: addDisplayForm.content_type,
          collection_name: addDisplayForm.name,
          station_or_classification: addDisplayForm.station_or_classification,
          order_by: displayData.length,
          is_active: 1,
          list_to_show: [],
        };
        updateDisplayData(temp_data, "create");
        close();
      }
    } else {
      //EDIT DISPLAY
      console.log("EIDT DISPLAY");
      const body = {
        collection_id: addDisplayForm.collection_id,
        station_or_classification: addDisplayForm.station_or_classification,
        collection_name: addDisplayForm.name,
      };
      const putResponse = await services.put("/podcast/collectionView", body);
      if (putResponse.status === 200) {
        updateDisplayData(body, "update");
        close();
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      name: "",
      description: "",
      search_keywords: "",
      image: "",
    });
    if (name === "name") {
      setStationForm((prev) => {
        return {
          ...prev,
          name: value,
        };
      });
    } else if (name === "description") {
      setStationForm((prev) => {
        return {
          ...prev,
          description: value,
        };
      });
    } else if (name === "activities") {
      setStationForm((prev) => {
        return {
          ...prev,
          activities: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "specialization") {
      setStationForm((prev) => {
        return {
          ...prev,
          specialization: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "roles") {
      setStationForm((prev) => {
        return {
          ...prev,
          roles: typeof value === "string" ? value.split(",") : value,
        };
      });
    } else if (name === "recommendation_keywords") {
      setStationForm((prev) => {
        return {
          ...prev,
          recommendation_keywords: [...prev.recommendation_keywords, value],
        };
      });
    }
  };

  const handleDisplayFormChange = (e) => {
    const { name, value } = e.target;
    setDisplayForm((prev) => {
      return {
        ...prev,
        name: value,
      };
    });
  };
  const validateForm = () => {
    let flag = true;
    if (addstationForm.name === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          name: "Name cannot be empty",
        };
      });
      flag = false;
    }
    if (addstationForm.description === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          description: "Description cannot be empty",
        };
      });
      flag = false;
    }
    if (addstationForm.search_keywords.length === 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          search_keywords: "Search keywords cannot be empty",
        };
      });

      flag = false;
    }
    if (addstationForm.station_id === null && image === null) {
      setFormErrors((prev) => {
        return {
          ...prev,
          image: "Image is Required",
        };
      });

      flag = false;
    }

    return flag;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      if (addstationForm.station_id === null) {
        const body = {
          name: addstationForm.name,
          description: send_specialCharReplace(addstationForm.description),
          search_keywords: addstationForm.search_keywords,
          activity: addstationForm.activities.toString(),
          specialization: addstationForm.specialization,
          role: addstationForm.roles.toString(),
          recommendation_keywords: addstationForm.recommendation_keywords,
          station_or_classification: type,
          content_type: content_type,
        };
        const post_res = await services.post("/podcast/stations", body);
        if (post_res.status === 200) {
          //Post Image post request so Image will be sent for everyrequest

          const headers = {
            Authorization: `Bearer ${Cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
          };
          const blob_image = new Blob([image], {
            type: "image/jpeg",
          });
          const data = new FormData();
          data.append("image$$" + `${addstationForm.name}`, blob_image, `${image?.name}`);

          const imageRes = await axios.put(`${process.env.REACT_APP_API_URL}/podcast/stations/imageUpload?station_id=${addstationForm.station_id}`, data, { headers });

          //UPDATE FRONTEND
          const temp_data = {
            station_id: post_res.data.station_id,
            name: addstationForm.name,
            description: addstationForm.description,
            station_or_classification: type,
            roles: addstationForm.roles,
            activities: addstationForm.activities,
            specialization: addstationForm.specialization,
          };
          updateStationData(temp_data, "create");
        }
      } else {
        const body = {
          station_id: addstationForm.station_id,
          name: addstationForm.name,
          description: send_specialCharReplace(addstationForm.description),
          search_keywords: addstationForm.search_keywords,
          activity: addstationForm.activities.toString(),
          specialization: addstationForm.specialization,
          role: addstationForm.roles.toString(),
          recommendation_keywords: addstationForm.recommendation_keywords,
          station_or_classification: type,
          content_type: content_type,
        };

        const res = await services.put("/podcast/stations", body);

        if (res.status === 200) {
          //Image Upload
          if (imageUpdated) {
            const headers = {
              Authorization: `Bearer ${Cookies.get("token")}`,
              "Content-Type": "multipart/form-data",
            };
            const blob_image = new Blob([image], {
              type: "image/jpeg",
            });
            const data = new FormData();
            data.append("image$$" + `${addstationForm.name}`, blob_image, `${image?.name}`);

            const imageRes = await axios.put(`${process.env.REACT_APP_API_URL}/podcast/stations/imageUpload?station_id=${addstationForm.station_id}`, data, { headers });
          }
          let temp_data = addstationForm;
          temp_data["image"] = image;
          updateStationData(temp_data, "update");
          updateSelectedDetailModalData(temp_data);
          close();
        }
      }
      setStationForm({
        station_id: null,
        name: "",
        description: "",
        search_keywords: [],
        image: "",
        activities: [],
        specialization: [],
        roles: [],
        recommendation_keywords: [],
        station_or_classification: type,
      });
    }
  };

  const handleImageUpload = (e) => {
    console.log("ImageUpload", e.target.files[0].name);
    setSelectedImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
    setImageUpdated(true);
  };

  return (
    //Add episodes
    <Box className="addmodal_main-div" sx={{ width: "716px", borderRadius: "20px" }}>
      {type === "episode" && (
        <Box sx={{ padding: "24px 86px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => close()}>
                <ArrowBack />
              </IconButton>
              <Typography sx={{ fontSize: "32px", marginLeft: "110px" }}>Add Episode</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField sx={{ width: "100%" }} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Mentor-1<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField sx={{ width: "100%" }} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Mentor-2</Typography>
              <TextField sx={{ width: "100%" }} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Mentor-3</Typography>
              <TextField sx={{ width: "100%" }} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                About episode<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField sx={{ width: "100%" }} multiline rows={4} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                About mentor<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField sx={{ width: "100%" }} multiline rows={4} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Keywords</Typography>
              <TextField sx={{ width: "100%" }} name="name" value="name" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Recording<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField sx={{ width: "100%" }} multiline rows={4} name="name" value="name" />
            </Box>{" "}
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Image<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField sx={{ width: "100%" }} multiline rows={4} name="name" value="name" />
            </Box>
            <button className="addmodal__save-button">Add to Episode</button>
          </Box>
        </Box>
      )}

      {type === "station" && (
        <Box sx={{ padding: "24px 86px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => close()}>
                <ArrowBack />
              </IconButton>
              <Typography sx={{ fontSize: "32px", marginLeft: "110px" }}>{addstationForm.station_id === null ? "Add Station" : "Edit Station"}</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField sx={{ width: "100%" }} name="name" value={addstationForm.name} onChange={(e) => handleFormChange(e)} error={formErrors.name === "" ? false : true} helperText={formErrors.name} />
            </Box>

            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField sx={{ width: "100%" }} multiline rows={4} name="description" value={addstationForm.description} onChange={(e) => handleFormChange(e)} error={formErrors.description === "" ? false : true} helperText={formErrors.description} />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Search Keywords</Typography>
              <Autocomplete
                multiple
                options={addstationForm.search_keywords}
                freeSolo
                value={addstationForm.search_keywords}
                onChange={(e, value) =>
                  setStationForm((prev) => {
                    return {
                      ...prev,
                      search_keywords: value,
                    };
                  })
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      // variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => <TextField {...params} error={formErrors.search_keywords === "" ? false : true} helperText={formErrors.search_keywords} />}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Photograph {addstationForm.station_id === null ? <span style={{ color: "red" }}>*</span> : <></>}</Typography>
              <Box
                sx={{
                  border: "1px dashed #2AA8C4",
                  height: "65px",
                  padding: "9px",
                  borderRadius: "10px",
                  backgroundColor: "rgba(56, 193, 223, 0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <label
                  style={{
                    border: "none",
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  for="files"
                  class="btn"
                >
                  <CloudUploadOutlinedIcon for="files" style={{ width: "40px", height: "40px", color: "#2AA8C4" }} /> {imageName === "" ? (selectedDetailModal.image === "" ? "Select an Image" : addstationForm.name) : imageName}
                </label>
                <TextField
                  id="files"
                  type="file"
                  onChange={handleImageUpload}
                  style={{
                    marginTop: "0",
                    visibility: "hidden",
                    width: "0%",
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} />
              <Typography>Link to</Typography>
            </Box>
            {checked ? (
              <Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Activity</Typography>
                  <Select
                    fullWidth
                    name="activities"
                    multiple
                    value={addstationForm.activities}
                    MenuProps={{ style: { height: "200px" } }}
                    onChange={(e) => handleFormChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          console.log("TempData", value);
                          let temp = activityList.find((item) => item.id === value);
                          return <Chip key={value} label={temp.act_type_name} />;
                        })}
                      </Box>
                    )}
                  >
                    {activityList.map((activity) => (
                      <MenuItem key={activity} value={activity.id}>
                        {`${activity.act_type_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Specialization</Typography>
                  <Select
                    fullWidth
                    name="specialization"
                    multiple
                    value={addstationForm.specialization}
                    MenuProps={{ style: { height: "200px" } }}
                    onChange={(e) => handleFormChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {specializationList.map((specialization) => (
                      <MenuItem key={specialization} value={specialization.name}>
                        {`${specialization.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Role</Typography>
                  <Select
                    fullWidth
                    name="roles"
                    multiple
                    value={addstationForm.roles}
                    MenuProps={{ style: { height: "200px" } }}
                    onChange={(e) => handleFormChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          let temp = roleList.find((item) => item.id === value);
                          return <Chip key={value} label={temp === undefined ? "" : temp.name} />;
                        })}
                      </Box>
                    )}
                  >
                    {roleList.map((role) => (
                      <MenuItem key={role} value={role.id}>
                        {`${role.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Recommendation Keywords</Typography>
                  <Autocomplete
                    multiple
                    options={addstationForm.recommendation_keywords}
                    freeSolo
                    value={addstationForm.recommendation_keywords}
                    onChange={(e, value) =>
                      setStationForm((prev) => {
                        return {
                          ...prev,
                          recommendation_keywords: value,
                        };
                      })
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <button className="addmodal__save-button" onClick={() => handleSubmit()}>
              {addstationForm.station_id === null ? "Add Station" : "Update Station"}
            </button>
          </Box>
        </Box>
      )}

      {type === "classification" && (
        <Box sx={{ padding: "24px 86px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => close()}>
                <ArrowBack />
              </IconButton>
              <Typography sx={{ fontSize: "32px", marginLeft: "110px" }}>{addstationForm.station_id === null ? "Add Classification" : "Edit Classification"}</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField sx={{ width: "100%" }} name="name" value={addstationForm.name} onChange={(e) => handleFormChange(e)} error={formErrors.name === "" ? false : true} helperText={formErrors.name} />
            </Box>

            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField sx={{ width: "100%" }} multiline rows={4} name="description" value={addstationForm.description} onChange={(e) => handleFormChange(e)} error={formErrors.description === "" ? false : true} helperText={formErrors.description} />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Search Keywords</Typography>

              <Autocomplete
                multiple
                options={addstationForm.search_keywords}
                freeSolo
                value={addstationForm.search_keywords}
                onChange={(e, value) =>
                  setStationForm((prev) => {
                    return {
                      ...prev,
                      search_keywords: value,
                    };
                  })
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      // variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => <TextField {...params} error={formErrors.search_keywords === "" ? false : true} helperText={formErrors.search_keywords} />}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Photograph {addstationForm.station_id === null ? <span style={{ color: "red" }}>*</span> : <></>}</Typography>

              <Box
                sx={{
                  border: formErrors.image === "" ? "1px dashed #2AA8C4" : "1px dashed red",
                  height: "65px",
                  padding: "9px",
                  borderRadius: "10px",
                  backgroundColor: "rgba(56, 193, 223, 0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <label
                  style={{
                    border: "none",
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  for="classification_image"
                  class="btn"
                >
                  <CloudUploadOutlinedIcon for="classification_image" style={{ width: "40px", height: "40px", color: "#2AA8C4" }} /> {imageName === "" ? (selectedDetailModal.image === "" ? "Select an Image" : addstationForm.image) : imageName}
                </label>
                <TextField
                  id="classification_image"
                  type="file"
                  onChange={handleImageUpload}
                  style={{
                    marginTop: "0",
                    visibility: "hidden",
                    width: "0%",
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} />
              <Typography>Link to</Typography>
            </Box>
            {checked ? (
              <>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Activity</Typography>
                  <Select
                    fullWidth
                    name="activities"
                    multiple
                    value={addstationForm.activities}
                    MenuProps={{ style: { height: "200px" } }}
                    onChange={(e) => handleFormChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          console.log("TempData", value);
                          let temp = activityList.find((item) => item.id === value);
                          return <Chip key={value} label={temp.act_type_name} />;
                        })}
                      </Box>
                    )}
                  >
                    {activityList.map((activity) => (
                      <MenuItem key={activity} value={activity.id}>
                        {`${activity.act_type_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Specialization</Typography>
                  <Select
                    fullWidth
                    name="specialization"
                    multiple
                    value={addstationForm.specialization}
                    MenuProps={{ style: { height: "200px" } }}
                    onChange={(e) => handleFormChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {specializationList.map((specialization) => (
                      <MenuItem key={specialization} value={specialization.name}>
                        {`${specialization.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Role</Typography>
                  <Select
                    fullWidth
                    name="roles"
                    multiple
                    value={addstationForm.roles}
                    MenuProps={{ style: { height: "200px" } }}
                    onChange={(e) => handleFormChange(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          let temp = roleList.find((item) => item.id === value);
                          console.log("tempValue", temp);
                          return <Chip key={value} label={temp === undefined ? "" : temp.name} />;
                        })}
                      </Box>
                    )}
                  >
                    {roleList.map((role) => (
                      <MenuItem key={role} value={role.id}>
                        {`${role.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Recommendation Keywords</Typography>
                  <Autocomplete
                    multiple
                    options={addstationForm.recommendation_keywords}
                    freeSolo
                    value={addstationForm.recommendation_keywords}
                    onChange={(e, value) =>
                      setStationForm((prev) => {
                        return {
                          ...prev,
                          recommendation_keywords: value,
                        };
                      })
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
            <button className="addmodal__save-button" onClick={() => handleSubmit()}>
              {addstationForm.station_id === null ? "Add to Classification" : "Update Classification"}
            </button>
          </Box>
        </Box>
      )}

      {type === "display" && (
        <Box sx={{ padding: "24px 86px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => close()}>
                <ArrowBack />
              </IconButton>
              <Typography sx={{ fontSize: "32px", marginLeft: "110px" }}>Add Display</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField sx={{ width: "100%" }} onChange={(e) => handleDisplayFormChange(e)} name="name" value={addDisplayForm.name} />
            </Box>
            <ToggleButtonGroup
              color="primary"
              value={addDisplayForm.station_or_classification}
              exclusive
              onChange={(e, value) =>
                setDisplayForm((prev) => {
                  return { ...prev, station_or_classification: value };
                })
              }
            >
              <ToggleButton
                value="episode"
                sx={{
                  width: "100%",
                  borderRadius: "20px 0px 0px 20px",
                  textTransform: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    justifyContent: "center",
                  }}
                >
                  {addDisplayForm.station_or_classification === "episode" && <Done />} <Typography>Episode</Typography>
                </Box>
              </ToggleButton>
              <ToggleButton
                value="station"
                sx={{
                  width: "100%",
                  borderRadius: "0px 20px 20px 0px",
                  textTransform: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    justifyContent: "center",
                  }}
                >
                  {addDisplayForm.station_or_classification === "station" && <Done />} <Typography>Stations</Typography>
                </Box>
              </ToggleButton>
              <ToggleButton
                value="classification"
                sx={{
                  width: "100%",
                  borderRadius: "0px 20px 20px 0px",
                  textTransform: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    justifyContent: "center",
                  }}
                >
                  {addDisplayForm.station_or_classification === "classification" && <Done />} <Typography>Classification</Typography>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>

            <button className="addmodal__save-button" onClick={() => handleDisplaySubmit()}>
              {addDisplayForm.collection_id === null ? "Add to Display" : "Edit Display"}
            </button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AddModal;
