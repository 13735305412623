import Cookies from "js-cookie";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
//DropDownComponent
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BookIcon from '@mui/icons-material/Book';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FaLayerGroup } from "react-icons/fa";
import "../../../index.css";
import "./SideNavigationBar.css";
// import { makeStyles, createStyles } from '@material-ui/core/styles'
import { PeopleOutlineOutlined } from "@mui/icons-material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { getAuth } from "firebase/auth";
export default function SideNavigationBar(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const path = window.location.pathname;
  console.log(path);

  const handleLogout = (e) => {
    console.log("Logout loader!!1");
    Cookies.remove("token");
    console.log("Logout loader!!2");
    navigate("/");
    console.log("Logout loader!!3");
    const auth = getAuth();
    console.log("auth", auth);
    auth
      ?.signOut()
      .then((user) => {
        console.log("Successfully signed out with Apple");
        navigate("/");
      })
      .catch((error) => {
        console.log("Error signing out with Apple", error);
      });
  };
  const handleAdminClick = (e) => {
    console.log("value of e ", e.target);
    if (e.target.value === "user_type") {
      navigate("/UserType");
    }
  };

  // const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openSku, setOpenSku] = React.useState(false);
  const [openMentor, setOpenMentor] = React.useState(false);
  const [openJobs, setOpenJobs] = React.useState(false);
  const [openPodcast, setOpenPodcast] = React.useState(false);
  const [openBlogs, setOpenBlogs] = React.useState(false);
  const [openB2C, setOpenB2Cs] = React.useState(false);

  function handleClick(e) {
    e.preventDefault();
    setOpen(!open);
  }

  function handleClick1(e) {
    e.preventDefault();
    setOpen1(!open1);
  }

  function handleClickPodcast(e) {
    e.preventDefault();
    setOpenPodcast(!openPodcast);
  }

  function handleClickBlogs(e) {
    e.preventDefault()
    setOpenBlogs(!openBlogs)
  }

  function handleClickSKU(e) {
    e.preventDefault();
    setOpenSku(!openSku);
  }

  function handleClickMentor(e) {
    e.preventDefault();
    setOpenMentor(!openMentor);
  }

  function handleClickJobs(e) {
    e.preventDefault();
    setOpenJobs(!openJobs);
  }

  function handleClickB2C(e) {
    e.preventDefault();
    setOpenB2Cs((prev) => !prev);
  }

  const handleNavigateUser = (e) => {
    e.preventDefault();
    navigate("/UserType");
  };
  const handleNavigateProgram = (e) => {
    e.preventDefault();
    navigate("/ProgramType");
  };
  const handleNavigateBatch = (e) => {
    e.preventDefault();
    navigate("/BatchType");
  };
  const handleNavigateSpecialization = (e) => {
    e.preventDefault();
    navigate("/SpecilizationType");
  };
  const handleNavigateRole = (e) => {
    e.preventDefault();
    navigate("/RoleType");
  };
  const handleNavigateActivity = (e) => {
    e.preventDefault();
    navigate("/ActivityType");
  };
  const handleNavigateJD = (e) => {
    e.preventDefault();
    navigate("/JD");
  };
  const handleNavigateDurations = (e) => {
    e.preventDefault();
    navigate("/Durations");
  };
  const handleNavigateDefaultJD = (e) => {
    e.preventDefault();
    navigate("/DefaultJD");
  };

  const handleNavigateCollegedata = (e) => {
    e.preventDefault();
    navigate("/CollegeData");
  };

  const handleNavigateCollegeAnalytics = (e) => {
    e.preventDefault();
    navigate("/CollegeAnalytics");
  }

  const handleNavigateBatchdata = (e) => {
    e.preventDefault();
    navigate("/BatchData");
  };
  const handleNavigateSKU = (e) => {
    e.preventDefault();
    navigate("/SKU");
  };
  const handleNavigateBundle = (e) => {
    e.preventDefault();
    navigate("/Bundle");
  };
  const handleNavigateCoupon = (e) => {
    e.preventDefault();
    navigate("/Coupon");
  };
  const handleNavigateMentorDetails = (e) => {
    e.preventDefault();
    navigate("/MentorDetails");
  };
  const handleNavigateMentorData = (e) => {
    e.preventDefault();
    navigate("/MentorData");
  };
  const handleNavigateMentorActivity = (e) => {
    e.preventDefault();
    navigate("/MentorActivity");
  };


  return (
    <div class="area">
      <nav class="main-menu">
        <ul>
          <li>
            <a href="/">
              <div style={{ padding: "10px" }}>
                <img
                  alt=""
                  src="Career_Carve_Logo__1_-removebg-preview 2.png"
                  style={{
                    margin: "17px 6px 75px 12px",
                    marginLeft: "6%",
                    width: "fit-content",
                    height: "50px",
                    float: "left",
                    cursor: "pointer",
                  }}
                />
              </div>
            </a>
          </li>
        </ul>
        <ul>
          <li class="has-subnav">
            <a href={"/"}>
              <ListItemButton style={{ padding: "0px" }}>
                <div style={{ display: "flex", width: "100%" }} className={path === "/Dashboard" ? "sidenav-image-a-dashboard" : ""}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                    className="avtive-icons"
                  >
                    {/* <div style={{ padding: "15px", margin:16,marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0 ,borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                    <FaLayerGroup />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Dashboard</span>
                  </div>
                </div>
              </ListItemButton>
            </a>
          </li>
          <li class="has-subnav">
            <a href={"/MyProfile"}>
              <div style={{ display: "flex", width: "100%" }} className={path === "/MyProfile" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <i class="fa fa-user fontA"></i>
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Profile</span>
                  </div>
                </ListItemButton>
              </div>
            </a>
          </li>
          <li class="has-subnav">
            {/* <a href={"/"} className={"sidenav-image-a-settings"}> */}
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}
              >
                <ListItemButton onClick={(e) => handleClick(e)} style={{ padding: "0px", width: "fit-content" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: 'none'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={
                      path !== "/Dashboard" &&
                        path !== "/MyProfile" &&
                        path !== "/CollegeData" &&
                        path !== "/BatchData" &&
                        path !== "/SKU" &&
                        path !== "/Bundle" &&
                        path !== "/StudentData" &&
                        path !== "/MentorData" &&
                        path !== "/MentorDetails" &&
                        path !== "/SupportTicket" &&
                        path !== "/MentorActivity" &&
                        path !== "/Podcast" &&
                        path !== "/Session" &&
                        !["/AllRequests",
                          "/Companies",
                          "/Colleges",
                          "/Jobs",
                          "/ViewRequests",
                          "/Posts",
                          "/JobPostingState",
                          "/StudentShortlist",
                          "/JobStudentApplicants",
                          "/JobStudentProcess"].includes(path) &&
                        path !== "/BlogsCategory" &&
                        path !== "/Blogs" &&
                        path !== "/BlogsTags" &&
                        path !== "/BlogsAuthor" &&
                        path !== "/B2CProcess" &&
                        path !== "/B2CProcess/College1x1StudentDetails"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    <i class="fa fa-gear fontA"></i>
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={
                      path !== "/Dashboard" &&
                        path !== "/MyProfile" &&
                        path !== "/CollegeData" &&
                        path !== "/BatchData" &&
                        path !== "/SKU" &&
                        path !== "/Bundle" &&
                        path !== "/StudentData" &&
                        path !== "/MentorData" &&
                        path !== "/MentorDetails" &&
                        path !== "/SupportTicket" &&
                        path !== "/MentorActivity" &&
                        path !== "/Podcast" &&
                        path !== "/Session" &&
                        !["/AllRequests",
                          "/Companies",
                          "/Colleges",
                          "/Jobs",
                          "/ViewRequests",
                          "/Posts",
                          "/JobPostingState",
                          "/StudentShortlist",
                          "/JobStudentApplicants",
                          "/JobStudentProcess"].includes(path) &&
                        path !== "/BlogsCategory" &&
                        path !== "/Blogs" &&
                        path !== "/BlogsTags" &&
                        path !== "/BlogsAuthor" &&
                        path !== "/B2CProcess" &&
                        path !== "/B2CProcess/College1x1StudentDetails"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    {/* <span class="nav-text" sx={{paddingTop: 0, display:'inline' }}>Admin<span>{open ? <ExpandLess /> : <ExpandMore />}</span></span> */}
                    <p class="nav-text">
                      Admin
                      <span>{open ? <ExpandLess className="admin-icons" sx={{ marginLeft: "5%" }} /> : <ExpandMore className="admin-icons" sx={{ marginLeft: "5%" }} />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText primary="User Type" onClick={(e) => handleNavigateUser(e)} className={path === "/UserType" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Program Type" onClick={(e) => handleNavigateProgram(e)} className={path === "/ProgramType" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Batch Type" onClick={(e) => handleNavigateBatch(e)} className={path === "/BatchType" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Specialization Type" onClick={(e) => handleNavigateSpecialization(e)} className={path === "/SpecilizationType" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Role Type" onClick={(e) => handleNavigateRole(e)} className={path === "/RoleType" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Activity Type" onClick={(e) => handleNavigateActivity(e)} className={path === "/ActivityType" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="JD Type" onClick={(e) => handleNavigateJD(e)} className={path === "/JD" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Durations" onClick={(e) => handleNavigateDurations(e)} className={path === "/Durations" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Default JD" onClick={(e) => handleNavigateDefaultJD(e)} className={path === "/DefaultJD" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Admin Rights" onClick={(e) => navigate('/AdminRights')} className={path === "/DefaultJD" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    {/* <ListItemButton sx={{ pl: 2, ml: 10, p: '5px' }}>
                                            <ListItemText primary="Group Sessions" onClick={(e) => handleNavigateGroupSession(e)} className={path === '/RoleType' ? 'sidenav-image-a-dashboard' : ''} />
                                        </ListItemButton> */}
                  </List>
                </Collapse>
              </List>
            </div>

            {/* </a> */}
          </li>
          <li class="has-subnav">
            {/* <a href={"/"} className={"sidenav-image-a-settings"}> */}
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}
              >
                <ListItemButton
                  onClick={(e) => handleClick1(e)}
                  style={{
                    padding: "0px",
                    width: "fit-content",
                    height: "50px",
                  }}
                >
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: 'none'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 0,
                      marginLeft: 12,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/CollegeData") ||
                        path === "/BatchData" ||
                        path === "/StudentData"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    {/* <i class="fa fa-gear fa-2x fontA"></i> */}
                    <SchoolOutlinedIcon />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/CollegeData") ||
                        path === "/BatchData" ||
                        path === "/StudentData"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    {/* <span class="nav-text" sx={{paddingTop: 0, display:'inline' }}>Admin<span>{open ? <ExpandLess /> : <ExpandMore />}</span></span> */}
                    <p class="nav-text" style={{ marginLeft: 8 }}>
                      College
                      <span>{open1 ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={open1} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText primary="College Data" onClick={(e) => handleNavigateCollegedata(e)} className={path === "/CollegeData" || path === "/StudentData" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText
                        primary="College Analytics"
                        onClick={(e) => handleNavigateCollegeAnalytics(e)}
                        className={
                          path === "/CollegeAnalytics" || path === "/StudentData"
                            ? "sidenav-image-a-dashboard"
                            : ""
                        }
                      />
                    </ListItemButton>
                    {/* <ListItemButton sx={{ pl: 2, ml: 10, p: '4px' }}>
                                            <ListItemText primary="Batch Data" onClick={(e) => handleNavigateBatchdata(e)} className={path === '/BatchData' ? 'sidenav-image-a-dashboard' : ''} />
                                        </ListItemButton> */}
                  </List>
                </Collapse>
              </List>
            </div>
          </li>
          <li class="has-subnav">
            {/* <a href={"/"} className={"sidenav-image-a-settings"}> */}
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}>
                <ListItemButton onClick={(e) => handleClickSKU(e)} style={{ padding: "0px", width: "fit-content" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: 'none'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 8,
                      marginLeft: 8,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/SKU") ||
                        path === "/Bundle"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    {/* <i class="fa fa-gear fa-2x fontA"></i> */}
                    <InventoryOutlinedIcon sx={{ marginLeft: "5px" }} />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/SKU") ||
                        path === "/Bundle"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    {/* <span class="nav-text" sx={{paddingTop: 0, display:'inline' }}>Admin<span>{open ? <ExpandLess /> : <ExpandMore />}</span></span> */}
                    <p class="nav-text" style={{ marginLeft: 0 }}>
                      SKU Prices
                      <span>{openSku ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={openSku} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText primary="SKU" onClick={(e) => handleNavigateSKU(e)} className={path === "/SKU" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Bundle" onClick={(e) => handleNavigateBundle(e)} className={path === "/Bundle" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Coupon" onClick={(e) => handleNavigateCoupon(e)} className={path === "/Coupon" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </li>
          <li class="has-subnav">
            {/* <a href={"/"} className={"sidenav-image-a-settings"}> */}
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}>
                <ListItemButton onClick={(e) => handleClickJobs(e)} style={{ padding: "0px", width: "fit-content" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 8,
                      marginLeft: 8,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/AllRequests") ||
                        path === "/Companies" ||
                        path === "/Jobs" ||
                        path === "/Collegs" ||
                        path === "/ViewRequests" ||
                        path === "/Posts" ||
                        path === "/JobPostingState"

                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    <WorkOutlineIcon sx={{ marginLeft: "5px" }} />


                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/AllRequests") ||
                        path === "/Companies" ||
                        path === "/Jobs" ||
                        path === "/Collegs" ||
                        path === "/ViewRequests" ||
                        path === "/Posts" ||
                        path === "/JobPostingState"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    <p class="nav-text" style={{ marginLeft: 0 }}>
                      Jobs
                      <span>{openJobs ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={openJobs} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText primary="All Requests" onClick={(e) => navigate("/AllRequests")} className={path === "/AllRequests" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Companies" onClick={(e) => navigate("/Companies")} className={["/Companies", "/ViewRequests", "/Posts", "/JobPostingState"].includes(path) ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Jobs" onClick={(e) => navigate("/Jobs")} className={path === "/Jobs" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Colleges" onClick={(e) => navigate("/Colleges")} className={path === "/Colleges" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </li>

          <li class="has-subnav">
            {/* <a href={"/"} className={"sidenav-image-a-settings"}> */}
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}
              >
                <ListItemButton onClick={(e) => handleClickMentor(e)} style={{ padding: "0px", width: "fit-content" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 8,
                      marginLeft: 8,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/MentorDetails") ||
                        path === "/MentorData" ||
                        path === "/MentorActivity"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    <AccountCircleOutlinedIcon sx={{ marginLeft: "5px" }} />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={
                      (path !== "/" &&
                        path !== "/MyProfile" &&
                        path === "/MentorDetails") ||
                        path === "/MentorData" ||
                        path === "/MentorActivity"
                        ? "sidenav-image-a-dashboard"
                        : "sidenav-image-a"
                    }
                  >
                    <p class="nav-text" style={{ marginLeft: 0 }}>
                      Mentors
                      <span>{openMentor ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={openMentor} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText primary="Mentor Details" onClick={(e) => handleNavigateMentorDetails(e)} className={path === "/MentorDetails" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Mentor Interest Data" onClick={(e) => handleNavigateMentorData(e)} className={path === "/MentorData" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Mentor Activity" onClick={(e) => handleNavigateMentorActivity(e)} className={path === "/MentorActivity" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </li>

          <li class="has-subnav">
            {/* <a href={"/"} className={"sidenav-image-a-settings"}> */}
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}
              >
                <ListItemButton onClick={(e) => handleClickPodcast(e)} style={{ padding: "0px", width: "fit-content" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 8,
                      marginLeft: 8,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={path === "/Podcast" || path === "/Session" ? "sidenav-image-a-dashboard" : "sidenav-image-a"}
                  >
                    <PodcastsIcon sx={{ marginLeft: "5px" }} />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={path === "/Podcast" || path === "/Session" ? "sidenav-image-a-dashboard" : "sidenav-image-a"}
                  >
                    <p class="nav-text" style={{ marginLeft: 0 }}>
                      Content
                      <span>{openPodcast ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={openPodcast} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }} onClick={(e) => navigate("/Podcast")}>
                      <ListItemText primary="Podcast" className={path === "/MentorDetails" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }} onClick={(e) => navigate("/Session")}>
                      <ListItemText primary="Session" className={path === "/MentorData" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </li>

          {/* BLOGS */}
          <li class="has-subnav">
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}
              >
                <ListItemButton onClick={(e) => handleClickBlogs(e)} style={{ padding: "0px", width: "fit-content" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 8,
                      marginLeft: 8,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={(path !== "/" &&
                      path !== "/MyProfile" && path === "/BlogsCategory") || path === "/Blogs" || path === "/BlogsTags" || path === '/BlogsAuthor' ? "sidenav-image-a-dashboard" : "sidenav-image-a"}
                  >
                    <BookIcon sx={{ marginLeft: "5px" }} />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={(path !== "/" &&
                      path !== "/MyProfile" && path === "/BlogsCategory") || path === "/Blogs" || path === "/BlogsTags" || path === "/BlogsAuthor" ? "sidenav-image-a-dashboard" : "sidenav-image-a"}
                  >
                    <p class="nav-text" style={{ marginLeft: 0 }}>
                      Blogs
                      <span>{openBlogs ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={openBlogs} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }} onClick={(e) => navigate("/BlogsCategory")}>
                      <ListItemText primary="Blogs Category" className={path === "/BlogsCategory" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }} onClick={(e) => navigate("/BlogsTags")}>
                      <ListItemText primary="Blogs Tags" className={path === "/BlogsTags" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }} onClick={(e) => navigate("/BlogsAuthor")}>
                      <ListItemText primary="Blogs Authors" className={path === "/BlogsAuthor" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }} onClick={(e) => navigate("/Blogs")}>
                      <ListItemText primary="Blogs" className={path === "/Blogs" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </li>

          {/* B2C */}
          <li class="has-subnav">
            <div style={{ display: "flex" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 260,
                  bgcolor: "transparent",
                  padding: 0,
                }}
              >
                <ListItemButton onClick={(e) => handleClickB2C(e)} style={{ padding: "0px", width: "fit-content" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 8,
                      marginLeft: 8,
                      paddingRight: "28px",
                      marginTop: 0,
                      marginBottom: 0,
                      border: "none",
                    }}
                    className={(path !== "/" &&
                      path !== "/MyProfile" && path === "/B2CProcess/College1x1StudentDetails") || path === "/B2CProcess" ? "sidenav-image-a-dashboard" : "sidenav-image-a"}
                  >
                    <PeopleOutlineOutlined sx={{ marginLeft: "5px" }} />
                  </div>
                  <div
                    style={{
                      padding: "10px 0px 9px 0px",
                      marginRight: 0,
                      verticalAlign: "middle",
                      width: "fit-content",
                      border: "none",
                    }}
                    className={(path !== "/" &&
                      path !== "/MyProfile" && path === "/B2CProcess/College1x1StudentDetails") || path === "/B2CProcess" ? "sidenav-image-a-dashboard" : "sidenav-image-a"}
                  >
                    <p class="nav-text" style={{ marginLeft: 0 }}>
                      B2C
                      <span>{openB2C ? <ExpandLess className="admin-icons" /> : <ExpandMore className="admin-icons" />}</span>
                    </p>
                  </div>
                </ListItemButton>
                <Collapse in={openB2C} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }} onClick={(e) => navigate("/B2CProcess")}>
                      <ListItemText primary="Process" className={["/B2CProcess", "/B2CProcess/College1x1StudentDetails"].includes(path) ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </li>

          <li class="has-subnav">
            <a href={"/SupportTicket"}>
              <div style={{ display: "flex", width: "100%" }} className={path === "/SupportTicket" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <SupportAgentIcon />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Support Ticket</span>
                  </div>
                </ListItemButton>
              </div>
            </a>
          </li>
        </ul>

        <ul class="logout" onClick={(e) => handleLogout(e)}>
          <li class="has-subnav">
            <a className="sidenav-image-a">
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ padding: "18px 0px 18px 30px", width: "100px" }}>
                  <i class="fa fa-power-off"></i>
                </div>
                <div style={{ padding: "14px 0px 9px 0px", width: "80%" }}>
                  <span class="nav-text">Logout</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </div >
  );
}

// import React, { useRef, useState, useEffect, useContext, createContext } from "react";
// import axios from "axios";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import Cookies from 'js-cookie';
// //DropDownComponent
// import ListSubheader from '@mui/material/ListSubheader';
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Collapse from '@mui/material/Collapse';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import SendIcon from '@mui/icons-material/Send';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import StarBorder from '@mui/icons-material/StarBorder';
// import { FaLayerGroup } from 'react-icons/fa'
// import '../../../index.css';
// import './SideNavigationBar.css'
// // import { makeStyles, createStyles } from '@material-ui/core/styles'

// export default function SideNavigationBar(props) {
//     const navigate = useNavigate();
//     const [searchParams, setSearchParams] = useSearchParams();
//     const [loader, setLoader] = useState(false);
//     const path = window.location.pathname;
//     console.log(path);

//     const resume_id = searchParams.get("resume_id");

//     const handleLogout = (e) => {
//         console.log("Logout loader!!1")
//         setLoader(true)
//         // window.localStorage.clear('token');
//         Cookies.remove('token');
//         console.log("Logout loader!!2")
//         navigate('/Login');
//         setLoader(true)
//         console.log("Logout loader!!3")
//     }
//     const handleAdminClick = (e) => {
//         console.log("value of e ",
// e.target
// )
//         if (e.target.value === "user_type") {
//             navigate('/UserType')
//         }
//     }

//     // const classes = useStyles()
//     const [open, setOpen] = React.useState(false)

//     function handleClick(e) {
//         e.preventDefault()
//         setOpen(!open)
//     }

//     const handleNavigateUser = (e) => {
//         e.preventDefault();
//         navigate('/UserType');
//     }
//     const handleNavigateProgram = (e) => {
//         e.preventDefault();
//         navigate('/ProgramType');
//     }
//     const handleNavigateBatch = (e) => {
//         e.preventDefault();
//         navigate('/BatchType');
//     }

//     const drawerWidth = 240

//     // const useStyles = makeStyles(theme =>
//     //     createStyles({
//     //         appMenu: {
//     //             width: '100%',
//     //         },
//     //         navList: {
//     //             width: drawerWidth,
//     //         },
//     //         menuItem: {
//     //             width: drawerWidth,
//     //         },
//     //         menuItemIcon: {
//     //             color: '#97c05c',
//     //         },
//     //     }),
//     // )
//     return (
//         <div class="area">
//             <nav class="main-menu">
//                 <ul>
//                     <li>
//                         <a href="/" >
//                             <div style={{ padding: "0px" }}>
//                                 <img src="Career_Carve_Logo__1_-removebg-preview 2.png" style={{ margin: '16px 0px 106px 12px', width: '50px', height: '50px', float: 'left', cursor: "pointer" }} />
//                             </div>
//                         </a>
//                     </li>
//                 </ul>
//                 <ul>
//                     <li class="has-subnav">
//                         <a href={"/"} className={path === "" ? "sidenav-image-a-dashboard" : "sidenav-image-a"}>
//                             <div style={{ display: "flex", width: "80%" }}>
//                                 <div style={{ padding: "18px 30px 18px 30px", width: "20%", alignItems: "center" }}>
//                                     <FaLayerGroup />
//                                 </div>

//                                 <div style={{ padding: "18px 156px 18px 17px", width: "80%" }}>
//                                     <span class="nav-text">Dashboard</span>
//                                 </div>
//                             </div>
//                         </a>

//                     </li>
//                     <li class="has-subnav">
//                         <a href={"/MyProfile"} className={path === "/MyProfile" ? "sidenav-image-a-myprofile" : "sidenav-image-a"}>
//                             <div style={{ display: "flex", width: "100%" }}>
//                                 <div style={{ padding: "18px 30px 18px 30px", width: "20%", alignItems: "center" }}>
//                                     <i class="fa fa-user fa-2x fontA"></i>
//                                 </div>
//                                 <div style={{ padding: "18px 100px 18px 17px", width: "100%" }}>
//                                     <span class="nav-text">Profile</span>
//                                 </div>
//                             </div>
//                         </a>
//                     </li>
//                     <li class="has-subnav" style={{padding:"0px"}}>
//                         <a href={"/"} className={"sidenav-image-a-settings"}>
//                             <div style={{ display: "flex", width: "100%" }}>
//                                 <List sx={{ width: '100%', bgcolor: 'transparent', padding:0 }}>
//                                     <ListItemButton onClick={(e) => handleClick(e)} style={{padding:0}}>
//                                         <div style={{ padding: "18px 30px 18px 30px" }}>
//                                             <i class="fa fa-gear fa-2x fontA"></i>
//                                         </div>
//                                         <div style={{ padding: "18px 100px 18px 1px" }}>
//                                             <span class="nav-text">Admin </span>
//                                         </div>
//                                         {open ? <ExpandLess /> : <ExpandMore />}
//                                     </ListItemButton>
//                                     <Collapse in={open} timeout="auto" unmountOnExit style={{maxWidth:"80%"}}>
//                                         <List component="div" style={{marginLeft:"55px"}}>
//                                             <ListItemButton sx={{ pl: 4 }} type="button" >
//                                                 <ListItemText primary="User Type" onClick={(e) => handleNavigateUser(e)} />
//                                             </ListItemButton>
//                                             <ListItemButton sx={{ pl: 4 }}>
//                                                 <ListItemText primary="Program Type" onClick={(e) => handleNavigateProgram(e)}/>
//                                             </ListItemButton>
//                                             <ListItemButton sx={{ pl: 4 }}>
//                                                 <ListItemText primary="Batch Type" onClick={(e) => handleNavigateBatch(e)}/>
//                                             </ListItemButton>
//                                         </List>
//                                     </Collapse>
//                                 </List>
//                             </div>

//                         </a>

//                     </li>
//                 </ul>

//                 <ul class="logout" onClick={(e) => handleLogout(e)}>
//                     <li class="has-subnav">
//                         <a className="sidenav-image-a">
//                             <div style={{ display: "flex", width: "100%" }}>
//                                 <div style={{ padding: "18px 30px 18px 30px", width: "20%", alignItems: "center" }}>
//                                     <i class="fa fa-power-off fa-2x fontA" style={{ padding: "1px 0 1px 0" }}></i>
//                                 </div>
//                                 <div style={{ padding: "18px 156px 18px 17px", width: "80%" }}>
//                                     <span class="nav-text">
//                                         Logout
//                                     </span>
//                                 </div>
//                             </div>
//                         </a>
//                     </li>
//                 </ul>
//             </nav>
//         </div>

//     );

// }
