
import React, { useEffect, useState, useMemo } from "react";
import '../reusables/ModalInput/ModalInputStyles.css';
//modal
import { IconButton, TextField, Autocomplete, Chip, Box, ListItemText, Typography, Checkbox, ListSubheader, InputAdornment } from "@mui/material";
import { Color } from "../../GlobalStyles.js";
import Modal from "react-bootstrap/Modal";
import CollegeData from '../../CollegeDataServices/httpCollege'
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Service from '../../services/httpService';
import { useLocation } from 'react-router-dom';
import { UserTypeContext as RowsContext } from "../pages/RowsContext/RowsContext";
import Cookies from 'js-cookie';
import OnGoingGroupSessions from "../Dashboard/OnGoingGroupSessions/OnGoingGroupSessions"
import dayjs from 'dayjs';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import SideNavigationBar from '../reusables/SideNavigationBar/SideNavigationBar';
import Loader from "../reusables/Loader/Loader";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CancelIcon from '@mui/icons-material/Cancel';
import ServiceCM from "../../services/cartmoduleServices.js";
import GroupSessionDateAndTime from "./GroupSessionDateAndTime.js";
import { calculateEpochTimeInLocalTime, extractDateFromDateString } from "../../services/specialCharReplace.js";
import SearchIcon from "@mui/icons-material/Search";
import { send_specialCharReplace, get_specialCharReplace } from "../../services/specialCharReplace.js";
import { ActivityLoader } from "../reusables/Loader/Loader";
import CircularProgress from '@mui/material/CircularProgress';
const UserType = () => {


	const [loading, setLoading] = useState(true);
	const [submittingLoader, setSubmittingLoader] = useState(false)
	const [dateAndTimePickerModal, setDateAndTimePickerModal] = useState(false);

	const collegeService = new CollegeData()
	const service = new Service()
	const cartModule = new ServiceCM()
	const navigate = useNavigate();
	const location = useLocation();

	let college_id = location.state?.college_id
	let college_name1 = location.state?.college_name || ""
	let batchName = location.state?.batchName
	let batchId = location.state?.batchId
	const [groupSessionsDetails, setGroupSessionsDetails] = useState({ name_of_session: "", description: "", link: "", session_keywords: [], notify_student: false, date_time: null, duration: "", activity_name: "", mentor_name: "" })
	console.log("details", college_id, college_name1, batchName, batchId);

	const [selectDateTime, setSelectDateTime] = useState(dayjs('2023-03-27T15:30'));

	const goBack = () => {
		console.log("Back Button is Clicked Now")
		navigate(-1);
	}


	// group session data
	const [GroupSessionData, setGroupSessionData] = useState([])

	const [rowsData, setRowsData] = useState([{ id: null, sku_id: null, duration_name: "", mentor_id: null, college_id: null, batch_id: null, date: "", time: "", link: "", description: "", formattedTime: "" }])

	//from table index
	const [from_table, setFrom_table] = useState();
	const [errMsg, setErrMsg] = useState("")
	// getter of activity, duration, mentor, college and batch
	const [roleData, setRoleData] = useState([])
	const [activityTypeData, setActivityTypeData] = useState([])
	const [durationData, setDurationData] = useState([])
	const [mentorData, setMentorData] = useState([])
	const [collegeData, setCollegeData] = useState([])
	const [batchData, setBatchData] = useState([])
	const [role_details, setRole_details] = useState([])
	const [specialization_details, setSpecialization_details] = useState([])
	// name of college mentor and activity
	const [activity_name, setActivity_name] = useState()
	const [college_name, setCollege_name] = useState()
	const [duration_name, setDuration_name] = useState()
	const [searchText, setSearchText] = useState("");
	const containsText = (text, searchText) =>
		text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

	console.log("containsText", containsText);
	const [mentor_name, setMentor_name] = useState()
	// const displayedOptions = useMemo(() => {
	// 	if (!mentor_name) {
	// 		return [];
	// 	}
	// 	return searchText
	// 		? mentor_name.filter((option) => containsText(option.key, searchText))
	// 		: mentor_name;
	// }, [mentor_name, searchText]);


	// console.log("displayedOptions", displayedOptions);
	const [batch_name, setBatch_name] = useState()
	// ids of college and mentor 
	const [gs_activity_id, setGS_activity_id] = useState()
	const [gs_duration_id, setGS_duration_id] = useState()
	const [gs_mentor_id, setGS_mentor_id] = useState()
	const [gs_college_id, setGS_college_id] = useState()
	const [gs_batch_id, setGS_batch_id] = useState()


	//changing the time format form the get response to hh:mm
	const getTimeFormatted = async (time) => {
		let milliseconds = parseInt(time);
		const hours = Math.floor(milliseconds / 3600000);
		const minutes = Math.floor((milliseconds % 3600000) / 60000);
		const ampm = hours >= 12 ? 'PM' : 'AM';

		// convert the hour from 24-hour to 12-hour format
		const formattedHour = hours % 12 === 0 ? 12 : hours % 12;

		return `${formattedHour}:${minutes.toString().padStart(2, '0')} ${ampm}`;
	}
	const [jd_input_name, setJd_input_name] = useState(GroupSessionData[from_table?.index]?.user_group_name);
	const [date, setDate] = useState(GroupSessionData[from_table?.index]?.date)
	const [time, setTime] = useState(getTimeFormatted(GroupSessionData[from_table?.index]?.time))
	const [link, setLink] = useState(GroupSessionData[from_table?.index]?.link)
	const [description, setDescription] = useState(GroupSessionData[from_table?.index]?.description)

	//activity
	const [activity, setActivity] = useState("");

	// search mentor


	const [selectedValues, setSelectedValues] = useState([]);
	const [selectedSpecializationValues, setSelectedSpecializationValues] = useState([])
	const handleChangeOptions = (event) => {
		setSelectedValues(event.target.value);
	};
	console.log("groupSessionsDetails", groupSessionsDetails, gs_activity_id, gs_duration_id, gs_mentor_id, selectedValues);
	const handleDeleteOptions = (valueToDelete) => {
		console.log("am i being called");
		setSelectedValues((prevValues) =>
			prevValues.filter((value) => value !== valueToDelete)
		);
	};

	const handleChangeSpecializationOptions = (event) => {
		setSelectedSpecializationValues(event.target.value);
	};
	console.log("groupSessionsDetails", groupSessionsDetails, gs_activity_id, gs_duration_id, gs_mentor_id, selectedValues);
	// const handleDeleteSpecializationOptions = (e, valueToDelete) => {
	// 	setSelectedSpecializationValues((prevValues) =>
	// 		prevValues.filter((value) => value !== valueToDelete)
	// 	);
	// };
	const handleDeleteSpecializationOptions = (value) => {
		console.log("delete clicked");
		// event.stopPropagation();
		setSelectedSpecializationValues((prevValues) =>
			prevValues.filter((selectedValue) => selectedValue !== value)
		);
	};

	console.log("searchText", searchText);
	const data_getter = (name, id, index, act) => {
		// setRowsData(data)
		console.log("Dude here's your name:", name);
		console.log("Dude here's your id:", id);
		console.log("Dude here's your id:", index);
		setActivity(act);

		if (act === "add") {
			setJd_input_name()
			// setJd_batch_id()
			setDate()
			setTime()
			setLink()
			setDescription()
			setGS_activity_id()
			setGS_duration_id()
			setGS_batch_id()
			setGS_college_id()
			setGS_mentor_id()
			// setJd_program_id()
			setGroupSessionsDetails((prev) => {
				return {
					...prev,
					description: "",
					link: "",
					name_of_session: "",
					session_keywords: [],
					notify_student: false,
					date_time: null,
					activity_name: "",
					mentor_name: ""
				};
			});
			setSelectedValues([])
			setSelectedSpecializationValues([])
		} else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
			handleDelete(id, index);
		}

		setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
	}



	const handleDelete = async (id, index) => {
		console.log("delete", id)
		let list = [...rowsData];

		console.log("listtttt1", list);
		service
			.delete(`/groupSession?id=${id}`)
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list.splice(index, 1);
				setRowsData(list)
				setBatchData(list)
				console.log("listtttt2", list);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);

				console.log(err.response.data.message)
				window.alert(err.response.data.message)

			});
	}

	const batchDataGetter = async () => {
		try {
			const res = await service.get(`/groupSession?college_id=${college_id}`)
			console.log("group session info RESPONSE:", res.data)
			setGroupSessionData([...res.data])
			const dataWithFormattedTime = res.data.map(item => {
				return {
					...item,
					time: getTimeFormatted(item.time)
				};
			});
			setRowsData([...dataWithFormattedTime])
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	console.log("setGroupSessionData", GroupSessionData);

	const activityTypeGetter = async () => {

		try {
			const res = await cartModule.get(`/sku?type=Group`)
			console.log("Response from backend for activity name:", res.data)
			setActivityTypeData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}

		console.log("Response activity type data", activityTypeData);
	}




	const DurationGetter = async () => {

		try {
			const res = await service.get('/duration')
			console.log("Response from backend for duration:", res.durations)
			return res.durations
		} catch (err) {
			console.log(err)
		}
		console.log("Response duration type data", durationData);

	}

	const CollegeGetter = async () => {

		try {
			const res = await collegeService.get('/college')
			console.log("Response from backend for college:", res.data)
			setCollegeData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
		console.log(" Response college type data", collegeData);

	}

	const roleGetter = async () => {
		try {
			const res = await service.get('/role?is_admin=1')
			console.log("Response from backend for college:", res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	const specializationGetter = async () => {
		try {
			const res = await service.get('/specialization?is_admin=1')
			console.log("Response from backend for college:", res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	const mentorGetter = async () => {

		try {
			const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
			console.log("Response from backend for mentor:", res.data)
			setMentorData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
		console.log("Response mentor type data", mentorData);

	}


	const batchGetter = async () => {

		try {
			const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/collegeModule/userGroup`)
			console.log("Response from backend for batch:", res.data)
			setBatchData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
		console.log("Response bacth type data", batchData);

	}

	// get request
	useEffect(() => {
		console.log("Brother")
		const handleEffect = async () => {

			if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
				navigate('/');
			}
			//batch data getter
			const batchData = await batchDataGetter();
			console.log("batch data", batchData);
			//batch getter 
			const activityType = await activityTypeGetter();
			const duration = await DurationGetter();
			const mentor = await mentorGetter()
			const college = await CollegeGetter()
			const batch = await batchGetter()
			const role = await roleGetter()
			const specialization = await specializationGetter()
			// setRole_and_specialization([...role, ...specialization])
			setRole_details(role)
			setSpecialization_details(specialization)
			data_manupulator_activity(activityType);
			data_manupulator_duration(duration);
			data_manupulator_mentor(mentor);
			data_manupulator_college(college);
			data_manupulator_batch(batch);
			setLoading(false)
		}

		handleEffect();
	}, [gs_college_id])

	console.log("role_and_specialization", role_details, specialization_details);
	useEffect(() => {
		const setting_data = async () => {

			if (from_table?.index === undefined) {
				console.log("Inside if of useEffect")
				setJd_input_name()
				setTime()
				setDate()
				setLink()
				setDescription()
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						description: "",
						link: "",
						name_of_session: "",
						session_keywords: [],
						notify_student: false,
						date_time: null,
						activity_name: "",
						mentor_name: ""
					};
				});
				setSelectedValues([])
				setSelectedSpecializationValues([])
				setGS_activity_id()
				setGS_batch_id()
				setGS_college_id()
				setGS_duration_id()
				setGS_mentor_id()
			}
			else {
				//setting values for Edit Modal

				setJd_input_name(GroupSessionData[from_table?.index]?.user_group_name)
				setTime(await getTimeFormatted(GroupSessionData[from_table?.index]?.time))
				console.log("time in the table", getTimeFormatted(GroupSessionData[from_table?.index]?.time));
				setDate(GroupSessionData[from_table?.index]?.date)
				console.log("time in the table", GroupSessionData[from_table?.index]?.date);

				setDescription(GroupSessionData[from_table?.index]?.description)
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						description: get_specialCharReplace(GroupSessionData[from_table?.index]?.description),
						link: GroupSessionData[from_table?.index]?.link,
						name_of_session: GroupSessionData[from_table?.index]?.name,
						session_keywords: typeof GroupSessionData[from_table?.index]?.keywords === "string" ? JSON.parse(GroupSessionData[from_table?.index]?.keywords) : GroupSessionData[from_table?.index]?.keywords,
						date_time: GroupSessionData[from_table?.index]?.date_time,
						duration: GroupSessionData[from_table?.index]?.duration_name,
						activity_name: activity_name.find(pro => pro.id === GroupSessionData[from_table?.index]?.sku_id)?.key,
						mentor_name: mentor_name.find(pro => pro.id === GroupSessionData[from_table?.index]?.mentor_id)?.key
					};
				});
				setSelectedValues(typeof GroupSessionData[from_table?.index]?.role_ids === "string" ? JSON.parse(GroupSessionData[from_table?.index]?.role_ids) : GroupSessionData[from_table?.index]?.role_ids)
				setSelectedSpecializationValues(typeof GroupSessionData[from_table?.index]?.spe_ids === "string" ? JSON.parse(GroupSessionData[from_table?.index]?.spe_ids) : GroupSessionData[from_table?.index]?.spe_ids)
				setLink(GroupSessionData[from_table?.index]?.link)
				console.log({ GroupSessionData }, { roleData }, { setActivityTypeData })
				setGS_activity_id(GroupSessionData[from_table?.index]?.sku_id)
				setGS_batch_id(GroupSessionData[from_table?.index]?.batch_id)
				setGS_college_id(GroupSessionData[from_table?.index]?.college_id)
				setGS_duration_id(GroupSessionData[from_table?.index]?.duration_name)
				setGS_mentor_id(GroupSessionData[from_table?.index]?.mentor_id)
			}
			console.log("group session data", GroupSessionData);

		}
		setting_data();
	}, [from_table])
	console.log("time in the table", date, time);

	const handleDateTimePickerOpen = () => {
		setDateAndTimePickerModal(true)
	}

	const handleDateTimePickerClose = () => {
		setDateAndTimePickerModal(false)
	}

	const data_manupulator_activity = (activityType) => {
		console.log("activity type", activityType);
		// let temp = activityType;
		let temp = activityType.filter(act => act.type === "Group");
		temp = temp.map(bat => {
			bat.key = bat.name;
			bat.id = bat.id;
			bat.type = bat.type
			return bat;
		});
		console.log("temp rows activity:", temp)
		setActivity_name(temp)
		console.log("activity group names", activity_name);
	}
	const data_manupulator_duration = (duration) => {
		console.log("duration name", duration);

		let temp = duration;
		temp = temp?.map(bat => {
			bat.key = bat.duration_name;
			// bat.id = bat.id;
			return bat;
		});
		console.log("temp rows batch:", temp)
		setDuration_name(temp)
	}
	const data_manupulator_mentor = (mentor) => {
		console.log("mentor type", mentor);

		let temp = mentor;
		temp = temp?.map(bat => {
			bat.key = `${bat.first_name} ${bat.last_name}`;
			bat.id = bat.user_id;
			return bat;
		});
		console.log("temp rows mentor:", temp)
		setMentor_name(temp)
	}
	const data_manupulator_college = (college) => {
		console.log("college type", college);

		let temp = college;
		temp = temp?.map(bat => {
			bat.key = bat.college_name;
			bat.id = bat.id;
			return bat;
		});
		setCollege_name(temp)
		console.log("temp rows batch:", temp)

	}

	const data_manupulator_batch = (batch) => {
		console.log("bactyh type", batch);

		let temp = batch;
		temp = temp?.map(bat => {
			bat.key = bat.user_group_name;
			bat.id = bat.id;
			return bat;
		});
		console.log("temp rows batch:", temp)
		setBatch_name(temp)
	}


	//MODAL
	const Modal_JD = (show, setShow, mode) => {
		console.log("bhdbjf", show, setShow);

		const modalSubmit = async () => {

			if (activity === "add")
				await handleFormSubmission(gs_activity_id, gs_duration_id, gs_mentor_id, college_id, batchId, date, time, link, description, setShow);
			else
				await handlePutRequest(GroupSessionData[from_table?.index].id, gs_activity_id, gs_duration_id, gs_mentor_id, gs_college_id, gs_batch_id, date, time, link, description, setShow);

		}






		const onHide = () => {
			setShow(false)
			setJd_input_name()
			setTime()
			setDate()
			setDescription()
			setGroupSessionsDetails((prev) => {
				return {
					...prev,
					description: "",
					link: "",
					name_of_session: "",
					session_keywords: [],
					notify_student: false,
					date_time: null,
					duration: "",
					activity_name: "",
					mentor_name: ""
				};
			});
			setSelectedValues([])
			setSelectedSpecializationValues([])
			setLink()
			setGS_activity_id()
			setGS_batch_id()
			setGS_college_id()
			setGS_duration_id()
			setGS_mentor_id()
			setErrMsg("")
		}
		// const filteredOptions = mentor_name?.filter((option) =>
		// 	option.key.toLowerCase().includes(searchText.toLowerCase())
		// );

		const handleChange = (e, index) => {
			console.log("name from form  is:", e.target.name);
			console.log("value from form  is:", e.target.value);

			if (e.target.name === "activity_name") {
				setGS_activity_id(e.target.value)
				console.log("activity selected id", e.target.value)
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						activity_name: activity_name.find(pro => pro.id === e.target.value)?.key,
					};
				});
			}
			else if (e.target.name === "duration_name") {
				setGS_duration_id(e.target.value)
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						duration: e.target.value,
					};
				});
				console.log("duration selected id", e.target.value)
			} else if (e.target.name === "mentor_name") {
				setGS_mentor_id(e.target.value)
				setSearchText('');
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						mentor_name: mentor_name.find(pro => pro.id === e.target.value)?.key,
					};
				});
				console.log("mentor selected id", e.target.value)
			} else if (e.target.name === "college_name") {
				setGS_college_id(e.target.value)
				console.log("college selected id", e.target.value)
				console.log("batch according to college id", gs_college_id);
			} else if (e.target.name === "batch_name") {
				setGS_batch_id(e.target.value)
				console.log("batch selected id", e.target.value)
			} else if (e.target.name === "link") {
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						link: e.target.value,
					};
				});
				// setLink(e.target.value)
				console.log("time selected", e.target.value)
			} else if (e.target.name === "description") {
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						description: e.target.value,
					};
				});
				// setDescription(e.target.value)
				console.log("description....", e.target.value)
			} else if (e.target.name === "name_of_session") {
				setGroupSessionsDetails((prev) => {
					return {
						...prev,
						name_of_session: e.target.value,
					};
				});
			}


		}

		const filteredMentors = mentor_name.filter(
			(mentor) =>
				mentor.key.toLowerCase().includes(searchText.toLowerCase()) ||
				mentor.email_id.toLowerCase().includes(searchText.toLowerCase())
		);



		let formattedDate = dayjs('2022-04-17T15:30');
		if (date && time) {
			formattedDate = dayjs(date + ' ' + time);
		}



		//render
		return (
			<Modal
				show={show}
				onHide={() => setShow(false)}
				className="FileUploadModal"
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ backdropFilter: "blur(5px)", minWidth: "80%", maxWidth: "95%", minHeight: "80%", maxHeight: "95%" }}
			>
				<Modal.Body
					style={{
						position: 'absolute',
						left: '50%',
						height: 'auto',
						transform: 'translate(-50%, -50%)',
						lineHeight: '1.4',
						padding: '14px 28px',
						backgroundColor: '#fff',
						borderRadius: '10px',
						maxWidth: '95%',
						minWidth: '1000px',
						minHeight: '400px'
					}}
				>
					<form className="Entire-form" style={{ justifyContent: 'center' }}>
						<div className='row full-div'>
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
								<CloseIcon className="closeIcon" onClick={onHide} />
								<p className="compTitle" style={{ fontSize: "32px", textAlign: "center", background: 'transparent' }}>Group Session Details</p>
							</div>
							<div className="groupSessionCompleteData" style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div className="groupSession" style={{ width: "45%", display: "flex", flexDirection: "column", gap: "4px" }}>
									<div className="form-group-session">
										<label className="inputLabel">Name of the session<span>*</span></label>
										<input
											name="name_of_session"
											type="text"
											className="form-control inputFields"
											placeholder="Example input"
											value={groupSessionsDetails.name_of_session}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group-session">
										<label className="inputLabel">Duration<span>*</span></label>
										<Select
											name="duration_name"
											id="selectList"
											className="form-control inputFields"
											onChange={handleChange}
											value={gs_duration_id || groupSessionsDetails.duration}
										>
											{duration_name?.map((pro, i) => (
												<MenuItem key={i} value={pro.key}>
													<ListItemText primary={pro.key} />
												</MenuItem>
											))}
										</Select>
									</div>
									<div className="form-group-session">
										<Typography>Date and Time</Typography>
										<form noValidate autoComplete="off" onClick={handleDateTimePickerOpen} style={{ width: "100%", height: "40px" }}>
											<FormControl fullWidth sx={{ height: "40px" }}>
												<OutlinedInput placeholder="Choose Date and Time" value={groupSessionsDetails.date_time ? `${extractDateFromDateString(groupSessionsDetails.date_time)} ${calculateEpochTimeInLocalTime(groupSessionsDetails.date_time)}` : "Choose Date and Time"} />
											</FormControl>
										</form>
									</div>
									<div className="form-group-session" style={{ marginTop: "1rem" }}>
										<Typography sx={{ marginBottom: ".3rem" }}>Specialization</Typography>
										<FormControl fullWidth>
											<Select
												sx={{ minHeight: "40px", height: 'auto' }}
												labelId="multiselect-label"
												id="multiselect"
												multiple
												value={selectedSpecializationValues}
												onChange={handleChangeSpecializationOptions}
												onMouseDown={(event) => {
													event.stopPropagation();
												  }}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 200, // adjust the max height as needed
														},
													},
													getContentAnchorEl: null,
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "left",
													},
													transformOrigin: {
														vertical: "top",
														horizontal: "left",
													},
												}}
												renderValue={(selected) => (
													<div>
													  {selected.map((value) => (
														<Chip  onMouseDown={(event) => {
															event.stopPropagation();
														   }} key={value} label={specialization_details.find((spec) => spec.id === value)?.name} onDelete={() => handleDeleteSpecializationOptions(value)} />
													  ))}
													</div>
												  )}
											>
												{specialization_details.map((spec) => (
													<MenuItem key={spec.id} value={spec.id}>
														{spec.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>

									</div>
									<div className="form-group-session">
										<label className="inputLabel">Description<span>*</span></label>
										<textarea
											name="description"
											className="form-control inputFields"
											rows="3"
											style={{ height: 'auto' }}
											placeholder="Example input"
											value={groupSessionsDetails.description}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group-session">
										<div style={{ display: "flex", flexDirection: 'row', marginTop: ".5rem" }}>
											<Checkbox
												checked={groupSessionsDetails.notify_student}
												onChange={(e) => setGroupSessionsDetails((prev) => ({
													...prev,
													notify_student: e.target.checked,
												}))}
												sx={{ padding: "0px" }}
											/>
											<Typography style={{ margin: 'auto 0' }}>Notify Students</Typography>
										</div>
									</div>
								</div>
								<div className="groupSession" style={{ width: "45%", display: "flex", flexDirection: "column", gap: "4px" }}>
									<div className="form-group-session">
										<label className="inputLabel">Activity Type<span>*</span></label>
										<Select
											name="activity_name"
											id="selectList"
											className="form-control inputFields"
											value={gs_activity_id || groupSessionsDetails.activity_name}
											onChange={handleChange}
										>
											{activity_name?.map((pro, i) => (
												<MenuItem key={i} value={pro.id}>
													<ListItemText primary={pro.key} />
												</MenuItem>
											))}
										</Select>
									</div>
									<div className="form-group-session">
										{/* <label className="inputLabel">Mentor Name<span>*</span></label>
										<Select
											name="mentor_name"
											id="selectList"
											className="form-control inputFields"
											onChange={handleChange}
											value={gs_mentor_id}
											placeholder="Select Mentor"
										>
											{mentor_name?.map((pro, i) => (
												<MenuItem key={i} value={pro.id}>
													<ListItemText primary={pro.key} />
												</MenuItem>
											))}
										</Select> */}
										<Typography>Mentor</Typography>
										<FormControl fullWidth>
											<InputLabel id="search-select-label">Options</InputLabel>
											<Select
												labelId="search-select-label"
												id="search-select"
												value={gs_mentor_id || groupSessionsDetails.mentor_name}
												label="Options"
												name="mentor_name"
												onChange={handleChange}
												onClose={() => setSearchText("")}
												renderValue={() => groupSessionsDetails.mentor_name}
												MenuProps={{
													autoFocus: false, disableAutoFocusItem: true,
													PaperProps: {
														style: {
															maxHeight: 200,
														},
													},
													getContentAnchorEl: null,
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "left",
													},
													transformOrigin: {
														vertical: "top",
														horizontal: "left",
													},
												}}

											>
												<ListSubheader>
													<TextField
														size="small"
														autoFocus
														placeholder="Type to search..."
														fullWidth
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<SearchIcon />
																</InputAdornment>
															)
														}}
														// value={searchText}
														onChange={(e) => setSearchText(e.target.value)}
														onKeyDown={(e) => {
															if (e.key !== 'Escape') {
																e.stopPropagation();
															}
														}}
													/>
												</ListSubheader>
												{filteredMentors.map((mentor, i) => (
													<MenuItem key={i} value={mentor.id}>
														{mentor.key}{' '}
														<span style={{ fontSize: '12px', color: 'grey' }}>
															({mentor.email_id})
														</span>
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
									<div className="form-group-session">
										<label className="inputLabel">Link<span>*</span></label>
										<input
											name="link"
											type="text"
											className="form-control inputFields"
											placeholder="Example input"
											value={groupSessionsDetails.link}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group-session">
										<Typography>Role</Typography>
										<FormControl fullWidth>
											<Select
												labelId="multiselect-label"
												sx={{ minHeight: "40px", height: 'auto' }}
												id="multiselect"
												multiple
												value={selectedValues}
												onChange={handleChangeOptions}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 200, // adjust the max height as needed
														},
													},
													getContentAnchorEl: null,
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "left",
													},
													transformOrigin: {
														vertical: "top",
														horizontal: "left",
													},
												}}
												inputProps={{
													id: "deviceSource-native-select",
													name: "deviceSource"
												}}
												renderValue={(selected) => (
													<div>
													  {selected.map((value) => (
														<Chip  onMouseDown={(event) => {
															event.stopPropagation();
														   }} key={value} label={role_details.find((spec) => spec.id === value)?.name} onDelete={() => handleDeleteOptions(value)} />
													  ))}
													</div>
												  )}
											>
												{role_details.map((spec) => (
													<MenuItem key={spec.id} value={spec.id}>
														{spec.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
									<div className="form-group-session">
										<Typography>Keywords</Typography>
										<Autocomplete
											sx={{ height: "40px" }}
											multiple
											autoComplete="off"
											placeholder="Type Skills..."
											options={[]}
											value={groupSessionsDetails.session_keywords}
											freeSolo
											renderTags={(value, getTagProps) =>
												value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
											}
											renderInput={(params) => <TextField {...params} />}
											getOptionLabel={(option) => option}
											filterOptions={(options, params) => {
												const filtered = options.filter((option) =>
													option.toLowerCase().includes(params.inputValue.toLowerCase())
												);

												if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
													filtered.push(params.inputValue);
												}

												return filtered;
											}}
											isOptionEqualToValue={(option, value) => option === value}
											onChange={(e, values) => {
												const session_keywords = values.map((value) => value.split(",")).flat();
												setGroupSessionsDetails((prev) => ({
													...prev,
													session_keywords: session_keywords,
												}));
											}}
										/>
									</div>
								</div>
							</div>
							<div className="form-group-session btn-comp" style={{ marginTop: "4%", textAlign: 'center' }}>
								<button type="button" onClick={modalSubmit} className="btn submit-btn">{submittingLoader ? <CircularProgress /> : "Submit"}</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>

		)
	}


	// post request
	const handleFormSubmission = async (sku_id, duration_name, mentor_id, college_id, batch_id, date, time, link, description, setShow) => {
		let temp_rows = [...rowsData];
		let temp_batchData = JSON.parse(JSON.stringify(GroupSessionData));
		setSubmittingLoader(true)
		let data = {
			sku_id: parseInt(sku_id),
			duration_name: duration_name,
			mentor_id: parseInt(mentor_id),
			college_id: parseInt(college_id),
			batch_id: parseInt(batch_id),
			date_time: groupSessionsDetails.date_time,
			link: groupSessionsDetails.link,
			description: send_specialCharReplace(groupSessionsDetails.description),
			name: groupSessionsDetails.name_of_session,
			keywords: groupSessionsDetails.session_keywords,
			role_ids: selectedValues,
			spe_ids: selectedSpecializationValues,
			notify_student: groupSessionsDetails.notify_student === true ? 1 : 0
		}

		console.log("User TYpe id not found, sending teh post request", temp_rows);
		await service
			.post("/groupSession", data)
			.then((res) => {
				console.log("Response from backend is", JSON.stringify(res, null, 2));
				console.log(res)
				console.log(res?.data)
				let temp = JSON.parse(JSON.stringify(rowsData));


				temp = ([{
					id: res?.data?.id,
					sku_id: parseInt(sku_id),
					duration_name: duration_name,
					mentor_id: parseInt(mentor_id),
					college_id: parseInt(college_id),
					batch_id: parseInt(batch_id),
					date_time: groupSessionsDetails.date_time,
					link: groupSessionsDetails.link,
					description: groupSessionsDetails.description,
					name: groupSessionsDetails.name_of_session,
					keywords: groupSessionsDetails.session_keywords,
					role_ids: selectedValues,
					spe_ids: selectedSpecializationValues,
					notify_student: groupSessionsDetails.notify_student === true ? 1 : 0
				}, ...temp])
				console.log(".then ~ temp", temp)
				setRowsData(temp)


				temp_batchData = ([{
					id: res?.data?.id,
					sku_id: sku_id,
					duration_name: duration_name,
					mentor_id: parseInt(mentor_id),
					college_id: parseInt(college_id),
					batch_id: parseInt(batch_id),
					date_time: groupSessionsDetails.date_time,
					link: groupSessionsDetails.link,
					description: groupSessionsDetails.description,
					name: groupSessionsDetails.name_of_session,
					keywords: groupSessionsDetails.session_keywords,
					role_ids: selectedValues,
					spe_ids: selectedSpecializationValues,
					notify_student: groupSessionsDetails.notify_student === true ? 1 : 0
				}, ...temp_batchData])

				setGroupSessionData(temp_batchData)
				console.log("batch data right now", temp_batchData);
				setSubmittingLoader(false)
				setShow(false)
			})
			.catch((err) => {
				console.log("Errog Msg: ", err);
				setSubmittingLoader(false)
				setShow(false)
				window.alert(err.response.data.message)
			});

		return temp_batchData

	}

	const handlePutRequest = async (id, sku_id, duration_name, mentor_id, college_id, batch_id, date, time, link, description, setShow) => {
		//get request here
		let temp_batchData = JSON.parse(JSON.stringify(GroupSessionData));

		try {
			setSubmittingLoader(true)
			const res = service.put("/groupSession", {
				id: id,
				sku_id: parseInt(sku_id),
				duration_name: duration_name,
				mentor_id: parseInt(mentor_id),
				college_id: parseInt(college_id),
				batch_id: parseInt(batch_id),
				date_time: groupSessionsDetails.date_time,
				link: groupSessionsDetails.link,
				description: send_specialCharReplace(groupSessionsDetails.description),
				name: groupSessionsDetails.name_of_session,
				keywords: groupSessionsDetails.session_keywords,
				role_ids: selectedValues,
				spe_ids: selectedSpecializationValues,
				notify_student: groupSessionsDetails.notify_student === true ? 1 : 0
			})
			console.log("Response from backend is", JSON.stringify(res, null, 2));



			let temp = JSON.parse(JSON.stringify(rowsData));

			temp[from_table?.index].sku_id = parseInt(gs_activity_id)
			temp[from_table?.index].duration_name = groupSessionsDetails.duration
			temp[from_table?.index].mentor_id = parseInt(gs_mentor_id)
			temp[from_table?.index].college_id = parseInt(gs_college_id)
			temp[from_table?.index].batch_id = parseInt(gs_batch_id)
			temp[from_table?.index].date_time = groupSessionsDetails.date_time
			temp[from_table?.index].link = groupSessionsDetails.link
			temp[from_table?.index].description = groupSessionsDetails.description
			temp[from_table?.index].name = groupSessionsDetails.name_of_session
			temp[from_table?.index].keywords = groupSessionsDetails.session_keywords
			temp[from_table?.index].role_ids = selectedValues
			temp[from_table?.index].spe_ids = selectedSpecializationValues
			temp[from_table?.index].notify_student = groupSessionsDetails.notify_student === true ? 1 : 0
			console.log("Matey", temp)
			setRowsData(temp)


			temp_batchData[from_table?.index].sku_id = gs_activity_id
			temp_batchData[from_table?.index].duration_name = duration_name
			temp_batchData[from_table?.index].mentor_id = parseInt(mentor_id)
			temp_batchData[from_table?.index].college_id = parseInt(college_id)
			temp_batchData[from_table?.index].batch_id = parseInt(batch_id)
			temp[from_table?.index].date_time = groupSessionsDetails.date_time
			temp[from_table?.index].link = groupSessionsDetails.link
			temp[from_table?.index].description = groupSessionsDetails.description
			temp[from_table?.index].name = groupSessionsDetails.name_of_session
			temp[from_table?.index].keywords = groupSessionsDetails.session_keywords
			temp[from_table?.index].role_ids = selectedValues
			temp[from_table?.index].spe_ids = selectedSpecializationValues
			temp[from_table?.index].notify_student = groupSessionsDetails.notify_student === true ? 1 : 0
			console.log(temp_batchData)


			setGroupSessionData(temp_batchData)

			setSubmittingLoader(false)
			setShow(false)
		} catch (err) {
			console.log("Errog Msg: ", err);
			setSubmittingLoader(false)
			setShow(false)
			window.alert(err.response.data.message)
		};
		return temp_batchData
	}
	console.log("searchText", searchText);
	return (
		<RowsContext.Provider value={rowsData}>
			{loading ? <ActivityLoader show={loading} /> : <>
				<SideNavigationBar />
				{submittingLoader ? <ActivityLoader show={submittingLoader} /> : <>
					<OnGoingGroupSessions role_details={role_details} specialization_details={specialization_details} batchName={batchName} college_name={college_name1} activityTypeData={activityTypeData} durationData={durationData} mentorData={mentorData} collegeData={collegeData} batchData={batchData} GroupSessionData={GroupSessionData} mainFile_modal={Modal_JD} data_getter={data_getter} path={window.location.pathname} />

				</>}
				<GroupSessionDateAndTime modalOpen={dateAndTimePickerModal} handleModalClose={handleDateTimePickerClose} groupSessionsDetails={groupSessionsDetails} setGroupSessionsDetails={setGroupSessionsDetails} />
			</>}

		</RowsContext.Provider>
	)
}

export default UserType