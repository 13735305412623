import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, FormControlLabel } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from 'react-router-dom';
import { Color } from '../../../GlobalStyles';
import ServiceCM from '../../../services/cartmoduleServices';
import { extractDateFromDateString } from '../../../services/specialCharReplace';
import '../OngoingEvents/OngoingEvents.css';
import CollegeData from '../../../CollegeDataServices/httpCollege';
import LazyLoadingList from './RoleAndSpecializationCount';
import GroupChatModal from '../../GroupChatModal/GroupChatModal';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

const ProcessCard = (props) => {
    const location = useLocation();
    let college_name = location.state?.college_name || props.college_name
    let batchName = location.state?.batchName || props.batchName
    const [from_table, setFrom_table] = useState();
    const [rowsData, setRowsData] = useState([{ id: null, user_group_id: null, activity_type_id: null, duration_name: "", is_deleted: 0 }])
    const [selectSkuId, setSelectSkuId] = useState({ id: null, name: "" });
    const [expandedCardDetails, setExpandedCardDetails] = useState({})
    const [formInputs, setFormInputs] = useState({ start_date: null, end_date: null, recording: 1, timeZone: 'Asia/Kolkata', process_id: null, is_offline: 0 })
    const collegeData = new CollegeData()
    const [show, setShow] = useState(false)
    const [jd_input_name, setJd_input_name] = useState();
    const [jd_select_id, setJd_select_id] = useState();
    const [jd_program_id, setJd_program_id] = useState();
    const [activity, setActivity] = useState("");
    const [is_toggled, setIs_toggled] = useState(1)
    const [selectedProcessIndex, setSelectedProcessIndex] = useState(null);
    // const [origin, setOrigin] = useState("")
    console.log("batch name", college_name);
    const { origin, skuData, batchId, cardDetails, setCardDetails, navigateToResumesSettings, expandedCardDetailsDataGetter, navigateToStudentDetails } = props
    console.log({ origin });
    const [groupChat, setGroupChat] = useState(false);
    const [process_id, setProcess_id] = useState(null)
    const handleExpand = async (event, isAllDataExpanded, isExpanded, is1x1Expanded, notScheduledExpanded, index, process_id) => {
        event.stopPropagation();
        console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded, process_id);
        let tempExpandedCardDetails = await expandedCardDetailsDataGetter(process_id, index);
        const updatedCardDetails = cardDetails.map((element, cardIndex) => {
            if (cardIndex === index) {
                return {
                    ...element,
                    done_count: tempExpandedCardDetails.done_count,
                    feedback_count: tempExpandedCardDetails.feedback_count,
                    in_process_count: tempExpandedCardDetails.in_process_count,
                    initialized_count: tempExpandedCardDetails.initialized_count,
                    initialized_expired_bookings_count: tempExpandedCardDetails.initialized_expired_bookings_count,
                    mentor_absent_count: tempExpandedCardDetails.mentor_absent_count,
                    purchased_count: tempExpandedCardDetails.purchased_count,
                    scheduled_count: tempExpandedCardDetails.scheduled_count,
                    student_absent_count: tempExpandedCardDetails.student_absent_count
                };
            }
            return element;
        });

        setCardDetails(updatedCardDetails);
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                isAllDataExpanded: !isAllDataExpanded,
                isExpanded: !isExpanded
            };
            return updatedBundleInfo;
        });
        setExpandedCardDetails(tempExpandedCardDetails);
        console.log("details", process_id, tempExpandedCardDetails, updatedCardDetails);
    };

    const handleGroupChatOpen = (e, process_id) => {
        console.log("process id", process_id);
        e.stopPropagation()
        setProcess_id(process_id)
        setGroupChat(true);
    };

    const handleGroupChatClose = () => {
        setGroupChat(false);
    };
    const closeIs1x1Expanded = (event, is1x1Expanded, index) => {
        event.stopPropagation();
        // console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded);
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                is1x1Expanded: !is1x1Expanded,
            };
            return updatedBundleInfo;
        });
    }

    const closeNotScheduledExpanded = (event, notScheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                notScheduledExpanded: !notScheduledExpanded,
            };
            return updatedBundleInfo;
        });
    }

    const closeIsExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                isExpanded: !isExpanded,
                // is1x1Expanded: false,
                // notScheduledExpanded: false
            };
            return updatedBundleInfo;
        });
    }

    const closeRescheduledExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, rescheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                rescheduledExpanded: !rescheduledExpanded,
            };
            return updatedBundleInfo;
        });
    }
    useEffect(() => {
        const setting_data = () => {
            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setSelectSkuId()
                setFormInputs({ ...formInputs, process_id: null, start_date: null, end_date: null, recording: 1, timeZone: "Asia/Kolkata", is_offline: 0 })
            }
            else {
                const processData = cardDetails[selectedProcessIndex]
                setSelectSkuId(processData?.sku_id)
                setFormInputs({ ...formInputs, process_id: processData.id, start_date: extractDateFromDateString(processData?.start_date), end_date: extractDateFromDateString(processData?.end_date), recording: processData?.recording, is_offline: processData?.is_offline })
                console.log("what is inside", formInputs);

                setIs_toggled(processData?.recording)
            }
        }
        setting_data();
    }, [from_table])



    function getGmtOffsetInEpoch(timezone) {
        const now = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', { timeZone: timezone, hour: 'numeric', minute: 'numeric', second: 'numeric' });
        const parts = formatter.formatToParts();
        console.log("partsss", parts);
        const hours = parts.find(part => part.type === 'hour').value;
        const minutes = parts.find(part => part.type === 'minute').value;

        const offsetInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);

        const offsetInMilliseconds = 1 * offsetInMinutes * 60 * 1000;
        return offsetInMilliseconds;
    }

    function calculateEpochTimeAtMidnight(selectedDate, timezone) {
        console.log("selectedDate right now", selectedDate, timezone);
        const [year, month, day] = selectedDate.split('-').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

        const offsetInMilliseconds = getGmtOffsetInEpoch(timezone);
        console.log("offsetInMilliseconds", offsetInMilliseconds);
        const epochTime = date.getTime();
        console.log("get offset in epoch");
        return epochTime;
    }

    function calculateEpochTimeAfter24Hours(selectedDate, timezone) {
        console.log("selectedDate right now", selectedDate, timezone);
        const [year, month, day] = selectedDate.split('-').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day, 23, 59, 59));

        const offsetInMilliseconds = getGmtOffsetInEpoch(timezone);
        console.log("offsetInMilliseconds", offsetInMilliseconds);
        const epochTime = date.getTime()
        return epochTime;
    }

    // post request
    console.log("from_table", from_table);
    const handleFormSubmission = async (origin, batch_id) => {
        console.log("origin", origin);

        let temp_rows = [...rowsData];
        let temp_processData = JSON.parse(JSON.stringify(cardDetails));
        let data = {}

        if (formInputs.start_date !== null && formInputs.end_date !== null) {
            const result = calculateEpochTimeAtMidnight(formInputs?.start_date, formInputs?.timeZone);
            const resultAfetr24Hours = calculateEpochTimeAfter24Hours(formInputs?.end_date, formInputs?.timeZone);
            data = {
                user_group_id: batch_id,
                sku_id: parseInt(selectSkuId),
                start_date: result,
                end_date: resultAfetr24Hours,
                recording: formInputs.recording,
                is_offline: formInputs.is_offline
            }
        }
        else
            data = {
                user_group_id: batch_id,
                sku_id: parseInt(selectSkuId),
                recording: formInputs.recording,
                is_offline: formInputs.is_offline
            }


        console.log("User Type id not found, sending teh post request", temp_rows);
        await collegeData
            .post("/process", data)
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                console.log(res)
                console.log(res?.data)

                temp_processData = ([{
                    id: res?.data?.id,
                    user_group_id: batch_id,
                    sku_id: parseInt(selectSkuId),
                    sku_name: skuData.find(obj => obj.id === parseInt(selectSkuId)).name,
                    start_date: formInputs.start_date,
                    end_date: formInputs.end_date,
                    recording: formInputs.recording,
                    is_offline: formInputs.is_offline
                }, ...temp_processData])
                setCardDetails(temp_processData)
                // setOrigin("")
                console.log("batch data right now", temp_processData);
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message);
            });

        return temp_processData

    }

    const handlePutRequest = async (origin, id, batch_id, jd_program_id, selectSkuId) => {
        //get request here
        let temp_processData = JSON.parse(JSON.stringify(cardDetails));
        console.log("temp_processData", temp_processData);
        const result = calculateEpochTimeAtMidnight(formInputs?.start_date, formInputs?.timeZone);
        const resultAfetr24Hours = calculateEpochTimeAfter24Hours(formInputs?.end_date, formInputs?.timeZone);
        console.log('basically the input', formInputs?.timeZone, result, resultAfetr24Hours);
        try {
            const res = collegeData.put("/process", {
                id: id,
                start_date: result,
                end_date: resultAfetr24Hours,
                recording: formInputs.recording,
                is_offline: formInputs.is_offline
            })
            console.log("Response from backend is", JSON.stringify(res, null, 2));


            temp_processData[from_table?.index].user_group_id = batch_id
            temp_processData[from_table?.index].sku_id = selectSkuId
            temp_processData[from_table?.index].sku_name = skuData.find(obj => obj.id === parseInt(selectSkuId)).name
            temp_processData[from_table?.index].start_date = formInputs.start_date
            temp_processData[from_table?.index].end_date = formInputs.end_date
            temp_processData[from_table?.index].recording = formInputs.recording
            temp_processData[from_table?.index].is_offline = formInputs.is_offline
            console.log(temp_processData)
            setCardDetails(temp_processData)
        } catch (err) {
            console.log("Errog Msg: ", err);
            window.alert(err.response.data.message);
        };
        return temp_processData
    }

    const modalSubmit = async (origin) => {
        console.log("Just submitted:", origin, jd_input_name, jd_select_id, selectSkuId, jd_program_id, activity)
        // setOrigin(origin)
        let temp_jdData
        if (activity === "add")
            temp_jdData = await handleFormSubmission(origin, batchId, jd_program_id, selectSkuId);
        else {
            let temp_id = cardDetails[selectedProcessIndex].id
            temp_jdData = await handlePutRequest(origin, temp_id, batchId, jd_program_id, selectSkuId);
        }
        console.log({ temp_jdData })
        onHide()
    }
    console.log("origin", origin);
    const handleEditModal = (e, index, origin) => {
        console.log("what is the index", index, origin);
        // setOrigin(origin)
        setActivity("edit")
        e.preventDefault();
        e.stopPropagation();
        setSelectedProcessIndex(index)
        if (index !== null) {
            const processData = cardDetails[index]
            setSelectSkuId(processData?.sku_id)
            setFormInputs({ ...formInputs, process_id: processData.id, start_date: extractDateFromDateString(processData?.start_date), end_date: extractDateFromDateString(processData?.end_date), recording: processData?.recording, is_offline: processData?.is_offline })
            console.log("what is inside", formInputs, processData);

            setIs_toggled(processData?.recording)
        } else {
            setSelectSkuId()
            setFormInputs({ ...formInputs, process_id: null, start_date: null, end_date: null, recording: 1, timeZone: "Asia/Kolkata", is_offline: 0 })
        }
        // setSelectedCollegeIndex(index);
        setShow(true);
        setFrom_table({ ...from_table, index: typeof index === "number" ? index : undefined })
    };

    const onHide = (e) => {
        setJd_input_name()
        setSelectSkuId({ id: null, name: "" })
        setFormInputs({ start_date: null, end_date: null, process_id: null, is_offline: 0 })

        setShow(false)
        setSelectedProcessIndex(null)
    }


    const handleChange = (e, index) => {
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);

        if (e.target.name === "sku-select") {
            setSelectSkuId(e.target.value)
            console.log("batch selected id", e.target.value)
        }
        else if (e.target.name === "start_date") {
            setFormInputs({ ...formInputs, start_date: e.target.value })
            console.log("process start date", e.target.value)
        }
        else if (e.target.name === "end_date") {
            setFormInputs({ ...formInputs, end_date: e.target.value })
            console.log("process end date", e.target.value)
        } else if (e.target.name === "timezone_offset") {
            setFormInputs({ ...formInputs, timeZone: e.target.value })
            console.log("process end date", e.target.value)
        }

    }

    const handleToggle = () => {
        console.log("toggle state currently", is_toggled);
        const temp_toggle_state = !is_toggled
        setIs_toggled(temp_toggle_state)
        setFormInputs({ ...formInputs, recording: temp_toggle_state === true ? 1 : 0 })
    }

    const handleAddModal = (e, index, origin) => {
        console.log("origin in add", origin);
        // setOrigin(origin)
        if (index === null) {
            setActivity("add")
            setSelectSkuId()
            setFormInputs({ ...formInputs, start_date: null, end_date: null, recording: 1, timeZone: "Asia/Kolkata", process_id: null, is_offline: 0 })
        }
        setSelectedProcessIndex(index);
        setShow(true);

    };
    console.log("temp_processData", rowsData);

    return (
        <div>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "85%", alignItems: "center", margin: "auto 0" }}>
                <Typography style={{}} sx={{ fontSize: 20, fontWeight: "medium", marginTop: ".5rem" }}>{origin === "resumes" ? "Resumes Digital" : "Online 1x1"} </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                    <AddIcon onClick={(e) => handleAddModal(e, null, origin)} sx={{
                        height: '30px',
                        borderRadius: "100%",
                        width: '30px',
                        padding: '5px',
                        opacity: '50%', alignItems: "center", justifyContent: "center",
                        ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
                    }} />
                    {/* <Typography style={{}} sx={{ fontSize: 14, }}>Add Process</Typography> */}
                </Box>
            </Box>
            <div style={{ marginLeft: ".2rem", marginBottom: ".2rem", marginTop: "2rem" }} className="cardsInsideFlex">
                {cardDetails?.map((details, index) => {
                    return (
                        <>
                            <Box sx={{ width: 255, marginRight: '1rem', marginLeft: '.2rem', marginBottom: '.2rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: details.isAllDataExpanded ? "auto" : 240, cursor: "pointer" }}
                                onClick={origin === "resumes" ? null : details?.isAllDataExpanded === true ? () => { } : () => navigateToStudentDetails(details.id, details.sku_name, "All")}
                            >
                                <CardContent className="cardContainer" disableGutters style={{ width: index === 0 ? "255px" : '255px' }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <Typography variant="body2" sx={{ fontSize: 20, marginBottom: "1rem" }} gutterBottom>
                                            {details.sku_name.substring(0, 15).concat(details.sku_name.length > 15 ? "..." : "")}
                                        </Typography>
                                        <EditIcon
                                            onClick={(e) => handleEditModal(e, index, origin)}
                                            sx={{
                                                color: '#BFBFBF',
                                                height: '30px',
                                                borderRadius: "100%",
                                                width: '30px',
                                                padding: '5px',
                                                opacity: '50%',
                                                ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
                                            }}
                                        />
                                    </Box>
                                    <Typography variant="h5" component="div"></Typography>
                                    <div className="container">
                                        <Typography variant="body2" class='providingSpace1' >Start Date:</Typography>
                                        <Typography variant="body2" class='providingSpace'>{extractDateFromDateString(details.start_date)}</Typography>
                                    </div>
                                    <div className="container">
                                        <Typography variant="body2" class='providingSpace1'>End Date:</Typography>
                                        <Typography variant="body2" class='providingSpace'>{extractDateFromDateString(details.end_date)}</Typography>
                                    </div>
                                    <div className="container">
                                        <Typography variant="body2" class='providingSpace1'>Total Students:</Typography>
                                        <Typography variant="body2" class='providingSpace'>{details.total_student ? details.total_student : 0}</Typography>
                                    </div>
                                    {details.isAllDataExpanded && (
                                        <>
                                            <>
                                                <div className="containerHeadings">
                                                    <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Key Updates</Typography>
                                                    <button onClick={(e) => closeIsExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.isExpanded ? "Hide" : "Show"}</button>
                                                </div>
                                                {
                                                    details.isExpanded && (
                                                        <>

                                                            <div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Done")}>
                                                                    <Typography variant="body2" color={Color.primary1}>1x1 Done:</Typography>
                                                                    <Typography variant="body2" class="providingSpaceForSubDetails">{details.done_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Mentor Absent")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Mentor Absent:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.mentor_absent_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Scheduled")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Scheduled:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">{details.scheduled_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Not Scheduled")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Not Scheduled:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">{details.purchased_count + details.initialized_expired_bookings_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Mentor Awaited")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Mentor Awaited:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.initialized_count - details.initialized_expired_bookings_count}</Typography>
                                                                </div>

                                                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Ongoing")}>
                                                                    <Typography variant="body2" color={Color.primary1}>Ongoing:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.in_process_count}</Typography>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                                <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />
                                                <div className="containerHeadings">
                                                    <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>1x1 Done</Typography>
                                                    <button onClick={(e) => closeIs1x1Expanded(e, details.is1x1Expanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.is1x1Expanded ? "Hide" : "Show"}</button>
                                                </div>
                                                {
                                                    details.is1x1Expanded && (
                                                        <>
                                                            <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Awaited Mentor Feedback")}>
                                                                <Typography variant="body2" color={Color.primary1}>Awaited Mentor Feedback: </Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.feedback_count}</Typography>
                                                            </div>
                                                            <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Received Mentor Feedback")}>
                                                                <Typography variant="body2" color={Color.primary1}>Received Mentor Feedback:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.done_count - details.student_absent_count}</Typography>
                                                            </div>
                                                            <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Student Absent")}>
                                                                <Typography variant="body2" color={Color.primary1}>Student Absent:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.student_absent_count}</Typography>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                                <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />
                                                <div className="containerHeadings">
                                                    <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Not Scheduled</Typography>
                                                    <button onClick={(e) => closeNotScheduledExpanded(e, details.notScheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.notScheduledExpanded ? "Hide" : "Show"}</button>
                                                </div>
                                                {
                                                    details.notScheduledExpanded && (
                                                        <>
                                                            <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "No Action Students")}>
                                                                <Typography variant="body2" color={Color.primary1}>No Action Students: </Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.purchased_count}</Typography>
                                                            </div>
                                                            <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, "Expired Bookings")}>
                                                                <Typography variant="body2" color={Color.primary1}>Expired Bookings:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.initialized_expired_bookings_count}</Typography>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />
                                                <div className="containerHeadings">
                                                    <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Rescheduled</Typography>
                                                    <button onClick={(e) => closeRescheduledExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, details.rescheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.rescheduledExpanded ? "Hide" : "Show"}</button>
                                                </div>
                                                {details.rescheduledExpanded && (
                                                    <>
                                                        <div className="container">
                                                            <Typography variant="body2" color={Color.primary1}>Rescheduled by student: </Typography>
                                                            <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                        </div>
                                                        <div className="container">
                                                            <Typography variant="body2" color={Color.primary1}>Rescheduled by mentor:</Typography>
                                                            <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                        </div>
                                                        <div className="container">
                                                            <Typography variant="body2" color={Color.primary1}>Mentor’s Pending reschedule request:</Typography>
                                                            <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                        </div>
                                                        <div className="container">
                                                            <Typography variant="body2" color={Color.primary1}>Student’s Pending reschedule request:</Typography>
                                                            <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                        </div>
                                                        <div className="container">
                                                            <Typography variant="body2" color={Color.primary1}>Rejected Requests by student:</Typography>
                                                            <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                        </div>
                                                        <div className="container">
                                                            <Typography variant="body2" color={Color.primary1}>Rejected Requests by mentor:</Typography>
                                                            <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </>
                                    )}
                                    {details.isAllDataExpanded === false && (
                                        <CardActions sx={{ justifyContent: 'flex-end', bottom: 0, }}>
                                            <button
                                                className="submitBtn"
                                                type="button" d
                                                style={{ height: "44px", bottom: 0, }}
                                                onClick={(event) => handleExpand(event, details.isAllDataExpanded, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index, details.process_id)}
                                            >
                                                {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : "Load Details"}
                                            </button>
                                        </CardActions>
                                    )}
                                    {/* <CardActions sx={{ justifyContent: 'flex-end', bottom: 0, }}>
                                            <button
                                                className="submitBtn"
                                                type="button"
                                                style={{ height: "44px", bottom: 0, background: Color.white, border: `1px solid ${Color.primary1}`, color: Color.primary1 }}
                                                onClick={(e) => handleGroupChatOpen(e, details.process_id)}
                                            >
                                                {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : <Typography><TextsmsOutlinedIcon sx={{ color: Color.primary1 }} /> Mentor Chat Room </Typography>}
                                            </button>
                                        </CardActions> */}
                                </CardContent>

                            </Box>

                        </>
                    )
                })}
                <Modal
                    show={show}
                    onHide={(e) => onHide(e)}
                    className="FileUploadModal"
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={{ backdropFilter: "blur(5px)", minWidth: "80%", maxWidth: "95%", minHeight: "80%", maxHeight: "95%" }}
                >
                    <Modal.Body style={{
                        position: 'absolute',
                        left: '60%',
                        height: 'auto',
                        transform: 'translate(-50%, -50%)',
                        lineHeight: '1.4',
                        padding: '14px 28px',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        maxWidth: '95%',
                        minWidth: '500px',
                        minHeight: '400px'
                    }}>
                        <form className="Entire-form" style={{ justifyContent: 'center' }}>
                            <div className='row full-div' >
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                    <p className="compTitle" style={{ fontSize: "20px", textAlign: "center", background: 'transparent' }}>{college_name}/{batchName}</p>
                                </div>

                                <div className="form-group" style={{ display: 'flex' }}>
                                    <label className="inputLabel" style={{ textAlign: "center", marginTop: "0.5rem", marginRight: "0.5rem" }}>Recording of the sessions</label>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={is_toggled}
                                                onChange={handleToggle}
                                                name="toggleSwitch"
                                            />
                                        }
                                    />
                                </div>
                                <Box className="form-group " disabled={activity !== "add"} sx={{ display: "flex", gap: "1rem", alignItems: 'center' }}>
                                    <label className="inputLabel">SKU<span>*</span></label>
                                    <select disabled={activity !== "add"} name="sku-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={selectSkuId}>
                                        <option className="options-value" value={""} selected>Select SKU</option>
                                        {origin === "resumes" ?
                                            skuData
                                                .filter(item => item.name === "Resumes.Digital 1 year")
                                                .map((row, i) => (
                                                    <option key={i} value={row.id}>
                                                        {row.name}
                                                    </option>
                                                )) :
                                            skuData?.map((row, i) => (
                                                <option key={i} value={row.id}>
                                                    {row.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </Box>
                                <div style={{ display: "flex", gap: "1rem", alignItems: 'center', width: "100%" }}>
                                    <div className="form-group" style={{ width: "50%", display: "flex", gap: "1rem", alignItems: 'center' }}>
                                        <label className="inputLabel">Start Date</label>
                                        <input name="start_date" type="date" className="form-control" placeholder="Example input" value={formInputs.start_date} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <div className="form-group" style={{ width: "50%", display: "flex", gap: "1rem", alignItems: 'center' }}>
                                        <label className="inputLabel">End Date</label>
                                        <input name="end_date" type="date" className="form-control" placeholder="Example input" value={formInputs.end_date} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="form-group" style={{ width: "100%", display: "flex", gap: "1rem", alignItems: 'center' }}>
                                    <label className="inputLabel">Timezone</label>
                                    <select name="timezone_offset" id="timezone-offset" className="form-control" onChange={(e) => handleChange(e)}>
                                        <option value="America/Los_Angeles">America/Los_Angeles</option>
                                        <option value="America/Chicago">America/Chicago </option>
                                        <option value="America/Denver">America/Denver</option>
                                        <option value="Europe/Paris">Europe/Paris</option>
                                        <option value="America/Sao_Paulo">America/Sao_Paulo</option>
                                        <option value="America/New_York">America/New_York</option>
                                        <option value="America/Anchorage">America/Anchorage</option>
                                        <option value="America/Phoenix">America/Phoenix</option>
                                        <option value="Asia/Shanghai">Asia/Shanghai</option>
                                        <option value="Asia/Dubai">Asia/Dubai</option>
                                        <option value="Asia/Kolkata" selected="selected">Asia/Kolkata</option>
                                        <option value="Asia/Singapore">Asia/Singapore</option>
                                        <option value="Asia/Tokyo">Asia/Tokyo </option>
                                        <option value="Asia/Tehran">Asia/Tehran</option>
                                        <option value="Australia/Melbourne">Australia/Melbourne</option>
                                        <option value="Pacific/Honolulu">Pacific/Honolulu</option>
                                        <option value="Africa/Johannesburg">Africa/Johannesburg</option>
                                        <option value="Europe/Istanbul">Europe/Istanbul</option>
                                        <option value="Europe/Lisbon">Europe/Lisbon </option>
                                        <option value="Europe/Berlin">Europe/Berlin</option>
                                    </select>
                                </div>

                                {activity !== "add" &&
                                    <div>
                                        <LazyLoadingList process_id={formInputs.process_id} roleTypess={props.roleTypes} />
                                    </div>
                                }
                                <div className="form-group ">
                                    <div className="btn-comp" style={{ marginLeft: "", marginTop: "4%" }}>
                                        <button type="button" onClick={() => modalSubmit(origin)} className="btn submit-btn">Submit</button>
                                    </div>
                                </div>

                            </div>


                        </form>
                    </Modal.Body>
                </Modal>
            </div>
            {groupChat && <GroupChatModal modalOpen={groupChat} handleModalClose={handleGroupChatClose} process_id={process_id} />}
        </div>
    )
}

export default ProcessCard
