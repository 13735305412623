import { Box, Button, Checkbox, Typography } from '@mui/material'
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import CCService from '../../../services/httpServiceWithoutSuperAdmin'
import { Color } from '../../../GlobalStyles'

function AccessRightsModal({allAccessRights, selectedLevel,setAllAccessRights, close, levels, setLevels, college_id}) {
    const services = new CCService()
    const handleChecked = (id) =>{
        setAllAccessRights((prev) =>{
        console.log("incoming id", id, prev)

            return prev.map((item) =>{
               if(item.access_right_id === id){
                return {
                    ...item,
                    checked: !item.checked
                }

                }else return item
            })
        })
    }

    const handleSave = async() =>{  
     
 
         if(selectedLevel !== null){
            try {
                let body = {
                    data: allAccessRights.map((item) =>{
                        return {
                            level_id:selectedLevel.id,
                            access_right_id: item.access_right_id,
                            is_activated: item.checked ? 1:0
                        }
                    })
                }
               await services.put(`/po/acl/accessLvlMap`, body)
                close()
            } catch (error) {
                toast.error(`Error: ${error.response.data.message}`)
            }
         }else{
            try {
                const body = {
                    "level_name": `Level ${levels.length + 1}`, 
                    "college_id": college_id, 
                    "access": allAccessRights.map((item) =>{
                        return {
                            access_right_id: item.access_right_id,
                            is_activated: item.checked ? 1:0
                        }
                    })
                }
            const data=    await services.post(`/po/acl/accessRight`, body)

setLevels((prev) => [...prev, { "id": data.data.id,
    "level_name": `Level ${levels.length + 1}`,
    "college_id": null,
    "college_name": null
}])
               

close()
            } catch (error) {
                window.alert(error.response.data.message)
            }
         }
       
       
    }
  return (
    <Box sx={{background:"#fff", height:"99vh", width:"min(800px ,40vw)", borderRadius:"8px 0px 0px 8px", padding:"16px", overflow:"auto" }} >
        <ToastContainer />
       <Box sx={{}}>
        <Box >
            <Typography sx={{fontSize:"20px"}}>{selectedLevel?.level_name || "New Level"}</Typography>
            <hr />
        </Box>
       {allAccessRights.map((item) => {
   return <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <Typography>{item.access_right_name}</Typography>
        <Checkbox checked={item.checked} onChange={()=>handleChecked(item.access_right_id
)} />
    </Box>
})}
       </Box>
     <Box sx={{display:"flex", gap:"16px"}}>

     <Button sx={{border:`1px solid ${Color.primary1}`, padding:"4px 12px", textTransform:"none",color:Color.primary1, borderRadius:"12px" }} onClick={()=>close()}>Cancel</Button>
        
       <Button sx={{border:`1px solid ${Color.primary1}`, padding:"4px 12px", textTransform:"none",background:Color.primary1, borderRadius:"12px", color:"#fff",'&:hover':{background:Color.primary1,  color:"#fff"} }}     onClick={()=>handleSave()}>Save</Button>
     </Box>
    </Box>
  )
}

export default AccessRightsModal
