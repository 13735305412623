import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Tab, Tabs } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Service from "../../services/httpService";
import "../ResumeSettings/Settings.css"; // Import stylesheet
import colors from "./Colors.json"; // Import colors for ribbon
import FontFamilyJson from "./FontFamily.json"; // Import FontFamily with their Sizes
import FormatLocking1 from "./FormatLocking.json";
import MiniatureResume from "./MiniatureResume1";
import resumeSections1 from "./SectionTemplate.json";

function QuickSettings(props) {
  const service = new Service();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [baseTemplate, setBaseTemplate] = useState("");
  const [selectedValue, setSelectedValue] = useState(useState({ sectionTemplate_id: "", existingBaseTemplate: "", section_template: "" }));
  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);

  const { process_id, sectionWisedata, FontSize, setSectionDetails, setFontSize, FontS, setFontS, Bullet, setBullet, TableWidth, setTableWidth, LineHeight, setLineHeight, ribbonColor, setRibbonColor, fontFamilys, setFontFamilys, sectionDetails, template_name, userData, profileSummaryBullets, profileSummarySubSection, Academicdetail, projectData, sipData, certficationData, extracurricularData, leadershipData, workExData, image, picturePosition, logoPosition, headerImages, showPicture, showAdress, academicAchievementData, file, academicsData, showLogo, headerPosition, setheaderPosition } = props;
  const [sectionEntireData, setSectionEntireData] = useState();

  console.log({ TableWidth });
  console.log(FontSize);
  const [resumeSections, setResumeSections] = useState([]);

  // Handle changes to the ribbon color
  const [FormatLocking, setFormatLocking] = useState(FormatLocking1);
  console.log("FormatLocking", FormatLocking);
  const cookies = Cookies.get("token");

  // handling lock
  const [isRibbonColorLocked, setIsRibbonColorLocked] = useState(false);
  const [isPageMarginLocked, setIsPageMarginLocked] = useState(false);
  const [isBulletStyleLocked, setIsBulletStyleLocked] = useState(false);
  const [isLineSpacingLocked, setIsLineSpacingLocked] = useState(false);
  const [isFontSizeLocked, setIsFontSizeLocked] = useState(false);
  const [isHeaderPositionLocked, setIsLeaderPositionLocked] = useState(false);
  const [isFontStyleLocked, setIsFontStyleLocked] = useState(false);

  const [isTemplateLocked, setIsTemplateLocked] = useState(sectionDetails.map((item) => item.is_template_locked));

  const [templateChoosenLock, setTemplateChoosenLock] = useState();
  console.log("data", isTemplateLocked);
  const [selectedSectionTemplate, setSelectedSectionTemplate] = useState([{ section_id: null, section_template_name: "Free Flowing" }]);
  useEffect(() => {
    const handelEffect = async () => {
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      try {
        const templateGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping?process_id=${process_id}`, { headers });

        const formattedGetter = JSON.parse(templateGet.data.data[0].formatting_params);
        setFormatLocking(formattedGetter);
        // section details getter
        const sectionEntireDetailsGetter = JSON.parse(templateGet.data.data[0].section_params);
        setSectionEntireData(sectionEntireDetailsGetter);
        const sectionDetailsGetter = sectionEntireDetailsGetter.section;
        setSectionDetails(sectionDetailsGetter);
        setBaseTemplate(templateGet.data.data[0].template_name);

        setIsFontStyleLocked(formattedGetter.font_family.is_locked);
        setIsLeaderPositionLocked(formattedGetter.header_position.is_locked);
        setIsFontSizeLocked(formattedGetter.font_size.is_locked);
        setIsLineSpacingLocked(formattedGetter.line_height.is_locked);
        setIsPageMarginLocked(formattedGetter.page_width.is_locked);
        setIsBulletStyleLocked(formattedGetter.bullet_style.is_locked);

        setIsRibbonColorLocked(formattedGetter.ribbon_color.is_locked);

        setRibbonColor(formattedGetter.ribbon_color.value);
        setTableWidth(formattedGetter.page_width.value);

        let temp = sectionEntireDetailsGetter.section.map((item) => {
          let options = resumeSections1.sectionsDropDown.find((section) => section.id === item.section_type_id);
          return {
            name: item.section_name,
            id: item.section_type_id,
            "Free Flowing": options["Free Flowing"],
            Tabular: options["Tabular"],
            value: item.value,
            is_template_locked: item.is_template_locked,
          };
        });
        console.log("sdfljsdljflj", sectionEntireDetailsGetter);
        setResumeSections(temp);

        const lockedStatusArray = sectionDetailsGetter.map((item) => item.is_template_locked);

        setIsTemplateLocked(lockedStatusArray);
      } catch (error) {
        // setLoader(false);
        console.log(error);
        window.alert(error);
      }
    };

    handelEffect();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleRibbonChange = (e) => {
    setRibbonColor(e.target.value);
    const updatedFormatedLocking = {
      ...FormatLocking,
      ribbon_color: {
        ...FormatLocking.ribbon_color,
        value: e.target.value,
      },
    };
    setFormatLocking(updatedFormatedLocking);
  };

  const handleRibbonChangeLock = (e) => {
    const updateLockState = !isRibbonColorLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      ribbon_color: {
        ...FormatLocking.ribbon_color,
        is_locked: updateLockState,
      },
    };
    setIsRibbonColorLocked(!isRibbonColorLocked);
    setFormatLocking(updatedFormatedLocking);
  };

  // Handle changes to the font family with Json
  const handleFontFace = (e) => {
    setFontS(e.target.value);
    const updatedFormatedLocking = {
      ...FormatLocking,
      font_family: {
        ...FormatLocking.font_family,
        value: e.target.value,
      },
    };
    setFormatLocking(updatedFormatedLocking);
  };

  const handleFontStyleLock = (e) => {
    const updateLockState = !isFontStyleLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      font_family: {
        ...FormatLocking.font_family,
        is_locked: updateLockState,
      },
    };
    setIsFontStyleLocked(!isFontStyleLocked);
    setFormatLocking(updatedFormatedLocking);
  };

  const handleHeaderPosition = (event) => {
    let Position = event.target.value;
    setheaderPosition(Position);
    const updatedFormatedLocking = {
      ...FormatLocking,
      header_position: {
        ...FormatLocking.header_position,
        value: Position,
      },
    };
    setFormatLocking(updatedFormatedLocking);
  };

  const handleHeaderPositionLock = (e) => {
    const updateLockState = !isHeaderPositionLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      header_position: {
        ...FormatLocking.header_position,
        is_locked: updateLockState,
      },
    };
    setIsLeaderPositionLocked(!isHeaderPositionLocked);
    setFormatLocking(updatedFormatedLocking);
  };

  const handleTableWidth = (e) => {
    console.log("TableWidth", e.target.value);
    setTableWidth(!TableWidth);
    // setIsPageMarginLocked(e.target.value === "true" ? true : false);
    const updatedFormatedLocking = {
      ...FormatLocking,
      page_width: {
        ...FormatLocking.page_width,
        value: !TableWidth,
      },
    };
    setFormatLocking(updatedFormatedLocking);
  };

  const handlePageMarginLock = (e) => {
    const updateLockState = !isPageMarginLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      page_width: {
        ...FormatLocking.page_width,
        is_locked: updateLockState,
      },
    };
    setIsPageMarginLocked(!isPageMarginLocked);
    setFormatLocking(updatedFormatedLocking);
  };

  const handleFontSize = (e) => {
    setFontSize(e.target.value);
    const updatedFormatedLocking = {
      ...FormatLocking,
      font_size: {
        ...FormatLocking.font_size,
        value: e.target.value,
      },
    };
    setFormatLocking(updatedFormatedLocking);
  };

  const handleFontSizeLock = (e) => {
    const updateLockState = !isFontSizeLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      font_size: {
        ...FormatLocking.font_size,
        is_locked: updateLockState,
      },
    };
    setIsFontSizeLocked(!isFontSizeLocked);
    setFormatLocking(updatedFormatedLocking);
  };

  // Handle changes to the bullet style
  const handleBullet = () => {
    setBullet(!Bullet);
    const updatedFormatedLocking = {
      ...FormatLocking,
      bullet_style: {
        ...FormatLocking.bullet_style,
        value: !Bullet,
      },
    };
    setFormatLocking(updatedFormatedLocking);
    console.log("FormatLocking", updatedFormatedLocking);
  };

  const handleBulletStyleLock = (e) => {
    const updateLockState = !isBulletStyleLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      bullet_style: {
        ...FormatLocking.bullet_style,
        is_locked: updateLockState,
      },
    };
    setIsBulletStyleLocked(!isBulletStyleLocked);
    setFormatLocking(updatedFormatedLocking);
    console.log("FormatLocking", updatedFormatedLocking);
    console.log("updatedFormatedLocking", updatedFormatedLocking);
  };

  // Handle changes to the line height
  const handleLineHeight = (e) => {
    setLineHeight(e.target.value);
    const updatedFormatedLocking = {
      ...FormatLocking,
      line_height: {
        ...FormatLocking.line_height,
        value: e.target.value,
      },
    };
    setFormatLocking(updatedFormatedLocking);
  };

  const handleLineSpacingLock = (e) => {
    const updateLockState = !isLineSpacingLocked;
    const updatedFormatedLocking = {
      ...FormatLocking,
      line_height: {
        ...FormatLocking.line_height,
        is_locked: updateLockState,
      },
    };
    setIsLineSpacingLocked(!isLineSpacingLocked);
    setFormatLocking(updatedFormatedLocking);
    console.log("FormatLocking", updatedFormatedLocking);
    console.log("updatedFormatedLocking", updatedFormatedLocking);
  };

  console.log("FormatLocking", FormatLocking);

  const handlePutRequest = async () => {
    console.log("");
    service
      .put(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping`, {
        process_id: process_id,
        formatting_params: FormatLocking,
        verification_params: props.lockVerification,
      })
      .then((res) => {
        res.data.success ? setModalConfirmationShow(true) : console.log("Failed Operation");
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message);
      });
  };

  const handleSectionPutRequest = async () => {
    console.log("");
    service
      .put(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping`, {
        process_id: process_id,
        section_params: sectionEntireData,
        verification_params: props.lockVerification,
      })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        if (res.data.success) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setModalConfirmationShow(true);
        }
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message);
      });
  };

  let className = "ResumeContent-Resume";

  const handleSectionTemplateChange = (e, id, baseTemplate, item, index) => {
    const section_template = e.target.value;
    console.log("TEMPLATE CHANGE", selectedSectionTemplate, sectionDetails);
    setSelectedSectionTemplate((prevState) => {
      let updated = false;

      const updatedState = prevState.map((item) => {
        if (item.section_id === id && index === item.index) {
          updated = true;
          return { ...item, section_template_name: section_template };
        }
        return item;
      });

      if (!updated) {
        updatedState.push({ section_id: id, section_template_name: section_template, index: index });
      }

      return updatedState;
    });
    const temp_section = sectionDetails.map((sectionDetail, i) => (sectionDetail.section_type_id === id && index === i ? { ...sectionDetail, value: section_template } : { ...sectionDetail }));
    setSectionDetails(temp_section);
    const updateEditState = {
      ...sectionEntireData,
      section: temp_section,
    };
    setSectionEntireData(updateEditState);
    setSelectedValue({ id, baseTemplate, section_template });
  };

  const handleSectionTemplateLock = (e, id, baseTemplate) => {
    console.log("current data", id, baseTemplate);
    const updatedResumeSections = resumeSections.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_template_locked: !item.is_template_locked,
        };
      }
      return item;
    });

    setResumeSections(updatedResumeSections);
    const updatedSectionDetails = sectionDetails.map((sectionDetail) => {
      if (sectionDetail.section_type_id === id) {
        return {
          ...sectionDetail,
          is_template_locked: !sectionDetail.is_template_locked,
          value: sectionDetail.value,
        };
      }
      console.log("section type id and id", sectionDetail.section_type_id, id);
      return sectionDetail;
    });

    setSectionDetails(updatedSectionDetails);
    const updateEditState = {
      ...sectionEntireData,
      section: updatedSectionDetails,
    };
    setSectionEntireData(updateEditState);
    // const updatedTemplateLockedStatus = updatedSectionDetails.map(
    //   (sectionDetail) => sectionDetail.is_template_locked
    // );
    // setIsTemplateLocked(updatedTemplateLockedStatus);
  };

  console.log("is Locked", isTemplateLocked);
  // handle save modal
  function HandleEditUser(props) {
    return (
      <Modal className="myprofile_modal" {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered style={{ backdropFilter: "blur(5px)" }}>
        <Modal.Body
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            height: "180px",
            transform: "translate(-50%, -50%)",
            lineHeight: "1.4",
            padding: "14px 28px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            maxWidth: "500px",
            minWidth: "400px",
            minHeight: "auto",
            border: "2px solid white",
          }}
        >
          <div className="row" style={{ margin: "auto", top: "20%" }}>
            <div className="headerPart">
              {/* <CloseIcon className="closeIcon" onClick={((e) => { setModalConfirmationShow(false) })} /> */}
              <div style={{ display: "flex", marginTop: "30px", margin: "0 auto" }}>
                <CheckCircleOutlineIcon style={{ height: "40px", width: "40px", color: "#561D5E", marginRight: "1rem" }} />
                <p className="DeleteConfirm1" style={{ fontSize: "24px", fontWeight: "normal", marginTop: ".5rem" }}>
                  Data has been Saved
                </p>
              </div>
            </div>

            <div className="form-group btns">
              <button
                type="button"
                className="Rephrasebutton"
                onClick={() => {
                  setModalConfirmationShow(false);
                }}
              >
                Edit again
              </button>
              <button type="button" className="RephrasebuttonNoBackground" onClick={() => setModalConfirmationShow(false)}>
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <div className="Resume-Settings-Content" style={{ display: "flex", position: "absolute", width: "" }}>
      <div className="QuickSettings-SettingBox" style={{ float: "left" }}>
        <React.Fragment>
          <Tabs value={currentTabIndex} onChange={handleTabChange} style={{ marginTop: "1rem", width: "100%" }}>
            <Tab style={{ fontSize: "13px", padding: "12px 13px" }} label="Resume Formatting" />
            <Tab style={{ fontSize: "13px", padding: "12px 13px" }} label="Section-Wise Template" />
          </Tabs>
          {currentTabIndex === 0 && (
            <>
              <div>
                {/* <div className='QuickSettings-SettingBox1' style={{ height: 'auto', paddingBottom: "0px", padding: '0px', marginTop: '.8rem' }}>
                  <tr >
                    <td className='ResumeDropDownHeading2'>
                      <label className='QuickSettings-label1'>
                        Select Base Template
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className='ResumeDropDown' colSpan={4}>
                      <select className='Resume-Setting-Small-DropDown1'  onChange={(e) => { handleBaseTemplate(e) }} value={baseTemplate}>
                        <option value={"Free Flowing"}>Free Flowing</option>
                        <option value={"Tabular"}>Tabular</option>
                      </select>
                    </td>
                    <td className='lockMechanism' style={{ marginLeft: '1rem' }}>
                  <button onClick={() => handleBaseTemplateLock()} style={{ border: 'none', backgroundColor: "transparent" }}>
                  {isbaseTemplateLocked === false ? <LockOpenIcon/> : <LockOutlinedIcon/>}
                    </button>
                    </td>
                  </tr>
                </div> */}
                {/* <p style={{ textAlign: "left", marginTop: '.8rem', marginBottom: ".5rem",color: "#561D5E" }}>Formatting</p> */}
                <tr style={{}}>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label" style={{ marginLeft: "0px" }}>
                      Ribbon Color
                    </label>
                  </td>
                  <td className="ResumeDropDown">
                    <select className="Resume-Setting-Small-DropDown" value={ribbonColor} onChange={handleRibbonChange}>
                      {Object.keys(colors).map((Ribbons) => (
                        <option className="" key={Ribbons} value={Ribbons}>
                          {Ribbons}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handleRibbonChangeLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isRibbonColorLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label">Page Margin</label>
                  </td>
                  <td className="ResumeDropDown">
                    <select
                      className="Resume-Setting-Small-DropDown"
                      onChange={(e) => {
                        handleTableWidth(e);
                      }}
                      value={TableWidth}
                    >
                      <option value={false}>Narrow</option>
                      <option value={true}>Normal</option>
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handlePageMarginLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isPageMarginLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label">Bullet style</label>
                  </td>
                  <td className="ResumeDropDown">
                    <select className="Resume-Setting-Small-DropDown" onChange={handleBullet} value={Bullet}>
                      <option value={false}>Rounded</option>
                      <option value={true}>Square</option>
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handleBulletStyleLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isBulletStyleLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label">Line Spacing</label>
                  </td>
                  <td className="ResumeDropDown">
                    <select className="Resume-Setting-Small-DropDown" onChange={handleLineHeight} value={LineHeight}>
                      <option value="Narrow">Narrow</option>
                      <option value="Normal">Normal</option>
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handleLineSpacingLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isLineSpacingLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label">Font Size</label>
                  </td>
                  <td className="ResumeDropDown">
                    <select className="Resume-Setting-Small-DropDown" onChange={handleFontSize} value={FontSize}>
                      <option value="medium">Medium</option>
                      <option value="small">Small</option>
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handleFontSizeLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isFontSizeLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label" for="fontFamily">
                      Font Style
                    </label>
                  </td>
                  <td>
                    <select className="Resume-Setting-Small-DropDown" onChange={handleFontFace} value={FontS}>
                      {Object.keys(FontFamilyJson).map((fontFace, index) => (
                        <option key={fontFace} value={fontFace}>
                          {fontFace}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handleFontStyleLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isFontStyleLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="ResumeDropDownHeading">
                    <label className="QuickSettings-label" for="fontFamily">
                      Heading Position
                    </label>
                  </td>
                  <td>
                    <select className="Resume-Setting-Small-DropDown" onChange={handleHeaderPosition} value={headerPosition}>
                      <option value="left">Left</option>
                      <option value="center">Center</option>
                    </select>
                  </td>
                  <td className="lockMechanism" style={{ paddingLeft: "1rem" }}>
                    <button onClick={() => handleHeaderPositionLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                      {isHeaderPositionLocked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                    </button>
                  </td>
                </tr>
              </div>
              <tr>
                <button
                  onClick={() => {
                    handlePutRequest();
                  }}
                  style={{ marginTop: "2rem" }}
                  className="saveButtonResumesDigital1"
                >
                  Save
                </button>
              </tr>
            </>
          )}

          {currentTabIndex === 1 && (
            <>
              <div className="QuickSettings-SettingBox1">
                {resumeSections?.map((item, index) => (
                  <>
                    <tr key={item.id} style={{}}>
                      <td className="ResumeDropDownHeading2">
                        <label className="QuickSettings-label1" style={{ marginLeft: "0px", marginTop: "0.8rem" }}>
                          {item.name}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="ResumeDropDown">
                        <select className="Resume-Setting-Small-DropDown1" onChange={(e) => handleSectionTemplateChange(e, item.id, baseTemplate, item, index)} value={sectionDetails.find((section_value, i) => section_value.section_type_id === item.id && index === i)?.value || ""}>
                          {baseTemplate === "Free Flowing" ? (
                            item["Free Flowing"]?.map((template) => (
                              <option key={template.section_template} value={template.section_template}>
                                {template.section_template}
                              </option>
                            ))
                          ) : baseTemplate === "Tabular" ? (
                            item["Tabular"]?.map((template) => (
                              <option key={template.section_template} value={template.section_template}>
                                {template.section_template}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </select>
                      </td>

                      <td className="lockMechanism" style={{ position: "absolute", marginTop: "1.5rem", marginLeft: "0px" }}>
                        <button onClick={(e) => handleSectionTemplateLock(e, item.id, baseTemplate)} style={{ border: "none", backgroundColor: "transparent" }}>
                          {item.is_template_locked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
                <tr>
                  <button
                    onClick={() => {
                      handleSectionPutRequest();
                    }}
                    style={{ marginTop: "2rem" }}
                    className="saveButtonResumesDigital1"
                  >
                    Save
                  </button>
                </tr>
              </div>
            </>
          )}
        </React.Fragment>
      </div>
      <div className="ResumeContent-AdjustableResume">
        <div className={className} style={{ marginTop: "0", marginRight: "3.5%", marginLeft: "0%", boxShadow: "0 0px 25px rgba(92, 99, 105, .2", width: TableWidth ? "21cm" : "21cm" }}>
          {console.log("sectionDetails", sectionDetails, sectionWisedata)}
          <div className="page" style={{ padding: TableWidth ? "2.54cm" : "1.27cm", fontSize: FontSize }}>
            {sectionDetails.length > 1 ? sectionDetails.map((item, index) => <MiniatureResume selectedValue={selectedValue} count={index} section_detail={item} data={sectionWisedata[index]} sectionDetails={sectionDetails} setSectionDetails={setSectionDetails} template_name={template_name} userData={userData} profileSummaryBullets={profileSummaryBullets} profileSummarySubSection={profileSummarySubSection} Academicdetail={Academicdetail} projectData={projectData} sipData={sipData} certficationData={certficationData} extracurricularData={extracurricularData} leadershipData={leadershipData} workExData={workExData} image={image} picturePosition={picturePosition} logoPosition={logoPosition} headerImages={headerImages} showPicture={showPicture} showAdress={showAdress} showLogo={showLogo} academicAchievementData={academicAchievementData} file={file} fontS={FontS} ribbonColor={colors[ribbonColor]} FontSize={FontSize} Bullet={Bullet} TableWidth={TableWidth} LineHeight={LineHeight} headerPosition={headerPosition} baseTemplate={baseTemplate} />) : <></>}
          </div>
        </div>
        <HandleEditUser
          show={modalConfirmationShow}
          onHide={() => {
            setModalConfirmationShow(false);
          }}
        />
      </div>
    </div>
  );
}

export default QuickSettings;
