import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CollegeData from '../../../CollegeDataServices/httpCollege';
import ServiceCM from '../../../services/cartmoduleServices';
import Service from '../../../services/httpService';
import Colleges from '../../Dashboard/Colleges/Colleges';
import DashboardOngoingEvents from '../../Dashboard/DashboardOngoingEvents/DashboardOngoingEvents';
import MentorAwaited from '../../Dashboard/MentorAwaited/MentorAwaited';
import MentorStatus from '../../Dashboard/MentorStatus/MentorStatus';
import '../../Dashboard/OngoingEvents/OngoingEvents.css';
import OnGoingGroupSessions from '../../Dashboard/OnGoingGroupSessions/OnGoingGroupSessions';
import Loader from '../../reusables/Loader/Loader';
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import TopBar from '../../reusables/TopBar/Topbar';
import AnomaliesDashboard from './AnomaliesDashboard/AnomaliesDashboard';
import { Box } from '@mui/material';
import { Color } from '../../../GlobalStyles';
function Dashboard() {
    const [loading, setLoading] = useState(true);
    const cartModule = new ServiceCM()
    const service = new Service()
    const collegeService = new CollegeData()
    const [role_details, setRole_details] = useState([])
    const [specialization_details, setSpecialization_details] = useState([])
    const navigate = useNavigate();

    const [entireGroupSessionData, setEntireGroupSessionData] = useState([])
    const [activityTypeData, setActivityTypeData] = useState([])
    const [roleTypeData, setRoleTypeData] = useState([])
    const [durationData, setDurationData] = useState([])
    const [mentorData, setMentorData] = useState([])
    const [collegeData, setCollegeData] = useState([])
    const [batchData, setBatchData] = useState([])
    const [activity_name, setActivity_name] = useState()
    const [college_name1, setCollege_name1] = useState()
    const [duration_name, setDuration_name] = useState()
    const [mentor_name, setMentor_name] = useState()
    const [batch_name, setBatch_name] = useState()
    const [role_name, setRole_name] = useState()

    const activityTypeGetter = async () => {
        try {
            const res = await cartModule.get('/sku?type=Group')
            console.log("Response from backend for activity name:", res.data)
            setActivityTypeData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log("Response activity type data", activityTypeData);
    }

    const DurationGetter = async () => {
        try {
            const res = await service.get('/duration')
            console.log("Response from backend for duration:", res.durations)

            // setDurationData(getTimeFormated(res.durations))
            return res.durations
        } catch (err) {
            console.log(err)
        }
        console.log("Response duration type data", durationData);
    }

    const CollegeGetter = async () => {
        try {
            const res = await collegeService.get('/college')
            console.log("Response from backend for college:", res.data)
            setCollegeData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log(" Response college type data", collegeData);
    }

    const mentorGetter = async () => {
        try {
            const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
            console.log("Response from backend for mentor:", res.data)
            setMentorData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log("Response mentor type data", mentorData);
    }

    const batchGetter = async () => {
        try {
            const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/collegeModule/userGroup`)
            console.log("Response from backend for batch:", res.data)
            setBatchData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log("Response bacth type data", batchData);
    }

    const roleTypeGetter = async () => {
        try {
            const res = await service.get('/role?is_admin=1')
            console.log("roleTypeGetter", res.data)
            setRoleTypeData([...res.data])
            return res.data
        }
        catch (err) {
            console.log(err)
        }
    }

    const data_manupulator_activity = (activityType) => {
        console.log("activity type", activityType);
        // let temp = activityType;
        let temp = activityType.filter(act => act.type === "Group");
        temp = temp.map(bat => {
            bat.key = bat.act_type_name;
            bat.id = bat.id;
            bat.type = bat.type
            return bat;
        });
        console.log("temp rows activity:", temp)
        setActivity_name(temp)
        console.log("activity group names", activity_name);
    }
    const data_manupulator_duration = (duration) => {
        console.log("duration name", duration);
        let temp = duration;
        temp = temp?.map(bat => {
            bat.key = bat.duration_name;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setDuration_name(temp)
    }

    const data_manupulator_mentor = (mentor) => {
        console.log("mentor type", mentor);
        let temp = mentor;
        temp = temp?.map(bat => {
            bat.key = bat.first_name;
            bat.id = bat.user_id;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setMentor_name(temp)
    }

    const data_manupulator_college = (college) => {
        console.log("college type", college);
        let temp = college;
        temp = temp?.map(bat => {
            bat.key = bat.college_name;
            bat.id = bat.id;
            return bat;
        });
        setCollege_name1(temp)
        console.log("temp rows batch:", temp)
    }

    const data_manupulator_batch = (batch) => {
        console.log("bactyh type", batch);
        let temp = batch;
        temp = temp?.map(bat => {
            bat.key = bat.user_group_name;
            bat.id = bat.id;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setBatch_name(temp)
    }

    const data_manupulator_role = (role) => {
        console.log("role type", role);
        let temp = role;
        temp = temp?.map(bat => {
            bat.key = bat.user_group_name;
            bat.id = bat.id;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setRole_name(temp)
    }

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }

            //batch data getter
            const batchData = await batchDataGetter();

            const activityType = await activityTypeGetter();
            const duration = await DurationGetter();
            const mentor = await mentorGetter()
            const college = await CollegeGetter()
            const batch = await batchGetter()
            const roleType = await roleTypeGetter()
            const specialization = await specializationGetter()
            // const batchAccordingToCollege = await batchAccordingToCollegeGetter()
            setRole_details(roleType)
            setSpecialization_details(specialization)
            data_manupulator_activity(activityType);
            data_manupulator_duration(duration);
            data_manupulator_mentor(mentor);
            data_manupulator_college(college);
            data_manupulator_batch(batch);
            data_manupulator_role(roleType)
        }

        handleEffect();
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])
    const batchDataGetter = async () => {
        try {
            const res = await service.get(`/groupSession`)
            console.log("group session info RESPONSE:", res.data)
            setEntireGroupSessionData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const specializationGetter = async () => {
        try {
            const res = await service.get('/specialization?is_admin=1')
            console.log("Response from backend for college:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const navigateToRespectivePages = (type, is_offline) => {
        console.log("type", type);

        navigate(`/OfflineAndResumesFromDashboard?fromResumesDigitalPath=${type}&is_offline=${is_offline}`)
    }

    return (
        <div>
            {loading ? (
                <Loader show={loading} />
            ) : (
                <>
                    <TopBar />
                    <SideNavigationBar />
                    <div className='OngoingEventsCard' style={{ marginLeft: "10rem", marginTop: "6rem" }}>
                        <div style={{ position: 'relative' }}>
                            <p className='topHeadings' style={{ marginBottom: "2rem" }}>Dashboard</p>
                            <AnomaliesDashboard />
                            <Typography style={{ marginBottom: "2rem" }} sx={{ fontSize: 20 }}>Ongoing Events</Typography>
                            <DashboardOngoingEvents />
                            <Typography sx={{ fontSize: 20 }} style={{ marginBottom: "2rem", marginTop: "2rem" }}>Other Ongoing Activities</Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", paddingLeft: ".5rem", marginRight: ".5rem", gap: "2rem" }}>
                                <button className="submitBtn" type="button" style={{ width: "auto", paddingLeft: ".5rem", paddingRight: ".5rem", background: "transparent", border: `1px solid ${Color.primary1}`, color: Color.blueBlack }} onClick={() => navigateToRespectivePages("RDS", 0)}>Resumes Digital</button>
                                <button className="submitBtn" type="button" style={{ width: "auto", paddingLeft: ".5rem", paddingRight: ".5rem", background: "transparent", border: `1px solid ${Color.primary1}`, color: Color.blueBlack }} onClick={() => navigateToRespectivePages("1 x 1", 1)}>Offline Sessions</button>
                            </Box>
                            <Typography sx={{ fontSize: 20 }} style={{ marginBottom: "2rem", marginTop: "2rem" }}>Mentor Status</Typography>
                            <MentorStatus activityTypeData={activityTypeData} roleTypeData={roleTypeData} />
                            <MentorAwaited not_expired={true} activityTypeData={activityTypeData} roleTypeData={roleTypeData} />
                            <MentorAwaited expired={true} activityTypeData={activityTypeData} roleTypeData={roleTypeData} />
                            <OnGoingGroupSessions role_details={role_details} specialization_details={specialization_details} activityTypeData={activityTypeData} durationData={durationData} mentorData={mentorData} collegeData={collegeData} batchData={batchData} entireGroupSessionData={entireGroupSessionData} path={window.location.pathname} />
                            <Colleges activityTypeData={activityTypeData} durationData={durationData} mentorData={mentorData} collegeDetails={collegeData} batchData={batchData} entireGroupSessionData={entireGroupSessionData} path={window.location.pathname} />
                        </div>
                    </div>
                </>
            )}

        </div>
    )
}

export default Dashboard