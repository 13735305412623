import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./ChatModalStyles.css"
import Modal from "react-bootstrap/Modal";
import httpService from "../../../services/httpService";
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'
import { green } from "@mui/material/colors";
import { CheckOutlined } from '@ant-design/icons';
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { send_specialCharReplace, get_specialCharReplace } from "../../../services/specialCharReplace";
export default function ModalSt(props) {
    const service = new CCService();
    const [user_type_id, SetUser_type_id] = useState(null);
    const [allowChatToHappen, setAllowChatToHappen] = useState(false)
    const rowsData = useContext(RowsContext);
    const [rowsD, setRowsD] = useState(rowsData);
    const [chat_input_name, setChat_input_name] = useState("");
    const { show, setShow, headCells, from_table, path, supportTicketModalDetails, supportTicketDetails, ticket_id,category_name, userDetails, chatDetails, setChatDetails, supportTicketCategoryNameDetails } = props;
    console.log("supportTicketModalDetails", supportTicketModalDetails);
    console.log("supportTicketDetails...", supportTicketDetails, supportTicketModalDetails, ticket_id, chatDetails, userDetails);
    console.log("chat details right now", chatDetails);
    console.log("supportTicketCategoryNameDetails",supportTicketCategoryNameDetails);
    const [name, setName] = useState(rowsData[from_table?.index]?.name);
    const [special, setSpecial] = useState()
    const [jd, setJD] = useState()
    const [messagesInfo, setMessagesInfo] = useState({ chat_by: userDetails?.userDetail?.user_id, message: "", timestamp: null })

    const modalHide = () => {
        setShow(!show);
        console.log(!show + "bruh --------");
        setName("")
        setSpecial("")
        setJD("")
    };


    useEffect(() => {
        console.log("Brother")
        console.log("from table inside useEffect of modal input:", from_table)
        function handleEffect() {
            if (from_table?.index !== undefined || rowsData[from_table?.index]?.name !== "") {
                console.log("Inside if cond of useEffect",)
                setName(rowsData[from_table?.index]?.name)
                setSpecial(rowsData[from_table?.index]?.specialization_id)
                setJD(rowsData[from_table?.index]?.default_jd)
            }
            else if (from_table?.index === undefined || rowsData[from_table?.index]?.name === "") {
                console.log("Inside else if cond of useEffect")
                setName("")
            }

            console.log("bhoomikaaaaa", from_table?.index);
        }
        handleEffect();
    }, [from_table])

    const handleChange = (e, index) => {
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);

        if (e.target.name === "chat-input") {
            setChat_input_name(e.target.value)
            console.log("add-specialization-input")
        }



    }

    const chatDivRef = useRef();

    const scrollToBottom = () => {
        if (chatDivRef.current) {
            chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        }
    };



    const get_data_from_form = (name) => {
        console.log("HandleEditUser ~ special", special)
        console.log("🚀HandleEditUser ~ name", name)
        console.log("🚀HandleEditUser ~ id", from_table?.id)
        // name.preventDefault();

        if (path === "/RoleType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim(), undefined, undefined, special.trim(), jd)
                setName("")
                setSpecial("")
                setJD("")
            }
            else {
                console.log("PUT")
                console.log("PUT - special value:", special)
                props.data_getter(name.trim(), from_table?.id, from_table?.index, special, jd)
            }
        }

        else if (path === "/UserType" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim())
                setName("")
            }
            else {
                console.log("PUT")
                props.data_getter(name.trim(), from_table?.id, from_table?.index)
            }
        }

        setShow(false)
    }

    console.log("rows data in modal input file", rowsData);
    console.log("spec_data", props.spec_data);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handlePutRequest()
        }
    }

    console.log("chat details", chatDetails);
    const chatData = JSON.parse(chatDetails[0].chat_stuff);

    const handlePutRequest = () => {
        let putData = {
            "ticket_id": ticket_id,
            "chat_by": userDetails?.userDetail?.user_id,
            "message": send_specialCharReplace(chat_input_name)
        }
        service
            .put(`/supportTicket/ticket/chat`, putData)
            .then((res) => {
                const now = new Date(); // Get the current date and time in the local time zone
                const istOffset = 330 * 60 * 1000; // IST is UTC+5:30, so convert to milliseconds
                const istTime = new Date(now.getTime() + istOffset);

                const newMessageInfo = {
                    chat_by: userDetails?.userDetail?.user_id,
                    message: send_specialCharReplace(chat_input_name),
                    timestamp: istTime.getTime(), // Use IST time in milliseconds
                };
                const updatedChatDetails = {
                    ...chatDetails[0],
                    chat_stuff: JSON.stringify({
                        chats: [...chatData.chats, newMessageInfo],
                    }),
                };
                console.log("updated chat data", updatedChatDetails);
                setChatDetails([updatedChatDetails]);
                setChat_input_name("")
                scrollToBottom();

            })
            .catch((err) => {
                console.log("error message", err);
                window.alert(err.response.data.errMess)
            });
    }



    return (
        <Modal
            className="contained-modal-title-vcenter"
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => modalHide()}
        >
            {console.log("Value of state name in Modal Input:", name)}
            {console.log("Value of state special in Modal Input:", special)}
            <Modal.Body
                style={{
                    position: "absolute",
                    top: '-25px',
                    right: "382px",
                    height: "700px",
                    lineHeight: "1.4",
                    padding: "14px 28px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    width: "400px",
                    float: "right",

                }}
            >
                {/* topbar */}
                <div style={{ marginTop: "32px" }}>
                    {supportTicketDetails.map(ticket => {
                        if (ticket.ticket_id === ticket_id) {
                            
                            return (
                                <>
                                
                                    <div style={{
                                        padding: '10px',
                                        borderBottom: "1px solid var(--Neutral-Light-Grey, #E6E6E6)",
                                        background: "var(--Neutral-White, #FFF)", boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)'
                                    }}>
                                        <p style={{
                                            fontStyle: "normal",
                                            marginBottom: "0px",
                                            fontWeight: 400,
                                            fontSize: "18px",
                                            lineHeight: "26px",
                                        }}>
                                            {ticket?.user_name}
                                        </p>
                                        <p style={{ color: "grey", width: "100%", fontStyle: "normal", fontWeight: "400", fontSize: "14px", lineHeight: "15px", letterSpacing: "0.005em" }}>{ticket?.category_name}</p>

                                        <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex", marginTop: "7px" }}>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                        return null;
                    })}
                </div>
                {console.log("chats", chatData.chats, userDetails?.userDetail?.user_id)}
                
                <div className="chat-container" style={{ height: "auto", }} ref={chatDivRef}>
                    <div style={{ maxHeight: "100%", overflowY: "auto", position: "relative" }}>
                        {chatData.chats.map((message, index) => (
                            <React.Fragment key={index}>
                                <div style={{ width:message.chat_by === null && category_name === "Resolve feedback" ? "95%" : "60%", borderRadius: "10px", backgroundColor: (category_name !== "Resolve feedback" &&  message.chat_by === userDetails?.userDetail?.user_id) ? "rgba(28, 142, 168, 0.15)" : (category_name === "Resolve feedback" && (message.chat_by !== supportTicketModalDetails[0]?.ticket_details.user_id)) ? "#F5A536" : (message.chat_by === null && category_name === "Resolve feedback") ? "rgba(217, 217, 217, 0.50)" : "transparent", boxShadow: (message.chat_by === null && category_name === "Resolve feedback") ? "" : "2px 2px 5px rgba(0, 0, 0, 0.25)", marginTop: "16px", float: ((category_name !== "Resolve feedback" &&  message.chat_by === userDetails?.userDetail?.user_id) || (category_name === "Resolve feedback" && message.chat_by !== supportTicketModalDetails[0]?.ticket_details.user_id)) ? "right" :  "left", marginRight: (message.chat_by === null && category_name === "Resolve feedback") ? "0px" : "1rem", wordWrap: "break-word", marginBottom: (message.chat_by === null && category_name === "Resolve feedback") ? "" : ".11rem" }}>
                                    <div style={{ padding: (message.chat_by === null && category_name === "Resolve feedback") ? "0px" : "5px", marginBottom: (message.chat_by === null && category_name === "Resolve feedback") ? "0px" : "5px" }}>
                                        <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                                            {message.chat_by === null ? <></> : <p style={{ fontSize: "10px", color: "#8B8B8B", marginBottom: "0px" }}>{new Date(message?.timestamp).getUTCHours().toString() + ":" + new Date(message?.timestamp).getUTCMinutes().toString()}</p>}
                                        </div>
                                        {message.chat_by === null ? <></> : <p style={{ fontSize: "16px", marginBottom: "0px" }}>{get_specialCharReplace(message.message)}</p>}
                                    </div>
                                    {message.chat_by === null && category_name === "Resolve feedback" && <>
                                    <div style={{background:"rgba(217, 217, 217, 0.50)", borderRadius: "10px",height:"35px", fontSize:"11px", textAlign:"center", display:"flex", justifyContent:'center', alignItems:"center", marginTop:"auto"}}>
                                        <p style={{color:"red"}}>{get_specialCharReplace(message.message)}</p>
                                    </div>
                                    </>}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                
                <div style={{ position: "absolute", bottom: "20px", width: "100%", }}>
                    <input disabled={(category_name === "Resolve feedback" && supportTicketModalDetails[0]?.ticket_details.executive_id !== null && supportTicketCategoryNameDetails.length === 0) ? true : false} type="text" style={{ width: "80%", opacity:(category_name === "Resolve feedback" && supportTicketModalDetails[0]?.ticket_details.executive_id !== null && supportTicketCategoryNameDetails.length === 0) ? 0.5 : 1, borderRadius: "10px", pointerEvents: "auto", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)", height: "48px", border: "1px solid var(--Neutral-Light-Grey, #E6E6E6)", paddingLeft: "1rem" }} name="chat-input" autoFocus value={chat_input_name} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                    <img src={category_name !== "Resolve feedback" ? "./SentMessageButtonChat.png" : "SendMessageResolveFeedbackButton.png"} width={"40px"} height={"40px"} alt="" style={{
                        widows: "20%", marginLeft: "8px", cursor: chat_input_name === "" ? "not-allowed" : "pointer",
                        opacity: chat_input_name === "" ? 0.5 : 1,
                    }} onClick={() => {
                        if (chat_input_name !== "") {
                            handlePutRequest();
                        }
                    }} />
                </div>
            </Modal.Body>
        </Modal >
    );
}
