import React from "react";
import { useEffect } from "react";
import Service from "../../../services/httpService";
import { useState } from "react";
import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import { useNavigate } from "react-router-dom";
import Loader from "../../reusables/Loader/Loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Color } from "../../../GlobalStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
function NewCollegeAnalytics() {
  const service = new Service();
  const navigate = useNavigate();
  const [allColleges, setAllColleges] = useState([]);
  const [selectedColleges, setSelectedCollege] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [allBatches, setAllBatches] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleBatchSelect = (e, college) => {
    setSelectedBatch(e.target.value);
  };
  const handleStudentAnalytics = () => {
    console.log("id", selectedColleges, selectedBatch);
    navigate("/StudentProcess", { state: { college_id: selectedColleges.id, college_name: selectedColleges.college_name, program_id: selectedBatch.program_id, program_name: selectedBatch.program_name, batch: selectedBatch, activated_students : selectedBatch.activated_students, non_activated_students : selectedBatch.non_activated_students } });
  };

  const handleBatchAnnalytics = () => {
    navigate("/BatchWiseAnalytics", { state: { college_id: selectedColleges.id, college_name: selectedColleges.college_name, program_id: selectedBatch.program_id, program_name: selectedBatch.program_name, activated_students : selectedBatch.activated_students, non_activated_students : selectedBatch.non_activated_students } });
  };
  useEffect(() => {
    const getAllCollege = async () => {
      const allColleges = await service.get("/getCollegeWithstudentCount");
      console.log("allcollegs", allColleges);
      setSelectedCollege(allColleges.data[0]);
      setAllColleges(allColleges.data);
      setLoading(false);
    };

    getAllCollege();
  }, []);

  useEffect(() => {
    const getBatchData = async () => {
      if(selectedColleges && selectedColleges.id) {
        const res = await service.get(`/programs?college_id=${selectedColleges.id}`);
        console.log("allBatches", res.data);
        setAllBatches(res.data);
      }
      
    };

    getBatchData();
  }, [selectedColleges]);

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  return (
    <div>
      <SideNavigationBar />
      <Box sx={{ padding: "5rem" }}>
        <div className="topHeadings" style={{ display: "flex", marginLeft: "3rem", marginBottom: "3rem" }}>
          <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
          <p style={{ color: Color.neutralMidGrey }}>Colleges</p>
        </div>
        {loading === false ? (
          <>
            <Grid container columnGap={4} rowGap={6} sx={{ display: "flex", justifyContent: "center" }}>
              {allColleges.map((college, index) => (
                <Grid item xs={12} sm={6} md={3} sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;", padding: "20px", display: "flex", flexDirection: "column", gap: "10px", scale: selectedColleges.id === college.id ? "1.1" : "0.9", "&:hover": { cursor: "pointer" } }} onClick={(e) => setSelectedCollege(college)}>
                  <Typography sx={{ fontSize: "20px" }}>{college.college_name}</Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "16px" }}>No. of Batches: {college.total_user_group}</Typography>
                    <Typography sx={{ fontSize: "16px" }}>No. of Batches: {college.total_students}</Typography>
                  </Box>
                  <Typography>Select Batch</Typography>
                  {selectedColleges === college ? (
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedColleges.id === college.id ? selectedBatch : null} onChange={(e) => handleBatchSelect(e, college)} sx={{ height: "45px" }}>
                      {allBatches.map((item) => (
                        <MenuItem value={item}>{item.program_name}</MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Box sx={{ border: "1px solid #E0E0E0", borderRadius: "5px", background: "#FFF", width: "100%", height: "45px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                      <ArrowDropDownIcon sx={{ color: "#929292" }} />
                    </Box>
                  )}
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Button disabled={selectedColleges.id === college.id ? false : true} style={{ padding: "12px", border: "none", background: selectedColleges.id === college.id ? "#41B8D5" : "#525252", borderRadius: "10px", color: "#fff", width: "100%", fontSize: "12px" }} onClick={() => handleBatchAnnalytics()}>
                      Batch Analytics
                    </Button>
                    <Button disabled={selectedColleges.id === college.id ? false : true} style={{ padding: "12px", border: "none", background: selectedColleges.id === college.id ? "#41B8D5" : "#525252", borderRadius: "10px", color: "#fff", width: "100%", fontSize: "12px" }} onClick={() => handleStudentAnalytics()}>
                      Student Analytics
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto", marginLeft: "45%", marginTop: "10%" }}>
              <Loader show={loading} />
            </div>
          </>
        )}
      </Box>
    </div>
  );
}

export default NewCollegeAnalytics;
