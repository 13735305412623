import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Card, Typography } from '@mui/material';
import './JobCompanies.css'
import { ellipsisFunction } from '../../../../Reusable_functions/TextFunctions/EllipsisFunction';
import { Color, DivCenter } from '../../../../../GlobalStyles';
import { ViewDetailsModal } from '../additional/ViewDetailsModal';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideNavigationBar from '../../../../reusables/SideNavigationBar/SideNavigationBar';
import CCService from '../../../../../services/httpServiceWithoutSuperAdmin';
import { UniqueObjectsByKey } from '../../../../Reusable_functions/DataManipulationFunctions/UnniqueObjectsByKey';
import TimeService from '../../../../../functions/epochTime';
import Loader, { SmallPageLoader } from '../../../../reusables/Loader/Loader';
import { makeTextBold } from '../../../../Reusable_functions/htmlParser/htmlParser';
import formatConvertermonthfirst from '../../../../Reusable_functions/date/formatCovertermonthfirst'
import formateConverter from '../../../../Reusable_functions/date/formateConverter';
import { formatTimestamp } from '../../../../Reusable_functions/date/formatTimestamp';
import { get_specialCharReplace } from '../../../../../services/specialCharReplace';
const JobCompanies = () => {

    //obejcts and instances
    const services = new CCService();
    const timeServices = new TimeService();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [jobCompanies, setJobCompanies] = useState([
        {
            "company_id": null,
            "account_holder": "",
            "email_id": "",
            "designation": "",
            "company_name": "",
            "job_id": null
        }
    ]);
    const [viewDetails, setViewDetails] = useState({
        open: false,
        onClose: () => { setViewDetails((prev) => ({ ...prev, open: false })) },
        data: {
            "Company Name": 'N/A',
            "On boarding date": 'N/A',
            // "Role": 'N/A',
            // "Specialization": 'N/A',
            "Industry": 'N/A',
            "Email": 'N/A',
            "Contact": 'N/A',
            "Linkedin": 'N/A',
            "Company Description": 'N/A'
        }
        // data: {
        //     company_name: '',
        //     on_boarding_date: '',
        //     category: '',
        //     role: '',
        //     specialization: '',
        //     industry: '',
        //     email: '',
        //     contact: '',
        //     linkedin: '',
        //     company_desc: ''
        // }
    });

    useEffect(() => {
        const handleMainUseEffect = async () => {
            setLoading(true);
            //fetching the data
            const companies_data = await companiesDataOperations();
            console.log({ companies_data });
            setJobCompanies(companies_data);
            // const companies_detail_data = await fetchCompaniesDetailData();
            // console.log("companies_detail_data:", companies_detail_data);
            setViewDetails((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    "Company Name": jobCompanies[0].company_name,
                }
            }));
            setLoading(false);
        }
        handleMainUseEffect();
    }, []);

    const companiesDataOperations = async () => {
        const fetchedCompaniesData = await fetchCompaniesData();
        console.log("fetchedCompaniesData:", fetchedCompaniesData);

        const temp_fetchedCompaniesData = UniqueObjectsByKey(fetchedCompaniesData, 'company_id');
        console.log("temp_fetchedCompaniesData:", temp_fetchedCompaniesData);
        return temp_fetchedCompaniesData;
    }

    const fetchCompaniesData = async () => {
        const response = await services.get(`/jobs/admin/recruiterCompanies`);
        console.log("response ~ fetchCompaniesData:", response);
        return response.details;
    };

    // const fetchCompaniesDetailData = async () => {
    //     const response = await services.get(`/jobs/admin/recruiterCompanies`);
    //     console.log("response ~ fetchCompaniesData:", response);
    //     return response;
    // }

    const handleClickViewDetails = (company_id) => {
        const clicked_data = jobCompanies.find(comp => comp.company_id === company_id);
        console.log("clicked_data:", clicked_data);
        setViewDetails((prev) => ({
            ...prev,
            open: true,
            data: {
                ...prev.data,
                "Company Name": clicked_data?.company_name ?? 'N/A',
                "On boarding date": formatTimestamp(clicked_data.timestamp ?? null) ?? 'N/A',
                // "Role": clicked_data.roles ?? 'N/A',
                // "Specialization": clicked_data.spe ?? 'N/A',
                "Industry": clicked_data.industry ?? 'N/A',
                "Email": clicked_data.email_id ?? 'N/A',
                "Contact": clicked_data.contact ?? 'N/A',
                "Linkedin": clicked_data.linkedin ?? 'N/A',
                "Company Description": makeTextBold(get_specialCharReplace(clicked_data.about)) ?? 'N/A'
            }
        }));
    }

    const handleClickViewRequests = (company_id, company_name) => {
        navigate(`/ViewRequests?company_id=${company_id}`, {
            state: {
                company_name: company_name
            }
        });
    }


    return (
        <>
            {loading ?
                <SmallPageLoader show={loading} /> :
                <Box className='main_box'>
                    <SideNavigationBar />
                    <Box sx={{ width: '12%' }}></Box>
                    <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '33px', alignItems: 'flex-start' }}>
                        <Box sx={{ ...DivCenter, height: '10%', mt: '10px' }}>
                            <Typography sx={{ fontSize: '32px', color: 'black' }}>Companies</Typography>
                        </Box>
                        <Box className='box__companyCards' style={{ height: '90%' }}>
                            {jobCompanies.map((job_company) =>
                                <Card className='card__companyCards'>
                                    {jobCompanies[0].company_id !== null ?
                                        <Box sx={{ height: '100%', width: '100%' }}>
                                            <Box className='box__companyName'>
                                                <Typography className='typography__companyName' sx={{ height: '100%' }}>{job_company.company_name}</Typography>
                                            </Box>

                                            <Box className='box__companyCardContent'>
                                                <Box className='box__companyDetails'>
                                                    <Typography className='typography__box_companyCardContent'>{ellipsisFunction(job_company.account_holder, 15)}</Typography>
                                                    <Typography className='typography__box_companyCardContent'>{ellipsisFunction(job_company.email_id, 15)}</Typography>
                                                </Box>
                                                <Box className='box__actionButtons'>
                                                    <Button variant='contained' className='button__actionButtons'
                                                        onClick={() => { handleClickViewRequests(job_company.company_id, job_company.company_name) }}
                                                        sx={{ background: Color.primary_gradient, '&:hover': { background: Color.primary_gradient, boxShadow: 'none' } }}>
                                                        <Typography sx={{ color: 'white', textTransform: 'none' }}>View Requests</Typography></Button>
                                                    <Button
                                                        variant='outlined'
                                                        className='button__actionButtons'
                                                        onClick={() => handleClickViewDetails(job_company.company_id)}
                                                        sx={{ '&:hover': { background: Color.white, borderColor: Color.primary1, boxShadow: 'none' } }}>
                                                        <Typography sx={{ color: 'black', textTransform: 'none' }}>View Details</Typography>
                                                    </Button>
                                                    <Button variant='text' className='button__actionButtons' onClick={() => navigate(`/Posts?company_id=${job_company.company_id}`, {
                                                        state: {
                                                            company_name: job_company.company_name
                                                        }
                                                    })}>
                                                        <Typography sx={{ color: Color.primary1, textTransform: 'none' }}>View All Posts</Typography>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box> :
                                        <Box sx={{ ...DivCenter, width: '100%', height: '100%' }}>
                                            <CircularProgress size={20} sx={{ color: Color.primary1 }} />
                                        </Box>}
                                </Card>)
                            }
                        </Box>
                    </Box>
                    <Box sx={{ width: '8%' }}></Box>
                    <ViewDetailsModal
                        open={viewDetails.open}
                        onClose={viewDetails.onClose}
                        data={viewDetails.data}
                    />
                </Box >}
        </>
    )
}

export default JobCompanies;