import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import OngoingEvents from '../OngoingEvents/OngoingEvents'
import UpcomingPrograms from "../UpcomingPrograms/UpcomingPrograms";
import AdminsOfCollege from '../Admins/AdminsOfCollege'
import Typography from '@mui/material/Typography';
import '../OngoingEvents/OngoingEvents.css'
import React, { useEffect, useState } from "react";
import OnGoingGroupSessions from '../OnGoingGroupSessions/OnGoingGroupSessions'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Color } from "../../../GlobalStyles";
import Service from "../../../services/httpService";
import CollegeData from "../../../CollegeDataServices/httpCollege";
import Loader from "../../reusables/Loader/Loader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CollegeAdminLevels from "./CollegeAdminLevels";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { Add } from "@mui/icons-material";
import AccessRightsModal from "../../College/CollegAdmin/AccessRightsModal";
import ServiceCM from "../../../services/cartmoduleServices";
const CollegeInfoPage = () => {
  const location = useLocation();
  const service = new Service()
  const services = new CCService()
  const collegeService = new CollegeData()
  const navigate = useNavigate();
  const cartModule = new ServiceCM()
  let college_data_name = location.state?.college_name || ""
  const [searchParams, setSearchParams] = useSearchParams();
  const college_id = searchParams.get("college_id")
  console.log("college id", college_id);
  const college_name = searchParams.get("college_name")
  const [entireGroupSessionData, setEntireGroupSessionData] = useState([])
  const [activityTypeData, setActivityTypeData] = useState([])
  const [durationData, setDurationData] = useState([])
  const [mentorData, setMentorData] = useState([])
  const [collegeData, setCollegeData] = useState([])
  const [batchData, setBatchData] = useState([])
  const [activity_name, setActivity_name] = useState()
  const [college_name1, setCollege_name1] = useState()
  const [duration_name, setDuration_name] = useState()
  const [mentor_name, setMentor_name] = useState()
  const [batch_name, setBatch_name] = useState()
  const [loading, setLoading] = useState(true);
  const [adminLevels, setAdminLevels] = useState(true);
  const [showRightsModal, setShowRightsModal] = useState(false);
  const [allAccessRights, setAllAccessRights] = useState([])
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [role_details, setRole_details] = useState([])
	const [specialization_details, setSpecialization_details] = useState([])
  const activityTypeGetter = async () => {

    try {
      const res = await cartModule.get('/sku?type=Group')
      console.log("Response from backend for activity name:", res.data)
      setActivityTypeData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }

    console.log("Response activity type data", activityTypeData);
  }

  const DurationGetter = async () => {

    try {
      const res = await service.get('/duration')
      console.log("Response from backend for duration:", res.durations)

      // setDurationData(getTimeFormated(res.durations))
      return res.durations
    } catch (err) {
      console.log(err)
    }
    console.log("Response duration type data", durationData);

  }

  const CollegeGetter = async () => {

    try {
      const res = await collegeService.get('/college')
      console.log("Response from backend for college:", res.data)
      setCollegeData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
    console.log(" Response college type data", collegeData);

  }

  const mentorGetter = async () => {

    try {
      const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
      console.log("Response from backend for mentor:", res.data)
      setMentorData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
    console.log("Response mentor type data", mentorData);
  }

  const batchGetter = async () => {

    try {
      const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/collegeModule/userGroup`)
      console.log("Response from backend for batch:", res.data)
      setBatchData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
    console.log("Response bacth type data", batchData);

  }

  const data_manupulator_activity = (activityType) => {
    console.log("activity type", activityType);
    // let temp = activityType;
    let temp = activityType.filter(act => act.type === "Group");
    temp = temp.map(bat => {
      bat.key = bat.act_type_name;
      bat.id = bat.id;
      bat.type = bat.type
      return bat;
    });
    console.log("temp rows activity:", temp)
    setActivity_name(temp)
    console.log("activity group names", activity_name);
  }
  const data_manupulator_duration = (duration) => {
    console.log("duration name", duration);

    let temp = duration;
    temp = temp?.map(bat => {
      bat.key = bat.duration_name;
      // bat.id = bat.id;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setDuration_name(temp)
  }
  const data_manupulator_mentor = (mentor) => {
    console.log("mentor type", mentor);

    let temp = mentor;
    temp = temp?.map(bat => {
      bat.key = bat.first_name;
      bat.id = bat.id;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setMentor_name(temp)
  }
  const data_manupulator_college = (college) => {
    console.log("college type", college);

    let temp = college;
    temp = temp?.map(bat => {
      bat.key = bat.college_name;
      bat.id = bat.id;
      return bat;
    });
    setCollege_name1(temp)
    console.log("temp rows batch:", temp)

  }

  const data_manupulator_batch = (batch) => {
    console.log("bactyh type", batch);

    let temp = batch;
    temp = temp?.map(bat => {
      bat.key = bat.user_group_name;
      bat.id = bat.id;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setBatch_name(temp)
  }

  const getAdminLevels = async () =>{
    const data = await services.get(`/po/acl/allAdminLevel?college_id=${college_id }`)
    const getData = await services.get("/po/acl/accessRight")
      
    
    setAllAccessRights(getData.data )
    setAdminLevels(data.data)
  
  }

  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {

      //batch data getter
      const batchData = await batchDataGetter();
      const adminLevel = await getAdminLevels()
      const activityType = await activityTypeGetter();
      const duration = await DurationGetter();
      const mentor = await mentorGetter()
      const college = await CollegeGetter()
      const batch = await batchGetter()

      const roleType = await roleTypeGetter()
      const specialization = await specializationGetter()
      // const batchAccordingToCollege = await batchAccordingToCollegeGetter()
      setRole_details(roleType)
      setSpecialization_details(specialization)
      data_manupulator_activity(activityType);
      data_manupulator_duration(duration);
      data_manupulator_mentor(mentor);
      data_manupulator_college(college);
      data_manupulator_batch(batch);

      // batchData.map((item, i) => {
      //   college.map((college_filter) => {
      //     console.log("temp filtered college data", temp_data);
      //   })
      // })
      console.log("batch info ", batchData);
      console.log("college info ", college);

      setLoading(false)
    }

    handleEffect();
  }, [])

  const roleTypeGetter = async () => {
    try {
      const res = await service.get('/role?is_admin=1')
      console.log("roleTypeGetter", res.data)
      return res.data
    }
    catch (err) {
      console.log(err)
    }
  }

  const specializationGetter = async () => {
		try {
			const res = await service.get('/specialization?is_admin=1')
			console.log("Response from backend for college:", res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}
  const batchDataGetter = async () => {
    try {
      const res = await service.get(`/groupSession`)
      console.log("group session info RESPONSE:", res.data)
      let temp_data = res.data.filter((filtering_info, i) => filtering_info.college_id === parseInt(college_id))
      console.log("temp filtered college data", temp_data);
      setEntireGroupSessionData([...temp_data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const goBack = () => {
    console.log("Back Button is Clicked Now")
    navigate(-1);
  }
  const handleEditRights = async(item) =>{
    try {
     const data = await services.get(`/po/acl/allAccessLevel?level_id=${item.id}`)
     const rights_ids = JSON.parse(data.data[0].access)
   
     setAllAccessRights((prev) => {
         return prev.map((ele) =>{
             return{
                 ...ele,
                 checked: rights_ids.find((item) => item.access_right_id === ele.access_right_id).is_activated === 1 ? true : false
             }
         })
     })


     setSelectedLevel(item)
     setShowRightsModal(true)
    } catch (error) {
     console.log(`Error: ${error.response.data.message}`)
    }
 }

 const handleNewLevel = ()=>{
  setAllAccessRights((prev) => {
    return prev.map((item) =>{
     return {
      ...item,
      checked: true
     }
    })
  })
  setSelectedLevel(null)
  setShowRightsModal(true)
 }
  return (
    <>
      {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div> : <>
        <div className='OngoingEventsCard' style={{ marginTop: "5.5rem", marginLeft: "10rem" }}>
          <SideNavigationBar />
          <div className="topHeadings" style={{ display: "flex" }}>
          {/* <ArrowBackIcon className="Arrow-back-btn" style={{height:"30px", width:"30px", marginRight:"0.5rem"}} onClick={() => goBack()} /> */}

            <p style={{ color: Color.neutralMidGrey, marginBottom: "1rem" }}>{college_name}</p>
          </div>

          <Typography class="topHeadings"  >{college_data_name}</Typography>
          <UpcomingPrograms college_id={college_id} college_name={college_name} />
          <OngoingEvents college_id={college_id} college_name={college_name} />
          <OnGoingGroupSessions role_details={role_details} specialization_details={specialization_details} activityTypeData={activityTypeData} durationData={durationData} mentorData={mentorData} collegeData={collegeData} batchData={batchData} entireGroupSessionData={entireGroupSessionData} path={window.location.pathname} />
          {/* <OnGoingGroupSessions college_id={college_id} college_name={college_name} entireGroupSessionData={entireGroupSessionData} /> */}
          <div style={{marginBlock:"1rem", display:"flex", gap:"16px", flexWrap:"wrap"}}>
          {adminLevels.map((item) => <Box sx={{padding:"8px"}}>
               <Button sx={{color:Color.primary1, border:"none", textTransform:"none"}} onClick={()=>handleEditRights(item)}>{item.level_name}</Button>
          </Box>)}

          <IconButton onClick={()=>handleNewLevel()}>
              <Add />
          </IconButton>
    </div>
          <AdminsOfCollege college_id={college_id} college_name={college_name} />
        </div>


        
      <Modal open={showRightsModal} onClose={()=> {
        setShowRightsModal(false)
        setSelectedLevel(null)
      }} sx={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
        <AccessRightsModal allAccessRights={allAccessRights} selectedLevel={selectedLevel} setAllAccessRights={setAllAccessRights} close ={()=>{ setShowRightsModal(false)
        setSelectedLevel(null)}} levels={adminLevels} setLevels={setAdminLevels} college_id={college_id} />
      </Modal>
      </>}

    </>
  )
}

export default CollegeInfoPage
