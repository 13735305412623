import React from 'react';

const formatConverterSipDash = (date) => {
    // console.log("datebtaobhai", date.substring(5, 7))
    // console.log("cuigic", date.substring(5, 7) === "11")
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const d = new Date(date);
    // console.log("The current month is " + monthNames[d.getMonth()]);
    // let mn = date.substring(0, 7)
    // let alpha_mn = "";
    // console.log(mn)
    // switch (mn) {
    //     case '01':

    //         alpha_mn = 'Jan';
    //         break;
    //     case '02':
    //         alpha_mn = 'Feb';
    //         break;
    //     case '03':
    //         alpha_mn = 'Mar';
    //         break;
    //     case '04':
    //         alpha_mn = 'Apr';
    //         break;
    //     case '05':
    //         alpha_mn = 'May';
    //         break;
    //     case '06':
    //         alpha_mn = 'Jun';
    //         break;
    //     case '07':
    //         alpha_mn = 'Jul';
    //         break;
    //     case '08':
    //         alpha_mn = 'Aug';
    //         break;
    //     case '09':
    //         alpha_mn = 'Sep';
    //         break;
    //     case '10':
    //         alpha_mn = 'Oct';
    //         break;
    //     case '11':
    //         alpha_mn = 'Nov';
    //         break;
    //     case '12':
    //         alpha_mn = 'Dec';
    //         break;
    // }
    // console.log("asdfghj", monthNames[d.getMonth()])
    return (monthNames[d.getMonth(date)] === undefined ? " " :  " – " + monthNames[d.getMonth(date)]+" ");
}

export default formatConverterSipDash
