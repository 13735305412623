import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Color } from '../../../../GlobalStyles';



const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
   
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 3,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:Color.white,
            borderStyle:"dashed",
            borderColor:Color.primary1
        },
    },
    
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: Color.green,
            border: 0,
        },
    },
   
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: Color.primary1,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: Color.green,
    }),
}));

function ColorlibStepIcon(props) {
    const { currStep, active, completed, className } = props;

    return (
        <ColorlibStepIconRoot ownerState={{ currStep, completed, active }} className={className}>
            { completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) :
                (props.icon)
            }
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    currStep:PropTypes.bool,
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

// const steps = ['Batch Summary', 'Mentor Interest', 'Mentor Shown Interest', 'Launch Student Availability', 'Launch Schedule', 'Student List'];
const StepperOS = ({ steps,
    setSteps,
    activeStep,
    lastActivePage,
    setActiveStep,
    connectorColor,
    stepBackgroundColor,
    completedColor,
    onStepChange }) => {
    console.log("lastActivePage in stepperos:", lastActivePage, steps);
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            {/* <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
            <Stepper alternativeLabel activeStep={activeStep.step - 1} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                    <Step
                        onClick={() => { { if (label.completed === 1|| index+1<=lastActivePage){ onStepChange(null, label.id, 0); }} }}
                        completed={label.completed === 1 ? true : false}
                        active={index<lastActivePage}
                        key={label.id}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label.content}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}
export default StepperOS
