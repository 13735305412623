import React from "react";
function ExtraCurricularFreeFlowing(props) {
    const extracurricularData =[
        {
            "basic_info": {
                "tag": "Extra curricular basic info"
            },
            "sub_section_detail": {
                "sub_section_id": 681,
                "sub_section_order": 1,
                "is_on": 1
            },
            "bullets": [
                {
                    "bullet_id": 1233,
                    "bullet_content": "Extra curricular details",
                    "achievement_year": "Year",
                    "bullet_order": 1
                }
            ]
        }
    ]
    console.log("extracurricularData",extracurricularData);
    let Details=[]
    if ((extracurricularData && extracurricularData?.length > 0)) {
        extracurricularData?.map((value,index)=>
        Details.push({
        Role: (
            <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
                Achievement 1
            </p>
        ),
        Desc:(
        <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
            <ul className="resumeTable-ul"
                                style={{
                                    marginLeft: "17px",
                                    listStyleType: props.Bullet ? "square" : "",
                                    lineHeight: props.LineHeight,
                                }}
                            >
                            {
                                <li style={{ listStyleType: 'none', fontFamily: props.fontS }}>
                                    Extracurricular data {2017 ? ", " + 2012 : ""}
                                </li>
                            }
                            </ul>
        </p>
        )
        }))
    }
    else{
    Details = [
        {
            Desc: (
                <p id="resumeTable-p" style={{ textAlign: "justify" }}>
                    <ul className="resumeTable-ul" style={{
                        marginLeft: "12px",
                        listStyleType:"none",
                        lineHeight: props.LineHeight ? "1.2" : "",
                        LineHeight: props.miniLheight
                    }}>
                        <li>
                            CFA RC	Represented IIMV @ CFA Research Challenge Zonal Round after placing 1st /4 Teams in Campus Prelims
                        </li>
                        <li>
                            {/* Aestimatus 7.0	2nd Runner-up / 175+ Teams in Finance Case Competition for valuing B2B E-Commerce Firm @ IIM Kashipur */}
                        </li>

                    </ul>
                </p>
            ),
        },
    ];
}
    return (
        <div>
            <table className="ResumeTable"
                style={{ width: props.minwidth ? props.minwidth : props.TableWidth, }}
            >
                <tr>
                    <th className="Resumetable-th" colspan="6" id="Topic"
                        style={{
                            backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
                            color: props.Color ? "white" : "",
                            fontSize: props.FontSize,
                            fontFamily: props.fontS,
                            border: "none",
                            textAlign: props.headerPosition,
                            height: props.miniheight, fontSize: props.miniFontSize, padding: props.minipadding
                        }}>
                        {props.name ? props.name : 'ExtraCurricular'}
                    </th>
                </tr>
                {Details.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td className="ResumeTable-td"
                                style={{
                                    fontFamily: props.fontS,
                                    fontSize: props.FontSize,
                                    height: props.miniheight, fontSize: props.miniFontSize, padding: props.minipadding
                                }}
                                colSpan={4}>
                                Details
                            </td>
                        </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default ExtraCurricularFreeFlowing;
