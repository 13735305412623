import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
//TopBar
import Login from "./Login/Login";
// SideNavBar
import ModalInput from "./components/reusables/ModalInput/ModalInput";
//user_type
import ActivityType from "./components/pages/ActivityType/ActivityType";
import BatchType from "./components/pages/BatchType/BatchType";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import ProgramType from "./components/pages/ProgramType/ProgramType";
import RoleType from "./components/pages/Role/Role";
import SpecilizationType from "./components/pages/SpecilizationType/SpecilizationType";
import UserType from "./components/pages/UserType/UserType";
import ModalInputUpload from "./components/reusables/ModalInput/ModalInputUpload";
// JD
import BatchData from "./components/College/BatchData/BatchData";
import CollegeData from "./components/College/CollegeData/CollegeData";
import ProcessData from "./components/College/ProcessData/ProcessData";

import DefaultJD from "./components/pages/JD/DefaultJD";
import JD from "./components/pages/JD/JD";
//durations
import Durations from "./components/pages/Durations/Durations";
//sku prices
import StudentData from "./components/College/StudentData/StudentData";
import Bundle from "./components/pages/SKUPrices/Bundle";
import Coupon from "./components/pages/SKUPrices/Coupon";
import SKU from "./components/pages/SKUPrices/SKU";
// mentors data
import ResumesDigitalAnalytics from "./components/Analytics/ResumesDigitalAnalytics/ResumesDigitalAnalytics";
import GroupSession from "./components/GroupSessions/GroupSession";
import MentorActivity from "./components/Mentor/MentorActivity/MentorActivity";
import MentorData from "./components/Mentor/MentorData/MentorData";
import MentorDetails from "./components/Mentor/MentorDetails/MentorDetails";
import SupportTicket from "./components/pages/SupportTicket/SupportTicket";
// dashboard data
import ActivatedStatus from "./components/Dashboard/ActivatedStatus/ActivatedStatus";
import AdminsOfCollege from "./components/Dashboard/Admins/AdminsOfCollege";
import College1x1StudentDetails from "./components/Dashboard/College1x1StudentDetails/College1x1StudentDetails";
import College1x1BatchDetails from "./components/Dashboard/CollegeBatchDetails/College1x1BatchDetails";
import CollegeInfoPage from "./components/Dashboard/CollegeInfoPage/CollegeInfoPage";
import MentorAwaited from "./components/Dashboard/MentorAwaited/MentorAwaited";
import MentorAwaitedByRole from "./components/Dashboard/MentorAwaited/MentorAwaitedByRole";
import MentorStatus from "./components/Dashboard/MentorStatus/MentorStatus";
import OngoingEvents from "./components/Dashboard/OngoingEvents/OngoingEvents";
import UpcomingPrograms from "./components/Dashboard/UpcomingPrograms/UpcomingPrograms";
// admin resume settings
import ResumeTemplate from "./components/AdminResumeSettings/ResumeTemplate/ResumeTemplate";
import Settings from "./components/ResumeSettings/Settings";
import { theme } from "./muiTheme";

// mentor complete details
import MentorActivityTable from "./components/Dashboard/MentorStatus/MentorActivityTable";
import MentorNotActivatedAndApprovalAwaited from "./components/Dashboard/MentorStatus/MentorNotActivatedAndApprovalAwaited";
import MentorStatusFilter from "./components/Dashboard/MentorStatus/MentorStatusFilter";

import Podcast from "./components/PodcastSessions/Podcast";

// analytics
import { ThemeProvider } from "@mui/material";
import Analytics from "./components/Analytics/Analytics";
import ActivityReport from "./components/Analytics/BatchWiseAnalytics/ActivityReport";
import BatchProgressTracker from "./components/Analytics/BatchWiseAnalytics/BatchProgressTracker";
import BatchWiseAnalytics from "./components/Analytics/BatchWiseAnalytics/BatchWiseAnalytics";
import DiagnosticInterview_1 from "./components/Analytics/BatchWiseAnalytics/DiagnosticInterview_1";
import NewCollegeAnalytics from "./components/Analytics/CollegeWiseAnalytics/NewCollegeAnalytics";
import InterviewWiseAnalytics from "./components/Analytics/InterviewWiseAnalytics/InterviewWiseAnalytics";
import StudentProcess from "./components/Analytics/StudentProcess";
import StudentWiseAnalytics from "./components/Analytics/StudentWiseAnalytics/StudentWiseAnalytics";

import Blogs from "./components/Blogs/Blogs";
import BlogsAuthor from "./components/Blogs/BlogsAuthor";
import BlogsCategory from './components/Blogs/BlogsCategory';
import BlogsTags from "./components/Blogs/BlogsTags";
import EditTheBlogs from "./components/Blogs/EditTheBlogs";
import PocketExpenses from "./components/College/OfflineSessions/StudentSummary/PocketExpenses";
import StudentSummary from "./components/College/OfflineSessions/StudentSummary/StudentSummary";
import Example from "./components/College/OfflineSessions/example";
import B2CDashboard from "./components/pages/B2C/B2CDashboard";
import B2CProcess from "./components/pages/B2C/B2CProcess";
import JobAllRequests from "./components/pages/JobFlow/JobAllRequests/JobAllRequests";
import JobColleges from "./components/pages/JobFlow/JobColleges/JobColleges";
import JobCompanies from "./components/pages/JobFlow/JobCompanies/main/JobCompanies";
import JobJobs from "./components/pages/JobFlow/JobJobs/JobJobs";
import JobPostings from "./components/pages/JobFlow/JobPostings/JobPostings";
import JobStudentApplicants from "./components/pages/JobFlow/JobPostings/JobStudentApplicants";
import JobStudentProcess from "./components/pages/JobFlow/JobPostings/JobStudentProcess";
import JobStudentShortlist from "./components/pages/JobFlow/JobPostings/JobStudentShortlist";
import ViewJobPostingState from "./components/pages/JobFlow/JobPostings/ViewJobPostingState";
import ViewRequests from "./components/pages/JobFlow/ViewRequests/ViewRequests";
import AnomaliesPastBookings from "./components/pages/Dashboard/AnomaliesDashboard/AnomaliesPastBookings";
import Levels from "./components/College/CollegAdmin/Levels";
import FeedbackAndMcq from "./components/pages/ActivityType/FeedbackAndMcq";
import OfflineAndResumesFromDashboard from "./components/pages/Dashboard/OfflineAndResumesFromDashboard/OfflineAndResumesFromDashboard";
function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/Dashboard" exact element={<Dashboard />} />
            <Route path="/OfflineAndResumesFromDashboard" exact element={<OfflineAndResumesFromDashboard />} />
            <Route path="/UserType" exact element={<UserType />} />
            <Route path="/ModalInput" exact element={<ModalInput />} />
            <Route path="/ModalInputUpload" exact element={<ModalInputUpload />} />
            <Route path="/ProgramType" exact element={<ProgramType />} />
            <Route path="/BatchType" exact element={<BatchType />} />
            <Route path="/RoleType" exact element={<RoleType />} />
            <Route path="/SpecilizationType" exact element={<SpecilizationType />} />
            <Route path="/JD" exact element={<JD />} />
            <Route path="/DefaultJD" exact element={<DefaultJD />} />
            <Route path="/ActivityType" exact element={<ActivityType />} />
            <Route path="/Durations" exact element={<Durations />} />
            <Route path="/CollegeData" exact element={<CollegeData />} />
            <Route path="/BatchData" exact element={<BatchData />} />
            <Route path="/GroupSession" exact element={<GroupSession />} />
            <Route path="/ProcessData" exact element={<ProcessData />} />
            <Route path="/SKU" exact element={<SKU />} />
            <Route path="/Bundle" exact element={<Bundle />} />
            <Route path="/Coupon" exact element={<Coupon />} />
            <Route path="/StudentData" exact element={<StudentData />} />
            <Route path="/MentorDetails" exact element={<MentorDetails />} />
            <Route path="/MentorData" exact element={<MentorData />} />
            <Route path="/MentorActivity" exact element={<MentorActivity />} />
            <Route path="/SupportTicket" exact element={<SupportTicket />} />
            <Route path="/OngoingEvents" exact element={<OngoingEvents />} />
            <Route path="/UpcomingPrograms" exact element={<UpcomingPrograms />} />
            {/* cc admin */}
            <Route path="/AdminsOfCollege" exact element={<AdminsOfCollege />} />
            <Route path="/CollegeInfoPage" exact element={<CollegeInfoPage />} />
            <Route path="/College1x1BatchDetails" exact element={<College1x1BatchDetails />} />
            <Route path="/College1x1StudentDetails" exact element={<College1x1StudentDetails />} />
            <Route path="/MentorStatus" exact element={<MentorStatus />} />
            <Route path="/ActivatedStatus" exact element={<ActivatedStatus />} />
            <Route path="/MentorAwaited" exact element={<MentorAwaited />} />
            <Route path="/MentorAwaitedByRole" exact element={<MentorAwaitedByRole />} />
            <Route path="/ResumeTemplate" exact element={<ResumeTemplate />} />
            <Route path="/Settings" exact element={<Settings />} />
            <Route path="/MentorActivityTable" exact element={<MentorActivityTable />} />
            <Route path="/MentorStatusFilter" exact element={<MentorStatusFilter />} />
            <Route path="/MentorNotActivatedAndApprovalAwaited" exact element={<MentorNotActivatedAndApprovalAwaited />} />
            <Route path="/Podcast" exact element={<Podcast />} />
            <Route path="/Session" exact element={<Podcast />} />
            <Route path="/Analytics" exact element={<Analytics />} />
            <Route path="/BlogsCategory" exact element={<BlogsCategory />} />
            <Route path="/Blogs" exact element={<Blogs />} />
            <Route path="/BlogsTags" exact element={<BlogsTags />} />
            <Route path="/BlogsAuthor" exact element={<BlogsAuthor />} />
            <Route path="/EditTheBlogs" exact element={<EditTheBlogs />} />
            <Route path="/BatchWiseAnalytics" exact element={<BatchWiseAnalytics />} />
            <Route path="/BatchProgressTracker" exact element={<BatchProgressTracker />} />
            <Route path="/ActivityReport" exact element={<ActivityReport />} />
            <Route path="/StudentWiseAnalytics" exact element={<StudentWiseAnalytics />} />
            <Route path="/StudentProcess" exact element={<StudentProcess />} />
            {/* ResumeTemplate */}
            <Route path="/DiagnosticInterview" exact element={<DiagnosticInterview_1 />} />
            <Route path="/InterviewWiseAnalytics" exact element={<InterviewWiseAnalytics />} />
            <Route path="/CollegeAnalytics" exact element={<NewCollegeAnalytics />} />
            {/* <Route path="/NewCollegesAnalytics" exact element={<NewCollegeAnalytics />} /> */}

            {/* Recruiter flow */}
            <Route path="/AllRequests" exact element={<JobAllRequests />} />
            <Route path="/Companies" exact element={<JobCompanies />} />
            <Route path="/Colleges" exact element={<JobColleges />} />
            <Route path="/Jobs" exact element={<JobJobs />} />

            <Route path="/ViewRequests" exact element={<ViewRequests />} />
            <Route path="/Posts" exact element={<JobPostings />} />
            <Route path="/JobPostingState" exact element={<ViewJobPostingState />} />
            <Route path="/StudentShortlist" exact element={<JobStudentShortlist />} />
            <Route path="/JobStudentApplicants" exact element={<JobStudentApplicants />} />
            <Route path="/JobStudentProcess" exact element={<JobStudentProcess />} />

            {/* B2C */}
            <Route path="/B2C" exact element={<B2CDashboard />} />
            <Route path="/B2CProcess" exact element={<B2CProcess />} />
            <Route path="/B2CProcess/College1x1StudentDetails" exact element={<College1x1StudentDetails />} />
            {/* Offline Sessions */}
            <Route path="/OfflineSessions/StudentSummary" exact element={<StudentSummary />} />
            <Route path="/OfflineSessions/PocketExpenses" exact element={<PocketExpenses />} />
            <Route path="/OfflineSessions/Example" exact element={<Example />} />

            <Route path="/AdminRights" exact element={<Levels />} />
            <Route path="/FeedbackAndMcq" exact element={<FeedbackAndMcq />} />
            <Route path="/ResumesDigitalAnalytics" exact element={<ResumesDigitalAnalytics />} />

            {/* anomalies dashboard */}
            <Route path="/AnomaliesPastBookings" exact element={<AnomaliesPastBookings />} />

          </Routes>
        </BrowserRouter>
      </ThemeProvider >
    </>
  );
}

export default App;
