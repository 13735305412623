export const SearchFunction = (data, keysToSearch, searchTerm) => {
    if (!searchTerm.trim()) {
        return data;
    }

    return data.filter((item) =>
        keysToSearch.some((key) =>
            item[key]?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        )
    );
};