import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { Color, DivCenter } from '../../../../GlobalStyles';
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import Service from '../../../../services/httpService';
import '../JobAllRequests/JobAllRequests.css'
import { SlideInRightModal } from '../AdditionalComponents/SlideInRightModal';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import TimeService from '../../../../functions/epochTime';
import { ellipsisFunction } from '../../../Reusable_functions/TextFunctions/EllipsisFunction';
import { send_specialCharReplace } from '../../../../services/specialCharReplace';
import { ArrowBackIos, Close } from '@mui/icons-material';
import { SmallPageLoader } from '../../../reusables/Loader/Loader';

const ViewRequests = () => {

    const services = new CCService();
    const navigate = useNavigate();
    const timeServices = new TimeService();

    const initial_requestData = {
        "job_id": null,
        "job_published_time": "",
        "company_name": "",
        "roles": "",
        "spe": "",
        "designation": "",
        "job_status": ""
    }
    const [loading, setLoading] = useState({ page: false, onHoldConfirmation: false });

    const [filterButtons, setFilterButtons] = useState({ new: false, on_hold: false, recent: false });

    const [allRequestData, setAllRequestData] = useState({
        "New Requests": [initial_requestData],
        "Past Requests": [initial_requestData]
    });
    console.log("allRequestData:", allRequestData);
    const [searchParams, setSearchParams] = useSearchParams();
    // const location = useLocation();
    // const { company_name } = location.state;
    const [company_name, setCompany_name] = useState("");
    const company_id = searchParams.get("company_id");
    console.log("company_id:", company_id);

    const [backup_RequestData, setBackup_RequestData] = useState({ ...allRequestData });
    const [viewRequest, setVeiwRequest] = useState({
        open: false,
        onClose: () => { setVeiwRequest((prev) => ({ ...prev, open: false })) },
        data: { company_name: "Abhra's Cute Company", on_boarding_date: '', category: '', role: '', specialization: '', industry: '', email: '', contact: '', linkedin: '', company_desc: '' }
    });

    const [onHoldRemarks, setOnHoldRemarks] = useState({ flag: '', show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });

    useEffect(() => {
        handleMainUseEffect();
    }, []);

    const handleMainUseEffect = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        const final_data = await companyRequests_operations();
        console.log("final_data:", final_data);
        setAllRequestData(final_data);
        setLoading((prev) => ({ ...prev, page: false }));
    }

    const companyRequests_operations = async () => {
        const fetched_data = await fetchCompanyRequests_by_CompanyId();
        console.log("fetched_data:", fetched_data);
        if (fetched_data.length) {
            let temp_companyRequestsData = {
                "New Requests": [],
                "Past Requests": []
            }
            temp_companyRequestsData["New Requests"] = fetched_data.filter((data) => data.job_status === "In review");
            temp_companyRequestsData["Past Requests"] = fetched_data.filter((data) => data.job_status !== "In review");

            return temp_companyRequestsData;
        }
        else {
            console.log("fetched data empty")
            return [];
        }
    }

    const fetchCompanyRequests_by_CompanyId = async () => {
        const response = await services.get(`/jobs/admin/recruiterCompanies/companyRequests?company_id=${company_id}`);
        console.log("response:", response);
        if (response) {
            setCompany_name(response?.company_name?.company_name);
            return response.details;
        }
        else return [];
    }



    const handleViewButtonClick = (job_id, request_key) => {
        const sending_data = allRequestData[request_key].find(data => data.job_id === job_id);
        console.log("sending_data:", sending_data);
        setVeiwRequest((prev) => ({
            ...prev,
            open: true,
            data: {
                ...prev.data,
                company_name: company_name,
                date: timeServices.convertEpochToDate(parseInt(sending_data.job_published_time)) ?? 'No data',
                time: timeServices.convertEpochToTime(parseInt(sending_data.job_published_time)) ?? 'No data',
                role: sending_data.roles ?? 'No data',
                specialization: sending_data.spe ?? 'No data',
                responsibilities: sending_data.responsibilities,
                status: sending_data.job_status ?? 'No data',
                buttons: true,
                job_id
            }
        }));
    }

    const handleAcceptClick = async () => {
        try {
            setLoading((prev) => ({ ...prev, onHoldConfirmation: true }));
            const body = {
                "job_id": viewRequest.data.job_id,
                "action": "Accept",
                "job_status": "In review",
                "remarks": send_specialCharReplace(onHoldRemarks.content)
            }
            console.log("body:", body);
            const accept_put_request = await putAcceptOrOnHoldRequest(body);
            console.log("accept_put_request:", accept_put_request);
            setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
            onHoldRemarks.onClose();
            viewRequest.onClose();
            handleMainUseEffect();
        } catch (err) {
            window.alert("Error while accepting request", err);
            setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
        }
    }



    const putAcceptOrOnHoldRequest = async (body) => {
        const response = await services.put(`/jobs/admin/recruiterCompanies/request`, body);
        console.log("response:", response);
        return response;
    }


    function OnHoldRemarksModal({ open, onClose }) {
        const [formInput, setFormInput] = useState({ value: onHoldRemarks.content, error: '' });
        console.log("formInput:", formInput);
        const handleOnHoldRemarksSubmit = () => {
            if (formInput.value.length > 0) {
                handleOnHoldClick();
            } else {
                setFormInput((prev) => ({ ...prev, error: 'Please provide a valid response' }))
            }
        }
        const handleOnHoldClick = async () => {
            //calling put request
            try {
                setLoading((prev) => ({ ...prev, onHoldConfirmation: true }));
                const body = {
                    "job_id": viewRequest.data.job_id,
                    "action": "On hold",
                    "job_status": "In review",
                    "remarks": send_specialCharReplace(formInput.value)
                }
                console.log("body:", body);
                const onHold_put_request = await putAcceptOrOnHoldRequest(body);
                console.log("onHold_put_request:", onHold_put_request);
                setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
                setOnHoldRemarks((prev) => ({ ...prev, show: false, content: formInput.value }));
                viewRequest.onClose();
                handleMainUseEffect();
            } catch (err) {
                window.alert("Error while on hold-ing request", err);
                setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
            }
        }
        const onholdmarksStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 604,
            height: 354,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
        }
        return (
            <Modal
                open={open}
                onClose={loading.onHoldConfirmation ? () => { } : onClose}
                // aria-labelledby="modal-modal-title"
                // aria-describedby="modal-modal-description"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}>
                <Fade in={open}>
                    <Box sx={{ ...onholdmarksStyle }}>
                        {onHoldRemarks.flag === 'Accept' ?
                            <>
                                <Box>
                                    <Typography fontSize={32}>Are you sure you want to accept this request?</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', height: '90%', justifyContent: 'space-evenly' }}>
                                    <Button variant='outlined' disabled={loading.onHoldConfirmation}
                                        sx={{ height: '44px', width: '40%', borderColor: Color.primary1, color: Color.primary1 }}
                                        onClick={() => onHoldRemarks.onClose()}>No</Button>
                                    <Button variant='contained' disabled={loading.onHoldConfirmation}
                                        sx={{
                                            height: '44px', width: '40%', background: Color.primary1,
                                            '&:hover': { background: Color.primary1 }
                                        }}
                                        onClick={() => handleAcceptClick()}
                                    >{loading.onHoldConfirmation ?
                                        <CircularProgress size={24} sx={{ color: Color.white }} />
                                        : "Yes"}</Button>
                                </Box>
                            </> :
                            <>
                                <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                                    <Typography fontSize={32}>Why keep this request on Hold?</Typography>

                                    <IconButton onClick={() => onClose()}>
                                        <Close />
                                    </IconButton>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', height: '30%' }}>
                                    <Typography fontSize={18}>Your comments will be seen to the recruiter</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '50%' }}>
                                    <TextField multiline rows={4} disabled={loading.onHoldConfirmation}
                                        value={formInput.value}
                                        onChange={(event) => { setFormInput((prev) => ({ ...prev, value: event.target.value, error: '' })); }}
                                        sx={{ width: '80%', height: '100%' }}></TextField>
                                    <Typography color={'red'} mb={'10px'}>{formInput.error}</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', height: '15%' }}>
                                    <Button variant='contained' disabled={loading.onHoldConfirmation} sx={{ height: '100%', width: '40%', boxShadow: 'none', bgcolor: Color.primary_gradient, color: 'white', textTransform: 'none', '&:hover': { bgcolor: Color.primary_gradient, boxShadow: 'none', } }}
                                        onClick={() => handleOnHoldRemarksSubmit()}>{loading.onHoldConfirmation ?
                                            <CircularProgress size={24} sx={{ color: Color.white }} />
                                            : "Submit"}</Button>
                                </Box>
                            </>}
                    </Box>
                </Fade>

            </Modal>
        );
    }

    return (
        <>
            {loading.page ?
                <SmallPageLoader show={true} />
                : <Box className='main_box'>
                    <SideNavigationBar />
                    <Box className='box__fillerBox' sx={{ width: '12%' }}></Box>
                    <Box className='box__mainContent' sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '33px', alignItems: 'flex-start' }}>
                        <Box sx={{ ...DivCenter, mt: '10px', gap: '33px' }}>
                            <IconButton onClick={() => navigate(-1)} style={{ height: "30px", width: "30px", }} >
                                <ArrowBackIos className="Arrow-back-btn" />
                            </IconButton>
                            <Typography sx={{ fontSize: '32px', color: 'black' }}>{company_name ?? 'No Company Name'}</Typography>
                        </Box>
                        {/* <Box sx={{ ...DivCenter, gap: '10px' }}>
                    <Button variant='outlined' className='button__filterButtons'>New</Button>
                    <Button variant='outlined' className='button__filterButtons'>Recent</Button>
                    <IconButton sx={{ color: Color.primary1 }}>+</IconButton>
                </Box> */}
                        <Box sx={{ width: '100%' }}>
                            <Box className='box__header'>
                                <Typography className='typography__header' style={{ width: '20%' }}>Posted on</Typography>
                                {/* <Typography className='typography__header' style={{ width: '16.67%' }}>Company</Typography> */}
                                <Typography className='typography__header' style={{ width: '20%' }}>Designation</Typography>
                                <Typography className='typography__header' style={{ width: '20%' }}>Role</Typography>
                                <Typography className='typography__header' style={{ width: '20%' }}>Status</Typography>
                                <Typography className='typography__header' style={{ width: '20%', color: 'white' }}>View Buttons</Typography>
                            </Box>
                            <hr style={{ color: Color.neutralMidGrey }} />
                            <Box className='box__postings'>
                                {Object.keys(allRequestData).map((request_key) => (
                                    <Box key={request_key} sx={{ width: '100%' }}>
                                        <Typography sx={{ color: Color.neutralDarkGrey, fontSize: '16px', padding: '16px 0 16px 0' }}>{request_key}</Typography>
                                        {allRequestData[request_key].length ?
                                            allRequestData[request_key].map((request_row, index) => (
                                                <Box key={index}>
                                                    {console.log("request_row:", request_row)}
                                                    <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-evenly', padding: '16px 0 16px 0' }}>
                                                        <Typography style={{ width: '16.67%', textAlign: 'center' }}>{timeServices.convertEpochToTime(parseInt(request_row.job_published_time)) + ", " + timeServices.convertEpochToDate(parseInt(request_row.job_published_time))}</Typography>
                                                        {/* <Typography style={{ width: '16.67%', textAlign: 'center' }}>{request_row.company_name ?? "No company name"}</Typography> */}
                                                        <Typography style={{ width: '16.67%', textAlign: 'center' }}>{ellipsisFunction(request_row.designation ?? 'N/A', 10)}</Typography>
                                                        <Typography style={{ width: '16.67%', textAlign: 'center' }}>{request_row.roles ?? 'N/A'}</Typography>
                                                        <Typography style={{ width: '16.67%', textAlign: 'center', color: request_row.job_status === 'In review' ? Color.accent : request_row.job_status === 'Accept' ? '#08AB3F' : 'red' }}>{request_row.job_status ?? 'N/A'}</Typography>
                                                        <Button
                                                            variant={request_row.job_status === 'In review' ? 'contained' : 'outlined'}
                                                            // onClick={() => handleViewButtonClick(request_row.job_id, request_key)}
                                                            onClick={() => ['In review', 'On hold'].includes(request_row.job_status) ?
                                                                handleViewButtonClick(request_row.job_id, request_key) :
                                                                navigate(`/JobPostingState?job_id=${request_row.job_id}`, { state: { company_name: request_row.company_name } })
                                                            }
                                                            sx={{
                                                                width: '16.67%', background: request_row.job_status === 'In review' ? Color.primary1 : 'white',
                                                                borderColor: Color.primary1,
                                                                color: request_row.job_status === 'In review' ? 'white' : 'black',
                                                                padding: '16px 0 16px 0', borderRadius: '10px',
                                                                textTransform: 'none',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    background: request_row.job_status === 'In review' ? Color.primary3 : '',
                                                                    boxShadow: 'none',
                                                                }
                                                            }}
                                                        >View</Button>
                                                    </Box>
                                                    <hr />
                                                </Box>
                                            )) : <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>}
                                    </Box>
                                ))}
                            </Box>

                        </Box>
                    </Box>
                    <Box className='box__fillerBox' sx={{ width: '8%' }}></Box>
                    <SlideInRightModal
                        open={viewRequest.open}
                        onClose={viewRequest.onClose}
                        data={viewRequest.data}
                        onHoldRemarks={onHoldRemarks}
                        setOnHoldRemarks={setOnHoldRemarks}
                    />
                    <OnHoldRemarksModal
                        open={onHoldRemarks.show}
                        onClose={onHoldRemarks.onClose} />
                </Box>}
        </>
    );
}

export default ViewRequests;