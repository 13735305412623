import './Shapes.css';
export const FunnelComponent = () => {
    return (
        <div className='div__funnelContainer'>
            <svg xmlns="http://www.w3.org/2000/svg" width="287" height="196" viewBox="0 0 287 196" fill="none">
                <path d="M0 10.4469C0 4.56781 5.04714 -0.0427675 10.9022 0.487658L277.902 24.6758C283.054 25.1426 287 29.4618 287 34.635V161.365C287 166.538 283.054 170.857 277.902 171.324L10.9022 195.512C5.04715 196.043 0 191.432 0 185.553L0 10.4469Z" fill="white" />
                <path d="M0 10.4469C0 4.56781 5.04714 -0.0427675 10.9022 0.487658L277.902 24.6758C283.054 25.1426 287 29.4618 287 34.635V161.365C287 166.538 283.054 170.857 277.902 171.324L10.9022 195.512C5.04715 196.043 0 191.432 0 185.553L0 10.4469Z" fill="#165983" fill-opacity="0.1" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="287" height="144" viewBox="0 0 287 144" fill="none">
                <path d="M0 10.6841C0 4.90122 4.89051 0.323848 10.6608 0.705953L277.661 18.3866C282.916 18.7346 287 23.0984 287 28.3648V115.635C287 120.902 282.916 125.265 277.661 125.613L10.6608 143.294C4.89052 143.676 0 139.099 0 133.316L0 10.6841Z" fill="white" />
                <path d="M0 10.6841C0 4.90122 4.89051 0.323848 10.6608 0.705953L277.661 18.3866C282.916 18.7346 287 23.0984 287 28.3648V115.635C287 120.902 282.916 125.265 277.661 125.613L10.6608 143.294C4.89052 143.676 0 139.099 0 133.316L0 10.6841Z" fill="#165983" fill-opacity="0.4" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="287" height="104" viewBox="0 0 287 104" fill="none">
                <path d="M0 10.4897C0 4.77928 4.77382 0.22831 10.4777 0.5011L277.478 13.2705C282.809 13.5254 287 17.9219 287 23.2591V80.741C287 86.0781 282.809 90.4746 277.478 90.7295L10.4777 103.499C4.77381 103.772 0 99.2207 0 93.5103L0 10.4897Z" fill="white" />
                <path d="M0 10.4897C0 4.77928 4.77382 0.22831 10.4777 0.5011L277.478 13.2705C282.809 13.5254 287 17.9219 287 23.2591V80.741C287 86.0781 282.809 90.4746 277.478 90.7295L10.4777 103.499C4.77381 103.772 0 99.2207 0 93.5103L0 10.4897Z" fill="#165983" fill-opacity="0.7" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="287" height="74" viewBox="0 0 287 74" fill="none">
                <path d="M0 10.3461C0 4.68992 4.68721 0.159504 10.3401 0.35187L277.34 9.43777C282.727 9.6211 287 14.0415 287 19.432V54.568C287 59.9585 282.727 64.3789 277.34 64.5622L10.3401 73.6481C4.68722 73.8405 0 69.3101 0 63.6539L0 10.3461Z" fill="#165983" />
            </svg>
        </div>
    );
};