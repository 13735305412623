import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ResumeTemplate.css";
import Switch from "./Switch.js";
import section1 from "./tempData.json";
// import Second_heading from "../Reusable_functions/previewAndTips";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import userdata from "./tempData.json";
import { Color } from "../../../GlobalStyles";
import CustomizedSmallDialogs from "../MuiDilog";
// import { Maximize } from "@mui/icons-material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
// import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import TemplateChange from "../../ResumeSettings/TemplateChange.json";
import { AddOutlined, Edit } from "@mui/icons-material";

export default function ResumeTemplate() {
  const [templatesAvailable, setTemplateAvailable] = useState(TemplateChange);
  const [lockVerification, setLockVerification] = useState({
    basic_info: false,
    bullet: false,
  });

  // check out

  const [checked, setChecked] = useState(true);
  const [resumesAnalyticsVisible, setResumesAnalyticsVisible] = useState(false)
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);
  const [modalChangeTemplate, setModalChangeTemplate] = useState(false);
  const handleCheckBoxChange = (event, id) => {
    const temp_checked = event.target.checked;
    setChecked(temp_checked);
    setSectionDetails(sectionDetails.map((sectionDetail) => (sectionDetail.section_type_id === id ? { ...sectionDetail, is_disabled: temp_checked } : { ...sectionDetail })));
    const updateEditState = {
      ...sectionEntireData,
      section: sectionDetails.map((sectionDetail) => (sectionDetail.section_type_id === id ? { ...sectionDetail, is_disabled: temp_checked } : { ...sectionDetail })),
    };
    setSectionEntireData(updateEditState);
    console.log("id being checked", id);
  };

  // select base template
  const [baseTemplate, setBaseTemplate] = useState("Free Flowing");
  const [isbaseTemplateLocked, setIsBaseTemplateLocked] = useState(0);

  const service = new CCService();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const process_id = location.state?.process_id || parseInt(searchParams.get("process_id"));
  const batch_name = location.state?.batch_name;
  const college_name = location.state?.college_name;
  const batch_id = location.state?.batch_id;
  console.log("process id", process_id, college_name, batch_name, batch_id);
  const cookies = Cookies.get("token");
  const navigate = useNavigate();
  const [button, setButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dashboardAllow, setDashboardAllow] = useState(false);
  const ToolTips = [
    "If you have multiple years of work experience, you should keep this section to highlight your function or domain expertise, strengths & achievements and major projects exposed to in a nutshell. Not advisable to have this section if you have less than 4 years of experience",
    "This section should be there if you have extraordinary scholastic achievements like Scholarships, Class topper, Competitive Exams, etc",
    "Use this section to write about the full time Summer Internship project done during your MBA program. Summer Internship projects are usually done at the end of first year of MBA and before starting the second year. Do not write your UG projects, academic projects or other part time projects conducted (use Projects section to write all other type of projects).First year MBA students would not need this section",
    "If you have full time work experience after studies, you should use this section to write about it. Projects or Internships done alongside your studies should be written in Projects or Internships",
    "All projects done as part of academics, live projects done with company, other projects or assignments should be written in this section. Do not write full time internships done during your UG or MBA here (use Summer Internship Experience or UG Internships)",
    "Certifications done outside academics should be mentioned here. Courses done with certifying agencies like Coursera, Udemy, Swayam , Google, LinkedIn etc can be added in this section",
    "Key positions handled in college clubs or committee, society or local community, NGOs or other social groups, Rotaract, Toastmasters etc should be mentioned in this section. It is important to note that key positions held only to be written here. If you were only one of the members with no significant people/team responsibility, mention the point in extra curricular section",
    "Participations, membership in club and committee, other non academic relevant activates to be mentioned in this section. Language knowhow can also be mentioned here",
    "This should be used to highlight 10th, 12th, Graduation, Postgraduation and any other full time coursework done like CA",
  ];
  //for the  done button to be normal ((and not outline-dark no borders))
  const doneButton = useRef();

  const [disableAddButton, setDisableAddButton] = useState(false);
  // const [hideAdd, setHideAdd] = useState(false);
  const [addButtonText, setAddButtonText] = useState("ADD MORE SECTIONS"); //previously ADD MORE SECTIONS
  // const [resume_id, setResume_id] = useState(81);
  const [isPost, setIsPost] = useState(1);
  // let isdragable = true;
  // const [isEdit, setIsEdit] = useState({ "0": false, "1": true, "2": false, "3": false, "4": true, "5": true, "6": true, "7": true })
  const [resumeVerification, setResumeVerification] = useState(false);
  // const [allow_disable_sharing, setallow_disable_sharing] = useState(false);
  const [uploadProofCheck, setUploadProofCheck] = useState(false);
  console.log("uploadProofCheck:", uploadProofCheck);
  const [disableNext, setDisableNext] = useState(false);
  const [sectionEntireData, setSectionEntireData] = useState(section1);
  console.log("section details", sectionEntireData);
  const [isEdit, setIsEdit] = useState(false);
  const [add_new_section, setadd_new_section] = useState(false);
  const [is_dnd, setIsDnd] = useState(false);
  const [allow_disable_sharing, setAllowed_disable_sharing] = useState(false);
  const [sectionDetails, setSectionDetails] = useState(section1.section);

  const [modalShow, setModalShow] = useState(false);
  const [modalStudentViewShow, setModalStudentViewShow] = useState(false);
  const resume_id = parseInt(searchParams.get("resume_id"));
  const [disableMainButtons, setDisableMainButtons] = useState(false);
  var isActive = 100;
  console.log("add_new_section", add_new_section);
  const [resumesDigitalAnalyticsData, setResumesDigitalAnalyticsData] = useState([]);
  const [resumesDigitalVisible, setResumesDigitalVisible] = useState(true)
  // handle base template selection
  const handleBaseTemplate = (e, key) => {
    setBaseTemplate(key);
    console.log("template choosen", key);
  };

  const handleBaseTemplateLock = () => {
    setIsBaseTemplateLocked(1);
    const updatedTemplates = { ...templatesAvailable };
    updatedTemplates[baseTemplate].is_locked = 1;
    console.log("is_locked up", updatedTemplates);
    // Send the updated is_locked value to the backend
    // const isLockedValue = updatedTemplates[baseTemplate].is_locked;
  };

  useEffect(() => {
    const handelEffect = async () => {
      console.log(cookies);
      if (cookies === null) {
        navigate("/Login");
      }
      if (resume_id == null) {
        navigate("/Dashboard");
      }
      setLoader(true);
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      try {
        const templateGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping?process_id=${process_id}`, { headers });
        console.log("getter data", templateGet.data.data);
        // console.log("getter1111", JSON.parse(templateGet.data.data[0].section_params))
        let arrayDataGetter = templateGet.data.data.length;
        if (arrayDataGetter === 0) {
          setModalChangeTemplate(true);
        }
        setLockVerification({
          basic_info: templateGet.data.data[0].verification_params ? JSON.parse(templateGet.data.data[0].verification_params).basic_info : false,
          bullet: templateGet.data.data[0].verification_params ? JSON.parse(templateGet.data.data[0].verification_params).bullet : false,
        });

        const sectionEntireDetailsGetter = JSON.parse(templateGet.data.data[0].section_params);
        const baseTemplateGetRequest = JSON.parse(templateGet.data.data[0].is_locked);
        setIsBaseTemplateLocked(baseTemplateGetRequest);
        setResumeVerification(templateGet.data.data.length > 0 ? (templateGet.data.data[0].verification_required === 0 ? false : true) : false);
        setDashboardAllow(templateGet.data.data.length > 0 ? (templateGet.data.data[0].dashboard_allowed === 0 ? false : true) : false);
        if(templateGet.data.data[0].dashboard_allowed === 1) {
          setResumesAnalyticsVisible(true)
        }
        // setallow_disable_sharing(templateGet.data.data.length > 0 ? (templateGet.data.data[0].allow_disable_sharing === 0 ? false : true) : false);
        setUploadProofCheck(templateGet.data.data.length > 0 ? ([null, undefined].includes(templateGet.data.data[0].supported_doc_required) ? false : templateGet.data.data[0].supported_doc_required === 0 ? false : true) : false);
        const sectionDetailsGetter = sectionEntireDetailsGetter.section;
        console.log("section detsila getting...", sectionDetailsGetter.section);
        console.log("section details from get", sectionDetailsGetter);
        // setSectionDetails(sectionDetailsGetter);
        console.log("section  details from get...", sectionEntireDetailsGetter);
        setBaseTemplate(templateGet.data.data[0].template_name);
        setSectionEntireData(sectionEntireDetailsGetter);
        setIsEdit(sectionEntireDetailsGetter.isEdit);
        setIsDnd(sectionEntireDetailsGetter.is_dnd);
        setAllowed_disable_sharing(sectionEntireDetailsGetter.allow_disable_sharing);
        setadd_new_section(sectionEntireDetailsGetter.add_new_section);
        if (sectionDetailsGetter.length > 0) {
          setIsPost(0);
          let list = [];
          let order_count = 0;

          for (var i in sectionDetailsGetter) {
            let json = {};
            for (var j in sectionDetailsGetter[i]) {
              json[j] = sectionDetailsGetter[i][j];
            }
            json["isEdit"] = true;
            console.log("listttt", list);
            console.log(json.section_order);
            list.splice(parseInt(json.section_order), 0, json);
            console.log(list);
          }

          setSectionDetails(list);
          console.log("list of section details", list);
        }

        if (templateGet.data.data[0].dashboard_allowed === 1) {
          let batchResumesDigitalGetter = await batchResumesDigitalProcessGetter()

          const processIds = batchResumesDigitalGetter.map(item => item.process_id);
          console.log("processIds", processIds);
          if (processIds.length > 0) {
            setResumesDigitalVisible(false)
            const resumeDigitalResult = await batchResumesDigitalAnalyticsDataGetter(processIds)
            setResumesDigitalAnalyticsData(resumeDigitalResult)
          }
        }
      } catch (error) {
        // setLoader(false);
        console.log(error);
      }
    };
    handelEffect();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log(`100 ${sectionDetails}`);
  }, [resumesAnalyticsVisible]);

  const handleResumeVerificationChange = (e) => {
    setResumeVerification(e.target.checked);
  };

  const batchResumesDigitalAnalyticsDataGetter = async (process_ids) => {
    try {
      const res = await service.get(`/analytics/user/alResumeProcess?process_ids=[${process_ids.join(',')}]`);
      console.log("analytics data response:", res.batchAnalysisData);
      return res.data;
    } catch (err) {
      console.log(err);
      window.alert(err.response.data.message)
    }
  };

  const batchResumesDigitalProcessGetter = async () => {
    try {
      const res = await service.get(`/user/processIdsForDashboard?ug_id=${batch_id}`);
      console.log("analytics data response:", res.prepared_data);
      return res.data;
    } catch (err) {
      console.log(err);
      window.alert(err.response.data.message)
    }
  };

  const handleResumeDashboardAllow = (e) => {
    setDashboardAllow(e.target.checked);
  };

  const handleAllowDisableSharingChange = (e) => {
    // setallow_disable_sharing(e.target.checked)
    const updatedIsDnD = e.target.checked;
    setAllowed_disable_sharing(updatedIsDnD);
    const updateDnDState = {
      ...sectionEntireData,
      allow_disable_sharing: updatedIsDnD,
      section: [...sectionDetails],
    };
    setSectionEntireData(updateDnDState);
  };
  const handleUploadProofChange = (e) => {
    setUploadProofCheck(e.target.checked);
  };

  console.log("allow_disable_sharing", allow_disable_sharing);

  // handle save modal
  function HandleEditUser(props) {
    return (
      <Modal className="myprofile_modal" {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered style={{ backdropFilter: "blur(5px)" }}>
        <Modal.Body
          style={{
            position: "absolute",
            left: "50%",
            height: "180px",
            transform: "translate(-50%, -50%)",
            lineHeight: "1.4",
            padding: "14px 28px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            maxWidth: "500px",
            minWidth: "400px",
            minHeight: "auto",
            border: "2px solid white",
          }}
        >
          <div className="row" style={{ margin: "auto", top: "20%" }}>
            <div className="headerPart">
              {/* <CloseIcon className="closeIcon" onClick={((e) => { setModalConfirmationShow(false) })} /> */}
              <div style={{ display: "flex", marginTop: "30px", margin: "0 auto" }}>
                <CheckCircleOutlineIcon style={{ height: "40px", width: "40px", color: "#561D5E", marginRight: "1rem" }} />
                <p className="DeleteConfirm1" style={{ fontSize: "24px", fontWeight: "normal", marginTop: ".5rem" }}>
                  Data has been Saved
                </p>
              </div>
            </div>

            <div className="form-group btns">
              <button
                type="button"
                className="Rephrasebutton"
                onClick={() => {
                  setModalConfirmationShow(false);
                }}
              >
                Edit again
              </button>
              <button type="button" className="RephrasebuttonNoBackground" onClick={() => {setModalConfirmationShow(false); setResumesAnalyticsVisible(true)}}>
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const baseTemplateSaveButton = (e) => {
    service
      .put("/user/processIdToParamsMapping", {
        process_id: process_id,
        template_name: baseTemplate,
        is_locked: isbaseTemplateLocked,
        verification_required: resumeVerification ? 1 : 0,
        dashboard_allowed: dashboardAllow ? 1 : 0,
        supported_doc_required: uploadProofCheck ? 1 : 0,
        verification_params: lockVerification,
      })
      .then((res) => {
        if (res.data.success) {
          setModalChangeTemplate(false);
          service.post("/user/setProcessParams", {
            process_id: process_id,
            template_name: baseTemplate,
            is_locked: isbaseTemplateLocked,
          });
        }
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message);
      });
  };

  function HandleSelectBaseTemplate(props) {
    return (
      <Modal className="myprofile_modal" {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered style={{ backdropFilter: "blur(5px)" }}>
        <Modal.Body
          style={{
            position: "absolute",
            left: "50%",
            height: "auto",
            transform: "translate(-50%, -50%)",
            lineHeight: "1.4",
            padding: "14px 28px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            maxWidth: "600px",
            minWidth: "450px",
            minHeight: "auto",
            border: "2px solid white",
          }}
        >
          <div className="row" style={{ margin: "auto", top: "20%" }}>
            <div>
              {/* <CloseIcon className="closeIcon" onClick={((e) => { setModalConfirmationShow(false) })} /> */}
              {/* <div style={{display:"flex",marginTop:"30px", margin:'0 auto'}}> */}
              {/* <CheckCircleOutlineIcon style={{height:"40px", width:"40px", color:"#561D5E", marginRight:"1rem"}}/> */}
              <p className="HeadingsBaseTemplate" style={{ fontSize: "24px", fontWeight: "normal", marginTop: ".5rem" }}>
                Select Base Template
              </p>
              <p className="subHeadingsBaseTemplate">Select the general template which will be used for the resume</p>
              {/* </div> */}
            </div>

            <div>
              {Object.keys(templatesAvailable).map((key) => (
                <button
                  key={key}
                  style={{ whiteSpace: "nowrap" }}
                  className={baseTemplate === key ? "saveButtonResumesDigitalBaseTemplateSelected" : "saveButtonResumesDigitalBaseTemplate"}
                  onClick={(e) => {
                    handleBaseTemplate(e, key);
                  }}
                >
                  {key}
                </button>
              ))}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button type="button" className="saveButtonResumesDigitalBaseTemplate1" onClick={(e) => baseTemplateSaveButton(e)}>
                  Save
                </button>
                <button onClick={() => handleBaseTemplateLock()} style={{ border: "none", backgroundColor: "transparent" }}>
                  {isbaseTemplateLocked === 0 ? <LockOpenIcon /> : <LockOutlinedIcon />}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(sectionDetails);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    console.log(e);
    setSectionDetails(tempData);
    sectionDetails[e.destination.index].section_order = e.source.index;
    sectionDetails[e.source.index].section_order = e.destination.index;
    if (e.destination.index > e.source.index) {
      for (var i = e.source.index; i < e.destination.index; i++) {
        sectionDetails[i + 1].section_order = i;
        console.log(sectionDetails[i].section_order);
        console.log(sectionDetails[i].section_name);
      }
    } else {
      for (var i = e.destination.index; i < e.source.index; i++) {
        sectionDetails[i].section_order = i + 1;
        console.log(sectionDetails[i].section_order);
        console.log(sectionDetails[i].section_name);
      }
    }
    const updateEditState = {
      ...sectionEntireData,
      section: tempData,
    };
    setSectionEntireData(updateEditState);
  };

  const handleToggle = (id) => {
    console.log("toggle switch");
    console.log(sectionDetails.filter((sectionDetail) => sectionDetail.on_off_locked).length, sectionDetails);
    if (sectionDetails.filter((sectionDetail) => sectionDetail.on_off_locked).length > 3 || !sectionDetails[id].on_off_locked) {
      let objIndex = id;
      let isOn = sectionDetails[objIndex].on_off_locked;
      setSectionDetails(sectionDetails.map((sectionDetail) => (sectionDetail.section_order == id ? { ...sectionDetail, on_off_locked: !isOn } : { ...sectionDetail })));
      const updateEditState = {
        ...sectionEntireData,
        section: sectionDetails.map((sectionDetail) => (sectionDetail.section_order == id ? { ...sectionDetail, on_off_locked: !isOn } : { ...sectionDetail })),
      };
      setSectionEntireData(updateEditState);

      console.log(sectionDetails);
    } else {
      window.alert("minimum 3 section be on");
    }
  };

  // useEffect
  // useEffect(() => {
  //     const updatedSectionEntireDetails = {
  //         section: sectionDetails
  //       };
  //     setSectionEntireData(updatedSectionEntireDetails);
  //   }, [sectionDetails]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    // console.log(e);
    console.log(sectionDetails);
    const list = [...sectionDetails];
    console.log(isActive);
    // if(!isEdit[index])  //@ashish
    // {
    list[index].section_name = value;
    setSectionDetails(list);
    // }
  };
  const handleEdit = (index) => {
    if (sectionDetails[index].isEdit) {
      isActive = index;
      console.log(isActive);

      const isEdit_value = !sectionDetails[index].isEdit;
      setSectionDetails(sectionDetails.map((sectionDetail) => (sectionDetail.section_order === index ? { ...sectionDetail, isEdit: isEdit_value } : { ...sectionDetail, isEdit: true })));

      console.log(sectionDetails);

      if (isEdit_value === false) {
        setDisableMainButtons(true);
      } else if (isEdit_value === true) {
        setDisableMainButtons(false);
      }
    } else {
      if (sectionDetails[index].section_name.replaceAll(" ", "").length > 0) {
        isActive = index;
        console.log(isActive);

        const isEdit_value = !sectionDetails[index].isEdit;
        setSectionDetails(sectionDetails.map((sectionDetail) => (sectionDetail.section_order === index ? { ...sectionDetail, isEdit: isEdit_value } : { ...sectionDetail, isEdit: true })));
        const updateEditState = {
          ...sectionEntireData,
          section: sectionDetails.map((sectionDetail) => (sectionDetail.section_order === index ? { ...sectionDetail, isEdit: isEdit_value } : { ...sectionDetail })),
        };
        setSectionEntireData(updateEditState);
        console.log(sectionDetails);

        if (isEdit_value === false) {
          setDisableMainButtons(true);
        } else if (isEdit_value === true) {
          setDisableMainButtons(false);
        }
      } else {
        toast.error("Name of section cannot be empty");
      }
    }
  };

  const handlePutRequest = async (temp1) => {
    let temp = temp1 === undefined ? { ...sectionEntireData } : { ...temp1 };
    temp.section =
      temp1 === undefined
        ? sectionDetails.map((item) => {
          const { section_id, ...rest } = item;
          return rest;
        })
        : temp1.section.map((item) => {
          const { section_id, ...rest } = item;
          return rest;
        });
    console.log("temp_section", sectionEntireData, temp);
    service
      .put("/user/processIdToParamsMapping", {
        process_id: process_id,
        section_params: temp,
        verification_required: resumeVerification ? 1 : 0,
        dashboard_allowed: dashboardAllow ? 1 : 0,
        supported_doc_required: uploadProofCheck ? 1 : 0,
        verification_params: lockVerification,
      })
      .then((res) => {
        setSectionDetails((prev) => {
          return prev.map((item) => {
            let temp = { ...item };
            delete temp["is_new"];
            return temp;
          });
        });
        res.data.success ? setModalConfirmationShow(true) : console.log("Failed Operation");
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message);
      });
  };

  function AddButton(props) {
    console.log(props.index);
    setButton(false);
    return (
      <button style={{ border: "none", fontSize: "16px", color: "rgb(86, 29, 94)", backgroundColor: "transparent" }} onClick={(e) => handleAddButton(props.index)} className="add">
        <AddOutlined />
      </button>
    );
  }

  function DeleteButton(props) {
    console.log(props.index);
    return (
      <Button type="button" class="btn" style={{ backgroundColor: "transparent", color: "rgb(86, 29, 94)", border: "none", marginTop: "-5%" }} onClick={(e) => handleDeleteButton(props.index)}>
        <i class="fa-solid fa-trash"></i>
      </Button>
    );
  }

  const handleAddButton = (index) => {
    let json = { ...sectionDetails[index] };
    json["section_name"] = "Copy of " + sectionDetails[index]["section_name"];
    json["on_off_locked"] = true;
    json["section_order"] = sectionDetails.length;
    json["section_type_id"] = sectionDetails[index].section_type_id;
    json["isEdit"] = true;
    json["section_id"] = null;
    json["is_primary"] = 11;
    json["admin_add"] = false;
    json["is_locked"] = false;
    json["is_new"] = true;

    sectionDetails[index].isAdd = false;

    sectionDetails.push(json);

    setSectionDetails(sectionDetails.map((sectionDetail) => (sectionDetail.section_type_id === 1000 ? { ...sectionDetail, isAdd: true } : { ...sectionDetail })));
  };

  const deleteSectionFromBackend = async (section_id) => {
    try {
      setLoader(true);
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      const deleteResponse = await axios.delete(`${process.env.REACT_APP_API_URL}/user/section`, { data: { resume_id: resume_id, section_ids: [section_id] }, headers: headers });
      setLoader(false);
      return deleteResponse.status === 200 ? true : false;
    } catch {
      setLoader(false);
      console.log("Unable to delete the section!!!! @gaurav");
      return false;
    }
  };

  const handleDeleteButton = async (index) => {
    const section_id_to_delete = sectionDetails[index].section_id;
    const new_section = sectionDetails.filter((_, i) => i !== index);
    let temp = { ...sectionEntireData };
    temp.section = new_section;

    // let deleted_from_database = false;
    if (section_id_to_delete !== null) {
      await handlePutRequest(temp);
      setSectionDetails((sectionDetail) => (sectionDetail = [...new_section]));
    }
    // if (deleted_from_database) {
    else if (section_id_to_delete === null) {
      // const sti = sectionDetails[index].section_type_id;
      // sectionDetails[]
      setSectionDetails((sectionDetail) => (sectionDetail = [...new_section]));
    } else {
      window.alert("Something went wrong, please check your connectivity or report this issue!");
    }
  };

  function MyStudentViewModal(props) {
    return (
      <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="FileUploadModal" style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}>
        <Modal.Body
          style={{
            position: "absolute",
            left: "50%",
            height: "auto",
            transform: "translate(-50%, -50%)",
            lineHeight: "1.4",
            padding: "14px 28px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            maxWidth: "100%",
            minWidth: "1000px",
            minHeight: "400px",
            top: "60%",
            margin: "0 auto",
          }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <table className="resume-template-content-modal1" style={{ borderCollapse: "collapse" }}>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <>
                    <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                      {sectionDetails?.map((item, index) => (
                        <>
                          {/* {index === 0 &&
                                                <div style={{ display: 'flex', justifyContent: "space-between", marginRight: "9.5rem" }}>
                                                    <button onClick={() => handleDnDDisable()} style={{ border: 'none', backgroundColor: "transparent" }}>
                                                        {is_dnd === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                                                    </button>
                                                    <button onClick={() => handleSectionNameEditDisable()} style={{ border: 'none', backgroundColor: "transparent" }}>
                                                        {isEdit === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                                                    </button>
                                                </div>} */}
                          <Draggable key={item.section_name} draggableId={item.section_name} index={index} isDragDisabled={disableMainButtons}>
                            {(provider) => (
                              <>
                                <tr {...provider.draggableProps} ref={provider.innerRef} className="table-row" id="resume-template-tr">
                                  <div style={{ display: "flex", width: "max(95%,690px)", color: "caret-color" }}>
                                    <td className="icon" {...provider.dragHandleProps} style={{ padding: "2px", width: "2%" }}>
                                      {!disableMainButtons ? <MenuOutlinedIcon style={{ marginLeft: "0px", marginTop: ".5rem", color: "#561D5E" }} /> : <MenuOutlinedIcon style={{ marginLeft: "0px", marginTop: ".5rem", color: "#561D5E" }} />}
                                    </td>
                                    {item.isEdit === false ? (
                                      <>
                                        <td class="tooltip_css" style={{ marginTop: "1.7%", marginLeft: "20px" }}>
                                          <i style={{ marginLeft: "0px", marginTop: "1.5%", color: "#561D5E" }} class="fa-solid fa-circle-info"></i>
                                          <span class="tooltiptext" style={{ textTransform: "none" }}>
                                            {ToolTips[index]}{" "}
                                          </span>
                                        </td>
                                        <td className="sub-heading" style={{ marginLeft: "15px", marginTop: "0.8%" }}>
                                          <input value={item.section_name} className="resume-template-input" onChange={(e) => handleChange(e, index)} disabled={item.isEdit} ref={(ref) => ref && ref.focus()} style={{ fontSize: "16px" }} onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)} />
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <div className="sub-heading" style={{ display: "flex", marginLeft: "0px", marginTop: "-0.18%" }}>
                                          <div class="tooltip_css" style={{ marginTop: "0.7%" }}>
                                            <i style={{ marginLeft: "0px", marginTop: "1.5%", color: "#561D5E" }} class="fa-solid fa-circle-info"></i>
                                            <span class="tooltiptext" style={{ textTransform: "none" }}>
                                              {ToolTips[index]}{" "}
                                            </span>
                                          </div>
                                          <p style={{ fontSize: "16px", float: "left", marginTop: "0.6%", marginLeft: "25px" }}>{item.section_name}</p>
                                        </div>
                                      </>
                                    )}

                                    <td style={{ width: "10%", marginTop: "1.3%", textAlign: "center", color: "rgb(86, 29, 94)" }}>
                                      {item.is_primary === 1 && item.admin_add === true && <AddButton index={index} />}

                                      {item.is_primary === 11 && <DeleteButton index={index} />}
                                    </td>
                                    <td className="icon" style={{ height: "28px", justifyContent: "space-evenly", alignItems: "center", width: "7%", marginTop: "1.2%" }}>
                                      <button onClick={(e) => handleEdit(index)} disabled={disableAddButton} className="editOrSave-btn" style={{ border: "none" }}>
                                        {item.isEdit === true && disableMainButtons && <Edit style={{ color: Color.primary1 }} />}

                                        {item.isEdit === true && !disableAddButton && !disableMainButtons && <Edit style={{ color: Color.primary1 }} />}

                                        {item.isEdit === false && disableAddButton === true && <h6 style={{ color: Color.neutralLightGrey }}>Save</h6>}
                                        {item.isEdit === false && disableAddButton === false && <h6 style={{ color: Color.accent }}>Save</h6>}
                                      </button>
                                    </td>
                                    {!disableMainButtons ? (
                                      <>
                                        <td className="icon" style={{ paddingTop: "1%", marginTop: "0.3%" }}>
                                          <Switch id={index} isToggled={item.on_off_locked} onToggle={handleToggle} disable={disableAddButton} />
                                        </td>
                                        {/* <td style={{ marginLeft: "2rem", textAlign: 'center', marginTop: ".5rem" }}>
                                                                            <button onClick={() => handleSectionsLock(index)} style={{ border: 'none', backgroundColor: "transparent" }}>
                                                                            {item.is_locked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                                                                        </button></td> */}
                                      </>
                                    ) : (
                                      <>
                                        <td className="icon" style={{ paddingTop: "1%", marginTop: "0.3%" }}>
                                          <Switch id={index} isToggled={item.on_off_locked} disable={true} />
                                        </td>
                                        <td style={{ marginLeft: "2rem", textAlign: "center", marginTop: ".5rem" }}>
                                          <i className="fa-solid fa-lock" style={{ fontSize: "22px", width: "22px", height: "22px" }}></i>
                                        </td>
                                      </>
                                    )}
                                  </div>
                                </tr>
                              </>
                            )}
                          </Draggable>
                        </>
                      ))}
                      {provider.placeholder}
                    </tbody>
                  </>
                )}
              </Droppable>
              <tr style={{ margin: "0 auto", width: "100%" }}>
                {add_new_section === true ? (
                  <button
                    ref={doneButton}
                    onClick={(e) => {
                      handleOpenDialog();
                    }}
                    style={{ backgroundColor: "white", color: "#0061D0", fontSize: "14px", borderShadow: "0 0 0", focus: "none", alignItems: "center", justifyContent: "center", border: "none", margin: "0 auto" }}
                  >
                    <i class="fa-solid fa-plus" style={{ color: Color.accent, justifyContent: "center", alignItems: "center", margin: "0 auto", width: "30px", height: "30px", marginLeft: "300px", paddingTop: "20px" }}></i>
                  </button>
                ) : (
                  <></>
                )}
              </tr>
            </table>
          </DragDropContext>
        </Modal.Body>
      </Modal>
    );
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        // fullscreen={true}
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ justifyContent: "center", alignItems: "center", margin: "0 0 0 18%" }}>
            NOT RECOMMENDED ACTION!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Enough sections are already provided by default. Adding more sections will clutter your resume leaving less space to write high impact content. Before adding a new section, please check if you have used the existing sections appropriately.
            <br />
            <br />
            To ensure uniformity in design and content, only a few sections can be duplicated. Duplicate the one which is closest to your new section requirement.
            <br />
            <br />
            Are you sure you want to add more sections?
            <br />
            <br />
          </p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center", alignItems: "center" }}>
          <Button onClick={(e) => handleModalYes(e)} variant="dark" className="modal-yes-btn">
            Yes
          </Button>
          <Button onClick={props.onHide} variant="dark" className="modal-no-btn">
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDashboardNavigate = () => {
    console.log("resumesDigitalAnalyticsData", resumesDigitalAnalyticsData);

    if (resumesDigitalAnalyticsData.length === 0) {
      window.alert("No students present")
    } else {
      navigate("/ResumesDigitalAnalytics", { state: {  resumesDigitalAnalyticsData: resumesDigitalAnalyticsData , fromResumesTemplatePath :true , college_name: college_name, batch_name: batch_name, process_id:process_id} })
    }
  }

  const handleModalYes = (e, index) => {
    doneButton.current.style.backgroundColor = "black";
    doneButton.current.style.color = "white";
    doneButton.current.style.fontSize = "17px";
    setOpenDialog(false);
    setDisableAddButton(true);
    setDisableNext(true);
    setAddButtonText("DONE");
    console.log(sectionDetails);
    // !@abhra
    setSectionDetails(sectionDetails.map((sectionDetail) => ([5, 6, 8].includes(sectionDetail.section_type_id) ? (sectionDetail.is_primary === 1 ? { ...sectionDetail, isAdd: true } : sectionDetail.is_primary === 0 ? { ...sectionDetail, isDelete: true } : { ...sectionDetail }) : { ...sectionDetail })));
    console.log(sectionDetails);
  };

  // console.log("sectionDetails...", sectionDetails.is_dnd);

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const dialogActions = [
    { label: "Cancel", handler: handleCloseDialog, color: Color.accent, variant: "outlined" },
    { label: "Confirm", handler: handleModalYes, color: Color.accent, variant: "contained" },
  ];

  const handleDnDDisable = () => {
    const updatedIsDnD = !is_dnd;
    setIsDnd(updatedIsDnD);
    const updateDnDState = {
      ...sectionEntireData,
      is_dnd: updatedIsDnD,
      section: [...sectionDetails],
    };
    setSectionEntireData(updateDnDState);
    console.log("updateDnDState", updateDnDState, updatedIsDnD);
  };

  const handleSectionNameEditDisable = () => {
    const updatedIsEmail = !isEdit;
    setIsEdit(updatedIsEmail);
    const updateEditState = {
      ...sectionEntireData,
      isEdit: updatedIsEmail,
      section: [...sectionDetails],
    };
    setSectionEntireData(updateEditState);
    console.log("updateDnDState", updateEditState, updatedIsEmail);
  };

  const handleSectionsLock = (id) => {
    console.log("id...", id);
    console.log(sectionDetails.filter((sectionDetail) => sectionDetail.is_locked).length, sectionDetails);
    // if (!sectionDetails[id].is_locked) {
    let objIndex = id;
    let isOn = sectionDetails[objIndex].is_locked;
    setSectionDetails(sectionDetails.map((sectionDetail) => (sectionDetail.section_order == id ? { ...sectionDetail, is_locked: !isOn } : { ...sectionDetail })));
    const updateEditState = {
      ...sectionEntireData,
      section: sectionDetails.map((sectionDetail) => (sectionDetail.section_order === id ? { ...sectionDetail, is_locked: !isOn } : { ...sectionDetail })),
    };
    setSectionEntireData(updateEditState);
    console.log(sectionDetails);
    // } else {
    //     window.alert("minimum 4 section be on")
    // }

    console.log("updateDnDState", sectionDetails);
  };

  const handleVerification = (e) => {
    let temp = { ...lockVerification };
    temp[e.target.name] = e.target.checked;
    setLockVerification(temp);
  };

  const handleAddNewSectionLock = () => {
    const updatedAddNewSEction = !add_new_section;
    setadd_new_section(updatedAddNewSEction);
    const updateEditState = {
      ...sectionEntireData,
      add_new_section: updatedAddNewSEction,
      section: [...sectionDetails],
    };
    setSectionEntireData(updateEditState);
  };

  console.log("section entire details", sectionEntireData);
  console.log("resumesDigitalAnalyticsData",resumesDigitalAnalyticsData);
  
  return (
    <div className="resume-template-complete">
      <ToastContainer position="top-center" />
      <SideNavigationBar />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Box sx={{ display: "flex" }}>
          <table className="resume-template-content" style={{ borderCollapse: "collapse" }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: "0 auto" }}>
              <button onClick={() => navigate(-1, { state: { process_id: process_id, college_name: college_name, batch_name: batch_name, batch_id: batch_id } })} className="signup-button">
                {" "}
                Previous
              </button>
              <p style={{ fontSize: "32px", textAlign: "center", marginTop: "58px", marginBottom: "58px" }}>Select your sections</p>
              <button onClick={() => navigate("/Settings?process_id=" + process_id, { state: { process_id: process_id, sectionDetails: sectionDetails, baseTemplate: baseTemplate, sectionEntireData: sectionEntireData, lockVerification: lockVerification } })} className="signup-button">
                Next
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <Checkbox checked={resumeVerification} style={{ color: "#561D5E" }} onChange={(e) => handleResumeVerificationChange(e)} />
                <Typography sx={{ userSelect: "none", fontSize: "13px" }}>Make Resume Verification Compulsory</Typography>
              </div>

              <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <Checkbox disabled={resumeVerification === false ? true : false} checked={uploadProofCheck} style={{ color: resumeVerification === false ? Color.neutralMidGrey : "#561D5E" }} onChange={(e) => handleUploadProofChange(e)} />
                <Typography sx={{ userSelect: "none", fontSize: "13px", color: resumeVerification === false ? Color.neutralMidGrey : "black" }}>Make Upload Proof Compulsory</Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <Checkbox checked={allow_disable_sharing} style={{ color: "#561D5E" }} onChange={(e) => handleAllowDisableSharingChange(e)} />
                <Typography sx={{ userSelect: "none", fontSize: "13px" }}>Allow Disable Sharing</Typography>
              </div>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox name="basic_info" checked={lockVerification.basic_info} style={{ color: lockVerification.basic_info === false ? Color.neutralMidGrey : "#561D5E" }} onChange={(e) => handleVerification(e)} />
                <Typography sx={{ fontSize: "13px" }}>Lock basic Info Editing </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox name="bullet" checked={lockVerification.bullet} style={{ color: lockVerification.bullet === false ? Color.neutralMidGrey : "#561D5E" }} onChange={(e) => handleVerification(e)} />
                <Typography sx={{ fontSize: "13px" }}>Lock Bullet Editing </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox name="bullet" checked={dashboardAllow} style={{ color: "#561D5E" }} onChange={(e) => handleResumeDashboardAllow(e)} />
                <Typography sx={{ fontSize: "13px" }}>Allow Dashboard </Typography>
              </Box>
              {dashboardAllow && resumesAnalyticsVisible && (
                <div>
                  <button
                    className="saveButtonResumesDigital" 
                    onClick={() => {
                      handleDashboardNavigate();
                    }}
                    style={{height:"35px", width:"auto",padding: "0 10px"}}
                  >
                    Analytics
                  </button>
                </div>

              )}
            </div>
            <Divider sx={{ borderColor: Color.blueBlack, marginBottom: "1rem" }} />
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <>
                  <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                    {sectionDetails?.map((item, index) => (
                      <>
                        {index === 0 && (
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <button onClick={() => handleDnDDisable()} style={{ border: "none", backgroundColor: "transparent" }}>
                              {is_dnd === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                            </button>
                            <p style={{ marginLeft: "54rem", fontSize: "14px", color: "#561D5E" }}>Disable</p>
                            <button onClick={() => handleSectionNameEditDisable()} style={{ border: "none", backgroundColor: "transparent" }}>
                              {isEdit === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                            </button>
                          </div>
                        )}

                        <Draggable key={item.section_name} draggableId={item.section_name} index={index} isDragDisabled={disableMainButtons}>
                          {(provider) => (
                            <>
                              <tr {...provider.draggableProps} ref={provider.innerRef} className="table-row" id="resume-template-tr">
                                <div style={{ display: "flex", width: "max(100%,690px)", color: "caret-color" }}>
                                  <td className="icon" {...provider.dragHandleProps} style={{ padding: "2px", width: "2%" }}>
                                    {!disableMainButtons ? <MenuOutlinedIcon style={{ marginLeft: "0px", marginTop: ".5rem", color: "#561D5E" }} /> : <MenuOutlinedIcon style={{ marginLeft: "0px", marginTop: ".5rem", color: "#561D5E" }} />}
                                  </td>
                                  {item.isEdit === false ? (
                                    <>
                                      {/* <td class="tooltip_css" style={{ marginTop: '1.7%', marginLeft: "20px" }}>
                                                                        
                                                                        <i style={{ marginLeft: "0px", marginTop: "1.5%", color: "#561D5E" }} class="fa-solid fa-circle-info"></i>
                                                                        <span class="tooltiptext" style={{ textTransform: "none" }}>{ToolTips[index]} </span>

                                                                    </td> */}
                                      <td className="sub-heading" style={{ marginLeft: "15px", marginTop: "0.8%" }}>
                                        {/* <label for="name">Name</label> */}
                                        <input value={item.section_name} className="resume-template-input" onChange={(e) => handleChange(e, index)} disabled={item.isEdit} ref={(ref) => ref && ref.focus()} style={{ fontSize: "16px" }} onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)} />
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <div className="sub-heading" style={{ display: "flex", marginLeft: "0px", marginTop: "-0.18%" }}>
                                        {/* <div class="tooltip_css" style={{ marginTop: '0.7%' }}>
                                                                                
                                                                                <i style={{ marginLeft: "0px", marginTop: "1.5%", color: "#561D5E" }} class="fa-solid fa-circle-info"></i>
                                                                                <span class="tooltiptext" style={{ textTransform: "none" }}>{ToolTips[index]} </span>
                                                                            </div> */}
                                        <p style={{ fontSize: "16px", float: "left", marginTop: "0.6%", marginLeft: "25px" }}>{item.section_name}</p>
                                      </div>
                                    </>
                                  )}

                                  <td style={{ width: "10%", marginTop: "1.3%", textAlign: "center", color: "rgb(86, 29, 94)" }}>
                                    {item.is_primary === 1 && <AddButton index={index} />}

                                    {item.is_primary === 11 && item.is_new && <DeleteButton index={index} />}
                                  </td>
                                  <td>
                                    <Checkbox checked={item.is_disabled} style={{ color: "#561D5E", marginTop: ".4rem", marginRight: ".5rem" }} onChange={(e) => handleCheckBoxChange(e, item.section_type_id)} />
                                  </td>
                                  <td className="icon" style={{ height: "28px", justifyContent: "space-evenly", alignItems: "center", width: "7%", marginTop: "1.2%" }}>
                                    <button onClick={(e) => handleEdit(index)} disabled={disableAddButton} className="editOrSave-btn" style={{ border: "none" }}>
                                      {item.isEdit === true && disableMainButtons && <Edit style={{ color: Color.neutralLightGrey }} />}

                                      {item.isEdit === true && !disableAddButton && !disableMainButtons && <Edit style={{ color: "#561D5E" }} />}

                                      {item.isEdit === false && disableAddButton === true && <h6 style={{ color: Color.neutralLightGrey }}>Save</h6>}
                                      {item.isEdit === false && disableAddButton === false && <h6 style={{ color: Color.accent }}>Save</h6>}
                                    </button>
                                  </td>
                                  {!disableMainButtons ? (
                                    <>
                                      <td className="icon" style={{ paddingTop: "1%", marginTop: "0.3%" }}>
                                        <Switch id={index} isToggled={item.on_off_locked} onToggle={handleToggle} disable={disableAddButton} />
                                      </td>
                                      <td style={{ marginLeft: "2rem", textAlign: "center", marginTop: ".5rem" }}>
                                        <button onClick={() => handleSectionsLock(index)} style={{ border: "none", backgroundColor: "transparent" }}>
                                          {item.is_locked === false ? <LockOpenIcon /> : <LockOutlinedIcon />}
                                        </button>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="icon" style={{ paddingTop: "1%", marginTop: "0.3%" }}>
                                        <Switch id={index} isToggled={item.on_off_locked} disable={true} />
                                      </td>
                                      <td style={{ marginLeft: "2rem", textAlign: "center", marginTop: ".5rem" }}>
                                        <i className="fa-solid fa-lock" style={{ fontSize: "22px", width: "22px", height: "22px" }}></i>
                                      </td>
                                    </>
                                  )}
                                </div>
                              </tr>
                            </>
                          )}
                        </Draggable>
                      </>
                    ))}
                    {provider.placeholder}
                    <tr style={{ display: "flex", width: "max(100%, 800px)", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
                      <td className="sub-heading" style={{ width: "95.5%" }}>
                        Add new Section
                      </td>
                      <td style={{ marginBottom: "1.5rem", width: "4.5%" }}>
                        <Switch isToggled={add_new_section} onToggle={handleAddNewSectionLock} disable={disableAddButton} />
                      </td>
                      {/* <td></td> */}
                    </tr>
                    <tr style={{ display: "flex", width: "max(100%, 800px)", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
                      <td align="center" className="sub-heading" style={{ justifyContent: "center", margin: "0 auto", textAlign: "center" }}>
                        <button
                          className="signup-button"
                          onClick={() => {
                            setModalChangeTemplate(true);
                          }}
                        >
                          Change Base Template
                        </button>
                      </td>
                    </tr>
                    <tr style={{ display: "flex", width: "max(100%, 800px)", justifyContent: "center", alignItems: "center", margin: "0 auto", marginTop: ".5rem" }}>
                      <td className="sub-heading">
                        <button
                          className="signup-button"
                          style={{ marginLeft: "0px" }}
                          onClick={() => {
                            setModalStudentViewShow(true);
                          }}
                        >
                          Student View
                        </button>
                      </td>
                      <td>
                        <button
                          className="saveButtonResumesDigital"
                          onClick={() => {
                            handlePutRequest();
                          }}
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
            </Droppable>
          </table>
        </Box>
      </DragDropContext>




      <CustomizedSmallDialogs
        open={openDialog}
        onClose={handleCloseDialog}
        title="NOT RECOMMENDED ACTION!"
        // content="This is a custom dialog component."
        actions={dialogActions}
        content={
          <p>
            Enough sections are already provided by default. Adding more sections will clutter your resume leaving less space to write high impact content. Before adding a new section, please check if you have used the existing sections appropriately.
            <br />
            <br />
            To ensure uniformity in design and content, only a few sections can be duplicated. Duplicate the one which is closest to your new section requirement.
            <br />
            <br />
            Are you sure you want to add more sections?
          </p>
        }
      />
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
      <MyStudentViewModal show={modalStudentViewShow} onHide={() => setModalStudentViewShow(false)} />
      <HandleEditUser
        show={modalConfirmationShow}
        onHide={() => {
          setModalConfirmationShow(false);
        }}
      />
      <HandleSelectBaseTemplate
        show={modalChangeTemplate}
        onHide={() => {
          setModalChangeTemplate(false);
        }}
      />
    </div>
  );
}
