import React from "react";
import { useState, useEffect } from "react";
function Intership(props) {
    // const name = props.name;
    const  section_detail =  {
        
        "section_name": "Profile Summary",
        
       
    }
    const Non_AI_all_bullets = [
        {bullet_id: 1210, bullet_content: 'Profile Summary bullet 1', achievement_year: '2000', bullet_order: 0},
        {bullet_id: 1211, bullet_content: 'Profile Summary bullet 2', achievement_year: '2000', bullet_order: 1},
        {bullet_id: 1215, bullet_content: 'Profile Summary bullet 3', achievement_year: '2000', bullet_order: 2}
    ]
    console.log("section_detail", section_detail)
    // console.log("Role", Non_AI_all_subSection)
    console.log("All bullets", Non_AI_all_bullets)
    let years = []
    let Details = []
    // if ((Non_AI_all_subSection && Non_AI_all_subSection?.length > 0)) {
        
            Details.push({
                // Role: (
                //     <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS }}>
                //         {value?.bi?.length > 0 ? value.bi : ""}
                //     </p>
                // ),
                Desc: (
                    <p style={{ textAlign: "justify", marginBottom: '0px' ,padding:props.minipadding? props.minipadding : "0.16rem"}}>
                        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                            {
                                (Non_AI_all_bullets && Non_AI_all_bullets.length > 0) ? Non_AI_all_bullets.map((bullet) => {
                                    return bullet?.bullet_content?.length > 0 ?
                                        <li style={{ listStyleType: Non_AI_all_bullets ? "" : 'none', fontFamily: props.fontS }}>
                                            {bullet?.bullet_content} 
                                        </li>
                                        : <></>
                                }
                                ) : []
                            }
                        </ul>
                    </p>
                ),
                // Year: (
                //     (Non_AI_all_bullets && Non_AI_all_bullets.length > 0) ? Non_AI_all_bullets[index].map((bullet2) => {
                //         return bullet2?.achievement_year ?
                //             <p style={{ listStyleType: Non_AI_all_bullets ? "" : 'none', fontFamily: props.fontS }}>
                //                 {years = bullet2?.achievement_year}
                //             </p>
                //             : <></>
                //     }
                //     ) : []
                // ),
            })
    // }
    // console.log(section_detail.s)
    return (
        <div>
            <table
                className="IntershipBox New ResumeTable overlapping-table"
                // style={{ width: props.TableWidth ? "105%" : "100%" }}
                style={{
                    width: props.minwidth ? props.minwidth : props.TableWidth,
                    // marginRight: '2%', margin:'auto'
                    // width:props.minwidth+"%",
                }}
            // style={{ width: props.widths+"px" }}
            >
                <tr>
                    <th className="Resumetable-th"
                        id="SIP"
                        colspan="6"
                        style={{
                            backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
                            color: "#000",
                            fontSize: props.FontSize,
                            maxWidth: "274px",
                            fontFamily: props.fontS,
                            border: "0.1px solid rgb(0 0 0)",
                            textAlign: props.headerPosition,
                            height: props.miniheight,
                            // height: '25px',
                            fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                            padding: props.minipadding,
                            lineHeight: props.LineHeight
                        }}
                    >
                        {section_detail?.section_name ? section_detail.section_name : "Summer Internship Experience"}
                        {/* SUMMER INTERNSHIP PROGRAMME */}
                        {/* {props.name ? props.name : 'Summer Internship Experience'} */}
                        {/* {props.text5} */}
                    </th>
                </tr>



                {Details.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td className="ResumeTable-td"
                                colSpan={4}
                                style={{
                                    // fontSize: props.FontSmall,
                                    fontSize: props.FontSize,
                                    fontFamily: props.fontS,
                                    color: "#000",
                                    border: "0.1px solid rgb(0 0 0)",
                                    height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize
                                    // , padding: props.minipadding
                                    // fontFamily: props.FontFamily ? "" : "Arial",
                                    // fontFamily: props.Arial,
                                }}
                            >
                                {val.Desc}
                            </td>
                        </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default Intership;