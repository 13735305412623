import { Box, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Color, DivCenter } from '../../../../GlobalStyles'
import { Typography } from 'antd'
import { AutoAwesome, EditOutlined } from '@mui/icons-material'
import MentorDetailsModule from './MentorDetailsModule'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { SmallPageLoader } from '../../../reusables/Loader/Loader'
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import { scrollToTargetId } from '../../../Reusable_functions/ScrollToTargetId/ScrollToTargetId'

const MentorShownInterest = ({ specializationData, process_id, mentor_interest_deadline, studentSummaryData, onStepChange }) => {
    
    const [interview_per_mentor, setTnterview_per_mentor] = useState(0);
    const [approved_mentor_count, setApproved_mentor_count] = useState(0);
    //Requisites
    const service = new CCService();
    const [loading, setLoading] = useState({ page: false });
    //Data Variables
    const [mentorShownInterestData, setMentorShownInterestData] = useState([{
        "first_name": "",
        "last_name": "",
        "email_id": "",
        "ph_no": "",
        "mapped_spe": [{ spe_id: null, spe_name: '', slot_count: null }],
        "spe": [{ spe_id: null, spe_name: '', }],
        "departure_city": "",
        "arrival_city": "",
        "status": null,
        "comment": "",
        "total_interviews": "",
    }]);
    const [mentorSummary, setMentorSummary] = useState([
        {
            "id": null,
            "process_id": null,
            "spe_id": null,
            "student_count": null,
            "approved_slot_count": 0,
            "pending_slot_count": 0,
            "rejected_slot_count": 0,
            "left_out_students":0
        },
    ]);
    const [mentorDateSlotMapping, setMentorDateSlotMapping] = useState({});
    const [skuDetails, setSkuDetails] = useState({
        "college_code": "",
        "user_group_name": "",
        "name": "",
        "sku_name": ""
    });
    console.log("")
    console.log("To understand Data:", mentorSummary);
    const [totals, setTotals] = useState({ total: 0, dynamic: 0 });
    //STYLES
    const styles = {
        page: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        button_styles: {
            contained: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.primary1,
                color: Color.white,
                borderRadius: '12px',
                textTransform: 'none',
                '&:hover': {
                    background: Color.primary1,
                    color: Color.white,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            },
            outlined: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.white,
                color: Color.primary1,
                borderRadius: '12px',
                border: `1px solid ${Color.primary1}`,
                textTransform: 'none',
                '&:hover': {
                    background: Color.white,
                    color: Color.primary1,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            }
        }
    }

    //FUNCTIONS
    useEffect(() => {
        const handleUseEffect = async () => {
            setLoading((prev) => ({ ...prev, page: true }));
            await handleMentorShownInterestEffect();
            setLoading((prev) => ({ ...prev, page: false }));
        }
        handleUseEffect();
    }, []);
    console.log("interview count and total approved mentors:", interview_per_mentor, approved_mentor_count);
    const handleMentorShownInterestEffect = async () => {
        console.log("in handleMentorShownInterestEffect");
        //MENTOR SHOWN INTEREST
        try {
            const mentor_shown_interest = await getMentorShownInterest();
            console.log("in handleMentorShownInterestEffect mentor_shown_interest", mentor_shown_interest, specializationData);
            setSkuDetails(mentor_shown_interest.description);   //doesnt need handling there will always be value here

            const dataslotmapping = JSON.parse(mentor_shown_interest.session_date_slot_count_mapping[0].session_date_slot_count_mapping).session_date_slot_count_mapping;
            if (mentor_shown_interest.session_date_slot_count_mapping.length) setMentorDateSlotMapping(dataslotmapping);

            const remade_data = remakeMentorShownInterestData(mentor_shown_interest.data, specializationData);
            console.log("handleMentorShownInterestEffect ~ remade_data:", remade_data);
            if (remade_data.length) setMentorShownInterestData(remade_data);

            const mentor_summary = await getMentorSummary();
            console.log("handleMentorShownInterestEffect ~ mentor_summary:", mentor_summary);
            const made_mentor_summary = makeMentorSummaryData(mentor_summary, dataslotmapping, specializationData,);
            // const made_mentor_summary = makeMentorSummaryData(studentSummaryData, dataslotmapping, remade_data);
            setMentorSummary(made_mentor_summary);
            return remade_data;
        } catch (error) {
            console.error("Mentor Shown ~ error:", error);
            setLoading((prev) => ({ ...prev, page: false }));
        }
    }


    const getMentorSummary = async () => {
        try {
            const response = await service.get(`/os/superadmin/interestedMentors/slots?process_id=${process_id}`);
            console.log("Mentor Shown Interest Summary:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error while fetching mentor shown interest:", error);
        }
    }

    const getMentorShownInterest = async () => {
        try {
            const response = await service.get(`/os/superadmin/interestedMentors?process_id=${process_id}`);
            console.log("Mentor Shown Interest:", response);
            return response;
        } catch (error) {
            console.error("Error while fetching mentor shown interest:", error);
        }
    }

    const remakeMentorShownInterestData = (fetched_data, specilization_data) => {
        let data = [...fetched_data];
        data.forEach((summary) => {
            console.log('summnary:', summary)
            if (summary.spe_ids) {
            
                summary.spe_ids = JSON.parse(summary.spe_ids)
                summary.spe = summary.spe_ids.map(id => {
                    const foundData = specilization_data.find(data => data.id === id);
                    return foundData ? { spe_id: foundData.id, spe_name: foundData.name } : null;
                });
                delete summary.spe_ids;
                // summary.spe_names = summary.spe_ids.map(id => {
                //     const foundData = specilization_data.find(data => data.id === id);
                //     return foundData ? foundData.name : null;
                // });
            } else {
                summary.spe = []
            }
            if (summary.mapped_spe_ids.length) {
                // summary.mapped_spe_ids = JSON.parse(summary.mapped_spe_ids).count_data
              
                summary.mapped_spe = summary.mapped_spe_ids.flatMap(map => {
                    const foundData = specilization_data.find(data => data.id === map.spe_id);
                    return foundData ? { spe_id: foundData.id, spe_name: foundData.name, slot_count: map.session_count } : null;
                });
                delete summary.mapped_spe_ids;
                // summary.mapped_spe_names = summary.mapped_spe_ids.map((spe) => spe.spe_id).map(id => {
                //     const foundData = specilization_data.find(data => data.id === id);
                //     return foundData ? foundData.name : null;
                // });
            } else {
                summary.mapped_spe = []
            }
        })
        console.log("remakeMentorShownInterestData ~ data 33:", data)
        return data;
    }

    // const makeMentorSummaryData = (student_summary, dataslotmapping, mentorShownInterest_data) => {
    //     const student_summary_data = [...student_summary.spe_count];
    //     const totalInterviewscount_per_mentor = dataslotmapping.reduce((acc, obj) => {
    //         return acc + parseInt(obj['per_mentor_slot_count']);
    //     }, 0);

    //     console.log("makeMentorSummaryData ~ mentorShownInterest_data:", mentorShownInterest_data)

    //     const mentors_approved_count = mentorShownInterest_data.filter((mentor) => mentor.status === 'Accepted').length;
    //     const total_mentors_shown_interest = mentorShownInterest_data.length;
    //     const mentors_rejected_count = mentorShownInterest_data.filter((mentor) => mentor.status === 'Rejected').length;

    //     const mentor_summary = [];
    //     let nullSpeItems = []; // Array to store objects with null spe_name

    //     for (const student_spe_index in student_summary_data) {
    //         const student_spe_item = student_summary_data[student_spe_index];

    //         if (student_spe_item.spe_name === null) {
    //             nullSpeItems.push({
    //                 spe_name: 'Others',
    //                 total_students: student_spe_item.student_count,
    //                 left_out_students: Math.round(student_spe_item.student_count % totalInterviewscount_per_mentor),
    //                 total_mentors_required: Math.floor(student_spe_item.student_count / totalInterviewscount_per_mentor),
    //                 total_interested: 0,
    //                 mentors_approved: mentors_approved_count,
    //                 mentors_yet_to_be_approved: total_mentors_shown_interest - (mentors_approved_count + mentors_rejected_count),
    //                 mentors_rejected: mentors_rejected_count
    //             });
    //         } else {
    //             mentor_summary.push({
    //                 spe_name: student_spe_item.spe_name,
    //                 total_students: student_spe_item.student_count,
    //                 left_out_students: Math.round(student_spe_item.student_count % totalInterviewscount_per_mentor),
    //                 total_mentors_required: Math.floor(student_spe_item.student_count / totalInterviewscount_per_mentor),
    //                 total_interested: 0,
    //                 mentors_approved: mentors_approved_count,
    //                 mentors_yet_to_be_approved: total_mentors_shown_interest - (mentors_approved_count + mentors_rejected_count),
    //                 mentors_rejected: mentors_rejected_count
    //             });
    //         }
    //     }

    //     // Append null spe_name objects to the end
    //     mentor_summary.push(...nullSpeItems);

    //     return mentor_summary;
    // };


    const makeMentorSummaryData = (mentorSummaryData, dataslotmapping, specilization_data) => {
        const temp_mentorSummary = [...mentorSummaryData];
        const totalInterviewscount_per_mentor = dataslotmapping.reduce((acc, obj) => {
            return acc + parseInt(obj['per_mentor_slot_count']);
        }, 0);
        setTnterview_per_mentor(totalInterviewscount_per_mentor);
        console.log("mentorSummaryData in this func:", mentorSummaryData)
        console.log("mentor summary in this :", interview_per_mentor, approved_mentor_count)
        let totalDecimal = 0;
        let totalMentorsRequired = 0;
        temp_mentorSummary.forEach((summary) => {
            if (summary.spe_id) {
                summary.spe_name = specilization_data.find((spe) => spe.id === summary.spe_id)?.name;
            } else summary.spe_name = 'N/A';
            console.log("mentor summary in this :", interview_per_mentor, approved_mentor_count)
            summary.left_out_students = (summary.student_count - summary.approved_slot_count )
            summary.approved_slot_count = (summary.approved_slot_count / totalInterviewscount_per_mentor).toFixed(2);
            summary.pending_slot_count = (summary.pending_slot_count / totalInterviewscount_per_mentor).toFixed(2);
            summary.rejected_slot_count = (summary.rejected_slot_count / totalInterviewscount_per_mentor).toFixed(2);
            setApproved_mentor_count( parseFloat( approved_mentor_count) + parseFloat((summary.approved_slot_count / totalInterviewscount_per_mentor).toFixed(2)));
            summary.total_mentors_required = (summary.student_count / totalInterviewscount_per_mentor).toFixed(2);
           
            const decimalPart = parseFloat(summary.total_mentors_required) % 1; // Extract decimal part
            totalDecimal += decimalPart; // Add decimal part to total
            totalMentorsRequired += parseFloat(summary.total_mentors_required);
            console.log("temp_mentorSummary.forEach ~ totalMentorsRequired:", totalMentorsRequired, parseInt(summary.total_mentors_required))
        });
        console.log("makeMentorSummaryData ~ totalMentorsRequired:", totalMentorsRequired)
        setTotals((prev) => ({ ...prev, total: totalMentorsRequired, dynamic: totalDecimal }));
        console.log("makeMentorSummaryData ~ temp_mentorSummary:", temp_mentorSummary);
        return temp_mentorSummary;
    }

    return (
        <>{loading.page ?
            <SmallPageLoader show={loading.page} />
            : <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                <Box sx={{ ...styles.page, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
                    <Typography style={{ fontSize: '24px', fontWeight: 500 }}>Mentor Summary</Typography>
                    <Typography onClick={() => { onStepChange(null, 2, null); scrollToTargetId('MentorShowInterestDeadline') }} style={{ ...DivCenter, gap: '8px', fontSize: '18px', cursor: 'pointer' }}>
                        Deadline : { mentor_interest_deadline ? formatConvertermonthfirst(mentor_interest_deadline, false, true): "No deadline specified" }
                        <EditOutlined sx={{ height: '20px', width: '20px', color: Color.primary1, cursor: 'pointer' }} />
                    </Typography>
                </Box>
                <Box sx={{ ...styles.page, gap: '16px' }}>
                    <Typography style={{ fontSize: '20px', color: Color.neutralMidGrey }}>View all mentors interested, approved, yet to be approved</Typography>
                </Box>
                <Box sx={{ ...styles.page, gap: '16px', width: '100%', }}>
                    <Box sx={{ ...DivCenter, width: '100%', gap: '16px', minHeight: '76px', background: 'rgba(249, 253, 254, 1)' }}>
                        <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>Specialization</Typography>
                        <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>Total Students</Typography>
                        <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>Total Mentors Required</Typography>
                        {/* <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>Total Interested</Typography> */}
                        <Typography style={{ ...DivCenter, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', gap: '8px' }}>Approved<Tooltip title='Optimize' placement='top'><IconButton><AutoAwesome /></IconButton></Tooltip></Typography>
                        <Typography style={{ ...DivCenter, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', gap: '8px' }}>Yet to Approve<Tooltip title='Optimize' placement='top'><IconButton><AutoAwesome /></IconButton></Tooltip></Typography>
                        <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>Rejected</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '24px' }}>
                        {mentorSummary.map((summary) =>
                            <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', gap: '16px', }}>
                                <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', }}>{summary.spe_name}</Typography>
                                <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>{summary.student_count ?? '-'}
                                    {summary.left_out_students? <><span style={{ color:  summary.left_out_students>0 ? Color.red : Color.green}}>({summary.left_out_students})</span></> : <></>}
                                </Typography>
                                <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>{summary.total_mentors_required ?? '-'}</Typography>
                                {/* <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>{summary.total_interested ?? '-'}</Typography> */}
                                <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>{(summary.approved_slot_count) ?? '-'}</Typography>
                                <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>{(summary.pending_slot_count) ?? '-'}</Typography>
                                <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>{summary.rejected_slot_count ?? '-'}</Typography>
                            </Box>
                        )}
                        <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', gap: '16px', }}>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', }}>Total</Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                {mentorSummary.reduce((acc, obj) => {
                                    return acc + parseInt(obj['student_count']);
                                }, 0)} / {interview_per_mentor * Math.ceil(mentorSummary.reduce((acc, obj) => {
                                    return (acc + parseFloat(obj['approved_slot_count']));
                                }, 0))} </Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                {Math.ceil(totals.total)}</Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>
                                {Math.ceil(mentorSummary.reduce((acc, obj) => {
                                    return (acc + parseFloat(obj['approved_slot_count']));
                                }, 0))}</Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>
                                {Math.ceil(mentorSummary.reduce((acc, obj,) => {

                                    return (acc + parseFloat(obj['pending_slot_count']));
                                }, 0))}</Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>
                                {Math.ceil(mentorSummary.reduce((acc, obj) => {
                                    return acc + parseFloat(obj['rejected_slot_count']);
                                }, 0))}</Typography>
                        </Box>
                        <hr style={{ width: '100%' }} />
                        <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', gap: '16px', mt: '16px' }}>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', }}>Dynamic</Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                {/* {mentorSummary.reduce((acc, obj) => {
                                    return acc + parseInt(obj['student_count']);
                                }, 0)} */}-
                            </Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                {Math.ceil(totals.dynamic) ?? '-'}</Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>
                                {/* {mentorSummary.reduce((acc, obj) => {
                                    return acc + parseInt(obj['approved_slot_count']);
                                }, 0)} */}-
                            </Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>
                                {/* {mentorSummary.reduce((acc, obj) => {
                                    return acc + parseInt(obj['pending_slot_count']);
                                }, 0)} */}-
                            </Typography>
                            <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center', color: Color.primary1 }}>
                                {/* {mentorSummary.reduce((acc, obj) => {
                                    return acc + parseInt(obj['rejected_slot_count']);
                                }, 0)} */}-
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {/* {mentorShownInterestData[0].email_id ? */}
                    <MentorDetailsModule
                        mentorShownInterestData={mentorShownInterestData}
                        setMentorShownInterestData={setMentorShownInterestData}
                        handleMentorShownInterestEffect={handleMentorShownInterestEffect}
                        mentorSummary={mentorSummary}
                        mentorDateSlotMapping={mentorDateSlotMapping}
                        
                        // setMentorDateSlotMapping={setMentorDateSlotMapping}
                        specializationData={specializationData}
                        skuDetails={skuDetails}
                        process_id={process_id} />
                    {/* : <></>} */}
            </Box>}</>
    )
}

export default MentorShownInterest