import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Color, DivCenter } from "../../../../GlobalStyles";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
import "../JobAllRequests/JobAllRequests.css";
import { SlideInRightModal } from "../AdditionalComponents/SlideInRightModal";
import { useEffect } from "react";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import TimeService from "../../../../functions/epochTime";
import { ellipsisFunction } from "../../../Reusable_functions/TextFunctions/EllipsisFunction";
import { send_specialCharReplace } from "../../../../services/specialCharReplace";
import { Close } from "@mui/icons-material";
import { colorDecider } from "../../../reusables/Jobs/jobsFunctions";
import { SmallPageLoader } from "../../../reusables/Loader/Loader";

const JobJobs = () => {
  const services = new CCService();
  const timeServices = new TimeService();
  const [loading, setLoading] = useState({ page: false, onHoldConfirmation: false });
  const navigate = useNavigate();
  const [allJobsData, setAllJobsData] = useState([
    {
      job_id: null,
      job_published_time: "",
      job_type_name: "",
      company_name: "",
      responsibilities: "",
      roles: "",
      spe: "",
      designation: "",
      job_status: "",
      total_applicants: null,
    },
  ]);
  const [backup_allJobsData, setBackup_allJobsData] = useState([...allJobsData]);
  console.log("JobJobs ~ backup_allJobsData:", backup_allJobsData);
  // {
  //     "New Requests": [{
  //         posted_on: '24th March, 12pm',
  //         company_name: 'CareerCarve',
  //         specialization: 'Gazing and Chasing',
  //         role: 'Minister of Dark Arts',
  //         status: 'In review'
  //     }, {
  //         posted_on: '24th March, 12pm',
  //         company_name: 'CareerCarve',
  //         specialization: 'Gazing and Chasing',
  //         role: 'Minister of Dark Arts',
  //         status: 'In review'
  //     }, {
  //         posted_on: '24th March, 12pm',
  //         company_name: 'CareerCarve',
  //         specialization: 'Gazing and Chasing',
  //         role: 'Minister of Dark Arts',
  //         status: 'In review'
  //     }],
  //         "On Hold": [{
  //             posted_on: '24th March, 12pm',
  //             company_name: 'CareerCarve',
  //             specialization: 'Gazing and Chasing',
  //             role: 'Minister of Dark Arts',
  //             status: 'On Hold'
  //         }, {
  //             posted_on: '24th March, 12pm',
  //             company_name: 'CareerCarve',
  //             specialization: 'Gazing and Chasing',
  //             role: 'Minister of Dark Arts',
  //             status: 'On Hold'
  //         }],
  //             "Recent": [{
  //                 posted_on: '24th March, 12pm',
  //                 company_name: 'CareerCarve',
  //                 specialization: 'Gazing and Chasing',
  //                 role: 'Minister of Dark Arts',
  //                 status: 'Accepted'
  //             }]
  // }

  const [filterButtons, setFilterButtons] = useState([]);
  const [viewRequest, setVeiwRequest] = useState({
    open: false,
    onClose: () => {
      setVeiwRequest((prev) => ({ ...prev, open: false }));
    },
    data: { company_name: "Abhra's Cute Company", on_boarding_date: "", category: "", role: "", specialization: "", industry: "", email: "", contact: "", linkedin: "", company_desc: "" },
  });

  const [onHoldRemarks, setOnHoldRemarks] = useState({ flag: "", show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });

  useEffect(() => {
    handleMainUseEffect();
  }, []);

  const handleMainUseEffect = async () => {
    setLoading((prev) => ({ ...prev, page: true }));
    const fetched_data = await fetchJobData();
    console.log("fetched_data:", fetched_data);
    const remade_jobsData = jobDataOperations(fetched_data);
    console.log("handleMainUseEffect ~ remade_jobsData:", remade_jobsData);
    setAllJobsData(remade_jobsData);
    setBackup_allJobsData(fetched_data);
    setLoading((prev) => ({ ...prev, page: false }));
    handleClickFilterButton("Accept");
  };

  const jobDataOperations = (final_job_data) => {
    const job_data = [...final_job_data];
    return job_data.sort((job1, job2) => {
      if (job1.job_status === "Accept" && job2.job_status !== "Accept") {
        return -1; // Move job1 with 'Accept' status to the front
      } else if (job1.job_status !== "Accept" && job2.job_status === "Accept") {
        return 1; // Move job2 with 'Accept' status to the front
      } else {
        return 0; // Keep other jobs in their original order
      }
    });
  };

  useEffect(() => {
    const handleUpdateAllRequestData = () => {
      if (filterButtons.length === 0) {
        setAllJobsData(backup_allJobsData);
      } else {
        const filteredData = backup_allJobsData.filter((job) => filterButtons.includes(job.job_status?.trim()));
        console.log({ filteredData, filterButtons });
        setAllJobsData(filteredData);
      }
    };
    handleUpdateAllRequestData();
  }, [filterButtons]);

  const fetchJobData = async () => {
    try {
      const response = await services.get(`/jobs/admin/allJobs`);
      console.log("response:", response);
      if (response.details) return response.details;
      else return [];
    } catch (err) {
      console.error("Error fetching jobdata:", err);
    }
  };

  const handleViewRequestClick = (job_id) => {
    const sending_data = allJobsData.find((data) => data.job_id === job_id);
    console.log("sending_data:", sending_data);
    setVeiwRequest((prev) => ({
      ...prev,
      open: true,
      data: {
        ...prev.data,
        company_name: sending_data.company_name,
        date: timeServices.convertEpochToDate(parseInt(sending_data.job_published_time)) ?? "No data",
        time: timeServices.convertEpochToTime(parseInt(sending_data.job_published_time)) ?? "No data",
        category: sending_data.category ?? "No data",
        role: sending_data.roles ?? "No data",
        specialization: sending_data.spe ?? "No data",
        status: sending_data.job_status ?? "No data",
        job_id,
        responsibilities: sending_data.responsibilities,
        closed_timing: sending_data.closed_timing,
        applications: { applied: sending_data.total_applicants },
        buttons: true,
      },
    }));
  };

  const handleClickFilterButton = (button) => {
    let temp = [...filterButtons];
    if (filterButtons.includes(button)) {
      setFilterButtons(temp.filter((data) => data !== button));
      // setAllJobsData(backup_allJobsData);
    } else {
      temp.push(button);
      setFilterButtons(temp);
      // setAllJobsData((prev) => (prev.filter((job) => job.job_status === button)));
    }
  };

  const handleAcceptClick = async () => {
    try {
      setLoading((prev) => ({ ...prev, onHoldConfirmation: true }));
      const body = {
        job_id: viewRequest.data.job_id,
        action: "Accept",
        job_status: "In review",
        remarks: send_specialCharReplace(onHoldRemarks.content),
      };
      console.log("body:", body);
      const accept_put_request = await putAcceptOrOnHoldRequest(body);
      console.log("accept_put_request:", accept_put_request);
      setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
      onHoldRemarks.onClose();
      viewRequest.onClose();
      handleMainUseEffect();
    } catch (err) {
      window.alert("Error while accepting request", err);
      setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
    }
  };

  const putAcceptOrOnHoldRequest = async (body) => {
    const response = await services.put(`/jobs/admin/recruiterCompanies/request`, body);
    console.log("response:", response);
    return response;
  };

  function OnHoldRemarksModal({ open, onClose }) {
    const [formInput, setFormInput] = useState({ value: onHoldRemarks.content, error: "" });
    console.log("formInput:", formInput);
    const handleOnHoldRemarksSubmit = () => {
      if (formInput.value.length > 0) {
        handleOnHoldClick();
      } else {
        setFormInput((prev) => ({ ...prev, error: "Please provide a valid response" }));
      }
    };
    const handleOnHoldClick = async () => {
      //calling put request
      try {
        const body = {
          job_id: viewRequest.data.job_id,
          action: "On hold",
          job_status: "In review",
          remarks: send_specialCharReplace(formInput.value),
        };
        console.log("body:", body);
        const onHold_put_request = await putAcceptOrOnHoldRequest(body);
        console.log("onHold_put_request:", onHold_put_request);
        setOnHoldRemarks((prev) => ({ ...prev, show: false, content: formInput.value }));
        viewRequest.onClose();
        handleMainUseEffect();
      } catch (err) {
        console.error("Error while on hold-ing request", err);
      }
    };
    const onholdmarksStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 604,
      height: 354,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      borderRadius: "20px",
      boxShadow: 24,
      p: 4,
    };
    return (
      <Modal
        open={open}
        onClose={onClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...onholdmarksStyle }}>
            {onHoldRemarks.flag === "Accept" ? (
              <>
                <Box>
                  <Typography fontSize={32}>Are you sure you want to accept this request?</Typography>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "90%", justifyContent: "space-evenly" }}>
                  <Button variant="outlined" sx={{ height: "44px", width: "40%", borderColor: Color.primary1, color: Color.primary1 }} onClick={() => onHoldRemarks.onClose()}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      height: "44px",
                      width: "40%",
                      background: Color.primary1,
                      "&:hover": { background: Color.primary1 },
                    }}
                    onClick={() => handleAcceptClick()}
                  >
                    {loading.onHoldConfirmation ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Yes"}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ ...DivCenter, width: "100%", height: "10%", justifyContent: "space-between" }}>
                  <Typography fontSize={32}>Why keep this request on Hold?</Typography>

                  <IconButton onClick={() => onClose()}>
                    <Close />
                  </IconButton>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "30%" }}>
                  <Typography fontSize={18}>Your comments will be seen to the recruiter</Typography>
                </Box>
                <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", height: "50%" }}>
                  <TextField
                    multiline
                    rows={4}
                    value={formInput.value}
                    onChange={(event) => {
                      setFormInput((prev) => ({ ...prev, value: event.target.value, error: "" }));
                    }}
                    sx={{ width: "80%", height: "100%" }}
                  ></TextField>
                  <Typography color={"red"} mb={"10px"}>
                    {formInput.error}
                  </Typography>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", height: "15%" }}>
                  <Button variant="contained" sx={{ height: "100%", width: "40%", boxShadow: "none", bgcolor: Color.primary_gradient, color: "white", textTransform: "none", "&:hover": { bgcolor: Color.primary_gradient, boxShadow: "none" } }} onClick={() => handleOnHoldRemarksSubmit()}>
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    );
  }

  return (
    <>
      {loading.page ? (
        <SmallPageLoader show={loading.page} />
      ) : (
        <Box className="main_box">
          <SideNavigationBar />
          <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
          <Box className="box__mainContent" sx={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", gap: "33px", alignItems: "flex-start" }}>
            <Box sx={{ mt: "10px" }}>
              <Typography sx={{ fontSize: "32px", color: "black" }}>Jobs</Typography>
            </Box>
            <Box sx={{ ...DivCenter, gap: "10px" }}>
              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("Accept")}
                sx={{
                  background: filterButtons.includes("Accept") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Accept") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Accept") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Accept") ? Color.white : Color.primary1,
                  },
                }}
              >
                Live
              </Button>

              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("Paused")}
                sx={{
                  background: filterButtons.includes("Paused") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Paused") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Paused") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Paused") ? Color.white : Color.primary1,
                  },
                }}
              >
                Paused
              </Button>
              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("Closed")}
                sx={{
                  background: filterButtons.includes("Closed") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Closed") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Closed") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Closed") ? Color.white : Color.primary1,
                  },
                }}
              >
                Closed
              </Button>

              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("Incomplete")}
                sx={{
                  background: filterButtons.includes("Incomplete") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Incomplete") ? Color.white : Color.primary1,

                  "&:hover": {
                    background: filterButtons.includes("Incomplete") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Incomplete") ? Color.white : Color.primary1,
                  },
                }}
              >
                In-complete
              </Button>

              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("On hold")}
                sx={{
                  background: filterButtons.includes("On hold") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("On hold") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("On hold") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("On hold") ? Color.white : Color.primary1,
                  },
                }}
              >
                On-hold
              </Button>

              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("In review")}
                sx={{
                  background: filterButtons.includes("In review") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("In review") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("In review") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("In review") ? Color.white : Color.primary1,
                  },
                }}
              >
                In-review
              </Button>

              <Button
                variant="outlined"
                className="button__filterButtons"
                onClick={() => handleClickFilterButton("Completed")}
                sx={{
                  background: filterButtons.includes("Completed") ? Color.primary3 : Color.white,
                  color: filterButtons.includes("Completed") ? Color.white : Color.primary1,
                  "&:hover": {
                    background: filterButtons.includes("Completed") ? Color.primary3 : Color.white,
                    color: filterButtons.includes("Completed") ? Color.white : Color.primary1,
                  },
                }}
              >
                Completed
              </Button>
              {/* <Button variant='outlined' className='button__filterButtons' sx={{ width: '88px!important' }}>On Hold</Button> */}
              {/* <IconButton sx={{ color: Color.primary1 }}>+</IconButton> */}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box className="box__header">
                <Typography className="typography__header" style={{ width: "16.67%" }}>
                  Posted on
                </Typography>
                <Typography className="typography__header" style={{ width: "16.67%" }}>
                  Company
                </Typography>
                <Typography className="typography__header" style={{ width: "16.67%" }}>
                  Designation
                </Typography>
                <Typography className="typography__header" style={{ width: "16.67%" }}>
                  Role
                </Typography>
                <Typography className="typography__header" style={{ width: "16.67%" }}>
                  Status
                </Typography>
                <Typography className="typography__header" style={{ width: "16.67%", color: "white" }}>
                  View Buttons
                </Typography>
              </Box>
              <hr style={{ color: Color.neutralMidGrey }} />
              <Box className="box__postings">
                <Box sx={{ width: "100%" }}>
                  {allJobsData.map((request_row, index) => (
                    <Box key={index}>
                      <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-evenly", padding: "16px 0 16px 0" }}>
                        <Typography style={{ width: "16.67%", textAlign: "center" }}>{timeServices.convertEpochToTime(parseInt(request_row.job_published_time)) + ", " + timeServices.convertEpochToDate(parseInt(request_row.job_published_time))}</Typography>
                        <Typography style={{ flexWrap: "wrap", width: "16.67%", textAlign: "center" }}>{ellipsisFunction(request_row.company_name, 10)}</Typography>
                        <Typography style={{ flexWrap: "wrap", width: "16.67%", textAlign: "center" }}>{ellipsisFunction(request_row.designation ?? "N/A", 10)}</Typography>
                        <Typography style={{ flexWrap: "wrap", width: "16.67%", textAlign: "center" }}>{ellipsisFunction(request_row.roles ?? "N/A", 10) ?? "No specia. specified"}</Typography>
                        <Typography style={{ flexWrap: "wrap", width: "16.67%", textAlign: "center", color: colorDecider(request_row.job_status, "color") }}>{request_row.job_status === "Accept" ? "Live" : request_row.job_status ?? "N/A"}</Typography>
                        <Button
                          variant={request_row.status === "In review" ? "contained" : "outlined"}
                          // onClick={() => handleViewRequestClick(request_row.job_id)}
                          // onClick={() =>
                          //     // navigate(`/JobPostingState?job_id=${request_row.job_id}`)
                          //     navigate(`/JobPostingState?job_id=${request_row.job_id}`, { state: { company_name: request_row.company_name } })
                          // }
                          onClick={() => (["In review", "On hold"].includes(request_row.job_status) ? handleViewRequestClick(request_row.job_id) : navigate(`/JobPostingState?job_id=${request_row.job_id}`, { state: { company_name: request_row.company_name } }))}
                          sx={{
                            width: "16.67%",
                            background: request_row.status === "In review" ? Color.primary1 : "white",
                            borderColor: Color.primary1,
                            color: request_row.status === "In review" ? "white" : "black",
                            padding: "16px 0 16px 0",
                            borderRadius: "10px",
                            textTransform: "none",
                            boxShadow: "none",
                            "&:hover": {
                              background: request_row.status === "In review" ? Color.primary3 : "",
                              boxShadow: "none",
                            },
                          }}
                        >
                          View
                        </Button>
                      </Box>
                      <hr />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="box__fillerBox" sx={{ width: "8%" }}></Box>
          <SlideInRightModal open={viewRequest.open} onClose={viewRequest.onClose} data={viewRequest.data} onHoldRemarks={onHoldRemarks} setOnHoldRemarks={setOnHoldRemarks} />
          <OnHoldRemarksModal open={onHoldRemarks.show} onClose={onHoldRemarks.onClose} />
        </Box>
      )}
    </>
  );
};

export default JobJobs;
