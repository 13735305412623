import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { Color, DivCenter } from '../../../../GlobalStyles';
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import './JobAllRequests.css'
import { SlideInRightModal } from '../AdditionalComponents/SlideInRightModal';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import { ellipsisFunction } from '../../../Reusable_functions/TextFunctions/EllipsisFunction';
import TimeService from '../../../../functions/epochTime';
import CloseIcon from '@mui/icons-material/Close';
import Loader, { SmallPageLoader } from '../../../reusables/Loader/Loader';
import { send_specialCharReplace } from '../../../../services/specialCharReplace';

const JobAllRequests = () => {

    const services = new CCService();
    const timeServices = new TimeService();
    const navigate = useNavigate();

    const [loading, setLoading] = useState({ page: false, onHoldConfirmation: false });
    const initial_requestData = {
        "job_id": null,
        "job_published_time": "",
        "company_name": "",
        "roles": "",
        "spe": "",
        "job_status": "",
        "designation": ""
    }
    const [filterButtons, setFilterButtons] = useState({ new: false, on_hold: false, recent: false });

    const [allRequestData, setAllRequestData] = useState({
        "New Requests": [initial_requestData],
        "On Hold": [initial_requestData],
        "Recent": [initial_requestData]
    });
    console.log("allRequestData:", allRequestData);
    const [backup_RequestData, setBackup_RequestData] = useState({ ...allRequestData });
    const [viewRequest, setVeiwRequest] = useState({
        open: false,
        onClose: () => { setVeiwRequest((prev) => ({ ...prev, open: false })) },
        data: { job_id: '', company_name: '', on_boarding_date: '', category: '', role: '', specialization: '', industry: '', email: '', contact: '', linkedin: '', company_desc: '' }
    });

    const [onHoldRemarks, setOnHoldRemarks] = useState({ flag: '', show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });

    useEffect(() => {
        handleMainUseEffect();
    }, []);

    useEffect(() => {
        const handleUpdateAllRequestData = () => {
            if (filterButtons.new === false && filterButtons.on_hold === false && filterButtons.recent === false) {
                setAllRequestData(backup_RequestData);
            } else {
                setAllRequestData((prev) => ({ "New Requests": filterButtons.new ? backup_RequestData["New Requests"] : [], "On Hold": filterButtons.on_hold ? backup_RequestData["On Hold"] : [], "Recent": filterButtons.recent ? backup_RequestData["Recent"] : [] }));
            }
        }
        handleUpdateAllRequestData();
    }, [filterButtons.new, filterButtons.on_hold, filterButtons.recent])

    const handleMainUseEffect = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        const final_allRequestData = await allRequestDataOperations();
        console.log("final_allRequestData:", final_allRequestData);
        setAllRequestData(final_allRequestData);
        setBackup_RequestData(final_allRequestData);  //back up data to restore to previous state after being filtered
        setLoading((prev) => ({ ...prev, page: false }));
    }

    const allRequestDataOperations = async () => {
        const all_request_data = await fetchAllRequestData();
        console.log("all_request_data:", all_request_data);

        //Function
        const remakeAllRequestData = (data) => {
            let temp_data = [...data];
            let final_data = { "New Requests": [], "On Hold": [], "Recent": [] };
            const temp_new_req = temp_data.filter((temp) => temp.job_status === "In review");
            final_data["New Requests"] = temp_new_req.length ? temp_new_req : [{ job_id: null }];
            const temp_on_hold = temp_data.filter((temp) => temp.job_status === "On hold");
            final_data["On Hold"] = temp_on_hold.length ? temp_on_hold : [{ job_id: null }];
            const temp_recent = temp_data.filter((temp) => temp.job_status === "Accept");
            final_data["Recent"] = temp_recent.length ? temp_recent : [{ job_id: null }];
            return final_data;
        }


        //forming/remaking the data
        const remade_allRequestData = remakeAllRequestData(all_request_data);
        console.log("remade_allRequestData:", remade_allRequestData);
        return remade_allRequestData;
    }

    const fetchAllRequestData = async () => {
        try {
            const response = await services.get(`/jobs/admin/allRequests`);
            console.log("response:", response);
            if (response) return response.details;
            else return [];
        } catch (err) {
            console.error("Error fetching all request data:", err);
        }
    }


    const handleViewRequestClick = (job_id, request_key) => {
        const sending_data = allRequestData[request_key].find(data => data.job_id === job_id);
        console.log("sending_data:", sending_data);
        setVeiwRequest((prev) => ({
            ...prev,
            open: true,
            data: {
                ...prev.data,
                company_name: sending_data.company_name,
                date: timeServices.convertEpochToDate(parseInt(sending_data.job_published_time)) ?? 'No data',
                time: timeServices.convertEpochToTime(parseInt(sending_data.job_published_time)) ?? 'No data',
                category: sending_data.category ?? 'No data',
                role: sending_data.roles ?? 'No data',
                specialization: sending_data.spe ?? 'No data',
                status: sending_data.job_status ?? 'No data',
                job_id: job_id,
                buttons: true
            }
        }));
    }

    const handleFilterButtonClick = (button) => {
        // let temp = JSON.parse(JSON.stringify(filterButtons));
        // setAllRequestData((prev) => ({ "New Requests": temp.new ? [] : prev["New Requests"], "On Hold": temp.on_hold ? [] : prev["On Hold"], "Recent": temp.recent ? [] : prev["Recent"] }));
        if (button === 'New') {
            setFilterButtons((prev) => ({ ...prev, new: !prev.new }));
        } else if (button === 'On Hold') {
            setFilterButtons((prev) => ({ ...prev, on_hold: !prev.on_hold }));
        } else if (button === 'Recent') {
            setFilterButtons((prev) => ({ ...prev, recent: !prev.recent }));
        }
    }

    const handleAcceptClick = async () => {
        try {
            setLoading((prev) => ({ ...prev, onHoldConfirmation: true }));
            const body = {
                "job_id": viewRequest.data.job_id,
                "action": "Accept",
                "job_status": "In review",
                "remarks": send_specialCharReplace(onHoldRemarks.content)
            }
            console.log("body:", body);
            const accept_put_request = await putAcceptOrOnHoldRequest(body);
            console.log("accept_put_request:", accept_put_request);
            setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
            onHoldRemarks.onClose();
            viewRequest.onClose();
            handleMainUseEffect();
        } catch (err) {
            window.alert("Error while accepting request", err);
            setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
        }
    }



    const putAcceptOrOnHoldRequest = async (body) => {
        const response = await services.put(`/jobs/admin/recruiterCompanies/request`, body);
        console.log("response:", response);
        return response;
    }


    function OnHoldRemarksModal({ open, onClose }) {
        const [formInput, setFormInput] = useState({ value: onHoldRemarks.content, error: '' });
        console.log("formInput:", formInput);
        const handleOnHoldRemarksSubmit = () => {
            if (formInput.value.length > 0) {
                handleOnHoldClick();
            } else {
                setFormInput((prev) => ({ ...prev, error: 'Please provide a valid response' }))
            }
        }
        const handleOnHoldClick = async () => {
            //calling put request
            try {
                setLoading((prev) => ({ ...prev, onHoldConfirmation: true }));
                const body = {
                    "job_id": viewRequest.data.job_id,
                    "action": "On hold",
                    "job_status": "In review",
                    "remarks": send_specialCharReplace(formInput.value)
                }
                console.log("body:", body);
                const onHold_put_request = await putAcceptOrOnHoldRequest(body);
                console.log("onHold_put_request:", onHold_put_request);
                setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
                setOnHoldRemarks((prev) => ({ ...prev, show: false, content: formInput.value }));
                viewRequest.onClose();
                handleMainUseEffect();
            } catch (err) {
                window.alert("Error while on hold-ing request", err);
                setLoading((prev) => ({ ...prev, onHoldConfirmation: false }));
            }
        }
        const onholdmarksStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 604,
            height: 354,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
        }
        return (
            <Modal
                open={open}
                onClose={loading.onHoldConfirmation ? () => { } : onClose}
                // aria-labelledby="modal-modal-title"
                // aria-describedby="modal-modal-description"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}>
                <Fade in={open}>
                    <Box sx={{ ...onholdmarksStyle }}>
                        {onHoldRemarks.flag === 'Accept' ?
                            <>
                                <Box>
                                    <Typography fontSize={32}>Are you sure you want to accept this request?</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', height: '90%', justifyContent: 'space-evenly' }}>
                                    <Button variant='outlined' disabled={loading.onHoldConfirmation}
                                        sx={{ height: '44px', width: '40%', borderColor: Color.primary1, color: Color.primary1 }}
                                        onClick={() => onHoldRemarks.onClose()}>No</Button>
                                    <Button variant='contained' disabled={loading.onHoldConfirmation}
                                        sx={{
                                            height: '44px', width: '40%', background: Color.primary1,
                                            '&:hover': { background: Color.primary1 }
                                        }}
                                        onClick={() => handleAcceptClick()}
                                    >{loading.onHoldConfirmation ?
                                        <CircularProgress size={24} sx={{ color: Color.white }} />
                                        : "Yes"}</Button>
                                </Box>
                            </> :
                            <>
                                <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                                    <Typography fontSize={32}>Why keep this request on Hold?</Typography>

                                    <IconButton onClick={() => onClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', height: '30%' }}>
                                    <Typography fontSize={18}>Your comments will be seen to the recruiter</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '50%' }}>
                                    <TextField multiline rows={4} disabled={loading.onHoldConfirmation}
                                        value={formInput.value}
                                        onChange={(event) => { setFormInput((prev) => ({ ...prev, value: event.target.value, error: '' })); }}
                                        sx={{ width: '80%', height: '100%' }}></TextField>
                                    <Typography color={'red'} mb={'10px'}>{formInput.error}</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', height: '15%' }}>
                                    <Button variant='contained' disabled={loading.onHoldConfirmation} sx={{ height: '100%', width: '40%', boxShadow: 'none', bgcolor: Color.primary_gradient, color: 'white', textTransform: 'none', '&:hover': { bgcolor: Color.primary_gradient, boxShadow: 'none', } }}
                                        onClick={() => handleOnHoldRemarksSubmit()}>{loading.onHoldConfirmation ?
                                            <CircularProgress size={24} sx={{ color: Color.white }} />
                                            : "Submit"}</Button>
                                </Box>
                            </>}
                    </Box>
                </Fade>

            </Modal>
        );
    }

    return (
        <>{loading.page ?
            <SmallPageLoader show={loading.page} /> :
            <Box className='main_box'>
                <SideNavigationBar />
                <Box className='box__fillerBox' sx={{ width: '12%' }}></Box>
                <Box className='box__mainContent' sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '33px', alignItems: 'flex-start' }}>
                    <Box sx={{ mt: '10px' }}>
                        <Typography sx={{ fontSize: '32px', color: 'black' }}>All requests</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, gap: '10px' }}>
                        <Button variant='outlined' className='button__filterButtons' onClick={() => handleFilterButtonClick('New')}
                            sx={{
                                background: filterButtons.new ? Color.primary3 : Color.white, color: filterButtons.new ? Color.white : Color.primary1,
                                '&:hover': {
                                    background: filterButtons.new ? Color.primary3 : Color.white, color: filterButtons.new ? Color.white : Color.primary1,
                                }
                            }}>New</Button>
                        <Button variant='outlined' className='button__filterButtons' onClick={() => handleFilterButtonClick('On Hold')}
                            sx={{
                                width: '88px !important', background: filterButtons.on_hold ? Color.primary3 : Color.white, color: filterButtons.on_hold ? Color.white : Color.primary1,
                                '&:hover': {
                                    background: filterButtons.on_hold ? Color.primary3 : Color.white, color: filterButtons.on_hold ? Color.white : Color.primary1,
                                }
                            }}>On Hold</Button>
                        <Button variant='outlined' className='button__filterButtons' onClick={() => handleFilterButtonClick('Recent')}
                            sx={{
                                background: filterButtons.recent ? Color.primary3 : Color.white, color: filterButtons.recent ? Color.white : Color.primary1,
                                '&:hover': {
                                    background: filterButtons.recent ? Color.primary3 : Color.white, color: filterButtons.recent ? Color.white : Color.primary1,
                                }
                            }}>Recent</Button>
                        {/* <IconButton sx={{ color: Color.primary1, height: '40px', width: '40px' }}>+</IconButton> */}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Box className='box__header'>
                            <Typography className='typography__header' style={{ width: '16.67%' }}>Posted on</Typography>
                            <Typography className='typography__header' style={{ width: '16.67%' }}>Company</Typography>
                            <Typography className='typography__header' style={{ width: '16.67%' }}>Designation</Typography>
                            <Typography className='typography__header' style={{ width: '16.67%' }}>Role</Typography>
                            <Typography className='typography__header' style={{ width: '16.67%' }}>Status</Typography>
                            <Typography className='typography__header' style={{ width: '16.67%', color: 'white' }}>View Buttons</Typography>
                        </Box>
                        <hr style={{ color: Color.neutralMidGrey }} />
                        <Box className='box__postings'>
                            {Object.keys(allRequestData).map((request_key) => (
                                <Box key={request_key} sx={{ width: '100%' }}>
                                    {allRequestData[request_key].length === 0 ? <></> :
                                        <>
                                            <Typography sx={{ color: Color.neutralDarkGrey, fontSize: '16px', padding: '16px 0 16px 0' }}>{request_key}</Typography>
                                            {allRequestData[request_key][0].job_id !== null ?
                                                allRequestData[request_key].map((request_row, index) => (
                                                    <Box key={index}>
                                                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-evenly', padding: '16px 0 16px 0' }}>
                                                            <Typography style={{ width: '16.67%', textAlign: 'center' }}>{timeServices.convertEpochToTime(parseInt(request_row.job_published_time)) + ", " + timeServices.convertEpochToDate(parseInt(request_row.job_published_time))}</Typography>
                                                            <Typography style={{ width: '16.67%', textAlign: 'center' }}>{ellipsisFunction(request_row.company_name ?? 'N/A', 10)}</Typography>
                                                            <Typography style={{ width: '16.67%', textAlign: 'center' }}>{ellipsisFunction(request_row.designation ?? 'N/A', 10)}</Typography>
                                                            <Typography style={{ width: '16.67%', textAlign: 'center' }}>{ellipsisFunction(request_row.roles ?? 'N/A', 10)}</Typography>
                                                            <Typography style={{ width: '16.67%', textAlign: 'center', color: request_row.job_status === 'In review' ? Color.accent : request_row.job_status === 'Accept' ? '#08AB3F' : 'red' }}>{request_row.job_status ?? 'N/A'}</Typography>
                                                            <Button
                                                                variant={request_row.job_status === 'In review' ? 'contained' : 'outlined'}
                                                                onClick={() => ['In review', 'On hold'].includes(request_row.job_status) ?
                                                                    handleViewRequestClick(request_row.job_id, request_key) :
                                                                    navigate(`/JobPostingState?job_id=${request_row.job_id}`, { state: { company_name: request_row.company_name } })
                                                                }
                                                                sx={{
                                                                    width: '16.67%', background: request_row.job_status === 'In review' ? Color.primary1 : 'white',
                                                                    borderColor: Color.primary1,
                                                                    color: request_row.job_status === 'In review' ? 'white' : 'black',
                                                                    padding: '16px 0 16px 0', borderRadius: '10px',
                                                                    textTransform: 'none',
                                                                    boxShadow: 'none',
                                                                    '&:hover': {
                                                                        background: request_row.job_status === 'In review' ? Color.primary3 : '',
                                                                        boxShadow: 'none',
                                                                    }
                                                                }}
                                                            >View</Button>
                                                        </Box>
                                                        <hr />
                                                    </Box>
                                                )) : <Typography style={{ fontSize: '16px', padding: '16px 0 16px 0' }}>
                                                    {request_key === 'New Requests' ?
                                                        'No new requests at the moment' :
                                                        request_key === 'On Hold' ? 'No on Hold requests at the moment' :
                                                            request_key === 'Recent' ? 'No recent requests at the moment' : 'No data at the moment'}</Typography>}
                                        </>}
                                </Box>
                            ))}
                        </Box>

                    </Box>
                </Box>
                <Box className='box__fillerBox' sx={{ width: '8%' }}></Box>
                <SlideInRightModal
                    open={viewRequest.open}
                    onClose={viewRequest.onClose}
                    data={viewRequest.data}
                    onHoldRemarks={onHoldRemarks}
                    setOnHoldRemarks={setOnHoldRemarks}
                />
                <OnHoldRemarksModal
                    open={onHoldRemarks.show}
                    onClose={onHoldRemarks.onClose} />
            </Box >
        }</>
    );
}

export default JobAllRequests;