import React, { useEffect, Profiler } from "react";
import axios from "axios";
import QuickSettings from "./QuickSettings";
import "./ResumeSettings.css";
// import './ResumeContent.css';
import colors from "./Colors.json";
import Cookies from "js-cookie";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import SideNavigationBar from '../SideNavigationBar/SideNavigationBar';
// import Loading from "../Reusable_functions/LoadingComponent/LoadingComponent";
import { useState } from "react";
import FontFamilyJson from "./FontFamily.json"; // Import FontFamily with their Sizes
// import TopBar_noLogoWith_Saves from '../Reusable_functions/TopBar_noLogoWithSaves/TopBar_noLogoWithSaves';
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
import { useLocation } from "react-router-dom";
import Service from "../../services/httpService";
export default function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [templateData, setTemplateData] = useState(0);
  const [template_name, setTemplate_name] = useState();
  const [file, setFile] = useState();

  const [showPicture, setShowPicture] = useState(false);
  const [FontSize, setFontSize] = useState(FontFamilyJson["Calibri"]["Small"]); // Initial font size
  const [FontS, setFontS] = useState("Calibri"); // Initial font family
  const [Bullet, setBullet] = useState(false); // Initial bullet style
  const [TableWidth, setTableWidth] = useState(true); // Initial page width
  const [LineHeight, setLineHeight] = useState(false); // Initial line height
  const [ribbonColor, setRibbonColor] = useState("Grey"); // Initial ribbon color
  const [fontFamilys, setFontFamilys] = useState(FontFamilyJson["Calibri"]); // Initial font family sizes
  const [headerImages, setHeaderImage] = useState({
    address: "left",
    picture: "center",
    logo: "right",
  });
  const service = new Service();
  const location = useLocation();
  const [headerPosition, setheaderPosition] = useState("Left");
  const resume_id = "1159";
  console.log("resume_id", resume_id);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [time, setTime] = useState(5);
  //HeaderData
  const [userData, setUserData] = useState({});
  //ProfileSummary Data
  const [profileSummaryBullets, setProfileSummaryBullets] = useState([]);
  // const [profileSummarySubSection, setProfileSummarySubSection] = useState();
  const [academicAchievementData, setAcademicAchievementData] = useState();
  const [showLogo, setShowLogo] = useState(false);
  const [paramsData, setParamsData] = useState("");
  //Academics Data
  const [academicsData, setAcademicsData] = useState([]);
  //ProjectData
  const [projectData, setProjectData] = useState([]);
  const [certficationData, setCertificationsData] = useState([]);
  const [leadershipData, setLeadershipData] = useState([]);
  const [extracurricularData, setExtracurricularData] = useState([]);
  //SIPDataState
  const [sipData, setSipData] = useState();
  const [workExData, setWorkExData] = useState([]);
  const [image, setImage] = useState();
  const [logoPosition, setLogoPosition] = useState("Right");
  const [picturePosition, setPicturePosition] = useState("Centre");
  const [sectionDetails, setSectionDetails] = useState(location.state.sectionDetails);
  const [sectionEntireData, setSectionEntireData] = useState(location.state.sectionEntireData);
  const [isPost, setIsPost] = useState(1);
  const [showAdress, setShowAdress] = useState(false);
  const { lockVerification } = location.state;
  const cookies = Cookies.get("token");
  const process_id = location.state?.process_id || parseInt(searchParams.get("process_id"));
  console.log("process id", process_id);
  console.log("section details id", sectionDetails);

  const TemplateDataGet = async () => {
    const headers = {
      Authorization: `Bearer ${cookies}`,
    };
    try {
      setLoader(true);
      let templateGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/template?resume_id=${resume_id}`, { headers });
      console.log(templateGet.data.resumes);
      // setTemplateId(templateGet.data.resumes.template_id)
      // console.log("set template ID:", templateGet.data.resumes.template_id)
      if (JSON.parse(templateGet.data.resumes[0].params).data == "Free Flowing") {
        setTemplate_name("Free Flowing");
      } else {
        setTemplate_name("Tabular");
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  console.log("Template Name:", template_name);
  const GetResumeParams = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      const paramsGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping?process_id=${process_id}`, { headers });
      const params = JSON.parse(paramsGet.data.data[0].formatting_params);
      console.log("parameter", params);
      const parameter = JSON.parse(paramsGet.data.data[0].formatting_params);
      console.log("parameter listtt", parameter.page_width.value);

      console.log(params);
      setBullet(parameter.bullet_style.value ? parameter.bullet_style.value : Bullet);
      setFontS(parameter.font_family.value ? parameter.font_family.value : "Calibri");
      setLineHeight(parameter.line_height.value ? parameter.line_height.value : LineHeight);
      setFontSize(parameter.font_size.value ? parameter.font_size.value : FontSize);
      // setTableWidth(parameter.page_width.value ? parameter.page_width.value : TableWidth);
      setheaderPosition(parameter.header_position.value ? parameter.header_position.value : headerPosition);
      let e = parameter.ribbon_color.value;
      const handleRibbonChange = (e) => {
        console.log("RibbonColor:", e.target.value);
        let colorVal = e.target.value;
        let color = colors[colorVal];
        setRibbonColor(color);
      };
      setRibbonColor(parameter.ribbon_color.value ? parameter.ribbon_color.value : "Grey");
    } catch (err) {
      console.log(err);
    }
  };
  console.log("Bullet--d", Bullet);
  console.log("resume_params", paramsData);

  const [sectionWisedata, setSectionWisedata] = useState([]);
  const HandLeResumeData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      //Building Data For All Setions
      let ResumeDataGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/resume/render?resume_id=${resume_id}`, { headers });
      console.log("ResumeDataGet", ResumeDataGet);
      const HeaderData = ResumeDataGet.data.resume_data.sectionsData[0].section_data;
      setUserData(HeaderData);
      let temp_data = [];
      let temp_section_data = [];
      ResumeDataGet.data.resume_data.sectionsData.map((item, index) => {
        console.log({ item });
        if (index > 0) {
          if (item.section_detail.section_type_id === 1 && item.section_detail.is_on === 1) {
            //for profile summary
            console.log({ item });
            try {
              temp_data.push(item.sub_sections[0].bullets);
            } catch {
              temp_data.push([]);
            }
          } else if (item.section_detail.is_on === 1) {
            console.log({ item });
            temp_data.push(item?.sub_sections || []);
          }
          if (item.section_detail.is_on === 1) {
            temp_section_data.push(item.section_detail);
          }
        }
      });
      // setSectionDetails(temp_section_data)
      console.log(temp_data);
      setSectionWisedata(temp_data);
      // let ProfileSummaryData = ResumeDataGet.data.resume_data.sectionsData[2].sub_sections[0]
      let AcademicAchievementDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 2)[0]?.sub_sections || [];
      setAcademicAchievementData(AcademicAchievementDetail);
      let ProfileSummaryBullets = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 1)[0]?.sub_sections[0]?.bullets || [];
      setProfileSummaryBullets(ProfileSummaryBullets);
      console.log("params Getin", ResumeDataGet.data.resume_params);
      setParamsData(ResumeDataGet.data.resume_params);
      ResumeDataGet.data?.resume_data?.sectionsData.map((data, index) => {
        console.log("get", data.section_detail.section_type_id);
      });
      let Academicdetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 9)[0]?.section_data || [];
      setAcademicsData(Academicdetail);
      // console.log("Academicdetail",academicsData);
      let ProjectDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 5)[0]?.sub_sections || [];
      setProjectData(ProjectDetail);
      // let CertificationsDetail=ResumeDataGet.data?.resume_data?.sectionsData[6]?.sub_sections || []
      let CertificationsDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 6)[0]?.sub_sections || [];
      setCertificationsData(CertificationsDetail);
      // LeadershipPositionDetail
      let LeadershipPositionDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 7)[0]?.sub_sections || [];
      setLeadershipData(LeadershipPositionDetail);
      // ExtracurricularDetail
      let ExtracurricularDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 8)[0]?.sub_sections || [];
      setExtracurricularData(ExtracurricularDetail);
      // console.log("ProjectDetail",projectData)
      let SIPDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 3)[0]?.sub_sections || [];
      setSipData(SIPDetail);
      let WorkExDetail = ResumeDataGet.data?.resume_data?.sectionsData.filter((data, index) => data.section_detail.section_type_id === 4)[0]?.sub_sections || [];
      setWorkExData(WorkExDetail);
      // console.log("SIPData",SIPDetail)
    } catch (error) {
      console.log(error);
    }
  };
  const HandlePicture = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      const imageGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/picture`, { headers });
      console.log("img", imageGet.data.url);
      if (imageGet.status === 200) {
        setImage(imageGet.data.url);
      }
      // toParent(image);
    } catch (error) {
      // if(imageGet.status===400){
      //     setImage("")
      // }
      if (error.response.status == 400) {
        setImage("https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg");
      }
      console.log("hj", error);
    }
  };
  const HandleLogo = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies}`,
      };
      const imageGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/logo?resume_id=${resume_id}`, { headers });
      console.log("img", imageGet.data.url);
      if (imageGet.status === 200) {
        setFile(imageGet.data.url);
      }
      // toParent(image);
    } catch (error) {
      // if(imageGet.status===400){
      //     setImage("")
      // }
      if (error.response.status == 400) {
        setFile("https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg");
      }
      console.log("hj", error);
    }
  };

  useEffect(() => {
    const handelEffect = async () => {
      if (resume_id === "null") {
        setLoader(false);
      } else {
        const headers = {
          Authorization: `Bearer ${cookies}`,
        };
        try {
          console.log("res_id", resume_id);
          if (resume_id !== "null") {
            const templateGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/template?resume_id=${resume_id}`, { headers });
            const paramsData = templateGet;
            let templateDataLen = templateGet?.data?.resumes?.length || 0;
            if (templateDataLen > 0) {
              setTemplateData(templateDataLen);
              setLoader(false);
            } else {
              setLoader(false);
            }
          }
          const paramsGet = await axios.get(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping?process_id=${process_id}`, { headers });
          console.log("getter data of settings", paramsGet.data);
          let information = paramsGet.data;

          // let search_params = JSON.parse(information[0].section_params);
          // console.log("sections data of settings", search_params);

          // const sections = search_params.section;
          // console.log("sections data of settings", sections);
        } catch (error) {
          console.log(error);
        }
        console.log("templateDataLen", templateData);
        try {
          GetResumeParams();
          TemplateDataGet();
          // HandLeResumeData();
          HandlePicture();
          HandleLogo();
          // HeaderParameterData();
        } catch (error) {
          console.log(error);
        }
      }
    };

    handelEffect();
  }, []);

  console.log("FontS", FontS);

  // put request
  const handlePutRequest = async () => {
    console.log("");
    //get request here
    service
      .put(`${process.env.REACT_APP_API_URL}/user/processIdToParamsMapping`, {
        process_id: process_id,
      })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation");
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message);
      });
  };

  return (
    <div className="resumeSettingsContainer">
      <SideNavigationBar />
      <div style={{ display: "flex", marginLeft: "8rem" }}>
        <button onClick={() => navigate("/ResumeTemplate?process_id=" + process_id)} style={{ marginTop: "3rem" }} className="signup-button">
          Previous
        </button>

        <p style={{ fontSize: "32px", textAlign: "left", marginTop: "3rem", marginLeft: "108px" }}>Settings</p>
      </div>
      <div style={{ float: "left", width: "100vh" }}>
        <QuickSettings process_id={process_id} setSectionDetails={setSectionDetails} setSectionEntireData={setSectionEntireData} sectionWisedata={sectionWisedata} FontSize={FontSize} headerPosition={headerPosition} setheaderPosition={setheaderPosition} setFontSize={setFontSize} FontS={FontS} setFontS={setFontS} Bullet={Bullet} setBullet={setBullet} TableWidth={TableWidth} setTableWidth={setTableWidth} LineHeight={LineHeight} setLineHeight={setLineHeight} ribbonColor={ribbonColor} setRibbonColor={setRibbonColor} fontFamilys={fontFamilys} setFontFamilys={setFontFamilys} sectionDetails={sectionDetails} template_name={template_name} userData={userData} profileSummaryBullets={profileSummaryBullets} Academicdetail={academicsData} projectData={projectData} sipData={sipData} certficationData={certficationData} extracurricularData={extracurricularData} leadershipData={leadershipData} workExData={workExData} image={image} picturePosition={picturePosition} logoPosition={logoPosition} headerImages={headerImages} showPicture={showPicture} showAdress={showAdress} academicAchievementData={academicAchievementData} file={file} showLogo={showLogo} lockVerification={lockVerification} />
      </div>
    </div>
  );
}
