export const formatTimestamp = (timestampString) => {
    // Create a new Date object from the timestamp string
    const date = new Date(timestampString);

    // Extract the components of the date (year, month, day, hours, minutes, seconds)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Create a formatted date string
    const formattedDate = `${day}-${month}-${year} | ${hours}:${minutes}:${seconds}`;

    console.log(formattedDate);
    return formattedDate;
} 