import React, { useEffect, useState } from 'react'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { Color } from '../../../GlobalStyles'
import { Add, Edit } from '@mui/icons-material'
import CCService from '../../../services/httpServiceWithoutSuperAdmin'
import AccessRightsModal from './AccessRightsModal'
import { ToastContainer, toast } from 'react-toastify'

function Levels() {
    const services = new CCService()
    const [levels, setLevels] = useState([])
    const [allAccessRights, setAllAccessRights] = useState([])
    const [selectedLevel, setSelectedLevel] = useState(null)



    const [addMoreModal, setAddMoreModal] = useState(false)

    const getAccessRights = async() =>{
        const getData = await services.get("/po/acl/accessRight")
      
    
        setAllAccessRights(getData.data )
    
    }


   
    const getAccessLevels = async() =>{
    const getData = await services.get("/po/acl/allAdminLevel")
  
    
    setLevels(getData.data )

}

const handleEditRights = async(item) =>{
    try {
     const data = await services.get(`/po/acl/allAccessLevel?level_id=${item.id}`)
     const rights_ids = JSON.parse(data.data[0].access)
   
     setAllAccessRights((prev) => {
         return prev.map((ele) =>{
             return{
                 ...ele,
                 checked: rights_ids.find((item) => item.access_right_id === ele.access_right_id).is_activated === 1 ? true : false
             }
         })
     })


     setSelectedLevel(item)
     setAddMoreModal(true)
    } catch (error) {
     toast.error(`Error: ${error.response.data.message}`)
    }
 }


const handleAddNew = () =>{

     setAllAccessRights((prev) => {
         return prev.map((ele) =>{
             return{
                 ...ele,
                 checked:true
             }
         })
     })
     setSelectedLevel(null)
     
     setAddMoreModal(true)
}

    useEffect(()=>{
        getAccessLevels()
        getAccessRights()
    },[])
  return (
    <div style={{padding:"2rem 7rem"}}>
    <SideNavigationBar />
      <Box>
        <ToastContainer />
          <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBlock:"16px"}} >
          <Typography sx={{fontSize:"20px"}}>Default College Admin Levels</Typography> 
          <Box sx={{display:"flex", gap:"8px", alignItems:"center"}} onClick={()=>handleAddNew()}>
            <Add />
            <Typography>Add more </Typography>
          </Box> 
          </Box>
         <Box sx={{display:"flex", justifyContent:"space-between"}}>
            <Box sx={{flex:1}}>
<Typography sx={{color:Color.neutralMidGrey, fontSize:"16px"}}>Levels</Typography>
            </Box>
            <Box sx={{flex:1}}>
            <Typography sx={{color:Color.neutralMidGrey, fontSize:"16px", textAlign:"center"}}>Allowed Rights</Typography>

            </Box>
            <Box sx={{flex:1}}>
            <Typography sx={{color:Color.neutralMidGrey, fontSize:"16px", textAlign:"center"}}>Restricted Rights</Typography>

            </Box>
            <Box sx={{flex:1}}>
            <Typography sx={{color:Color.neutralMidGrey, fontSize:"16px", textAlign:"center"}}>Last Modified</Typography>

            </Box>


         </Box>
         <hr />
        <Box>

        {levels.map((item, index) =>   <Box sx={{display:"flex", justifyContent:"space-between"}}>
         <Box sx={{display:"flex", justifyContent:"space-between", width:"100%", marginBlock:"2rem"}}>
            <Box sx={{flex:1}}>
<Typography sx={{color:Color.neutralMidGrey, fontSize:"16px"}}>{item.level_name}</Typography>
            </Box>
            <Box sx={{flex:1}}>
            <Typography sx={{color:Color.neutralMidGrey, fontSize:"16px", textAlign:"center"}}>Allowed Rights</Typography>

            </Box>
            <Box sx={{flex:1}}>
            <Typography sx={{color:Color.neutralMidGrey, fontSize:"16px", textAlign:"center"}}>Restricted Rights</Typography>

            </Box>
            <Box sx={{flex:1, display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{color:Color.neutralMidGrey, fontSize:"16px", textAlign:"center"}}>Last Modified</Typography>
            <IconButton onClick={()=>{
                handleEditRights(item)
               
            }}>
               <Edit />
            </IconButton>
            </Box>


         </Box>


         </Box>)}
        </Box>
      </Box>

      <Modal open={addMoreModal} onClose={()=>setAddMoreModal(false)} sx={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
        <AccessRightsModal allAccessRights={allAccessRights} selectedLevel={selectedLevel} setAllAccessRights={setAllAccessRights} close ={()=>setAddMoreModal(false)} levels={levels} setLevels={setLevels} college_id={null}/>
      </Modal>

</div>
  )
}

export default Levels
