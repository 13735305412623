import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import '../OngoingEvents/OngoingEvents.css'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Service from "../../../services/httpService";
import ActivatedStatus from '../ActivatedStatus/ActivatedStatus'
import EditIcon from '@mui/icons-material/Edit';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { calculateEpochTimeInLocalTime, extractDateFromDateString } from "../../../services/specialCharReplace";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { get_specialCharReplace } from "../../../services/specialCharReplace";
export default function UpcomingPrograms(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const collegeService = new Service()
  const { college_id, college_name, batchName, activityTypeData, durationData, mentorData, GroupSessionData, collegeData, batchData, specialization_details, role_details } = props
  console.log("college_name", college_name);
  const [show, setShow] = useState(false)
  const [to_modal_json, setTo_modal_json] = useState({});
  const [action, setAction] = useState("")
  console.log("hvbhbdhbjgfd", GroupSessionData, props.entireGroupSessionData);
  // const roleAndSpecialization = [...role_details, ...specialization_details]
  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {

      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      // let role_and_specialization = role_details.filter((item) => item)
    }

    handleEffect();
  }, [])


  const callModalInput = (e) => {
    console.log("Yaay its here", props.path)
    props.data_getter("", undefined, undefined, "add")
    setShow(true);
  }

  const callModalEditInput = (e, id, index, from) => {
    console.log("TableComponent-callModalInput2:", { id }, { index })
    setTo_modal_json({ ...to_modal_json, id: id, index: index })

    if (from === "edit") {
      setShow(true);
      props.data_getter("", id, index, "edit")
      setAction("edit")
    }
  }


  return (
    <div style={{ marginTop: "2rem", width: '85%' }}>
      <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', width: "100%", alignItems: 'center' }}>
        <Typography style={{}} sx={{ fontSize: 20 }}>Group Sessions</Typography>
        {(props.path === "/GroupSession" || props.path === '/College1x1BatchDetails') ? <>
          <CardActions sx={{ justifyContent: 'flex-end', bottom: 0, marginBottom: ".5rem" }}>
            <button
              className="submitBtn"
              type="button"
              style={{ height: "44px", bottom: 0, width: "200px" }}
              onClick={(e) => callModalInput(e)}
            >
              Add new Group Session
            </button>
          </CardActions></> : <></>}
      </Box>
      <div className="cardsInsideFlex" style={{ gap: "15px", justifyContent: "space-between", marginBottom:"3rem" }}>
        {props.entireGroupSessionData?.length === 0 ? <>
          <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: 'center', margin: "0 auto", marginTop: "1.5rem" }}>
            <div className="noUpcomingBookingsIcon">
              <EventBusyIcon
                sx={{
                  color: "#1C8EA8",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="noUpcomingBookingsTexts">
              <p className="noUpcomingBookingsText">
                No Ongoing Group Sessions
              </p>

            </div>
          </div>
        </> : <>
          {((props.path === "/GroupSession" || props.path === "/College1x1BatchDetails") ? GroupSessionData : props.entireGroupSessionData)?.map((batchInfo, i) => {
            return (
              <Box key={i} sx={{ width: 543, marginRight: '1rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px" }}>
                <CardContent className="cardContainer" disableGutters>
                  <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="body2" sx={{ fontSize: 22 }} color={Color.primary1} gutterBottom>
                      {batchInfo?.name}
                    </Typography>
                    {(props.path === "/GroupSession" || props.path === "/College1x1BatchDetails") && (
                      <ModeEditOutlinedIcon sx={{ color: Color.primary1, cursor: 'pointer' }} onClick={(e) => { callModalEditInput(e, batchInfo.id, i, "edit") }} />
                    )}
                  </div>
                  <Typography component="div" sx={{ color: Color.neutralMidGrey, fontSize: '12px' }}>{get_specialCharReplace(batchInfo.description)}</Typography>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Session:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack">{activityTypeData.filter((item_s) => item_s.id === parseInt(batchInfo?.sku_id))[0]?.name}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">College:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack">{college_name ? college_name : collegeData.filter((item_s) => item_s.id === parseInt(batchInfo?.college_id))[0]?.college_name} | {batchName ? batchName : batchData.filter((item_s) => item_s.id === parseInt(batchInfo?.batch_id))[0]?.user_group_name}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Date & Time:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack" >{`${extractDateFromDateString(batchInfo.date_time)} | ${calculateEpochTimeInLocalTime(batchInfo.date_time)}`}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Duration:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack" >{batchInfo?.duration_name}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Mentor:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetails" >{mentorData.filter((item_s) => item_s.user_id === parseInt(batchInfo?.mentor_id))[0]?.first_name} {mentorData.filter((item_s) => item_s.user_id === parseInt(batchInfo?.mentor_id))[0]?.last_name}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Domain & Role:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack">
                      {(role_details || specialization_details) && (() => {
                        let roleNames = role_details
                          .filter(item => (typeof batchInfo.role_ids === "string" ? JSON.parse(batchInfo.role_ids) : batchInfo.role_ids).includes(item.id))
                          .map(item => item.name)
                          .join(', ');

                        let specializationNames = specialization_details
                          .filter(item => (typeof batchInfo.spe_ids === "string" ? JSON.parse(batchInfo.spe_ids) : batchInfo.role_ids).includes(item.id))
                          .map(item => item.name)
                          .join(', ');

                        // Combine roleNames and specializationNames
                        let combinedNames = `${roleNames}, ${specializationNames}`;

                        // Check if combined length exceeds 50 characters
                        let displayNames = combinedNames.length > 50 ? `${combinedNames.slice(0, 35)}...` : combinedNames;

                        return displayNames;
                      })()}
                    </Typography>
                  </div>

                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Keywords:</Typography>

                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack">
                      {(typeof batchInfo.keywords === "string" ? JSON.parse(batchInfo.keywords) : batchInfo.keywords).map((item, index, array) => (
                        <span key={index}>
                          {item}
                          {index !== array.length - 1 && ', '}
                        </span>
                      ))}
                    </Typography>

                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Session venue:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetails" sx={{color: batchInfo?.link.startsWith("http://") || batchInfo?.link.startsWith("https://") ? Color.primary1 : Color.blueBlack, cursor : batchInfo?.link.startsWith("http://") || batchInfo?.link.startsWith("https://") ? "pointer" : "none"}}
                    onClick={(event) => {
                      event.stopPropagation();
                      const mentorLinkedInLink = batchInfo.link;
                      if (mentorLinkedInLink.startsWith("http://") || mentorLinkedInLink.startsWith("https://")) {
                        window.open(mentorLinkedInLink);
                      } else {
                        // window.open("https://" + mentorLinkedInLink);
                      }
                    }}
                     >{batchInfo?.link}</Typography>
                  </div>
                </CardContent>
              </Box>
            );
          })}

        </>}

      </div>
      {show === true && (props.path === "/GroupSession" || props.path === '/College1x1BatchDetails') ?
        (props.mainFile_modal(show, setShow, "Add")) : (<></>)

      }
    </div>
  )
}

