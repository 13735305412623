import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "../../pages/SupportTicket/TableStyling.css"
import ModalSt from "../College1x1StudentDetails/ModalSt"
import { Typography } from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles';
import Service from '../../../services/httpService';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { saveAs } from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import CardActions from '@mui/material/CardActions';
import Loader from '../../reusables/Loader/Loader';
// import data from './sampleFeedback';
import ExcelJS from 'exceljs';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import exportFromJSON from 'export-from-json'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
const MentorNotActivatedAndApprovalAwaited = (props) => {
    const service = new Service()
    const navigate = useNavigate();
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState('');
    const [studentDetails, setStudentDetails] = useState([])
    const { batchid, batchName, college_data_name, college_data_id, process_id, sku_name, searched } = props
    console.log("process_id in create table", process_id);
    console.log("sku_name in create table", sku_name);
    console.log("searched in create table", searched);
    const [openModal, setOpenModal] = useState(false)
    const tableTitles = ["First Name", "Last Name", "Email Id", "Phone Number"];
    const [studentsDetailsClick, setStudentsDetailsClick] = useState({})
    const callModalToOpen = () => {
        console.log("Modal open fucntion called")
        setOpenModal(true);
    }
    let detailsToShow = location.state?.detailsToShow
    console.log("details to shoe", detailsToShow);
    const [warRoomDetailsSelected, setWarRoomDetailsSelected] = useState([])
    const [roleTypeData, setRoleTypeData] = useState([])
    const [mentorFeedbackDetails, setMentorFeedbackDetails] = useState([])
    const [studentFeedbackDetails, setStudentFeedbackDetails] = useState([])
    const [copyList, setCopyList] = useState(detailsToShow)
    const mentorMissed = new Date().getTime();
    const currentDate = new Date();
    const [loading, setLoading] = useState(true);

    const [feedbackFields, setFeedbackFields] = useState({})
    const [studentFeedbackFields, setStudentFeedbackFields] = useState({})

    // feedback fields
    const [mentorFeedbackFields, setMentorFeedbackFields] = useState([]);

    const [mentorFeedbackMCQs, setMentorFeedbackMCQs] = useState([]);
    // const [selectedOptions, setSelectedOptions] = useState(false)
    const [options, setOptions] = useState([
        { label: 'Activated', value: 'Activated', isSelected: false },
        { label: 'Not Activated', value: 'Not Activated', isSelected: false },
        { label: 'Approval Awaited', value: 'Approval Awaited', isSelected: false },
        { label: 'Blocked/Deactivated', value: 'Blocked/Deactivated', isSelected: false },
    ]);


    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            let studentsDetails = await collegeDataGetter();
            // let warRoomDetails = await warRoomDataGetter();

            let roleType = await roleTypeGetter()
            console.log("datas", { studentsDetails, roleType })
            // Now studentsDetails contains the merged data with the first student unchanged
            console.log("temp data", studentsDetails);
            setWarRoomDetailsSelected(studentsDetails)
            //college getter
            setStudentDetails(studentsDetails)
            data_manupulator_roleType(roleType)
            let temp_time = new Date().getTime()

            if (studentsDetails.length != 0 && searched) {
                const updatedOptions = options.map(option => ({
                    ...option,
                    isSelected: option.value === searched ? true : false
                }));
                setOptions(updatedOptions);

                console.log("inside if condition", searched);
                let filteredList;
                if (searched === "Student Absent") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Done" && item.is_absent === 1
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Expired Bookings") {
                    filteredList = studentsDetails.filter((item) =>
                        (item.status === "Initialized") && (temp_time > item.student_last_slot_cutoff)
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Scheduled") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Scheduled"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Initialized") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Initialized"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Not Scheduled") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Purchased"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Done") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Done"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Awaited Mentor Feedback") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Feedback"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Ongoing") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "In Process"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "No Action Students") {
                    filteredList = studentsDetails.filter((item) =>
                        item.status === "Purchased"
                    );
                    console.log("Student Absent list", filteredList);
                } else if (searched === "Mentor Absent") {
                    filteredList = studentsDetails.filter((item) =>
                        (new Date().getTime() - new Date(item.slot_date).getTime() + 19800000 >= item.end_time + 900000 && item.status === "Scheduled")
                    );
                    console.log("Mentor Absent list", filteredList);
                } else if (searched === "Mentor Awaited") {
                    filteredList = studentsDetails?.filter((item) =>
                        item.status === "Initialized"
                    );
                } else if (searched === "Awaited Mentor Feedback") {
                    filteredList = studentsDetails?.filter((item) =>
                        item.status === "Feedback"
                    );
                } else if (searched === "Received Mentor Feedback") {
                    filteredList = studentsDetails.filter((item) =>
                        item.mentor_feedback_submitted === 1
                    );
                } else if (searched === "All") {
                    filteredList = studentsDetails
                    console.log("filtered list", filteredList);
                }
                else {
                    filteredList = studentsDetails.filter((item) =>
                        item.student_name?.toLowerCase().includes(searched.toLowerCase()) ||
                        item.mentor_name?.toLowerCase().includes(searched.toLowerCase()) || item.student_name?.includes(searched) ||
                        item.mentor_name?.includes(searched) || item.student_name?.toUpperCase().includes(searched.toUpperCase()) ||
                        item.mentor_name?.toUpperCase().includes(searched.toUpperCase()) ||
                        item.student_roll_no?.includes(searched)
                    );
                }
                console.log("filtered list", filteredList);
                if (filteredList.length === 0) {
                    console.log("i'm here");
                    toast.dismiss();

                    setCopyList([]);
                } else {
                    console.log("now, i'm here");
                    toast.dismiss();
                    setCopyList(filteredList);

                }
            }

            setLoading(false)

        }
        handleEffect();
    }, [])

    console.log("war room details", warRoomDetailsSelected, studentDetails);

    const collegeDataGetter = async () => {
        try {
            const res = await service.get(`/mentor/allDetails`)
            console.log("student details response:", res.mentorList)
            return res.mentorList
        } catch (err) {
            console.log(err)
        }
    }



    const roleTypeGetter = async () => {
        try {
            const res = await service.get('/role?is_admin=1')
            console.log("roleTypeGetter", res.data)
            setRoleTypeData([...res.data])
            return res.data
        }

        catch (err) {
            console.log(err)
        }
    }

    const data_manupulator_roleType = (roleType) => {
        console.log("SKU data:", roleType)
        let temp_role = JSON.parse(JSON.stringify(roleType));
        temp_role = temp_role.map((role) => {
            role.table = "sku"
            return role
        });

        console.log("temp role:", temp_role)
        // setRoleTypeDataMulti(temp_sku)
    }




    const requestSearch = (searched, isSelected) => {
        console.log("searched", searched);
        let filteredList;

        filteredList = detailsToShow.filter((item) =>
            item.first_name?.toLowerCase().includes(searched.toLowerCase()) ||
            item.last_name?.toLowerCase().includes(searched.toLowerCase()) ||
            item.email?.includes(searched.toLowerCase()) || item.ph_no?.includes(searched.toLowerCase()) || item.ph_no?.includes(searched.toLowerCase())
        );

        console.log("filtered list", filteredList);
        if (filteredList.length === 0) {
            console.log("i'm here");
            toast.dismiss();
            setCopyList([]);

        } else {
            toast.dismiss();
            console.log("now, i'm here");
            setCopyList(filteredList);

        }
    }

    console.log("click students data", studentsDetailsClick);
    console.log("copyList", copyList);
    console.log("mentor details", mentorFeedbackDetails);
    const fileName = 'download'
    const exportType = 'xls'





    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is: ", studentDetails);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        const hardcodedRow = ['user_id', 'student_first_name', 'student_email', 'student_last_name', 'student_phone', 'is_verified'];

        // Add the column headers
        worksheet.addRow([...hardcodedRow]);

        // Add data rows
        (copyList.length > 0 ? copyList : studentDetails).forEach((row, index) => {
            const rowData = [...Object.values(row)];
            worksheet.addRow(rowData);
        });
        // Save workbook to a Buffer
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Convert Buffer to Blob
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Save the Blob as a downloadable file
            const currentDate = new Date();
            const filename = `${searched}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
            saveAs(blob, filename);
        });
    };
    const goBack = () => {
        console.log("Back Button is Clicked Now")
        navigate(-1);
    }


    return (
        <>
            <div className="student-details-parent" style={{ marginTop: "3rem" }}>
                <ToastContainer position="top-center" limit={1} />
                <SideNavigationBar />
                <div className="topHeadings" style={{ display: "flex" }}>
                    <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />

                    <p style={{ color: Color.neutralMidGrey }}>Mentors</p>
                </div>
                {loading ? (<div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div>) : (
                    <>
                        <div style={{ display: "flex", marginTop: "2rem" }}>
                            <div className='searchTab' style={{ alignItems: 'center', width: "100%", height: "30px", marginBottom: ".5rem" }}>
                                <input style={{ border: "none", height: "30px", width: "100%" }}
                                    className='searchingText searchTab'
                                    type='text'
                                    onChange={(e) => requestSearch(e.target.value)}
                                    placeholder='Search for name, roll number, etc'
                                />
                            </div>
                            <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
                        </div>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {tableTitles.map((title, index) => (
                                            <TableCell align='center'
                                                key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                                            >
                                                {title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(copyList.length > 0 ? copyList : detailsToShow)?.map((details, index) => {
                                        return (
                                            <>
                                                <TableRow
                                                    style={{ cursor: "pointer" }}>
                                                    <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                                        <p style={{ color: "black" }}>{details.first_name} </p>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                                        <p style={{ color: "black" }}>{details.last_name} </p>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16 }}>
                                                        {details.email_id ? details.email_id : details.email}
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16 }}>
                                                        {details.ph_no}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

            </div>
            {/* <ModalSt studentFeedbackFields={studentFeedbackFields} feedbackFields={feedbackFields} studentsDetailsClick={studentsDetailsClick} setStudentsDetailsClick={setStudentsDetailsClick} show={openModal} setShow={setOpenModal} /> */}
        </>
    );
};

export default MentorNotActivatedAndApprovalAwaited;