import React, { useEffect, useState } from "react";
import ServiceCM from '../../../services/cartmoduleServices';
import Service from '../../../services/httpService';
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
import Papa from 'papaparse';
import { saveAs } from "file-saver";

import AddIcon from '@mui/icons-material/Add';
import Modal from "react-bootstrap/Modal";

import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../../reusables/Loader/Loader";
import { IconButton } from "@mui/material";
import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext';
import { formatConvertermonthfirst as DateConverter } from '../../../functions/date/formatCovertermonthfirst'

import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';


const Coupon = () => {
    const navigate = useNavigate();

    const [rowsData, setRowsData] = useState([])
    const [couponData, setCouponData] = useState([])
    const [skuData, setSkuData] = useState([])
    const [skuDataMulti, setSkuDataMulti] = useState([])
    const [bundleData, setBundleData] = useState([])
    const [bundleDataMulti, setBundleDataMulti] = useState([])
    const [formInputs, setFormInputs] = useState({ coupon_code: "", name: "", start_date: "", end_date: "", min_order_value: "", max_discount_value: "", discount: {}, sku_ids: [], marketing_user_group: "", use_start_date: "", use_end_date: "" })
    //get current date and time
    const currentDate = new Date();
    const [loading, setLoading] = useState(true);

    const [from_table, setFrom_table] = useState();
    const [isPercentage, setIsPercentage] = useState(false);


    const [JDfiles, setJDFiles] = useState([]);

    const serviceCM = new ServiceCM()
    const serviceSA = new Service()
    let count = 0;
    const headCells = [
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'Coupon Code'
        },
        {
            id: 'heading3',
            numeric: 'right',
            disablePadding: false,
            label: 'Name'
        },
        {
            id: 'heading4',
            numeric: 'right',
            disablePadding: false,
            label: 'Start Date'
        },
        {
            id: 'heading4',
            numeric: 'right',
            disablePadding: false,
            label: 'End Date'
        }

    ];

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }

            let coupon = await couponGetter();
            let bundle = await bundleGetter();
            let sku = await SKUGetter();

            console.log("odonil", { coupon }, { bundle }, { sku })
            data_manupulator_coupon(coupon);
            data_manupulator_bundle(bundle);
            data_manupulator_sku(sku);
            setLoading(false)
        }

        handleEffect();
    }, [])






    useEffect(() => {
        const setting_data = () => {

            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setFormInputs({ coupon_code: "", name: "", start_date: "", end_date: "", min_order_value: "", max_discount_value: "", discount: {}, sku_ids: [], marketing_user_group: "", use_start_date: "", use_end_date: "" })
            }
            else {

                console.log("Inside else of useEffect")
                console.log({ rowsData })
                console.log(couponData, couponData.length, from_table)

                if (couponData.length > 0) {
                    setFormInputs({
                        coupon_code: couponData[from_table?.index]?.coupon_code,
                        name: couponData[from_table?.index]?.name,
                        start_date: couponData[from_table?.index]?.start_date.substring(0, 10),
                        end_date: couponData[from_table?.index]?.end_date.substring(0, 10),
                        min_order_value: couponData[from_table?.index]?.min_order_value,
                        max_discount_value: couponData[from_table?.index]?.max_discount_value,
                        discount: (JSON.parse(couponData[from_table?.index]?.discount))?.discount,
                        sku_ids: (JSON.parse(couponData[from_table?.index]?.sku_ids).sku_ids).filter(value => value.table === 'sku' ? value.name = skuData.map(sku => sku.id === value.id ? sku.name : "").filter(val => val !== "")[0] : ""),
                        bundle_ids: (JSON.parse(couponData[from_table?.index]?.sku_ids).sku_ids).filter(value => value.table === 'bundle' ? value.name = bundleData.map(bundle => bundle.id === value.id ? bundle.name : "").filter(val => val !== "")[0] : ""),
                        marketing_user_group: couponData[from_table?.index]?.marketing_user_group,
                        use_start_date: couponData[from_table?.index]?.use_start_date.substring(0, 10),
                        use_end_date: couponData[from_table?.index]?.use_end_date.substring(0, 10)
                    })

                    setIsPercentage((JSON.parse(couponData[from_table?.index]?.discount))?.discount.value === null ? true : false)
                }
            }

        }
        setting_data();
    }, [from_table])



    const couponGetter = async () => {
        try {
            const res = await serviceCM.get('/coupon')
            console.log("Duration RESPONSE:", res.data)
            setRowsData([...res.data])
            setCouponData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const bundleGetter = async () => {
        try {
            const res = await serviceCM.get('/bundle')
            console.log("Duration RESPONSE:", res.data)
            setRowsData([...res.data])
            setBundleData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const SKUGetter = async () => {
        try {
            const res = await serviceCM.get('/sku')
            console.log("Duration RESPONSE:", res.data)
            setSkuData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }




    const data_manupulator_coupon = (coupon) => {
        console.log("Coupon data:", coupon)
        // console.log("Activity data:", activity)
        let temp_rowsData = JSON.parse(JSON.stringify(coupon));
        // let temp_activity = JSON.parse(JSON.stringify(activity));


        temp_rowsData = temp_rowsData.map((row) => {
            // row.act_type_id = temp_activity?.map(act => act.id === row.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]
            row.start_date = DateConverter(row.start_date)
            row.end_date = DateConverter(row.end_date)
            // delete row.act_type_id
            delete row.is_deleted
            delete row.min_order_value
            delete row.max_discount_value
            delete row.discount
            delete row.sku_ids
            delete row.marketing_user_group
            delete row.use_start_date
            delete row.use_end_date
            return row
        });
        console.log("temp rows:", temp_rowsData)
        setRowsData(temp_rowsData)

    }
    const data_manupulator_bundle = (bundle) => {
        console.log("SKU data:", bundle)
        let temp_bundle = JSON.parse(JSON.stringify(bundle));


        temp_bundle = temp_bundle.map((bundle) => {
            // bundle.act_type_id = temp_activity?.map(act => act.id === bundle.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]
            bundle.table = "bundle"

            delete bundle.actual_price
            delete bundle.discounted_price
            delete bundle.footer_desc
            delete bundle.header_desc
            delete bundle.image_url
            delete bundle.is_deleted
            delete bundle.skus
            return bundle
        });
        console.log("temp bundle:", temp_bundle)
        setBundleDataMulti(temp_bundle)

    }
    const data_manupulator_sku = (sku) => {
        console.log("SKU data:", sku)
        // console.log("Activity data:", activity)
        let temp_sku = JSON.parse(JSON.stringify(sku));
        // let temp_activity = JSON.parse(JSON.stringify(activity));


        temp_sku = temp_sku.map((sku) => {
            // sku.act_type_id = temp_activity?.map(act => act.id === sku.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]
            sku.table = "sku"
            // delete sku.act_type_id
            delete sku.act_type_id
            delete sku.description
            delete sku.duration
            delete sku.is_deleted
            delete sku.price

            return sku
        });
        console.log("temp sku:", temp_sku)
        setSkuDataMulti(temp_sku)

    }


    const handleDeleteMul = (index, id) => {
        console.log("delete ~ index", index)
        console.log("delete ~ id", id)
        let list_rowsData = [...rowsData];
        let list_couponData = [...couponData];
        // let index = JSON.parse(JSON.stringify(index))
        // let id = JSON.parse(JSON.stringify(id))


        serviceCM
            .delete("/coupon", {
                data: { "ids": id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list_rowsData = list_rowsData.filter((item) => id.indexOf(item.id) < 0);
                setRowsData(list_rowsData)
                list_couponData = list_couponData.filter((item) => id.indexOf(item.id) < 0);
                console.log(".then ~ list_couponData:", list_couponData)
                setCouponData(list_couponData)
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
        console.log({ index }, { id })
    }

    const data_getter = (name, id, index, activity) => {

        console.log("Dude here's your name:", name);
        console.log("Dude here's your id:", id);
        console.log("Dude here's your index:", index);

        if (name === "delete_mul") {
            console.log("inside delete mul if")
            handleDeleteMul(index, id)
        }
        if (activity === "add") {
            setFormInputs({})
        }
        setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
    }



    const Modal_bundle = (show, setShow, mode) => {

        // useEffect(() => {
        //     console.log("Brother")
        //     const handleEffect = async () => {

        //         setFormInputs({...formInputs, activity_type_id: skuData[from_table?.index].activity_type_id})

        //     }

        //     handleEffect();
        // }, [from_table?.index])

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }

        const modalSubmit = () => {
            if (from_table.index === undefined)
                handlePostRequest();
            else
                handlePutRequest();

            setShow(false)
        }



        const handleFileInput = (event) => {
            const f = event.target.files;
            let filesArray = [...JDfiles];
            for (let i = 0; i < f.length; i++) {
                filesArray.push(f[i]);
            }
            setJDFiles(filesArray);
        };

        const handleRemoveFile = (fileIndex) => {
            console.log("Removed id:", rowsData[from_table?.index]?.id)

            try {
                let res = serviceCM.delete("/job_name/file",
                    {
                        data: {
                            jd_id: rowsData[from_table?.index]?.id
                        }
                    })


                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                const filesArray = [...JDfiles];
                filesArray.splice(fileIndex, 1);
                setJDFiles(filesArray);

            } catch (err) {
                console.log("Errog Msg: ", err);

            }

        };

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }


        const onHide = () => {
            setShow(false)
        }


        const handleChange = (e, index) => {

            console.log("name from form  is:", e.target.name);
            console.log("value from form  is:", e.target.value);
            const { name, value } = e.target

            if (name === "name") {
                console.log("name")
                setFormInputs({ ...formInputs, name: value })
            }
            else if (name === "coupon_code") {
                console.log("coupon_code")
                setFormInputs({ ...formInputs, coupon_code: value })
            }
            else if (name === "start_date") {
                console.log("start_date")
                setFormInputs({ ...formInputs, start_date: value })
            }
            else if (name === "end_date") {
                console.log("end_date")
                setFormInputs({ ...formInputs, end_date: value })
            }
            else if (name === "min_order_value") {
                console.log("min_order_value")
                setFormInputs({ ...formInputs, min_order_value: value })
            }
            else if (name === "max_discount_value") {
                console.log("max_discount_value")
                setFormInputs({ ...formInputs, max_discount_value: value })
            }
            else if (name === "discount_value") {
                console.log("discount_value")
                setFormInputs(prevState => ({
                    ...prevState,
                    discount: {
                        ...prevState.discount,
                        value: value,
                        percent: null
                    }
                }));
            }
            else if (name === "discount_percent") {
                console.log("discount_percent")
                setFormInputs(prevState => ({
                    ...prevState,
                    discount: {
                        ...prevState.discount,
                        percent: value,
                        value: null
                    }
                }));
            }
            else if (name === "marketing_user_group") {
                console.log("marketing_user_group")
                setFormInputs({ ...formInputs, marketing_user_group: value })
            }
            else if (name === "use_start_date") {
                console.log("use_start_date")
                setFormInputs({ ...formInputs, use_start_date: value })
            }
            else if (name === "use_end_date") {
                console.log("use_end_date")
                setFormInputs({ ...formInputs, use_end_date: value })
            }
        }

        const onSelectPercentage = (e) => {
            e.preventDefault();
            setIsPercentage(true);

        }
        const onSelectAmount = (e) => {
            e.preventDefault();
            setIsPercentage(false);

        }


        const onSelect_sku = (e) => {
            console.log(e)
            setFormInputs({ ...formInputs, sku_ids: e })
        }
        const onRemove_sku = (e) => {
            console.log(e)
            setFormInputs({ ...formInputs, sku_ids: e })

        }
        const onSelect_bundle = (e) => {
            console.log(e)
            setFormInputs({ ...formInputs, bundle_ids: e })

        }
        const onRemove_bundle = (e) => {
            console.log(e)
            setFormInputs({ ...formInputs, bundle_ids: e })

        }


        return (
            <Modal
                show={show}
                onHide={() => onHide()}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '95%',
                    minWidth: '800px',
                    minHeight: '500px'
                }}>
                    <div>
                        <form className="Entire-form" style={{ display: "flex", flexDirection: "columns", justifyContent: 'center' }}>
                            <div className="right-side-buttons-jd row">
                                <div className="headerComp" sx={{ marginTop: '3%' }} >
                                    <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                    <p className="compTitle">Coupon</p>
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Name<span>*</span></label>
                                    <input name="name" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.name} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Coupon Code<span>*</span></label>
                                    <input name="coupon_code" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.coupon_code} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Start Date<span>*</span></label>
                                    <input name="start_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.start_date} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">End Date<span>*</span></label>
                                    <input name="end_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.end_date} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Minimum Order Value<span>*</span></label>
                                    <input name="min_order_value" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.min_order_value} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Maximum Discount Value<span>*</span></label>

                                    <input name="max_discount_value" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.max_discount_value} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>



                            <div className='right-side-buttons-jd row' style={{ paddingTop: '4%', paddingBottom: '5%' }}>
                                <div className="form-group">
                                    <label className="inputLabel">SKU<span>*</span></label>
                                    <Multiselect
                                        disable={mode === "View" ? true : false}
                                        displayValue="name"
                                        selectedValues={formInputs.sku_ids}
                                        onRemove={(e) => onRemove_sku(e)}
                                        onSelect={(e) => onSelect_sku(e)}
                                        options={skuDataMulti}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Bundle<span>*</span></label>
                                    <Multiselect
                                        disable={mode === "View" ? true : false}
                                        displayValue="name"
                                        selectedValues={formInputs.bundle_ids}
                                        onRemove={(e) => onRemove_bundle(e)}
                                        onSelect={(e) => onSelect_bundle(e)}
                                        options={bundleDataMulti}
                                    />
                                </div>
                                <div className="form-group">
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                        <label className="inputLabel">{isPercentage ? "Discount" : "Discount"} <span>*</span></label>
                                        {/* Toggle buttons */}
                                        {console.log("isPercentage is", isPercentage)}
                                        <div className="btn-group btn-toggle" style={{ alignContent: "center", margin: "0 auto", alignItems: "center", justifyContent: "center", justifySelf: "center" }} >
                                            <button className={`btn btn-md border selected ${isPercentage === true ? "btn-selected" : ""}`} disabled={mode === "View" ? true : false} onClick={(e) => onSelectPercentage(e)}>Percent</button>
                                            <button className={`btn btn-md  border ${isPercentage === false ? "btn-selected" : ""} `} disabled={mode === "View" ? true : false} onClick={(e) => onSelectAmount(e)}>Amount</button>
                                        </div>
                                    </div>

                                    <input name={isPercentage ? "discount_percent" : "discount_value"} disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder={isPercentage ? "Enter Percent" : "Enter Amount"} value={isPercentage ? formInputs.discount?.percent : formInputs.discount?.value} onChange={(e) => handleChange(e)} />
                                </div>
                                {/* <div className="form-group">
                                <label className="inputLabel">Discount Percent<span>*</span></label>
                                <input name="discount_percent" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.discount?.percent} onChange={(e) => handleChange(e)} />
                            </div> */}
                                <div className="form-group">
                                    <label className="inputLabel">Marketing User Group<span>*</span></label>
                                    <input name="marketing_user_group" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.marketing_user_group} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Use Start Date<span>*</span></label>
                                    <input name="use_start_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.use_start_date} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Use End Date<span>*</span></label>
                                    <input name="use_end_date" type="date" disabled={mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.use_end_date} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            {/* <div className="headerComp" >
                            <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                            <p className="compTitle">Coupon</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: 'row' }}>
                            <div>

                                <div className="form-group">
                                    <label className="inputLabel">Name<span>*</span></label>
                                    <input name="name" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.name} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Coupon Code<span>*</span></label>
                                    <input name="coupon_code" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.coupon_code} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Start Date<span>*</span></label>
                                    <input name="start_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.start_date} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">End Date<span>*</span></label>
                                    <input name="end_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.end_date} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Minimum Order Value<span>*</span></label>
                                    <input name="min_order_value" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.min_order_value} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Maximum Discount Value<span>*</span></label>
                                    <input name="max_discount_value" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.max_discount_value} onChange={(e) => handleChange(e)} />
                                </div>

                            </div>
                            <div style={{ marginLeft: "100px" }}>
                                <div className="form-group">
                                    <label className="inputLabel">SKU<span>*</span></label>
                                    <Multiselect
                                        disable={mode === "View" ? true : false}
                                        displayValue="name"
                                        selectedValues={formInputs.sku_ids}
                                        onRemove={(e) => onRemove_sku(e)}
                                        onSelect={(e) => onSelect_sku(e)}
                                        options={skuDataMulti}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Bundle<span>*</span></label>
                                    <Multiselect
                                        disable={mode === "View" ? true : false}
                                        displayValue="name"
                                        selectedValues={formInputs.bundle_ids}
                                        onRemove={(e) => onRemove_bundle(e)}
                                        onSelect={(e) => onSelect_bundle(e)}
                                        options={bundleDataMulti}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Discount Value<span>*</span></label>
                                    <input name="discount_value" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.discount?.value} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Discount Percent<span>*</span></label>
                                    <input name="discount_percent" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.discount?.percent} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Marketing User Group<span>*</span></label>
                                    <input name="marketing_user_group" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={formInputs.marketing_user_group} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Use Start Date<span>*</span></label>
                                    <input name="use_start_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.use_start_date} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label className="inputLabel">Use End Date<span>*</span></label>
                                    <input name="use_end_date" type="date" disabled={mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.use_end_date} onChange={(e) => handleChange(e)} />
                                </div>
                            </div> */}

                            {/* 
                        </div> */}
                            {/* SUBMIT BUTTON */}

                        </form>
                        {mode === "View" ? <></> : <div className="btn-comp3" style={{ marginRight: "45%" }}>
                            <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
                        </div>}
                    </div>

                </Modal.Body>
            </Modal >

        )
    }

    const headers = {
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
    };


    const handlePostRequest = async () => {
        let body = { ...formInputs }
        console.log({ body })
        body.sku_ids = body.sku_ids.map(sku => {
            const { name, ...rest } = sku; // Use destructuring to remove age key from user object
            return rest; // Return a new user object without the age key
        })
        console.log({ body })
        body.bundle_ids = body.bundle_ids.map(sku => {
            const { name, ...rest } = sku; // Use destructuring to remove age key from user object
            return rest; // Return a new user object without the age key
        })

        body.sku_ids = body.sku_ids.concat(body.bundle_ids)
        setFormInputs({ ...formInputs, sku_ids: body.sku_ids })
        body.user_group_ids = [body.marketing_user_group]
        delete body.bundle_ids
        delete body.marketing_user_group
        // delete body.sku_ids
        console.log(body)
        await serviceCM
            .post("/coupon", body)
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")


                let temp = JSON.parse(JSON.stringify(couponData));

                temp = ([{
                    id: res?.data?.id,
                    coupon_code: formInputs.coupon_code,
                    name: formInputs.name,
                    start_date: formInputs.start_date,
                    end_date: formInputs.end_date,
                    min_order_value: formInputs.min_order_value,
                    max_discount_value: formInputs.max_discount_value,
                    discount: JSON.stringify({ discount: formInputs.discount }),
                    sku_ids: JSON.stringify({ sku_ids: body.sku_ids }),
                    marketing_user_group: formInputs.marketing_user_group,
                    use_start_date: formInputs.use_start_date,
                    use_end_date: formInputs.use_end_date
                }, ...temp])

                console.log("Put temp value:", temp)
                setCouponData(temp)
                data_manupulator_coupon(temp)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }

    const handlePutRequest = async () => {
        console.log("PUT Request")
        let body = { ...formInputs }
        body.id = from_table?.id
        body.sku_ids = body.sku_ids.map(sku => {
            const { name, ...rest } = sku; // Use destructuring to remove age key from user object
            return rest; // Return a new user object without the age key
        })

        body.bundle_ids = body.bundle_ids.map(sku => {
            const { name, ...rest } = sku; // Use destructuring to remove age key from user object
            return rest; // Return a new user object without the age key
        })

        body.sku_ids = body.sku_ids.concat(body.bundle_ids)
        setFormInputs({ ...formInputs, sku_ids: body.sku_ids })
        body.user_group_ids = [body.marketing_user_group]
        delete body.bundle_ids
        delete body.marketing_user_group
        // delete body.sku_ids
        console.log(body)
        // body.push({
        //     id: from_table?.id
        // })
        serviceCM
            .put("/coupon", body)
            .then((res) => {

                console.log("Response from backend is", JSON.stringify(res, null, 2));
                // res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")



                let temp = JSON.parse(JSON.stringify(couponData));
                // let temp = JSON.parse(JSON.stringify(rowsData));

                temp[from_table?.index].coupon_code = formInputs.coupon_code
                temp[from_table?.index].name = formInputs.name
                temp[from_table?.index].start_date = formInputs.start_date
                temp[from_table?.index].end_date = formInputs.end_date
                temp[from_table?.index].min_order_value = formInputs.min_order_value
                temp[from_table?.index].max_discount_value = formInputs.max_discount_value
                temp[from_table?.index].discount = JSON.stringify({ discount: formInputs.discount })
                temp[from_table?.index].sku_ids = JSON.stringify({ sku_ids: body.sku_ids })
                temp[from_table?.index].marketing_user_group = formInputs.marketing_user_group
                temp[from_table?.index].use_start_date = formInputs.use_start_date
                temp[from_table?.index].use_end_date = formInputs.use_end_date


                console.log("Put temp value:", temp)
                setCouponData(temp)
                data_manupulator_coupon(temp)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }


    // const handleDelete = async (jd_id, index) => {
    //     console.log("delete", jd_id)
    //     let list = [...rowsData];

    //     console.log("listtttt1", list);
    //     serviceCM
    //         .delete("/duration", {
    //             data: { "ids": id }
    //         })
    //         .then((res) => {
    //             console.log("Response from backend is", res);
    //             res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
    //             list.splice(index, 1);
    //             setRowsData(list)
    //             console.log("listtttt2", list);

    //         })
    //         .catch((err) => {
    //             console.log("Errog Msg: ", err);

    //             console.log(err.response.data.message)
    //             window.alert(err.response.data.message)

    //         });
    // }

    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", rowsData);
        // Simulate a button click after 2 seconds
        const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


    }


    return (<div >
        <RowsContext.Provider value={rowsData}>
            <TopBar />
            <SideNavigationBar />
            {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
                {console.log("from_table.index", from_table?.index)}
                {console.log("sku value in formInputs", formInputs?.skus)}
                {console.log("footer desc value in formInputs", formInputs?.footer_desc)}
                {console.log({ formInputs })}
                {console.log({ rowsData })}
                {console.log({ skuData })}
                {console.log({ bundleData })}
                <EnhancedTable mainFile_modal={Modal_bundle} data_getter={data_getter} fileDownload={fileDownload} headCells={headCells} count={count} path={window.location.pathname} />
            </>}
        </RowsContext.Provider>
    </div>
    )
}


export default Coupon