import React from "react";
import formatConverterSip from "../Reusable_functions/date/formatConverterSip";
import formatConverterSipDash from "../Reusable_functions/date/formatConverterSipDash";
import { List } from "../Reusable_functions/List";

function filterContext(data) {
  return data.filter(ele => ele.part === 'Context')
}

function filterAction(data) {
  return data.filter(ele => ele.part === 'Action')
}

function filterResult(data) {
  return data.filter(ele => ele.part === 'Result')
}

function filterData(projectData, props) {
  let data =
  {
    Context: (filterContext(projectData)?.map(ele => {
      return (
        <p id="resumeTable-p" style={{ textAlign: "justify", marginBottom: '0px' }}>
          <ul className="resumeTable-ul"
            style={{
              marginLeft: "17px",
              listStyleType: props.Bullet ? "square" : "",
              lineHeight: props.LineHeight,
              fontSize: props.FontSize
            }}
          >
            <li style={{ fontFamily: props.fontS }}>
              {ele.bullet_content}
            </li>
          </ul>
        </p>
      )
    })),
    Action: filterAction(projectData)?.map(ele => {
      return (
        <p id="resumeTable-p" style={{ textAlign: "justify", marginBottom: '0px' }}>
          <ul className="resumeTable-ul"
            style={{
              marginLeft: "17px",
              listStyleType: props.Bullet ? "square" : "",
              lineHeight: props.LineHeight,
              fontSize: props.FontSize
            }}
          >
            <li style={{ fontFamily: props.fontS }}>
            {ele.bullet_content}
            </li>
          </ul>
        </p>
      )
    }),
    Result: filterResult(projectData)?.map(ele => {
      return (
        <p id="resumeTable-p" style={{ textAlign: "justify", marginBottom: '0px' }}>
          <ul className="resumeTable-ul"
            style={{
              marginLeft: "17px",
              listStyleType: props.Bullet ? "square" : "",
              lineHeight: props.LineHeight,
              fontSize: props.FontSize

            }}
          >
            <li style={{ fontFamily: props.fontS }}>
              <>
              {ele.bullet_content}
              </>
            </li>
          </ul>
        </p>
      )
    }),
  }
  return data
}

function Intership2(props) {
  const  sipData  = [
    {
        "basic_info": {
            "company_name": "Company",
            "start_date": "2023-01-10T18:30:00.000Z",
            "end_date": "2023-02-10T18:30:00.000Z",
            "still_working": 0,
            "domain": "Analytics",
            "designation": "Designation"
        },
        "sub_section_detail": {
            "sub_section_id": 651,
            "sub_section_order": 1,
            "is_on": 1
        },
        "projects": [
            {
                "projectDetail": {
                    "sub_section_id": 651,
                    "project_id": 1,
                    "project_order": 1,
                    "project_is_on": 1
                },
                "project_data": [
                    {
                        "bullet_id": 2151,
                        "part": "Action",
                        "bullet_order": 0,
                        "bullet_content": "Action"
                    },
                    {
                        "bullet_id": 2152,
                        "part": "Result",
                        "bullet_order": 0,
                        "bullet_content": "Result"
                    },
                    {
                        "bullet_id": 2148,
                        "part": "Context",
                        "bullet_order": 1,
                        "bullet_content": "Context"
                    }
                ]
            }
        ]
    }
]
  console.log("sipData", sipData)
  console.log("props in internship flow:", props)
  const name = props.name;
  let Details = [];
  if (sipData && sipData?.length > 0) {
    sipData?.map((value, index) => {
      let basicInfo = value.basic_info
      let Projects = value.projects
      Details.push({
        Info: basicInfo,
        ProjectData: Projects?.map(proj => (filterData(proj.project_data, props)))
      })
    }
    )
    console.log("MyDetails", Details)
  }
  else {
    Details = [
      {
        Role: (
          <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS }}>
            {/* {props.previewText} */}
            {/* {props.text} */}
            Liabilities Product Analytics
          </p>
        ),
        Desc: (
          <p id="resumeTable-p" style={{ textAlign: "justify", marginBottom: '0px' }}>
            <ul className="resumeTable-ul"
              style={{
                marginLeft: "17px",
                listStyleType: props.Bullet ? "square" : "",
                lineHeight: props.LineHeight,
                fontSize: props.FontSize
              }}
            >
              <li style={{ fontFamily: props.fontS }}>
                Analyzed Corporate Salary Portfolio and devised Forecasting Model for gathering insights about existing and future fund flows. Key measures entailed debits, credits, and their interplay on balance retention.
              </li>
              <li style={{ fontFamily: props.fontS }}>
                Formulated Monitoring Framework (2 x 2 Matrix) that grouped
                companies into different Quadrants based on ‘Time to/elapsed since
                Point-of-Convergence’ (X-Axis) and ‘Retention to Available Amount’
                (Y-Axis).
              </li>
              <li style={{ fontFamily: props.fontS }}>
                Recommended reestablishing contact and offering preferential
                services, schemes, & waivers to ‘win-back’ accounts under
                Quadrants 3 & 4 that were deemed critical due to declining
                balance-levels over time.
              </li>
            </ul>
          </p>
        ),
      },
    ];
  }
  return (
    <div>
      <table
        className="IntershipBox New  ResumeTable"
        // style={{ width: props.TableWidth ? "105%" : "100%" }}
        style={{
          width: props.minwidth ? props.minwidth : props.TableWidth
          // width:props.minwidth+"%",
        }}
      // style={{ width: props.widths+"px" }}
      >
        <tr>
          <th className="Resumetable-th"
            id="SIP"
            colspan="6"
            style={{
              backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
              color: props.ribbonColor ? props.ribbonColor["Color"] : "#000",
              fontSize: props.FontSize,
              fontFamily: props.fontS,
              // border: props.styleIntership ? "" : "0.1px solid #000",
              textAlign: props.headerPosition,
              height: props.miniheight,
              // height: '25px',
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
              padding: props.minipadding,
              lineHeight: props.LineHeight
            }}
          >
            {props.name ? props.name : 'SUMMER INTERNSHIP PROGRAMME'}
            {/* {props.text5} */}
          </th>
        </tr>

        {

          Details && Details.length > 0 ? Details?.map((detail, key) => {
            console.log({ Details });
            return (
              <>
                <tr style={{ borderBottom: "solid 0.5px #000" }}>
                  <td className="ResumeTable-td"
                    id="Context"
                    style={{
                      // backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                      width: "40%",
                      fontSize: props.FontSize,
                      fontFamily: props.fontS,
                      padding: "0.2rem",
                      // border: "solid 0.25px #000",
                      fontWeight: "bold",
                      color: props.ribbonColor ? props.ribbonColor["Color"] : "#000",
                      // height:'25px',
                      height: props.miniheight,
                      fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                      lineHeight: props.LineHeight,
                      textAlign: "left"

                      // paddingTop: key===0?"0.16rem":"0rem",
                      // paddingBottom: key===Details.length-1?"0.16rem":"0rem",
                    }}
                  >
                    {/* {console.log("ss",detail.Info?.designation)} */}
                    Company
                  </td>
                  <td className="ResumeTable-td"
                    colspan="2"
                    id="FirmName"
                    style={{
                      // backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                      width: "30%",
                      fontSize: props.FontSize,
                      fontFamily: props.fontS,
                      // padding: "0.2rem",
                      // border: "solid 0.25px #000",
                      fontWeight: "bold",
                      color: props.ribbonColor ? props.ribbonColor["Color"] : "#000",
                      // height:'25px',
                      height: props.miniheight,
                      fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                      lineHeight: props.LineHeight,
                      // paddingTop: key===0?"0.16rem":"0rem",
                      // paddingBottom: key===Details.length-1?"0.16rem":"0rem",
                    }}
                  >
                    Designation
                  </td>
                  <td className="ResumeTable-td"
                    colspan="2"
                    id="Duration"
                    style={{
                      // backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                      fontSize: props.FontSize,
                      fontFamily: props.fontS,
                      // padding: "0.2rem",
                      // border: "solid 0.25px #000",
                      fontWeight: "bold",
                      height: '25px',
                      color: props.ribbonColor ? props.ribbonColor["Color"] : "#000",
                      height: props.miniheight,
                      fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                      lineHeight: props.LineHeight,
                      textAlign: "right"
                      // paddingTop: key===0?"0.16rem":"0rem",
                      // paddingBottom: key===Details.length-1?"0.16rem":"0rem",
                    }}
                  >
                    {console.log("startin", detail.Info?.start_date > 0)}
                    {console.log(formatConverterSip(detail.Info?.start_date?.substring(5, 7)))}
                    {console.log("yo", detail.Info?.start_date?.substring(0, 4))}
                    {console.log(formatConverterSip(detail.Info?.end_date?.substring(5, 7)))}
                    Start Time - End Time
                    {(formatConverterSip(detail.Info?.start_date?.substring(5, 7)))}{detail.Info?.start_date?.substring(0, 4)}{(formatConverterSipDash(detail.Info?.end_date?.substring(5, 7)))}{detail.Info?.end_date?.substring(0, 4)}
                  </td>
                </tr>
                {detail.ProjectData && detail.ProjectData.length > 0 ? detail.ProjectData?.map(project => {
                  return (
                    <>
                      {/* <tr key="1"> */}
                      {/* <td className="ResumeTable-td"
                    style={{
                      fontFamily: props.fontS,
                      fontSize: props.FontSize,
                      // border: "solid 0.25px #000",
                      height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                      // maxWidth: '100px',
                      height: '30px',
                      overflowWrap: 'break-word',

                    }}
                    
                  >
                  {project.Context}
                  </td> */}
                      <tr key="1">
                        <td className="ResumeTable-td"
                          style={{
                            fontFamily: props.fontS,
                            fontSize: props.FontSize,
                            height: props.miniheight + "px", fontSize: props.miniFontSize,
                            // padding: props.minipadding,
                            paddingTop: key === 0 ? "0.16rem" : "0rem",
                            paddingBottom: key === Details.length - 1 ? "0.16rem" : "0rem",
                          }}
                          colSpan={4}>
                          {project.Context}
                          {project.Action}
                          {project.Result}
                          {/* </td> */}
                          {console.log({ key })}
                        </td>
                      </tr>
                    </>)
                }

                ) : <>
                  <tr key="1">
                    <td className="ResumeTable-td"
                      style={{
                        fontFamily: props.fontS,
                        fontSize: props.FontSize,
                        // border: "solid 0.25px #000",
                        height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                        padding: props.minipadding,
                        // maxWidth: '100px',
                        height: '30px',
                        overflowWrap: 'break-word',

                      }}

                    >
                      Liabilities Product Analytics
                    </td>
                    <td className="ResumeTable-td"
                      style={{
                        // fontSize: props.FontSmall,
                        fontFamily: props.fontS,
                        fontSize: props.FontSize,
                        // border: "solid 0.25px #000",
                        height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                        // padding: props.minipadding,
                        height: '30px',
                        // fontFamily: props.FontFamily ? "" : "Arial",
                        // fontFamily: props.Arial,
                      }}
                      colSpan={4}
                    >
                      <p id="resumeTable-p" style={{ textAlign: "justify", marginBottom: '0px' }}>
                        <ul className="resumeTable-ul"
                          style={{
                            marginLeft: "17px",
                            listStyleType: props.Bullet ? "square" : "",
                            lineHeight: props.LineHeight,
                            fontSize: props.FontSize,
                            fontFamily: props.fontS
                          }}
                        >
                          {/* <li style={{ fontFamily: props.fontS }}>
            Analyzed Corporate Salary Portfolio and devised Forecasting Model for gathering insights about existing and future fund flows. Key measures entailed debits, credits, and their interplay on balance retention.
          </li>
          <li style={{ fontFamily: props.fontS }}>
          Formulated Monitoring Framework (2 x 2 Matrix) that grouped companies into different Quadrants based on ‘Time to/elapsed since Point-of-Convergence’ (X-Axis) and ‘Retention to Available Amount’ (Y-Axis).
           </li> */}
                          <li style={{ fontFamily: props.fontS, fontSize: props.FontSize, listStyleType: "none" }}>
                          </li>
                        </ul>
                      </p>
                    </td>
                  </tr>
                </>
                }
              </>
            )
          }
          )
            : <></>
        }
      </table>

    </div>
  )
};

export default Intership2;
