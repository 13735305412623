import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../../pages/SupportTicket/TableStyling.css"
import ModalSt from "../College1x1StudentDetails/ModalSt"
import { Typography } from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles';
import Service from '../../../services/httpService';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { saveAs } from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import CardActions from '@mui/material/CardActions';
import Loader from '../../reusables/Loader/Loader';
// import data from './sampleFeedback';
import ExcelJS from 'exceljs';
// import XLSX from 'xlsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import * as XLSX from 'xlsx';
import exportFromJSON from 'export-from-json'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
const MentorStatusFilter = (props) => {
    const service = new Service()
    const navigate = useNavigate();
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState('');
    const [studentDetails, setStudentDetails] = useState([])
    const { batchid, batchName, college_data_name, college_data_id, process_id, sku_name } = props
    console.log("process_id in create table", process_id);
    console.log("sku_name in create table", sku_name);
    const [openModal, setOpenModal] = useState(false)
    const tableTitles = ["Activity Name", "Student Details", "Role", "Specialization", "Mentor Status"];
    const [detailsToShowClick, setdetailsToShowClick] = useState({})

    let detailsToShow = location.state?.detailsToShow
    let searched = location.state?.searched
    console.log("details to shoe", detailsToShow);
    console.log("searched in create table", searched);

    const [warRoomDetailsSelected, setWarRoomDetailsSelected] = useState([])
    const [roleTypeData, setRoleTypeData] = useState([])
    const [mentorFeedbackDetails, setMentorFeedbackDetails] = useState([])
    const [studentFeedbackDetails, setStudentFeedbackDetails] = useState([])
    const [copyList, setCopyList] = useState(detailsToShow)
    const mentorMissed = new Date().getTime();
    const currentDate = new Date();
    const [loading, setLoading] = useState(true);

    // const [selectedOptions, setSelectedOptions] = useState(false)
    const [options, setOptions] = useState([
        { label: 'All', value: 'All', isSelected: false },
        { label: 'Taken', value: 'Taken', isSelected: false },
        { label: 'Missed', value: 'Missed', isSelected: false },
        { label: 'Rescheduled', value: 'Rescheduled', isSelected: false },
        { label: 'Cancelled', value: 'Cancelled', isSelected: false },
        { label: 'Upcoming', value: 'Upcoming', isSelected: false },
    ]);


    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            // let detailsToShow = await collegeDataGetter();
            // let warRoomDetails = await warRoomDataGetter();

            let roleType = await roleTypeGetter()
            console.log("datas", { detailsToShow, roleType })
            // Now detailsToShow contains the merged data with the first student unchanged
            console.log("temp data", detailsToShow);
            setWarRoomDetailsSelected(detailsToShow)
            //college getter
            setStudentDetails(detailsToShow)
            data_manupulator_roleType(roleType)
            let filteredList;

            if (searched) {
                const updatedOptions = options.map(option => ({
                    ...option,
                    isSelected: option.value === searched ? true : false
                }));
                setOptions(updatedOptions);

                console.log("inside if condition", searched);
                if (searched === "Taken") {
                    filteredList = detailsToShow.filter((item) =>
                        item.order_flag === "Done"
                    );
                }
                else if (searched === "Missed") {
                    filteredList = detailsToShow.filter((item) =>
                        item.order_flag === "Missed"
                    );
                }
                else if (searched === "Rescheduled") {
                    filteredList = detailsToShow.filter((item) =>
                        item.order_flag === "Rescheduled"
                    );
                }
                else if (searched === "Cancelled") {
                    filteredList = detailsToShow.filter((item) =>
                        item.order_flag === "Cancel"
                    );
                }
                else if (searched === "Upcoming") {
                    filteredList = detailsToShow.filter((item) =>
                        item.status === "Scheduled"
                    );
                }
                else if (searched === "All") {
                    filteredList = detailsToShow
                }
                else {
                    filteredList = detailsToShow.filter((item) =>
                        item.act_type_name?.toLowerCase().includes(searched.toLowerCase()) ||
                        item.first_name?.toLowerCase().includes(searched.toLowerCase()) ||
                        item.role_name?.includes(searched.toLowerCase()) ||
                        item.specialization_name?.includes(searched.toLowerCase()) ||
                        item.order_flag?.includes(searched.toLowerCase())
                    );
                }
                console.log("filtered list", filteredList);
                if (filteredList.length === 0) {
                    console.log("i'm here");
                    toast.dismiss();

                    setCopyList([]);
                } else {
                    console.log("now, i'm here");
                    toast.dismiss();
                    setCopyList(filteredList);

                }
            }

            setLoading(false)

        }
        handleEffect();
    }, [])

    console.log("war room details", warRoomDetailsSelected, studentDetails);

    const collegeDataGetter = async () => {
        try {
            const res = await service.get(`/mentor/allDetails`)
            console.log("student details response:", res.mentorList)
            return res.mentorList
        } catch (err) {
            console.log(err)
        }
    }

    const roleTypeGetter = async () => {
        try {
            const res = await service.get('/role?is_admin=1')
            console.log("roleTypeGetter", res.data)
            setRoleTypeData([...res.data])
            return res.data
        }
        catch (err) {
            console.log(err)
        }
    }

    const data_manupulator_roleType = (roleType) => {
        console.log("SKU data:", roleType)
        let temp_role = JSON.parse(JSON.stringify(roleType));
        temp_role = temp_role.map((role) => {
            role.table = "sku"
            return role
        });

        console.log("temp role:", temp_role)
        // setRoleTypeDataMulti(temp_sku)
    }

    const requestSearch = (searched, isSelected) => {
        console.log("searched", searched);
        let filteredList;

        // if (detailsToShow.length != 0 && searched) {
            const updatedOptions = options.map(option => ({
                ...option,
                isSelected: option.value === searched ? true : false
            }));
            setOptions(updatedOptions);

            console.log("inside if condition", searched);
            if (searched === "Taken") {
                filteredList = detailsToShow.filter((item) =>
                    item.order_flag === "Done"
                );
            }
            else if (searched === "Missed") {
                filteredList = detailsToShow.filter((item) =>
                    item.order_flag === "Missed"
                );
            }
            else if (searched === "Rescheduled") {
                filteredList = detailsToShow.filter((item) =>
                    item.order_flag === "Rescheduled"
                );
            }
            else if (searched === "Cancelled") {
                filteredList = detailsToShow.filter((item) =>
                    item.order_flag === "Cancel"
                );
            }
            else if (searched === "Upcoming") {
                filteredList = detailsToShow.filter((item) =>
                    item.status === "Scheduled"
                );
            } else if (searched === "All") {
                filteredList = detailsToShow
            }
            else {
                filteredList = detailsToShow.filter((item) =>
                    item.act_type_name?.toLowerCase().includes(searched) ||
                    item.first_name?.toLowerCase().includes(searched) ||
                    item.role_name?.toLowerCase().includes(searched) ||
                    item.specialization_name?.toLowerCase().includes(searched) ||
                    item.order_flag?.toLowerCase().includes(searched) ||
                    item.act_type_name?.includes(searched) ||
                    item.first_name?.includes(searched) ||
                    item.role_name?.includes(searched) ||
                    item.specialization_name?.includes(searched) ||
                    item.order_flag?.includes(searched)
                );
            }
        // }

        console.log("filtered list", filteredList);
        if (filteredList?.length === 0) {
            console.log("i'm here");
            toast.dismiss();
            setCopyList([]);

        } else {
            toast.dismiss();
            console.log("now, i'm here");
            setCopyList(filteredList);

        }
    }

    console.log("click students data", detailsToShowClick);
    console.log("copyList", copyList);
    console.log("mentor details", mentorFeedbackDetails);
    const fileName = 'download'
    const exportType = 'xls'

    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is: ", studentDetails);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        const hardcodedRow = ['id', 'order_id', 'activity name', 'role name', 'specialization', 'order_flag', 'student_first_name', 'student_last_name', 'student_email', 'student_phone'];

        worksheet.addRow([...hardcodedRow]);
        (copyList.length > 0 ? copyList : studentDetails).forEach((row, index) => {
            const rowData = [...Object.values(row)];
            worksheet.addRow(rowData);
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const currentDate = new Date();
            const filename = `${searched}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
            saveAs(blob, filename);
        });
    };

    const goBack = () => {
        console.log("Back Button is Clicked Now")
        navigate(-1);
      }


    return (
        <>
            <div className="student-details-parent" style={{ marginTop: "3rem" }}>
                <ToastContainer position="top-center" limit={1} />
                <SideNavigationBar />
                <div className="topHeadings" style={{ display: "flex" }}>
                    <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />

                    <p style={{ color: Color.neutralMidGrey }}>Mentors</p>
                </div>
                {loading ? (<div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto', marginLeft: "40%", marginTop: "10%" }}><Loader show={loading} /></div>) : (
                    <>
                        <div style={{ display: "flex", marginTop: "2rem" }}>
                            <div className='searchTab' style={{ alignItems: 'center', width: "100%", height: "30px", marginBottom: ".5rem" }}>
                                <input style={{ border: "none", height: "30px", width: "100%" }}
                                    className='searchingText searchTab'
                                    type='text'
                                    onChange={(e) => requestSearch(e.target.value)}
                                    placeholder='Search for name, roll number, etc'
                                />
                            </div>
                            <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
                        </div>
                        <div>
                            {options.map((option, index) => (
                                <button
                                    key={index}
                                    className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
                                    style={{}}
                                    onClick={() => {
                                        requestSearch(option.value, !option.isSelected)
                                    }}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </div>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {tableTitles.map((title, index) => (
                                            <TableCell align='center'
                                                key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                                            >
                                                {title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(copyList)?.map((details, index) => {
                                        return (
                                            <>
                                                <TableRow
                                                    style={{ cursor: "pointer" }}>
                                                    <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                                        <p style={{ color: "black" }}>{details.act_type_name ? details.act_type_name : "-"} </p>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                                        <p style={{ color: "black" }}>{details.first_name} {details.last_name}</p>
                                                        {details.email_id}<br />
                                                        {details.ph_no}
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16 }}>
                                                        {details.role_name ? details.role_name : "-"}
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16 }}>
                                                        {details.role_name ? details.specialization_name : "-"}
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 16 }}>
                                                        {details.order_flag}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

            </div>
        </>
    );
};

export default MentorStatusFilter;