import { Box, Button, CircularProgress, IconButton, InputAdornment, MenuItem, Modal, TextField, Typography, Tooltip } from '@mui/material'
import { modalBoxStyle, DivCenter, Color, styles } from '../../../../GlobalStyles'
import { AddOutlined, Close, EditOutlined, GetApp, Search, UploadFile } from '@mui/icons-material'
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import { useEffect, useState } from 'react'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { scrollToTargetId } from '../../../Reusable_functions/ScrollToTargetId/ScrollToTargetId'
import { SearchFunction } from '../../../Reusable_functions/TextFunctions/SearchFunction'
import TimeService from '../../../../functions/epochTime'
import SwapStudentList from './SwapStudentList'
import { OfflineSessionConfirmationModal } from '../OfflineSessionModals'

const service = new CCService();
export function ChooseMentorModal({ open, onClose, mentorSlotDetails = {}, mentorData = [], extraData = {}, setAvailableSlots }) {
    console.log("ChooseMentorModal ~ extraData:", extraData)
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...modalBoxStyle, width: '50%', minWidth: '900px', height: 'auto', minHeight: '500px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto' }}>
                <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '32px', width: '100%', fontWeight: 500 }}>{extraData.heading}</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                {mentorSlotDetails && <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '16px', width: '100%', fontWeight: 500 }}>Scheduled with Mentor : {mentorSlotDetails.mentor_name}</Typography>
                    <Typography style={{ textAlign: 'left', fontSize: '16px', width: '100%', fontWeight: 500 }}>{mentorSlotDetails.schedule_time}</Typography>
                </Box>}

                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', p: '0px', justifyContent: 'flex-start', gap: '16px' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '20px', fontWeight: 600, width: '100%' }}>To change mentor, select a mentor from below list</Typography>
                    <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: 'rgba(250, 254, 249, 1)', height: '62px' }}>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Mentor</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Specialization</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Total interviews</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Total scheduled</Typography>
                    </Box>
                    <Box sx={{ width: '100%', overflow: 'auto' }}>
                        {mentorData.map((mentor, index) =>
                            <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: index % 2 ? 'rgba(251, 251, 251, 1)' : '', height: '62px' }}>
                                <Tooltip placement="top" title={mentor.pending_interviews === 0 ? "No more interviews" : "See Slots"}>
                                    <Typography onClick={() => mentor.pending_interviews === 0 ? {} :
                                        setAvailableSlots((prev) => ({
                                            ...prev, open: true, mentorSlotDetails: mentor,
                                            extraData: {
                                                heading: `Schedule session for ${extraData.student_name}`,
                                                student_name: extraData.student_name, order_id: extraData.order_id,
                                                is_edit: mentorSlotDetails ? 1 : 0
                                            }
                                        }))}
                                        sx={{
                                            textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: mentor.pending_interviews === 0 ? Color.neutralMidGrey : Color.primary1,
                                            cursor: mentor.pending_interviews === 0 ? 'default' : 'pointer',
                                            textDecoration: 'none', // Remove default underline
                                            '&:hover': {
                                                textDecoration: mentor.pending_interviews === 0 ? 'none' : 'underline',
                                            },
                                        }}>
                                        {mentor.mentor_name}
                                    </Typography>
                                </Tooltip>
                                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{JSON.parse(mentor.spe_name).map((spe) => spe + ', ')}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{mentor.total_interview} (<span style={{ color: Color.red }}>{mentor.pending_interviews}</span>)</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>{mentor.scheduled_interviews}</Typography>
                            </Box>)}

                    </Box>
                </Box>
            </Box>
        </Modal>)
}

export function SwapModal({ process_id, open, onClose,setSwappedStudent, filter_student_list, setFilteredStudentList, swappedRequestDetails, studentList, refresh, studentDetails, mentorSlotDetails = {}, mentorData = [], extraData = {}, setAvailableSlots }) {
    console.log("SwapModal ~ studentList:", studentList)
    const time = new TimeService();
    const [confirmation, setConfirmation] = useState({
        open: false,
        onClose: () => setConfirmation((prev) => ({ ...prev, open: false })),
        extraData: {
            heading: '',
            body: '',
            button1: {},
            button2: {},
            loading: { button: false }
        }
    });
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...modalBoxStyle, width: '50%', minWidth: '1100px', height: 'auto', height: '600px', justifyContent: 'flex-start', alignItems: 'center', }}>
                <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '24px', width: '100%', fontWeight: 500, marginBottom:"24px" }}>{extraData.heading}</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ width: "70%", alignSelf: "flex-start" }}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', p: '0px', justifyContent: 'flex-start', gap: '16px', boxShadow: '0px 5.72px 10.49px 0px rgba(0, 0, 0, 0.11)', paddingBottom: "12px", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px" }}>
                        <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: '#E4E4E4', height: '44px', borderTopLeftRadius: "12px", borderTopRightRadius: "12px", }}>

                            <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', marginLeft: "12px" }}>Specialization</Typography>
                            <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Mentor Details</Typography>
                            <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Date and Time</Typography>
                        </Box>
                        <Box sx={{ ...DivCenter, justifyContent: 'space-around', width: '100%', height: '62px', }}>

                            <Typography
                                sx={{
                                    padding: "0px", textAlign: 'center', fontSize: '16px', width: "30%", fontWeight: 500, color: Color.neutralMidGrey,
                                    cursor: 'pointer',
                                    textDecoration: 'none', // Remove default underline
                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                }}>
                                {swappedRequestDetails.spe_name}
                            </Typography>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{swappedRequestDetails.mentor_name}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{swappedRequestDetails.mentor_email}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{swappedRequestDetails.mentor_ph_no}</Typography>

                            </div>
                            <div style={{ display: "flex", flexDirection: "column" , width:"200px"}}>
                                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{time.convertEpochToDate(swappedRequestDetails.end_time)}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{time.convertEpochToTime(swappedRequestDetails.end_time)}</Typography>
                                {/* <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{swappedRequestDetails.mentor_ph_no}</Typography> */}

                            </div>
                        </Box>
                        {/* <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>dddd</Typography> */}

                        {/* <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>ddddddd</Typography></Box> */}
                    </Box>
                </Box>
                <Box style={{width:"100%",}}>
                    <SwapStudentList onClose={onClose} process_id={process_id} filter_student_list={filter_student_list} setFilteredStudentList={setFilteredStudentList} setSwappedStudent={setSwappedStudent} swappedRequestDetails={swappedRequestDetails} studentList={studentList} confirmation={confirmation} setConfirmation={setConfirmation} refresh={refresh}/>
                </Box>
                {confirmation.open && <OfflineSessionConfirmationModal
                    open={confirmation.open}
                    onClose={confirmation.onClose}
                    extraData={confirmation.extraData}
                />}
            </Box>
        </Modal>)
}


export function AvailableSlotsModal({ open, onClose, extraData, mentorSlotDetails = { spe_name: '' }, availableSlotsData, handleMainEffect, getStatLists, dialogue, setDialogue }) {

    const [selectedSlot, setSelectedSlot] = useState({});
    const [slotData, setSlotData] = useState([]);
    const [modalLoading, setModalLoading] = useState({ slots: false });



    //FUNCTIONS

    useEffect(() => {
        handleMainSlotEffect();
    }, []);

    const handleMainSlotEffect = async () => {
        setModalLoading((prev) => ({ ...prev, slots: true }));
        const available_slots = await getAvailableSlots(extraData.order_id);
        console.log("handleMainEffect ~ available_slots:", available_slots);
        const remade_slot_data = remakeSlotData(available_slots.data, available_slots.timings);
        console.log("handleMainEffect ~ remade_slot_data:", remade_slot_data);
        setSlotData(remade_slot_data);
        setModalLoading((prev) => ({ ...prev, slots: false }));
    }

    const getAvailableSlots = async (order_id) => {
        try {
            console.log("order if: ", order_id, mentorSlotDetails.mentor_id)
            const response = await service.get(`/os/superAdmin/bookingSlots?order_id=${order_id}&mentor_id=${mentorSlotDetails.mentor_id}`);
            return response;
        } catch (error) {
            console.error("Error while fetchinng student available slots:", error);
        }
    }

    function remakeSlotData(jsonArray, json_timings) {
        const transformedData = {};
        const timings = JSON.parse(json_timings);
        console.log("remakeSlotData ~ timings:", timings)
        jsonArray.forEach(item => {
            const startDate = new Date(item.slot_start_time);
            const endDate = new Date(item.slot_end_time);
            startDate.setHours(0, 0, 0, 0); // Set time to 12 AM

            const startDateString = startDate.toDateString();

            // Check if date already exists in transformedData
            if (!transformedData[startDateString]) {
                transformedData[startDateString] = {
                    session_date: startDate.getTime(),
                    slots: []
                };
            }

            const afternoonTime = new Date(timings.afternoon).toLocaleTimeString('en-US', { hour12: false });
            console.log("remakeSlotData ~ afternoonTime:", afternoonTime);
            const eveningTime = new Date(timings.evening).toLocaleTimeString('en-US', { hour12: false });
            console.log("remakeSlotData ~ eveningTime:", eveningTime);
            const startTime = new Date(item.slot_start_time).toLocaleTimeString('en-US', { hour12: false });
            const endTime = new Date(item.slot_end_time).toLocaleTimeString('en-US', { hour12: false });

            // Extract hours and minutes from time strings
            const [startHour, startMinute] = startTime.split(':').map(Number);
            const [endHour, endMinute] = endTime.split(':').map(Number);
            const [afternoonHour, afternoonMinute] = afternoonTime.split(':').map(Number);
            const [eveningHour, eveningMinute] = eveningTime.split(':').map(Number);
            console.log("check slot type:", startHour, afternoonHour, startMinute, afternoonMinute);
            let slotType = '';
            // Compare hours and minutes to determine slot_type
            if (startHour < afternoonHour || (startHour === afternoonHour && startMinute < afternoonMinute)) {
                slotType = 'Morning';
            } else if ((startHour === afternoonHour && startMinute >= afternoonMinute) || (startHour > afternoonHour && startHour < eveningHour) || (startHour === eveningHour && startMinute <= eveningMinute)) {
                slotType = 'Afternoon';
            } else {
                slotType = 'Evening';
            }

            // Add slot information to the respective date's slots array
            transformedData[startDateString].slots.push({
                slot_id: item.slot_id,
                mentor_id: item.mentor_id,
                available: item.available,
                slot_start_time: item.slot_start_time,
                slot_end_time: item.slot_end_time,
                slot_type: slotType
            });
        });

        return Object.values(transformedData);
    }

    const confirmHandleSubmitSchedule = () => {
        setDialogue((prev) => ({
            ...prev,
            open: true,
            actions: [
                { width: '40%', label: 'Reject', handler: () => { dialogue.onClose() }, color: Color.red, variant: "outlined" },
                { width: '40%', label: 'Accept', handler: () => { handleSubmitSchedule() }, color: Color.green, background: Color.green_highlight, variant: "outlined" }
            ],
            title: 'Confirmation',
            content: <p style={{ textAlign: 'center', lineHeight: '25px' }}>Student : {extraData.student_name} is getting scheduled with <br />Mentor : {mentorSlotDetails.mentor_name} <br />on {formatConvertermonthfirst(selectedSlot.slot_start_time, false, false)}. Are you sure ?</p>,
        }))
    }

    const handleSubmitSchedule = async () => {

        console.log("handleSubmitSchedule ~ mentorSlotDetails.slot_start_time:", mentorSlotDetails.slot_start_time)
        if (!extraData.is_edit) {
            //SCHEDULE BUTTON
            const post_body = {
                "order_id": extraData.order_id,
                "mentor_id": mentorSlotDetails.mentor_id,
                "slot_start_time": selectedSlot.slot_start_time,
                "slot_end_time": selectedSlot.slot_end_time
            }
            try {
                await service.post(`/os/superAdmin/scheduling?order_id=${extraData.order_id}`, post_body);
                const data = await getStatLists();
                handleMainEffect(data);
                dialogue.onClose();
                onClose();
            } catch (error) {
                console.error('Error while posting scheduling:', error);
            }
        } else {
            //EDIT STUDENT SCHEDULE
            const put_body = {
                "order_id": extraData.order_id,
                "mentor_id": mentorSlotDetails.mentor_id,
                "slot_start_time": selectedSlot.slot_start_time,
                "slot_end_time": selectedSlot.slot_end_time
            }
            try {
                await service.put(`os/superAdmin/action/editBooking`, put_body);
                const data = await getStatLists();
                handleMainEffect(data);
                onClose();
            } catch (error) {
                console.error('Error while posting scheduling:', error);
            }
        }



    }


    //OTHER functions
    const getSlotNames = (date) => {
        const d = new Date(date);
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedTime = `${(hours % 12) === 0 ? 12 : (hours % 12) < 10 ? "0" + (hours % 12) : ((hours % 12))}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
        return formattedTime;
    }

    const handleSlotClick = (blue) => {
        scrollToTargetId('saveButton');
        setSelectedSlot(blue);
    }


    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...modalBoxStyle, width: '80%', minWidth: '900px', height: '90%', minHeight: '700px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto', p: '50px' }}>
                <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '32px', width: '100%', fontWeight: 500 }}>{extraData.heading}</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '20%', minHeight: '120px', justifyContent: 'space-between' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '20px', width: '100%', fontWeight: 500 }}>Selected Mentor : {mentorSlotDetails.mentor_name}</Typography>
                    <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralBlack }}>Specialization: {mentorSlotDetails.spe_name ? JSON.parse(mentorSlotDetails.spe_name).map((spe) => spe + ', ') : ''}</Typography>
                    <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralBlack }}>Total interveiws: {mentorSlotDetails.total_interview} (<span style={{ color: Color.red }}>{mentorSlotDetails.pending_interviews}</span>)</Typography>
                    <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralBlack }}>Total scheduled: {mentorSlotDetails.scheduled_interviews}</Typography>
                </Box>
                <hr style={{ width: '100%', color: Color.neutralMidGrey }} />
                <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, width: '100%' }}>Select preferred slot from below</Typography>

                {/* available slots - shown here */}
                {modalLoading.slots ?
                    <CircularProgress size={24} sx={{ color: Color.primary1 }} />
                    : <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '32px', height: '70%' }}>
                        <>
                            {slotData.length ?
                                slotData.map((session) =>
                                    <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'column', gap: '16px' }}>
                                        <Typography style={{ textAlign: 'left', fontSize: '20px', color: Color.neutralBlack, width: '100%', marginTop: '24px' }}>
                                            {formatConvertermonthfirst(session.session_date, false, false, true)}
                                        </Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '18px', color: Color.neutralMidGrey, width: '100%' }}>
                                            {session.slots.length} available slots
                                        </Typography>
                                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                            {['Morning', 'Afternoon', 'Evening'].map((time_of_day) =>
                                                <Box sx={{
                                                    ...DivCenter, gap: '16px', justifyContent: 'flex-start', flexDirection: 'column', width: '30%', height: 'auto', p: '32px', minWidth: '335px', minHeight: '480px', borderRadius: '12px',
                                                    // boxShadow: '0px 6px 11px 0px rgba(0, 0, 0, 0.11)'
                                                    boxShadow: '0px 5.72px 10.49px 0px rgba(0, 0, 0, 0.11)'

                                                }}>
                                                    <Box sx={{ height: '10%' }}>
                                                        <Typography style={{ fontSize: '24px', fontWeight: 500 }}>{time_of_day} Slots</Typography>
                                                    </Box>
                                                    <Box sx={{ width: '100%', height: '90%' }}>
                                                        {session.slots?.filter((blue) => blue.slot_type === time_of_day).map((blue, blue_index) =>
                                                            <> <Box
                                                                onClick={() => blue.available ? handleSlotClick(blue) : {}}
                                                                sx={{
                                                                    ...DivCenter, width: '100%', gap: '8px', height: '40px',
                                                                    cursor: 'pointer',
                                                                }}>
                                                                <Typography sx={{
                                                                    ...DivCenter, alignItems: 'center',
                                                                    height: '30px',
                                                                    background: selectedSlot.slot_id === blue.slot_id ? Color.primary3 : Color.white,
                                                                    color: blue.available ? selectedSlot.slot_id === blue.slot_id ? Color.white : Color.primary1 : Color.neutralMidGrey,
                                                                    borderRadius: '12px',
                                                                    fontSize: 16,
                                                                    fontWeight: 500,
                                                                    textAlign: 'center',
                                                                    width: '70%',
                                                                    '&:hover': {
                                                                        background: blue.available ? Color.primary_highlight : Color.white
                                                                    }
                                                                }}>{getSlotNames(blue.slot_start_time)} - {getSlotNames(blue.slot_end_time)}</Typography>
                                                            </Box>
                                                                <hr style={{ color: Color.neutralBlack, width: '100%' }} />
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                ) : <Typography style={{ textAlign: 'left', width: '100%', color: Color.neutralMidGrey, fontSize: '14px' }}>No slots to be shown</Typography>}
                        </>
                        <Box id='saveButton' sx={{ ...DivCenter, gap: '16px', width: '100%', pb: '24px' }}>
                            <Button
                                // disabled={student.loading}
                                variant='outlined'
                                onClick={() => { onClose() }}
                                sx={{ ...styles.button_styles.outlined, color: Color.primary1, fontSize: '16px', textTransform: 'none', width: '40%', height: '51px' }}>
                                Cancel
                            </Button>
                            <Button
                                disabled={selectedSlot.slot_id ? false : true}
                                variant='contained'
                                onClick={() => { confirmHandleSubmitSchedule() }}
                                sx={{ ...styles.button_styles.contained, fontSize: '16px', textTransform: 'none', width: '40%', height: '51px' }}>
                                Submit
                            </Button>
                        </Box>
                    </Box>}
            </Box>
        </Modal >)
}




export function StudentsSwapModal({ open, onClose, extraData }) {
    const [modalLoading, setModalLoading] = useState({ modal: false })

    //data variable 
    const [swapData, setSwapData] = useState({
        data: [],
        details: {}
    })
    //Search
    const [searchTerm, setSearchTerm] = useState();

    const [filteredMainData, setFilteredMainData] = useState([]);
    console.log("StudentsSwapModal ~ filteredMainData:", filteredMainData)

    //FUNCTIONS
    useEffect(() => {
        handleSwapMainEffect();
    }, [])

    const handleSwapMainEffect = async () => {
        setModalLoading((prev) => ({ ...prev, modal: true }));
        const { swap_details, swap_data } = await getSwapData();
        setSwapData({ details: swap_details, data: swap_data });
        setFilteredMainData(swap_data);
        setModalLoading((prev) => ({ ...prev, modal: false }));

    }
    const getSwapData = async () => {
        try {
            const response = await service.get(`/os/superadmin/swap?process_id=${extraData.process_id}`);
            console.log("getSwapData ~ response:", response);
            return { swap_details: { total_orders: response.total_orders, swaped_orders: response.swaped_orders }, swap_data: response.swaps_data };
        } catch (error) {
            console.error('Errow while getting swap data', error);
        }
    }

    //Search
    const handleChangeSearch = (e, newValue) => {
        console.log("handleChangeSearch ~ newValue:", e.target.value, newValue)
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        if (searchTerm) {
            const filtered = SearchFunction(swapData.data,
                ['from_student_name', 'from_mentor_name', 'to_student_name', 'to_mentor_name',
                    'start_time', 'end_time'], searchTerm);
            setFilteredMainData(filtered);
        }
        else {
            setFilteredMainData([...swapData.data]);
        }
    }



    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...modalBoxStyle, width: '60%', minWidth: '900px', height: '60%', minHeight: '700px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto', p: '40px' }}>
                <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}>
                    <Typography style={{ textAlign: 'left', fontSize: '24px', width: '100%', fontWeight: 500 }}>{extraData.heading}</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                {modalLoading.modal ?
                    <Box sx={{ ...DivCenter, width: '100%', height: '100%' }}>
                        <CircularProgress size={40} sx={{ color: Color.primary1 }} />
                    </Box>
                    : <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '90%', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '32px' }}>

                        {/* Details CARD */}
                        <Box sx={{
                            width: '30%',
                            height: '40%',
                            p: '20px',
                            gap: '24px',
                            borderRadius: '9px',
                            opacity: '0px',
                            boxShadow: '0px 4.28px 7.85px 0px rgba(0, 0, 0, 0.08)'

                        }}>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', gap: '5px' }}>
                                <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralBlack }}>
                                    Total Students Swapped
                                </Typography>
                                <Typography style={{ textAlign: 'left', fontSize: '18px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>
                                    {swapData.details.total_orders} Students
                                </Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, height: '50%', width: '100%' }}>
                                <Box sx={{ ...DivCenter, position: 'relative', display: 'inline-flex', width: '100%' }}>
                                    <CircularProgress size={"100px"} variant="determinate" value={Math.round(swapData.details.swaped_orders / swapData.details.total_orders)} sx={{ fontSize: '30px', color: Color.primary1 }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        <Typography variant="caption" component="div" color="text.secondary" style={{ fontSize: '20px' }}>
                                            {`${Math.round(swapData.details.swaped_orders / swapData.details.total_orders)}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ ...DivCenter, height: '20%' }}>
                                <Button variant='text' onClick={() => { scrollToTargetId('ModalStudentList') }} sx={{ fontSize: '16px', textTransform: 'none', color: Color.primary1 }}>View Details</Button>
                            </Box>
                        </Box>

                        {/* SEARCH */}
                        <Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
                            <TextField
                                name="search_colleges"
                                // label="Search Student"
                                placeholder="Search Students who aren't placed"
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleChangeSearch}
                                fullWidth
                                sx={{ width: '90%', borderRadius: "12px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: Color.neutralMidGrey }} />
                                        </InputAdornment>
                                    ),
                                    sx: { borderRadius: "12px", height: "58px" }
                                }}
                            />
                            <Box sx={{ width: '10%', minWidth: '121px' }}>
                                <IconButton><AddOutlined /></IconButton>
                                <IconButton><GetApp /></IconButton>
                                <IconButton><UploadFile /></IconButton>
                            </Box>
                        </Box>
                        <Box id="ModalStudentList" sx={{ width: '100%' }}>
                            {/* MAP */}
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
                                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Student</Typography>
                                <Typography style={{ width: '100%', textAlign: 'center', fontSize: '22px', color: Color.primary1, }}>New Date</Typography>
                                <Typography style={{ width: '100%', textAlign: 'center', fontSize: '22px', color: Color.primary1, }}>New Mentor</Typography>
                                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Swapped With</Typography>
                            </Box>
                            {filteredMainData.map((swap, swap_index) =>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: swap_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
                                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', justifyContent: 'flex-start' }}>
                                        <Typography style={{ width: '100%', color: Color.primary1, fontSize: '16px' }}>{swap.from_student_name}</Typography>
                                        <Typography style={{ width: '100%', color: Color.primary1, fontSize: '16px' }}>{swap.to_student_name}</Typography>
                                    </Box>
                                    <Box sx={{ ...DivCenter, width: '100%', }}>
                                        <Typography style={{ textAlign: 'center', width: '100%', color: Color.neutralMidGrey, fontSize: '16px', flexWrap: 'wrap' }}>{swap?.start_time ? formatConvertermonthfirst(swap.start_time, true, true, true) : "-"}</Typography>
                                    </Box>
                                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', justifyContent: 'flex-start' }}>
                                        <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px', textAlign: 'center' }}>{swap.from_mentor_name}</Typography>
                                        <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px', textAlign: 'center' }}>{swap.to_mentor_name}</Typography>
                                    </Box>
                                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', }}>
                                        <Typography style={{ width: '100%', textAlign: 'left', color: Color.primary1, fontSize: '16px' }}>{swap.to_student_name ?? '-'}</Typography>
                                        <Typography style={{ width: '100%', textAlign: 'left', color: Color.primary1, fontSize: '16px' }}>{swap.from_student_name ?? '-'}</Typography>
                                    </Box>
                                </Box>
                            )}

                        </Box>
                    </Box>}
            </Box>
        </Modal >)
}