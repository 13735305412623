import axios from "axios"

const api = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}/cartModule`,
   headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*"
   }
})

const file = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}/cartModule`,
   headers: {
      "Content-Type": "multipart/form-data"
   }
})


class ServiceCM {
   post = async (endpoint, data) => {
      let res = await api.post(endpoint, data)
      return res;
   }

   get = async (endpoint) => {
      console.log(endpoint)
      let res = await api.get(endpoint)
      return res.data;
   }

   patch = async (endpoint, data) => {
      let res = await api.patch(endpoint, data)
      return res.data;
   }

   delete = async (endpoint, data) => {
      let res = await api.delete(endpoint, data)
      return res.data;
   }
   put = async (endpoint, data) => {
      let res = await api.put(endpoint, data)
      return res;
   }
   postFile = async (endpoint, data) => {
      let res = await file.post(endpoint, data)
      return res;
   }
}

export default ServiceCM;