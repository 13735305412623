// ***********************************//
/* {This is Considered As Certfication} */
// ***********************************//
import React from "react";
function ResponsibiltyRLTable(props) {
  const certficationData = [
    {
        "basic_info": {
            "tag": "Certificate basic info"
        },
        "sub_section_detail": {
            "sub_section_id": 680,
            "sub_section_order": 1,
            "is_on": 1
        },
        "bullets": [
            {
                "bullet_id": 1232,
                "bullet_content": "Certificate Details",
                "achievement_year": "Year",
                "bullet_order": 1
            }
        ]
    }
]
  console.log("certficationData",certficationData);
  let Details=[]
  if ((certficationData && certficationData?.length > 0)) {
    certficationData?.map((value,index)=>
    Details.push({
      Role: (
        <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
            {value?.basic_info? value.basic_info.tag:""}
        </p>
    ),
    Desc:(
      <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                          {
                            certficationData[index]?.bullets?.map((bullet)=>{
                              return bullet.bullet_content?.length>0?
                              <li style={{ listStyleType: bullet.bullet_content?.length>0 ? "" : 'none', fontFamily: props.fontS }}>
                                {bullet?.bullet_content}{bullet.achievement_year ? ", " + bullet?.achievement_year : ""}
                              </li>:<></>
                            })
                          }
                        </ul>
      </p>
    ),
    Year:(
      (certficationData && certficationData.length > 0) ? certficationData[index].bullets.map((bullet) => {
          return (bullet?.achievement_year > 0 ?
              <p style={{ listStyleType: certficationData ? "" : 'none', fontFamily: props.fontS,marginBottom:"0",color:"black" }}>
                  {bullet?.achievement_year}
              </p>
              : <></>
          )
      }
      ) : []
  )
  }))
}
  else{
  Details = [
    {
      Role: (
        <p id="resumeTable-p" style={{ textAlign: "centre", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, fontFamily: props.fontS }}>
          {/* Academics */}
          </p>
      ),
      Desc: (
        <p id="resumeTable-p" style={{ textAlign: "justify" }}>
          <ul className="resumeTable-ul"
            style={{
              marginLeft: "17px",
              listStyleType: props.Bullet ? "square" : "",
              lineHeight: props.LineHeight,
              LineHeight: props.miniLheight,
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
            }}
          >
            <li style={{ fontFamily: props.fontS,listStyleType:"none" }}>
              {/* Secured 2nd Position out of 20 teams in BREAKTHROUGH case presentation event conducted as part of VRIDDHI 2019 by IIM Visakhapatnam, for proposing a digital strategy to improve the profitability of a Children’s magazine. */}
            </li>
          </ul>
        </p>
      ),
    },
  ];
}
  console.log(1);
  console.log(props.TableWidth);
  console.log("Fontsize bro", props.FontSize);
  return (
    <div>
      {/* <table className="IntershipBox" style={{ width: `${props.TableWidth}%` }}> */}
      <table
        className="IntershipBox overlapping-table ResumeTable"
        style={{
          width: props.minwidth ? props.minwidth : props.TableWidth,
          border: "solid 0.25px #000",
          // width:props.minwidth+"%",
        }}
      // style={{ width: props.widths+"px" }}
      >
        <tr>
          <th className="Resumetable-th"
            colspan="6"
            id="Topic"
            style={{
              backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
              color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
              fontFamily: props.fontS,
              // border:props.style?"none":"",
              textAlign: props.headerPosition,
              // borderTop: "none",
              height: props.miniheight, padding: props.minipadding
            }}
          >
            {/* Responsibilty */}
            {props.name ? props.name : 'Responsibilty'}
          </th>
        </tr>
        {Details.map((val, key) => {
          return (
            <tr key={key} style={{
              // borderBottom:props.style?"none" :"solid 0.25px #000" 
            }}>
              <td className="ResumeTable-td"
                colSpan={4}
                style={{
                  // fontSize: props.FontSmall,
                  fontFamily: props.fontS,
                  // fontFamily: props.Arial,
                  // fontSize: props.FontSmall,
                  fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                  // border: "solid 0.25px #000",
                  height: props.miniheight,
                  fontSize: props.miniFontSize ? props.FontSize : props.miniFontSize, padding: props.minipadding
                }}
              >
                {val.Desc}
              </td>
              {/* <td className="ResumeTable-td"
                                style={{
                                    // fontFamily: props.FontFamily ? "" : "Arial",
                                    // fontFamily: props.Arial,
                                    fontFamily: props.fontS,
                                    width: "16%",
                                    // fontSize: props.FontSmall,
                                    fontSize: props.FontSize,
                                    border: "0.1px solid rgb(0 0 0)", 
                                    color: "#0009",
                                    height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                                    maxWidth:"50px"
                                }}
                            >
                                {val.Year}
                            </td>     */}
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default ResponsibiltyRLTable;
