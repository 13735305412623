import React from "react";
function AcadAchivementFreeFlowing(props) {
    const academicAchievementData =  [
        {
            "basic_info": {
                "tag": "Academic Info"
            },
            "sub_section_detail": {
                "sub_section_id": 674,
                "sub_section_order": 1,
                "is_on": 1
            },
            "bullets": [
                {
                    "bullet_id": 1226,
                    "bullet_content": "Academic Achievements -1",
                    "achievement_year": "Year",
                    "bullet_order": 1
                },
                {
                    "bullet_id": 1231,
                    "bullet_content": "Achievements - 2",
                    "achievement_year": "Year",
                    "bullet_order": 2
                }
            ]
        }
    ]
    console.log("academicAchievementData", academicAchievementData);
    let Details = []
    if ((academicAchievementData && academicAchievementData?.length > 0)) {
        academicAchievementData?.map((value, index) =>
            Details.push({
                Role: (
                    <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
                        {value?.basic_info ? value.basic_info.tag : ""}
                    </p>
                ),
                Desc: (
                    <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
                        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                            {
                                academicAchievementData[index]?.bullets?.map((bullet) => {
                                    return bullet.bullet_content?.length > 0 ?
                                        <li style={{ listStyleType: bullet.bullet_content?.length > 0 ? "" : 'none', fontFamily: props.fontS }}>
                                            {bullet?.bullet_content}{bullet.achievement_year ? ", " + bullet?.achievement_year : ""}
                                        </li> : 
                                        <li style={{ listStyleType: bullet.bullet_content?.length > 0 ? "" : 'none', fontFamily: props.fontS }}>
                                        {bullet?.bullet_content}{bullet.achievement_year ? ", " + bullet?.achievement_year : ""}
                                    </li>
                                })
                            }
                        </ul>
                    </p>
                )
            }))
    }
    else {
        Details = [
            {
                Desc: (
                    <p id="resumeTable-p" style={{ textAlign: "justify" }}>
                        <ul className="resumeTable-ul" style={{
                            marginLeft: "12px",
                            listStyleType: "none",
                            lineHeight: props.LineHeight ? "1.2" : "",
                            LineHeight: props.miniLheight
                        }}>
                            <li listStyleType="none">
                               Organically grew Club’s LinkedIn Page from 176 to 700+ followers, signed MoU with Angel Broking, roped in 3 Live Projects, & oversaw Uni Affiliation with CFA Institute. Recruited & Supervised 3 Jr. Coordinators.
                            </li>
                            <li>
                                Organized 4 Leadership Webinars, a 2-Day Equity Valuation Workshop, & Equity Research Challenge (893 Registrations). Founded Sunrise Investment Fund and inducted 2 Junior Fund Managers.
                            </li>

                        </ul>
                    </p>
                ),
            },
        ];
    }
    return (
        <div>
            <table className="ResumeTable"
                style={{ width: props.minwidth ? props.minwidth : props.TableWidth, }}
            >
                <tr>
                    <th className="Resumetable-th" colspan="6" id="Topic"
                        style={{
                            backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
                            color: props.Color ? "white" : "",
                            fontSize: props.FontSize,
                            fontFamily: props.fontS,
                            border: "none",
                            textAlign: props.headerPosition,
                            height: props.miniheight, fontSize: props.miniFontSize, padding: props.minipadding
                        }}>
                        {props.name ? props.name : 'Acadamecis'}
                    </th>
                </tr>
                {Details.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td className="ResumeTable-td"
                                style={{
                                    fontFamily: props.fontS,
                                    fontSize: props.FontSize,
                                    height: props.miniheight, fontSize: props.miniFontSize, padding: props.minipadding,

                                    paddingTop: key === 0 ? "0.16rem" : "0rem",
                                    paddingBottom: key === Details.length - 1 ? "0.16rem" : "0rem",
                                }}
                                colSpan={4}>
                                {val.Desc}
                            </td>
                        </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default AcadAchivementFreeFlowing;
