import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, TextField, Autocomplete, IconButton, Typography, Chip, MenuItem, Select, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CCService from '../../../services/httpServiceWithoutSuperAdmin';
import { Color } from '../../../GlobalStyles';
import Service from '../../../services/httpService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh', // set max height to 90% of the viewport height
    overflow: 'auto',  // enable scrolling
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
};


const FeedbackAndMcq = ({ open, setOpen, act_type_id, act_type_name }) => {
    const service = new Service();
    const ccservice = new CCService();
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [currentOptions, setCurrentOptions] = useState(['']);
    const [feedbackTags, setFeedbackTags] = useState([{ id: null, Tags: "", is_delete: false }]);
    const [questionType, setQuestionType] = useState('text');
    const [isAddingQuestion, setIsAddingQuestion] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        const handleEffect = async () => {
            let temp_feedback_getter = await majorAndMinorGetter();
            let temp_feedback_mcq_getter = await feedbackMcqGetter()
            const feedbackData = temp_feedback_getter.map(item => ({
                id: item.id,
                Tags: item.field_name,
                is_delete: item.is_delete
            }));
            setFeedbackTags(feedbackData);

            const feedbackMcq = temp_feedback_mcq_getter.map(item => ({
                id: item.id,
                question: item.question,
                options: JSON.parse(item.options).options,
                sequence: item.sequence
            }))
            setQuestions(feedbackMcq)
        }
        handleEffect();
    }, [act_type_id]);

    const handleAddOption = () => {
        setCurrentOptions([...currentOptions, '']);
    };

    const handleOptionChange = (index, event) => {
        const newOptions = [...currentOptions];
        newOptions[index] = event.target.value;
        setCurrentOptions(newOptions);
    };

    const handleAddQuestion = async () => {
        if (currentQuestion && currentOptions.length > 0) {
            const newQuestion = { question: currentQuestion, options: currentOptions };

            try {
                if (editIndex !== null) {
                    // PUT request for editing a question
                    const questionId = questions[editIndex].id;
                    await ccservice.put('/mentorModule/mentorFeedbackMcq', {
                        id: questionId,
                        question: currentQuestion,
                        options: currentOptions,
                        sequence: editIndex + 1
                    });
                    const updatedQuestions = questions.map((q, i) => (i === editIndex ? { ...newQuestion, id: questionId } : q));
                    setQuestions(updatedQuestions);
                    setEditIndex(null);
                } else {
                    // POST request for adding a new question
                    const response = await ccservice.post('/mentorModule/mentorFeedbackMcq', {
                        act_type_id,
                        question: currentQuestion,
                        options: currentOptions,
                        sequence: questions.length + 1
                    });
                    const newQuestionWithId = { ...newQuestion, id: response.data.id };
                    setQuestions([...questions, newQuestionWithId]);
                }

                setCurrentQuestion('');
                setCurrentOptions(['']);
                setIsAddingQuestion(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleEditQuestion = (index) => {
        const questionToEdit = questions[index];
        setCurrentQuestion(questionToEdit.question);
        setCurrentOptions(questionToEdit.options);
        // setQuestionType(questionToEdit.type);
        setIsAddingQuestion(true);
        setEditIndex(index);
    };

    const handleDeleteQuestion = (index) => {
        const questionId = questions[index].id;
        if (questionId) {
            ccservice.delete(`/mentorModule/mentorFeedback`, {
                data: { "mcq": [questionId] }
            }).then(() => {
                setQuestions(prevState => prevState.filter((_, i) => i !== index));
            }).catch(console.error);
        } else {
            setQuestions(prevState => prevState.filter((_, i) => i !== index));
        }
    };

    const handleChange = (event, values) => {
        const newValues = values.map(value => ({
            Tags: value,
            is_delete: false
        }));

        const currentValues = feedbackTags || [];
        const filteredNewValues = newValues.filter(newValue =>
            !currentValues.some(existingValue => existingValue.Tags === newValue.Tags)
        );

        const updatedValues = [...currentValues, ...filteredNewValues];
        setFeedbackTags(updatedValues);
    };

    const handleCloseApplicationsModalClose = (type) => {
        if (type === "Cancel") {
            const temp_post_data = feedbackTags.filter(item => item.id);
            setFeedbackTags(temp_post_data);
        }
    };

    const handlePostValues = async () => {
        const temp_post_data = feedbackTags.filter(item => !item.id && !item.is_delete);
        temp_post_data.forEach(item => {
            delete item.is_delete;
        });
        const transformedArray = temp_post_data.map(item => ({
            "field_name": item.Tags
        }))
        console.log("temp_post_data", temp_post_data, transformedArray);



        if (temp_post_data.length > 0) {
            try {
                await ccservice.post('/mentorModule/mentorFeedbackFields', {
                    act_type_id: act_type_id,
                    tags: transformedArray,
                }).then(res => {
                    setFeedbackTags(prevState => prevState.map(item => {
                        if (!item.id && !item.is_delete) {
                            return { ...item, id: Date.now() };
                        }
                        return item;
                    }));
                    handleCloseApplicationsModalClose("Confirm");
                });
            } catch (err) {
                console.log(err);
            }
        }
        handleCloseApplicationsModalClose("Confirm");
    };

    const handleCloseApplications = async () => {
        handleMultiDelete();
    };

    const handleMultiDelete = async () => {
        const itemsToDelete = feedbackTags.filter(item => item.is_delete && item.id).map(item => item.id);

        if (itemsToDelete.length > 0) {
            try {
                await ccservice.delete(`/po/ca/poMajorMinor?ids=[${itemsToDelete.join(',')}]`).then(res => {
                    setFeedbackTags(prevState =>
                        prevState.filter(item => !itemsToDelete.includes(item.id))
                    );
                });
            } catch (err) {
                console.log(err);
            }
        }
        setFeedbackTags(prevState => prevState.filter(item => !item.is_delete));
        handlePostValues();
    };



    const majorAndMinorGetter = async () => {
        try {
            const res = await ccservice.get(
                `mentorModule/mentorFeedbackFields?act_type_id=${act_type_id ?? null}`
            );
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };

    const feedbackMcqGetter = async () => {
        try {
            const res = await ccservice.get(
                `mentorModule/mentorFeedbackMcq?act_type_id=${act_type_id ?? null}`
            );
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };



    // const handleEditMajorAndMinor = async () => {
    //     const temp_major_and_minor = await majorAndMinorGetter();
    //     temp_major_and_minor.forEach(item => {
    //         item.is_delete = false;
    //     });
    //     setFeedbackTags(temp_major_and_minor);
    // };

    const handleDeleteSpecialization = (index) => {
        setFeedbackTags(prevState =>
            prevState.map((item, i) =>
                i === index ? { ...item, is_delete: true } : item
            )
        );
    };

    console.log("questions", questions);

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography sx={{ textAlign: 'left', paddingBottom: '1rem', marginBottom: '1rem', borderBottom: `1px solid ${Color.neutralDarkGrey}`, fontSize: '24px' }}>
                        Feedback Tags and MCQ Questions of {act_type_name}
                    </Typography>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>
                        <Autocomplete
                            multiple
                            freeSolo
                            options={[]}
                            value={feedbackTags.filter(item => !item.is_delete).map(item => item.Tags)}
                            renderTags={(value, getTagProps) =>
                                feedbackTags.map((item, index) => {
                                    if (item.is_delete) return null;
                                    return (
                                        <Chip
                                            key={index}
                                            label={item.Tags}
                                            {...getTagProps({ index })}
                                            onDelete={() => handleDeleteSpecialization(index)}
                                            sx={{ margin: '0.25rem' }}
                                        />
                                    );
                                })
                            }
                            renderInput={(params) => <TextField {...params} label="Add Feedback Tags" />}
                            onChange={handleChange}
                        />

                        <button
                            name="next-button"
                            className="Submitbutton"
                            style={{

                                height: '40px'
                            }}
                            onClick={() => handleCloseApplications()}
                        >
                            Submit Feedback Fields
                        </button>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                        <Typography variant="h6" component="div">
                            MCQ Questions
                        </Typography>
                        <IconButton onClick={() => setIsAddingQuestion(!isAddingQuestion)}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    {isAddingQuestion && (
                        <Box sx={{ marginTop: '1rem' }}>
                            <TextField
                                fullWidth
                                label="Question"
                                value={currentQuestion}
                                onChange={(e) => setCurrentQuestion(e.target.value)}
                                sx={{ marginBottom: '1rem' }}
                            />
                            {/* <Select
                                fullWidth
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                                sx={{ marginBottom: '1rem' }}
                            >
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                            </Select> */}
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
                                {currentOptions.map((option, index) => (
                                    <TextField
                                        key={index}
                                        label={`Option ${index + 1}`}
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, e)}
                                    />
                                ))}
                                <Button variant="outlined" onClick={handleAddOption}>
                                    Add Option
                                </Button>
                            </Box>
                            <button className='saveButtonCareercarve' style={{ width: "250px" }} onClick={handleAddQuestion}>
                                {editIndex !== null ? 'Update' : 'Save Question'}
                            </button>
                        </Box>
                    )}
                    <Box sx={{ marginTop: '2rem' }}>
                        {questions.map((question, index) => (
                            <Box key={index} sx={{ marginBottom: '1rem', borderBottom: '1px solid #ccc', paddingBottom: '1rem', display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Box>
                                    <Typography sx={{ fontSize: "18px" }}>{question.question}</Typography>
                                    <ul style={{ display: 'flex', flexDirection: 'row', padding: 0, listStyleType: 'none', margin: 0 }}>
                                        {question.options.map((option, i) => (
                                            <li key={i} style={{ marginRight: '1rem' }}>{option}</li>
                                        ))}
                                    </ul>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton onClick={() => handleEditQuestion(index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteQuestion(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                </Box>
            </Modal>
        </div>
    );
};

export default FeedbackAndMcq;
