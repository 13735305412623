import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: "24px",
      fontWeight: 400,
    },
    h2: {
      fontSize: "20px",
      fontWeight: 400,
    },
    h3: { fontSize: "16px", fontWeight: 400 },
    h4: {
      fontSize: "14px",
      fontWeight: 400,
    },
    h5: { fontSize: "12px", fontWeight: 400 },
    h6: { fontSize: "11px", fontWeight: 400 },
    // body1: { fontSize: "10px", fontWeight: 400 },
    // body3: { fontSize: "9px", fontWeight: 400 },
  },
});
