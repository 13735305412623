import axios from "axios";
import React, { useEffect, useState } from "react";
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
//modal
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Modal from "react-bootstrap/Modal";
import CollegeData from '../../../CollegeDataServices/httpCollege'
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import Service from '../../../services/httpService';
import ServiceCM from '../../../services/cartmoduleServices';
import Switch from '@mui/material/Switch';
import { saveAs } from "file-saver";
import FormControlLabel from '@mui/material/FormControlLabel';
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { antistringify as JsonParse } from '../../../functions/antistringify.js'
import { useNavigate } from 'react-router-dom';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Cookies from 'js-cookie';
import { Color } from "../../../GlobalStyles";
import ExcelJS from 'exceljs';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MentorActivity = () => {
    const location = useLocation();


    const collegeData = new CollegeData()
    const service = new Service()
    const serviceCM = new ServiceCM()
    const navigate = useNavigate();
    const batch_id = location.state?.batch_id

    console.log("batch id", batch_id);
    const batch_name = location.state?.batch_name || ""
    const college_name = location.state?.college_name || ""

    // batch data
    const [BatchData, setBatchData] = useState([])

    const [rowsData, setRowsData] = useState([{ id: null, user_group_id: null, activity_type_id: null, duration_name: "", is_deleted: 0 }])
    const [rowsData_mod_id, setRowsData_mod_id] = useState([])
    const [rowsData_mod_key, setRowsData_mod_key] = useState([])

    const tableTitles = ["Start Date", "End Date", "Download"];

    const [mentor_name, setMentor_name] = useState()

    //from table index
    const [from_table, setFrom_table] = useState();

    const [skuData, setSKUData] = useState([])
    const [processData, setProcessData] = useState([])

    // ids of batch and program
    const [jd_select_id, setJd_select_id] = useState();
    const [selectSkuId, setSelectSkuId] = useState({ id: null, name: "" });
    const [formInputs, setFormInputs] = useState({ start_date: null, end_date: null, recording: 0, timeZone: 'Asia/Kolkata' })
    const [jd_program_id, setJd_program_id] = useState();


    //get current date and time
    const currentDate = new Date();



    //activity
    const [activity, setActivity] = useState("");

    // toggle switch 
    const [is_toggled, setIs_toggled] = useState(0)






    const data_manipulation_collegeDetails = collegeDetails => {
        let temp_college = collegeDetails;
        console.log("tem data", temp_college);
        temp_college = temp_college.map(mentor => {
            mentor.first_name = mentor.first_name;
            mentor.last_name = mentor.last_name;
            mentor.id = mentor.user_id;
            mentor.email_id = mentor.email_id
            mentor.start_date = null;
            mentor.end_date = null;
            return mentor;
        });
        console.log("temp data", temp_college);
        setMentor_name(temp_college);
    };
    console.log("college nameee", mentor_name);


    const mentorUserDetailsGetter = async () => {
        try {
            const res = await service.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
            console.log("mentorUserDetailsGetter", res.data)
            // setMentorDetails([...res.data])

            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    // get request
    useEffect(() => {
        const handleEffect = async () => {

            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //process data getter
            const mentorDetailsss = await mentorUserDetailsGetter();
            data_manipulation_collegeDetails(mentorDetailsss);

        }

        handleEffect();
    }, [])

    function calculateEpochTimeAtMidnight(selectedDate, timezone) {
        console.log("selectedDate right now", selectedDate, timezone);
        const [year, month, day] = selectedDate.split('-').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

        const offsetInMilliseconds = getGmtOffsetInEpoch(timezone);
        console.log("offsetInMilliseconds", offsetInMilliseconds);
        const epochTime = date.getTime();
        console.log("get offset in epoch");
        return epochTime;
    }

    function calculateEpochTimeAfter24Hours(selectedDate, timezone) {
        console.log("selectedDate right now", selectedDate, timezone);
        const [year, month, day] = selectedDate.split('-').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day, 23, 59, 59));

        const offsetInMilliseconds = getGmtOffsetInEpoch(timezone);
        console.log("offsetInMilliseconds", offsetInMilliseconds);
        const epochTime = date.getTime()
        return epochTime;
    }

    function getGmtOffsetInEpoch(timezone) {
        const now = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', { timeZone: timezone, hour: 'numeric', minute: 'numeric', second: 'numeric' });
        const parts = formatter.formatToParts();
        console.log("partsss", parts);
        const hours = parts.find(part => part.type === 'hour').value;
        const minutes = parts.find(part => part.type === 'minute').value;

        const offsetInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);

        const offsetInMilliseconds = 1 * offsetInMinutes * 60 * 1000;
        return offsetInMilliseconds;
    }

    useEffect(() => {
        const setting_data = () => {

            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setSelectSkuId()
                setFormInputs({ ...formInputs, start_date: null, end_date: null, recording: 0 })
            }
            else {
                setSelectSkuId(processData[from_table?.index]?.sku_id)
                setFormInputs({ ...formInputs, start_date: processData[from_table?.index]?.start_date, end_date: processData[from_table?.index]?.end_date, recording: processData[from_table?.index]?.recording })
                setIs_toggled(processData[from_table?.index]?.recording)
            }

        }
        setting_data();
    }, [from_table])

    const makingRowsData = (process, sku) => {
        let temp_process = JSON.parse(JSON.stringify(process))
        let temp_sku = JSON.parse(JSON.stringify(sku))
        for (let i = 0; i < temp_process.length; i++) {
            let matchFound = false;
            for (let j = 0; j < temp_sku.length; j++) {
                if (temp_process[i].sku_id === temp_sku[j].id) {
                    temp_process[i].sku_name = temp_sku[j].name;
                    matchFound = true;
                    break;
                }
            }
            if (!matchFound) {
                temp_process[i].sku_name = null;
            }
        }

        //correcting the arrangement of keys in the json in the array
        temp_process = temp_process.map(obj => ({
            id: obj.id,
            sku_id: obj.sku_id,
            sku_name: obj.sku_name,
            start_date: obj.start_date,
            end_date: obj.end_date,
            user_group_id: obj.user_group_id,
            type: obj.type,
            recording: obj.recording
        }))
        setRowsData(temp_process)
    }

    const completeActivityDownload = (data) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        const hardcodedRow = ['first_name', 'last_name', 'email_id', 'mentor_id', 'session_count', 'college_code', 'user_group_name', 'sku_name', 'session_duration', 'order_flag'];
        worksheet.addRow([...hardcodedRow]);
        (data).forEach((row, index) => {
            const rowData = [...Object.values(row)];
            worksheet.addRow(rowData);
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const currentDate = new Date();
            const filename = `"Mentor Activity Details"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
            saveAs(blob, filename);
        });
    }

    // post request
    const handleFormSubmission = async () => {
        console.log("formInputs", formInputs);
        let temp_rows = [...rowsData];
        let temp_processData = JSON.parse(JSON.stringify(processData));
        const result = calculateEpochTimeAtMidnight(formInputs?.start_date, formInputs?.timeZone);
        const resultAfetr24Hours = calculateEpochTimeAfter24Hours(formInputs?.end_date, formInputs?.timeZone);
        let data = {
            "from": result,
            "to": resultAfetr24Hours,
        }
        console.log("User Type id not found, sending teh post request", temp_rows);
        await service
            .post("/mentor/account", data)
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                console.log(res)
                console.log(res?.data)
                completeActivityDownload(res?.data?.data)
                // const csvData = res?.data?.data?.map((row) => Object.values(row).join(",")).join("\n");
                // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
                // saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
        return temp_processData
    }




    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", rowsData);

    }

    const goBack = () => {
        console.log("Back Button is Clicked Now")
        navigate(-1);
    }



    const handleChange = (e, index) => {
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);

        if (e.target.name === "sku-select") {
            setSelectSkuId(e.target.value)
            console.log("batch selected id", e.target.value)
        }
        else if (e.target.name === "start_date") {
            setFormInputs({ ...formInputs, start_date: e.target.value })
            console.log("process start date", e.target.value)
        }
        else if (e.target.name === "end_date") {
            setFormInputs({ ...formInputs, end_date: e.target.value })
            console.log("process end date", e.target.value)
        }

    }



    return (<div style={{ marginBottom: "2rem", marginTop: "7%", marginLeft: "10%" }}>
        <TopBar />
        <SideNavigationBar />
        <div className="topHeadings" style={{ display: "flex", flexDirection:"row", gap:"1rem" }}>
            <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
            <Typography sx={{ fontSize: 20 }} >Mentor Activity Download</Typography>
        </div>
        <TableContainer className="student-details-parent" style={{ width: "60%", margin:"2rem 0 0 0" , }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {tableTitles.map((title, index) => (
                            <TableCell align='center'
                                key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                            >
                                {title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody sx={{
                    '&:last-child td, &:last-child th': {
                        borderBottom: 'none',
                    },
                }}>
                    {console.log("college namee", mentor_name)}
                    <TableRow style={{ cursor: "pointer" }}>

                        <TableCell>
                            <input name="start_date" type="date" className="form-control" placeholder="Example input" value={formInputs.start_date} onChange={(e) => handleChange(e)} />
                        </TableCell>
                        <TableCell>
                            <input name="end_date" type="date" className="form-control" placeholder="Example input" value={formInputs.end_date} onChange={(e) => handleChange(e)} />
                        </TableCell>
                        <TableCell align="center">
                            <button className='batch-button' onClick={() => handleFormSubmission()}>
                                Download
                            </button>
                        </TableCell>
                    </TableRow>
                    {/* </>
                        )
                    })} */}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    )
}

export default MentorActivity