import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import EnhancedTable from '../../reusables/TableComponent/TableComponent'
import Service from '../../../services/httpService'
import { saveAs } from "file-saver";
import Modal from "react-bootstrap/Modal";
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import CloseIcon from '@mui/icons-material/Close';
import CollegeData from '../../../CollegeDataServices/httpCollege'
import { useLocation } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Papa from 'papaparse';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Color } from "../../../GlobalStyles";
import DownloadIcon from '@mui/icons-material/Download';
import CardActions from '@mui/material/CardActions';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserType = () => {
  const navigate = useNavigate();

  const [rowsData, setRowsData] = useState([])
  const [formInputs, setFormInputs] = useState([{ email: "", roll_no: "", first_name: "", last_name: "", ph_no: "", batch_id: null, user_id: null, oop: 0 }])
  const [from_table, setFrom_table] = useState();
  const collegeData = new CollegeData()
  const location = useLocation();

  const [csvData, setCsvData] = useState(null)
  // const [count, setCount] = useState()
  const service = new Service()
  const batch_id = location.state?.batch_id
  const batch_name = location.state?.batch_name || ""
  const college_name = location.state?.college_name || ""
  //get current date and time
  const currentDate = new Date();
  const [is_toggled, setIs_toggled] = useState(0)
  const [originalStudentDetails, setOriginalStudentDetails] = useState([])

  const [options, setOptions] = useState([
    { label: 'All', value: 'All', isSelected: true },
    { label: 'Activated', value: 'Activated', isSelected: false },
    { label: 'Non Activated', value: 'Non Activated', isSelected: false },
  ]);

  let count = 0;
  const headCells = [

    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Roll Number',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Phone Number',
    }

  ];

  // handle csv upload 

  const handleCsvUpload = async (event) => {
    console.log("csv upload at student data");
    let parsedData = {};
    let data;
    // let filteredArr;
    if (event && event.target) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        parsedData = Papa.parse(reader.result, { delimiter: ',', dynamicTyping: true }).data;
        console.log("parsed data", parsedData);
        const dataToSend = parsedData.slice(1); // exclude the header row
        console.log('dataToSend before filteredArr = dataToSend.slice(0, -1);', dataToSend);
        data = dataToSend.map(row => {
          if (row[0])
            return ({
              email: row[0],
              roll_no: row[1].toString(),
              first_name: row[2],
              last_name: row[3],
              ph_no: row[4].toString(),
              batch_id: batch_id
            })
        });
        let dataNew = data.filter(el => el !== undefined);
        console.log("data inside bulk upload", dataNew);
        service
          .post(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload`, { "students_data": dataNew })
          .then((res) => {
            console.log("Response from backend is", JSON.stringify(res, null, 2));
            res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
            const jsonData = dataToSend.map((arr) => {
              return { name: arr[0] };
            });

            setRowsData(rowsData => [...jsonData, ...rowsData]); // add new data to the existing array
            window.location.reload(false)
          })
          .catch((err) => {
            console.log("Errog Msg: ", err);
            window.alert(err.response.data.message)
          });
      };
    }



  };

  const handleToggle = () => {
    console.log("toggle state currently", is_toggled);
    const temp_toggle_state = !is_toggled
    setIs_toggled(temp_toggle_state)
    setFormInputs({ ...formInputs, oop: temp_toggle_state === true ? 1 : 0 })
  }
  console.log("toggle state currently", is_toggled);
  console.log("formInputs...", formInputs);
  // delete request
  const handleDelete = async (email, index) => {
    console.log("delete", email)
    let list = [...rowsData];

    console.log("single delete", list);
    service
      .delete(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload`, {
        data: { "emails": [email] }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list.splice(index, 1);
        setRowsData(list)
        console.log("listtttt2", list);

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);

        console.log(err.response.data.message)
        window.alert(err.response.data.message)

      });
  }

  const handleDeleteMul = (id, index) => {
    console.log("delete", index)
    let list = [...rowsData];
    console.log("ids", id);
    console.log("listtttt1", list);
    service
      .delete(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload`, {
        data: { "emails": id }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list = list.filter((item) => id.indexOf(item.email) < 0);
        setRowsData(list)
        console.log("listtttt2", list);
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
      });
  }

  const data_getter = (name, id, index, activity) => {
    console.log("Dude here's your name:", name);
    console.log("Dude here's your id:", id);
    console.log("Dude here's your index:", index);
    if (name === "delete_mul") {
      console.log("inside delete mul if")
      handleDeleteMul(id, index)
    }
    else if (name === "delete") {
      handleDelete(id, index);
    }
    if (activity === "add") {
      console.log("UserType ~ activity", activity)
      setFormInputs({})
      setIs_toggled(0)
    }
    setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
  }

  // get request
  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //college getter
      let student = await studentDataGetter();
      // forming the data
      console.log("odonil", { student })
      data_manupulator_duration(student);
    }

    handleEffect();
  }, [])

  const studentDataGetter = async () => {
    try {
      const res = await service.get(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload/batchId?batchId=${batch_id}`)
      console.log("College RESPONSE:", res.data)
      setRowsData([...res.data])
      setOriginalStudentDetails([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const studentOOPGetter = async (user_id) => {
    try {
      const res = await service.get(`${process.env.REACT_APP_RD_API_URL}/superAdmin/oop?user_id=${user_id}`)
      console.log("College RESPONSE:", res.data)
      setRowsData([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const studentOOPDataGetter = async () => {
    try {
      const res = await service.get(`/oop`)
      console.log("College RESPONSE:", res.data)
      setRowsData([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    const setting_data = async () => {

      if (from_table?.index === undefined) {
        console.log("Inside if of useEffect")
        setFormInputs({})
      }
      else {
        //setting values for Edit Modal
        console.log("Inside else of useEffect", formInputs)
        if (rowsData[from_table?.index]?.user_id !== null) {
          const oopData = await studentOOPGetter(rowsData[from_table?.index]?.user_id)
          console.log("oopData", oopData);
        }
        console.log({ rowsData })
        setFormInputs({ ...formInputs, email: rowsData[from_table?.index]?.email, roll_no: rowsData[from_table?.index]?.roll_no, first_name: rowsData[from_table?.index]?.first_name, last_name: rowsData[from_table?.index]?.last_name, ph_no: rowsData[from_table?.index]?.ph_no, batch_id: rowsData[from_table?.index]?.batch_id ? rowsData[from_table?.index]?.batch_id : null, user_id: rowsData[from_table?.index]?.user_id ? rowsData[from_table?.index]?.user_id : null, oop: rowsData[from_table?.index]?.oop ? rowsData[from_table?.index]?.oop : 0 })
        setIs_toggled(rowsData[from_table?.index]?.oop)

      }
    }
    setting_data();
  }, [from_table])
  console.log("Inside else of useEffect", formInputs)

  const data_manupulator_duration = (college) => {
    console.log("college data:", college)
    let temp_rowsData = JSON.parse(JSON.stringify(college));

    //show data - send to Table Component
    temp_rowsData.forEach((row) => {

      delete row.is_deleted

    });
    console.log("temp rows:", temp_rowsData)
    setRowsData(temp_rowsData)

  }


  // post request
  const handlePostRequest = async () => {
    let data = [{
      email: formInputs.email,
      roll_no: formInputs.roll_no,
      first_name: formInputs.first_name,
      last_name: formInputs.last_name,
      ph_no: formInputs.ph_no,
      batch_id: batch_id
    }]

    console.log("User TYpe id not found, sending teh post request", data);
    await service
      .post(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload`, { "students_data": data })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = JSON.parse(JSON.stringify(rowsData));
        temp = ([{
          email: formInputs.email,
          roll_no: formInputs.roll_no,
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          ph_no: formInputs.ph_no,
          batch_id: batch_id
        }, ...temp])
        setRowsData(temp)
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
  }

  const handlePutOOPRequest = async () => {
    console.log("")
    //get request here
    service
      .put(`${process.env.REACT_APP_RD_API_URL}/superAdmin/oop`, {
        user_id: formInputs?.user_id
      })
      .then((res) => {
        // console.log("Response from backend is", res.data);
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = JSON.parse(JSON.stringify(rowsData));

        temp[from_table?.index].oop = formInputs.oop

        // temp[from_table?.index].is_deleted = rowsData[from_table?.index].is_deleted
        console.log("Put temp value:", temp)
        setRowsData(temp)

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
  }

  const handlePutRequest = async () => {
    console.log("")
    //get request here
    service
      .put(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload`, {
        existingEmail: rowsData[from_table?.index].email,
        email: formInputs.email,
        roll_no: formInputs.roll_no,
        first_name: formInputs.first_name,
        last_name: formInputs.last_name,
        ph_no: formInputs.ph_no,
        batch_id: batch_id
      })
      .then((res) => {
        // console.log("Response from backend is", res.data);
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = JSON.parse(JSON.stringify(rowsData));
        temp[from_table?.index].email = formInputs.email
        temp[from_table?.index].roll_no = formInputs.roll_no
        temp[from_table?.index].first_name = formInputs.first_name
        temp[from_table?.index].last_name = formInputs.last_name
        temp[from_table?.index].ph_no = formInputs.ph_no
        temp[from_table?.index].oop = formInputs.oop
        if (temp[from_table?.index].oop === 1) {
          console.log("oop value", temp[from_table?.index].oop = 1);
          handlePutOOPRequest()
        }
        // temp[from_table?.index].is_deleted = rowsData[from_table?.index].is_deleted
        console.log("Put temp value:", temp)
        setRowsData(temp)

      })


      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });

  }


  const Modal_Durations = (show, setShow, Mode) => {
    const modalSubmit = () => {
      console.log("from table in college data", from_table)
      if (from_table.index === undefined)
        handlePostRequest();
      else
        handlePutRequest();

      setShow(false)
    }

    const onHide = () => {
      setShow(false)
    }


    const handleChange = (e, index) => {
      //Logs for entering values
      console.log("name from form  is:", e.target.name);
      console.log("code from form  is:", e.target.value);

      //Conditions for storing values
      if (e.target.name === "email") {
        setFormInputs({ ...formInputs, email: e.target.value })
      }
      else if (e.target.name === "roll_no") {
        setFormInputs({ ...formInputs, roll_no: e.target.value })
      }
      else if (e.target.name === "first_name") {
        setFormInputs({ ...formInputs, first_name: e.target.value })
      }
      else if (e.target.name === "last_name") {
        setFormInputs({ ...formInputs, last_name: e.target.value })
      }
      else if (e.target.name === "ph_no") {
        setFormInputs({ ...formInputs, ph_no: e.target.value })
      }
    }


    //render
    return (
      <Modal
        show={show}
        onHide={() => onHide()}
        className="FileUploadModal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
      >
        <Modal.Body style={{
          position: 'absolute',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          lineHeight: '1.4',
          padding: '14px 28px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          maxWidth: '95%',
          minWidth: '500px',
          minHeight: '300px'
        }}>
          <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>
            <div className='right-side-buttons-college row'>
              <div className="headerComp" >
                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                <p className="compTitle">Student Data</p>
              </div>
              <div className="form-group">
                <label className="inputLabel">Email<span>*</span></label>
                <input name="email" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.email} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Roll Number<span>*</span></label>
                <input name="roll_no" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.roll_no} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">First Name<span>*</span></label>
                <input name="first_name" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.first_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Last Name<span>*</span></label>
                <input name="last_name" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.last_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Phone Number<span>*</span></label>
                <input name="ph_no" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.ph_no} onChange={(e) => handleChange(e)} />
              </div>
              {formInputs.batch_id === null ? <>
                <div className="form-group">
                  <label className="inputLabel">Out Of Process<span></span></label>
                  {Mode === "View" ? <><FormControlLabel
                    control={
                      <Switch
                        checked={is_toggled}
                        onChange={handleToggle}
                        // color="#1c8ea8"
                        name="toggleSwitch"
                        disabled={is_toggled === 1 ? true : false}
                      />
                    }
                  /></> : <><FormControlLabel
                    control={
                      <Switch
                        checked={is_toggled}
                        onChange={handleToggle}
                        // color="#1c8ea8"
                        name="toggleSwitch"
                        disabled={is_toggled === 1 ? true : false}
                      />
                    }
                  /></>}
                </div>
              </> : <>

              </>}

              {Mode === "View" ? <></> : (<div className=" btn-comp " style={{ marginTop: "4%", marginLeft: '0' }}>
                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
              </div>)}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is:  ", rowsData);
    // Simulate a button click after 2 seconds
    const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${college_name}_${batch_name}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  }

  const downloadCSVFormat = () => {
    const hardcodedRow = ['Email', 'Roll Number', 'First Name', 'Last Name', 'Phone Number'];
    const csvData = `${hardcodedRow.join(',')}\n`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${college_name}_${batch_name}_Student_Data_Bulk_Upload_Format_CSV_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  }

  const completeActivityDownload = () => {
    console.log("Downloading the file");
    const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  }

  const requestSearch = (searched, isSelected) => {
    console.log("searched", searched);
    let filteredList;
    const updatedOptions = options.map(option => ({
      ...option,
      isSelected: option.value === searched ? !option.isSelected : false
    }));

    console.log("options selected", updatedOptions);
    setOptions(updatedOptions);

    if (searched === "Activated") {
      filteredList = originalStudentDetails.filter((item) => item.is_verified === 1)
      setRowsData(filteredList);
    } else if (searched === "Non Activated") {
      filteredList = originalStudentDetails.filter((item) => item.is_verified === 0)
      setRowsData(filteredList);
    } else if (searched === "All") {
      filteredList = originalStudentDetails
      setRowsData(filteredList);
    }
    else {
      filteredList = originalStudentDetails.filter((item) => item.first_name?.toLowerCase().includes(searched.toLowerCase()) 
      || item.last_name?.toLowerCase().includes(searched.toLowerCase())
      || item.email?.toLowerCase().includes(searched.toLowerCase()) 
      || item.ph_no?.toLowerCase().includes(searched.toLowerCase())
      || item.roll_no?.toLowerCase().includes(searched.toLowerCase()) )
      setRowsData(filteredList);
    }
    console.log("no data found", filteredList);
  }
  console.log("originalStudentDetails", originalStudentDetails);
  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };


  return (<div >
    <RowsContext.Provider value={rowsData}>
      <TopBar />
      <SideNavigationBar />
      {console.log({ rowsData })}
      <div className='OngoingEventsCard' style={{ marginLeft: "10rem", marginTop: "5rem", overflowX: "hidden" }}>
        <div className="topHeadings" style={{ display: "flex" }}>
          <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
          <p style={{ color: Color.neutralMidGrey }}>{college_name}</p>
          <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
          <p>{batch_name}</p></div>
        <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, }}>
          <button
            className="submitBtn"
            type="button"
            style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "1rem", paddingRight: "1rem" }}
            onClick={() => completeActivityDownload()}
          >
            Download Complete Activity Details
          </button>
        </CardActions>
        <div style={{ display: "flex", marginTop: "2rem" }}>
          <div className='searchTab' style={{ alignItems: 'center', width: "90%", height: "30px", marginBottom: ".5rem" }}>
            <input style={{ border: "none", height: "30px" }}
              className='searchingText searchTab'
              type='text'
              onChange={(e) => requestSearch(e.target.value)}
              placeholder='Search for name, roll number, etc'
            />
          </div>
          <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
        </div>
        <div>
          {options.map((option, index) => (
            <button
              key={index}
              className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
              style={{}}
              onClick={() => {
                requestSearch(option.value, !option.isSelected)
              }}
            >
              {option.label}

            </button>
          ))}
        </div>
      </div>
      <div style={{ width: "90%", marginTop: "0px" }}>
        <EnhancedTable process_data={[college_name, batch_name]} data_getter={data_getter} mainFile_modal={Modal_Durations} handleCsvUpload={handleCsvUpload} headCells={headCells} count={count} fileDownload={fileDownload} downloadCSVFormat={downloadCSVFormat} path={window.location.pathname} />
      </div>
    </RowsContext.Provider>
    {/* <input type="file" onChange={handleCsvUpload} /> */}
  </div>
  )
}
// value={{rowsData, handleFormSubmission : (e,user_type_name) => handleFormSubmission(e,user_type_name)}}

export default UserType