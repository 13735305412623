import React, { useRef, useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
// import Loading from '../../Reusable_functions/LoadingComponent/LoadingComponent'
// import '../../EditProfile/EditProfile.css';
import './Blogs.css';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Typography, useMediaQuery, CardActionArea } from "@mui/material";
// import { Color } from "../../../GlobalStyles";
import { Color } from "../../GlobalStyles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Padding } from "@mui/icons-material";
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { Helmet } from "react-helmet-async";
// import HorizontalScroll from '../../Mentor/HorizontalScroll';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../functions/date/formatCovertermonthfirst";
import BlogsLoader from "./BlogsLoader";
import Loader from "../reusables/Loader/Loader";
import CategoryBasedBlogs from "./CategoryBasedBlogs";
const Blogs = (props) => {
    const service = new CCService()
    const [blogsDetails, setBlogsDetails] = useState([])
    const [tagsDetails, setTagDetails] = useState([])
    const [category, setCategory] = useState([])
    const [addOrEditTag, setAddOrEditTag] = useState("")
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const [countElement, setCountElement] = useState(1);
    const skeletonLoading = true;

    useEffect(() => {

        const handelEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/Login');
            }

            // const blogsData = await blogsDataGetter();
            // const tagsData = await tagsDataGetter()
            const categoryData = await categoryDataGetter()
            setCategory(categoryData)
            // let temp_blogsData = blogsData.filter(category => category.blogs);
            // console.log("temp blogs", temp_blogsData);
            // setBlogsDetails(temp_blogsData);
            // setTagDetails(tagsData)
            window.scrollTo(0, 0);

        };
        handelEffect();
    }, [])
    console.log("blog details", blogsDetails);
    console.log("tags details", tagsDetails);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY;
            console.log("elemnt reached Bottom", countElement);
            // Check if the user has scrolled to the bottom of the page
            if (windowHeight + scrollTop + 700 > documentHeight) {
                // Increment the count by one when the user reaches the bottom
                setCountElement((prev) => prev + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [countElement]);



   

    const categoryDataGetter = async () => {
        try {
            const res = await service.get(`/blog/category`);
            console.log("category data response", res.blog_category);
            return res.blog_category;
        } catch (err) {
            console.log(err);
        }
    };

    

    const goBack = () => {
        console.log("Back Button is Clicked Now");
        navigate(-1);
    };

    const handleNewBlogs = () => {
        setAddOrEditTag("Add")
        navigate('/EditTheBlogs', { state: { addOrEditTag: "Add" } })
    }

    return (
        <Box sx={{}}>
            <SideNavigationBar />
            <div className='OngoingEventsCard' style={{ paddingLeft: "10rem", paddingTop: "5rem" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "35px", width: "35px", marginRight: "0.5rem", marginBottom: ".5rem" }} onClick={() => goBack()} />
                <div style={{ position: 'relative' }}>
                    <p className='topHeadings' style={{ marginBottom: "2rem" }}>Blogs</p></div>
                <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: "2rem 2rem 2rem 0rem" }}>
                    <Button
                        variant="outlined"
                        style={{ color: Color.btnColor, border: "1px solid #2AA8C4", marginBottom: "1.5rem", fontSize: "20px" }}
                        type="button"
                        onClick={() => handleNewBlogs()}
                    >
                        Add New Blogs
                    </Button>
                </Box>
                <Fragment>
                    <div>
                        <div>
                            {
                                category.length > 0 &&
                                category.map((item, index) => countElement >= index && <CategoryBasedBlogs category={item} countElement={countElement} setCountElement={setCountElement} skeletonLoading={skeletonLoading} />)
                            }
                        </div>
                    </div>
                </Fragment>
            </div>
        </Box >
    );
}
export default Blogs
