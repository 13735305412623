import { Box, Button, CircularProgress, IconButton, Modal } from "@mui/material"
import { Color, DivCenter, DivRight, modalBoxStyle } from "../../../GlobalStyles"
import { useState } from "react"
import { Typography } from "antd";
import { Close } from "@mui/icons-material";

export const OfflineSessionConfirmationModal = ({ open, onClose, extraData }) => {
    const { heading, body, button1, button2 } = extraData;

    const [loading, setLoading] = useState(false);
    console.log("loading btn:", loading)
    const styles = {
        button: {
            button1: {
                color: Color.neutralMidGrey,
                background: Color.white,
                borderRadius: "12px",
                border: `1px  solid ${Color.neutralMidGrey}`,
                '&:hover': {
                    color: Color.neutralDarkGrey,
                    background: Color.neutralLightGrey,
                    border: Color.neutralMidGrey,
                }
            },
            button2: {
                background: Color.primary_gradient,
                color: 'white',
                boxShadow: 'none',
                borderRadius: "12px",
                textTransform: 'none',
                ':&hover': {
                    background: Color.primary1,
                    color: 'white',
                    boxShadow: 'none',
                }
            }
        }
    }


    return (
        <Modal open={open} onClose={onClose}>

            <Box sx={{ ...modalBoxStyle, padding: "1.5rem 0.5rem", minWidth: "600px", minHeight: "100px", justifyContent: 'space-between', }}>
                {/* <Box sx={{ ...DivCenter, width: '100%', height: '10%', justifyContent: 'space-between' }}> */}
                {/* <Typography style={{ textAlign: 'center', fontSize: '24px', width: '100%', fontWeight: 'bold' }}>{heading}</Typography> */}
                {/* <IconButton onClick={onClose}> */}
                {/* <Close /> */}
                {/* </IconButton> */}
                {/* </Box> */}

                <Typography style={{ textAlign: "center", fontWeight: 500, width: '100%', fontSize: '20px', marginBottom: "20px" }}>{body}</Typography>

                <Box sx={{ ...DivRight, width: '100%', gap: '24px', paddingRight: "12px" }}>
                    <Button variant="outlined" onClick={() => button1.action()} sx={{ ...styles.button.button1 }}>{button1.name}</Button>
                    <Button variant="outlined" onClick={!loading ? () => { setLoading(true); button2.action(); } : null} sx={{ ...styles.button.button2 }}>{loading ? <CircularProgress size={24} sx={{ color: styles.button.button1.color }} /> : button2.name}</Button>
                </Box>
            </Box>
        </Modal>
    )
}