import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import CardActions from '@mui/material/CardActions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Service from '../../../services/httpService';
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Modal from 'react-bootstrap/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from "@mui/icons-material/Close";

const MentorAwaitedByRole = (props) => {
    const service = new Service()
    const navigate = useNavigate();
    const location = useLocation();
    const { college_id, college_name, activityTypeData, not_expired, expired } = props
    const [cardDetails, setCardDetails] = useState({})
    const [tableDetails, setTableDetails] = useState([])
    const [tableActivityBasedDetails, setTableActivityBasedDetails] = useState([])
    let activityType = activityTypeData.filter((item) => item.type === "1 x 1");
    console.log("tableDetails", activityType);
    const [show, setShow] = useState(false)
    const [condition, setCondition] = useState("")
    const [activityShow, setActivityShow] = useState(false)
    const [copyList, setCopyList] = useState(condition === "Role Based" ? tableDetails : tableActivityBasedDetails)
    console.log("expired or not expired", not_expired, expired);
    const [loading, setLoading] = useState(true);

    const requestSearch = (searched) => {
        console.log("searched text", searched);
        const filteredList = (condition === "Role Based" ? tableDetails : tableActivityBasedDetails)?.filter((item) => item.role_name?.toLowerCase().includes(searched) || item.college_name?.toLowerCase().includes(searched) || item.user_group_name?.toLowerCase().includes(searched) || item.role_name?.includes(searched) || item.college_name?.includes(searched) || item.user_group_name?.includes(searched) || item.role_name?.toUpperCase().includes(searched) || item.college_name?.toUpperCase().includes(searched) || item.user_group_name?.toUpperCase().includes(searched))
        if (filteredList.length === 0) {
            setCopyList([])
        }
        setCopyList(filteredList)
    }

    console.log("copyList of role", copyList);
    // modal for role based mentor awaited and activity based
    const RoleBasedAndActivityBased = (props) => {
        console.log("bhdbjf", show, setShow, tableDetails);
        //render
        return (
            <Modal
                show={condition === "Role Based" ? show : activityShow}
                onHide={condition === "Role Based" ? () => setShow(false) : () => setActivityShow(false)}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "105%", minHeight: "100%", maxHeight: "100vh", }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    top: "10%",
                    height: 'auto',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '100%',
                    minWidth: '1000px',
                    // maxHeight: '100vh'
                }}>
                    <div>
                        <div className="container" style={{ justifyContent: 'space-between', marginTop: condition === "Role Based" ? "40%" : "20%" }}>
                            <Typography variant="body2" sx={{ fontSize: 22, marginLeft: "6rem", marginTop: "0.5rem", marginBottom: "0.5rem" }} gutterBottom>
                                Mentor Awaited
                            </Typography>
                            <CloseIcon className="closeIcon" style={{ float: "right" }} onClick={condition === "Role Based" ? () => setShow(false) : () => setActivityShow(false)} />

                        </div>
                        <div className='searchTab' style={{ marginLeft: "6rem", alignItems: 'center', width: "80%", height: "30px", marginBottom: ".5rem", justifyContent: 'center' }}>
                            <input style={{ border: "none", height: "30px" }}
                                className='searchingText searchTab'
                                type='text'
                                onChange={(e) => requestSearch(e.target.value)}
                                placeholder='Search for name, roll number, etc'
                            />
                        </div>
                        <div className="cardsInsideFlex" style={{ width: "100%", justifyContent: "center", alignItems: "center", height: "auto" }}>
                            <TableContainer component={Paper} style={{ width: "80%", justifyContent: "center", alignItems: "center", boxShadow: "none" }}>
                                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{""}</TableCell>
                                            <TableCell>Total</TableCell>
                                            {activityType.map((item) => {
                                                return (
                                                    <>
                                                        <TableCell style={{ padding: "0px" }} align='center'>{item.act_type_name}</TableCell>
                                                    </>
                                                )
                                            })}
                                        </TableRow>

                                    </TableHead>
                                    {condition === "Role Based" ? <>
                                        <TableBody>
                                            {(copyList.length > 0 ? copyList : tableDetails).map((row) => (
                                                <TableRow
                                                    key={row.role_name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.role_name}
                                                    </TableCell>

                                                    <TableCell align="center">{not_expired ? row.not_expired_count : row.expired_count}</TableCell>
                                                    {activityType.map((activity, activityIndex) => {
                                                        const matchingItem = row.activity_role_data.find(item => item.activity_type_id === activity.id);
                                                        return (
                                                            <TableCell align='center' key={activityIndex}>
                                                                {matchingItem ? not_expired ? matchingItem.not_expired_count : matchingItem.expired_count : "0"}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </> : <>
                                        <TableBody>
                                            {(copyList.length > 0 ? copyList : tableActivityBasedDetails).map((row) => (
                                                <TableRow
                                                    key={row.college_name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row?.college_name} {row?.user_group_name}
                                                    </TableCell>
                                                    <TableCell align="center">{not_expired ? row.not_expired_count : row.expired_count}</TableCell>
                                                    {activityType.map((activity, activityIndex) => {
                                                        const matchingItem = row.activity_process_data?.find(item => item.activity_type_id === activity.id);
                                                        return (
                                                            <TableCell align='center' key={activityIndex}>
                                                                {matchingItem ? not_expired ? matchingItem.not_expired_count : matchingItem.expired_count : "0"}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </>}

                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    // useeffect'
    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let mentorAwaited = await mentorAwaitedGetter();
            let mentorAwaitedRoleBased = await mentorAwaitedRoleBasedGetter();
            let mentorAwaitedActivityBased = await mentorAwaitedActivityBasedGetter()
            console.log("awaited data...", mentorAwaited, mentorAwaitedRoleBased, mentorAwaitedActivityBased);

            mentorAwaitedRoleBased.forEach((roleBasedItem) => {
                roleBasedItem.activity_role_data.forEach((roleBasedActivity) => {
                    const matchingActivity = mentorAwaited.activity_data.find(activity => activity.act_type_name === roleBasedActivity.act_type_name);

                    if (matchingActivity) {
                        roleBasedActivity.total_not_expired_count = matchingActivity.not_expired_count;
                        roleBasedActivity.total_expired_count = matchingActivity.expired_count;
                    }
                });
            });

            console.log("Updated mentorAwaitedRoleBased:", mentorAwaitedRoleBased);

            console.log("Updated mentorAwaited:", mentorAwaitedRoleBased);

            setCardDetails(mentorAwaited)
            setTableDetails(mentorAwaitedRoleBased)
            setTableActivityBasedDetails(mentorAwaitedActivityBased)
            console.log("datas", { mentorAwaited })
            setLoading(false)
        }
        handleEffect();
    }, [])

    const mentorAwaitedGetter = async () => {
        try {
            const res = await service.get(`/mentorAwaitedCount`)
            console.log("mentor awaited details response:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const mentorAwaitedRoleBasedGetter = async () => {
        try {
            const res = await service.get(`/roleBased/mentorAwaitedCount`)
            console.log("mentor roles awaited:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const mentorAwaitedActivityBasedGetter = async () => {
        try {
            const res = await service.get(`/userGrpBased/mentorAwaitedCount`)
            console.log("mentor activity awaited:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const handleRoleBasedClick = () => {
        setCopyList([])
        setCondition("Role Based")
        setShow(true)
    }

    const handleActivityBasedClick = () => {
        setCopyList([])
        setCondition("User Group based")
        setActivityShow(true)
    }



    console.log("crad details", cardDetails);
    return (
        <div>
            <Typography sx={{ fontSize: 20 }} style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                {(cardDetails?.role_data?.every(details => details.not_expired_count === 0) && not_expired) ? null :
                    (cardDetails?.role_data?.every(details => details.expired_count === 0) && !not_expired) ? null :
                        not_expired ? "Mentor Awaited" : "Expired Mentor Awaited"}
            </Typography>

            <div className="cardsInsideFlex">
                {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto', marginLeft: "40%", height: "400px" }}><CircularProgress size={24} color="inherit" /></div> : <>
                    {(cardDetails?.role_data?.every(details => details.not_expired_count === 0) && not_expired) || (cardDetails?.role_data?.every(details => details.expired_count === 0) && !not_expired) ? null : (
                        <>
                            <Box sx={{ width: 255, marginRight: '1rem', marginLeft: '.2rem', marginBottom: '.2rem', justifyContent: "space-between", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: "auto", cursor: "pointer", flexDirection: "column" }}
                            >
                                <CardContent className="cardContainer" disableGutters style={{ width: '255px' }}>
                                    <Typography sx={{ textAlign: "center", color: Color.neutralMidGrey }}>Roles</Typography>
                                    {cardDetails?.role_data?.slice(0, 5)?.map((details, index) => {
                                        return (
                                            <>
                                                <Typography variant="h5" component="div"></Typography>
                                                <div className="container">
                                                    <Typography variant="body2" class='providingSpace1'>{details.role_name}:</Typography>
                                                    <Typography variant="body2" class='providingSpace'>{not_expired ? details.not_expired_count : details.expired_count}</Typography>
                                                </div>
                                            </>
                                        )
                                    })}

                                </CardContent>
                                <CardActions sx={{ justifyContent: 'flex-end', margin: " 0 1rem 1.5rem 1rem" }}>
                                    <button
                                        className="submitBtn"
                                        type="button"
                                        style={{ height: "44px", bottom: 0, marginTop: "0" }}
                                        onClick={() => handleRoleBasedClick()}
                                    >
                                        View All
                                    </button>
                                </CardActions>
                            </Box>
                        </>
                    )}
                    {(cardDetails?.activity_data?.every(details => details.not_expired_count === 0) && not_expired) || (cardDetails?.activity_data?.every(details => details.expired_count === 0) && !not_expired) ? null :
                        <Box sx={{ width: 255, marginRight: '1rem', marginLeft: '.2rem', justifyContent: "space-between", marginBottom: '.2rem', flexDirection: "column", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: "auto", cursor: "pointer" }}
                        >
                            <CardContent className="cardContainer" disableGutters style={{ width: '255px', flex: "1" }}>
                                <Typography sx={{ textAlign: "center", color: Color.neutralMidGrey }}>Activity</Typography>

                                {cardDetails?.activity_data?.slice(0, 5)?.map((details, index) => {
                                    return (
                                        <>
                                            <Typography variant="h5" component="div"></Typography>
                                            <div className="container">
                                                <Typography variant="body2" class='providingSpace1'>{details.act_type_name}:</Typography>
                                                <Typography variant="body2" class='providingSpace'>{not_expired ? details.not_expired_count : details.expired_count}</Typography>
                                            </div>
                                        </>
                                    )
                                })}

                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-end', margin: " 0 1rem 1.5rem 1rem", }}>
                                <button
                                    className="submitBtn"
                                    type="button"
                                    style={{ height: "44px", bottom: 0, marginTop: "0" }}
                                    onClick={() => handleRoleBasedClick()}
                                >
                                    View All
                                </button>
                            </CardActions>
                        </Box>}
                    {(cardDetails?.process_data?.every(details => details.not_expired_count === 0) && not_expired) || (cardDetails?.process_data?.every(details => details.expired_count === 0) && !not_expired) ? null :
                        <Box sx={{ width: 255, marginRight: '1rem', marginLeft: '.2rem', marginBottom: '.2rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: "auto", cursor: "pointer", flexDirection: "column" }}
                        >
                            <CardContent className="cardContainer" disableGutters style={{ width: '255px', flex: "1" }}>
                                <Typography sx={{ textAlign: "center", color: Color.neutralMidGrey }}>College Batch Activity</Typography>

                                {cardDetails?.process_data?.slice(0, 2)?.map((details, index) => {
                                    return (
                                        <div style={{ paddingBottom: "0.5rem", paddingTop: ".5rem" }}>
                                            {/* <Typography variant="h5" component="div"></Typography> */}
                                            <Typography variant="body2" class='providingSpace1'>{details.college_name ? details.college_name : "(College Name not provided)"}:</Typography>
                                            <div className="container">
                                                <Typography variant="body2" class='providingSpace1'>{details.user_group_name}:</Typography>
                                                <Typography variant="body2" class='providingSpace'>{not_expired ? details.not_expired_count : details.expired_count}</Typography>
                                            </div>
                                        </div>
                                    )
                                })}

                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-end', margin: " 0 1rem 1.5rem 1rem" }}>
                                <button
                                    className="submitBtn"
                                    type="button"
                                    style={{ height: "44px", bottom: 0, marginTop: "0" }}
                                    onClick={() => handleActivityBasedClick()}
                                >
                                    View All
                                </button>
                            </CardActions>
                            <RoleBasedAndActivityBased show={condition === "Role Based" ? show : activityShow} onHide={condition === "Role Based" ? setShow : setActivityShow} />
                        </Box>}

                </>}
            </div>
        </div>
    )
}

export default MentorAwaitedByRole
