import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeService from "../../../functions/epochTime";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { DivCenter } from "../../../GlobalStyles";
export function DeadlineTimeCalendarModal(props) {
    const { open, onClose, extraData: { heading, sub_heading, action_button, action_button_color, handleAction, } } = props;
    const service = new CCService()
    const [formError, setFormError] = useState({
        meetingLink: "",
    });
    const timeService = new TimeService();
    let previousdate = new Date().toISOString().slice(0, 10);

    const navigate = useNavigate()
    let previousTime = `${new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`;
    // console.log("DeadlineTimeCalendarModal ~ shouldDisableDates:", props?.shouldDisableDates)
    console.log("DeadlineTimeCalendarModal ~ previousTime:", previousTime)
    let timeCheck = previousTime.split(":");
    if (timeCheck[0].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }
    if (timeCheck[1].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }

    const [value, setValue] = useState(dayjs(previousdate));
    const [ampm, setAmpm] = useState("AM");

    const [time, setTime] = useState(previousTime);
    const [meetingLink, setMeetingLink] = useState("");
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const services = new CCService();
    const handleSave = async () => {
        if (props?.setPublishConfirmationModal) {
            // props.handleModalClose(); //removed this coz i felt like the user should be able to see the chosen date
            props.setPublishConfirmationModal((prev) => ({ ...prev, open: true, extraData: { value: value, time: time } }));
        } else {
            const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
            const epochTime = date.getTime()
            try {
                const res = await service.get(`/po/ca/publish?po_job_id=${props.job_id}&application_deadline=${epochTime}`)
                props.handleModalClose()
                console.log("verify", res);
                navigate("/JobsPostingPage", { state: { batch_id: props.batch_id } })
                // handleEffect()
                return res
            } catch (err) {
                console.log(err)
            }
        }
    };
    useEffect(() => {
        if (parseInt(time.substring(0, 2)) > 11) {
            setAmpm("PM");
        } else {
            setAmpm("AM");
        }
    }, [time, value]);

    // console.log("value inside date selected", date, time, epochTime);
    return (
        <Box>
            <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ width: "40%", minWidth: "700px", background: "#fff", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "20px", padding: "24px" }}>
                    <Box>
                        <Typography sx={{ fontSize: "24px" }}>{heading}</Typography>
                        {sub_heading ? <Typography sx={{ fontSize: "20px" }}>{sub_heading}</Typography> : <></>}
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: "45%", background: "#165983", borderRadius: "0px 0px 0px 0px", padding: "20px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                            <Box>
                                <Typography sx={{ color: "#fff", fontSize: "20px" }}>{value["$d"].getFullYear()}</Typography>
                                <Typography></Typography>
                                <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                                    {days[value["$d"].getDay()]}, {months[value["$d"].getUTCMonth()]} {value["$d"].getDate()}
                                </Typography>
                            </Box>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeClock value={time} onChange={(newValue) => setTime(newValue)} ampm />
                                </LocalizationProvider> */}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <TextField
                                    type="time"
                                    value={time}
                                    InputProps={{
                                        sx: {
                                            height: "74px",
                                            width: "fit-content",
                                            m: 0,

                                            fontSize: "30px",
                                            color: "#fff",
                                            borderRadius: "10px 0px 0px 10px",

                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                                borderRadius: "5px 5px 0 0",
                                            },
                                        },
                                        endAdornment: null,
                                    }}
                                    onChange={(e) => setTime(e.target.value)}
                                    sx={{
                                        background: "#165983",
                                        border: "1px solid #fff",
                                        borderRight: "none",
                                        borderRadius: "15px 0px 0px 15px",
                                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                                            filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                                            color: "#165983",
                                        },
                                        "& .MuiFilledInput-root": {
                                            background: "rgb(232, 241, 250)",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                        "&:hover fieldset": {
                                            border: "none",
                                            borderColor: "none!important",
                                        },
                                    }}
                                />
                                <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                                    <Typography sx={{ fontSize: "14px", padding: "5px", background: ampm === "AM" ? "#fff" : "#165983", color: ampm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>AM</Typography>
                                    <Typography sx={{ fontSize: "14px", padding: "5px", background: ampm === "PM" ? "#fff" : "#165983", color: ampm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>PM</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: "55%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "14px", color: "#165983" }}>{day}</Typography>} disablePast />
                            </LocalizationProvider>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px", width: "100%", margin: "0 auto" }}>
                        <Button
                            sx={{ width: "50%", height: '57px', minWidth: '100px', padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }}
                            onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button
                            sx={{ width: "50%", height: '57px', minWidth: '100px', padding: "8px 15px", borderRadius: "15px", background: action_button_color ?? "#165983", color: "#fff", border: `1px solid ${action_button_color ?? "#165983"}`, "&:hover": { background: action_button_color ?? "#165983", color: "#fff" } }}
                            onClick={() => handleAction(value, time)}>
                            {action_button}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export function DeadlineCalendarModal(props) {
    const { open, onClose, extraData: { heading, sub_heading, action_button, action_button_color, handleAction, }, } = props;
    const service = new CCService()
    const [formError, setFormError] = useState({
        meetingLink: "",
    });
    const timeService = new TimeService();
    let previousdate = new Date().toISOString().slice(0, 10);
    // console.log("props nhoomx:", props?.shouldDisableDates);
    const navigate = useNavigate()
    let previousTime = `${new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`;

    let timeCheck = previousTime.split(":");
    if (timeCheck[0].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }
    if (timeCheck[1].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }

    const [value, setValue] = useState();
    const [ampm, setAmpm] = useState("AM");

    const [time, setTime] = useState(previousTime);
    const [meetingLink, setMeetingLink] = useState("");
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const services = new CCService();
    const handleSave = async () => {
        if (props?.setPublishConfirmationModal) {
            // props.handleModalClose(); //removed this coz i felt like the user should be able to see the chosen date
            props.setPublishConfirmationModal((prev) => ({ ...prev, open: true, extraData: { value: value, time: time } }));
        } else {
            const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
            const epochTime = date.getTime()
            try {
                const res = await service.get(`/po/ca/publish?po_job_id=${props.job_id}&application_deadline=${epochTime}`)
                props.handleModalClose()
                console.log("verify", res);
                navigate("/JobsPostingPage", { state: { batch_id: props.batch_id } })
                // handleEffect()
                return res
            } catch (err) {
                console.log(err)
            }
        }
    };
    useEffect(() => {
        if (parseInt(time.substring(0, 2)) > 11) {
            setAmpm("PM");
        } else {
            setAmpm("AM");
        }
    }, [time, value]);
    const disabledDates = props?.shouldDisableDates?.map((item) => (JSON.stringify(new Date(item.session_date).getDate()) + " " + JSON.stringify(new Date(item.session_date).getMonth()) + " " + JSON.stringify(new Date(item.session_date).getFullYear())));

    function checkDates(temp_date) {
        const formatted_date = JSON.stringify(((temp_date["$d"].getDate()))) + " " + JSON.stringify((temp_date["$d"].getMonth())) + " " + JSON.stringify((temp_date["$d"].getFullYear()));
        if (disabledDates.includes((formatted_date))) {
            return true;
        } return false;
    }
    // console.log("value inside date selected", date, time, epochTime);
    return (
        <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: "auto", minWidth: "300px", background: "#fff", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "20px", padding: "24px" }}>
                <Box>
                    <Typography sx={{ fontSize: "24px" }}>{heading}</Typography>
                    {sub_heading ? <Typography sx={{ fontSize: "20px" }}>{sub_heading}</Typography> : <></>}
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100%" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                shouldDisableDate={props?.shouldDisableDates ? checkDates : null}
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                                views={["year", "month", "day"]}
                                dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "14px", color: "#165983" }}>{day}</Typography>}
                                disablePast={props?.disablePast ?? false}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px", width: "100%", margin: "0 auto" }}>
                    <Button
                        sx={{ width: "50%", height: '57px', minWidth: '100px', padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }}
                        onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ width: "50%", height: '57px', minWidth: '100px', padding: "8px 15px", borderRadius: "15px", background: action_button_color ?? "#165983", color: "#fff", border: `1px solid ${action_button_color ?? "#165983"}`, "&:hover": { background: action_button_color ?? "#165983", color: "#fff" } }}
                        onClick={() => handleAction(value, time)}>
                        {action_button}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export function DeadlineTimeModal(props) {
    const { open, onClose, extraData: { heading, sub_heading, action_button, action_button_color, handleAction, preloaded_time } } = props;
    const service = new CCService()
    const [formError, setFormError] = useState({
        meetingLink: "",
    });
    const timeService = new TimeService();
    const options = { year: 'numeric', month :'2-digit', day : '2-digit' }
    let previousdate = preloaded_time ? new Date(preloaded_time).toLocaleDateString('en-US', options) : new Date().toLocaleDateString('en-US', options);
    console.log("previous date", previousdate);
    const navigate = useNavigate()
    let previousTime = preloaded_time ?
        `${new Date(preloaded_time).getHours() < 10 ? `0${new Date(preloaded_time).getHours()}` : new Date(preloaded_time).getHours()} :
         ${new Date(preloaded_time).getMinutes() < 10 ? `0${new Date(preloaded_time).getMinutes()}` : new Date(preloaded_time).getMinutes()}` :
        `${new Date().getHours()}:${new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()}`;

    console.log("DeadlineTimeCalendarModal ~ previousTime:", previousTime)
    let timeCheck = previousTime.split(":");
    if (timeCheck[0].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }
    if (timeCheck[1].length !== 2) {
        previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
    }

    const [value, setValue] = useState(dayjs(previousdate));
    const [ampm, setAmpm] = useState("AM");
    console.log("prevoius date and value:", previousdate, value)
    const [time, setTime] = useState(previousTime);
    const [meetingLink, setMeetingLink] = useState("");
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const services = new CCService();
    const handleSave = async () => {
        if (props?.setPublishConfirmationModal) {
            // props.handleModalClose(); //removed this coz i felt like the user should be able to see the chosen date
            props.setPublishConfirmationModal((prev) => ({ ...prev, open: true, extraData: { value: value, time: time } }));
        } else {
            const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
            const epochTime = date.getTime()
            try {
                const res = await service.get(`/po/ca/publish?po_job_id=${props.job_id}&application_deadline=${epochTime}`)
                props.handleModalClose()
                console.log("verify", res);
                navigate("/JobsPostingPage", { state: { batch_id: props.batch_id } })
                // handleEffect()
                return res
            } catch (err) {
                console.log(err)
            }
        }
    };
    useEffect(() => {
        if (parseInt(time.substring(0, 2)) > 11) {
            setAmpm("PM");
        } else {
            setAmpm("AM");
        }
    }, [time, value]);

    // console.log("value inside date selected", date, time, epochTime);
    return (
        <Box>
            <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ ...DivCenter, width: "auto", minWidth: "500px", background: "#fff", borderRadius: "15px", flexDirection: "column", gap: "20px", padding: "24px" }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ fontSize: "24px", textAlign: 'center' }}>{heading}</Typography>
                        {sub_heading ? <Typography sx={{ fontSize: "20px" }}>{sub_heading}</Typography> : <></>}
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Box sx={{ ...DivCenter, width: "100%", background: "#165983", padding: "20px", display: "flex", flexDirection: "column", gap: "1.5rem", borderRadius: '12px' }}>
                            <Box sx={{ ...DivCenter, flexDirection: 'column' }}>
                                <Typography sx={{ color: "#fff", fontSize: "24px" }}>{value["$d"].getFullYear()}</Typography>
                                <Typography></Typography>
                                <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                                    {days[value["$d"].getDay()]}, {months[value["$d"].getUTCMonth()]} {value["$d"].getDate()}
                                </Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, }}>
                                <TextField
                                    type="time"
                                    value={time}
                                    InputProps={{
                                        sx: {
                                            height: "74px",
                                            width: "fit-content",
                                            m: 0,

                                            fontSize: "30px",
                                            color: "#fff",
                                            borderRadius: "10px 0px 0px 10px",

                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                                borderRadius: "5px 5px 0 0",
                                            },
                                        },
                                        endAdornment: null,
                                    }}
                                    onChange={(e) => setTime(e.target.value)}
                                    sx={{
                                        background: "#165983",
                                        border: "1px solid #fff",
                                        borderRight: "none",
                                        borderRadius: "15px 0px 0px 15px",
                                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                                            filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                                            color: "#165983",
                                        },
                                        "& .MuiFilledInput-root": {
                                            background: "rgb(232, 241, 250)",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                        "&:hover fieldset": {
                                            border: "none",
                                            borderColor: "none!important",
                                        },
                                    }}
                                />
                                <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                                    <Typography sx={{ fontSize: "14px", padding: "5px", background: ampm === "AM" ? "#fff" : "#165983", color: ampm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>AM</Typography>
                                    <Typography sx={{ fontSize: "14px", padding: "5px", background: ampm === "PM" ? "#fff" : "#165983", color: ampm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>PM</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box sx={{ width: "55%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "14px", color: "#165983" }}>{day}</Typography>} disablePast />
                            </LocalizationProvider>
                        </Box> */}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", gap: "20px", width: "100%", margin: "0 auto" }}>
                        <Button
                            sx={{ width: "100%", height: '57px', minWidth: '100px', padding: "8px 15px", borderRadius: "15px", background: action_button_color ?? "#165983", color: "#fff", border: `1px solid ${action_button_color ?? "#165983"}`, "&:hover": { background: action_button_color ?? "#165983", color: "#fff" } }}
                            onClick={() => handleAction(value, time)}>
                            {action_button}
                        </Button>
                        <Button
                            sx={{ width: "100%", height: '57px', minWidth: '100px', padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }}
                            onClick={() => onClose()}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}