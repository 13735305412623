import React, { useRef, useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Typography from "@mui/material/Typography";
import ReactApexChart from "react-apexcharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
// import Service from "../../../services/httpService";
import SchoolIcon from "@mui/icons-material/School";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import TrafficIcon from "@mui/icons-material/Traffic";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Chart from "react-apexcharts";
import { BatchTrackerSkeleton } from "../../reusables/SkeletonLoader/SkeletonLoader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
const InterviewWiseAnalytics = (props) => {
  const navigate = useNavigate();
  const service = new CCService();
  const { college_id, college_name, program_id, program_name, process_id, sku_name, countElement, setCountElement,downloadButtonClicked } = props;

  const [filteredBatchAnalyticsData, setFilteredBatchAnalyticsData] = useState([]);
  const [experiencedRAGData, setExperiencedRAGData] = useState({ ER: null, EA: null, EG: null });
  const [freshersRAGData, setFreshersRAGData] = useState({ FR: null, FA: null, FG: null });
  const [RedAverageData, setRedAverageData] = useState(0);
  const [AmberAverageData, setAmberAverageData] = useState(0);
  const [GreenAverageData, setGreenAverageData] = useState(0);
  const [percentages, setPercentages] = useState({});
  const [communication, setCommunication] = useState();
  const [introduction, setIntroduction] = useState([]);
  const [work_experience, setWork_Experience] = useState([]);
  const [resume_pointers, setResume_Pointers] = useState([]);
  const [concept_understanding, setConcept_Understanding] = useState([]);
  const [freshersRating, setFreshersRating] = useState(0);
  const [experiencedRating, setExperiencedRating] = useState(0);
  const [batchAverageRating, setBatchAverageRating] = useState(0);
  const [studentDetails, setStudentDetails] = useState({});
  const [not_overall_hireability, setNot_Overall_Hireability] = useState(0);
  const [freshers_hire_ready, setFreshers_Hire_Ready] = useState([]);
  const [experienced_hire_ready, setExperienced_Hire_Ready] = useState([]);
  const [studentAverageData, setStudentAverageData] = useState();
  const [batchProcessDataGetter, setBatchProcessDataGetter] = useState([]);
  const [batchAnalyticsData, setBatchAnalyticsData] = useState([]);
  const [interviewWiseSkeletonLoader, setInterviewWiseSkeletonLoader] = useState(false);
  const [interviewWiseDataRendering, setInterviewWiseDataRendering] = useState(true);
  console.log("batch analytics data", batchAnalyticsData);

  const [hireabilityOptions, setHireabilityOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      id: "apexchart-example",
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ["#1f8fa9", "#E8E8E8"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -16,
      style: {
        fontSize: "12px",
        colors: ["#1f8fa9"],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        dataLabels: {
          position: "top",
          offsetY: 30000,
        },
      },
    },
    yaxis: {
      show: true,
      max: 110,
      tickAmount: 11,
      title: {
        text: "Percentage %",
        style: {
          fontSize: "12px",
          fontWeight: 600,
        },
      },
    },
    xaxis: {
      categories: ["a", "b", "c", "d", "e", "f", "g", "h", "i"],
    },
    legend: {
      show: true,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
      customLegendItems: ["Hire-able", "NOT Hire-able"],
    },
  });
  
  const [hireabilitySeries, setHireabilitySeries] = useState([
    {
      name: "Hire-able",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      name: "NOT Hire-able",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ]);

  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      setInterviewWiseSkeletonLoader(true);

      // let batchProcessesDetails = await batchProcessGetter();
      let batchAnalysisResults = await batchAnalyticsDataGetter(process_id);

      if (batchAnalysisResults.length === 0) {
        setInterviewWiseSkeletonLoader(false);
        setInterviewWiseDataRendering(false);
        setFilteredBatchAnalyticsData([]);
        setCountElement((prev) => prev + 1);
      } else {
        const batchAnalyticsDetails = batchAnalysisResults;
        console.log("inside the array", batchAnalyticsDetails);
        setFreshersRating(batchAnalyticsDetails[0]?.fresher_avg_rating);
        setExperiencedRating(batchAnalyticsDetails[0]?.exp_avg_rating);
        setBatchAverageRating(batchAnalyticsDetails[0]?.batch_avg_rating);
        setBatchAnalyticsData(batchAnalyticsDetails)
        let temp_experienced_rag_data = {
          ER: batchAnalyticsDetails[0]["ER"],
          EA: batchAnalyticsDetails[0]["EA"],
          EG: batchAnalyticsDetails[0]["EG"],
        };
        setExperiencedRAGData(temp_experienced_rag_data);

        let temp_freshers_rag_data = {
          FR: batchAnalyticsDetails[0]["FR"],
          FA: batchAnalyticsDetails[0]["FA"],
          FG: batchAnalyticsDetails[0]["FG"],
        };

        // Extract and update freshersRAGData
        setFreshersRAGData(temp_freshers_rag_data);

        setRedAverageData(batchAnalyticsDetails[0]["R"]);
        setAmberAverageData(batchAnalyticsDetails[0]["A"]);
        setGreenAverageData(batchAnalyticsDetails[0]["G"]);

        const modifiedIntroduction = batchAnalyticsDetails[0] ? batchAnalyticsDetails[0]?.["Introduction"].replace(/NaN/g, "0") : "";
        const temp_introduction = JSON.parse(modifiedIntroduction);
        // const temp_introduction = JSON.parse(batchAnalyticsDetails[0]?.["Introduction"]);
        const modifiedWorkExperience = batchAnalyticsDetails[0] ? batchAnalyticsDetails[0]?.["Work Experience"].replace(/NaN/g, "0") : "";
        const temp_work_ex = JSON.parse(modifiedWorkExperience);
        console.log("temp work ex", temp_work_ex);
        // const temp_work_ex = JSON.parse(batchAnalyticsDetails[0]?.["Work Experience"]);
        const modifiedResumePointers = batchAnalyticsDetails[0] ? batchAnalyticsDetails[0]?.["Resume Pointers"].replace(/NaN/g, "0") : "";
        const temp_resume_pointers = JSON.parse(modifiedResumePointers);
        // const temp_resume_pointers = JSON.parse(batchAnalyticsDetails[0]?.["Resume Pointers"]);
        const modifiedConceptUnderstanding = batchAnalyticsDetails[0] ? batchAnalyticsDetails[0]?.["Concept Understanding"].replace(/NaN/g, "0") : "";
        const temp_concept_understanding = JSON.parse(modifiedConceptUnderstanding);

        const hireData = JSON.parse(batchAnalyticsDetails[0]?.hire_ready_stu); // Parse the JSON string

        const freshersData = {};
        const expData = {};

        for (const role in hireData) {
          if (hireData.hasOwnProperty(role)) {
            freshersData[role] = hireData[role].freshers;
            expData[role] = hireData[role].exp;
          }
        }

        const Non_hireData = JSON.parse(batchAnalyticsDetails[0]?.not_hire_ready_stu); // Parse the JSON string

        const non_hire_freshersData = {};
        const non_hire_expData = {};

        for (const role in Non_hireData) {
          if (Non_hireData.hasOwnProperty(role)) {
            non_hire_freshersData[role] = Non_hireData[role].freshers;
            non_hire_expData[role] = Non_hireData[role].exp;
          }
        }

        console.log("Freshers Data:", freshersData);
        console.log("Exp Data:", expData);
        setFreshers_Hire_Ready(freshersData);
        setExperienced_Hire_Ready(expData);

        setIntroduction(temp_introduction);
        setWork_Experience(temp_work_ex);
        setResume_Pointers(temp_resume_pointers);
        setConcept_Understanding(temp_concept_understanding);
        console.log("all the feedback info", temp_introduction, temp_work_ex, temp_resume_pointers, temp_concept_understanding);

        const temp_student_details = JSON.parse(batchAnalyticsDetails[0]?.interview_data);
        console.log("temp_student_details", temp_student_details);
        setStudentDetails(temp_student_details);

        const overallHireabilityValue = batchAnalyticsDetails[0]?.overall_hireaility;
        const percentage = overallHireabilityValue * 100; // Convert to percentage

        const remainingPercentage = 100 - percentage;
        setNot_Overall_Hireability(remainingPercentage);
        console.log(`Remaining Percentage: ${remainingPercentage}%`);

        let temp_rolePreference = JSON.parse(batchAnalyticsDetails[0].spe_wize);
        console.log("role preparedness", temp_rolePreference);
        
        const hirableCount = {};
        const nonHirableCount = {};

        for (const [category, hirablePercentage] of Object.entries(temp_rolePreference)) {
          hirableCount[category] = (hirablePercentage * 100).toFixed(0);
          nonHirableCount[category] = (100 - hirablePercentage * 100).toFixed(0);
        }

        console.log("hireable count", hirableCount, nonHirableCount);

        setHireabilitySeries([{
          name: "Hire-able",
          data: Object.values(hirableCount),
        },
        {
          name: "NOT Hire-able",
          data: Object.values(nonHirableCount),
        },]);

        setHireabilityOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: Object.keys(hirableCount),
            },
          };
        });


        let temp_rolePreference1 = JSON.parse(batchAnalyticsDetails[0].role_prefrence);

        const totalCounts = JSON.parse(temp_rolePreference1.total);
        const hireableCounts = JSON.parse(temp_rolePreference1.hireable);

        const allRoles = Object.keys(totalCounts);

        const roleCounts = {};
        allRoles.forEach((role) => {
          roleCounts[role] = {
            total: totalCounts[role],
            hireable: hireableCounts[role],
          };
        });

        console.log("roleCounts", roleCounts);

        let temp_role_data = JSON.parse(temp_rolePreference1?.total);
        let temp_role_hirable_data = JSON.parse(temp_rolePreference1?.hireable);
        console.log("temp_role_data", temp_role_data, temp_role_hirable_data, temp_rolePreference1);
        // Assuming you have the roleOUcnt object
        const roleTotalCounts = {};
        const roleHireableCounts = {};

        for (const role in roleCounts) {
          if (roleCounts.hasOwnProperty(role)) {
            roleTotalCounts[role] = roleCounts[role].total;
            roleHireableCounts[role] = roleCounts[role].hireable;
          }
        }

        console.log("counts of json", roleTotalCounts, roleHireableCounts);

        // Assuming you have the roleCount object
        const roleData = Object.keys(roleCounts).map((role) => [
          {
            name: "Total Count",
            data: roleCounts[role].total ? roleCounts[role].total : null,
          },
          {
            name: "Hirable Count",
            data: roleCounts[role].hireable ? roleCounts[role].hireable : null,
          },
        ]);
        // console.log("roleData", roleData);

        let roleCount = Object.values(roleHireableCounts);
        roleCount = roleCount.map((item) => {
          if (item) {
            return item;
          } else {
            return 0;
          }
        });

        setRolePreferenceSeries([
          {
            name: "Hirable Count",
            // data: Object.values(roleHireableCounts),
            data: roleCount,
          },
          {
            name: "Total Count",
            data: Object.values(roleTotalCounts),
          },
        ]);

        setRolePreferenceOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: Object.keys(roleTotalCounts),
            },
          };
        });

        setHireabilityFreshersSeries([
          {
            name: "Freshers",
            data: Object.values(freshersData).map(DecimalToPercentage),
          },
          {
            name: "Exp",
            data: Object.values(expData).map(DecimalToPercentage),
          },
        ]);
        setHireabilityFreshersOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: Object.keys(freshersData),
            },
          };
        });

        console.log("freshers and not", freshersData, expData);

        setHireabilityExperiencedSeries([
          {
            name: "Freshers",
            data: Object.values(non_hire_freshersData).map(DecimalToPercentage),
          },
          {
            name: "Exp",
            data: Object.values(non_hire_expData).map(DecimalToPercentage),
          },
        ]);
        setHireabilityExperiencedOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: Object.keys(non_hire_freshersData),
            },
          };
        });
        let mapping_of_data = Object.values(temp_freshers_rag_data).map(DecimalToPercentage);
        const mappedDataWithPercentage = mapping_of_data.map(value => `${value}%`);
        console.log("mappedDataWithPercentage",mappedDataWithPercentage);
        let mapping_of_experienced_data = Object.values(temp_experienced_rag_data).map(DecimalToPercentage);
        console.log("mapping_of_data", mapping_of_data, mapping_of_data[0]);
        console.log("mapping data", Object.values(freshersRAGData).map(DecimalToPercentage));
        setFreshersAndExperiencedSeries([
          {
            data: [mappedDataWithPercentage[0]],
          },
          {
            data: [mappedDataWithPercentage[1]],
          },
          {
            data: [mappedDataWithPercentage[2]],
          },
        ]);

        setExperiencedRAGSeries([
          {
            data: [mapping_of_experienced_data[0]],
          },
          {
            data: [mapping_of_experienced_data[1]],
          },
          {
            data: [mapping_of_experienced_data[2]],
          },
        ]);

        const studentRatingData = JSON.parse(batchAnalyticsDetails[0]?.student_rating);
        const values = Object.values(studentRatingData);
        const sum = values.reduce((acc, value) => acc + value, 0);
        const average = sum / values.length;

        let result = 0;

        for (const key in studentRatingData) {
          if (studentRatingData.hasOwnProperty(key)) {
            result += parseFloat(key) * studentRatingData[key];
          }
        }

        setStudentAverageData(result.toFixed(2));

        setPercentages(studentRatingData);

        setStudentRatings((prev) => {
          const updatedOptions = {
            ...prev.options,
            labels: Object.keys(studentRatingData).map((label) => `${label} rating (${(studentRatingData[label]?.toFixed(4) * 100).toFixed(2)}%)`),
          };

          return {
            ...prev,
            options: updatedOptions,
            series: Object.values(studentRatingData),
          };
        });

        console.log(
          "something",
          Object.entries(studentRatingData).map(([key, value]) => {
            return {
              name: key,
              value: value,
            };
          })
        );

        console.log("freshersData", freshersData);

        console.log("introductionmsndndj", Object.keys(temp_introduction));
          // forming the data
        console.log("bhoomika", { batchAnalyticsDetails });
        setFilteredBatchAnalyticsData(batchAnalyticsDetails);
        setInterviewWiseSkeletonLoader(false);
      }
    };

    handleEffect();
  }, []);
  console.log("freshers and experienced rag data", experiencedRAGData, freshersRAGData);
  console.log("percentages in", percentages);

  // decimal to percentage
  function DecimalToPercentage(data) {
    const percentage = data * 100;
    return Math.round(percentage);
  }

  const totalExperiencedData = Object.values(experiencedRAGData).reduce((acc, value) => acc + value, 0);
  const totalFreshersData = Object.values(freshersRAGData).reduce((acc, value) => acc + value, 0);

  // Normalize the values to add up to 1.0 (100%)
  const experiencedData = Object.keys(experiencedRAGData).map((key) => ({
    name: key,
    value: experiencedRAGData[key] / totalExperiencedData,
  }));
  const freshersData = Object.keys(freshersRAGData).map((key) => ({
    name: key,
    value: freshersRAGData[key] / totalFreshersData,
  }));

  const RAGPercentageData = [
    {
      name: "Experienced",
      data: experiencedData.map((item) => item.value),
    },
    {
      name: "Freshers",
      data: freshersData.map((item) => item.value),
    },
  ];
  

  // semi circle data

  const communicationData = {
    series: [DecimalToPercentage(filteredBatchAnalyticsData[0]?.communication)],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false
        }
      },
      colors: ["#41B8D5", "#E8E8E8"],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "100%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#E8E8E8",
              // opacity: 1,
              // blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
    },
  };

  const [rolePreferenceOptions, setRolePreferenceOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      id: "apexchart-example",
      stacked: true,
      foreColor: "#000",
      toolbar: {
        show: false
      }
    },
    colors: ["#1f8fa9", "#afe5f1"],
    xaxis: {
      categories: ["a", "b", "c", "d", "e", "f", "g", "h", "i"],
    },

    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -18,
      style: {
        fontSize: "12px",
        colors: ["#1f8fa9"],
      },
    },
    yaxis: {
      show: true,
      title: {
        text: "No. of students",
        style: {
          fontSize: "12px",
          fontWeight: 600,
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
      customLegendItems: ["Hire-able (Yes)", "Role Preference"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        dataLabels: {
          position: "top",
          offsetY: 30000,
        }, // Adjust the percentage to change the bar width
      },
    },
  });

  const [rolePreferenceSeries, setRolePreferenceSeries] = useState([
    {
      name: "Total Count",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      name: "Hirable Count",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ]);

  const [hireabilityFreshersOptions, setHireabilityFreshersOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      id: "apexchart-example",
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ["#1f8fa9", "#afe5f1"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -16,
      style: {
        fontSize: "12px",
        colors: ["#1f8fa9"],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        dataLabels: {
          position: "top",
          offsetY: 30000,
        },
      },
    },
    yaxis: {
      show: true,
      max: 110,
      tickAmount: 11,
      title: {
        text: "Percentage %",
        style: {
          fontSize: "12px",
          fontWeight: 600,
        },
      },
    },
    xaxis: {
      categories: ["a", "b", "c", "d", "e", "f", "g", "h", "i"],
      
    },
    legend: {
      show: true,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
      customLegendItems: ["Freshers", "Work-ex"],
    },
  });

  // const [hireabilityFreshersSeries, setHireabilityFreshersSeries] = useState([
  //     {
  //         data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  //     },
  // ]);
  const [hireabilityFreshersSeries, setHireabilityFreshersSeries] = useState([
    {
      name: "Freshers",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      name: "Exp",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ]);

  const [hireabilityExperiencedOptions, setHireabilityExperiencedOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      id: "apexchart-example",
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ["#1f8fa9", "#afe5f1"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1f8fa9"],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",

        dataLabels: {
          position: "top",
          offsetY: -30,
        },
      },
    },

    yaxis: {
      show: true,
      max: 110,
      tickAmount: 11,
      title: {
        text: "Percentage %",
        style: {
          fontSize: "12px",
          fontWeight: 600,
        },
      },
    },
    xaxis: {
      categories: ["a", "b", "c", "d", "e", "f", "g", "h", "i"],
    },
    legend: {
      show: true,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
      customLegendItems: ["Freshers", "Work-ex"],
    },
  });

  const [hireabilityExperiencedSeries, setHireabilityExperiencedSeries] = useState([
    {
      name: "Freshers",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      name: "Exp",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ]);

  const [FreshersAndExperiencedOptions, setFreshersAndExperiencedOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      id: "apexchart-example",
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      }
    },
    colors: ["#FF7575", "#FFBF00", "#4BD492"],

    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    grid: { show: false },
    xaxis: {
      // categories: ["Freshers"],
      axisBorder: { show: false },
      show: false,
      max: 100,
      labels: {
        show: false, // Hide y-axis scale labels
      },
      lines: {
        show: false, // Hide x-axis lines
      },
    },
    yaxis: {
      show: false, // Hide the y-axis
      
    },
    legend: {
      show: false,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
    },
  });

  const [FreshersAndExperiencedSeries, setFreshersAndExperiencedSeries] = useState([
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ]);

  const [ExperiencedRAGOptions, setExperiencedRAGOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      id: "apexchart-example",
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      }
    },
    colors: ["#FF7575", "#FFBF00", "#4BD492"],

    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    grid: { show: false },
    xaxis: {
      // categories: ["Experienced"],
      axisBorder: { show: false },
      show: false,
      max: 100,
      labels: {
        show: false, // Hide y-axis scale labels
      },
      lines: {
        show: false, // Hide x-axis lines
      },
    },
    yaxis: {
      show: false, // Hide the y-axis
    },
    legend: {
      show: false,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
    },
  });

  const [ExperiencedRAgSeries, setExperiencedRAGSeries] = useState([
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ]);

  console.log("FreshersAndExperiencedSeries", FreshersAndExperiencedSeries);

  // batch processes endpoint
  // const batchProcessGetter = async () => {
  //     try {
  //         const res = await service.get(`/analysis/processByUg_id?user_group_id=${program_id}`);
  //         console.log("batch processes response:", res.getProcessAndSku);
  //         return res.getProcessAndSku;
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  // batch progress tracker endpoint

  // interview wise data endpoint
  const batchAnalyticsDataGetter = async (program_id) => {
    try {
      const res = await service.get(`/analytics/user/batchAnalysis?process_id=${program_id}`);
      console.log("analytics data response:", res.batchAnalysisData);

      return res.batchAnalysisData;
    } catch (err) {
      console.log(err);
    }
  };

  // Create the studentRatings object with updated series
  const [studentRatings, setStudentRatings] = useState({
    series: [],

    options: {
      chart: {
        type: "donut",
        toolbar: {
          show: false
        }
      },
      labels: ["5 Rating", "4 Rating", "3 Rating", "2 Rating", "1 Rating"],
      responsive: [
        {
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#1F8FA9", "#46C3E0", "#6CE5E8", "#AFE5F1", "#E8E8E8"],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
        fontSize: "12px",
        width: 200,
        offsetX: 130,
      },
      dataLabels: {
        style: {
          fontSize: "14px",
          colors: ["#1F8FA9"],
        },
        background: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            show: true,
            offset: 35,
          },
        },
      },
    },
  });
  console.log("datasss", Object.values(freshersRAGData), Object.values(experiencedRAGData));

  console.log("batch process data getter", batchProcessDataGetter);

  return (
    <div>
      {interviewWiseSkeletonLoader ? (
        <>
          <BatchTrackerSkeleton />
        </>
      ) : (
        <>
          {interviewWiseDataRendering ? (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", marginTop: "1.5rem" }}>
                  <Box sx={{ height: "2px", width: "100%", background: "#E88F09" }}></Box>
                  <Typography variant="h1" sx={{ color: "#E88F09", textAlign: "center", fontWeight: "600", width: "1000px", letterSpacing: "2.5px" }}>
                    {sku_name}
                  </Typography>
                  <Box sx={{ height: "2px", width: "100%", background: "#E88F09" }}></Box>
                </Box>
                <Box display="flex" justifyContent="space-around" height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)" }}>
                  <div>
                    <div className="studentCompletionData">
                      <AccountCircleIcon style={{ color: "#41B8D5", fontSize: "2rem" }} />
                      <Typography class="student_details_info numberOfHireReady">{studentDetails?.total - studentDetails?.absent}</Typography>
                      <CheckCircleIcon style={{ color: "#45A834", position: "absolute", marginRight: "1.4rem", marginTop: ".6rem" }} />
                    </div>
                    <Typography variant="h6">students completed this session</Typography>
                  </div>
                  <div>
                    <div className="studentCompletionData">
                      <AccountCircleIcon style={{ color: "#eee", fontSize: "2rem" }} />
                      <Typography class="student_details_info numberOfHireReady">{studentDetails?.absent}</Typography>
                      <BlockIcon style={{ color: "red", position: "absolute", marginRight: "1.4rem", marginTop: ".6rem" }} />
                    </div>
                    <Typography variant="h6">students were absent</Typography>
                  </div>
                  <div>
                    <div className="studentCompletionData">
                      <SpeakerNotesIcon style={{ color: "#41B8D5", fontSize: "2rem" }} />
                      <Typography class="student_details_info numberOfHireReady">{studentDetails?.student_feedback_given}</Typography>
                      <CheckCircleIcon style={{ color: "#45A834", position: "absolute", marginRight: "1.4rem", marginTop: ".6rem" }} />
                    </div>
                    <Typography variant="h6">students gave feedback to mentors</Typography>
                  </div>
                  <div>
                    <div className="studentCompletionData">
                      <SpeakerNotesOffIcon style={{ color: "#eee", fontSize: "2rem" }} />
                      <Typography style={{ marginTop: "auto", marginBottom: "auto" }} class="student_details_info numberOfHireReady">
                        {studentDetails?.total - studentDetails?.absent - studentDetails?.student_feedback_given}
                      </Typography>
                      <BlockIcon style={{ color: "red", position: "absolute", marginRight: "1.4rem", marginTop: ".6rem" }} />
                    </div>
                    <Typography variant="h6">students did not give feedback</Typography>
                  </div>
                </Box>
                <Box
                  height="auto"
                  // width="80%"
                  borderRadius={5}
                  sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)" }}
                >
                  <Typography variant="h4" sx={{ textAlign: "left", width: "800px", marginBottom: "1rem" }}>
                    RAG Report (Red-Amber-Green)
                  </Typography>
                  <div class="" style={{ display: "flex" }}>
                    <div className="flexingOfData" style={{ width: "50%", borderRight: "1ps solid #E88F09" }}>
                      <div className="flexingOfData" style={{ width: "30%", marginLeft: "2rem", textAlign: "left", marginRight: "2rem" }}>
                        <Box sx={{ paddingInline: "", display: "flex" }}>
                          <Box borderRadius={5} sx={{ background: "#F6F6F6", padding: "0rem .5rem .5rem .5rem", borderRadius: "10px" }}>
                            <Box sx={{ width: "100%", display: "flex", gap: "20px", alignItems: "center", marginBottom: ".5rem", marginTop: "1.3rem" }}>
                              <Box sx={{ border: "5px solid #FF3131", borderRadius: "50%", width: "50px", height: "50px", background: "radial-gradient( #F2E2E2,#FF3131)" }}></Box>
                            </Box>
                            <Box sx={{ width: "100%", display: "flex", gap: "20px", alignItems: "center", justifyContent: "center", marginBottom: ".5rem", marginTop: "1.3rem" }}>
                              <Box sx={{ border: "5px solid #E88F09", borderRadius: "50%", width: "50px", height: "50px", background: "radial-gradient( #F2E2E2,#E88F09)" }}></Box>
                            </Box>{" "}
                            <Box sx={{ width: "100%", display: "flex", gap: "20px", alignItems: "center", justifyContent: "flex-end", marginBottom: ".5rem", marginTop: "1.3rem" }}>
                              <Box sx={{ border: "5px solid #00BF63", borderRadius: "50%", width: "50px", height: "50px", background: "radial-gradient( #F2E2E2,#00BF63)" }}></Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box sx={{ width: "100%", gap: "20px", alignItems: "center", marginBottom: ".5rem", marginTop: "1.7rem", marginLeft: ".5rem" }}>
                              <Typography variant="h3" style={{ color: "#FF3131", fontWeight: "bold", letterSpacing: "2px" }}>
                                {DecimalToPercentage(RedAverageData) + "%"}
                              </Typography>
                              <Typography>of the batch</Typography>
                            </Box>
                            <Box sx={{ width: "100%", gap: "20px", alignItems: "center", marginBottom: ".5rem", marginTop: "1.7rem", marginLeft: ".5rem" }}>
                              <Typography variant="h3" style={{ color: "#E88F09", fontWeight: "bold", letterSpacing: "2px" }}>
                                {DecimalToPercentage(AmberAverageData) + "%"}
                              </Typography>
                              <Typography>of the batch</Typography>
                            </Box>
                            <Box sx={{ width: "100%", gap: "20px", alignItems: "center", marginBottom: ".5rem", marginTop: "1.7rem", marginLeft: ".5rem" }}>
                              <Typography variant="h3" style={{ color: "#00BF63", fontWeight: "bold", letterSpacing: "2px" }}>
                                {DecimalToPercentage(GreenAverageData) + "%"}
                              </Typography>
                              <Typography>of the batch</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </div>
                      <div style={{ width: "40%", textAlign: "left" }}>
                        <Typography style={{ textAlign: "left", fontSize: "11px" }}>
                          As per the RAG analysis, this batch is divided into red, amber, and green for this session. {DecimalToPercentage(RedAverageData) + "%"} of students have to work really hard on overall preparedness, {DecimalToPercentage(AmberAverageData) + "%"} of students are just around the corner and are advised to go through the mentor feedback thoroughly, and {DecimalToPercentage(GreenAverageData) + "%"} of students are on the right track.
                        </Typography>
                      </div>
                      <div style={{ borderRight: "2px solid #E88F09", height: "170px", marginLeft: "auto", marginRight: "auto", opacity: ".3" }}></div>
                    </div>
                    <div style={{ width: "50%" }}>
                      <Typography class="textLeftAlign" variant="body1" style={{marginBottom:"0px"}}>
                        Freshers Percentage Distribution
                      </Typography>
                      {/* <Box style={{ height: "90px" }}> */}
                        <Chart options={FreshersAndExperiencedOptions} series={FreshersAndExperiencedSeries} type="bar" height={85} width={"95%"} />
                        <Typography class="textLeftAlign" variant="body1" style={{marginBottom:"0px"}}>
                          Work-ex Percentage Distribution
                        </Typography>
                        <Chart options={ExperiencedRAGOptions} series={ExperiencedRAgSeries} type="bar" height={85} width={"95%"}/>
                      {/* </Box> */}
                    </div>
                  </div>
                  <Typography style={{ marginTop: "1rem" }} class="body1font2">
                    <span className="color-red">NOTE:</span> Assigning Red, Amber or Green flag to a student is strictly based on the analysis data of mentor reviews. It may not prove true caliber, ability and competence of a student or batch. It must be processed solely in the context of an activity and not on the basis of any other assumptions whatsoever. CareerCarve holds no responsibility for the actions taken by any party holding access privileges to these reports.{" "}
                  </Typography>
                </Box>
                <Box display="flex" gap="1.5rem" width="100%">
                  <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)", width: "70%" }}>
                    <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", width: "800px" }}>
                      Hireability Analysis: Freshers v/s Work-ex
                    </Typography>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Box class="interviewWiseCenterData">
                        <div className="flexingOfData">
                          <img src="https://ccicons.s3.amazonaws.com/analytics/Final_Grad.jpg" style={{ width: "60px", height: "60px" }} />
                          {/* <SchoolIcon style={{ fontSize: '3rem', color: "#41B8D5", border: "4px solid #41B8D5", borderRadius: "100%" }} /> */}
                          <div className="numberOfHireReady">
                            <Typography variant="h4" style={{ color: "#41B8D5", fontSize: "28px", fontWeight: "bold" }}>
                              {DecimalToPercentage(filteredBatchAnalyticsData[0]?.fresher_hireaility) + " %"}
                            </Typography>
                            <Typography style={{ color: "#41B8D5", fontSize: "10px" }}>of freshers were hire-ready. </Typography>
                          </div>
                        </div>
                        <div className="flexingOfData">
                          <img src="https://ccicons.s3.amazonaws.com/analytics/TIE.jpg" style={{ width: "60px", height: "60px" }} />
                          {/* <i class="fa-brands fa-black-tie fa-3x" style={{ color: "#41B8D5", border: "4px solid #41B8D5", borderRadius: "100%" }}></i> */}
                          <div className="numberOfHireReady">
                            <Typography variant="h4" style={{ color: "#41B8D5", fontSize: "28px", fontWeight: "bold" }}>
                              {DecimalToPercentage(filteredBatchAnalyticsData[0]?.exp_hireaility) + " %"}
                            </Typography>
                            <Typography style={{ color: "#41B8D5", fontSize: "10px" }}>of work-ex students were hire-ready.</Typography>
                          </div>
                        </div>
                      </Box>
                    </Box>
                    <Typography variant="body1" class="body1font2" style={{ marginTop: "2rem" }}>
                      During this activity, mentors were asked if they would have hired a particular student purely on the basis of their performance in this particular activity. According to this hireability data provided by mentors, students were seggreagated into freshers and experienced.{" "}
                    </Typography>
                  </Box>
                  <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)", width: "30%"}}>
                    <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400" }}>
                      Communication Meter
                    </Typography>
                    <ReactApexChart options={communicationData.options} series={communicationData.series} type="radialBar" />
                    <Typography class="body1font2" style={{ textAlign: "center", fontWeight: "400" }}>
                      of batch has NO communication problem
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" gap="1.5rem" width="100%">
                  <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)" , width: "70%"}}>
                    <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", width: "800px", marginBottom: "1rem" }}>
                      Hireability Analysis: Specialization-wise
                    </Typography>
                    <Chart options={hireabilityOptions} series={hireabilitySeries} type="bar" height={350} width={"94%"}/>
                    <Box class="interviewWiseCenterData" sx={{ marginTop: "2rem" }}>
                      <Box>
                        <div className="studentCompletionData">
                          <ThumbDownIcon style={{ color: "#8a8a8a" }} />
                          <Typography class="student_details_info numberOfHireReady" style={{ color: "#8a8a8a", fontWeight: "bold" }}>
                            {not_overall_hireability.toFixed(2) + " %"}
                          </Typography>
                        </div>
                        <Typography class="body1font2">
                          of batch was <span style={{ fontWeight: "bold" }}>NOT</span> hire-ready.{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <div className="studentCompletionData">
                          <ThumbUpIcon style={{ color: "#41B8D5" }} />
                          <Typography class="student_details_info numberOfHireReady" style={{ fontWeight: "bold" }}>
                            {(filteredBatchAnalyticsData[0]?.overall_hireaility * 100).toFixed(2) + " %"}
                          </Typography>
                        </div>
                        <Typography class="body1font2">of batch was hire-ready. </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box height="auto"  borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)", width: "30%" }}>
                  <Typography variant="h4" sx={{ textAlign: "left", fontWeight: "400", marginBottom: "1rem" }}>
                  Student Ratings for Mentors
                    </Typography>
                    <Box style={{ position: "absolute", marginTop: "6.8rem", marginLeft: "8.5rem" }}>
                      <Typography>Avg</Typography>
                      <Typography style={{ textAlign: "center", fontWeight: "bold" }}>{studentAverageData}</Typography>
                    </Box>
                    <Box style={{ marginTop: "3rem", position: "relative" }}>
                      <ReactApexChart options={studentRatings.options} series={studentRatings.series} type="donut" height={350} width={300} />
                    </Box>
                  </Box>
                </Box>
                <Box borderRadius={5} sx={{ display: "flex", flexDirection: "column", border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }}>
                  <Typography variant="h4" sx={{}}>
                    {not_overall_hireability.toFixed(2) + "%"} <span style={{ fontWeight: "bold" }}>NOT</span> Hire-ready Students: Breakdown
                  </Typography>
                  <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".5rem", marginBottom: "1rem" }}>
                    According to this data provided by mentors, the non hire-ready students were segregated into freshers and experienced on basis of specialisations they preferred for this activity.{" "}
                  </Typography>
                  <Chart options={hireabilityExperiencedOptions} series={hireabilityExperiencedSeries} type="bar" width={"95%"} height={320} />
                </Box>
                <Box borderRadius={5} sx={{ display: "flex", flexDirection: "column", border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }}>
                  <Typography variant="h4" sx={{}}>
                    {DecimalToPercentage(filteredBatchAnalyticsData[0]?.overall_hireaility) + "%"} Hire-ready Students: Breakdown
                  </Typography>
                  <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".5rem", marginBottom: "1rem" }}>
                    According to this data provided by mentors, hire-ready students were seggreagated into freshers and experienced on basis of the specialisations they preferred for this activity.{" "}
                  </Typography>
                  <Chart options={hireabilityFreshersOptions} series={hireabilityFreshersSeries} type="bar" width={"95%"} height={320} />
                </Box>
                <Box borderRadius={5} sx={{ display: "flex", flexDirection: "column", border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }}>
                  <Typography variant="h4">Role Preferences: {sku_name}</Typography>
                  <Typography class="body1font2" style={{ color: "#525252", fontStyle: "italic", marginTop: ".5rem", marginBottom: "1rem" }}>
                    Roles preferences displayed below are the top 10 roles that were most preferred by students while choosing roles for {sku_name}. Also, the hire-able students for each of these 10 roles are showcased.
                  </Typography>
                  <Chart options={rolePreferenceOptions} series={rolePreferenceSeries} type="bar" width={"95%"} height={320} />
                </Box>
                <Box height="auto" borderRadius={5} sx={{ p: 2, border: "1px solid rgba(255, 165, 0, 0.3)" }}>
                  <Typography variant="h4" sx={{ textAlign: "left", width: "800px" }}>
                    Mentor Ratings for Students
                  </Typography>
                  <Box class="interviewWiseCenterData" style={{ marginTop: "1.5rem" }}>
                    <div>
                      <Typography class="textCenterAlign" variant="h6">
                        Introduction
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "250px" }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }}>Specialization</TableCell>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }} align="right">
                                out of 5
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(introduction).map(([key, value]) => (
                              <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell style={{ fontSize: "10px" }} variant="h5" component="th" scope="row">
                                  {key}
                                </TableCell>
                                <TableCell style={{ fontSize: "10px" }} align="right">
                                  {value.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div>
                      <Typography class="textCenterAlign" variant="h6">
                        Work Experience
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "250px" }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }}>Specialization</TableCell>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }} align="right">
                                out of 5
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(work_experience).map(([key, value]) => (
                              <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell style={{ fontSize: "10px" }} component="th" scope="row">
                                  {key}
                                </TableCell>
                                <TableCell style={{ fontSize: "10px" }} align="right">
                                  {value === "NaN" ? "0.00" : value.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    <div>
                      <Typography class="textCenterAlign" variant="h6">
                      SIP/Major Practical Work Done
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "250px" }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }}>Specialization</TableCell>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }} align="right">
                                out of 5
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(resume_pointers).map(([key, value]) => (
                              <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell style={{ fontSize: "10px" }} component="th" scope="row">
                                  {key}
                                </TableCell>
                                <TableCell style={{ fontSize: "10px" }} align="right">
                                  {value.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div>
                      <Typography class="textCenterAlign" variant="h6">
                      JD Preparedness
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "250px" }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }}>Specialization</TableCell>
                              <TableCell style={{ color: "#8A8A8A", fontSize: "9px" }} align="right">
                                out of 5
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(concept_understanding).map(([key, value]) => (
                              <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell style={{ fontSize: "10px" }} component="th" scope="row">
                                  {key}
                                </TableCell>
                                <TableCell style={{ fontSize: "10px" }} align="right">
                                  {value.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Box>
                  <Box sx={{ marginTop: "2rem" }}>
                    <Box class="interviewWiseCenterData">
                      <Box>
                        {console.log("filteredBatchAnalyticsData[0]?.fresher_avg_rating", freshersRating, experiencedRating, batchAverageRating)}
                        <Typography>Freshers Average Score</Typography>
                        <Box textAlign="center">
                          <Rating sx={{ color: "#1C8EA8" }} className="feedbackRating" name="simple-controlled" value={freshersRating} disabled icon={<StarIcon sx={{ color: "#1C8EA8" }} />} emptyIcon={<StarBorderIcon sx={{ color: "#1C8EA8" }} />} />
                        </Box>
                        <Typography style={{ color: "#1C8EA8", fontWeight: "bold", textAlign: "center", fontSize: "16px" }}>{freshersRating?.toFixed(2)}/5</Typography>
                      </Box>
                      <Box>
                        <Typography>Work-ex Average Score</Typography>
                        <Box margin="0 auto" textAlign="center">
                          <Rating sx={{ color: "#1C8EA8" }} className="feedbackRating" name="simple-controlled" value={experiencedRating} disabled icon={<StarIcon sx={{ color: "#1C8EA8" }} />} emptyIcon={<StarBorderIcon sx={{ color: "#1C8EA8" }} />} />
                        </Box>
                        <Typography style={{ color: "#1C8EA8", fontWeight: "bold", textAlign: "center", fontSize: "16px" }}>{experiencedRating?.toFixed(2)}/5</Typography>
                      </Box>
                    </Box>
                    <div style={{ textAlign: "center" }}>
                      <Box margin="0 auto" width="100%" display="inline-block">
                        <Typography>Batch Average Score</Typography>
                        <Rating sx={{ color: "#FFBF00" }} className="feedbackRating" name="simple-controlled" value={batchAverageRating} disabled icon={<StarIcon sx={{ color: "#e88f09" }} />} emptyIcon={<StarBorderIcon sx={{ color: "#e88f09" }} />} />
                        <Typography style={{ color: "#e88f09", fontWeight: "bold", textAlign: "center", fontSize: "18px" }}>{batchAverageRating?.toFixed(2)}/5</Typography>
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <div></div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InterviewWiseAnalytics;
