// ***********************************//
// Considered As Projects
// ***********************************//
import React from "react";
function Achivements(props) {
  const projectData = [
    {
        "basic_info": {
            "tag": "Project basic info",
            "duration": "Year"
        },
        "sub_section_detail": {
            "sub_section_id": 452,
            "sub_section_order": 1,
            "is_on": 1
        },
        "bullets": [
            {
                "bullet_id": 792,
                "part": "Context",
                "bullet_order": 0,
                "bullet_content": "Project context"
            },
            {
                "bullet_id": 793,
                "part": "Action",
                "bullet_order": 0,
                "bullet_content": "Project Action and Result"
            }
        ]
    }
]
  console.log("projectData", projectData)
  let Details = [];
  // Details.push
  if ((projectData && projectData?.length > 0)) {
    projectData?.map((value, index) =>
      Details.push({
        Role: (
          <p id="resumeTable-p" style={{ textAlign: "initial", fontFamily: props.fontS, marginLeft: "7px",padding:"2.56px"}}>
            {value?.bullets?.filter((bullet) =>bullet?.part==="Context")[0]?.bullet_content}
          </p>
        ),
        Desc: (
          <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS }}>
            <ul className="resumeTable-ul"
              style={{
                marginLeft: "17px",
                listStyleType: props.Bullet ? "square" : "",
                lineHeight: props.LineHeight,
              }}
            >
              {
                projectData[index]?.bullets?.map((bullet) => {
                  if (bullet.part!=="Context"){
                    return bullet?.bullet_content?.length > 0 ?
                      <li style={{ listStyleType: bullet.bullet_content?.length > 0 ? "" : 'none', fontFamily: props.fontS, textAlign: "initial" }}>
                        {bullet?.bullet_content}
                      </li> : <></>
                    }
                })
              }
            </ul>
          </p>
        )
      }))
  }
  else {
    Details = [
      {
        Role: (
          <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS }}>
            {/* Academics */}
          </p>
        ),
        Desc: (
          <p id="resumeTable-p" style={{ textAlign: "justify" }}>
            <ul className="resumeTable-ul"
              style={{
                marginLeft: "17px",
                listStyleType: props.Bullet ? "square" : "",
                lineHeight: props.LineHeight,
                LineHeight: props.miniLheight,
              }}
            >
              <li style={{ fontFamily: props.fontS, listStyleType: "none" }}>
                {/* Awarded Merit Certificate for securing Rank 1 (Highest TGPA) in Term 3 for Academic Year (AY) 2020 - 21 */}
              </li>
              {/* <li style={{ fontFamily: props.fontS }}>
              Inducted to Director’s Merit List for Academic Performance (CGPA | Top 5 Percentile) in AY 2020 - 21
            </li> */}
            </ul>
          </p>
        ),
      },
    ];
  }
  return (
    <div>
      <table
        className="IntershipBox overlapping-table ResumeTable"
        style={{
          width: props.TableWidth ? "105%" : "100%",
          width: props.minwidth ? props.minwidth : props.TableWidth,
        }}
      // style={{ width: props.widths+"px" }}
      >
        <tr>
          <th className="Resumetable-th"
            colspan="6"
            id="Topic"
            // style={{
            //   backgroundColor: isActive ? "salmon" : "",
            //   color: isActive ? "white" : "",
            // }}
            style={{
              backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
              color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
              // fontSize: props.FontSmall,
              fontSize: props.FontSize,
              fontFamily: props.fontS,
              // border:props.styleAchivements?"none":"",
              textAlign: props.headerPosition,
              // borderTop: "none",
              border: "none",
              height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding
              // fontFamily: props.FontFamily ? "TimesNewRoman" : "Arial",
              // fontFamily: props.Arial,
            }}
          >
            {/* Achivements */}
            {props.name ? props.name : 'Achivements'}
          </th>
        </tr>

        {Details.map((val, key) => {
          return (
            <tr key={key} >
              <tr className="ResumeTable-td"
                style={{
                  // fontFamily: props.FontFamily ? "" : "Arial",
                  // fontFamily: props.Arial,
                  fontFamily: props.fontS,
                  width: "16%",
                  // fontSize: props.FontSmall,
                  fontSize: props.FontSize,
                  // border: "solid 0.25px #000",
                  // height: props.miniheight, 
                  fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                  maxWidth: props.minimaxWidth ? props.minimaxWidth : "",
                  fontWeight: "900"
                }}
              >
                {val.Role}
              </tr>
              <tr className="ResumeTable-td"
                colSpan={4}
                style={{
                  // fontSize: props.FontSmall,
                  fontSize: props.FontSize,
                  fontFamily: props.fontS,

                  height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding
                  // fontFamily: props.FontFamily ? "" : "Arial",
                  // fontFamily: props.Arial,
                }}
              >
                {val.Desc}
              </tr>
            </tr>
          );
        })}
      </table>
      {/* <button onClick={handleClick}>Color</button> */}
    </div>
  );
}

export default Achivements;
