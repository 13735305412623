import React from "react";
function ResponsibiltyFreeFlowing(props) {
  const certficationData = [
    {
        "basic_info": {
            "tag": "Certificate basic info"
        },
        "sub_section_detail": {
            "sub_section_id": 680,
            "sub_section_order": 1,
            "is_on": 1
        },
        "bullets": [
            {
                "bullet_id": 1232,
                "bullet_content": "Certificate Details",
                "achievement_year": "Year",
                "bullet_order": 1
            }
        ]
    }
]
  console.log("certficationData",certficationData);
  let Details=[]
  if ((certficationData && certficationData?.length > 0)) {
    certficationData?.map((value,index)=>
    Details.push({
      Role: (
        <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
            {value?.basic_info? value.basic_info.tag:""}
        </p>
    ),
    Desc:(
      <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                          {
                            certficationData[index]?.bullets?.map((bullet)=>{
                              return bullet.bullet_content?.length>0?
                              <li style={{ listStyleType: bullet.bullet_content?.length>0 ? "" : 'none', fontFamily: props.fontS }}>
                                {bullet?.bullet_content}{bullet.achievement_year ? ", " + bullet?.achievement_year : ""}
                              </li>:<></>
                            })
                          }
                        </ul>
      </p>
    )
    }))
  }else{
  Details = [
    {
      Desc: (
        <p id="resumeTable-p" style={{ textAlign: "justify" }}>
          <ul className="resumeTable-ul" style={{
            marginLeft: "12px",
            listStyleType: "none",
            lineHeight: props.LineHeight ? "1.2" : "",
            LineHeight: props.miniLheight
          }}>
            <li>
              {/* Secured 2nd Position out of 20 teams in BREAKTHROUGH case presentation event conducted as part of VRIDDHI 2019 by IIM Visakhapatnam, for proposing a digital strategy to improve the profitability of a Children’s magazine. */}
            </li>
            <li>
              {/* Formulated Monitoring Framework (2 x 2 Matrix) that grouped
              companies into different Quadrants based on ‘Time to/elapsed since
              Point-of-Convergence’ (X-Axis) and ‘Retention to Available Amount’
              (Y-Axis). */}
            </li>
          </ul>
        </p>
      ),
    },
  ];
}
  return (
    <div>
      <table className="ResumeTable"
        style={{ width: props.minwidth ? props.minwidth : props.TableWidth, }}
      >
        <tr>
          <th className="Resumetable-th" colspan="6" id="Topic"
            style={{
              backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
              color: props.Color ? "white" : "",
              fontSize: props.FontSize,
              fontFamily: props.fontS,
              border: "none",
              textAlign: props.headerPosition,
              height: props.miniheight, fontSize: props.miniFontSize, padding: props.minipadding
            }}>
            {props.name ? props.name : 'Responsibilty'}
          </th>
        </tr>
        {Details?.map((val, key) => {
          return (
            <tr key={key}>
              <td className="ResumeTable-td"
                style={{
                  fontFamily: props.fontS,
                  fontSize: props.FontSize,
                  height: props.miniheight, fontSize: props.miniFontSize, padding: props.minipadding
                }}
                colSpan={4}>
                {val.Desc}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default ResponsibiltyFreeFlowing;
