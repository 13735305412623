import axios from "axios";
import React, { useEffect, useState } from "react";
import Service from '../../../services/httpService';
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
//modal
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Modal from "react-bootstrap/Modal";

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import Loader from "../../reusables/Loader/Loader";
import { saveAs } from "file-saver";
import { Checkbox } from '@mui/material';
import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext';
import { antistringify as JsonParse } from '../../../functions/antistringify.js'
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';

const UserType = () => {
	const navigate = useNavigate();

	const [rowsData, setRowsData] = useState([{ company_name: "", role_id: "", batch_type_id: "", college_type_id: "" }])
	const [rowsData_mod_id, setRowsData_mod_id] = useState([])
	const [rowsData_mod_key, setRowsData_mod_key] = useState([])
	const [showData, setShowData] = useState([{ company_name: "", role_id: "", batch_type_id: "", college_type_id: "" }])
	const [jdData, setJDData] = useState([])
	//from table index
	const [from_table, setFrom_table] = useState();

	// let jdData = []d
	const [roleData, setRoleData] = useState([])
	const [batchData, setBatchData] = useState([])
	const [programData, setProgramData] = useState([])

	const [role, setRole] = useState()
	const [batch, setBatch] = useState()
	const [program, setProgram] = useState()

	const [jd_select_id, setJd_select_id] = useState();
	const [jd_batch_id, setJd_batch_id] = useState([]);
	const [jd_program_id, setJd_program_id] = useState([]);

	const [jd_input_name, setJd_input_name] = useState(jdData[from_table?.index]?.company_name);
	const [jd_select_name, setJd_select_name] = useState();
	const [jd_batch_name, setJd_batch_name] = useState([]);
	const [jd_program_name, setJd_program_name] = useState([]);
	const [active_flag, setActive_flag] = useState();

	//sending the batch_type_ids and program_type_ids in PUT
	const [batch_type_ids, setBatch_type_ids] = useState([]);
	const [program_type_ids, setProgram_type_ids] = useState([]);

	//get current date and time
	const currentDate = new Date();

	//modal hooks
	const [batch_select_data, setBatch_select_data] = useState([])
	const [program_select_data, setProgram_select_data] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [JDfiles, setJDFiles] = useState([]);
	const [JDfilesname, setJDFilesname] = useState();
	// const [count, setCount] = useState()
	const service = new Service()
	let count = 0;

	//activity
	const [activity, setActivity] = useState("");
	const [loading, setLoading] = useState(true);


	const headCells = [
		{
			id: 'heading1',
			numeric: 'right',
			disablePadding: false,
			label: 'Company Name',
		},
		{
			id: 'heading2',
			numeric: 'right',
			disablePadding: false,
			label: 'Role Types',
		}

	];

	const handleDeleteMul = (id, index) => {
		console.log("delete", id)
		let list_jd = [...jdData];
		let list_rowsData = [...rowsData];

		console.log("listtttt1_jd", list_jd);
		console.log("listtttt1_rowsData", list_rowsData);


		service
			.delete("/job_desc", {
				data: { "jd_ids": id }
			})
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list_jd = list_jd.filter((item) => id.indexOf(item.id) < 0);
				list_rowsData = list_rowsData.filter((item) => id.indexOf(item.id) < 0);
				setRowsData(list_rowsData)
				setJDData(list_jd)

				console.log("listtttt2", list_jd);
				console.log("listtttt2", list_rowsData);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);
			});
	}

	const data_getter = (name, id, index, act) => {
		// setRowsData(data)
		console.log("Dude here's your name:", name);
		console.log("Dude here's your id:", id);
		console.log("Dude here's your id:", index);
		setActivity(act);
		if (name === "delete_mul") {
			console.log("inside delete mul if")
			handleDeleteMul(id, index)
		}
		if (act === "add") {
			setJd_input_name()
			setBatch_select_data()
			setProgram_select_data()
			setJDFiles([])
			setJd_select_name()
			setJd_select_id()
		}

		// if (act === "delete") {
		// 	handleDelete(id, index)
		// }
		console.log("from_table",from_table);
		

		setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
	}

	const jdGetter = async () => {
		try {
			const res = await service.get('/job_desc')
			console.log("JD RESPONSE:", res.data)
			// let value = JsonParse(JSON.parse(JSON.stringify(res.data)))
			setJDData([...res.data])
			setRowsData_mod_id([...res.data])
			setRowsData_mod_key([...res.data])
			setRowsData([...res.data])
			return res.data
		} catch (err) {
			console.log(err)
		}
	}
	const roleGetter = async () => {
		try {
			const res = await service.get('/role?is_admin=1')
			console.log("Response from backend is11:", res.data)
			setRoleData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}
	const batchGetter = async () => {

		try {
			const res = await service.get('/batchType')
			console.log("Response from backend is11:", res.data)
			setBatchData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}
	const programGetter = async () => {

		try {
			const res = await service.get('/programType')
			console.log("Response from backend is:", res.data)
			setProgramData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	// get request
	useEffect(() => {
		console.log("Brother")
		const handleEffect = async () => {

			if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
				navigate('/');
			}
			//jd getter
			const jd = await jdGetter();
			//role getter 
			const role = await roleGetter();
			//batch getter 
			const batch = await batchGetter();
			//program getter 
			const program = await programGetter();





			// forming the data
			console.log("odonil", { jd }, { role }, { batch }, { program })
			data_manupulator_jd(jd, role, batch, program);
			data_manupulator_role(role);
			data_manupulator_batch(batch);
			data_manupulator_program(program);
			setLoading(false)
		}

		handleEffect();
	}, [])

	useEffect(() => {
		const setting_data = () => {

			if (from_table?.index === undefined) {
				console.log("Inside if of useEffect")
				setJd_input_name()
				setBatch_select_data()
				setProgram_select_data()
				setJDFiles([])
				setJd_select_name()
				setJd_select_id()
			}
			else {
				//setting values for Edit Modal
				console.log("Inside else of useEffect")
				console.log("Multiselect Data:", jdData[from_table?.index]?.program_type_id, "---------", jdData[from_table?.index]?.batch_type_id)
				console.log("from_table",from_table);
				
				setJd_input_name(jdData[from_table?.index]?.company_name)
				console.log({ jdData }, { roleData }, { batchData }, { programData })
				setJd_select_id(jdData[from_table?.index]?.role_id)
				setJd_program_id(jdData[from_table?.index]?.program_type_id)
				setJd_batch_id(jdData[from_table?.index]?.batch_type_id)
				// setJd_program_id(jdData[from_table?.index]?.program_type_ids)
				setJd_select_name(jdData[from_table?.index]?.role_id)
				// setActive_flag(jdData[from_table?.index]?.active_flag === 1 ? true : false)
				console.log("whattt", jdData[from_table?.index]?.role_id, jdData[from_table?.index]?.company_name);
				
				if (jdData[from_table?.index]?.file_url !== null)
					setJDFiles([jdData[from_table?.index]?.file_url])
				else
					setJDFiles([])

				//setProgram_select_data()
				// setBatch_select_data()
				clean_rowsData_mod_batch_type_id(rowsData_mod_id[from_table?.index] ? rowsData_mod_id[from_table?.index] : [], rowsData_mod_key[from_table?.index] ? rowsData_mod_key[from_table?.index] : [])
				clean_rowsData_mod_program_type_id(rowsData_mod_id[from_table?.index] ? rowsData_mod_id[from_table?.index] : [], rowsData_mod_key[from_table?.index] ? rowsData_mod_key[from_table?.index] : [])
			}

		}
		setting_data();
	}, [from_table])






	const data_manupulator_jd = (jd, role, batch, program, from) => {
		console.log("Jd data:", jd)
		console.log("From where", from)
		let temp_rowsData = JSON.parse(JSON.stringify(jd));
		let temp_role = JSON.parse(JSON.stringify(role));
		let temp_batch = JSON.parse(JSON.stringify(batch));
		let temp_program = JSON.parse(JSON.stringify(program));

		//show data - send to Table Component
		temp_rowsData.forEach((row) => {

			row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
			// row.batch_type_id = JSON.parse(row.batch_type_id).ids.map(data => temp_batch.map(bat => data === bat.id ? bat.name : "").filter(value => value !== ""))
			// row.program_type_id = JSON.parse(row.program_type_id).ids.map(data => temp_program.map(pro => data === pro.id ? pro.name : "").filter(value => value !== ""))
			delete row.batch_type_id
			delete row.program_type_id
			delete row.file_url
			delete row.file_version
			delete row.active_flag
			delete row.role_name

		});
		console.log("temp rows:", temp_rowsData)
		setRowsData(temp_rowsData)


		//id - for the batch type
		let temp_rowsData_mod_id = JSON.parse(JSON.stringify(jd));
		temp_rowsData_mod_id.forEach((row) => {

			row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
			row.batch_type_id = JSON.parse(row.batch_type_id).ids?.map(data => temp_batch?.map(bat => data === bat.id ? bat.id : "")?.filter(value => value !== ""))
			row.program_type_id = JSON.parse(row.program_type_id).ids?.map(data => temp_program?.map(pro => data === pro.id ? pro.id : "")?.filter(value => value !== ""))
			delete row.file_url
			delete row.file_version
			delete row.active_flag
			delete row.role_name

		});
		console.log("temp rows mod:", temp_rowsData_mod_id)
		setRowsData_mod_id(temp_rowsData_mod_id)




		//key - for the program type
		let temp_rowsData_mod_key = JSON.parse(JSON.stringify(jd));
		temp_rowsData_mod_key.forEach((row) => {

			row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
			row.batch_type_id = JSON.parse(row.batch_type_id).ids?.map(data => temp_batch?.map(bat => data === bat.id ? bat.name : "")?.filter(value => value !== ""))
			row.program_type_id = JSON.parse(row.program_type_id).ids?.map(data => temp_program?.map(pro => data === pro.id ? pro.name : "")?.filter(value => value !== ""))
			delete row.file_url
			delete row.file_version
			delete row.active_flag
			delete row.role_name

		});
		console.log("temp rows mod:", temp_rowsData_mod_key)
		setRowsData_mod_key(temp_rowsData_mod_key)

		//JSON parsing jdData
		let temp_jdData = JSON.parse(JSON.stringify(jd));
		temp_jdData.forEach((row) => {

			row.batch_type_id = JSON.parse(row.batch_type_id)?.ids
			row.program_type_id = JSON.parse(row.program_type_id)?.ids
			delete row.role_name

		});
		console.log("temp rows mod:", temp_jdData)
		setJDData(temp_jdData)




	}
	const data_manupulator_role = (roles) => {

		let temp = roles;
		temp = temp.map(roley => {
			delete roley.id_Specialization;
			return roley;
		});
		console.log("temp rows role:", temp)
		setRole(temp)
	}
	const data_manupulator_batch = (batch) => {

		let temp = batch;
		temp = temp.map(bat => {
			bat.key = bat.name;
			bat.id = bat.id;

			delete bat.name;
			return bat;
		});
		console.log("temp rows batch:", temp)
		setBatch(temp)
	}
	const data_manupulator_program = (program) => {

		let temp = program;
		temp = temp.map(pro => {
			pro.key = pro.name;
			pro.id = pro.id;

			delete pro.name;
			return pro;
		});
		console.log("temp rows program:", temp)
		setProgram(temp)
	}


	// const clean_rowsData_mod_role_id = (value1) => {
	//   let temp_role_id = [];
	//   Object.keys(value1).map((a, ind) => {
	//     if (a === "batch_type_id") {
	//       value1[a].map((x, i) => {
	//         console.log("Here: - x", x)
	//         console.log("Here: - i", i)
	//         b_t_i_id.push(x[0]);
	//       })
	//     }
	//   })
	//   console.log("batch type id array:", b_t_i_id)
	//   console.log("batch type id array:", b_t_i_key)
	//   console.log("Final array:", temp_final)
	//   setBatch_select_data(temp_final)

	// }
	const clean_rowsData_mod_batch_type_id = (value1, value2) => {
		let temp_value1 = JSON.parse(JSON.stringify(value1));
		let temp_value2 = JSON.parse(JSON.stringify(value2));
		let b_t_i_id = [];
		let b_t_i_key = [];
		console.log({ temp_value1 }, { temp_value2 })
		Object.keys(temp_value1).map((a, ind) => {
			if (a === "batch_type_id") {
				temp_value1[a].map((x, i) => {
					console.log("Here: - x", x)
					console.log("Here: - i", i)
					b_t_i_id.push(x[0]);
				})
			}
		})
		Object.keys(temp_value2).map((a, ind) => {
			if (a === "batch_type_id") {
				temp_value2[a].map((x, i) => {
					console.log("Here: - x", x)
					console.log("Here: - i", i)
					b_t_i_key.push(x[0]);
				})
			}
		})
		console.log("batch type id array:-id", b_t_i_id)
		setBatch_type_ids(b_t_i_id);
		console.log("batch type id array:-key", b_t_i_key)
		let temp_final = []
		b_t_i_id.map((a, b) => {
			temp_final.push({
				id: a,
				key: b_t_i_key[b]
			})
		})
		console.log("Final array:", temp_final)
		setBatch_select_data(temp_final)

	}
	const clean_rowsData_mod_program_type_id = (value1, value2) => {
		console.log({ value1 }, { value2 })
		let temp_value1 = JSON.parse(JSON.stringify(value1));
		let temp_value2 = JSON.parse(JSON.stringify(value2));
		let b_t_i_id = [];
		let b_t_i_key = [];
		Object.keys(temp_value2).map((a, ind) => {
			if (a === "program_type_id") {
				temp_value1[a].map((x, i) => {
					console.log("Here: - x", x)
					console.log("Here: - i", i)
					b_t_i_id.push(x[0]);
				})
			}
		})
		Object.keys(temp_value2).map((a, ind) => {
			if (a === "program_type_id") {
				temp_value2[a].map((x, i) => {
					console.log("Here: - x", x)
					console.log("Here: - i", i)
					b_t_i_key.push(x[0]);
				})
			}
		})
		console.log("program type id array:-id", b_t_i_id)
		setProgram_type_ids(b_t_i_id);
		console.log("program type id array:-key", b_t_i_key)
		let temp_final = []
		b_t_i_id.map((a, b) => {
			temp_final.push({
				id: a,
				key: b_t_i_key[b]
			})
		})
		console.log("Final array:", temp_final)
		setProgram_select_data(temp_final)

	}

	//MODAL
	const Modal_JD = (show, setShow, option) => {
		console.log("hhhhhhhhhh")
		console.log("show value in modal:", show)
		console.log("setShow value in modal:", setShow)
		console.log("aaaaaaaaaaaaaaaaaaaaaaa-batch", batch)
		console.log("aaaaaaaaaaaaaaaaaaaaaaa-roles", jd_select_id)
		console.log("aaaaaaaaaaaaaaaaaaaaaaa-program", program)
		//variables
		// const fileInputRef = useRef(null);

		// function handleCloudClick() {
		//   fileInputRef.current.click();
		// }

		function handleFileInputChange(event) {
			const file = event.target.JDfiles[0];
			console.log(file);
		}

		const updating_rowsData_mod_id = (temp_jdData) => {
			//id - for the batch type
			console.log(temp_jdData)
			let temp_rowsData_mod_id = JSON.parse(JSON.stringify(temp_jdData));
			let temp_role = JSON.parse(JSON.stringify(roleData));
			let temp_batch = JSON.parse(JSON.stringify(batchData));
			let temp_program = JSON.parse(JSON.stringify(programData));
			temp_rowsData_mod_id.forEach((row) => {

				row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
				row.batch_type_id = (row.batch_type_id)?.map(data => temp_batch?.map(bat => data === bat.id ? bat.id : "")?.filter(value => value !== ""))
				row.program_type_id = (row.program_type_id)?.map(data => temp_program?.map(pro => data === pro.id ? pro.id : "")?.filter(value => value !== ""))
				delete row.file_url
				delete row.file_version
				delete row.active_flag

			});
			console.log("temp rows mod:", temp_rowsData_mod_id)
			setRowsData_mod_id(temp_rowsData_mod_id)
			return temp_rowsData_mod_id
		}
		const updating_rowsData_mod_key = (temp_jdData) => {
			console.log(temp_jdData)

			//key - for the program type
			let temp_rowsData_mod_key = JSON.parse(JSON.stringify(temp_jdData));
			console.log("UserType ~ temp_rowsData_mod_key", temp_rowsData_mod_key)
			let temp_role = JSON.parse(JSON.stringify(roleData));
			let temp_batch = JSON.parse(JSON.stringify(batchData));
			let temp_program = JSON.parse(JSON.stringify(programData));
			console.log({ temp_program },)
			temp_rowsData_mod_key.forEach((row) => {

				row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
				row.batch_type_id = row.batch_type_id?.map(data => temp_batch?.map(bat => data === bat.id ? bat.key : "")?.filter(value => value !== ""))
				row.program_type_id = row.program_type_id?.map(data => temp_program?.map(pro => data === pro.id ? pro.key : "")?.filter(value => value !== ""))
				delete row.file_url
				delete row.file_version
				delete row.active_flag

			});
			console.log("temp rows mod:", temp_rowsData_mod_key)
			setRowsData_mod_key(temp_rowsData_mod_key)
			return temp_rowsData_mod_key
		}

		const modalSubmit = async () => {
			console.log("Just submitted:", jd_input_name, jd_select_id, jd_batch_id, jd_program_id)
			let temp_jdData = []
			if (activity === "add")
				temp_jdData = await handleFormSubmission(jd_input_name, jd_select_id, jd_batch_id, jd_program_id);

			else
				temp_jdData = await handlePutRequest();
			console.log({ temp_jdData })
			let temp_id = updating_rowsData_mod_id(temp_jdData)
			let temp_key = updating_rowsData_mod_key(temp_jdData)
			console.log({ temp_id }, { temp_key })
			clean_rowsData_mod_batch_type_id(temp_id[from_table?.index] ? temp_id[from_table?.index] : [], temp_key[from_table?.index] ? temp_key[from_table?.index] : [])
			clean_rowsData_mod_program_type_id(temp_id[from_table?.index] ? temp_id[from_table?.index] : [], temp_key[from_table?.index] ? temp_key[from_table?.index] : [])

			setShow(false)
		}



		const handleFileInput = (event) => {
			const f = event.target.files;
			let filesArray = [...JDfiles];
			for (let i = 0; i < f.length; i++) {
				filesArray.push(f[i]);
			}
			console.log({ filesArray })
			setJDFiles(filesArray);
			setJDFilesname(filesArray[0].name)
		};

		const handleRemoveFile = (fileIndex) => {
			console.log("Removed id:", rowsData[from_table?.index]?.id)
			console.log("JD files inside remove file:", Object.values(JDfiles)[0].name)
			if (Object.values(JDfiles)[0]?.name === undefined) {
				console.log("Inside if ~ delete jd file")
				try {
					let res = service.delete("/job_desc/file",
						{
							data: {
								jd_id: rowsData[from_table?.index]?.id
							}
						})

					// console.log("Response from backend is", res.data);
					console.log("Response from backend is", JSON.stringify(res, null, 2));
					// res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
					const filesArray = [...JDfiles];
					filesArray.splice(fileIndex, 1);
					setJDFiles(filesArray);

				} catch (err) {
					console.log("Errog Msg: ", err);

				}
			}
			else {
				console.log("Inside else ~ delete jd file")
				const filesArray = [...JDfiles];
				filesArray.splice(fileIndex, 1);
				setJDFiles(filesArray);
			}

		};

		// function handleCloudClick() {
		//   fileInputRef.current.click();
		// }

		function handleFileInputChange(event) {
			const file = event.target.JDfiles[0];
			console.log(file);
		}



		const onSelect_batch = (e) => {
			console.log("Added an item to multi select - batch -", e)
			setBatch_select_data(e);
			let temp = JSON.parse(JSON.stringify(e));
			temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
			//   data.id = data.id;

			//   return data;
			// });
			setJd_batch_id(temp)
			console.log("temp inside onSelect:", temp)
		}
		const onSelect_program = (e) => {
			console.log("Added an item to multi select - program -", e)
			setProgram_select_data(e);

			let temp_key = JSON.parse(JSON.stringify(e));
			let temp_id = JSON.parse(JSON.stringify(e));
			temp_id = temp_id.filter(data => typeof data.id === "number").map(obj => obj.id)
			temp_key = temp_key.filter(data => typeof data.key !== "number").map(obj => obj.key)
			console.log("temp inside onSelect:", temp_id)
			console.log("temp inside onSelect:", temp_key)
			setJd_program_id(temp_id)
			setJd_program_name(temp_key)
		}

		const onRemove_batch = (e) => {
			console.log("Removed an item to multi select")
			setBatch_select_data(e);
			let temp = e;
			temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
			//   data.id = data.id;

			//   return data;
			// });
			setJd_batch_id(temp)
			console.log("temp inside onSelect:", temp)

		}
		const onRemove_program = (e) => {
			console.log("Removed an item to multi select")
			setProgram_select_data(e);
			let temp_key = e;
			let temp_id = e;
			temp_id = temp_id.filter(data => typeof data.id === "number").map(obj => obj.id)
			temp_key = temp_key.filter(data => typeof data.key !== "number").map(obj => obj.key)
			console.log("temp inside onSelect:", temp_id)
			console.log("temp inside onSelect:", temp_key)
			setJd_program_id(temp_id)
			setJd_program_name(temp_key)
		}
		const onHide = () => {
			setShow(false)
			setJd_input_name()
			setBatch_select_data()
			setProgram_select_data()
			setJd_select_id("Select role type")
			setJd_select_name("Select role type")
		}


		const handleChange = (e, index) => {

			//Logs for entering values
			// console.log("Name is: ", name);
			console.log("name from form  is:", e.target.name);
			console.log("value from form  is:", e.target.value);

			//Conditions for storing values
			if (e.target.name === "jd-input") {
				setJd_input_name(e.target.value)
				console.log("add-specialization-input")
			} else if (e.target.name === "jd-select") {
				setJd_select_id(e.target.value)
				console.log("add-specialization-select")

			}
			else if (e.target.name === "active_flag") {
				// if (e.target.value === "on")
				// 	setActive_flag(1)
				// else
				setActive_flag(!active_flag)
				console.log(" value:", e.target.value)
				console.log(" value:", active_flag)

			}
			// else if (e.target.name === "jd-input") {
			//   setName(e.target.value)
			//   console.log("add-normal-input")
			// }
		}


		//render
		return (
			<Modal
				show={show}
				onHide={() => onHide()}
				className="FileUploadModal"
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
			>
				<Modal.Body style={{
					position: 'absolute',
					left: '60%',
					height: 'auto',
					transform: 'translate(-50%, -50%)',
					lineHeight: '1.4',
					padding: '14px 28px',
					backgroundColor: '#fff',
					borderRadius: '10px',
					maxWidth: '95%',
					minWidth: '1000px',
					minHeight: '500px'
				}}>
					<form className="Entire-form" style={{ display: "flex", flexDirection: "columns", justifyContent: 'center' }}>

						<div className='right-side-buttons-jd row'>
							<div className="headerComp" >
								<CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
								<p className="compTitle">Job Description</p>
							</div>
							<div className="form-group">
								<label className="inputLabel">JD Name<span>*</span></label>
								<input name="jd-input" disabled={option === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={jd_input_name} onChange={(e) => handleChange(e)} />
							</div>
							<div className="form-group ">
								<label className="inputLabel">Role Types<span>*</span></label>
								{/* <input type="text" className="form-control inputFields" placeholder="Example input" /> */}
								<select disabled={option === "View" ? true : false} name="jd-select" id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={jd_select_id}>
									<option value={""} selected>{jd_select_id ? jd_select_name : jd_select_id}</option>
									{role?.map((rol, i) => (
										<option key={i} value={rol.id}>{rol.name}</option>
									))}
								</select>
							</div>
							<div className="form-group ">
								<label className="inputLabel">Batch Types<span>*</span></label>
								<Multiselect
									disable={option === "View" ? true : false}
									displayValue="key"
									selectedValues={batch_select_data}
									onRemove={(e) => onRemove_batch(e)}
									onSelect={(e) => { onSelect_batch(e) }}
									options={batch}
								/>
							</div>
							<div className="form-group ">
								<label className="inputLabel">Program Types<span>*</span></label>
								<Multiselect
									disable={option === "View" ? true : false}
									displayValue="key"
									selectedValues={program_select_data}
									onRemove={(e) => onRemove_program(e)}
									onSelect={(e) => { onSelect_program(e) }}
									options={program}
								/>
								{option !== "View" ? <div className=" btn-comp " style={{ marginTop: "4%" }}>
									<button Disabled={option === "View" ? true : false} type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
								</div> : <></>}
							</div>
						</div>


						<div className="right-side-buttons-jd row" sx={{ justifyContent: 'center' }}>
							{console.log("ZALOLITH", Object.values(JDfiles)[0])}
							<p className="compTitlejd">File Upload</p>
							{console.log({ JDfiles })}

							{JDfiles.length === 0 ?
								<div className="cloud-compjd" sx={{ paddingTop: '10%' }}>
									<CloudUploadOutlinedIcon style={{ paddingTop: '3%', width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
									<label style={{ border: 'none' }} className="btn-image1" id="btn-image1" for="files" class="btn">File Upload</label>
									{option !== "View" ? <input className="form-group " id="files" type="file" accept=".pdf" name="file" multiple onChange={(e) => handleFileInput(e)} style={{ marginTop: '0', visibility: 'hidden' }} /> : <></>}

									<div style={{ marginInline: '42%', marginBottom: "5%", marginTop: '-10%' }} class="image-upload">
										<label for={"Mentorfiles"}>
											{option !== "View" ? (<AddIcon className="add-icon" style={{ background: 'white', borderRadius: '100%', height: '35px', width: '35px', zIndex: '100' }} />) : <></>}
										</label>
										{/* <AddIcon style={{ background: 'white', borderRadius: '100%', height: '35px', width: '35px', zIndex: '100' }} onChange={(e) => { handleFileInput(e, fileType, index) }} /> */}
										{option !== "View" ? <input className="form-group " id={"Mentorfiles"} type="file" accept=".pdf" name="file" onChange={(e) => { handleFileInput(e) }} style={{ position: 'relative', marginTop: '1rem', height: '35px', width: "35px", border: '1px solid red', borderRadius: "100px", background: 'transparent' }} ></input> : <></>}
									</div>


									{JDfiles?.map((file, index_file) => (

										<div key={file?.name} style={{ display: 'flex' }}>
											<p style={{ marginRight: "%" }}>{file?.name ? file.name : file}</p>
											<DeleteIcon onClick={() => handleRemoveFile(index_file)}>Delete</DeleteIcon>
										</div>
									))}

								</div>
								:

								<div style={{ paddingTop: '10%' }}>
									{JDfiles?.map((file, index_file) => (
										<div key={index_file} style={{ borderRadius: "5px", padding: "10px", alignItems: "center", border: "1px solid black", width: "100%", height: "100px", justifyContent: "center", display: 'flex' }}>

											{/* <div style={{ alignItems: "center", display: "flex", padding: "10px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "100%", height: "50%" }}> */}
											{/* <p>{file ? file?.split("/")?.at(-1).toString() : }</p> */}
											{/* <p style={{ width: "85%", }}>{files[0]}</p> */}
											{JDfiles?.map((file, index_file) => (
												<div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "100%", height: "70px", marginTop: '2%', marginBottom: '2%' }}>

													{/* <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', marginLeft: '2%', fontSize: '12px', justifyContent: 'flex-start' }}>{Object.values(file).length !== 0 ? Object.values(file) : file?.name}</p> */}

													<p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', marginLeft: '2%', fontSize: '12px', justifyContent: 'flex-start' }}>{JDfilesname ?? file.split("/").at(-1)}</p>
													<DeleteIcon className="deleteIcon" sx={{ marginLeft: "20px" }} onClick={(e) => handleRemoveFile(e)}></DeleteIcon>


													{console.log("1111----")}
												</div>
											))}

											{/* </div> */}
											{/* <div style={{ height: "20%" }}></div> */}

											{/* <div style={{ height: "20%" }}></div> */}
										</div>
									))}
								</div>
							}


							{/* <div style={{
								display: "flex", border: "2px solid black", borderRadius: "10px", padding: "5px", width: "auto", height: "auto"
							}}>
								<p style={{ padding: "10px" }
								} > Active flag:</p>
								<Checkbox
									name="active_flag"
									checked={active_flag}
									onChange={(e) => handleChange(e)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</div> */}
						</div>
					</form>
				</Modal.Body >
			</Modal >

		)
	}

	const headers = {
		Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
	};

	// post request
	const handleFormSubmission = async (company_name, role_id, batch_type_ids, program_type_ids) => {
		let temp_rows = [...rowsData];
		let temp_jdData = JSON.parse(JSON.stringify(jdData));

		let data = {
			company_name: company_name,
			role_id: role_id,
			batch_type_ids: batch_type_ids,
			program_type_ids: program_type_ids,
			// file_url: file_url
		}

		console.log("User TYpe id not found, sending teh post request", temp_rows);
		try {
			let res = await service.post("/job_desc", data)

			console.log("Response from backend is", JSON.stringify(res, null, 2));
			console.log(res)
			// setRowsData([{ id: res.data.jd_id, company_name: company_name, role_id: roleData }, ...rowsData])
			// let temp = [...jdData]
			// temp.id = res.data.jd_id
			// temp.company_name = company_name
			// temp.role_id = role_id
			// temp.batch_type_id = batch_type_ids
			// temp.program_type_id = program_type_ids
			// jdData = temp
			// data_manupulator_jd_rerender_post(res.data.jd_id, company_name, role_id)


			let temp = JSON.parse(JSON.stringify(rowsData));
			let temp_role = JSON.parse(JSON.stringify(roleData));
			console.log({ temp_role })
			console.log(jd_select_id)
			console.log(temp_role.map(data => data.id))
			console.log(temp_role.map(data => data.id == jd_select_id.toString()))
			console.log(temp_role.map(data => data.id == jd_select_id.toString()))
			console.log("bread_and_butter:", temp_role.map(data => data.id == jd_select_id.toString() ? data.name : "").filter(value => value !== "") === undefined ? "No data" : "Bruh data")
			temp = ([{
				id: res?.data?.jd_id,
				company_name: company_name,
				role_id: temp_role.map(data => data.id == jd_select_id.toString() ? data.name : "")?.filter(value => value !== "") || null,
				// file_url: JDfiles[0]?.name || null
			}, ...temp])
			setRowsData(temp)


			temp_jdData = ([{
				id: res?.data?.jd_id,
				company_name: company_name,
				role_id: parseInt(jd_select_id),
				batch_type_id: jd_batch_id,
				program_type_id: jd_program_id,
				file_url: JDfiles[0]?.name || null,
				active_flag: jdData[from_table?.index]?.active_flag
			}, ...temp_jdData])

			setJDData(temp_jdData)
			console.log(".then ~ temp_jdData:", temp_jdData)
			// data_manupulator_jd(temp_jdData, roleData, batchData, programData, "from post");

			if (JDfiles[0] !== null)
				handleFilePostRequest(res.data.jd_id, JDfiles)
			// console.log("response id is:", temp);



		} catch (err) {
			console.log("Errog Msg: ", err);
			// console.log(err.response.data.message)
			window.alert(err.response.data.message)
		}
		return temp_jdData




	}

	const handlePutRequest = async () => {
		//get request here
		let temp_jdData = JSON.parse(JSON.stringify(jdData));

		try {
			const res = service.put("/job_desc", {
				jd_id: jdData[from_table?.index].id, company_name: jdData[from_table?.index].company_name, role_id: jdData[from_table?.index].role_id,
				batch_type_ids: jd_batch_id, program_type_ids: jd_program_id
			})
			// console.log("Response from backend is", res.data);
			console.log("Response from backend is", JSON.stringify(res, null, 2));



			let temp = JSON.parse(JSON.stringify(rowsData));
			let temp_role = JSON.parse(JSON.stringify(roleData));
			console.log({ temp_role })
			console.log(jd_select_id)

			temp[from_table?.index].company_name = rowsData[from_table?.index].company_name
			temp[from_table?.index].role_id = temp_role.map(data => data.id == jd_select_id.toString() ? data.name : "").filter(value => value !== "")
			// temp[from_table?.index].file_url = JDfiles[0]?.name || null

			console.log("Matey", temp)
			setRowsData(temp)

			// console.log
			temp_jdData[from_table?.index].company_name = rowsData[from_table?.index].company_name
			temp_jdData[from_table?.index].role_id = parseInt(jd_select_id)
			temp_jdData[from_table?.index].batch_type_id = jd_batch_id
			temp_jdData[from_table?.index].program_type_id = jd_program_id
			temp_jdData[from_table?.index].file_url = JDfiles[0]?.name ?? JDfiles[0]
			console.log(temp_jdData)
			setJDData(temp_jdData)

			// data_manupulator_jd(temp_jdData, roleData, batchData, programData, "from put")
			// clean_rowsData_mod_batch_type_id(rowsData_mod_id[from_table?.index] ? rowsData_mod_id[from_table?.index] : [], rowsData_mod_key[from_table?.index] ? rowsData_mod_key[from_table?.index] : [])
			// clean_rowsData_mod_program_type_id(rowsData_mod_id[from_table?.index] ? rowsData_mod_id[from_table?.index] : [], rowsData_mod_key[from_table?.index] ? rowsData_mod_key[from_table?.index] : [])
			if (JDfiles[0] !== null)
				handleFilePostRequest(rowsData[from_table?.index]?.id, JDfiles)
			// data_manupulator_jd_rerender_put(jd_select_id, rowsData[from_table?.index].company_name)
		} catch (err) {
			console.log("Errog Msg: ", err);
			window.alert(err.response.data.message)
		};
		return temp_jdData
	}

	// delete request
	const handleDelete = async (jd_id, index) => {
		console.log("delete", jd_id)
		let list = [...rowsData];

		console.log("listtttt1", list);
		service
			.delete("/job_desc", {
				data: { "jd_id": jd_id }
			})
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list.splice(index, 1);
				setRowsData(list)
				setJDData(list)
				console.log("listtttt2", list);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);

				console.log(err.response.data.message)
				window.alert(err.response.data.message)

			});
	}


	//PDF POST
	const handleFilePostRequest = async (id) => {
		console.log("Inside File post: ", JDfiles)
		for (var i = 0; i < JDfiles.length; i++) {
			const pdfFile = new Blob([JDfiles[i]], { type: 'application/pdf' });
			const data = new FormData();
			// const name = "mentor_"+`${i}`;
			data.append('jd_pdf', pdfFile, {
				filepath: pdfFile,
				contentType: 'multipart/form-data',
			});
			console.log("file data", id, JDfiles, data);
			const headers = {
				"Content-Type": "multipart/form-data"

			}
			await axios.post(`${process.env.REACT_APP_API_URL}/superAdmin/job_desc/file?jd_id=${id}`, data, { headers })
				.then((res) => {
					// console.log("Response from backend is", JSON.stringify(res, null, 2));
					console.log("asdfghj", JDfiles[0])
					console.log("response", res);
					res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
					return (res.data.success)
				})
				.catch((err) => {
					console.log("Errog Msg: ", err);
					window.alert(err.response.data.message)
					if (err.response.status === 400) {
						console.log("400 Error message")
					}
					return (false)
				});
		}

	}

	const tempFunction = (data) => {
		console.log(data);
	}

	const fileDownload = () => {
		console.log("Downloading the file");
		console.log("Rows data now is:  ", rowsData);
		// Simulate a button click after 2 seconds
		const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
		saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


	}





	return (<div >
		<RowsContext.Provider value={rowsData}>
			<TopBar />
			<SideNavigationBar />
			{loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
				{console.log("from_table.index", from_table?.index)}
				{console.log({ program_select_data })}
				{console.log({ batch_select_data })}
				{console.log({ jd_batch_id })}
				{console.log({ jd_select_id })}
				{console.log({ rowsData_mod_id })}
				{console.log({ rowsData_mod_key })}
				{console.log({ jdData }, { rowsData })}
				{console.log({ roleData })}
				{console.log({ JDfiles })}
				{console.log({ from_table })}
				{console.log(jdData[from_table?.index]?.active_flag)}
				<EnhancedTable mainFile_modal={Modal_JD} data_getter={data_getter} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} />
			</>}

		</RowsContext.Provider>

	</div>
	)
}
// value={{rowsData, handleFormSubmission : (e,user_type_name) => handleFormSubmission(e,user_type_name)}}

export default UserType