import React, { useState, useEffect, useRef } from "react";
import { Box, Checkbox, Typography, Button, TextField, Modal, IconButton, InputAdornment } from "@mui/material";
import { Color, DivCenter } from "../../GlobalStyles";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Service from "../../services/httpService";
import io from "socket.io-client";
import CCService from '../../services/httpServiceWithoutSuperAdmin'
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import axios from "axios";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { get_specialCharReplace, convertEpochToNormalTime } from "../../services/specialCharReplace";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useNavigate } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";

const GroupChat = (props) => {
  const socketRef = useRef();
  const services = new Service();
  const service = new CCService();
  const chatDivRef = useRef();
  const navigate = useNavigate();
  const { process_id } = props
  console.log("props job id", process_id);
  const [participantsList, setParticipantsList] = useState([]);
  const [allowMessages, setAllowMessages] = useState(true);
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    userType: "",
    user_id: null,
  });
  const [selectedImage, setSelectedImage] = useState({});
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState();
  const [imageName, setImageName] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const randomColors = [Color.green, Color.red, Color.blueBlack, Color.accent];
  const randomColor = randomColors[Math.floor(Math.random() * randomColors.length)];
  const [closeDialog, setCloseDialog] = useState(false);

  useEffect(() => {
    socketRef.current = io(services.socket_url, {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      transports: ["websocket"],
    });
    handleEffect();
  }, []);

  const handleEffect = async () => {
    const preUser = await service.get("/user/userDetail");
    console.log("preUser", preUser);
    const temp_formInputs = { ...formInputs };
    temp_formInputs.first_name = preUser?.userDetail.first_name;
    temp_formInputs.user_id = preUser?.userDetail.user_id;
    if (["undefined", "null", undefined, null].includes(temp_formInputs.first_name)) {
      temp_formInputs.first_name = "";
    }

    temp_formInputs.last_name = preUser?.userDetail.last_name;
    if (["undefined", "null", undefined, null].includes(temp_formInputs.last_name)) {
      temp_formInputs.last_name = "";
    }
    temp_formInputs.userType = preUser?.user_type;
    console.log("handleEffect ~ temp_formInputs:", temp_formInputs);
    setFormInputs(temp_formInputs);
    setUserId(temp_formInputs.user_id);
    if ((process_id) !== null) {
      console.log("in here job id", "process_id:", process_id, "req_raised_by:", temp_formInputs.user_id);
      socketRef.current.emit("processFindRoom", { process_id: process_id, req_raised_by: temp_formInputs.user_id }, (error, response) => {
        if (error) {
          console.error("errorrrrr", error);
        } else {
          console.log("responseeee", response);
        }
      });
      console.log("Socket connected");
      socketRef.current.on("processFoundRoom", (chatMessages) => {
        console.log("ANIDJBFJ", chatMessages);
        let parsedChat = [];
        if (chatMessages) {
          try {
            parsedChat = JSON.parse(chatMessages?.messages) || [];
          } catch (error) {
            console.error("Failed to parse chatMessages.chat", error);
          }
        }

        setMessages(parsedChat);

        // setParticipantsList(chatMessages.member);
      });
      console.log("error 1:");
    }
  };

  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
    console.log("messages", messages);

    if (Array.isArray(messages)) {
      messages.forEach((message) => {
        let messageDate = new Date(message.time);
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (messageDate.toLocaleDateString() === today.toLocaleDateString()) {
          messageDate = "Today";
        } else if (messageDate.toLocaleDateString() === yesterday.toLocaleDateString()) {
          messageDate = "Yesterday";
        } else {
          messageDate = messageDate.toLocaleDateString();
        }
        if (!groupedMessages[messageDate]) {
          groupedMessages[messageDate] = [];
        }
        groupedMessages[messageDate].push(message);
      });
    }
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDate(messages);

  const handleChangeInputField = (e, index) => {
    console.log("name from form  is:", e.target.name);
    console.log("value from form  is:", e.target.value);
    console.log("something inside", e.target.name);
    if (e.target.name === "chat-input") {
      setMessage(e.target.value);
      console.log("add-specialization-input");
    }
  };

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    console.log("in useeffect:");
    socketRef.current.on("processRoomMessage", (chatMessages) => {
      console.log("messages in processRoomMessage :", chatMessages);
      scrollToBottom();
      let parsedChat = [];
      if (chatMessages) {
        try {
          parsedChat = JSON.parse(chatMessages) || [];
          let updatedMessages = [...messages, parsedChat];
          setMessages(updatedMessages);
        } catch (error) {
          console.error("Failed to parse chatMessages.chat", error);
        }
      }

    });
  }, [messages]);


  function ParticipantsModal({ open, onClose, participants }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "1.5rem 2rem",
      borderRadius: "20px",
      width: "50%",
      height: "500px",
      overflowY: "auto",
      minHeight: "500px",
    };
    const [searchParticipants, setSearchedParticipants] = useState("");
    const [filteredParticipants, setFilterdParticipants] = useState([...participants]);
    const handleSearchParticipants = ({ target }) => {
      const { value } = target;
      console.log("handleSearchParticipants ~ value:", value);
      setSearchedParticipants(value);
      let filteredList = participants.filter(
        (item) => item.name.toLowerCase().includes(value.toLowerCase()) || item.name.toUpperCase().includes(value.toUpperCase()) || item.user_id.toString().toLowerCase().includes(value.toString().toLowerCase())
        // || item?.roll_no.includes(value)
      );

      console.log("filtered list", filteredList);
      if (filteredList.length === 0) {
        setFilterdParticipants([]);
      } else {
        setFilterdParticipants(filteredList);
      }
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle, justifyContent: "flex-start" }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Participants List
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography>
              <span style={{ color: Color.red }}>Note:</span>
              <span style={{ color: Color.neutralMidGrey }}>To add or remove participants from Asian Paints (Marketing Executive) chat, release a new shortlist or edit the current shortlist</span>
            </Typography>
            {props.path === "ClosedForApplication" && (
              <Box sx={{ ...DivCenter, width: "100%" }}>
                <Button variant="text" sx={{ color: Color.primary1 }} onClick={() => navigate("/AllApplicants", { state: { review: props?.review } })}>
                  Head to shortlist view
                </Button>
              </Box>
            )}
            <Box sx={{ width: "100%" }}>
              <TextField
                name="search_colleges"
                variant="outlined"
                placeholder="Search for a student"
                value={searchParticipants}
                onChange={handleSearchParticipants}
                fullWidth
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: "20px",
                  background: Color.neutralLightGrey,
                  borderRadius: "30px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: "30px", height: "44px" },
                }}
              />
            </Box>
            <Box sx={{ ...DivCenter, width: "100%", justifyContent: "flex-start", padding: "0 16px", display: "flex", flexDirection: "column" }}>
              {filteredParticipants.map((participant) => (
                <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-between", background: "rgba(250, 250, 250, 1)", marginTop: "1rem" }}>
                  <Typography style={{ color: Color.neutralBlack, fontSize: "18px" }}>{participant.name}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  const handleSend = () => {
    setMessageLoading(true);
    const temp_message = { text: message.replaceAll("'", "’").replaceAll('"', "”") };
    console.log("temp me", temp_message);
    const temp_data = { process_id: process_id, message: { text: temp_message.text }, user_id: userId, is_clgAdmin: true };
    if (temp_data.message.text.trim() !== "") sendMessage(temp_data);
    scrollToBottom();
    setMessage("");
    setMessageLoading(false);
  };
  const handleSendDocument1 = () => {
    setMessageLoading(true);
    const temp_message = { url: message.replaceAll("'", "’").replaceAll('"', "”") };
    console.log("temp me", temp_message);
    const temp_data = { process_id: process_id, message: { url: temp_message.url }, user_id: userId, is_clgAdmin: true };
    if (temp_data.message.url.trim() !== "") sendMessage(temp_data);
    setMessage("");
    setMessageLoading(false);
    scrollToBottom();
  };

  function sendMessage(temp_message_data) {
    console.log("MESSAGER", temp_message_data);
    try {
      socketRef.current.emit("processSendMessage", temp_message_data, (error, response) => {
        if (error) {
          console.error("errorrrrr", error);
        } else {
          console.log("responseeee", response);
        }

      });
      console.log("this stage");
    } catch (err) {
      console.error("errorrrrr in", err);
    }
  }

  const handleDocumentUpload = async (selectedImage) => {
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    };
    console.log("pdf name", selectedImage);
    let pdfFile = new Blob([selectedImage], {
      type: "application/pdf",
    });

    const image_data = new FormData();
    image_data.append("poFile", pdfFile, selectedImage.name);

    try {
      const imageRes = await axios.post(`${process.env.REACT_APP_API_URL}/po/student/poDocumentUpload`, image_data, { headers });
      console.log("imageRes", imageRes);
      return imageRes?.data?.document_url;
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleChangeDocument = async (e) => {
    console.log("ImageUpload", e.target.files[0].name, e.target.files[0]);
    setSelectedImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
    let temp_doc_name = await handleDocumentUpload(e.target.files[0]);
    console.log("temp_doc_name", temp_doc_name);
    setMessage(temp_doc_name);
    setOpenDialog(true);
  };

  function isLastOccurrenceNotThreeOrFour(arr) {
    let lastOccurrenceIndex = -1;
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].chat_admin_id === 3 || arr[i].chat_admin_id === 4) {
        lastOccurrenceIndex = i;
        break;
      }
    }
    if (lastOccurrenceIndex === -1) {
      return true;
    }
    return arr[lastOccurrenceIndex].chat_admin_id !== 3;
  }

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatDivRef.current) {
        chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
      }
    };

    const lastOccurrenceIndex = isLastOccurrenceNotThreeOrFour(messages);
    console.log("lastOccurrenceIndex", lastOccurrenceIndex);
    setAllowMessages(lastOccurrenceIndex);

    if (props.value === 3) {
      scrollToBottom();
    }
  }, [messages]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleViewParticipants = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onCloseDialog = () => {
    setCloseDialog(false);
  };

  function MaxWidthDialog({ openDialog, onCloseDialog, handleDocumentUpload }) {
    const [open, setOpen] = React.useState(openDialog);
    console.log("openDialog", openDialog);
    // const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState(350);

    const handleClose = () => {
      setOpen(false);
      setOpen(false);
      setOpenDialog(false);
      setMessage("");
      setSelectedImage(null);
      setImageName("");
    };

    const handleSendDocument = () => {
      handleSendDocument1();
      handleClose();
    };

    React.useEffect(() => {
      setOpen(openDialog);
    }, []);

    return (
      <Box>
        <Dialog
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          sx={{ width: "50%", marginLeft: "auto", bottom: props.path === "ClosedForApplication" ? "3rem" : "5.5rem", top: "auto", borderRadius: "40px", marginRight: props.path === "ClosedForApplication" ? 0 : "18rem" }}
        >
          <DialogContent>
            <DialogContentText>Attach Document for the group chat</DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <DescriptionOutlinedIcon />
                <button style={{ border: "none", height: "15px", color: "#1C8EA8", background: "transparent", outline: "none", fontSize: "16px" }}>{imageName}</button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none", color: Color.primary1, background: "transparent" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button style={{ textTransform: "none", color: Color.primary1, background: "transparent" }} onClick={handleSendDocument}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography sx={{ fontSize: "20px" }}>Group Chat</Typography>
      {console.log("all the messages", Object.entries(groupedMessages))}
      <Typography>Any message sent here will be sent to all the students in the participants list</Typography>
      {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box>
          <button
            className="submitBtn"
            type="button"
            style={{
              borderRadius: "40px",
              padding: "5px 10px",
              width: "auto",
              height: "35px",
              border: `1px solid ${Color.primary1}`,
              background: Color.primary_hover,
              color: Color.white,
              cursor: "pointer",
            }}
            onClick={handleViewParticipants}
          >
            View participants list
          </button>
        </Box>
      </Box> */}
      {messages.length === 0 ? (
        <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: "center", margin: "0 auto", marginTop: "1rem" }}>
          <div className="noUpcomingBookingsIcon">
            <SpeakerNotesOffIcon
              sx={{
                color: "#1C8EA8",
                width: "50px",
                height: "50px",
              }}
            />
          </div>
          <div className="noUpcomingBookingsTexts">
            <p className="noUpcomingBookingsText">No messages yet</p>
          </div>
        </div>
      ) : (
        <Box className="chat-container" style={{ overflowY: "auto" }} ref={chatDivRef}>
          <Box sx={{ borderRadius: "1rem", width: "100%", overflowY: "auto", paddingTop: ".5rem", paddingBottom: ".5rem" }}>
            <div style={{}}>
              {Object.entries(groupedMessages).map(([date, messages], index) => (
                <div key={index}>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="Rephrasebutton"
                      style={{
                        border: `1px solid ${Color.neutralLightGrey}`,
                        background: Color.neutralLightGrey,
                        color: Color.blueBlack,
                        textAlign: "center",
                        width: "auto",
                        height: "auto",
                        padding: "2px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "11px",
                      }}
                    >
                      {date}
                    </button>
                  </Box>

                  {messages?.length > 0 ? (
                    <>
                      {messages
                        .filter((filtering) => !filtering.chat_admin_id)
                        .map((item, index) => {
                          const isContinuous = index > 0 && item.user_id === messages[index - 1]?.user_id;
                          console.log("item", item);
                          
                          const marginTop = isContinuous ? ".05rem" : ".6rem";
                          return (
                            <div key={index}>
                              <div style={{ overflowY: "auto", position: "relative" }}>
                                <React.Fragment>
                                  <div style={{ padding: "6px", width: item.user_id === userId && !(item?.message?.text?.length > 80) ? "auto" : item.user_id === userId && item?.message?.text?.length > 80 ? "80%" : item.user_id !== userId && item?.message?.text?.length > 80 ? "80%" : "auto", borderRadius: "5px", backgroundColor: item.user_id === userId ? Color.primary2 : Color.white, border: "none", marginTop: marginTop, float: item.user_id === userId ? "right" : "left", marginRight: "1rem", marginLeft: "1rem", wordWrap: "break-word", marginBottom: item.user_id !== messages[index - 1]?.user_id && index !== 0 ? "" : ".11rem" }}>
                                    {!isContinuous && !(item.user_id === userId) && (
                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Typography sx={{ color: item.user_id === userId ? Color.green : randomColor, fontSize: "11px", marginTop: "auto", marginBottom: "auto", fontFamily: "Helvetica" }}>{item.name}</Typography>
                                      </Box>
                                    )}
                                    <div>
                                      {item?.message?.url ? (
                                        <>
                                          {messageLoading ? (
                                            <CircularProgress />
                                          ) : (
                                            <Box sx={{ background: parseInt(item.user_id) === parseInt(userId) ? "rgb(215,243,249)" : "rgb(238, 238, 238)" }}>
                                              <Box sx={{ marginTop: marginTop, minWidth: "200px", border: "rgba(238, 238, 238, 0.5)", background: parseInt(item.user_id) === parseInt(userId) ? "rgba(215,243,249, 0.7)" : "rgba(238, 238, 238, 0.7)", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: ".5rem", borderRadius: "4px", marginBottom: ".5rem" }}>
                                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                  <InsertDriveFileOutlinedIcon style={{ color: Color.neutralMidGrey }} />
                                                  <button
                                                    style={{ border: "none", height: "15px", color: Color.blueBlack, background: "transparent", outline: "none", fontSize: "13px", fontFamily: "Helvetica" }}
                                                    onClick={() => {
                                                      window.open(item?.message?.url);
                                                    }}
                                                  >
                                                    {(item?.message?.url?.split("/") ?? []).pop().length > 15 ? `${(item?.message?.url?.split("/") ?? []).pop().substring(0, 15)}...` : (item?.message?.url?.split("/") ?? []).pop()}
                                                  </button>
                                                </Box>
                                                <div style={{ color: "transparent", background: "transparent", width: "100%", marginTop: ".3rem", marginBottom: ".3rem", border: "none" }} className="line-login"></div>
                                                <button
                                                  onClick={() => {
                                                    window.open(item?.message?.url);
                                                  }}
                                                  style={{ border: "rgba(238, 238, 238, 0.5)", background: Color.white, borderRadius: ".3rem", height: "26px", fontFamily: "Helvetica", justifyContent: "center", alignItems: "center", color: Color.blueBlack, width: "100%", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", fontSize: "12px", paddingTop: ".2rem", paddingBottom: ".2rem" }}
                                                >
                                                  Open
                                                </button>
                                              </Box>
                                            </Box>
                                          )}
                                        </>
                                      ) : (
                                        <>{messageLoading ? <CircularProgress /> : <p style={{ fontSize: "13px", marginBottom: "0px", fontFamily: "Helvetica", paddingRight: "2.5rem", marginTop: !(item.user_id === userId) ? "-6px" : "0px" }}>{item?.message?.text ? get_specialCharReplace(item?.message?.text) : item?.message?.url ? get_specialCharReplace(item?.message?.url) : ""}</p>}</>
                                      )}
                                    </div>
                                    <div style={{ flexDirection: "row", justifyContent: item.user_id === userId ? "flex-end" : "flex-end", display: "flex", padding: "0px", marginTop: "-5px" }}>
                                      <p style={{ fontSize: "9px", color: "#8B8B8B", marginBottom: "0px", marginTop: "0px", fontFamily: "Helvetica" }}>{convertEpochToNormalTime(item?.time)}</p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </Box>
        </Box>
      )}

      <>
        <div style={{ position: "relative", display: "flex", flexDirection: "row", bottom: "20px", width: "100%", justifyContent: "space-around", marginTop: "2rem", gap: "1rem" }}>
          <input type="text" style={{ width: "80%", color: Color.blueBlack, borderRadius: "10px", pointerEvents: "auto", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)", height: "48px", border: "1px solid var(--Neutral-Light-Grey, #E6E6E6)", paddingLeft: "1rem" }} name="chat-input" autoFocus value={message} onChange={(e) => handleChangeInputField(e)} onKeyDown={handleKeyPress} />
          <Box style={{ width: "5%", marginTop: "auto", marginBottom: "auto" }}>
            <label htmlFor="file-input">
              <AttachFileIcon style={{ width: "100%", color: Color.primary1, cursor: "pointer", backgroundColor: "rgba(28, 142, 168, 0.15)", borderRadius: "50%", padding: "0.5rem", height: "100%" }} />
            </label>
            <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => handleChangeDocument(e)} />
          </Box>
          <SendOutlinedIcon disabled={message === "" ? true : false} style={{ width: "5%", marginTop: "auto", marginBottom: "auto", color: Color.primary1, cursor: message !== "" ? "pointer" : "not-allowed", backgroundColor: "rgba(28, 142, 168, 0.15)", borderRadius: "50%", padding: "0.5rem", height: "100%" }} onClick={() => handleSend()} />
        </div>  
      </>


      {openDialog ? <MaxWidthDialog openDialog={openDialog} onCloseDialog={onCloseDialog} handleDocumentUpload={handleDocumentUpload} /> : <></>}
      <ParticipantsModal open={isModalOpen} onClose={handleCloseModal} participants={participantsList} />
    </Box>
  );
};

export default GroupChat;
