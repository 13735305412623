import React, { useRef, useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, useMediaQuery, Button, CardActionArea, CardActions } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { } from '@mui/material';
import { Color } from "../../GlobalStyles";
import ShareIcon from '@mui/icons-material/Share';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../services/specialCharReplace";
import { useNavigate } from "react-router-dom";
import BlogsLoader from "./BlogsLoader";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const CategoryBasedBlogs = (props) => {
    const navigate = useNavigate()
    const Mobile = useMediaQuery("(max-width:800px)");
    const service = new CCService()
    const [blogsDetails, setBlogsDetails] = useState([])
    const [tagsDetails, setTagDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const [blogsAccordingToCategory, setBlogsAccordingToCategory] = useState([])
    const { skeletonLoading, setCountElement, countElement, category } = props
    const [showAllBlogs, setShowAllBlogs] = useState(false);
    const [addOrEditTag, setAddOrEditTag] = useState("")

    const blogsDataGetter = async () => {
        try {
            const res = await service.get(`/blog/blog/all?platform=CareerCarve`);
            console.log("blogs data response", res.blog_category);
            return res.blog_category;
        } catch (err) {
            console.log(err);
        }
    };

    const tagsDataGetter = async () => {
        try {
            const res = await service.get(`/blog/tag`);
            console.log("blogs data response", res.tags);
            return res.tags;
        } catch (err) {
            console.log(err);
        }
    };



    const blogsAccordingToCategoryDataGetter = async () => {
        try {
            const res = await service.get(`/blog/category/blog?category_id=${category.id}`);
            console.log("category data response", res.blogs);
            return res.blogs;
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const handelEffect = async () => {
            const blogsData = await blogsDataGetter();
            const tagsData = await tagsDataGetter()
            const blogsAccordingToCategory = await blogsAccordingToCategoryDataGetter()
            let temp_blogsData = blogsData.filter(category => category.blogs);
            setBlogsDetails(temp_blogsData);
            setTagDetails(tagsData)
            // setCategory(category)
            setBlogsAccordingToCategory(blogsAccordingToCategory)
            console.log("bhoomika all data getter", temp_blogsData, tagsData, blogsAccordingToCategory);

            setLoading(false)
        };
        handelEffect();
    }, [])

    const handleEntireDetailsNavigation = (blog_id) => {
        console.log("blog id here", blog_id);
        navigate("/BlogsEntireDetails", { state: { blog_id: blog_id, tagsDetails: tagsDetails } })
    }


    const handleViewAll = () => {
        setShowAllBlogs(true);
    };

    const handleEditBlogs = (e, index, blog_id) => {
        e.preventDefault()
        setAddOrEditTag("Edit")
        navigate("/EditTheBlogs", { state: { addOrEditTag: "Edit", blog_id: blog_id } })
    }

    return (
        <div>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "90%"
                }}
            >
                <Typography
                    variant={Mobile ? "h5" : "h4"}
                    fontSize={Mobile ? "20px" : "24px"}
                    color={"#545454"}
                    sx={{
                        fontWeight: "400",
                        textAlign: "left", marginBottom: "1.5rem"
                    }}
                >
                    {category.name}
                </Typography>
                {blogsAccordingToCategory.length > 3 && !showAllBlogs && (
                    <button style={{ background: "transparent", border: "none", color: Color.primary1 }} onClick={handleViewAll}>
                        View All
                    </button>
                )}
            </Box>
            {loading ? <>
                <div style={{ display: "flex", flexDirection: "row", gap: "5rem", background: "transparent" }}>
                    <BlogsLoader />
                    <BlogsLoader />
                    <BlogsLoader />
                </div>
            </> : <>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "left",
                        flexDirection: "column",
                        gap: "2rem",
                    }}
                >
                    <Grid container spacing={1}>
                        {blogsAccordingToCategory?.slice(0, showAllBlogs ? blogsAccordingToCategory?.length : 3).map((blogs, index) => {
                            return (
                                <>

                                    <Grid item xs={12} sm={6} md={4} mb={5}>
                                        <Card sx={{ maxWidth: 345, padding: "15px", borderRadius: "40px", background: "#FFF", boxShadow: "0px 10px 93.2px 0px rgba(0, 0, 0, 0.10)" }}>
                                            <CardMedia
                                                component="img"
                                                height="269"
                                                image={blogs.image}
                                                alt="green iguana"
                                                sx={{
                                                    // height: "269px",
                                                    alignSelf: "stretch",
                                                    borderRadius: "40px"
                                                }}
                                            />
                                            <CardContent>
                                                <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                                                    <Box sx={{ display: "flex", justifyContent: 'space-equally' }}>
                                                        {tagsDetails
                                                            .filter((tag) => (blogs?.tag_ids?.tag_ids)?.includes(tag.id)) // Filter to include only the tags with matching IDs
                                                            .slice(0, 2) // Take only the first two elements after filtering
                                                            .map((tag, index) => (
                                                                <Typography
                                                                    key={index}
                                                                    size="small"
                                                                    color={Color.neutralDarkGrey}
                                                                    style={{
                                                                        borderRadius: "61px",
                                                                        border: "1px solid #1C8EA8",
                                                                        background: "#EBF9FC",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                        wordWrap: "break-word",
                                                                        fontSize: "10px",
                                                                        justifyContent: "center",
                                                                        marginRight: ".2rem",
                                                                        paddingTop: "2px",
                                                                        alignItems: "center"
                                                                    }}
                                                                    gutterBottom
                                                                >
                                                                    {tag.name.length > 10 ? `${tag.name.slice(0, 10)}...` : tag.name}
                                                                </Typography>
                                                            ))}
                                                    </Box>

                                                    <EditOutlinedIcon onClick={(e) => handleEditBlogs(e, index, blogs.id, blogs)} style={{ cursor: "pointer" }} />

                                                </Box>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {blogs?.heading}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    {/* {blogs.description} */}
                                                    {blogs?.description?.length > 30
                                                        ? `${blogs.description?.slice(0, 30)}...`
                                                        : blogs?.description}
                                                </Typography>
                                                <Box sx={{ display: "flex" }} >
                                                    <Typography variant="body2" color={Color.neutralDarkGrey} style={{ marginRight: ".5rem" }}>
                                                        {formatConvertermonthfirst(
                                                            extractDateFromDateString(blogs.upload_time)
                                                        )}
                                                    </Typography>
                                                    <FiberManualRecordIcon style={{ width: ".3rem", paddingBottom: ".3rem", marginRight: ".5rem" }} />
                                                    <Typography variant="body2" color={Color.neutralDarkGrey}>{blogs.reading_time} mins read</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Box>
            </>}
        </div >
    )
}

export default CategoryBasedBlogs
