import React from "react";
import { useState, useEffect } from "react";
function Intership(props) {
  const name = props.name;
  const { BuildingContext_preview, BuildingAction_preview, BuildingResult_preview, currentProjectId, section_detail } = props
  console.log("BuildingContext_preview", BuildingContext_preview)
  console.log("section_detail", section_detail)
  // let projectContentData = []
  // if (BuildingContext_preview && BuildingContext_preview.length > 0) {
  //   projectContentData = BuildingContext_preview.filter((data) => { return data.project_id === currentProjectId })
  // }
  // {
  //   "project_Role":"cttsdfsdg",
  //   "Project_id":id,
  //   "Action":[]
  //   "Result":[]
  // }
  // let ProjectData = []

  //   (BuildingContext_preview && BuildingContext_preview.length > 0) ? BuildingContext_preview.map((contextData) => {
  //     ProjectData.push({
  //       project_role: contextData.bullet_content,
  //       project_id: contextData.project_id,
  //       Action: (BuildingAction_preview && BuildingAction_preview.length > 0) ? BuildingAction_preview.filter((val) => { return contextData.project_id === val.project_id }) : [],
  //       Result: (BuildingResult_preview && BuildingResult_preview.length > 0) ? BuildingResult_preview.filter((val) => { return contextData.project_id === val.project_id }) : [],
  //     })
  //   }) : []

  let Details = []

  if ((BuildingContext_preview && BuildingContext_preview.length > 0)) {
    BuildingContext_preview.map((value) =>
      Details.push({
        Role: (
          <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS }}>

            {value[0]?.bullet_content}
          </p>
        ),
        Desc: (
          <p style={{ textAlign: "justify", marginBottom: '0px' }}>
            <ul className="resumeTable-ul"
              style={{
                marginLeft: "17px",
                listStyleType: props.Bullet ? "square" : "",
                lineHeight: props.LineHeight,
              }}
            >
              {/* <li style={{ listStyleType: 'none', fontFamily: props.fontS }}>
                  {props.text1}
                </li> */}

              {
                ((BuildingAction_preview && BuildingAction_preview.length > 0) ? BuildingAction_preview.filter((val) => { return value[0]?.project_id === val.project_id }) : []).map((Action) => {
                  return Action?.bullet_content?.length > 0 ? <li style={{ listStyleType: BuildingAction_preview ? "" : 'none', fontFamily: props.fontS }}>
                    {Action?.bullet_content}
                  </li> : <></>
                }
                )
              }

              {
                ((BuildingResult_preview && BuildingResult_preview.length > 0) ? BuildingResult_preview.filter((val) => { return value[0]?.project_id === val.project_id }) : []).map((Result) => {
                  return Result?.bullet_content?.length > 0 ? <li style={{ listStyleType: BuildingResult_preview ? "" : 'none', fontFamily: props.fontS }}>
                    {Result?.bullet_content}
                  </li> : <></>
                }
                )
              }

              <li style={{ listStyleType: 'none', fontFamily: props.fontS }}>
                {/* Recommended reestablishing contact and offering preferential
                  services, schemes, & waivers to ‘win-back’ accounts under
                  Quadrants 3 & 4 that were deemed critical due to declining
                  balance-levels over time. */}
                {props.text1}
              </li>
            </ul >
          </p >
        ),
      })
    )
  }

  return (
    <div style={{ padding: props.padding, backgroundColor: "white" }} >
      {console.log("ïnternship.js:", props.padding, props.minwidth)}
      <table
        className="IntershipBox New ResumeTable"
        // style={{ width: props.TableWidth ? "105%" : "100%" }}
        style={{
          width: "100%",

          // width: props.minwidth ? props.minwidth : props.TableWidth,
          // marginRight: '2%', margin:'auto'
          // width:props.minwidth+"%",
        }}
      // style={{ width: props.widths+"px" }}
      >
        <tr>
          <th className="Resumetable-th"
            id="SIP"
            colspan="6"
            style={{
              backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
              color: "#0009",
              fontSize: props.FontSize,
              maxWidth: "274px",
              fontFamily: props.fontS,
              border: "0.1px solid rgb(0 0 0 / 34%)",
              textAlign: props.headerPosition,
              height: props.miniheight,
              height: '25px',
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
              padding: props.minipadding,
              lineHeight: props.LineHeight
            }}
          >
            {section_detail?.section_name ? section_detail.section_name : "Summer Internship Experience"}
            {/* SUMMER INTERNSHIP PROGRAMME */}
            {/* {props.name ? props.name : 'Summer Internship Experience'} */}
            {/* {props.text5} */}
          </th>
        </tr>
        <tr>
          <td className="ResumeTable-td"
            id="Role"
            style={{
              backgroundColor: "#fff",
              width: "16%",
              color: "#0009",
              fontSize: props.FontSize,
              fontFamily: props.fontS,
              padding: "0.2rem",
              border: "0.1px solid rgb(0 0 0 / 34%)",
              fontWeight: "bold",
              // height:'25px',
              height: props.miniheight,
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
              lineHeight: props.LineHeight
            }}
          >
            {/* {props.companyName} */}
            {props.text2}
            {/* SUMMER INTERN */}
            {props.formInputs?.designation}
          </td>
          <td className="ResumeTable-td"
            colspan="2"
            id="FirmName"
            style={{
              backgroundColor: "#fff",
              width: "58%",
              color: "#0009",
              fontSize: props.FontSize,
              fontFamily: props.fontS,
              padding: "0.2rem",
              border: "0.1px solid rgb(0 0 0 / 34%)",
              fontWeight: "bold",
              // height:'25px',
              height: props.miniheight,
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
              lineHeight: props.LineHeight
            }}
          >
            {props.designationName}
            {props.text3}
            {/* YES BANK */}
            {props.formInputs?.company_name}
          </td>
          <td className="ResumeTable-td"
            colspan="2"
            id="Duration"
            style={{
              backgroundColor: "#fff",
              color: "#0009",
              fontSize: props.FontSize,
              fontFamily: props.fontS,
              padding: "0.2rem",
              border: "0.1px solid rgb(0 0 0 / 34%)",
              fontWeight: "bold",
              // height:'25px',
              height: props.miniheight,
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
              lineHeight: props.LineHeight
            }}
          >
            {/* {props.dateChange ? props.dateChange + '—' : ''} */}
            {/* {props.endDateChange} */}
            {props.text4}
            {/* APRIL 2021 – JUNE 2021 */}
            {/* {props.end_month} */}
            {props.formInputs?.end_date ? props.start_date + " — " + props.end_date : props.start_date}
            {props.formInputs?.still_working && !props.formInputs?.end_date ? " — Present" : ""}
          </td>
        </tr>


        {Details.map((val, key) => {
          return (
            <tr key={key}>
              <td className="ResumeTable-td"
                style={{
                  fontFamily: props.fontS,
                  fontSize: props.FontSize,
                  border: "0.1px solid rgb(0 0 0 / 34%)",
                  height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                  maxWidth: '50px',
                  height: '30px',
                  color: "#0009",
                  overflowWrap: 'break-word',
                  paddingTop: key === 0 ? "0.16rem" : "0rem",
                  paddingBottom: key === Details.length - 1 ? "0.16rem" : "0rem",

                }}

              >
                {val.Role}
              </td>
              <td className="ResumeTable-td"
                style={{
                  // fontSize: props.FontSmall,
                  fontFamily: props.fontS,
                  fontSize: props.FontSize,
                  border: "0.1px solid rgb(0 0 0 / 34%)",
                  height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                  height: '30px',
                  color: "#0009",
                  // fontFamily: props.FontFamily ? "" : "Arial",
                  // fontFamily: props.Arial,
                }}
                colSpan={4}
              >
                {val.Desc}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default Intership;