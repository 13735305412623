import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { DivCenter } from "../../../GlobalStyles";

export const DialogueBox = ({ open, onClose, title, content, actions, isFullScreen, fullWidth, maxWidth, backgroundColor, borderRadius }) => {
    return (
        <Dialog open={open} onClose={onClose} fullScreen={isFullScreen ? true : false} fullWidth={fullWidth} maxWidth={maxWidth} PaperProps={{
            style: {
                width: '50%',
                borderRadius: borderRadius ? borderRadius : "20px",
                backgroundColor: backgroundColor ? backgroundColor : "#FFF"
            },
        }}>
            <DialogTitle
                // sx={{ display: "flex", justifyContent: 'center' }}
                sx={{ padding: '20px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{title}</Typography>
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                    onClick={() => onClose()}
                    color="inherit"
                    aria-label="close">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '20px' }}>{content}</DialogContent>
            <DialogActions sx={{ padding: '20px' }}>
                {actions?.length === undefined ?
                    <Box width="100%" sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button key={actions.label}
                            onClick={actions.handler}
                            disabled={actions.disabled ?? false}
                            // color={action.color}
                            variant={actions.variant}
                            sx={{
                                width: "10%", height: "40px",
                                background: actions.variant === "contained" ? actions.color : "transparent",
                                color: actions.variant === "contained" ? "#FFF" : actions.color,
                                borderColor: actions.color,
                                borderRadius: "10px", padding: '14px', boxShadow: 'none',
                                '&:hover': {
                                    background: actions.variant === "contained" ? actions.color : "transparent",
                                    color: actions.variant === "contained" ? "#FFF" : "#FFF",
                                    borderColor: actions.color, boxShadow: 'none',
                                }
                            }}>
                            {actions.label}
                        </Button>
                    </Box>
                    : actions.length > 0 ?
                        <Box width="100%" sx={{ display: "flex", justifyContent: "center", gap: '16px' }}>
                            {actions.map((action) =>
                                <Button key={action.label}
                                    onClick={action.handler}
                                    disabled={action.disabled ?? false}
                                    // color={action.color}
                                    variant={action.variant}
                                    sx={{
                                        width: action.width ? action.width : "auto", height: action.height ? action.height : "40px",
                                        background: action.background ? action.background : (action.variant === "contained" ? action.color : "transparent"),
                                        color: (action.variant === "contained" ? "#FFF" : action.color),
                                        borderColor: action.borderColor ? action.borderColor : action.color,
                                        borderRadius: "10px", padding: '14px', boxShadow: 'none',
                                        textTransform: 'none',

                                        '&:hover': {
                                            background: action.background ? action.background : (action.variant === "contained" ? action.color : "transparent"),
                                            color:  (action.variant === "contained" ? "#FFF" : action.color),
                                            borderColor: action.borderColor ? action.borderColor : action.color,
                                            boxShadow: 'none',
                                        }
                                    }}>
                                    {console.log("button_caction", { button_caction: action })}
                                    {action.label}
                                </Button>)}
                        </Box>
                        : <></>}

            </DialogActions>
        </Dialog >

    );
};