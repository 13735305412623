import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import CardActions from '@mui/material/CardActions';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Service from '../../../services/httpService';
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import Divider from '@mui/material/Divider';
import Loader from '../../reusables/Loader/Loader';
import CircularProgress from '@mui/material/CircularProgress';
const B2CProcessComponent = () => {
    const [loading, setLoading] = useState(true);
    const service = new Service()
    const navigate = useNavigate();
    const [cardDetails, setCardDetails] = useState([])
    const [expandedCardDetails, setExpandedCardDetails] = useState({})

    console.log("B2CProcessComponent ~ cardDetails:", cardDetails)


    // useeffect'
    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let student = await b2cProcessDataGetter();
            console.log("not filtered data", student);
            let filtered_student_list = student.filter(item => item.sku_name !== "Resumes.Digital 1 year")
            console.log("filtered data", filtered_student_list);
            filtered_student_list?.forEach(element => {
                element.process_id = element.process_id;
            });
            setCardDetails(filtered_student_list)
            setLoading(false);
        }
        handleEffect();
    }, [])

    const b2cProcessDataGetter = async () => {
        try {
            const res = await service.get("/b2cProcess")
            console.log("Card details response:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }


    const navigateToStudentDetails = (process_id, college_id, college_name, batchId, batchName, sku_name, searched) => {
        navigate("College1x1StudentDetails", {
            state: {
                college_data_id: college_id,
                college_data_name: college_name,
                batchName: batchName,
                batchid: batchId,
                process_id: process_id,
                sku_name: sku_name,
                searched: searched,
                is_b2c: true
            }
        })
    }

    console.log("crad details", cardDetails);
    console.log("expanded card details", Object.entries(expandedCardDetails));
    return (
        <div className="cardsInsideFlex">
            {loading ? (
                <Loader show={loading} />
            ) : (
                <>
                    {cardDetails?.map((details, index) => {
                        return (
                            <>
                                <Box
                                    onClick={details?.isAllDataExpanded === true ? () => { } : () => navigateToStudentDetails(details.process_id, details.college_id, details.college_name, details.user_group_id, details.user_group_name, details.sku_name, "All")}
                                    sx={{
                                        width: 230, marginRight: '1rem', marginLeft: '.2rem', marginBottom: '.2rem',
                                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px",
                                        display: "flex",
                                        height: 'auto',
                                        cursor: "pointer"
                                    }}>
                                    <CardContent className="cardContainer" disableGutters style={{ width: '100%', height: '100%' }}>
                                        <Typography variant="body2" sx={{ fontSize: 20, marginBottom: "1rem", height: '60%' }} gutterBottom>
                                            {details.sku_name}
                                        </Typography>
                                        <hr style={{ width: '100%' }} />
                                        <div className="container" style={{ height: '40%' }}>
                                            <Typography variant="body2" class='providingSpace1'>Total Orders:</Typography>
                                            <Typography variant="body2" class='providingSpace'>{details.order_count}</Typography>
                                        </div>
                                    </CardContent>
                                </Box>
                            </>
                        )
                    })}
                </>
            )}

        </div>
    )
}

export default B2CProcessComponent