import { Edit, Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

function EpisodeDetails({ type, close, onEdit, onAddEpisode, selectedDetailModal, activityList, specializationList, roleList, handleDragAndDropChange, handleEditEpisodeIcon }) {
  const location = useLocation();
  return (
    <Box className="detailmodal_main-div" sx={{ width: "716px" }}>
      <Box sx={{ padding: "24px 40px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "32px" }}>{selectedDetailModal.name}</Typography>

            <Box sx={{ display: "flex", gap: "10px" }}>
              <IconButton onClick={() => onAddEpisode()}>
                <Edit sx={{ color: "#1C8EA8" }} />
              </IconButton>
              <IconButton onClick={() => close()}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          {/* <button
        className="detailmodal__edit-button"
        onClick={() => onAddEpisode()}
      >
        Edit
      </button> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Mentor Name:</Typography>
              <Box sx={{ width: "80%", display: "flex" }}>
                {selectedDetailModal.mentor_details.map((item) => (
                  <Typography sx={{ fontSize: "16px", width: "80%" }}>{item.mentorName}</Typography>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Description::</Typography>
              <Box sx={{ width: "80%", display: "flex" }}>
                <Typography sx={{ fontSize: "16px", width: "80%" }}>{selectedDetailModal.about}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Picture:</Typography>
              {[null, "null", ""].includes(selectedDetailModal.image) ? (
                <Typography sx={{ fontSize: "14px", color: "red" }}>No image Available</Typography>
              ) : (
                <a href={selectedDetailModal.image} style={{ width: "85%", textDecoration: "none" }} target="_blank">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#1C8EA8",
                    }}
                  >
                    {selectedDetailModal.name.replaceAll(" ", "_")}.jpg
                  </Typography>
                </a>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>{location.pathname === "/Podcast" ? "Audio" : "Video"}</Typography>
              <a href={selectedDetailModal.recording} target="_blank" style={{ width: "80%", textDecoration: "none" }} rel="noreferrer">
                <Typography
                  sx={{
                    fontSize: "16px",

                    color: "#1C8EA8",
                  }}
                >
                  {location.pathname === "/Podcast" ? `${selectedDetailModal.name}.mp3` : `${selectedDetailModal.name}.mp4`}
                </Typography>
              </a>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Duration:</Typography>
              <Typography sx={{ fontSize: "16px", width: "80%" }}>22 mins</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Keywords:</Typography>
              <Box display={"flex"} gap={"1rem"} sx={{ flexWrap: "wrap" }} width={"80%"}>
                {selectedDetailModal.keywords.map((item) => (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      backgroundColor: "#E6E6E6",
                      borderRadius: "61px",
                      padding: "10px",
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B", width: "20%" }}>Linked to:</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  gap: "10px",
                }}
              >
                {selectedDetailModal.activities.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid #1C8EA8",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    {selectedDetailModal.activities.map((item) => {
                      let temp = activityList.find((ele) => ele.id === item);
                      return (
                        <Box
                          sx={{
                            display: "inline",
                            backgroundColor: "#E6E6E6",
                            padding: "5px",
                            marginBottom: "60px",
                            marginInline: "5px",
                          }}
                        >
                          {temp.act_type_name}
                        </Box>
                      );
                    })}
                  </Box>
                )}
                {selectedDetailModal.roles.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid #1C8EA8",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    {selectedDetailModal.roles.map((item) => {
                      let temp = roleList.find((ele) => ele.id === item);
                      return (
                        <Box
                          sx={{
                            display: "inline",
                            backgroundColor: "#E6E6E6",
                            padding: "5px",
                            marginBottom: "60px",
                            marginInline: "5px",
                          }}
                        >
                          {temp ? temp.name : ""}
                        </Box>
                      );
                    })}
                  </Box>
                )}
                {selectedDetailModal.specialization.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid #1C8EA8",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    {selectedDetailModal.specialization.map((item) => (
                      <Box
                        sx={{
                          display: "inline",
                          backgroundColor: "#E6E6E6",
                          padding: "5px",
                          marginBottom: "60px",
                          marginInline: "5px",
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", height: "1px", bgcolor: "#E6E6E6" }}></Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EpisodeDetails;
