import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "../College1x1StudentDetails/ModalSt";
import Modal from "react-bootstrap/Modal";
import httpService from "../../../services/httpService";
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'
import { green } from "@mui/material/colors";
// import ChatModal from "./ModalChat";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import ReScheduleConfirmation from "./ReScheduleConfirmationModal";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import dayjs from 'dayjs';
import './../College1x1StudentDetails/ModalSt.css'
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';

export default function ModalMentorDetails(props) {
    const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
    const service = new httpService();
    const [user_type_id, SetUser_type_id] = useState(null);
    const [order_details, SetOrder_details] = useState([]);
    const rowsData = useContext(RowsContext);
    const { show, setShow, headCells, from_table, path, studentsDetailsClick, setStudentsDetailsClick, feedbackFields, studentFeedbackFields, roleTypeData, activityTypeData, activeMentor, suspendedMentor, restrictedMentor, activityActiveMentor } = props;
    console.log("info.................", studentsDetailsClick);
    const [name, setName] = useState(rowsData[from_table?.index]?.name);
    const [special, setSpecial] = useState()
    const navigate = useNavigate();
    console.log("activeMentor", activeMentor);
    const [jd, setJD] = useState()
    const [start_time, setStart_time] = useState('')
    const [end_time, setEnd_time] = useState('');
    const [errMsg, setErrMsg] = useState("")
    const modalHide = () => {
        setShow(!show);
        console.log(!show + "bruh --------");
        setName("")
        setSpecial("")
        setJD("")
        setFormInputs({})
        setMentorActivationUpdates({})
        setErrMsg("")
        setSelectActive(false)
        setSelectSuspended(false)
        setSelectRestricted(false)
    };
    const [mentorDetails, setMentorDetails] = useState([])
    const [selectDate, setSelectDate] = useState(false)
    const [selectTime, setSelectTime] = useState(false)
    const [mentorDropDown, setMentorDropDown] = useState(false)
    const [mentor_name, setMentor_name] = useState()
    const [mentor_id, setMentor_id] = useState();

    const [is_active_toggled, setIs_active_toggled] = useState(activeMentor)
    const [is_suspended_toggled, setIs_suspended_toggled] = useState(suspendedMentor)
    const [is_restricted_toggled, setIs_restricted_toggled] = useState(restrictedMentor)
    const [is_activity_active_toggled, setIs_activity_active_toggled] = useState(activityActiveMentor)
    console.log("activityActiveMentor", activityActiveMentor, is_activity_active_toggled);

    const [selectSuspended, setSelectSuspended] = useState(false)
    const [selectRestricted, setSelectRestricted] = useState(false)
    const [selectActive, setSelectActive] = useState(false)


    const [formInputs, setFormInputs] = useState({ slot_date: studentsDetailsClick?.order_details?.slot_date, mentor_id: studentsDetailsClick?.order_details?.mentor_id, order_flag: "Scheduled" })
    const [mentorActivationUpdates, setMentorActivationUpdates] = useState({ active: activeMentor, suspended: suspendedMentor, restricted: restrictedMentor, active_flag: activityActiveMentor })
    const mentorUserDetailsGetter = async () => {
        try {
            const res = await service.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
            console.log("mentorUserDetailsGetter", res.data)
            setMentorDetails([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    console.log("updated mentorActivationUpdates", mentorActivationUpdates);

    const data_manipulation_collegeDetails = collegeDetails => {
        let temp_college = collegeDetails;
        console.log("tem data", temp_college);
        temp_college = temp_college.map(mentor => {
            mentor.first_name = mentor.first_name;
            mentor.last_name = mentor.last_name;
            mentor.id = mentor.user_id;
            mentor.email_id = mentor.email_id
            return mentor;
        });
        console.log("temp data", temp_college);
        setMentor_name(temp_college);
        console.log("college nameee", mentor_name);
    };
    console.log("mentor name", mentor_name);

    useEffect(() => {
        console.log("Brother")
        console.log("from table inside useEffect of modal input:", from_table)
        const handleEffect = async () => {

            let mentorDetailsDropdown = await mentorUserDetailsGetter()
            console.log("mentor dropdown", mentorDetailsDropdown);
            data_manipulation_collegeDetails(mentorDetailsDropdown);
        }
        handleEffect();
    }, [])
    console.log("feedback fields...", feedbackFields);

    const get_data_from_form = (name) => {
        console.log("HandleEditUser ~ special", special)
        console.log("🚀HandleEditUser ~ name", name)
        console.log("🚀HandleEditUser ~ id", from_table?.id)
        // name.preventDefault();
        if (path === "/RoleType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim(), undefined, undefined, special.trim(), jd)
                setName("")
                setSpecial("")
                setJD("")
            }
            else {
                console.log("PUT")
                console.log("PUT - special value:", special)
                props.data_getter(name.trim(), from_table?.id, from_table?.index, special, jd)
            }
        }
        else if (path === "/UserType" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim())
                setName("")
            }
            else {
                console.log("PUT")
                props.data_getter(name.trim(), from_table?.id, from_table?.index)
            }
        }
        setShow(false)
    }

    const handleActiveToggle = () => {
        console.log("toggle state currently", is_active_toggled);
        const temp_toggle_state = !is_active_toggled
        setIs_active_toggled(temp_toggle_state)
        setMentorActivationUpdates({ ...mentorActivationUpdates, active: temp_toggle_state === true ? 1 : 0 })
    }
    console.log("toggle state currently", is_active_toggled);

    const handleSuspendedToggle = () => {
        console.log("toggle state currently", is_suspended_toggled);
        const temp_toggle_state = !is_suspended_toggled
        setIs_suspended_toggled(temp_toggle_state)
        setMentorActivationUpdates({ ...mentorActivationUpdates, suspended: temp_toggle_state === true ? 1 : 0 })
    }
    const handleRestrictedToggle = () => {
        console.log("toggle state currently", is_restricted_toggled);
        const temp_toggle_state = !is_restricted_toggled
        setIs_restricted_toggled(temp_toggle_state)
        setMentorActivationUpdates({ ...mentorActivationUpdates, restricted: temp_toggle_state === true ? 1 : 0 })
    }

    const handleActivityActiveToggle = () => {
        console.log("toggle state currently", is_activity_active_toggled);
        const temp_toggle_state = !is_activity_active_toggled
        console.log("temp_toggle_state", temp_toggle_state);
        setIs_activity_active_toggled(temp_toggle_state)
        setMentorActivationUpdates({ ...mentorActivationUpdates, active_flag: temp_toggle_state === true ? 1 : 0 })
    }

    console.log("rows data in modal input file", rowsData);
    console.log("spec_data", props.spec_data);
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs('2023-03-27T15:30'));
    console.log("value for end time", value);
    // function to convert time to milliseconds
    function timeToMilliseconds(timeString) {
        const timeRegex = /^(\d{1,2}):(\d{2})\s(am|pm)$/i;
        const matches = timeString.match(timeRegex);

        if (!matches) {
            throw new Error('Invalid time format. Please use "hh:mm AM/PM".');
        }

        let hours = parseInt(matches[1]);
        const minutes = parseInt(matches[2]);
        const isPM = matches[3].toLowerCase() === 'pm';

        if (hours === 12) {
            hours = isPM ? 12 : 0;
        } else {
            hours += isPM ? 12 : 0;
        }

        const milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000);
        return milliseconds;
    }


    const slotsDataJson = studentsDetailsClick?.order_details?.slots;
    const slotsData = slotsDataJson ? JSON.parse(slotsDataJson) : {};
    const slotNames = slotsData?.timing?.map(timing => timing?.slot_name) ?? [];

    const resumeDataJson = studentsDetailsClick?.order_details?.resume
    const resumeData = resumeDataJson ? JSON.parse(resumeDataJson) : {};
    const resumeName = resumeData.name ?? " ";
    // console.log("slots selected", slotNames);



    console.log("activityActiveMentor", activityActiveMentor);

    const handleSaveButton = (mentor_id) => {
        setSelectActive(false)
        setSelectSuspended(false)
        setSelectRestricted(false)
        handlePutRequest(mentor_id)
    }

    const handleEditButton = () => {
        // /superAdmin/mentor/suspended
        // /superAdmin/mentor/restricted
        // /superAdmin/mentor/active
        // /mentor/status

        // const updatedFormInputs = {
        //     status: supportTicketModalDetails?.ticket_details?.ticket_status,
        //     notes: supportTicketModalDetails?.ticket_details.notes,
        //     executive_id: null 
        // };

        // const trimmedExecutiveName = (supportTicketModalDetails?.ticket_details?.executive_name || '').trim();

        // supportTicketCategoryNameDetails?.forEach(mentor => {
        //     if (mentor.first_name === trimmedExecutiveName) {
        //         updatedFormInputs.executive_id = mentor.user_id;
        //     }
        // });

        // setFormInputs(updatedFormInputs);


        setSelectSuspended(true)
        setSelectRestricted(true)
        setSelectActive(true)
        // setAdminNotes(true)
    }


    const handlePutRequest = async (mentor_id) => {
        //get request here

        service.put('/mentor/active', {
            ...(is_active_toggled !== activeMentor && {
                "active": mentorActivationUpdates.active
            }), "user_id": mentor_id,
            ...(is_activity_active_toggled !== activityActiveMentor) && { "active_flag": mentorActivationUpdates.active_flag }
        }).then((res) => {
            setMentorActivationUpdates({ ...mentorActivationUpdates, active: mentorActivationUpdates.active, active_flag: mentorActivationUpdates.active_flag })

        })
            .catch((err) => {
                console.log("Error Msg: ", err);
                window.alert(err.response.data.message)
            });

        if (suspendedMentor !== 0) {
            service.put(`/mentor/suspended`, { "suspended": mentorActivationUpdates.suspended, "user_id": mentor_id })
                .then((res) => {
                    setMentorActivationUpdates({ ...mentorActivationUpdates, suspended: mentorActivationUpdates.suspended })

                })
                .catch((err) => {
                    console.log("Error Msg: ", err);
                    window.alert(err.response.data.message)
                });
        }

        if (restrictedMentor !== 0) {
            service.put('/mentor/restricted', { "restricted": mentorActivationUpdates.restricted, "user_id": mentor_id }).then((res) => {
                setMentorActivationUpdates({ ...mentorActivationUpdates, restricted: mentorActivationUpdates.restricted })

            })
                .catch((err) => {
                    console.log("Error Msg: ", err);
                    window.alert(err.response.data.message)
                });
        }
    }

    const handleStudentsDetailsClick = async (mentor_id, searched) => {
        try {
            const res = await service.get(`/mentor/orderDetail?user_id=${mentor_id}`)
            console.log("mentor session", res.orders)
            SetOrder_details(res.orders)
            console.log("order_details", order_details);
            navigate("/MentorStatusFilter", { state: { detailsToShow: res.orders, searched: searched } })
        } catch (err) {
            console.log(err)
        }
    }


    console.log("order_details", order_details);

    return (
        <>
            <Modal
                className="ModalSt-contain"
                {...props} style={{ height: "100vh" }}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => modalHide()}
            >
                <Modal.Body
                    style={{
                        position: "absolute",
                        top: "-24px",
                        left: "127px",
                        height: "auto",
                        lineHeight: "1.4",
                        padding: "14px 28px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        width: "880px",
                        float: "right",
                    }}>
                    <div>
                        <CloseIcon className="closeIcon" style={{ float: "right" }} onClick={() => setShow(false)} />
                        <div style={{ marginTop: "30px", width: "100%" }}>
                            <p className="st-ticket-number">{studentsDetailsClick?.first_name} {studentsDetailsClick?.last_name}</p>
                            <div style={{ color: "red" }}>
                                {errMsg}
                            </div>
                        </div>
                        {selectSuspended && selectActive && selectRestricted ? <>
                            <button
                                className="submitBtn"
                                onClick={() => { handleSaveButton(studentsDetailsClick.mentor_id) }}
                                style={{ width: "45%", marginTop: ".5rem", marginBottom: "0.5rem" }}
                            >
                                Save
                            </button>
                        </> : <>
                            <button
                                className="submitBtn"
                                onClick={() => { handleEditButton(studentsDetailsClick.mentor_id) }}
                                style={{ width: "25%", marginTop: "1rem", marginBottom: "1rem" }}
                            >
                                Edit
                            </button>
                        </>}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "25px", width: '300px' }}>
                                <p className="label-grey-text textTagsHere">Suspended Flag:</p>
                                <p className="label-grey-text textTagsHere">Login Active:</p>
                                <p className="label-grey-text textTagsHere">Mentor Booking Blocked:</p>
                                <p className="label-grey-text textTagsHere">Increase total by 3:</p>

                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                <Switch
                                    checked={is_suspended_toggled}
                                    onChange={handleSuspendedToggle}
                                    name="toggleSwitch"
                                    disabled={!(selectSuspended && selectActive && selectRestricted) || suspendedMentor === 0 ? true : false}
                                />
                                <Switch
                                    checked={is_active_toggled}
                                    onChange={handleActiveToggle}
                                    name="toggleSwitch"
                                    disabled={!(selectSuspended && selectActive && selectRestricted) ? true : false}
                                />
                                <Switch
                                    checked={!is_activity_active_toggled}
                                    onChange={handleActivityActiveToggle}
                                    name="toggleSwitch"
                                    disabled={!(selectSuspended && selectActive && selectRestricted) ? true : false}
                                />
                                <Switch
                                    checked={is_restricted_toggled}
                                    onChange={handleRestrictedToggle}
                                    name="toggleSwitch"
                                    disabled={!(selectSuspended && selectActive && selectRestricted) || restrictedMentor === 0 ? true : false}
                                />
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style2">Mentor Details</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '300px' }}>
                                    <p className="label-grey-text textTagsHere">Mentor Name:</p>
                                    <p className="label-grey-text textTagsHere">Mentor Email:</p>
                                    <p className="label-grey-text textTagsHere">Mentor Contact:</p>
                                    <p className="label-grey-text textTagsHere">Linkedin:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                    <p className="textTagsHere">{studentsDetailsClick?.first_name}{" "}{studentsDetailsClick?.last_name}</p>
                                    <p className="textTagsHere">{studentsDetailsClick?.email_id ? studentsDetailsClick?.email_id : "N/A"}</p>
                                    <p className="textTagsHere">{studentsDetailsClick?.ph_no ? studentsDetailsClick?.ph_no : "N/A"}</p>
                                    <button
                                        className="textTagsHere" style={{ border: "none", color: "#1C8EA8", background: "transparent", textAlign: "left", outline: "none", marginLeft: "1.2rem" }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            const mentorLinkedInLink = studentsDetailsClick.linkedin;
                                            if (mentorLinkedInLink.startsWith("http://") || mentorLinkedInLink.startsWith("https://")) {
                                                window.open(mentorLinkedInLink);
                                            } else {
                                                window.open("https://" + mentorLinkedInLink);
                                            }
                                        }}>
                                        {studentsDetailsClick?.linkedin === "null" ? "-" : "LinkedIn"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style2">Expertise</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '300px' }}>
                                    <p className="label-grey-text textTagsHere">Areas allotted:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                    {JSON.parse(studentsDetailsClick?.role_type)?.role_type_ids.length === 0 ? <p className="label-grey-text textTagsHere">-- No role types selected --</p> : <p className="textTagsHere">
                                        {studentsDetailsClick?.role_type
                                            ? roleTypeData
                                                .filter(item => {
                                                    const roleTypeIds = JSON.parse(studentsDetailsClick?.role_type)?.role_type_ids;
                                                    return roleTypeIds?.includes(item.id);
                                                })
                                                .map(item => item.name)
                                                .join(", ")
                                            : "-"}
                                    </p>}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style2">Allowed Activity types</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '300px' }}>
                                    <p className="label-grey-text textTagsHere">Activity mapped:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                    {/* {JSON.parse(studentsDetailsClick?.role_type)?.role_type_ids.length === 0 ? <p className="label-grey-text textTagsHere">-- No role types selected --</p> : <p className="textTagsHere">
                                        {studentsDetailsClick?.role_type
                                            ? roleTypeData
                                                .filter(item => {
                                                    const roleTypeIds = JSON.parse(studentsDetailsClick?.role_type)?.role_type_ids;
                                                    return roleTypeIds?.includes(item.id);
                                                })
                                                .map(item => item.name)
                                                .join(", ")
                                            : "-"}
                                    </p>} */}
                                    {JSON.parse(studentsDetailsClick?.allowed_activity_type)?.allowed_activity_type.length === 0 ? <p className="label-grey-text textTagsHere">-- No Activity types selected --</p> : <p className="textTagsHere">
                                        {studentsDetailsClick?.allowed_activity_type
                                            ? activityTypeData
                                                .filter(item => {
                                                    const roleTypeIds = JSON.parse(studentsDetailsClick.allowed_activity_type).allowed_activity_type;
                                                    return roleTypeIds.includes(item.id);
                                                })
                                                .map(item => item.act_type_name)
                                                .join(", ")
                                            : "-"}
                                    </p>}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style2">Session Details</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "16px", width: '300px', cursor: "pointer" }} onClick={() => handleStudentsDetailsClick(studentsDetailsClick.mentor_id, "All")}>
                                <p className="label-grey-text textTagsHere">Total Allowed:</p>
                                <p className="providingSpaceForSubDetailsRed" style={{ margin: "8px 8px 8px 65px" }}>{JSON.parse(studentsDetailsClick.mentor_status).total}</p>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "16px", width: '300px', cursor: "pointer" }} onClick={() => handleStudentsDetailsClick(studentsDetailsClick.mentor_id, "Taken")}>
                                <p className="label-grey-text textTagsHere">Sessions Taken:</p>
                                <p className="providingSpaceForSubDetails" style={{ margin: "8px 8px 8px 46px" }}>{studentsDetailsClick.session_done}</p>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "16px", width: '300px', cursor: "pointer" }} onClick={() => handleStudentsDetailsClick(studentsDetailsClick.mentor_id, "Missed")}>
                                <p className="label-grey-text textTagsHere">Sessions Missed:</p>
                                <p className="providingSpaceForSubDetails" style={{ margin: "8px 8px 8px 40px" }}>{JSON.parse(studentsDetailsClick.mentor_status).missed}</p>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "16px", width: '300px', cursor: "pointer" }} onClick={() => handleStudentsDetailsClick(studentsDetailsClick.mentor_id, "Upcoming")}>
                                <p className="label-grey-text textTagsHere">Upcoming Sessions:</p>
                                <p className="providingSpaceForSubDetails" style={{ margin: "8px 8px 8px 20px" }}>{studentsDetailsClick.upcoming_session}</p>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "16px", width: '300px', cursor: "pointer" }} onClick={() => handleStudentsDetailsClick(studentsDetailsClick.mentor_id, "Rescheduled")}>
                                <p className="label-grey-text textTagsHere">Sessions Rescheduled:</p>
                                <p className="providingSpaceForSubDetails" style={{ margin: "8px 8px 8px 0px" }}>{JSON.parse(studentsDetailsClick.mentor_status).rescheduled_by_mentor}</p>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "16px", width: '300px', cursor: "pointer" }} onClick={() => handleStudentsDetailsClick(studentsDetailsClick.mentor_id, "Cancelled")}>
                                <p className="label-grey-text textTagsHere">Sessions Cancelled:</p>
                                <p className="providingSpaceForSubDetails" style={{ margin: "8px 8px 8px 20px" }}>{JSON.parse(studentsDetailsClick.mentor_status).cancelled}</p>
                            </div>

                            {/* </div> */}
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style2">Activity Readiness Guide</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '300px' }}>
                                    <p className="label-grey-text textTagsHere">Competed:</p>
                                    <p className="label-grey-text textTagsHere">Incomplete:</p>

                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                    {JSON.parse(studentsDetailsClick.activity_type).activity_type_ids.length === 0 ? <p className="label-grey-text textTagsHere">-- No completed activity types --</p> : <p className="textTagsHere" style={{ margin: "8px 8px 8px 20px" }}>{studentsDetailsClick?.activity_type
                                        ? activityTypeData
                                            .filter(item => {
                                                const roleTypeIds = JSON.parse(studentsDetailsClick.activity_type).activity_type_ids;
                                                return roleTypeIds.includes(item.id);
                                            })
                                            .map(item => item.act_type_name)
                                            .join(", ")
                                        : "-"}</p>}

                                    <p className="textTagsHere" style={{ margin: "8px 8px 8px 20px" }}>{studentsDetailsClick?.activity_type
                                        ? activityTypeData
                                            .filter(item => {
                                                const roleTypeIds = JSON.parse(studentsDetailsClick.activity_type).activity_type_ids;
                                                return !roleTypeIds.includes(item.id);
                                            })
                                            .map(item => item.act_type_name)
                                            .join(", ")
                                        : "-"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


