import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-bootstrap/Modal';
import "./ModelViewStyle.css"
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'

export default function ModalView(props) {
    // const [formData, setFormData] = useState({})
    console.log("Opening the 3rd Eye")

    const rowsData = useContext(RowsContext);
    console.log("Rows data is:", rowsData);
    const { showModalView, setshowModalView, from_table, headCells, path, spec_data } = props
    const [formFill, setformFill] = useState({})


    console.log("Data collected is:", from_table)
    console.log("Received spec_data is :", spec_data)

    let filtered_data = JSON.parse(JSON.stringify(rowsData)).filter(item => item.id === from_table.id);
    if (path === "/MentorDetails") {
        filtered_data = JSON.parse(JSON.stringify(rowsData)).filter(item => item.user_id === from_table.id);
    }

    let filtered_spec = {};
    if (path === "/RoleType") {
        filtered_spec = spec_data.filter((item) => item.id === filtered_data[0]?.specialization_id)
        console.log("Specilization data is filtered:", filtered_spec)
        if (filtered_data[0] !== undefined) {
            filtered_data[0]["Specialization_Name"] = filtered_spec[0]?.name

        }
    }

    console.log("Full data now is :", filtered_data);


    // console.log("filtered data form view", filtered_data);

    // console.log("Header Cells collected are:", headCells)


    // console.log("filtered_data is ", filtered_data);
    // // console.log("filtered_data id is ", filtered_data[0].id);
    // console.log("filtered_data name is ", filtered_data[0].name);
    // console.log("Path of the File is", path);

    if (path === "/ActivityType") {
        filtered_data = filtered_data.map(({ id, act_type_name, type, description }) => ({
            // ["Id"]: id,
            ['Name']: act_type_name,
            ['Type']: type,
            ['Descritption']: description
        }))
    }
    else if (path === "/UserType") {
        filtered_data = filtered_data.map(({ id, name }) => ({
            // ["Id"]: id,
            ['Name']: name,
        }))
    }
    else if (path === "/SpecilizationType") {
        filtered_data = filtered_data.map(({ name, id }) => ({
            // ["Id"]: id,
            ['Name']: name,
        }))
    }
    else if (path === "/JD") {
        filtered_data = filtered_data.map(({ id, company_name, role_id }) => ({
            // ["Id"]: id,
            ['Company Name']: company_name,
            ['Role Name']: role_id,
        }))
    }
    else if (path === "/ProgramType" || path === "/BatchType") {
        filtered_data = filtered_data.map(({ id, name, }) => ({
            // ["Id"]: id,
            ['Name']: name,
        }))
    }
    else if (path === "/RoleType") {
        filtered_data = filtered_data.map(({ id, name, Specialization_Name }) => ({
            ['Name']: name,
            ['Specialization Name']: Specialization_Name,
        }))
    }
    else if (path === "/CollegeData") {
        filtered_data = filtered_data.map(({ id, college_name, college_code }) => ({
            // ["Id"]: id,
            ['College Name']: college_name,
            ['College Code']: college_code,
        }))
    }
    else if (path.includes("/StudentData")) {
        filtered_data = filtered_data.map(({ email, roll_no, first_name, last_name, ph_no, }) => ({
            ["Email"]: email,
            ['College Name']: roll_no,
            ['First Name']: first_name,
            ['Last Name']: last_name,
            ['Phone Number']: ph_no,

        }))
    }
    else if (path.includes("/MentorDetails")) {
        filtered_data = filtered_data.map(({ first_name, last_name, ph_no, email_id }) => ({
            ["First Name"]: first_name,
            ['Last Name']: last_name,
            ['Phone Number']: ph_no,
            ['Email Id']: email_id,
        }))
    }

    const onHide = () => {
        setshowModalView(false)
    }

    function HandleIt(props) {
        return (
            <Modal
                show={props.show}
                onHide={props.onHide}
                className="FileUploadModal"
                // {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", margin: "0 auto" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    // top: '50%',
                    left: '60%',
                    height: 'auto',
                    top: '0px',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '600px',
                    minWidth: '450px',
                    minHeight: '450px'
                }}>
                    <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col', justifyContent: 'center' }}>

                        <div className='row'>
                            {/* header */}
                            <div className="top-header div-form-container" >
                                <CloseIcon className="top-header-close" onClick={(e) => onHide(e)} />
                                <p className="top-header-title">Data</p>
                            </div>
                            {filtered_data.map(object => {
                                return (
                                    <React.Fragment>
                                        {Object.entries(object).map(([key, value]) => {
                                            return (
                                                <div className="form-group div-form-container">
                                                    <label className="form-labels">{key ? key : ""}</label>
                                                    <input type="text-box" className="form-control form-input-text" disabled={true} placeholder="Example input" value={value ? value : ""} />
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <div style={{ margin: 'auto', position: 'absolute', marginLeft: '12%', marginTop: '8%' }}>
            <HandleIt
                show={showModalView}
                onHide={() => setshowModalView(false)}
            />
        </div>
    )
}


