import React from 'react'
import { Box, Button, Input, Tooltip, Typography, Modal, TextField, Autocomplete, Chip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Checkbox from '@mui/material/Checkbox';
import { textAlign } from '@mui/system';
import { Color } from '../../../GlobalStyles';

const MajorsAndMinorsModal = (props) => {
    const { postingOpportunityDetails } = props

    const handleChange = (event, values) => {
        console.log("values", values);

        const newValues = values
            .map(value => value.split(',').map(v => v.trim()))
            .flat()
            .map(value => ({
                major: value,
                minor: value,
                is_delete:false
            }));

        const currentValues = props.majorsAndMinors || [];
        const filteredNewValues = newValues.filter(newValue =>
            !currentValues.some(existingValue => existingValue.major === newValue.major)
        );

        const updatedValues = [...currentValues, ...filteredNewValues];
        props.setMajorsAndMinors(updatedValues);
    };

    console.log("postingOpportunityDetails in modal", postingOpportunityDetails);
    return (
        <div>
            <Modal open={props.modalOpen} onClose={() => props.handleModalClose("Cancel")}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', padding: '2rem', borderRadius: "40px", display: 'flex', flexDirection: "column", }}>
                    <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralDarkGrey}`, fontSize: "20px" }}>Student Specializations of {props.batch_name}</Typography>
                    <Box className="PreviousAndSubmit">
                        <Autocomplete
                            multiple
                            autoComplete="off"
                            placeholder='Type Majors and Minors...'
                            options={[]}
                            value={props.majorsAndMinors.filter(item => !item.is_delete).map(item => item.major)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                props.majorsAndMinors.map((item, index) => {
                                    if (item.is_delete) return null;
                                    return (
                                        <Chip
                                            key={index}
                                            label={item.major}
                                            {...getTagProps({ index })}
                                            onDelete={() => props.handleDeleteSpecialization(index)}
                                        />
                                    );
                                })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            onChange={handleChange}
                        />
                    </Box>
                    <Typography sx={{ marginBottom: "1rem", marginTop: "1rem", width: "550px", fontSize: "14px" }}><span style={{ color: "#f5a536" }}>Note:</span> Students will see only the above-mentioned data for Majors/Minors in PlaceO profile. Editing this once the batch has started publishing jobs on PlaceO is NOT recommended </Typography>

                    <div
                        className="PreviousAndSubmit"
                        style={{
                            justifyContent: "space-between",
                            width: "100%",
                            display: "flex",
                            flexDirection: props.Mobile ? "column" : "row",
                        }}
                    >
                        <button
                            name="previous-button"
                            style={{ height: '40px', margin:0 }}
                            className="Previousbutton"
                            onClick={() => props.handleModalClose("Cancel")}
                        >
                            Cancel
                        </button>
                        <button
                            name="next-button"
                            className="Submitbutton"
                            style={{

                                height: '40px'
                            }}
                            onClick={() => props.handleCloseApplications()}
                        >
                            Confirm
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default MajorsAndMinorsModal
