import { Box, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Color, DivCenter } from "../../../../../GlobalStyles";

export const ViewDetailsModal = ({ open, onClose, data }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '1px solid #000',
        border: 'none',
        borderRadius: '20px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ ...style, height: 'auto', width: '700px', background: 'white' }}>
                <Box sx={{ width: '100%', height: '20%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '32px' }}>{data["Company Name"]}</Typography>
                    <IconButton
                        onClick={onClose}
                        color="inherit"
                        aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ ...DivCenter, width: '100%', height: '80%', mt: '15px', flexDirection: 'column', justifyContent: 'flex-start', gap: '16px', overflowY: 'auto' }}>
                    {Object.keys(data).map((data_key, data_key_index) => {
                        if (data_key !== 'Company Name') {
                            return (
                                <Box key={data_key_index} sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>{data_key}: </Typography>
                                    <Typography sx={{ width: '70%', color: Color.neutralBlack, fontSize: '16px' }}>{data[data_key]}</Typography>
                                </Box>
                            )
                        }
                    })}
                </Box>

            </Box>
        </Modal>
    )
}