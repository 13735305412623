import CloseIcon from '@mui/icons-material/Close';
import { MenuItem, Select, Chip } from "@mui/material";
import { saveAs } from "file-saver";
import Cookies from 'js-cookie';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from 'react-router-dom';
import CollegeData from '../../../CollegeDataServices/httpCollege';
import Service from '../../../services/httpService';
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
import TopBar from '../../reusables/TopBar/Topbar';
import "../OngoingEvents/OngoingEvents.css";
import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";

export default function AdminsOfCollege(props) {
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState([])
  const CC_service = new CCService()
  // batch multiselect data
  const [batchData, setBatchData] = useState([])
  const [batch, setBatch] = useState([])
  const [jd_batch_id, setJd_batch_id] = useState([]);
  const { college_id, college_name } = props
  const [batch_type_ids, setBatch_type_ids] = useState([]);
  const [batch_select_data, setBatch_select_data] = useState([])
  const [formInputs, setFormInputs] = useState([{ first_name: "", last_name: "", ph_no: "", email: "", user_group_ids: "", college_id: college_id, designation: "", level_id: null }])
  const [from_table, setFrom_table] = useState();
  const collegeData = new CollegeData()
  const location = useLocation();
  const [csvData, setCsvData] = useState(null)
  const [selectedSpecializationValues, setSelectedSpecializationValues] = useState([])
  // const [count, setCount] = useState()
  const service = new Service()

  const [allLevels, setAllLevels] = useState([])

  console.log("college id", college_id);
  //get current date and time
  const currentDate = new Date();




  let count = 0;
  const headCells = [

    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Second Name',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Designation',
    },
    {
      id: 'heading2',
      numeric: 'right',
      disablePadding: false,
      label: 'Level',
    }

  ];


  // getting batch data
  const batchGetter = async () => {

    try {
      const res = await collegeData.get(`/userGroup/byCollege?collegeId=${college_id}`)
      console.log("Response from backend is11:", res.data)
      setBatchData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  // batch data manipulator
  const data_manupulator_batch = (batch) => {

    let temp = batch;
    temp = temp.map(bat => {
      bat.key = bat.user_group_name;
      bat.id = bat.id;

      delete bat.user_group_name;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setBatch(temp)
  }

  // multi select on select method
  const onSelect_batch = (e) => {
    console.log("Added an item to multi select - batch -", e)
    setBatch_select_data(e);
    let temp = JSON.parse(JSON.stringify(e));
    temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
    setFormInputs({ ...formInputs, user_group_ids: temp })
    console.log("temp inside onSelect:", temp)
  }

  // multiselect on remove method
  const onRemove_batch = (e) => {
    console.log("Removed an item to multi select")
    setBatch_select_data(e);
    let temp = JSON.parse(JSON.stringify(e));
    temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
    setFormInputs({ ...formInputs, user_group_ids: temp })
    console.log("temp inside onSelect:", temp)

  }

  console.log("selected batch data", batch_select_data, batch);


  // delete request
  const handleDelete = async (jd_id, index) => {
    console.log("delete", jd_id)
    let list = [...rowsData];

    console.log("listtttt1", list);
    CC_service
      .delete("/collegeAdmin", {
        data: { "user_id": jd_id }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list.splice(index, 1);
        setRowsData(list)
        setBatchData(list)
        console.log("listtttt2", list);

      })
      .catch((err) => {
        console.log("Errog Msg: ", err);

        console.log(err.response.data.message)
        window.alert(err.response.data.message)

      });
  }



  const handleDeleteMul = (id, index) => {
    console.log("delete", index)
    let list = [...rowsData];
    console.log("ids", id);
    console.log("listtttt1", list);


    service
      .delete(`${process.env.REACT_APP_RD_API_URL}/superAdmin/newstudent/BulkUpload`, {
        data: { "emails": id }
      })
      .then((res) => {
        console.log("Response from backend is", res);
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        list = list.filter((item) => id.indexOf(item.email) < 0);
        setRowsData(list)
        console.log("listtttt2", list);
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
      });
  }
  const data_getter = (name, id, index, activity) => {
    console.log("Dude here's your name:", name);
    console.log("Dude here's your id:", id);
    console.log("Dude here's your index:", index);

    if (name === "delete_mul") {
      console.log("inside delete mul if")
      handleDeleteMul(id, index)
    }
    else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
      handleDelete(id, index);
    }
    if (activity === "add") {
      console.log("UserType ~ activity", activity)
      setFormInputs({})
    }


    setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
  }

  const getLevel = async () => {
    const allLevel = await CC_service.get(`/po/acl/allAdminLevel?college_id=${college_id}`)
    setAllLevels(allLevel.data)
    console.log("Ndkjjf", allLevel)
  }

  // get request
  useEffect(() => {

    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //college getter
      let student = await batchGetter();
      let adminInfo = await studentDataGetter()
      await getLevel()
      // forming the data
      console.log("odonil", { student, adminInfo })
      data_manupulator_batch(student);
    }
    handleEffect();
  }, [])

  const studentDataGetter = async () => {
    try {
      const res = await service.get(`${process.env.REACT_APP_RD_API_URL}/collegeAdmin?college_id=${college_id}`)
      console.log("admins data", res.data)
      const parsedData = res.data.map(item => {
        // Parse the 'ug_id' property of each item
        const parsedUGID = JSON.parse(item.ug_id);
        // Return a new object with the parsed 'ug_id'
        return {
          ...item,
          ug_id: parsedUGID
        };
      });
      setRowsData(parsedData);
      // setRowsData([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }
  console.log("rows data", rowsData);


  useEffect(() => {
    const setting_data = () => {
      if (from_table?.index === undefined) {
        console.log("Inside if of useEffect")
        setFormInputs({})
        setBatch_select_data([])
        setSelectedSpecializationValues([])
        setBatch(batch)
      }
      else {
        //setting values for Edit Modal
        console.log("Inside else of useEffect", formInputs)
        console.log({ rowsData })
        const user_id = rowsData[from_table?.index]?.ug_id?.user_group_ids
        console.log("user ids", user_id);
        setFormInputs({ ...formInputs, email: rowsData[from_table?.index]?.email_id, first_name: rowsData[from_table?.index]?.first_name, last_name: rowsData[from_table?.index]?.last_name, ph_no: rowsData[from_table?.index]?.ph_no, designation: rowsData[from_table?.index]?.designation, user_group_ids: rowsData[from_table?.index]?.ug_id?.user_group_ids, college_id: parseInt(college_id), level_id: rowsData[from_table?.index]?.level_id })
        const find_batch = batch.filter((ele) => user_id.includes(ele.id));
        console.log("find batch", find_batch.map((item) => item.id));
        setSelectedSpecializationValues(find_batch.map((item) => item.id))
        setBatch_select_data(find_batch)
        setBatch(batch)
      }
    }
    setting_data();
  }, [from_table])

  // console.log("formInputs...", JSON.parse(rowsData[from_table?.index]?.ug_id).user_group_ids);
  console.log("batches...", batch);

  // post request
  const handlePostRequest = async () => {
    let data = {
      college_id: parseInt(college_id),
      designation: formInputs.designation,
      email: formInputs.email,
      first_name: formInputs.first_name,
      last_name: formInputs.last_name,
      ph_no: formInputs.ph_no,
      ug_id: { user_group_ids: selectedSpecializationValues },
      level_id: formInputs.level_id

    }

    console.log("User TYpe id not found, sending teh post request", data);
    await service
      .post(`${process.env.REACT_APP_RD_API_URL}/collegeAdmin`, data)
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")


        let temp = JSON.parse(JSON.stringify(rowsData));
        console.log("stringify data", temp);
        temp = ([{
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          email: formInputs.email,
          ph_no: formInputs.ph_no,

          college_id: parseInt(college_id),

          ug_id: { user_group_ids: selectedSpecializationValues },
          signup_platform: "CareerCarve",
          designation: formInputs.designation,
          level_id: formInputs.level_id
        }, ...temp])
        setRowsData(temp)
      })
      .catch((err) => {
        console.log("Errog Msg: ", err);
        window.alert(err.response.data.message)
      });
  }

  const handlePutRequest = async () => {
    console.log("")
    //get request here
    service
      .put(`${process.env.REACT_APP_RD_API_URL}/collegeAdmin`, {
        existingEmail: rowsData[from_table?.index].email,
        first_name: formInputs.first_name,
        last_name: formInputs.last_name,
        ph_no: formInputs.ph_no,
        email: formInputs.email,
        ug_id: { user_group_ids: selectedSpecializationValues },
        college_id: formInputs.college_id,
        designation: formInputs.designation,
        level_id: formInputs.level_id
      })
      .then((res) => {
        // console.log("Response from backend is", res.data);
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
        let temp = JSON.parse(JSON.stringify(rowsData));
        console.log("Put temp value:", temp)
        temp[from_table?.index].first_name = formInputs.first_name
        temp[from_table?.index].last_name = formInputs.last_name
        temp[from_table?.index].ph_no = formInputs.ph_no

        temp[from_table?.index].ug_id = { user_group_ids: selectedSpecializationValues }
        temp[from_table?.index].designation = formInputs.designation
        temp[from_table?.index].level_id = formInputs.level_id
        setRowsData(temp)
      })
      .catch((err) => {
        // console.log("Errog Msg: ", err);
        window.alert(err.response.message)
      });
  }

  const Modal_Durations = (show, setShow, Mode) => {
    const modalSubmit = () => {
      console.log("from table in college data", from_table)
      if (from_table.index === undefined)
        handlePostRequest();
      else
        handlePutRequest();
      setShow(false)
    }
    const onHide = () => {
      setShow(false)
    }


    const handleChange = (e, index) => {
      //Logs for entering values
      console.log("name from form  is:", e.target.name);
      console.log("code from form  is:", e.target.value);

      //Conditions for storing values
      if (e.target.name === "email") {
        setFormInputs({ ...formInputs, email: e.target.value })
      }
      else if (e.target.name === "first_name") {
        setFormInputs({ ...formInputs, first_name: e.target.value })
      }
      else if (e.target.name === "last_name") {
        setFormInputs({ ...formInputs, last_name: e.target.value })
      }
      else if (e.target.name === "ph_no") {
        setFormInputs({ ...formInputs, ph_no: e.target.value })
      }
      else if (e.target.name === "designation") {
        setFormInputs({ ...formInputs, designation: e.target.value })
      }
    }
    console.log("forminputs", formInputs);

    const handleChangeSpecializationOptions = (event) => {
      setSelectedSpecializationValues(event.target.value);
      setFormInputs({ ...formInputs, user_group_ids: event.target.value })
    };

    const handleDeleteSpecializationOptions = (value) => {
      console.log("delete clicked");
      // event.stopPropagation();
      setSelectedSpecializationValues((prevValues) =>
        prevValues.filter((selectedValue) => selectedValue !== value)
      );

    };

    //render
    return (
      <Modal
        show={show}
        onHide={() => onHide()}
        className="FileUploadModal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
      >
        <Modal.Body style={{
          position: 'absolute',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          lineHeight: '1.4',
          padding: '14px 28px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          maxWidth: '95%',
          minWidth: '500px',
          minHeight: '300px'
        }}>
          <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>
            <div className='right-side-buttons-college row'>
              <div className="headerComp" >
                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                <p className="compTitle">College Admin Data</p>
              </div>
              <div className="form-group">
                <label className="inputLabel">First Name<span>*</span></label>
                <input name="first_name" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.first_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Last Name<span>*</span></label>
                <input name="last_name" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.last_name} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Email<span>*</span></label>
                <input name="email" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.email} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">Phone Number<span>*</span></label>
                <input name="ph_no" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.ph_no} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group ">
                <label className="inputLabel">Batch<span>*</span></label>
                {/* 
                <Multiselect
                  displayValue="key"
                  selectedValues={batch_select_data}
                  onRemove={(e) => { onRemove_batch(e) }}
                  onSelect={(e) => { onSelect_batch(e) }}
                  options={batch}
                /> */}
                <FormControl fullWidth>
                  <Select
                    sx={{
                      minHeight: "40px",
                    }}
                    labelId="multiselect-label"
                    id="multiselect"
                    multiple
                    value={selectedSpecializationValues}
                    onChange={handleChangeSpecializationOptions}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    renderValue={(selected) => (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '5px', 
                        }}>
                        {selected.map((value) => (
                          <Chip onMouseDown={(event) => {
                            event.stopPropagation();
                          }} key={value} label={batch.find((spec) => spec.id === value)?.key} onDelete={() => handleDeleteSpecializationOptions(value)} />
                        ))}
                      </div>
                    )}
                  >
                    {batch.map((spec) => (
                      <MenuItem key={spec.id} value={spec.id}>
                        {spec.key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="form-group">
                <label className="inputLabel">Designation<span>*</span></label>
                <input name="designation" type="text" disabled={Mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.designation} onChange={(e) => handleChange(e)} />
              </div>

              <div className="form-group">
                <label className="inputLabel">Designation<span>*</span></label>

                <div>

                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formInputs.level_id}

                    onChange={(e, value) => setFormInputs({ ...formInputs, level_id: e.target.value })}
                  >
                    {allLevels.map((item) => <MenuItem value={item.id}>{item.level_name}</MenuItem>)}

                  </Select>
                </div>
              </div>


              {Mode === "View" ? <></> : (<div className=" btn-comp " style={{ marginTop: "4%", marginLeft: '0' }}>
                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
              </div>)}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }





  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is:  ", rowsData);
    // Simulate a button click after 2 seconds
    const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


  }
  const downloadCSVFormat = () => {
    const hardcodedRow = ['Email', 'Roll Number', 'First Name', 'Last Name', 'Phone Number'];
    const csvData = `${hardcodedRow.join(',')}\n`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Student_Data_Bulk_Upload_Format_CSV_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  }


  return (<div >
    <RowsContext.Provider value={rowsData}>
      <TopBar />
      <SideNavigationBar />

      <EnhancedTable process_data={[college_name]} data_getter={data_getter} mainFile_modal={Modal_Durations} headCells={headCells} count={count} fileDownload={fileDownload} downloadCSVFormat={downloadCSVFormat} path={window.location.pathname} />

    </RowsContext.Provider>
    {/* <input type="file" onChange={handleCsvUpload} /> */}
  </div>
  )
}
