import React, { useEffect, useState } from 'react'
import Loader, { ActivityLoader, SmallPageLoader } from '../../../reusables/Loader/Loader'
import { Alert, Box, Button, CircularProgress, Fade, IconButton, InputAdornment, MenuItem, Modal, TextField, Typography } from '@mui/material'
import { Color, DivCenter, DivRight, modalBoxStyle, styles } from '../../../../GlobalStyles'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import { Add, AddOutlined, ArrowBackIos, ArrowForwardIos, Clear, Close, Done, EditOutlined, GetApp, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, PlayArrow, Search, UploadFile } from '@mui/icons-material'
import { DeadlineCalendarModal, DeadlineTimeModal } from '../../../Reusable_functions/DeadlineTimeCalendarModal/DeadlineTimeCalendarModal'
import { DialogueBox } from '../../../Reusable_functions/DialogueBox/DialogueBox'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';

const LaunchScheduleSlots = ({ process_id, slot_count_mapping, onStepChange, handlementor, slot, mentor_id, mentor_name }) => {
    console.log(" handlementor, slot, mentor_id, mentor_name", handlementor, slot, mentor_id, mentor_name);
    const service = new CCService();
    const [loading, setLoading] = useState({ page: false, activity: false });
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [currentDay, setCurrentDay] = useState();
    const slotType = ['Morning', 'Afternoon', 'Evening'];
    const [selectedSlot, setSelectedSlot] = useState({ slot_start_time: null, slot_end_time: null })
    const [reviewCount, setReviewCount] = useState(0);
    const [generalInterviewCount, setgeneralinterviewcount] = useState(0);
    const [submitSnack, setSubmitSnack] = useState({
        open: false,
        state: ""
    });
    const [reviewClashModal, setReviewClashModal] = useState({
        open: false,
        onClose: () => { setReviewClashModal((prev) => ({ ...prev, open: false })) },
        extraData: { heading: 'Oops! It looks like there is a timing clash to schedule the mock interview.', subheading: "Click on the specific mentor to change the slot.", slot_index: null, slot_type: '' },
        formData: {},
        flag: "",
        blueprint_index: 0,
        slot: [],
        time_of_day: "",
        reverifyFunction: () => { },
        anywayFunction: () => { }
    }
    );
    console.log("LaunchScheduleSlots ~ selectedSlot:", selectedSlot)



    //data
    const [blueprintData, setBlueprintData] = useState([{
        "session_date": null,
        "per_mentor_slot_count": "",
        "slots": [
            {
                "id": null,
                "process_id": null,
                "slot_end_time": null,
                "slot_start_time": null,
                "slot_type": ""
            },
        ]
    }]);
    console.log("LaunchScheduleSlots ~ blueprintData:", blueprintData)
    const [testData, setTestData] = useState([]);
    const [mentorWiseData, setMentorWiseData] = useState({
        open: false,
        onClose: () => setMentorWiseData((prev) => ({ ...prev, open: false })),
        extraData: { heading: 'Mentor Wise Slots' },
        data: [],
        slot: [],
        page: 1
    });
    const [mentorSlotsCountData, setMentorSlotsCountData] = useState([]);



    //modal
    const [addeditSlot, setAddeditSlot] = useState({
        open: false,
        onClose: () => { setAddeditSlot((prev) => ({ ...prev, open: false })); setSelectedSlot((prev) => ({ ...prev, slot_start_time: null, slot_end_time: null, })) },
        extraData: { heading: '', slot_index: null, slot_type: '' },
        formData: { start_time: '', end_time: '', slot_type: '' },
    })

    const [checkInterviewCount, setCheckInterviewCount] = useState({
        open: false,
        flag: "general",
        onClose: () => { setCheckInterviewCount((prev) => ({ ...prev, open: false })) },
        header: "Number of slots are less than total interviews to be conducted",
        mentor_names: []
    })


    console.log("LaunchScheduleSlots ~ addeditSlot:", addeditSlot)
    const [deadlineModalData, setDeadlineModalData] = useState({
        open: false,
        onClose: () => { setDeadlineModalData((prev) => ({ ...prev, open: false })); setSelectedSlot((prev) => ({ ...prev, slot_start_time: null, slot_end_time: null, })) },
        value: null,
        flag: '',
        extraData: {
            heading: "",
            action_button: "",
            handleAction: {},
            preloaded_time: null
        }
    })
    const [dialogue, setDialogue] = useState({
        open: false,
        onClose: () => { setDialogue((prev) => ({ ...prev, open: false })); setSelectedSlot((prev) => ({ ...prev, slot_start_time: null, slot_end_time: null, })) },
        content: "",
        actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
    });

    const [confirmation, setConfirmation] = useState({
        open: false,
        onClose: () => { setConfirmation((prev) => ({ ...prev, open: false })) },
        blueprint_index: 0,
        // content: "",
        // actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
    });

    //FUNCTIONS
    useEffect(() => {
        handleEffect();
        handleOpenMentorFromParams();
    }, []);

    const handleOpenMentorFromParams = () => {
        console.log("mentor id, mentor", {
            heading: 'Mentor Wise Slots', process_id,
            mentor: { user_id: mentor_id, name: mentor_name }
        })
        const temp_slot = JSON.parse(slot)
        console.log("temp_slot ", temp_slot)
        if (handlementor) {
            {
                setMentorWiseData((prev) => ({
                    ...prev, open: true, extraData: {
                        heading: 'Mentor Wise Slots',
                        slot: temp_slot,
                        mentor: { user_id: mentor_id, name: mentor_name }
                    }, page: 2
                }));
            }

        }
    }
    const handleEffect = async () => {
        setLoading({ ...loading, page: true });
        const sorted_slot_count_mapping = slot_count_mapping.sort((a, b) => a.session_date - b.session_date);
        setCurrentDay(sorted_slot_count_mapping[0].session_date);
        const temp_generalInterviewCount = await getTotalGeneralInterviews();
        setgeneralinterviewcount(temp_generalInterviewCount)
        for (const session of sorted_slot_count_mapping) {
            const { time_from, time_to } = getDayRangeEpoch(session.session_date);
            const blueprint_data = await getBlueprint(time_from, time_to);
            const day_zone = JSON.parse(blueprint_data.day_zone);
            if (JSON.parse(blueprint_data.slots).length) {
                const made_bluePrint_data = makeBlueprintData(JSON.parse(blueprint_data.slots), day_zone.afternoon, day_zone.evening);
                session.slots = made_bluePrint_data;
            } else {
                session.slots = generateSlots(session.session_date);
            }
        }

        setBlueprintData(sorted_slot_count_mapping);
        //get all mentors
        const mentors = await getAllMentors();
        setMentorWiseData((prev) => ({ ...prev, data: mentors }));

        //[GET] mentor slot count
        const mentor_slot_count = await getMentorSlotCount();
        setMentorSlotsCountData(mentor_slot_count);
        setLoading({ ...loading, page: false });
    }

    // Function to generate time slots
    function generateSlots(epochTime) {
        const slots = [];
        const date = new Date(epochTime);
        const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0); // Set start time to 9:00 AM
        const middayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 0); // Set midday end time to 2:00 PM
        const afternoonEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 17, 0); // Set afternoon end time to 5:00 PM
        const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 19, 30); // Set end time to 7:30 PM
        const interval = 30 * 60 * 1000; // 30 minutes in milliseconds

        for (let currentTime = startDate.getTime(); currentTime < middayEnd.getTime(); currentTime += interval) {
            const slotStartTime = currentTime;
            const slotEndTime = currentTime + interval;
            slots.push({ slot_start_time: slotStartTime, slot_end_time: slotEndTime, slot_type: "Morning" });
        }

        for (let currentTime = middayEnd.getTime(); currentTime < afternoonEnd.getTime(); currentTime += interval) {
            const slotStartTime = currentTime;
            const slotEndTime = currentTime + interval;
            slots.push({ slot_start_time: slotStartTime, slot_end_time: slotEndTime, slot_type: "Afternoon" });
        }

        for (let currentTime = afternoonEnd.getTime(); currentTime < endDate.getTime(); currentTime += interval) {
            const slotStartTime = currentTime;
            const slotEndTime = currentTime + interval;
            slots.push({ slot_start_time: slotStartTime, slot_end_time: slotEndTime, slot_type: "Evening" });
        }

        return slots;
    }

    //making from [GET] blueprint data
    const makeBlueprintData = (blueprint_slots, afternoon, evening) => {
        console.log("makeBlueprintData ~ blueprint_slots:", blueprint_slots);
        // const afternoon = 1710405000000;
        // const evening = 1710415800000;
        // const made_blueprint_data = [];
        blueprint_slots.forEach((slot, index) => {
            // slot.slot_start_time = slot["slot_start_time "]
            // delete slot["slot_start_time "]
            slot.slot_type = calculateSlotType(afternoon, evening, slot)
        })
        return blueprint_slots;
    }

    function calculateSlotType(afternoonEpoch, eveningEpoch, jsonObject) {
        // Convert epoch times to time of day (hours and minutes)
        const afternoonTime = new Date(afternoonEpoch).toLocaleTimeString('en-US', { hour12: false });
        const eveningTime = new Date(eveningEpoch).toLocaleTimeString('en-US', { hour12: false });
        const startTime = new Date(jsonObject.slot_start_time).toLocaleTimeString('en-US', { hour12: false });
        const endTime = new Date(jsonObject.slot_end_time).toLocaleTimeString('en-US', { hour12: false });

        // Extract hours and minutes from time strings
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);
        const [afternoonHour, afternoonMinute] = afternoonTime.split(':').map(Number);
        const [eveningHour, eveningMinute] = eveningTime.split(':').map(Number);

        // Compare hours and minutes to determine slot_type
        if (startHour < afternoonHour || (startHour === afternoonHour && startMinute < afternoonMinute)) {
            jsonObject.slot_type = 'Morning';
        } else if ((startHour === afternoonHour && startMinute >= afternoonMinute) || (startHour > afternoonHour && startHour < eveningHour) || (startHour === eveningHour && startMinute <= eveningMinute)) {
            jsonObject.slot_type = 'Afternoon';
        } else {
            jsonObject.slot_type = 'Evening';
        }


        // Return the updated JSON object
        return jsonObject.slot_type;
    }

    //OUTER

    const handleTakeAction = (flag, slot, index, time_of_day) => {
        console.log("handleTakeAction ~ slot:", slot)
        setSelectedSlot((prev) => ({ ...prev, slot_start_time: slot.start_time, slot_end_time: slot.end_time, }));
        if (flag) {
            //Opens Edit Slot Modal
            setAddeditSlot({ ...addeditSlot, open: true, extraData: { heading: 'Edit existing slot', slot_index: index, slot_type: time_of_day }, formData: { ...slot } });
        } else {
            //Delete Slot - Opens confirmation Modal
            setDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { label: 'Cancel', handler: () => { dialogue.onClose() }, color: Color.primary1, variant: "outlined" },
                    { label: 'Delete', handler: () => { reviewSlots(index, slot, time_of_day, "delete"); dialogue.onClose(); }, color: Color.white, background: Color.primary1, variant: "contained" }
                ],
                title: 'Delete confirmation',
                content: 'Are you sure you want to delete this slot?',
            }))
        }
    }

    const handleAddSlot = () => {
        setAddeditSlot({ ...addeditSlot, open: true, extraData: { heading: 'Add new slot', slot_index: null, slot_type: '' }, formData: { start_time: '', end_time: '', slot_type: '' }, });
    }


    const reviewSlots = async (blueprint_index, slot, time_of_day, flag = "delete") => {
        let temp_blueprintData = [...blueprintData];
        // const temp_slots = temp_blueprintData[blueprint_index].slots;
        console.log("slot:", [slot]);
        const slots_to_review = [slot].map(((item) => {
            return ({
                start_time: item.start_time,
                end_time: item.end_time
            })
        }))

        // console.log("review count:", reviewCount);
        try {
            // if (temp_blueprintData.length > reviewCount) {
            const body = {
                "process_id": process_id,
                "to_be_saved_slots": [...slots_to_review],
                "conflictKey": "deleteSlots"
            }
            const res = await service.put(`/os/superadmin/reviewMentorslot`, body);
            // window.alert("res:", res);
            const temp_data = res.data.data
            console.log("response in put :", res);
            setReviewCount((prev) => prev + 1);
            if (Object.keys(res.data.data).length < 1) {
                console.log("submit:", res.data.data)
                deleteSlot(blueprint_index, time_of_day);
                // setReviewClashModal({
                //     ...reviewClashModal,
                //     flag: "delete",
                //     open: true,
                // });
            } else {
                console.log("submit not", res)
                setReviewClashModal({
                    ...reviewClashModal,
                    formData: temp_data,
                    flag: "delete",
                    blueprint_index: blueprint_index,
                    time_of_day: time_of_day,
                    slot: slots_to_review[0],
                    open: true,
                });
            }
            // } else {
            //     handleSubmit(blueprint_index);
            // }
        } catch (err) {
            console.log("error in review:", err);
            window.alert("error");
        }


    }

    console.log("in review data:", reviewClashModal);

    const handleSubmit = async (blueprint_index) => {
        // console.log("handleSubmit ~ blueprint_index:", blueprint_index, blueprintData.length, blueprint_index === (blueprintData.length - 1))
        // const slots = blueprintData[blueprint_index].slots;
        let temp_blueprintData = [...blueprintData];
        console.log("blueprint data:", blueprintData)
        const temp_slots = temp_blueprintData[blueprint_index].slots;
        console.log("aaaaa temp_blueprintData[blueprint_index].slots")
        console.log("aaaaa temp slots in handlesubmit:", temp_slots);
        const insert_slots = temp_slots.filter((slot) => slot?.id === undefined);
        console.log("aaaaa handleSubmit ~ insert_slots:", insert_slots)
        const update_slots = temp_slots.filter((slot) => slot?.id !== undefined);
        console.log("aaaaa handleSubmit ~ update_slots:", update_slots)
        setLoading((prev) => ({ ...prev, activity: true }));
        let post_flag = 0;
        let put_flag = 0;

        //[POST] mentor slots

        if (insert_slots.length) {
            try {
                const post_body = {
                    "slot_array": insert_slots.map((blue) => ({
                        process_id: parseInt(process_id),
                        slot_start_time: blue.slot_start_time,
                        slot_end_time: blue.slot_end_time,
                    }))
                };
                console.log("aaaaa handleSubmit ~ body:", post_body);
                const post_response = await service.post(`/os/superadmin/bluePrint`, post_body);
                console.log("aaaaa handleSubmit ~ post_response:", post_response)
                if (post_response?.data?.insertedIds?.length) {
                    for (const index in insert_slots) {
                        const slot = insert_slots[index];
                        slot.id = post_response.data.insertedIds[index];
                        //find index of the slot inside the slots array of the particular session
                        const slot_index_in_session = temp_slots.findIndex((obj) => obj.slot_start_time === slot.slot_start_time);
                        if (slot_index_in_session !== -1) temp_slots[slot_index_in_session] = slot;
                    }
                    post_flag = 1;
                }
                // setSubmitSnack((prev) => ({ ...prev, state:"added5", open: true }));
            } catch (error) {
                console.error("Error while Posting slot:", error);
            }
        } else post_flag = 1;


        // //[PUT] mentot slots

        if (update_slots.length) {
            try {
                const put_body = {
                    "slot_array": update_slots.map((blue) => ({
                        id: blue.id,
                        process_id: parseInt(process_id),
                        slot_start_time: blue.slot_start_time,
                        slot_end_time: blue.slot_end_time,
                    }))
                };
                console.log("aaaaa handleSubmit ~ body:", put_body);
                await service.put(`/os/superadmin/bluePrint`, put_body);
                put_flag = 1;
                // setSubmitSnack((prev) => ({ ...prev, state:"added4", open: true }));
            } catch (error) {
                console.error("Error while Putting slot:", error);
            }
        } else put_flag = 1;

        console.log("aaaaa handleSubmit ~ post_flag && put_flag:", post_flag, put_flag)
        //Page changes
        if (post_flag && put_flag) {
            console.log("aaaaa bluprint_index, length:", blueprint_index, blueprintData.length)
            if (blueprint_index < blueprintData.length - 1) {
                setCurrentDay(blueprintData[blueprint_index + 1].session_date);
            }
            else if (blueprint_index === (blueprintData.length - 1)) {
                console.log("aaaaa insert_slots.length && !update_slots.length:", insert_slots.length, !update_slots.length)
                //no edited slots , only newly added once(first time)
                if (insert_slots.length && !update_slots.length)
                    try {
                        const res = await service.post(`/os/superadmin/mentorSlot`, { "process_id": parseInt(process_id) });
                        // onStepChange(null, 6, null);
                        handleEffect();
                    } catch (error) {
                        console.error('Error while posting add mentor slot:', error);
                    }
                else {
                    const put_body = {
                        is_edited: 0,
                        slot_array: blueprintData.flatMap(slotted =>
                            slotted?.slots.map(slot => ({
                                id: slot.id,
                                process_id: parseInt(process_id),
                                slot_start_time: slot.slot_start_time,
                                slot_end_time: slot.slot_end_time
                            }))
                        )
                    };
                    try {
                        const res = await service.put(`/os/superadmin/mentorSlot`, put_body);
                        console.log("aaaaa put done", res);
                        // setSubmitSnack((prev) => ({ ...prev, state:"added2", open: true }));
                    } catch (error) {
                        console.error('Error while posting add mentor slot:', error);
                    }
                }
            }
            else {
                window.alert('Index greater than array length');
            }
        }
        // else {
        //     setDialogue((prev) => ({
        //         ...prev,
        //         open: true,
        //         actions: [
        //             { label: 'OK', handler: () => { dialogue.onClose() }, color: Color.white, variant: "contained" },
        //         ],
        //         title: 'Error',
        //         content: `Some error has occurred 💀`,
        //     }))
        // }
        setLoading((prev) => ({ ...prev, activity: false }));
        setDialogue((prev) => ({
            ...prev,
            open: true,
            actions: [
                { label: 'OK', handler: () => { dialogue.onClose() }, color: Color.white, variant: "contained" },
            ],
            title: 'Confirmation',
            content: `Slots updated sucessfully`,
        }))

    }

    //ADJUSTING SLOTS
    // Function to adjust slots based on the changed slot
    function adjustSlots(slotsArray, updatedSlot, updateSlotIndex, slotType) {
        const adjustedSlots = JSON.parse(JSON.stringify(slotsArray)); // Create a copy of the original slots array
        console.log("adjustSlots ~ indexOfChangedSlot:", adjustedSlots);

        // if (updateSlotIndex && slotType) {
        //to adjust the slots that come after the updated slot
        const from_morning_slots = adjustedSlots;
        const from_afternoon_slots = adjustedSlots.filter((slot) => ['Afternoon', 'Evening'].includes(slot.slot_type));
        const from_evening_slots = adjustedSlots.filter((slot) => slot.slot_type === 'Evening');
        let timeDifference = 0;
        if (slotType === 'Morning') {
            timeDifference = updatedSlot.slot_end_time - from_morning_slots[updateSlotIndex].slot_end_time;
            console.log("morning_slots ~ timeDifference:", timeDifference)
            // Adjust subsequent slots
            for (let i = updateSlotIndex + 1; i < from_morning_slots.length; i++) {
                from_morning_slots[i].slot_start_time += timeDifference;
                from_morning_slots[i].slot_end_time += timeDifference;
            }
            from_morning_slots[updateSlotIndex] = updatedSlot;
            return from_morning_slots;
        } else if (slotType === 'Afternoon') {
            timeDifference = updatedSlot.slot_end_time - from_afternoon_slots[updateSlotIndex].slot_end_time;
            console.log("afternoon_slots ~ timeDifference:", timeDifference)
            // Adjust subsequent slots
            for (let i = updateSlotIndex + 1; i < from_afternoon_slots.length; i++) {
                from_afternoon_slots[i].slot_start_time += timeDifference;
                from_afternoon_slots[i].slot_end_time += timeDifference;
            }
            from_afternoon_slots[updateSlotIndex] = updatedSlot;
            return [...adjustedSlots.filter((slot) => slot.slot_type === 'Morning'), ...from_afternoon_slots];
            // } else if(from_evening_slots.length === 0){
            //     from_evening_slots[0] = updatedSlot;
            //     return [...adjustedSlots.filter((slot) => ['Morning', 'Afternoon'].includes(slot.slot_type)  && slot.id !== updatedSlot.id ), ...from_evening_slots];

            // }
        }
        else {
            console.log("updatedSlot:", updatedSlot);
            console.log("from_evening_slots:", from_evening_slots);
            timeDifference = updatedSlot?.slot_end_time - from_evening_slots[updateSlotIndex]?.slot_end_time;
            console.log("evening_slots ~ timeDifference:", timeDifference)
            // Adjust subsequent slots
            for (let i = updateSlotIndex + 1; i < from_evening_slots.length; i++) {
                from_evening_slots[i].slot_start_time += timeDifference;
                from_evening_slots[i].slot_end_time += timeDifference;
            }
            from_evening_slots[updateSlotIndex] = updatedSlot;
            return [...adjustedSlots.filter((slot) => (['Morning', 'Afternoon'].includes(slot.slot_type))), ...from_evening_slots];
        }
        // } else window.alert('No slot index or slot type found');

    }

    function readjustAddedSlots(slotsArray, addedSlot) {
        const adjustedSlots = JSON.parse(JSON.stringify(slotsArray));

        const slotsJSON = {
            "Morning": adjustedSlots.filter((slot) => slot.slot_type === 'Morning'),
            "Afternoon": adjustedSlots.filter((slot) => slot.slot_type === 'Afternoon'),
            "Evening": adjustedSlots.filter((slot) => slot.slot_type === 'Evening')
        };

        const slotType = addedSlot.slot_type;
        const targetArray = adjustedSlots.filter((slot) => slot.slot_type === slotType);

        // Add the new slot to the target array
        targetArray.push(addedSlot);

        // Sort the target array based on slot_start_time
        targetArray.sort((a, b) => a.slot_start_time - b.slot_start_time);
        console.log("readjustAddedSlots ~ targetArray:", targetArray)

        slotsJSON[addedSlot.slot_type] = targetArray;

        const final_array = [...slotsJSON['Morning'], ...slotsJSON['Afternoon'], ...slotsJSON['Evening']];
        console.log("readjustAddedSlots ~ final_array:", final_array)

        return final_array;
    }


    //Delete a slot
    const deleteSlot = (slotIndex, timeOfDay) => {
        console.log("deleteSlot ~ slotIndex, timeOfDay:", slotIndex, timeOfDay)
        let temp_blueprint = [...blueprintData];
        let slots = temp_blueprint.find((blue) => blue.session_date === currentDay).slots;
        const morning_slots = slots.filter((slot) => slot.slot_type === 'Morning');
        const afternoon_slots = slots.filter((slot) => slot.slot_type === 'Afternoon');
        const evening_slots = slots.filter((slot) => slot.slot_type === 'Evening');
        const blueprintslotType = {
            'Morning': morning_slots,
            'Afternoon': afternoon_slots,
            'Evening': evening_slots
        }
        let success_flag = 0;
        if (blueprintslotType[timeOfDay][slotIndex]?.id) {
            try {
                const delete_body = {
                    "id": blueprintslotType[timeOfDay][slotIndex].id,
                    "process_id": process_id,
                }
                service.put(`/os/superadmin/bluePrint/delete`, delete_body);
                success_flag = 1;

            } catch (error) {
                console.error("Error while deleting a slot:", error);
            }
        } else {
            success_flag = 1;
        }
        if (success_flag) {
            setSubmitSnack((prev) => ({ ...prev, state: "deleted", open: true }));
            if (timeOfDay === 'Morning') {
                morning_slots.splice(slotIndex, 1);
            } else if (timeOfDay === 'Afternoon') {
                afternoon_slots.splice(slotIndex, 1);
            } else if (timeOfDay === 'Evening') {
                evening_slots.splice(slotIndex, 1);
            }
        } else {
            setDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { label: 'OK', handler: () => { dialogue.onClose() }, color: Color.white, variant: "contained" },
                ],
                title: 'Error',
                content: `Delete Failed. Some error has occurred 💀`,
            }))
        }
        slots = [...morning_slots, ...afternoon_slots, ...evening_slots];
        temp_blueprint.find((blue) => blue.session_date === currentDay).slots = slots;
        console.log("deleteSlot ~ slots in deleteslot:", slots)
        setBlueprintData(temp_blueprint);
        setReviewClashModal({
            ...reviewClashModal,
            flag: "delete",
            open: false,
        })
        console.log("deleteSlot ~ slots in deleteslot2:", slots)
    }

    console.log("deleteSlot ~ slots in deleteslot: review modal:", reviewClashModal);

    //[GET] functions
    const getBlueprint = async (time_from, time_to) => {
        try {
            const response = await service.get(`/os/superadmin/bluePrint?process_id=${process_id}&time_from=${time_from}&time_to=${time_to}`)
            console.log("getBlueprint ~ response:", response);
            return response.data[0];
        } catch (error) {
            console.error("Error getting blueprint:", error);
        }
    }

    //all mentors [GET]
    const getAllMentors = async (searchQuery = '') => {
        try {
            // setModalLoading((prev) => ({ ...prev, searchMentor: true }));
            const response = await service.get(`/superAdmin/mentors?limit=10&page=1&college_name=[]&company_name=[]&role_name=[]&designation=[]&searchQuery=${searchQuery}`);
            if (response?.mentors) {
                return response.mentors
            }
            // setModalLoading((prev) => ({ ...prev, searchMentor: false }));
        } catch (error) {
            console.error("Error while searching mentor:", error);
            // setModalLoading((prev) => ({ ...prev, searchMentor: false }));
        }
    }

    // [GET] mentor slot count
    const getMentorSlotCount = async (mentor_id) => {
        try {
            const response = await service.get(`/os/superadmin/mentorSlotsCount?process_id=${process_id}`)
            console.log("getMentorSlotCount ~ response:", response);
            return response.mentor_slots;
        } catch (error) {
            console.error("Error getting mentor slot count:", error);
        }
    }

    const getTotalGeneralInterviews = async () => {
        try {
            const response = await service.get(`/os/superadmin/generalInterviewCount?process_id=${process_id}`)
            console.log("generalInterviewCount ~ response:", response);
            return response.totalGeneralInterview;
        } catch (error) {
            console.error("Error getting mentor slot count:", error);
        }
    }

    //OTHER functions
    const getSlotNames = (date) => {
        const d = new Date(date);
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedTime = `${(hours % 12) === 0 ? 12 : (hours % 12) < 10 ? "0" + (hours % 12) : ((hours % 12))}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
        return formattedTime;
    }

    //get time_from and time_to
    function getDayRangeEpoch(epochTime) {
        const date = new Date(epochTime);

        // Set the time to 00:00:00 for the given date
        date.setHours(0, 0, 0, 0);
        const time_from = Math.floor(date.getTime());

        // Set the time to 23:59:59 for the given date
        date.setHours(23, 59, 59, 999);
        const time_to = Math.floor(date.getTime());

        return { time_from, time_to };
    }

    //setting the epoch time in start or end time 
    const handleDeadlineTimeCalendarSave = async (value, time) => {
        console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time);

        // Extract date components and time components
        const year = value["$d"].getFullYear();
        const month = value["$d"].getUTCMonth();
        const day = value["$d"].getDate();
        const hours = parseInt(time.slice(0, 2), 10);
        const minutes = parseInt(time.slice(3), 10);

        // Create a Date object for the original time
        const date = new Date(year, month, day, hours, minutes, 0);

        // Convert to epoch time in milliseconds
        const epochTime = date.getTime();

        // Add 30 minutes in milliseconds
        const thirtyMinutesMs = 30 * 60 * 1000;
        const epochPlus30 = epochTime + thirtyMinutesMs;

        // Convert the new epoch time back to a Date object
        const datePlus30 = new Date(epochPlus30);

        // console.log("handleDeadlineTimeCalendarSave ~ end_date:", datePlus30, epochTime, date.getHours());

        // setFormInputs((prev) => ({ ...prev, slot_selection_deadline: epochTime }));

        if (deadlineModalData.flag === "start_time") {
            // console.log("in start_time edit :", epochTime, datePlus30);
            setAddeditSlot({
                ...addeditSlot,
                open: true,
                formData: {
                    ...addeditSlot.formData,
                    start_time: epochTime,
                    end_time: epochPlus30
                }
            });
        } else {
            // console.log("in end_time edit :", epochTime, datePlus30);
            setAddeditSlot({
                ...addeditSlot,
                open: true,
                formData: {
                    ...addeditSlot.formData,
                    [deadlineModalData.flag]: epochTime
                }
            });
        }

        setDeadlineModalData((prev) => ({ ...prev, open: false }));
    };


    const handleViewMentorWise = () => {
        setMentorWiseData((prev) => ({ ...prev, open: true, extraData: { heading: 'Mentor Wise Slots' }, page: 1 }))
    }

    const toggling_between_days = (flag, blueprint_index) => {
        if (flag) {
            if (blueprint_index < blueprintData.length - 1) {
                setCurrentDay(blueprintData[blueprint_index + 1].session_date);
            } else if (blueprint_index === (blueprintData.length - 1)) {
                window.alert('beb this doesnt go any further')
            }
        }
        else {
            if (blueprint_index > 0)
                setCurrentDay(blueprintData[blueprint_index - 1].session_date);
            else if (blueprint_index === 0) window.alert('beb this doesnt go any further')
        }
    }





    //MODALS


    //ADD and EDIT SLOT MODAL
    function AddEditSlotModal({ open, onClose, extraData, formData }) {
        console.log("AddEditSlotModal ~ formData:", formData)

        const modal_styles = {
            box__textfield: {
                background: Color.white,
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${Color.primary1}`,
                borderRadius: 2,
                padding: '8px',
                width: '80%',
                height: '56px'
            }
        }

        const [formInputs, setFormInputs] = useState({ ...formData });

        //FUNCTIONS
        const handleSetDeadline = (flag, slot_time) => {
            console.log("handleSetDeadline ~ flag, slot_time:", flag, slot_time, currentDay)

            setDeadlineModalData((prev) => ({ ...prev, open: true, flag: flag, extraData: { ...prev.extraData, preloaded_time: slot_time === '' ? currentDay : slot_time } }));

            setAddeditSlot({ ...addeditSlot, open: false });
        }

        const handleSubmit = async () => {
            let temp_blueprint = [...blueprintData];
            const slots = temp_blueprint.find((blue) => blue.session_date === currentDay).slots;
            let adjustedSlots = []

            //edit an existing slot
            if (![null, undefined].includes(extraData?.slot_index)) {
                const change_slot = {
                    id: formData.id,
                    slot_start_time: formInputs.start_time,
                    slot_end_time: formInputs.end_time,
                    slot_type: formInputs.slot_type
                }
                adjustedSlots = adjustSlots(slots, change_slot, extraData.slot_index, formInputs.slot_type);//slots -> all slots before change &&  change_slot -> slot after change 

                temp_blueprint.find((blue) => blue.session_date === currentDay).slots = adjustedSlots;
                setBlueprintData(temp_blueprint);
                addeditSlot.onClose();
                setSubmitSnack((prev) => ({ ...prev, state: "edited", open: true }));
            }
            //add a new slot
            else {
                const body = {
                    "process_id": process_id,
                    "to_be_saved_slots": [{
                        "start_time": formInputs.start_time,
                        "end_time": formInputs.end_time
                    }],
                    "conflictKey": "addSlots"
                }
                const res = await service.put(`/os/superadmin/reviewMentorslot`, body);
                console.log("submit in add:", Object.keys(res.data.data))
                if (Object.keys(res.data.data).length > 0) {
                    console.log("submit:", res.data.data)
                    // deleteSlot(blueprint_index, time_of_day);
                    setReviewClashModal({
                        ...reviewClashModal,
                        slot: {
                            "start_time": formInputs.start_time,
                            "end_time": formInputs.end_time
                        },
                        formData: res.data.data,
                        flag: "Add",
                        open: true,
                        anywayFunction: handleAddSubmit
                    });
                } else {
                    const add_slot = {
                        slot_start_time: formInputs.start_time,
                        slot_end_time: formInputs.end_time,
                        slot_type: formInputs.slot_type
                    }
                    adjustedSlots = readjustAddedSlots(slots, add_slot);
                    console.log("readjustAddedSlots ~ adjustedSlots:", adjustedSlots)
                    temp_blueprint.find((blue) => blue.session_date === currentDay).slots = adjustedSlots;
                    setBlueprintData(temp_blueprint);
                    addeditSlot.onClose();
                    setSubmitSnack((prev) => ({ ...prev, state: "added1", open: true }));
                }
            }

        }

        const handleAddSubmit = async () => {
            let temp_blueprint = [...blueprintData];
            const slots = temp_blueprint.find((blue) => blue.session_date === currentDay).slots;
            let adjustedSlots = []

            const add_slot = {
                slot_start_time: formInputs.start_time,
                slot_end_time: formInputs.end_time,
                slot_type: formInputs.slot_type
            }
            console.log("AddedSlots ~ Slots:", slots)
            adjustedSlots = readjustAddedSlots(slots, add_slot);
            console.log("readjustAddedSlots ~ adjustedSlots:", adjustedSlots)


            temp_blueprint.find((blue) => blue.session_date === currentDay).slots = adjustedSlots;
            // setBlueprintData(temp_blueprint);
            addeditSlot.onClose();
        }


        return (
            <Modal open={open} onClose={onClose}>
                <Box sx={{ ...modalBoxStyle, width: '40%', minWidth: '700px', height: '300px', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Box sx={{ ...DivCenter, width: '100%', height: '20%', justifyContent: 'space-between' }}>
                        <Typography style={{ textAlign: 'center', fontSize: '24px', width: '100%', fontWeight: 500 }}>{extraData.heading}</Typography>
                        {/* <IconButton onClick={onClose}>
                            <Close />
                        </IconButton> */}
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%', height: '60%', }}>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                            <Typography style={{ textAlign: 'center' }}>Start Time</Typography>
                            <Box onClick={() => handleSetDeadline("start_time", formInputs.start_time)} sx={{
                                ...modal_styles.box__textfield, ...DivCenter,
                                cursor: formInputs.slot_selection_deadline ? 'default' : 'pointer',
                            }}>{formInputs.start_time ? getSlotNames(formInputs.start_time) : ''}</Box>
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                            <Typography style={{ textAlign: 'center' }}>End Time</Typography>
                            <Box
                                onClick={() => handleSetDeadline("end_time", formInputs.end_time)}
                                sx={{
                                    ...modal_styles.box__textfield, ...DivCenter,
                                    cursor: formInputs.slot_selection_deadline ? 'default' : 'pointer',
                                }}>{formInputs.end_time ? getSlotNames(formInputs.end_time) : ''}</Box>
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                            <Typography style={{ textAlign: 'center' }}>Slot Type</Typography>
                            <TextField select value={formInputs.slot_type} onChange={(e) => setFormInputs((prev) => ({ ...prev, slot_type: e.target.value }))} sx={{ width: '80%' }}>
                                {slotType.map((option) =>
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>)}
                            </TextField>
                        </Box>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%', height: '20%', gap: '32px' }}>
                        <Button variant='outlined' onClick={() => onClose()} sx={{ ...styles.button_styles.outlined, width: '30%' }}>Cancel</Button>
                        <Button variant='contained' onClick={() => handleSubmit()} sx={{ ...styles.button_styles.contained, width: '30%' }}>Submit</Button>
                    </Box>
                </Box>

            </Modal >
        )
    }

    function InterviewWarning({ flag, open, onClose, header, mentor_names }) {

        const modal_styles = {
            box__textfield: {
                background: Color.white,
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${Color.primary1}`,
                borderRadius: 2,
                padding: '8px',
                width: '80%',
                height: '56px'
            }
        }

        const [buttonLoader, setButtonLoader] = useState(false);
        const handleMClick = (mentor_id, mentor_name) => {
            // The URL you want to open
            const active_step = JSON.stringify({ step: 5, sub_page: 1 });
            const handlementor = "true";
            const url = `/OfflineSessions/StudentSummary?process_id=${process_id}&active_step=${active_step}&handlementor=${handlementor}&mentor_id=${JSON.stringify(mentor_id)}&mentor_name=${(mentor_name)}`;
            // Open the URL in a new tab
            window.open(url, '_blank');
        };


        console.log("MENTOR NAMES:", mentor_names)
        return (
            <Modal open={open} onClose={onClose}>
                {flag === "mentor" ?
                    <Box sx={{ ...modalBoxStyle, padding: "2rem", width: '60%', justifyContent: 'flex-start', overflow: "hidden" }}>
                        <Box sx={{ display: "flex", width: '100%', flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: '100%', height: '20%', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontSize: '24px', width: '100%', fontWeight: 500 }}><span style={{ fontSize: '24px', width: '100%', fontWeight: 500, color: "red" }}>Oops! </span>Some mentor(s) have fewer slots than needed</Typography>
                                <Typography style={{ fontSize: '16px', width: '100%', fontWeight: 400 }}>Click on a specific mentor to edit their slot</Typography>
                            </Box>
                            <Box sx={{ cursor: "pointer" }} onClick={onClose}>
                                <CloseIcon />
                            </Box>
                        </Box>

                        <div style={{ marginTop: 24, backgroundColor: Color.gray_200.concat("11"), borderRadius: "4px", padding: "12px", display: "flex", flexDirection: "row", width: "100%", alignItems: "baseline" }}>
                            <div style={{ fontWeight: 600, width: "70%" }}>Conflicted Mentors :</div>
                            <div style={{}}>
                                {
                                    mentor_names?.map((item, index) => {
                                        return (
                                            <div style={{ cursor: "pointer", color: Color.primary1 }} onClick={() => handleMClick(item.mentor_id, item.mentor_name)}>{item.mentor_name}
                                                {(index < mentor_names?.length - 1) ? ', ' : " "}
                                            </div>)
                                    })
                                }
                            </div>
                        </div>
                        <Box sx={{ ...DivCenter, width: '100%', height: '20%', gap: '32px', marginTop: "24px" }}>
                            <Button variant='outlined' onClick={() => { setButtonLoader(true); handleRefreshMentorCount(); checkCountInterviewSlots(); setButtonLoader(false); }} sx={{ ...styles.button_styles.contained, width: '50%' }}>
                                {buttonLoader ?
                                    <CircularProgress size={24} sx={{ color: "white" }} /> :
                                    "Reverify slots & Confirm"}</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ ...modalBoxStyle, width: '35%', minWidth: '300px', height: '200px', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Box sx={{ ...DivCenter, width: '100%', height: '20%', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'center', fontSize: '24px', width: '100%', fontWeight: 500, color: "red" }}>{"Warning"}</Typography>
                        </Box>
                        <Box sx={{ ...DivCenter, width: '100%', height: '60%', }}>
                            <Typography style={{ textAlign: 'center', fontSize: '20px', width: '100%', fontWeight: 400 }}>{header}</Typography>
                        </Box>
                        <Box sx={{ ...DivCenter, width: '100%', height: '20%', gap: '32px' }}>
                            <Button variant='contained' onClick={() => onClose()} sx={{ ...styles.button_styles.contained, width: '50%' }}>OK</Button>
                            {/* <Button variant='contained' onClick={() => handleSubmit()} sx={{ ...styles.button_styles.contained, width: '30%' }}>Submit</Button> */}
                        </Box>
                    </Box>
                }
            </Modal >
        )
    }

    function ReviewBeforeSubmit({ flag, open, onClose, extraData, formData, blueprint_index, time_of_day, slot, anywayFunction }) {
        console.log("ReviewBeforeSubmit ~ formData:", formData)
        const navigate = useNavigate();
        const [buttonLoader, setButtonLoader] = useState(false);
        const modal_styles = {
            box__textfield: {
                background: Color.white,
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${Color.primary1}`,
                borderRadius: 2,
                padding: '8px',
                width: '80%',
                height: '56px'
            }
        }

        const [formInputs, setFormInputs] = useState({ ...formData });

        //FUNCTIONS
        const handleSetDeadline = (flag, slot_time) => {
            console.log("handleSetDeadline ~ flag, slot_time:", flag, slot_time, currentDay)
            setDeadlineModalData((prev) => ({ ...prev, open: true, flag: flag, extraData: { ...prev.extraData, preloaded_time: slot_time === '' ? currentDay : slot_time } }));
            setAddeditSlot({ ...addeditSlot, open: false });
        }

        const handleClick = (slot, mentor_id, mentor_name) => {
            // The URL you want to open
            const active_step = JSON.stringify({ step: 5, sub_page: 1 });
            const handlementor = "true";
            const url = `/OfflineSessions/StudentSummary?process_id=${process_id}&active_step=${active_step}&handlementor=${handlementor}&slot=${JSON.stringify(slot)}&mentor_id=${JSON.stringify(mentor_id)}&mentor_name=${(mentor_name)}`;
            // Open the URL in a new tab
            window.open(url, '_blank');
        };

        const reviewforeSubmit = () => {
            let temp_blueprint = [...blueprintData];
            const slots = temp_blueprint.find((blue) => blue.session_date === currentDay).slots;
            let adjustedSlots = []

            //edit an existing slot
            if (![null, undefined].includes(extraData?.slot_index)) {
                const change_slot = {
                    id: formData.id,
                    slot_start_time: formInputs.start_time,
                    slot_end_time: formInputs.end_time,
                    slot_type: formInputs.slot_type
                }
                console.log("slots in addedit slot:", slots);
                adjustedSlots = adjustSlots(slots, change_slot, extraData.slot_index, formInputs.slot_type);//slots -> all slots before change &&  change_slot -> slot after change 
                console.log("adjustSlots ~ adjustedSlots:", adjustedSlots);
            }
            //add a new slot
            else {
                const add_slot = {
                    slot_start_time: formInputs.start_time,
                    slot_end_time: formInputs.end_time,
                    slot_type: formInputs.slot_type
                }
                adjustedSlots = readjustAddedSlots(slots, add_slot);
                console.log("readjustAddedSlots ~ adjustedSlots:", adjustedSlots)
            }
            temp_blueprint.find((blue) => blue.session_date === currentDay).slots = adjustedSlots;
            setBlueprintData(temp_blueprint);
            addeditSlot.onClose();
        }


        return (
            <Modal open={open}>
                <Box sx={{ ...modalBoxStyle, padding: "2rem", width: '60%', justifyContent: 'flex-start', overflow: "hidden" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box sx={{ ...DivCenter, width: '100%', height: '20%', flexDirection: "column", }}>
                            <Typography style={{ fontSize: '24px', width: '100%', fontWeight: 500 }}>{extraData.heading}</Typography>
                            <Typography style={{ fontSize: '16px', width: '100%', fontWeight: 400 }}>{extraData.subheading}</Typography>
                        </Box>
                        <Box sx={{ cursor: "pointer" }} onClick={onClose}>
                            <CloseIcon />
                        </Box>
                    </Box>
                    <div style={{ backgroundColor: Color.gray_200.concat("11"), borderRadius: "4px", marginTop: 24, padding: "12px", display: "flex", flexDirection: "row", width: "100%", alignItems: "baseline" }}>
                        <div style={{ fontWeight: 600, width: "70%" }}>Time Slot :</div>
                        <div style={{ color: Color.red }}>
                            {/* {new Date(slot.start_time).toLocaleTimeString('en-US', { hour12: false })}-{new Date(slot.end_time).toLocaleTimeString('en-US', { hour12: false })} */}
                            {/* 2:30 - 3:00 AM */}
                            {getSlotNames(slot.start_time)} - {getSlotNames(slot.end_time)}
                        </div>
                    </div>
                    <div style={{ marginTop: 24, backgroundColor: Color.gray_200.concat("11"), borderRadius: "4px", padding: "12px", display: "flex", flexDirection: "row", width: "100%", alignItems: "baseline" }}>
                        <div style={{ fontWeight: 600, width: "70%" }}>Conflicted Mentors :</div>
                        <div style={{}}>
                            {
                                Object.keys(formData)?.map((item, index) => {

                                    return (
                                        <div style={{ cursor: "pointer", color: Color.primary1 }} onClick={() => handleClick(slot, formData[item].mentor_id, item)}>{item}
                                            {(index < Object.keys(formData)?.length - 1) ? ', ' : " "}
                                        </div>)
                                })
                            }
                        </div>

                    </div>

                    <Box sx={{ ...DivCenter, width: '100%', height: '20%', gap: '32px', marginTop: "24px" }}>
                        <Button variant='outlined' onClick={() => { setButtonLoader(true); reviewSlots(blueprint_index, slot, time_of_day, flag) }} sx={{ ...styles.button_styles.contained, width: '30%' }}>
                            {buttonLoader ?
                                <CircularProgress size={24} sx={{ color: "white" }} /> :
                                "Reverify slots & Confirm"}</Button>
                        <Button variant='contained' onClick={() => {
                            if (flag === "Add") {
                                anywayFunction();
                            }
                            else {
                                deleteSlot(blueprint_index, time_of_day);
                            };
                            setReviewClashModal({
                                ...reviewClashModal,
                                open: false,
                            });
                        }} sx={{ ...styles.button_styles.outlined, width: '30%' }}>{flag === "Add" ? "Add anyway" : "Delete anyway"} </Button>
                    </Box>
                </Box>

            </Modal >
        )
    }
    //VIEW MENTORWISE DATA
    function MentorWiseSlots({ open, onClose, extraData, data, modal_page, slot }) {

        const [modalLoading, setModalLoading] = useState({ searchMentor: false, mentorSlots: false, activity: false })
        //data variable
        const [searchMentor, setSearchMentor] = useState('');
        const [mentorData, setMentorData] = useState([...data.map(item => ({ ...item, loading: false }))]);
        console.log("MentorWiseSlots ~ mentorData:", mentorData, open, onClose, extraData, data, modal_page,)
        const [mentorSlots, setMentorSlots] = useState([]);
        console.log("MentorWiseSlots ~ mentorSlots:", mentorSlots)
        const [mappedSpe, setMappedSpe] = useState([]);
        const [selectedMentor, setSelectedMentor] = useState({});
        const [page, setPage] = useState(modal_page);
        console.log("MentorWiseSlots ~ page:", page)
        const [currentSession, setCurrentSession] = useState(null);
        const [MWSelectedSlot, setMWSelectedSlot] = useState({});

        //modals
        const [deadlineCalendar, setDeadlineCalendar] = useState({
            open: false,
            onClose: () => setDeadlineCalendar((prev) => ({ ...prev, open: false })),
            flag: '',
            extraData: {
                preloaded_time: ''
            }
        })
        const [MWAddEditSlot, setMWAddEditSlot] = useState({
            open: false,
            onClose: () => { setMWAddEditSlot((prev) => ({ ...prev, open: false })); setMWSelectedSlot((prev) => ({ ...prev, slot_start_time: null, slot_end_time: null, })) },
            extraData: { heading: '', slot_index: null, slot_type: '' },
            formData: { start_time: '', end_time: '', slot_type: '' },
        })
        const [MWDialogue, setMWDialogue] = useState({
            open: false,
            onClose: () => { setMWDialogue((prev) => ({ ...prev, open: false })); setMWSelectedSlot((prev) => ({ ...prev, slot_start_time: null, slot_end_time: null, })) },
            content: "",
            actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
        });

        //FUNCTIONS

        useEffect(() => {
            const MWhandleEffect = () => {
                console.log("MWhandleEffect ~ extraData.mentor:", extraData?.mentor)
                if (extraData?.mentor?.user_id) {
                    setModalLoading((prev) => ({ ...prev, activity: true }));
                    handleMentorClick(extraData.mentor, null);
                    // setPage(2);
                    setModalLoading((prev) => ({ ...prev, activity: false }));
                }
            }
            MWhandleEffect();
        }, [])


        const handleChangeSearch = ({ target }) => {
            const { value } = target;
            if (!value) {
                setMentorData([...data.map(item => ({ ...item, loading: false }))]);
            }
            setSearchMentor(value);
        }
        const handleSearchMentor = async () => {
            const mentors = await getAllMentors(searchMentor);
            console.log("handleSearchMentor ~ mentors:", mentors)
            setMentorData([...mentors.map(item => ({ ...item, loading: false }))]);
        }

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSearchMentor();
            }
        };

        console.log("selected mentor - launch schedule slots:", selectedMentor);

        //all [GET]s for MENTOR-WISE are handled here
        const handleMentorClick = async (mentor, index) => {
            console.log("handleMentorClick ~ mentor:", mentor)
            if (index) mentorData[index].loading = true;
            else setModalLoading((prev) => ({ ...prev, mentorSlots: true }));
            setSelectedMentor(mentor);
            try {
                const mentor_slots = await service.get(`/os/superadmin/mentorSlot?mentor_id=${mentor.user_id ?? mentor.mentor_id}&process_id=${process_id}`);

                console.log("handleMentorClick ~ mentor_slots:", mentor_slots);
                const day_zone = JSON.parse(mentor_slots.day_zone[0].day_zone);
                const afternoon = day_zone.afternoon;
                const evening = day_zone.evening;
                const daysArray = getDaysBetweenEpochTimes(mentor_slots.mentor_details.slot_details);
                console.log("handleMentorClick ~ daysArray:", daysArray);
                if (daysArray.length < 100) {
                    const daysSlotsMap = organizeSlotsByDay(daysArray, mentor_slots.data, afternoon, evening);
                    console.log("handleMentorClick ~ daysSlotsMap:", daysSlotsMap);
                    setCurrentSession(daysSlotsMap[0].session_date);
                    setMentorSlots(daysSlotsMap);
                    setMappedSpe(mentor_slots.mentor_details.mapped_specializations);
                } else {
                    window.alert('Incorrect data - days exceeded 100');
                }

                if (index) mentorData[index].loading = false;
                else setModalLoading((prev) => ({ ...prev, mentorSlots: false }));
                setPage(2);
            } catch (error) {
                console.error("Error getting mentor slots:", error);
                if (index) mentorData[index].loading = false;
                else setModalLoading((prev) => ({ ...prev, mentorSlots: false }));
            }
        }

        function getDaysBetweenEpochTimes(epochArray) {
            const daysArray = [];
            const oneDayInSeconds = 24 * 60 * 60; // Seconds in a day

            // Convert epoch times to milliseconds
            const startMillis = epochArray[0].slot_start_time;
            const endMillis = epochArray[epochArray.length - 1].slot_start_time;

            // Calculate the number of days between the two epoch times
            const daysCount = Math.ceil((endMillis - startMillis) / (1000 * oneDayInSeconds));

            // Loop through each day and add it to the array
            for (let i = 0; i <= daysCount; i++) {
                const currentMillis = startMillis + i * (1000 * oneDayInSeconds);
                const currentDate = new Date(currentMillis);

                // Convert date back to epoch time
                const currentEpoch = Math.floor(currentMillis);
                //This is handled in case some data gets stuck in an infinite loop
                if (daysArray.length > 100) {
                    return daysArray;
                } else
                    // Add the current day to the array
                    daysArray.push({ session_date: currentEpoch });
            }

            return daysArray;
        }

        function organizeSlotsByDay(epochArray, slotsArray, afternoon, evening) {
            const filteredEpochArray = epochArray.reduce((acc, day) => {
                const slotsForDay = slotsArray.filter(slot => {
                    const dayDate = new Date(day.session_date).toLocaleDateString();
                    const slotDate = new Date(slot.slot_start_time).toLocaleDateString();
                    return dayDate === slotDate;
                });

                day.slots = makeBlueprintData(slotsForDay, afternoon, evening);

                if (day.slots.length > 0) {
                    acc.push(day);
                }

                return acc;
            }, []);

            return filteredEpochArray;
        }

        const handleAddNewDay = () => {
            setDeadlineCalendar((prev) => ({
                ...prev, open: true,
            }));
        }

        const callDialogueForMWDeleteDay = (sess_index, session_date) => {
            setCurrentSession(session_date);
            setMWDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { label: 'Cancel', handler: () => { MWDialogue.onClose() }, color: Color.primary1, variant: "outlined" },
                    { label: 'Delete', handler: () => { handleMentorWiseDeleteDay(sess_index); }, color: Color.primary1, background: Color.primary1, variant: "contained" }
                ],
                title: 'Delete confirmation',
                content: `Are you sure you want to delete ${formatConvertermonthfirst(session_date, false, false, true)}, ${dayNames[new Date(session_date).getDay()]} for this particular Mentor?`,
            }))
        }

        const handleMentorWiseDeleteDay = async (sessionIndex) => {
            let temp_mentorSlots = [...mentorSlots];
            let success_flag = 0;
            const slot_ids = temp_mentorSlots[sessionIndex].slots.map((slot) => slot?.id).filter((slot_id) => slot_id !== undefined);
            console.log("handleMentorWiseDeleteDay ~ slot_ids:", slot_ids)
            if (slot_ids.length) {
                try {
                    const delete_body = {
                        "slot_array": slot_ids
                    }
                    console.log("handleMentorWiseDeleteDay ~ delete_body:", delete_body)
                    await service.delete(`/os/superadmin/mentorSlot`, { data: delete_body });
                    success_flag = 1;
                } catch (error) {
                    console.error("Error while deleting a slot:", error);
                }
            } else {
                success_flag = 1;
            }
            if (success_flag) {
                temp_mentorSlots.splice(sessionIndex, 1);
                console.log("session date before setting:", sessionIndex)
                const current_index = sessionIndex === 0 ? sessionIndex : sessionIndex - 1;
                setCurrentSession(temp_mentorSlots[current_index]?.session_date);
                setMentorSlots(temp_mentorSlots);
                MWDialogue.onClose();
            } else {
                setMWDialogue((prev) => ({
                    ...prev,
                    open: true,
                    actions: [
                        { label: 'OK', handler: () => { MWDialogue.onClose() }, color: Color.primary1, variant: "contained" },
                    ],
                    title: 'Error',
                    content: `Delete Failed. Some error has occurred 💀`,
                }))
            }
        }

        //OTHER FUNCTIONS
        const handleMWTakeAction = (flag, slot, index, time_of_day) => {
            console.log("handleMWTakeAction ~ slot:", flag, slot, index, time_of_day)
            setMWSelectedSlot((prev) => ({ ...prev, slot_start_time: slot.start_time, slot_end_time: slot.end_time, }));
            if (flag) {
                //Opens Edit Slot Modal
                setMWAddEditSlot({
                    ...MWAddEditSlot, open: true,
                    extraData: { heading: 'Edit existing slot', slot_index: index, slot_type: time_of_day },
                    formData: { ...slot }
                });
            } else {
                //Delete Slot - Opens confirmation Modal
                setMWDialogue((prev) => ({
                    ...prev,
                    open: true,
                    actions: [
                        { label: 'Cancel', handler: () => { MWDialogue.onClose() }, color: Color.primary1, variant: "outlined" },
                        { label: 'Delete', handler: () => { MWdeleteSlot(index, time_of_day); MWDialogue.onClose() }, color: Color.primary1, background: Color.primary1, variant: "contained" }
                    ],
                    title: 'Delete confirmation',
                    content: 'Are you sure you want to delete this slot?',
                }))
            }
        }

        //MENTOR WISE DELETE SLOT
        //Delete a slot
        const MWdeleteSlot = (slotIndex, timeOfDay) => {
            console.log("deleteSlot ~ slotIndex, timeOfDay:", slotIndex, timeOfDay);

            let temp_blueprint = [...mentorSlots];
            let slots = temp_blueprint.find((blue) => blue.session_date === currentSession).slots;
            const morning_slots = slots.filter((slot) => slot.slot_type === 'Morning');
            const afternoon_slots = slots.filter((slot) => slot.slot_type === 'Afternoon');
            const evening_slots = slots.filter((slot) => slot.slot_type === 'Evening');
            const mentorSlotType = {
                'Morning': morning_slots,
                'Afternoon': afternoon_slots,
                'Evening': evening_slots
            }
            let success_flag = 0;
            console.log("MWdeleteSlot ~ mentorSlotType[timeOfDay][slotIndex]:", mentorSlotType[timeOfDay][slotIndex])
            if (mentorSlotType[timeOfDay][slotIndex]?.id) {
                try {
                    const delete_body = {
                        "slot_array": [mentorSlotType[timeOfDay][slotIndex].id]
                    }
                    service.delete(`/os/superadmin/mentorSlot`, { data: delete_body });
                    success_flag = 1;
                } catch (error) {
                    console.error("Error while deleting a slot:", error);
                }
            } else {
                success_flag = 1;
            }
            if (success_flag) {
                if (timeOfDay === 'Morning') {
                    morning_slots.splice(slotIndex, 1);
                } else if (timeOfDay === 'Afternoon') {
                    afternoon_slots.splice(slotIndex, 1);
                } else if (timeOfDay === 'Evening') {
                    evening_slots.splice(slotIndex, 1);
                }
            } else {
                setMWDialogue((prev) => ({
                    ...prev,
                    open: true,
                    actions: [
                        { label: 'OK', handler: () => { MWDialogue.onClose() }, color: Color.primary1, variant: "contained" },
                    ],
                    title: 'Error',
                    content: `Delete Failed. Some error has occurred 💀`,
                }))
            }
            slots = [...morning_slots, ...afternoon_slots, ...evening_slots];
            temp_blueprint.find((blue) => blue.session_date === currentSession).slots = slots;
            console.log("deleteSlot ~ slots:", slots)
            setMentorSlots(temp_blueprint);
        }

        //MENTOR WISE SLOT ADD
        const handleMWAddSlot = () => {
            setMWAddEditSlot({ ...MWAddEditSlot, open: true, extraData: { heading: 'Add new slot', slot_index: null, slot_type: '' }, formData: { start_time: '', end_time: '', slot_type: '' }, });
        }

        //MENTOR WISE SLOT [POST]/[PUT] - modular function
        const handleMWSlotSave = async (flag, body) => {
            try {
                if (flag === 'post') {
                    const post_response = await service.post(`/os/superadmin/mentorSlot/individual`, body);
                    return post_response.data;
                } else if (flag === 'put') {
                    const post_response = await service.put(`/os/superadmin/mentorSlot`, body);
                    console.log("handlePopulateSlots ~ post_response:", post_response)
                    return true;
                }
            } catch (error) {
                console.error('Error while posting mentor individual slots:', error);
                return false;
            }
        }

        //MENTOR WISE SAVE/SUBMIT
        const handleMWSubmit = async (session_index) => {
            let temp_mentorSlots = [...mentorSlots];
            const temp_mentorSlots_slots = temp_mentorSlots[session_index].slots;
            const insert_mentorSlots_slots = temp_mentorSlots_slots.filter((slot) => slot?.id === undefined);
            const update_mentorSlots_slots = temp_mentorSlots_slots.filter((slot) => slot?.id !== undefined);
            setModalLoading((prev) => ({ ...prev, activity: true }));
            let post_flag = 0;
            let put_flag = 0;

            //[POST] mentor slots
            if (insert_mentorSlots_slots.length) {
                console.log("mentor_id:", selectedMentor, selectedMentor?.user_id)
                const post_body = {

                    "is_edited": 1,
                    "slot_array": insert_mentorSlots_slots.map((slot) => ({
                        "process_id": parseInt(process_id),
                        "mentor_id": selectedMentor.user_id ?? selectedMentor.mentor_id,
                        "slot_start_time": slot.slot_start_time,
                        "slot_end_time": slot.slot_end_time
                    }))
                }
                const post_response = await handleMWSlotSave('post', post_body);
                // if (session_index !== mentorSlots.length - 1)
                console.log("post response:", post_response);
                if (post_response?.insertedIds) {
                    for (const index in insert_mentorSlots_slots) {
                        const slot = insert_mentorSlots_slots[index];
                        slot.id = post_response.insertedIds[index];
                        //find index of the slot inside the slots array of the particular session
                        const slot_index_in_session = temp_mentorSlots_slots.findIndex((obj) => obj.slot_start_time === slot.slot_start_time);
                        if (slot_index_in_session !== -1) temp_mentorSlots_slots[slot_index_in_session] = slot;
                    }
                    post_flag = 1;
                }
            } else post_flag = 1;
            //[PUT] mentot slots
            if (update_mentorSlots_slots.length) {
                const put_body = {

                    "is_edited": 1,
                    "slot_array": update_mentorSlots_slots.map((slot) => ({
                        "id": slot.id,
                        "process_id": parseInt(process_id),
                        "slot_start_time": slot.slot_start_time,
                        "slot_end_time": slot.slot_end_time
                    }))
                }
                await handleMWSlotSave('put', put_body);
                put_flag = 1;
            } else put_flag = 1;

            temp_mentorSlots[session_index].slots = temp_mentorSlots_slots;
            setMentorSlots(temp_mentorSlots);
            if (session_index !== mentorSlots.length - 1) setCurrentSession(temp_mentorSlots[session_index + 1].session_date);
            else {
                setModalLoading((prev) => ({ ...prev, activity: true }));
                const new_mentorSlots = await getMentorSlotCount();
                setMentorSlotsCountData(new_mentorSlots);
                setModalLoading((prev) => ({ ...prev, activity: false }));
                onClose();
            };
            setMWDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { label: 'OK', handler: () => { MWDialogue.onClose() }, color: Color.primary1, variant: "contained" },
                ],
                title: 'Success',
                content: `Slots saved successfully`,
            }))
            // } else {
            //     // console.log("wtf man put flag and post flag are 1", put_flag, post_flag);
            //     setMWDialogue((prev) => ({
            //         ...prev,
            //         open: true,
            //         actions: [
            //             { label: 'OK', handler: () => { MWDialogue.onClose() }, color: Color.primary1, variant: "contained" },
            //         ],
            //         title: 'Error',
            //         content: `Some Error Occurred while saving the slots. Try again.${post_flag}  ${put_flag}`,
            //     }))
            // }
            setModalLoading((prev) => ({ ...prev, activity: false }));
        }

        //Modal functions
        const handleDeadlineCalendarSave = (value, time) => {
            console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time)
            const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
            const epochTime = date.getTime()

            //setting the epochtime's time to the starting of the day ie 12 am
            const date_at_dayStarting = new Date(epochTime);
            date_at_dayStarting.setHours(0, 0, 0, 0);
            const sessionTime = Math.floor(date_at_dayStarting.getTime());

            //[POST]-ing while populating at the same time (changed now - we save at the time of final save and next/close)
            setMWDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { label: 'No', handler: () => { handlePopulateSlots(0, sessionTime); MWDialogue.onClose() }, color: Color.primary1, variant: "contained" },
                    { label: 'Yes', handler: () => { handlePopulateSlots(1, sessionTime); MWDialogue.onClose() }, color: Color.primary1, variant: "outlined" },
                ],
                title: 'Populate Slots',
                content: `Do you want to populate slots for this day?`,
            }))

            deadlineCalendar.onClose();
        }

        const handlePopulateSlots = async (flag, epoch_session) => {
            let temp_mentorSlots = [...mentorSlots];
            if (flag) {
                const generated_slots = generateSlots(epoch_session)
                temp_mentorSlots.push({ session_date: epoch_session, slots: generated_slots });
                // try {
                //     const generated_slots = generateSlots(epoch_session)
                //     const post_body = {
                //         "slot_array": generated_slots.map((slot) => ({
                //             "process_id": process_id,
                //             "mentor_id": selectedMentor.user_id,
                //             "slot_start_time": slot.slot_start_time,
                //             "slot_end_time": slot.slot_end_time
                //         }))
                //     }
                //     const insertedIds = await handleMWSlotPost(post_body);
                //     const generated_slots_with_ids = [...generated_slots];
                //     for (const index in generated_slots) {
                //         generated_slots_with_ids[index].id = insertedIds[index];
                //     }
                //     temp_mentorSlots.push({ session_date: epoch_session, slots: generated_slots_with_ids });
                // } catch (error) {
                //     console.error('Error while posting generated slots:', error);
                // }
            }
            else
                temp_mentorSlots.push({ session_date: epoch_session, slots: [] });
            setMentorSlots(temp_mentorSlots);
            setCurrentSession(epoch_session); // move the focus to the currently added day/session
        }


        //EDIT and ADD Modal inside MENTOR WISE SLOTS

        //MODALS
        function MWAddEditSlotModal({ editOpen, editOnClose, editExtraData, editFormData }) {
            const modal_styles = {
                box__textfield: {
                    background: Color.white,
                    display: 'flex',
                    alignItems: 'center',
                    border: `1px solid ${Color.primary1}`,
                    borderRadius: 2,
                    padding: '8px',
                    width: '80%',
                    height: '56px'
                }
            }

            const [formInputs, setFormInputs] = useState({ ...editFormData });
            const [MWDeadlineTimeModal, setMWDeadlineTimeModal] = useState({
                open: false,
                onClose: () => { setMWDeadlineTimeModal((prev) => ({ ...prev, open: false })); setMWSelectedSlot((prev) => ({ ...prev, slot_start_time: null, slot_end_time: null, })) },
                value: null,
                flag: '',
                extraData: {
                    heading: "",
                    action_button: "",
                    handleAction: {},
                    preloaded_time: null
                }
            });




            //FUNCTIONS
            const handleMWSetDeadline = (flag, slot_time) => {
                console.log("handleMWSetDeadline ~ flag, slot_time:", flag, slot_time, currentSession)
                setMWDeadlineTimeModal((prev) => ({
                    ...prev, open: true,
                    flag: flag,
                    extraData: { ...prev.extraData, preloaded_time: slot_time === '' ? currentSession : slot_time }
                }));
                // setMWAddEditSlot({ ...addeditSlot, open: false });
            }

            //data received from Deadline Time Modal
            const handleMWDeadlineTimeSave = (value, time) => {
                console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time)
                // const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
                // const epochTime = date.getTime();

                // Extract date components and time components
                const year = value["$d"].getFullYear();
                const month = value["$d"].getUTCMonth();
                const day = value["$d"].getDate();
                const hours = parseInt(time.slice(0, 2), 10);
                const minutes = parseInt(time.slice(3), 10);

                // Create a Date object for the original time
                const date = new Date(year, month, day, hours, minutes, 0);

                // Convert to epoch time in milliseconds
                const epochTime = date.getTime();

                // Add 30 minutes in milliseconds
                const thirtyMinutesMs = 30 * 60 * 1000;
                const epochPlus30 = epochTime + thirtyMinutesMs;

                // Convert the new epoch time back to a Date object
                const datePlus30 = new Date(epochPlus30);
                console.log("handleDeadlineTimeCalendarSave ~ epochTime:", epochTime, date.getHours());
                if (MWDeadlineTimeModal.flag === "start_time")
                    setFormInputs((prev) => ({
                        ...prev, [MWDeadlineTimeModal.flag]: epochTime,
                        end_time: epochPlus30
                    }));
                else
                    setFormInputs((prev) => ({ ...prev, [MWDeadlineTimeModal.flag]: epochTime }));

                // setMWAddEditSlot({ ...addeditSlot, open: true, });
                setMWDeadlineTimeModal((prev) => ({ ...prev, open: false }));
            }

            const handleSubmit = async () => {
                let temp_blueprint = [...mentorSlots];
                const slots = temp_blueprint.find((blue) => blue.session_date === currentSession).slots;
                let adjustedSlots = []
                //edit an existing slot
                if (![null, undefined].includes(editExtraData?.slot_index)) {
                    const change_slot = {
                        id: editFormData.id,
                        slot_start_time: formInputs.start_time,
                        slot_end_time: formInputs.end_time,
                        slot_type: formInputs.slot_type
                    }
                    //slots -> all slots before change &&  change_slot -> slot after change 
                    adjustedSlots = adjustSlots(slots, change_slot, editExtraData.slot_index, formInputs.slot_type);
                    console.log("adjustSlots ~ adjustedSlots:", adjustedSlots);
                }
                //add a new slot
                else {
                    const add_slot = {
                        slot_start_time: formInputs.start_time,
                        slot_end_time: formInputs.end_time,
                        slot_type: formInputs.slot_type
                    }
                    adjustedSlots = readjustAddedSlots(slots, add_slot);
                    //[POST] mentor individual slots
                    // const post_body = {
                    //     "slot_array": adjustedSlots.map((slot) => ({
                    //         "process_id": process_id,
                    //         "mentor_id": selectedMentor.user_id,
                    //         "slot_start_time": slot.slot_start_time,
                    //         "slot_end_time": slot.slot_end_time
                    //     }))
                    // }
                    // const insertedIds = await handleMWSlotPost(post_body);
                    // console.log("readjustAddedSlots ~ adjustedSlots:", adjustedSlots);
                    // const adjustedSlots_with_ids = [...adjustedSlots];
                    // for (const index in adjustedSlots) {
                    //     adjustedSlots_with_ids[index].id = insertedIds[index];
                    // }
                }
                temp_blueprint.find((blue) => blue.session_date === currentSession).slots = adjustedSlots;
                setMentorSlots(temp_blueprint);
                MWAddEditSlot.onClose();
            }



            console.log("MWAddEditSlotModal ~ MWDeadlineTimeModal:", MWDeadlineTimeModal)

            return (
                <Modal open={editOpen} onClose={editOnClose}>
                    <Box sx={{ ...modalBoxStyle, width: '40%', minWidth: '700px', height: '300px', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Box sx={{ ...DivCenter, width: '100%', height: '20%', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'center', fontSize: '24px', width: '100%', fontWeight: 500 }}>{editExtraData.heading}</Typography>
                            {/* <IconButton onClick={onClose}>
                                <Close />
                            </IconButton> */}
                        </Box>
                        <Box sx={{ ...DivCenter, width: '100%', height: '60%', }}>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                                <Typography style={{ textAlign: 'center' }}>Start Time</Typography>
                                <Box onClick={() => handleMWSetDeadline("start_time", formInputs.start_time)} sx={{
                                    ...modal_styles.box__textfield, ...DivCenter,
                                    cursor: formInputs.slot_selection_deadline ? 'default' : 'pointer',
                                }}>{formInputs.start_time ? getSlotNames(formInputs.start_time) : ''}</Box>
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                                <Typography style={{ textAlign: 'center' }}>End Time</Typography>
                                <Box onClick={() => handleMWSetDeadline("end_time", formInputs.end_time)} sx={{
                                    ...modal_styles.box__textfield, ...DivCenter,
                                    cursor: formInputs.slot_selection_deadline ? 'default' : 'pointer',
                                }}>{formInputs.end_time ? getSlotNames(formInputs.end_time) : ''}</Box>
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                                <Typography style={{ textAlign: 'center' }}>Slot Type</Typography>
                                <TextField select value={formInputs.slot_type} onChange={(e) => setFormInputs((prev) => ({ ...prev, slot_type: e.target.value }))} sx={{ width: '80%' }}>
                                    {slotType.map((option) =>
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>)}
                                </TextField>
                            </Box>
                        </Box>
                        <Box sx={{ ...DivCenter, width: '100%', height: '20%', gap: '32px' }}>
                            <Button variant='outlined' onClick={() => editOnClose()} sx={{ ...styles.button_styles.outlined, width: '30%' }}>Cancel</Button>
                            <Button variant='contained' onClick={() => handleSubmit()} sx={{ ...styles.button_styles.contained, width: '30%' }}>Submit</Button>
                        </Box>
                        {MWDeadlineTimeModal.open && <DeadlineTimeModal
                            open={MWDeadlineTimeModal.open}
                            onClose={MWDeadlineTimeModal.onClose}
                            extraData={{
                                ...MWDeadlineTimeModal.extraData,
                                heading: "Choose Deadline",
                                action_button: "Save",
                                handleAction: (a, b) => handleMWDeadlineTimeSave(a, b),
                            }}
                        />}
                    </Box>
                </Modal>
            )
        }


        console.log("current date:", currentSession,);
        return (
            <Modal open={open} onClose={onClose}>
                <Box sx={{ ...modalBoxStyle, width: '100%', minWidth: '900px', height: '100%', minHeight: '700px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto' }}>
                    <Box sx={{ ...DivCenter, width: '100%', height: '5%', justifyContent: 'space-between' }}>
                        <Typography style={{ ...DivCenter, justifyContent: 'flex-start', textAlign: 'left', fontSize: '24px', width: '100%', fontWeight: 500 }}>
                            {(page !== 1 && !extraData?.mentor?.user_id) &&
                                <IconButton onClick={() => setPage((prev) => prev > 0 ? prev - 1 : 0)}>
                                    <ArrowBackIos sx={{ fontSize: '22px' }} />
                                </IconButton>}
                            {extraData.heading}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '95%', justifyContent: 'flex-start', }}>
                        <Box sx={{ ...DivCenter, width: '100%', p: '32px 0 16px 0' }}>
                            <TextField
                                name="search_mentors"
                                // label="Search Student"
                                placeholder={selectedMentor.name ?? 'Search Mentors'}
                                variant="outlined"
                                onKeyPress={handleKeyPress}
                                value={searchMentor}
                                onChange={handleChangeSearch}
                                fullWidth
                                sx={{ width: '90%', borderRadius: "12px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: Color.neutralMidGrey }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PlayArrow sx={{ color: Color.neutralMidGrey }} />
                                        </InputAdornment>
                                    ),
                                    sx: { borderRadius: "12px", height: "58px" }
                                }}
                            />
                            <Box sx={{ ...DivCenter, width: '10%', height: "58px" }}>
                                <IconButton onClick={() => handleSearchMentor()}>
                                    <Search sx={{ color: Color.neutralMidGrey }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {page === 1 ?
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '16px' }}>
                                {modalLoading.searchMentor ?
                                    <CircularProgress size={24} sx={{ color: Color.primary1 }} />
                                    : mentorData.length ?
                                        mentorData.map((mentor, index) =>
                                            <Box
                                                onClick={() => handleMentorClick(mentor, index)}
                                                sx={{
                                                    ...DivCenter, width: '100%', borderBottom: '1px solid black', p: '16px 0', cursor: 'pointer',
                                                    // '&:hover': {
                                                    //     background: Color.primary_highlight
                                                    // }
                                                }}>
                                                <Typography style={{
                                                    width: '90%',
                                                    textAlign: 'left',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    lineHeight: '14px',
                                                }}>{mentor.mentor_name}</Typography>
                                                <Box sx={{ width: '5%' }}>{mentor.loading ? <CircularProgress size={18} sx={{ color: Color.primary1Color }} /> : <></>}</Box>
                                                <Box sx={{ width: '5%' }}><KeyboardArrowRight /></Box>
                                            </Box>
                                        ) : <Typography style={{
                                            width: '100%', textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            lineHeight: '14px',
                                            color: Color.neutralMidGrey,
                                        }} >No mentors to show</Typography>}
                            </Box>
                            : page === 2 ?
                                <>{
                                    modalLoading.mentorSlots ?
                                        <CircularProgress size={24} sx={{ color: Color.primary1 }} />
                                        : <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px' }}>
                                            <Typography style={{ width: '100%', textAlign: 'left', color: Color.neutralMidGrey, fontSize: '20px' }}>
                                                {selectedMentor.mentor_name}
                                            </Typography>
                                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                                <Typography style={{ width: '80%', textAlign: 'left', color: Color.neutralMidGrey, fontSize: '16px' }}>
                                                    Mapped Specialization : {mappedSpe.map((spe, spe_index) => spe.spe_name + ` (${spe.session_count}) ` + (spe_index === mappedSpe.length - 1 ? '' : ', '))}
                                                </Typography>
                                                <Typography style={{ width: '20%', textAlign: 'right', color: Color.neutralMidGrey, fontSize: '16px' }}>
                                                    Total interviews : {mentorSlots.map(item => item.slots.length).reduce((acc, curr) => acc + curr, 0)}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-end' }}>
                                                <Button onClick={() => handleAddNewDay()} sx={{ ...styles.button_styles.outlined, color: Color.neutralBlack, fontSize: '14px', '&:hover': { color: Color.neutralBlack, background: Color.primary_highlight } }}>
                                                    Add new day <Add sx={{ fontSize: '20px', color: Color.primary1 }} />
                                                </Button>
                                            </Box>
                                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                                {mentorSlots.map((blueprint, blueprint_index) =>
                                                    blueprint.session_date === currentSession ?
                                                        <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                                                            <Box sx={{ ...DivCenter, justifyContent: 'flex-start', width: '100%', gap: '32px' }}>
                                                                {mentorSlots.map((sess, sess_index) =>
                                                                    <Box sx={{ ...DivCenter }}>
                                                                        <Typography onClick={() => setCurrentSession(sess.session_date)} style={{ color: sess.session_date === currentSession ? Color.primary1 : Color.neutralBlack, fontSize: '24px', fontWeight: 500, cursor: 'pointer' }}>
                                                                            {formatConvertermonthfirst(sess.session_date, false, false, true)}, {dayNames[new Date(sess.session_date).getDay()]}
                                                                        </Typography>
                                                                        <IconButton onClick={() => callDialogueForMWDeleteDay(sess_index, sess.session_date)}>
                                                                            <Close sx={{ color: Color.red }} />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-end' }}>
                                                                <Box sx={{ ...DivCenter, justifyContent: 'flex-end', width: '20%', minWidth: '280px' }}>
                                                                    {/* <Button variant='text' onClick={() => handleViewMentorWise()} sx={{ color: Color.primary1, fontSize: '16px', fontWeight: 500, textTransform: 'none' }}>View Mentor Wise</Button> */}
                                                                    <IconButton onClick={() => handleMWAddSlot()}><AddOutlined /></IconButton>
                                                                    <IconButton><GetApp /></IconButton>
                                                                    <IconButton><UploadFile /></IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                                                {['Morning', 'Afternoon', 'Evening'].map((time_of_day) =>
                                                                    <Box sx={{ ...DivCenter, gap: '16px', justifyContent: 'flex-start', flexDirection: 'column', width: '20%', height: 'auto', p: '32px', minWidth: '380px', minHeight: '380px', borderRadius: '12px', boxShadow: '0px 6px 11px 0px rgba(0, 0, 0, 0.11)' }}>
                                                                        <Box sx={{ height: '10%' }}>
                                                                            <Typography style={{ fontSize: '24px', fontWeight: 500 }}>{time_of_day} Slots</Typography>
                                                                        </Box>
                                                                        <Box sx={{ width: '100%', height: '90%' }}>
                                                                            {blueprint.slots?.filter((blue) => blue.slot_type === time_of_day).map((blue, blue_index) =>

                                                                                <Box sx={{
                                                                                    ...DivCenter, width: '100%', gap: '8px', height: '40px',
                                                                                }}>
                                                                                    <Typography style={{
                                                                                        ...DivCenter, alignItems: 'center',
                                                                                        height: '30px',
                                                                                        background: MWSelectedSlot.slot_start_time === blue.slot_start_time ? Color.primary3 : Color.white,
                                                                                        color: extraData?.slot?.id === blue.blueprint_id ? Color.red : MWSelectedSlot.slot_start_time === blue.slot_start_time ? Color.white : Color.neutralBlack,
                                                                                        borderRadius: '12px',
                                                                                        fontSize: 16,
                                                                                        fontWeight: 500,
                                                                                        textAlign: 'center',
                                                                                        width: '70%',
                                                                                    }}>{getSlotNames(blue.slot_start_time)} - {getSlotNames(blue.slot_end_time)}</Typography>
                                                                                    <Box sx={{ ...DivCenter, gap: '8px', width: '30%', justifyContent: 'flex-end' }}>
                                                                                        <IconButton onClick={() => handleMWTakeAction(0, { id: blue.id, start_time: blue.slot_start_time, end_time: blue.slot_end_time, slot_type: blue.slot_type }, blue_index, time_of_day)}>
                                                                                            <Clear sx={{ color: Color.red }} />
                                                                                        </IconButton>
                                                                                        <div style={{ height: '20px', minWidth: '1px', background: Color.neutralBlack }} />
                                                                                        <IconButton onClick={() => handleMWTakeAction(1, { id: blue.id, start_time: blue.slot_start_time, end_time: blue.slot_end_time, slot_type: blue.slot_type }, blue_index, time_of_day)}>
                                                                                            <EditOutlined sx={{ color: Color.primary1 }} />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Box>)}
                                                                        </Box>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Typography style={{ textAlign: 'center', fontSize: '24px', fontWeight: 500 }}>Total slots: {blueprint.slots?.length} Slots</Typography>
                                                            </Box>
                                                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                                                <Button onClick={() => handleMWSubmit(blueprint_index)} sx={{ ...styles.button_styles.contained, width: '35%' }}>{blueprint_index === mentorSlots.length - 1 ? "Save and Close" : "Save and Next"}</Button>
                                                            </Box>
                                                        </Box>
                                                        : <></>)}
                                            </Box>
                                            <DeadlineCalendarModal
                                                open={deadlineCalendar.open}
                                                onClose={deadlineCalendar.onClose}
                                                extraData={{
                                                    ...deadlineCalendar.extraData,
                                                    heading: "Add New Day",
                                                    action_button: "Save",
                                                    handleAction: (a, b) => handleDeadlineCalendarSave(a, b),
                                                }}
                                            // shouldDisableDate={isDisabledDate}
                                            />
                                            {MWAddEditSlot.open &&
                                                <MWAddEditSlotModal
                                                    editOpen={MWAddEditSlot.open}
                                                    editOnClose={MWAddEditSlot.onClose}
                                                    editExtraData={MWAddEditSlot.extraData}
                                                    editFormData={MWAddEditSlot.formData}
                                                />}
                                            {MWDialogue.open && <DialogueBox
                                                open={MWDialogue.open}
                                                onClose={MWDialogue.onClose}
                                                title={MWDialogue.title}
                                                actions={MWDialogue.actions}
                                                content={<>
                                                    <p>{MWDialogue.content}</p>
                                                </>}
                                            />}
                                        </Box>
                                }</>
                                : <></>}
                    </Box>
                    <ActivityLoader show={modalLoading.activity} />
                </Box>
            </Modal >
        )
    }

    function ScheduleConfirmation({ open, onClose, blue_index }) {
        return (
            <Modal open={open} onClose={onClose}>
                <Box sx={{ ...modalBoxStyle, width: '100px', minWidth: "550px", minHeight: '100px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto' }}>
                    <Box sx={{ ...DivCenter, width: '100%', height: '5%', justifyContent: 'space-between' }}>
                        <Typography style={{ ...DivCenter, marginTop: "12px", justifyContent: 'center', textAlign: 'center', fontSize: '24px', width: '100%', fontWeight: 500 }}>

                            Confirmation
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', marginTop: "12px", width: '90%', justifyContent: 'center', textAlign: "center" }}>
                        You are about to submit the slots. Have you accounted for breaks like lunch, tea... if not please edit the slots again.
                    </Box>

                    <Box sx={{ ...DivCenter, width: '100%', gap: '24px', marginTop: "35px", marginBottom: "35px" }}>
                        <Button variant="outlined"
                            onClick={() => onClose()}
                            sx={{
                                color: Color.red,
                                background: Color.white,
                                borderRadius: "12px",
                                width: '40%',
                                border: `1px  solid red`,
                                '&:hover': {
                                    color: Color.red,
                                    background: Color.white,
                                    border: `1px  solid red`,
                                }
                            }} >Edit</Button>
                        <Button variant="outlined"
                            onClick={() => { onClose(); handleSubmit(blue_index); }}
                            sx={{
                                color: Color.green,
                                background: "#3CAB4D1A",
                                width: '40%',
                                borderRadius: "12px",
                                border: `1px  solid green`,
                                '&:hover': {
                                    color: Color.green,
                                    background: Color.white,
                                    border: `1px  solid green`,
                                }
                            }}> Continue </Button>
                    </Box>

                    {/* <ActivityLoader show={modalLoading.activity} /> */}
                </Box>
            </Modal >
        )
    }


    console.log("countss:", generalInterviewCount, blueprintData);

    const checkMentorTotalSlots = () => {
        const incomplete_slot_mentor_name = mentorSlotsCountData
            .filter(mentor => mentor.assigned_slot_count > mentor.slot_count)
            .map((mentor, index) => {
                console.log('mentor item contains:', mentor);
                return { mentor_name: mentor.mentor_name, mentor_id: mentor.mentor_id };
            });

        console.log("incomplete_slot_mentor_name", incomplete_slot_mentor_name);
        return incomplete_slot_mentor_name;


    }
    const checkCountInterviewSlots = (blueprint_index) => {
        const count_slots = blueprintData.reduce((acc, item, index) => {
            return (acc + item.slots.length)
        }, 0)

        const slot_mentor_name = checkMentorTotalSlots();
        console.log("total interview slots", count_slots)
        if (slot_mentor_name?.length > 0) {
            setCheckInterviewCount((prev) => ({ ...prev, open: true, header: "Number of slots of these mentors are less than total interviews to be conducted", flag: "mentor", mentor_names: [...slot_mentor_name] }));
        } else if (generalInterviewCount > count_slots) {
            setCheckInterviewCount((prev) => ({ ...prev, open: true, flag: "general", header: "Number of slots are less than total interviews to be conducted" }));
        } else {
            // handleSubmit(blueprint_index)
            setConfirmation((prev) => ({ ...prev, blueprint_index: blueprint_index, open: true }))
        }
    }

    async function handleRefreshMentorCount() {
        const new_mentorSlots = await getMentorSlotCount();
        setMentorSlotsCountData(new_mentorSlots);
    }
    //MAIN DOM RENDER

    return (
        <>{
            loading.page ?
                <SmallPageLoader show={loading.page} />
                :
                <>
                    {blueprintData.map((blueprint, blueprint_index) =>
                        blueprint.session_date === currentDay ?
                            <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                                <Typography style={{ fontSize: '24px', fontWeight: 500 }}>
                                    {formatConvertermonthfirst(blueprint.session_date, false, false, true)}, {dayNames[new Date(blueprint.session_date).getDay()]} - Review default Slots (Total : {blueprintData.reduce((acc, obj) => {
                                        return acc + parseInt(obj['per_mentor_slot_count']);
                                    }, 0)} interviews)
                                </Typography>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-end' }}>
                                    <Box sx={{ width: '20%', minWidth: '280px' }}>
                                        <Button variant='text' onClick={() => handleViewMentorWise()} sx={{ color: Color.primary1, fontSize: '16px', fontWeight: 500, textTransform: 'none' }}>View Mentor Wise</Button>
                                        <IconButton onClick={() => handleAddSlot()}><AddOutlined /></IconButton>
                                        <IconButton><GetApp /></IconButton>
                                        <IconButton><UploadFile /></IconButton>
                                    </Box>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                    {['Morning', 'Afternoon', 'Evening'].map((time_of_day) =>
                                        <Box sx={{ ...DivCenter, gap: '16px', justifyContent: 'flex-start', flexDirection: 'column', width: '30%', height: 'auto', p: '32px', minWidth: '335px', minHeight: '480px', borderRadius: '12px', boxShadow: '0px 6px 11px 0px rgba(0, 0, 0, 0.11)' }}>
                                            <Box sx={{ height: '10%' }}>
                                                <Typography style={{ fontSize: '24px', fontWeight: 500 }}>{time_of_day} Slots</Typography>
                                            </Box>
                                            <Box sx={{ width: '100%', height: '90%' }}>
                                                {blueprint?.slots?.filter((blue) => blue?.slot_type === time_of_day).map((blue, blue_index) =>
                                                    <Box sx={{
                                                        ...DivCenter, width: '100%', gap: '8px', height: '40px',
                                                        // '&:hover': {
                                                        //     background: Color.primary_highlight
                                                        // }
                                                    }}>
                                                        <Typography style={{
                                                            ...DivCenter, alignItems: 'center',
                                                            height: '30px',
                                                            background: selectedSlot.slot_start_time === blue.slot_start_time ? Color.primary3 : Color.white,
                                                            color: selectedSlot.slot_start_time === blue.slot_start_time ? Color.white : Color.neutralBlack,
                                                            borderRadius: '12px',
                                                            fontSize: 16,
                                                            fontWeight: 500,
                                                            textAlign: 'center',
                                                            width: '70%',
                                                        }}>{getSlotNames(blue.slot_start_time)} - {getSlotNames(blue.slot_end_time)}</Typography>
                                                        <Box sx={{ ...DivCenter, gap: '8px', width: '30%', justifyContent: 'flex-end' }}>
                                                            <IconButton onClick={() => handleTakeAction(0, { id: blue.id, start_time: blue.slot_start_time, end_time: blue.slot_end_time, slot_type: blue.slot_type }, blue_index, time_of_day)}>
                                                                <Clear sx={{ color: Color.red }} />
                                                            </IconButton>
                                                            <div style={{ height: '20px', minWidth: '1px', background: Color.neutralBlack }} />
                                                            <IconButton onClick={() => handleTakeAction(1, { id: blue.id, start_time: blue.slot_start_time, end_time: blue.slot_end_time, slot_type: blue.slot_type }, blue_index, time_of_day)}>
                                                                <EditOutlined sx={{ color: Color.primary1 }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>)}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontWeight: 500 }}>Total slots: {blueprint.slots?.length} Slots</Typography>
                                </Box>

                                <Box sx={{ ...DivCenter, width: '100%' }}>
                                    {/* <IconButton onClick={() => toggling_between_days(0, blueprint_index)}>
                                    <KeyboardDoubleArrowLeft sx={{ height: '40px', width: '40px' }} />
                                </IconButton> */}
                                    <Button variant='contained' onClick={() => { (blueprint_index === blueprintData.length - 1) ? checkCountInterviewSlots(blueprint_index) : handleSubmit(blueprint_index) }}
                                        sx={{ ...styles.button_styles.contained, height: '51px', width: '40%', minWidth: 'auto', fontSize: '20px' }}>
                                        {blueprint_index === blueprintData.length - 1 ? "Save and Submit" : "Save and Review next day slots"}
                                        <KeyboardDoubleArrowRight sx={{ height: '40px', width: '40px' }} />
                                    </Button>
                                    {/* <IconButton onClick={() => toggling_between_days(1, blueprint_index)}>
                                    <KeyboardDoubleArrowRight sx={{ height: '40px', width: '40px' }} />
                                </IconButton> */}
                                </Box>
                                {mentorSlotsCountData.length ? <>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'left', fontSize: '32px', fontWeight: 500 }}>
                                            Mentor Total interview and slot summary
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ...DivCenter, width: '100%', }}>
                                        <Typography style={{ width: '30%', textAlign: 'center', fontSize: '24px', color: Color.neutralMidGrey }}>Mentor name</Typography>
                                        <Typography style={{ width: '30%', textAlign: 'center', fontSize: '24px', color: Color.neutralMidGrey }}>Total assigned interviews</Typography>
                                        <Typography style={{ width: '30%', textAlign: 'center', fontSize: '24px', color: Color.neutralMidGrey }}>Total slots so far</Typography>
                                    </Box>
                                    {mentorSlotsCountData.map((mentor) =>
                                        <Box sx={{ ...DivCenter, width: '100%' }}>
                                            <Typography style={{ width: '30%', textAlign: 'center', fontSize: '16px', }}>{mentor.mentor_name}</Typography>
                                            <Typography style={{ width: '30%', textAlign: 'center', fontSize: '16px', }}>{mentor.assigned_slot_count}</Typography>
                                            <Typography style={{ ...DivCenter, width: '30%', textAlign: 'center', fontSize: '16px', color: mentor.assigned_slot_count > mentor.slot_count ? Color.red : Color.blueBlack }}>{mentor.slot_count}
                                                <IconButton onClick={() => {
                                                    setMentorWiseData((prev) => ({
                                                        ...prev, open: true, extraData: {
                                                            heading: 'Mentor Wise Slots',
                                                            mentor: { user_id: mentor.mentor_id, name: mentor.mentor_name }
                                                        }, page: 2
                                                    }));
                                                }
                                                }>
                                                    <EditOutlined sx={{ color: Color.primary1, fontSize: '20px' }} />
                                                </IconButton>
                                            </Typography>
                                        </Box>)}
                                </> : <></>}
                                {checkInterviewCount.open &&
                                    <InterviewWarning
                                        flag={checkInterviewCount.flag}
                                        open={checkInterviewCount.open}
                                        onClose={checkInterviewCount.onClose}
                                        header={checkInterviewCount.header}
                                        mentor_names={checkInterviewCount?.mentor_names}
                                    />
                                }
                                {addeditSlot.open &&
                                    <AddEditSlotModal
                                        open={addeditSlot.open}
                                        onClose={addeditSlot.onClose}
                                        extraData={addeditSlot.extraData}
                                        formData={addeditSlot.formData}
                                    />}
                                {
                                    reviewClashModal.open &&
                                    <ReviewBeforeSubmit
                                        flag={reviewClashModal.flag}
                                        open={reviewClashModal.open}
                                        onClose={reviewClashModal.onClose}
                                        extraData={reviewClashModal.extraData}
                                        formData={reviewClashModal.formData}
                                        blueprint_index={reviewClashModal.blueprint_index}
                                        time_of_day={reviewClashModal.time_of_day}
                                        slot={reviewClashModal.slot}
                                        reverifyFunction={reviewSlots}
                                        anywayFunction={reviewClashModal.anywayFunction}
                                    />
                                }
                                {deadlineModalData.open && <DeadlineTimeModal
                                    open={deadlineModalData.open}
                                    onClose={deadlineModalData.onClose}
                                    extraData={{
                                        ...deadlineModalData.extraData,
                                        heading: "Choose Deadline",
                                        action_button: "Save",
                                        handleAction: (a, b) => handleDeadlineTimeCalendarSave(a, b),
                                    }}
                                />}
                                {mentorWiseData.open && <MentorWiseSlots
                                    open={mentorWiseData.open}
                                    onClose={mentorWiseData.onClose}
                                    extraData={mentorWiseData.extraData}
                                    data={mentorSlotsCountData}
                                    modal_page={mentorWiseData.page}
                                    slot={mentorWiseData.slot}

                                />}
                                {dialogue.open && <DialogueBox
                                    open={dialogue.open}
                                    onClose={dialogue.onClose}
                                    title={dialogue.title}
                                    actions={dialogue.actions}
                                    content={<>
                                        <p>{dialogue.content}</p>
                                    </>}
                                />}
                                {
                                    confirmation.open && <ScheduleConfirmation
                                        open={confirmation.open}
                                        blue_index={confirmation.blueprint_index}
                                        onClose={() => setConfirmation((prev) => ({ ...prev, open: false }))}
                                    />
                                }
                                <ActivityLoader show={loading.activity} />
                            </Box> : <></>
                    )}
                    {
                        <Snackbar open={submitSnack.open} autoHideDuration={4000} onClose={() => setSubmitSnack((prev) => ({ ...prev, open: false }))}>
                            <Alert onClose={() => setSubmitSnack((prev) => ({ ...prev, open: false }))} severity="success">
                                Slots {submitSnack.state} successfully
                            </Alert>
                        </Snackbar>


                    }
                </>
        }</>
    )
}

export default LaunchScheduleSlots