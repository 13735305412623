import React from "react";
// import ReactTable from "react-table";
function Education(props) {
  const Academicdetail =[
    {
        "acad_id": 990,
        "start_year": 2020,
        "end_year": 2023,
        "board_univ": "College",
        "degree": "MBA",
        "score": "9.14"
    },
    {
        "acad_id": 995,
        "start_year": 2017,
        "end_year": 2019,
        "board_univ": "College",
        "degree": "UG",
        "score": "91.17"
    }
]
  console.log("Edu",Academicdetail)
  let data=[]
  if ((Academicdetail && Academicdetail?.length > 0)) {
    Academicdetail.map((value)=>
    data.push({
      Degree:value.degree,
      Institute:value.board_univ,
      PercentageCGPA:value.score,
      Year:value.start_year+"-"+value?.end_year,
    }),)
  }
  else{
   data = [
    {
      Degree: "  ",
      Institute: " ",
      PercentageCGPA: " ",
      Year: " ",
    },
    // {
    //   Degree: "BBA",
    //   Institute: "Symbiosis Centre for Management Studies",
    //   PercentageCGPA: 70,
    //   Year: 2012,
    // },
    // {
    //   Degree: "Class XII |CBSE",
    //   Institute: "Evergreen Public School, Delhi",
    //   PercentageCGPA: 86.20,
    //   Year: 2010,
    // },
    // {
    //   Degree: "Class X |CBSE",
    //   Institute: "Ahlcon Public School, Delhi",
    //   PercentageCGPA: 80,
    //   Year: 2008,
    // },
  ];
}
  console.log('105', props.TableWidth);
  console.log(props.minwidth);
  // const [fontSize, setFontSize] = useState(16);
  let clases=props.styleEducation?"EducationBox  ResumeTable":"EducationBox  overlapping-table ResumeTable"
  return (
    <div>
      {/* <button onClick={() => setFontSize(fontSize + 2)}>++</button>
      <button onClick={() => setFontSize(fontSize - 2)}>--</button> */}
      <table
        // class="EducationBox"
        className={clases}
        // style={{ width: props.TableWidth ? "105%" : "100%",
        style={{
          // width:  ? "105%" : "100%",
          width: props.minwidth ? props.minwidth : props.TableWidth,

        }}
      // style={{ width: props.widths+"px" }}
      >
        <thead>
          <tr>
          <th className="Resumetable-th"
            colspan="6"
            id="Topic"
            style={{
              backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
              color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
              fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
              fontFamily: props.fontS,
              border: props.styleEducation ? "none" : "0.5px solid #000",
              // border:props.style?"none":"",
              textAlign: props.headerPosition,
              // borderTop: "none",
              height: props.miniheight, padding: props.minipadding
            }}
          >
            {/* Responsibilty */}
            {props.name ? props.name : 'Education'}
          </th>
          </tr>
          <tr
            style={{
              border: props.styleEducation ? "" : "0.5px solid #000",
              // fontFamily: props.FontFamily ? "TimesNewRoman" : "Arial",
              // fontFamily: props.Arial
            }}
          >
            <td className="Resumetable-th"
              style={{
                backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
                // fontSize: props.FontSize,
                border: props.styleEducation ? "" : "0.5px solid #000",
                fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                padding: props.minipadding,
                height: props.miniheight,
                fontFamily: props.fontS,
                lineHeight: props.LineHeight,
                textTransform:"none"
              }}
              id="Degree"
            >
              Degree
            </td>
            <th className="Resumetable-th"
              style={{
                backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
                fontSize: props.FontSize,
                border: props.styleEducation ? "" : "0.5px solid #000",
                fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                padding: props.minipadding,
                height: props.miniheight,
                fontFamily: props.fontS,
                lineHeight: props.LineHeight,
                textTransform:"none"
              }}
              id="Institute"
            >
              Institute
            </th>
            <th className="Resumetable-th"
              style={{
                backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
                fontSize: props.FontSize,
                border: props.styleEducation ? "" : "0.5px solid #000",
                fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                padding: props.minipadding,
                height: props.miniheight,
                fontFamily: props.fontS,
                lineHeight: props.LineHeight,
                textTransform:"none"
              }}
              id="Percentage-CGPA"
            >
              % / CGPA
            </th>
            <th className="Resumetable-th"
              style={{
                backgroundColor: props.ribbonColor ? props.ribbonColor["lightShade"] : "#eeeeee",
                color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
                // fontSize: props.FontSmall,
                fontSize: props.FontSize,
                fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,
                padding: props.minipadding,
                height: props.miniheight,
                fontFamily: props.fontS,
                lineHeight: props.LineHeight,
                textTransform:"none"
              }}
              id="Year"
            >
              Year
            </th>
          </tr>
        </thead>
        {data.map((val, key) => {
          return (
            // <tr style={{ fontSize: props.fsize }} key={key}>
            <tr
              style={{
                // fontSize: props.FontSmall,
                fontSize: props.FontSize,
                fontFamily: props.fontS,
                fontSize: props.miniFontSize ? props.FontSize : props.miniFontSize,
                // fontFamily: props.FontFamily ? "TimesNewRoman" : "Arial",
                // fontFamily: props.Arial,
                // LineHeight: props.LineHeight ? "1.2" : "",
                LineHeight: props.miniLheight ? props.miniLheight : props.LineHeight,
              }}
              key={key}
            >
              <td className="ResumeTable-td" style={{ border: props.styleEducation ? "" : "0.2px solid #000", height: props.miniheight + "px", lineHeight: props.LineHeight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,height:"20.4px", fontFamily: props.fontS,width:"16%" }}>{val.Degree}</td>
              <td className="ResumeTable-td" style={{ border: props.styleEducation ? "" : "0.2px solid #000", height: props.miniheight + "px", lineHeight: props.LineHeight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,height:"20.4px" ,fontFamily: props.fontS }}>{val.Institute}</td>
              <td className="ResumeTable-td" style={{ border: props.styleEducation ? "" : "0.2px solid #000", height: props.miniheight + "px", lineHeight: props.LineHeight, fontSize: props.miniFontSize ?  props.miniFontSize : props.FontSize, padding: props.minipadding,height:"20.4px", fontFamily: props.fontS }}>{val.PercentageCGPA}</td>
              <td className="ResumeTable-td" style={{ border: props.styleEducation ? "" : "0.2px solid #000", height: props.miniheight + "px", lineHeight: props.LineHeight, fontSize: props.miniFontSize ?  props.miniFontSize : props.FontSize, padding: props.minipadding,height:"20.4px", fontFamily: props.fontS }}>{val.Year}</td>
            </tr>
          );
        })}
      </table>
      {/* <button onClick={changeFontType}></button> */}
    </div>
  );
}

export default Education;
