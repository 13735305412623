import React, { useState } from 'react'
import img from "./images/img.jpg";
import { calculateAge } from "../Reusable_functions/calculateAge";
function Header(props) {
  const {  image, picturePosition, logoPosition, showPicture, showAdress, showLogo, file } = props
  const userData = {
    "first_name": "First",
        "last_name": "Name",
        "ph_no": "Phone",
        "email_id": "Email",
        "gender": "Female",
        "dob": "2023-05-28T18:30:00.000Z",
        "city": "bangalore",
        "pin_code": 560085,
        "country": "india"
  }
//   {
//     "section_detail": {
//         "section_id": 0,
//         "section_type_id": 0,
//         "section_name": "User Detail",
//         "section_order": 0,
//         "section_template_name": null
//     },
//     "section_data": {
//         "first_name": "First",
//         "last_name": "Name",
//         "ph_no": "Phone",
//         "email_id": "ronem86093@rockdian.com",
//         "gender": "Female",
//         "dob": "2023-05-28T18:30:00.000Z",
//         "city": "bangalore",
//         "pin_code": 560085,
//         "country": "india"
//     }
// },
  console.log("image--d", image);
  let FontSize2 = false;
  console.log("me in header", userData)
  let miniFontSize2 = Number(props.miniFontSize?.substring(0, 2)) + 2 + "pt";
  //Calculate Age From Date
  let year = userData?.dob?.substring(0, 4);
  let month = userData?.dob?.substring(5, 7);
  let date = userData?.dob?.substring(8, 10);
  const age = calculateAge(year, month, date);
  console.log("age", age);
  { console.log("ss", showPicture) }
  console.log("sd", showAdress);
  console.log("showLogo", showLogo);
  let Name = "FirstName-Adress";
  let Name2 = "SecondName-Name";
  let Name3 = "ThirdName-Adress";

  const img1 = props.headerImages ? Object.keys(props.headerImages).find(key => props.headerImages[key] === "left") : "address";
  const img2 = props.headerImages ? Object.keys(props.headerImages).find(key => props.headerImages[key] === "center") : "picture";
  const img3 = props.headerImages ? Object.keys(props.headerImages).find(key => props.headerImages[key] === "right") : "logo";

  console.log("Header Image", props.headerImages);
  console.log("img1", img1);
  console.log("img2", img2);
  console.log("img3", img3);

  const images = {
    address: <>
      <p className='Name_User-Name' style={{fontFamily: props.fontS,fontWeight:"bold",fontSize: props.miniFontSize ? Number(props.miniFontSize?.substring(0, 2)) + 2 + "px" : Number(props.FontSize?.substring(0, 2)) + 2 + "pt"}}>{userData?.first_name == "null" ? "" : userData?.first_name}{userData?.last_name == "null" ? "" : " " + userData?.last_name}</p>
      <p style={{fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize}}>{userData?.gender == null ? "Male" : userData.gender} {age}</p>
      <p href="#" style={{fontFamily: props.fontS,margin: 0,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize,color:"blue"}} >{userData ? userData.email_id : "abc@xyz.edu"}< span className='Header-Phno' style={{fontFamily: props.fontS,margin: 0,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize}}>{userData?.ph_no > 0 || "" ? "|" + userData.ph_no : ""}</span></p>
    </>,

    picture:
      <img className='profile-image' src={image} style={{ visibility: showPicture ? "visible" : "hidden", marginBottom: "0.11rem" }} />,
    logo:
      <>
        {showAdress ?
          <>
            <p style={{ fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>{userData ? userData.city : ""}</p>
            <p style={{ fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>{userData ? userData.country : ""}</p>
            <p style={{ fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>{userData ? userData.pin_code : ""}</p>
          </> : <></>}
        {(showLogo) ? <img src={file} height="50" /> : <></>}
      </>
  };
  return (

    <div>
      {/* <table className='Header-Table ' style={{ width: props.minwidth ? props.minwidth : props.TableWidth === true ? "760px" : "728px" }}>
        <tr className='Header-TableRow' >
          <td className='StudentDetails HeaderName ' colSpan={8} style={{ fontFamily: props.fontS, height: props.miniheight + "px", fontSize: props.miniFontSize ? Number(props.miniFontSize?.substring(0, 2)) + 2 + "px" : Number(props.FontSize?.substring(0, 2)) + 2 + "px", fontFamily: props.fontS, padding: props.minipadding, border: "1px solid #fff" }}>{userData?.first_name == "null" ? "" : userData?.first_name}{userData?.last_name == undefined ? "YourName" : " " + userData?.last_name}</td>
          <td>
            <div>
            <img className='profile-image' src={image}/>
            </div>
            </td>
          <td className='Header-image ' rowSpan="3" colSpan={2} style={{ border: "1px solid #fff", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding }}><img className='image-2' src={img} alt="LOGO" style={{ height: props.imageHeight + "px", width: props.imageWidth + "px" }} /></td>
        </tr>
        <tr className='Header-TableRow'>
          <td className='StudentDetails ' colSpan={8} style={{ fontFamily: props.fontS, border: "1px solid #fff", height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding }}>{userData?.gender == null ? "Male" : userData.gender}{console.log("age",age)} {age}</td> 
        </tr>
        <tr className='Header-TableRow'>
          <td className='StudentDetails Email ' colSpan={8} style={{ fontFamily: props.fontS, border: "1px solid #fff", height: props.miniheight + "px", fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding }}>{userData ? userData.email_id : "abc@xyz.edu"}< span className='Header-Phno'>{userData?.ph_no > 0 || "" ? "|" + userData.ph_no : ""}</span></td>
        </tr>
      </table> */}
      {/* logo - 3
      address - 5
      pic - 1 */}
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
        <div style={{ width: (img1 === "address") ? "55.55%" : (img1 === "logo") ? "33.33%" : "11.11%", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}>{img1 ? images[img1] : <></>}</div>
        <div style={{ width: (img2 === "address") ? "55.55%" : (img2 === "logo") ? "33.33%" : "11.11%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>{img2 ? images[img2] : <></>}</div>
        <div style={{ width: (img3 === "address") ? "55.55%" : (img3 === "logo") ? "33.33%" : "11.11%", display: "flex", alignItems: "flex-end", justifyContent: "center", flexDirection: "column" }}>{img3 ? images[img3] : <></>}</div>
      </div>
    </div>
    // {/* {console.log({picturePosition})}
    // <div style={{width:"100%",display:"flex"}}>
    //   { picturePosition=="Left" &&
    // <div className="FirstName-Adress">
    // <img className='profile-image' src={image}/> 
    // </div>
    //   }
    //   { picturePosition=="Centre" &&
    //   <div className="SecondName-Adress">
    //   <img className='profile-image' src={image}/>   
    //   </div>
    // }
    //   { picturePosition=="Right" &&
    //   <div className="ThirdName-Adress">
    //   <img className='profile-image' src={image}/>   
    //   </div>
    // }
    //   </div>
    //     {logoPosition ==="Left" &&
    //   <div className="FirstName-Adress">
    //   <img className='image-2' src={img} alt="LOGO" style={{ height: props.imageHeight + "px", width: props.imageWidth + "px" }}/>
    //   </div>
    //    } 
    //  {logoPosition==="Right" && 
    //   <div  className="SecondName-Adress">
    //   <img className='image-2' src={img} alt="LOGO" style={{ height: props.imageHeight + "px", width: props.imageWidth + "px" }}/>
    //   </div>
    //  } 
    //   {logoPosition==="Centre" &&
    //   <div className="ThirdName-Adress" >
    //   <img className='image-2' src={img} alt="LOGO" style={{ height: props.imageHeight + "px", width: props.imageWidth + "px" }}/>
    //   </div>
    // }
    //   </div>
    //   <div style={{display:"flex"}}>
    //         <div className="LeftPhoto"><img className='profile-image' src={image} style={{float:"left"}}/></div>
    //         <div className="CenterPhoto"><img className='profile-image' src={image} style={{float:"right"}}/></div>
    //         <div className="RightPhoto"><img className='profile-image' src={image}/></div>
    //   </div> */}
  )
}

export default Header;
