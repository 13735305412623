export const UniqueObjectsByKey = (dataArray, uniqueKey) => {
    console.log("{ dataArray, uniqueKey }:", dataArray, uniqueKey);
    // Create a Set to store unique values of the specified key
    const uniqueValuesSet = new Set();

    // Filter the array to get only unique objects based on the specified key
    const uniqueObjects = dataArray.filter((obj) => {
        const value = obj[uniqueKey];
        if (uniqueValuesSet.has(value)) {
            // If the value is already in the set, it's not unique
            return false;
        }
        // If the value is not in the set, add it and return true (keep the object)
        uniqueValuesSet.add(value);
        return true;
    });
    return uniqueObjects;
}