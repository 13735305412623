import React, { useState, useEffect } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { DivCenter } from '../../../GlobalStyles';

const MyComponent = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchData = async (page) => {
        setIsLoading(true);

        // Simulate API call with delay for demonstration purposes
        const response = await new Promise((resolve) => setTimeout(() => {
            resolve([
                // Example data objects
                { id: 1, name: 'Item 1' },
                { id: 2, name: 'Item 2' },
                { id: 3, name: 'Item 3' },
                { id: 4, name: 'Item 4' },
                { id: 5, name: 'Item 5' },
                { id: 6, name: 'Item 6' },
                { id: 7, name: 'Item 7' },
                { id: 8, name: 'Item 8' },
                { id: 9, name: 'Item 9' },
                { id: 10, name: 'Item 10' },
                { id: 11, name: 'Item 11' },
                { id: 12, name: 'Item 12' },
                { id: 13, name: 'Item 13' },
                { id: 14, name: 'Item 14' },
                { id: 15, name: 'Item 15' },
                { id: 16, name: 'Item 16' },
                { id: 17, name: 'Item 17' },
                { id: 18, name: 'Item 18' },
                { id: 19, name: 'Item 19' },
                { id: 20, name: 'Item 20' },
                { id: 21, name: 'Item 21' },
                { id: 22, name: 'Item 22' },
                { id: 23, name: 'Item 23' },
                { id: 24, name: 'Item 24' },
                { id: 25, name: 'Item 25' },
                { id: 26, name: 'Item 26' },
                { id: 27, name: 'Item 27' },
                { id: 28, name: 'Item 28' },
                { id: 29, name: 'Item 29' },
                { id: 30, name: 'Item 30' },
                { id: 31, name: 'Item 31' },
                { id: 32, name: 'Item 32' },
                { id: 33, name: 'Item 33' },
                { id: 34, name: 'Item 34' },
                { id: 35, name: 'Item 35' },
                { id: 36, name: 'Item 36' },

                // ... (more data for subsequent pages)
            ]);
        }, 1000)); // Adjust delay as needed

        setData((prevData) => [...prevData, ...response.slice((page - 1) * 5, page * 5)]);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handleScroll = (event) => {
        console.log("handleScroll ~ event:", event)
        const { scrollHeight, scrollTop, clientHeight } = event.target;

        if (scrollTop + clientHeight >= scrollHeight - 50 && !isLoading) {
            setCurrentPage((prevPage) => prevPage + 1);
            fetchData(currentPage + 1);
        }
    };

    return (
        <div style={{ ...DivCenter, width: '400px', height: '100px', background: 'grey', display: 'flex', flexDirection: 'column', overflow: 'auto' }} onScroll={handleScroll}>
            {data.length === 0 && !isLoading && (
                <p>No data available.</p>
            )}
            {isLoading && (
                <Grid container spacing={2}>
                    {[...Array(5)].fill(null).map((_, index) => (
                        <Grid item key={index} xs={12}>
                            <Skeleton variant="rect" animation="wave" width="100%" height={110} />
                        </Grid>
                    ))}
                </Grid>
            )}
            {data.map((item) => (
                <div key={item.id} style={{ marginBottom: '1rem' }}>
                    {/* Render your data item content here */}
                    <p>{item.name}</p>
                </div>
            ))}
            {isLoading && <p>Loading more...</p>}
        </div>
    );
};

export default MyComponent;
