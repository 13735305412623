import { ArrowBack, ArrowBackIosNew, Close, DateRange, DnsOutlined, EditOutlined, EqualizerOutlined, GroupAdd, KeyboardDoubleArrowRight, Settings, VideoLabel } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Step, StepLabel, Stepper, Modal, Menu, MenuItem, MenuList } from '@mui/material'
import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Color, DivCenter, OfflineSessionsContainedPrimaryButton } from '../../../../GlobalStyles'
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import createStepper from '../../../Reusable_functions/CreateStepper/CreateStepper'
import ModularHorizontalStepper from '../../../Reusable_functions/CreateStepper/CreateStepper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import ReactApexChart from 'react-apexcharts'
import { convertLocalDateTimeToEpoch, formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import StudentSummaryModule from './StudentSummaryModule'
import { SmallPageLoader } from '../../../reusables/Loader/Loader'
import MentorInterest from '../MentorInterest/MentorInterest'
import MentorShownInterest from '../MentorShownInterest/MentorShownInterest'
import { LaunchStudentAvailabilityPage1, LaunchStudentAvailabilityPage2 } from '../LaunchStudentAvailability/LaunchStudentAvailability'
import LaunchScheduleDeadline from '../LaunchSchedule/LaunchScheduleDeadline'
import LaunchScheduleSlots from '../LaunchSchedule/LaunchScheduleSlots'
import LaunchScheduleStatus from '../LaunchSchedule/LaunchScheduleStatus'

import './StudentSummary.css'
import ReleaseNextList from '../ReleaseNextList/ReleaseNextList'
import { DialogueBox } from '../../../Reusable_functions/DialogueBox/DialogueBox'
import { DeadlineTimeCalendarModal } from '../../../Reusable_functions/DeadlineTimeCalendarModal/DeadlineTimeCalendarModal'
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import MenuIcon from '@mui/icons-material/Menu';
import StepperOS from './StepperOS'
const StudentSummary = () => {


    //Data Variables
    const navigate = useNavigate();
    const service = new CCService();
    const [searchParams, setSearchParams] = useSearchParams();
    const process_id = searchParams.get("process_id");
    const slot = searchParams.get("slot");
    const mentor_id = searchParams.get("mentor_id");
    const mentor_name = searchParams.get("mentor_name");
    const [releaseNextListData, setReleaseNextListData] = useState([]);
    const [handlementor, setHandlementor] = useState(false);
    const [loading, setLoading] = useState({ page: false, activity: false, button: false });
    const [dialogue, setDialogue] = useState({
        open: false,
        onClose: () => { setDialogue((prev) => ({ ...prev, open: false })) },
        content: "",
        actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    //More than one pages data
    const [specializationData, setSpecializationData] = useState([{ id: null, name: '' }])

    const columns = [
        {
            field: 'Applicants',
            // headerClassName: 'super-app-theme--header',
            // headerAlign: 'center',
            // width: 140,
        },

        {
            field: 'Specialization',
            // headerClassName: 'super-app-theme--header',
            // headerAlign: 'center',
            // width: 140,
        },
    ];
    //page data variables
    const [stepper, setStepper] = useState([
        { id: 1, content: 'Batch Summary', navigate: `/OfflineSessions/StudentSummary?process_id=${process_id}`, completed: 0 },
        { id: 2, content: 'Mentor Interest', navigate: `/OfflineSessions/MentorInterest?process_id=${process_id}`, completed: 0, },
        { id: 3, content: 'Mentor Shown Interest', navigate: `/OfflineSessions/`, completed: 0, },
        { id: 4, content: 'Launch Student Availability', navigate: `/OfflineSessions/`, completed: 0, page: 0 },
        { id: 5, content: 'Launch Schedule', navigate: `/OfflineSessions/`, completed: 0, },
        { id: 6, content: 'Student List', navigate: `/OfflineSessions/`, completed: 0, },
    ]);
    const [activeStep, setActiveStep] = useState({ step: 1, sub_page: 0 });
    const [lastActivePage, setLastActive] = useState();
    console.log("StudentSummary ~ activeStep:", activeStep)

    const [pageState, setPageState] = useState({
        student_summary: 0,
        list_view: 0
    });

    //page scroll -> Lazy loading
    const [studentSummPage, setStudentSummPage] = useState(1);

    const [itAllAboutNumbers, setitAllAboutNumbers] = useState({
        options: {
            series: [{
                name: 'Student Count',
                data: [],

            }],
            chart: {
                height: '100%',
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 20,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                    columnWidth: "40px",
                    colors: {
                        ranges: [{
                            from: 1,
                            to: 1000,
                            color: Color.primary1
                        }],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '14px',
                    colors: [Color.primary1]
                }
            },

            xaxis: {
                categories: [],
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return val;
                    }
                },
                // crosshairs: {
                //     show: true,
                //     position: 'back',
                //     stroke: {
                //         color: '#b6b6b6',
                //         width: 1,
                //         dashArray: 0,
                //     },
                // },

            },
            title: {
                text: '',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#444'
                }
            }
        }
    });

    //fetched data
    const [pageData, setPageData] = useState({
        "college_code": "",
        "user_group_name": "",
        "name": "",
        "sku_name": "",
        "college_name": ""
    });

    //STUDENT SUMMARY
    const [studentSummaryData, setStudentSummary] = useState({
        "specialization_deadline": null,
        "spe_count": [],
        "total_count": 0
    });
    console.log("StudentSummary ~ studentSummaryData:", studentSummaryData);
    console.log("StudentSummary ~ pageData:", pageData)
    const [studentDetails, setStudentDetails] = useState([{
        "user_id": null,
        "first_name": "",
        "last_name": "",
        "email_id": "",
        "ph_no": "",
        "spe_id": null,
        "spe_name": "",
        "student_not_interested": 0,
        "resume": { "url": "", "name": "" },
        "other_docs": [{ "url": "", "name": "" }],
        "student_availability": null,
        "student_availability_proof": { "url": "", "name": "" }
    }]);
    const handleStepChange = (event, newActiveStep, newActivePage) => {
        console.log("handleStepChange ~ newActiveStep, newActivePage:", newActiveStep, newActivePage)
        if (![null, undefined].includes(newActiveStep) && [null, undefined].includes(newActivePage))
            setActiveStep((prev) => ({ ...prev, step: newActiveStep }));
        else if ([null, undefined].includes(newActiveStep) && ![null, undefined].includes(newActivePage))
            setActiveStep((prev) => ({ ...prev, sub_page: newActivePage }));
        else if (![null, undefined].includes(newActiveStep) && ![null, undefined].includes(newActivePage))
            setActiveStep((prev) => ({ ...prev, step: newActiveStep, sub_page: newActivePage }));
    };

    //MODAL
    //set deadline modal
    const [deadlineModalData, setDeadlineModalData] = useState({
        open: false,
        onClose: () => setDeadlineModalData((prev) => ({ ...prev, open: false })),
        value: null,
        timeCalendarmodal: {
            open: false,
            onClose: () => setDeadlineModalData((prev) => ({ ...prev, timeCalendarmodal: { ...prev.timeCalendarmodal, open: false } }))
        },
        extraData: {
            process_id: null
        }
    })

    //MENTOR INTEREST
    const [mentorInterestData, setMentorInterestData] = useState({
        "process_id": null,
        "specialization_deadline": null,
        "session_date_slot_count_mapping": [
            {
                "session_date": null,
                "per_mentor_slot_count": ""
            },
        ],
        "mentor_cities": [],
        "likely_schedule": "",
        "trip_details": "",
        "mentor_interest_deadline": null,
        "mentor_compensation": "",
        "departure_cities": [],
        "arrival_cities": [],
        "student_availability_deadline": null,
        "slot_selection_deadline": null,
        "no_of_student_per_list": null,
        "percent_cutoff_for_new_list": null
    });
    const [mentorCities, setMentorCities] = useState([]);

    //MENTOR SHOWN INTEREST
    //In MentorShownInterest Page

    const mainButtonFunctions = {
        "1": () => { handleStepChange(null, 2, null) },
        "2": () => { handleStepChange(null, 3, null) },
        "3": () => {
            console.log("stepper[3].completed", stepper[3].completed);
            if (stepper[3].completed) {
                handleStepChange(null, 4, 1)
            } else {
                handleStepChange(null, 4, null)
            }
        },
        "4": () => { handleStepChange(null, 5, null) },
        "5": () => {
            {
                setDialogue((prev) => ({
                    ...prev,
                    open: true,
                    actions: [
                        { width: '40%', label: 'Reject', handler: () => { dialogue.onClose() }, color: Color.red, variant: "outlined" },
                        { width: '40%', label: 'Accept', handler: () => { handleReleaseNextSlot(); }, color: Color.green, background: Color.green_highlight, variant: "outlined" }
                    ],
                    title: 'Confirmation',
                    content: 'Are you sure you want to release the first list',
                }))
            }
        },
        "6": () => {
            setDialogue((prev) => ({
                ...prev,
                open: true,
                actions: [
                    { width: '40%', label: 'Reject', handler: () => { dialogue.onClose() }, color: Color.red, variant: "outlined" },
                    { width: '40%', label: 'Accept', handler: () => { releaseNextListData[3][0].students_waiting === 0 ? handleMarkAsComplete() : handleReleaseNextSlot() }, color: Color.green, background: Color.green_highlight, variant: "outlined" }
                ],
                title: 'Confirmation',
                content: releaseNextListData[3][0].students_waiting === 0 ? 'Are you sure you want to mark process complete?' : 'Are you sure you want to release the next list',
            }))
        }
    }

    console.log("relllllll", mentorInterestData.mentor_cities && mentorInterestData.mentor_cities.length)
    // FUNCTIONS    
    useEffect(() => {
        handleMainEffect();
    }, []);

    useEffect(() => {

        const list_data = releaseNextListData;
        console.log("releaseNextListData in useEffect :", releaseNextListData)
        const updatedSteps = stepper?.map((step, index) => {
            switch (index) {
                case 0:
                    return { ...step, completed: studentSummaryData.specialization_deadline < Date.now() ? 1 : 0 }
                    break;
                case 1: return { ...step, completed: mentorInterestData.mentor_cities && mentorInterestData.mentor_cities.length > 0 ? 1 : 0 }
                    break;
                case 2: return { ...step, completed: mentorInterestData.mentor_interest_deadline && mentorInterestData.mentor_interest_deadline < Date.now() ? 1 : 0 }
                    break;
                case 3: return { ...step, completed: mentorInterestData.student_availability_deadline && mentorInterestData.student_availability_deadline < Date.now() ? 1 : 0 }
                    break;
                case 4: return { ...step, completed: (releaseNextListData.length && releaseNextListData[0].length > 0) ? 1 : 0 }
                    break;
                case 5: return { ...step, completed: (releaseNextListData[5] && releaseNextListData[5][0]?.status_name === "Done") ? 1 : 0 } // last session_date
                default: return { ...step }
                    break;
            }

            return step;
        });
        const lastCompletedIndex = updatedSteps.reduceRight((acc, step, index) => step.completed === 1 ? acc === -1 ? index : acc : acc, -1);
        const temp_value = lastCompletedIndex === 5 ? lastCompletedIndex + 1 : lastCompletedIndex === 1 ? lastCompletedIndex + 3 : lastCompletedIndex + 2;
        console.log("temp value in student summary:", temp_value);
        setActiveStep({ step: temp_value === 1 ? 2 : temp_value, sub_page: activeStep.sub_page ?? 0 }); // mentor interest and student cummary will happen simultaneously 
        const active_step = searchParams.get("active_step");
        const handle_mentor = searchParams.get("handlementor");
        if (active_step) {
            console.log("active_step+from :", JSON.parse(active_step))
            setActiveStep(JSON.parse(active_step));
        }
        if (handle_mentor) {
            setHandlementor(true);
        }
        const last_active = temp_value === 1 ? 2 : temp_value;
        setLastActive(last_active);
        setStepper(updatedSteps);
    }, [studentSummaryData, mentorInterestData, releaseNextListData]);

    console.log('temp value in setActiveStep:', activeStep)
    const handleMainEffect = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        const specilization_data = await getSpecializationData();
        setSpecializationData(specilization_data);
        //STUDENT SUMMARY
        try {
            const student_summary = await getStudentSummary();      //Specialization count
            console.log("handleMainEffect ~ student_summary:", student_summary);
            //data-representation data
            const { remade_student_summary } = representationData(student_summary);
            console.log("handleMainEffect ~ remade_data:", remade_student_summary);
            setStudentSummary((prev) => ({
                ...prev,
                "specialization_deadline": remade_student_summary.specialization_deadline,
                "spe_count": remade_student_summary.spe_count,
                "total_count": remade_student_summary.total_count
            }));
            const student_details = await getStudentDetails(studentSummPage);      //ORDER

            console.log("student_details.forEach ~ student_details:", student_details)
            setStudentDetails(student_details);
        } catch (error) {
            console.error("Student Summary ~ error:", error);
        }

        //MENTOR INTEREST
        try {
            const mentor_interest = await getMentorInterest();      //Properties [GET]
            setMentorInterestData(mentor_interest);
            const cities = await getMentorCities();
            setMentorCities(cities);
        } catch (error) {
            console.error("Mentor Interest ~ error:", error);
        }

        //MENTOR SHOWN INTEREST
        //In Main shown interest page

        const response_stat_data = await getStatLists();
        console.log("response_stat_data", response_stat_data);
        setReleaseNextListData(response_stat_data);

        setLoading((prev) => ({ ...prev, page: false }));
    }

    console.log("releaseNextListData in student summary:", releaseNextListData);

    // get stat list - release next list
    const getStatLists = async () => {
        try {
            const response = await service.get(`/os/superadmin/studentDetails?process_id=${process_id}`);
            console.log("getStatLists ~ response:", response);
            return response.data;
        } catch (error) {
            console.error('Error while fetching stat data:', error);
        }
    }

    const getSpecializationData = async () => {
        try {
            const response = await service.get(`/superAdmin/specialization`);
            return response.data;
        } catch (error) {
            console.error('Error while fetching specialization:', error);
        }
    }

    //STUDENT SUMMARY
    const getStudentSummary = async () => {
        try {
            const response = await service.get(`/os/superadmin/specializationCount?process_id=${process_id}`);
            console.log("getStudentSummary ~ response:", response);
            setPageData((prev) => ({
                ...prev,
                "college_code": response.description.college_code,
                "user_group_name": response.description.user_group_name,
                "name": response.description.name,
                "sku_name": response.description.sku_name,
                "college_name": response.description.college_name
            }))
            return response;
        } catch (err) {
            console.error("Error while fetching student summary:", err);
        }
    }

    const getStudentFilterDetails = async (page, flag) => {
        try {
            const response = flag === "Not yet selected" ?
            await service.get(`/os/superadmin/order?&page=${page}&process_id=${process_id}&limit=100&not_yet_selected=1`)
            :  flag === "Student not interested" ?
            await service.get(`/os/superadmin/order?page=${page}&process_id=${process_id}&limit=100&student_not_interested=1`)
            :
            await service.get(`/os/superadmin/order?page=${page}&process_id=${process_id}&limit=100&spe_filter=${flag}`)
               return response;    
        } catch (err) {
            console.log("err:", err);
        }
    }
    const getStudentDetails = async (page = 1, searchQuery, flag=undefined) => {
        try {
            if (page) {
                const response = searchQuery
                    ? await service.get(`/os/superadmin/order?process_id=${process_id}&page=${page}&limit=100&searchQuery=${searchQuery}`)
                    : flag
                        ? await getStudentFilterDetails(page, flag)
                        : await service.get(`/os/superadmin/order?process_id=${process_id}&page=${page}&limit=100`);
                console.log("getStudentDetails ~ response:", response.data);
                if (response?.data) {
                    const data = response.data;
                    if (data.length)
                        data.forEach((order) => {
                            order.resume = JSON.parse(order.resume);
                            order.other_docs = JSON.parse(order.other_docs);
                            order.student_availability_proof = JSON.parse(order.student_availability_proof);
                        });
                    return data;
                } else return [];
            }
        } catch (err) {
            console.error("Error while fetching student details:", err);
        }
    }


    const representationData = (studentSummary_data) => {
        /* 
            This function is mainly just to set representation data in itAllAboutNumbers, 
                but we're returning just in case in the future there is any required change in data structure 
        */

        let data = { ...studentSummary_data };
        // if ([0, 1, 2].includes(studentSummary_data.spe_count.length))
        //     data.spe_count = [
        //         {
        //             spe_id: 1,
        //             spe_name: 'HR',
        //             student_count: 1
        //         },
        //         {
        //             spe_id: 2,
        //             spe_name: 'IT',
        //             student_count: 2
        //         },
        //         {
        //             spe_id: 3,
        //             spe_name: 'Sales & Marketing',
        //             student_count: 3
        //         },
        //         {
        //             spe_id: 4,
        //             spe_name: 'Finance',
        //             student_count: 40
        //         },
        //         {
        //             spe_id: 5,
        //             spe_name: 'Consulting',
        //             student_count: 50
        //         },
        //         {
        //             spe_id: 6,
        //             spe_name: 'Operations',
        //             student_count: 60
        //         },
        //         {
        //             spe_id: 7,
        //             spe_name: 'Analytics',
        //             student_count: 70
        //         },
        //         {
        //             spe_id: 8,
        //             spe_name: 'Yet to select',
        //             student_count: 10
        //         },
        //     ];
        let temp_itAllNumbers = { ...itAllAboutNumbers };
        temp_itAllNumbers.options.series[0].data = data.spe_count.map((item) => item.student_count ?? item.total_student_not_interested);
        temp_itAllNumbers.options.xaxis.categories = data.spe_count.map((item) => (item.total_student_not_interested || item.total_student_not_interested == 0) ? "Students not interested" : item.spe_name ?? 'Not yet selected');
        console.log("representationData ~ temp_itAllNumbers:", temp_itAllNumbers.options.xaxis.categories, data, itAllAboutNumbers, temp_itAllNumbers)
        setitAllAboutNumbers(temp_itAllNumbers);
        return { remade_student_summary: data };
    }


    //MENTOR INTEREST
    const getMentorInterest = async () => {
        try {
            const response = await service.get(`/os/superadmin/properties?process_id=${process_id}`);
            console.log("Mentor Interest:", response.data);
            const format_data = formatMentorInterest(response.data);
            return format_data;
        } catch (error) {
            console.error("Error while fetching mentor interest:", error);
        }
    }

    const formatMentorInterest = (fetched_data) => {
        let data = { ...fetched_data };
        data = {
            ...data,
            session_date_slot_count_mapping: data.session_date_slot_count_mapping?.session_date_slot_count_mapping ?? [],
            mentor_cities: data.mentor_cities?.mentor_cities ?? [],
            departure_cities: data.departure_cities?.departure_cities ?? [],
            arrival_cities: data.arrival_cities?.arrival_cities ?? [],
        }
        return data;
    }

    const getMentorCities = async () => {
        try {
            const response = await service.get(`os/superadmin/mentorCities`);
            const cities = response.cities.map((city) => city.city);
            return cities;
        } catch (error) {
            console.error("Error while fetching mentor cities:", error);
        }
    }

    //MENTOR SHOWN INTEREST
    //In MentorShownInterest Page

    //OTHER FUNCTIONS
    const handleReleaseNextSlot = async () => {
        try {
            await service.put(`os/superadmin/launchSchedule`, { "process_id": parseInt(process_id) });
            const res_getStatLists = await getStatLists();
            setReleaseNextListData(res_getStatLists);
            dialogue.onClose();
        } catch (error) {
            console.error('Error while putting release next list:', error);
        }
    }

    const handleMarkAsComplete = async () => {
        try {
            await service.put(`/os/superadmin/endProcess`, { "process_id": parseInt(process_id) });
            const res_getStatLists = await getStatLists();
            setReleaseNextListData(res_getStatLists);
            handleMainEffect();
            dialogue.onClose();
        } catch (error) {
            console.error('Error while putting end process:', error);
        }
    }
    const handleDeadlineTimeCalendarModal = () => {
        setDeadlineModalData((prev) => ({ ...prev, timeCalendarmodal: { ...prev.timeCalendarmodal, open: true }, open: false }));
    }

    const handleDeadlineTimeCalendarSave = async (value, time) => {
        console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time)
        // const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
        const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
        const epochTime = date.getTime()
        console.log("handleDeadlineTimeCalendarSave ~ epochTime:", epochTime,);
        setDeadlineModalData((prev) => ({ ...prev, open: true, value: epochTime, timeCalendarmodal: { ...prev.timeCalendarmodal, open: false } }));
    }

    //MODALS
    function SetDeadlineModal({ open, onClose, extraData }) {
        const modalBoxStyle = {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            padding: "1.5rem 2rem",
            borderRadius: "20px",
            width: "600px",
            height: "350px",
            minHeight: '250px'
        };

        const handlePutOfflineSessionProperties_setDeadline = async () => {
            try {
                const response = await service.put(`/os/superadmin/properties`, {
                    "process_id": deadlineModalData.extraData.process_id,
                    "specialization_deadline": deadlineModalData.value,
                })
                deadlineModalData.timeCalendarmodal.onClose();
                deadlineModalData.onClose();
                console.log("response:", response);
                handleMainEffect();
            } catch (err) {
                console.log(err)
            }
        }



        return (
            <Modal open={open} onClose={onClose}>
                <Box sx={{ ...modalBoxStyle, justifyContent: 'space-between', gap: '20px' }}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', alignItems: 'space-between', justifyContent: "space-between", marginBottom: "8px", }}>
                            <Typography style={{ fontSize: "24px", fontWeight: 'bold' }}>
                                Set deadline
                            </Typography>
                            <Typography style={{ fontSize: "16px", color: Color.accent }}>
                                Specify a deadline for student to select specialization.
                            </Typography>
                            {/* <hr style={{ width: '100%', margin: '8px 0' }} /> */}
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', width: '100%', gap: '8px' }}>
                            <Typography style={{ fontSize: "14px", color: Color.neutralBlack, fontWeight: 'bold' }}>Deadline for choosing specialization<span style={{ color: Color.red }}>*</span></Typography>
                            <Box
                                onClick={() => deadlineModalData.value ? {} : handleDeadlineTimeCalendarModal()}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `1px solid ${Color.neutralMidGrey}`, // Set background color
                                    borderRadius: 4, // Add border radius
                                    padding: '8px', // Add padding
                                    cursor: deadlineModalData.value ? 'default' : 'pointer', // Simulate clickable behavior
                                    width: '100%'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, color: Color.neutralMidGrey }}>
                                    {deadlineModalData.value ?
                                        <Box sx={{ ...DivCenter, justifyContent: 'space-between', p: '8px 12px', cursor: 'default', width: '30%', minWidth: '230px', height: '45px', borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.neutralMidGrey }}>
                                            <Close
                                                sx={{ fontSize: '16px', cursor: 'pointer' }}
                                                onClick={() => setDeadlineModalData((prev) => ({ ...prev, value: null }))} />
                                            {formatConvertermonthfirst(deadlineModalData.value, true)}
                                        </Box>
                                        : "Specify the deadline"}
                                </Box>
                                <IconButton aria-label="Pick Date" disabled={deadlineModalData.value} onClick={() => { handleDeadlineTimeCalendarModal() }}>
                                    <DateRange />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Button variant="contained"
                            onClick={() => handlePutOfflineSessionProperties_setDeadline()}
                            sx={{ width: '40%', minWidth: '150px', height: '54px', background: Color.primary_gradient }}>Submit</Button>
                    </Box>
                </Box>
            </Modal>);
    }

    // console.log("studentSummaryData", studentSummaryData)

    // console.log("asunta:", studentSummaryData.spe_count.map((spe, spe_index) => ({
    //     Specialization: spe.spe_name ?? 'Not yet selected',
    //     Applicants: spe.student_count
    // })));
    const apiRef = useGridApiRef();
    const handleDownloadCSV = () => {
        apiRef.current.exportDataAsCsv();
    };
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ width: "100%", }}>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    };
    console.log("rows", [...studentSummaryData.spe_count, { spe_name: "Total students", student_count: studentSummaryData.total_count }].map((spe, spe_index) => ({
        id: spe_index,
        Specialization: spe.spe_name ?? 'Not yet selected',
        Applicants: spe.student_count
    })));

    return (

        <>
            {/* <StepperOS/> */}
            {loading.page ?
                <SmallPageLoader show={loading.page} />
                : <Box sx={{ p: '16px 90px 16px 138px', minWidth: '1311px', }}>
                    <SideNavigationBar />
                    <Box sx={{ ...DivCenter, width: '100%', justifyContent: "space-between" }}>
                        <Box sx={{ ...DivCenter, }}>
                            <Box sx={{ ...DivCenter, justifyContent: 'flex-start', }}>
                                <ArrowBackIosNew onClick={() => navigate(-1)} sx={{ width: '34px', height: '34px', cursor: 'pointer' }} />
                            </Box>
                            <Box sx={{}}>
                                <Typography style={{ fontSize: '24px', color: Color.primary1 }}>{pageData.sku_name}</Typography>
                                <Typography style={{ fontSize: '16px', color: Color.neutralMidGrey }}>{pageData.college_name}, {pageData.user_group_name}, {pageData.name}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ ...DivCenter, justifyContent: 'flex-end' }}>
                            {activeStep.step === 6 && <Box onClick={() => { navigate("/OfflineSessions/PocketExpenses?process_id=" + process_id) }} style={{ padding: "0px 6px" }}>
                                <Typography style={{ cursor: "pointer", fontSize: "20px", color: Color.primary1, marginRight: "12px" }}>Out of Pocket Expenses</Typography>
                            </Box>}
                            {((releaseNextListData[5] && releaseNextListData[5][0]?.status_name === "Done") || (activeStep.step === 4 && activeStep.sub_page === 0) || (activeStep.step === 5 && [0].includes(activeStep.sub_page)) || (!stepper[activeStep.step - 1].completed && ![1, 5, 6].includes(activeStep.step))) ? <></> :
                                <OfflineSessionsContainedPrimaryButton variant={activeStep.step === 6 && releaseNextListData[3][0].students_waiting == 0 ? 'outlined' : 'contained'} onClick={() => mainButtonFunctions[activeStep.step]()}>
                                    {activeStep.step === 1 ? "Collect Mentor Interest"
                                        : activeStep.step === 2 ? "Mentor Shown Interest"
                                            : activeStep.step === 3 ? "Launch Student Availability"
                                                : activeStep.step === 4 ? "Launch Student Availability"
                                                    : activeStep.step === 5 ? "Release First List"
                                                        : activeStep.step === 6 ? releaseNextListData[3][0].students_waiting == 0 ? "Mark Activity Complete" : "Release Next List" : ""}
                                    {activeStep.step === 6 && releaseNextListData[3][0].students_waiting == 0 ? "" : <KeyboardDoubleArrowRight sx={{ height: '50px', width: '50px' }} />}
                                </OfflineSessionsContainedPrimaryButton>}
                        </Box>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%', padding: '48px 0' }}>
                        <StepperOS
                            steps={stepper}
                            setSteps={setStepper}
                            activeStep={activeStep}
                            lastActivePage={lastActivePage}
                            setActiveStep={setActiveStep}
                            studentSummaryData={studentSummaryData}
                            connectorColor="blue"
                            stepBackgroundColor={Color.primary1} // Example customization
                            completedColor="green" // Example customization (optional)
                            onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                        />

                    </Box>
                    {/* Student Summary */}
                    {activeStep.step === 1 ?
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', padding: '48px 0 24px 0', gap: '10px' }}>
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: '24px', color: Color.neutralBlack }}>Batch Specialization Preference</Typography>
                                <Box onClick={() => { setDeadlineModalData((prev) => ({ ...prev, value: studentSummaryData.specialization_deadline, open: true, extraData: { process_id: process_id } })) }} sx={{ ...DivCenter, gap: '8px' }}>
                                    <Typography style={{ fontSize: '18px', color: Color.neutralMidGrey, cursor: 'pointer' }}>
                                        Deadline: {formatConvertermonthfirst(studentSummaryData.specialization_deadline, true)}
                                    </Typography>
                                    <EditOutlined sx={{ height: '20px', width: '20px', color: Color.primary1, cursor: 'pointer' }} />
                                </Box>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: '20px', color: Color.neutralMidGrey }}>
                                    {!pageState.student_summary ?
                                        "Number of students opted for different specialization"
                                        : "Student wise specialization preference data"}</Typography>
                                <IconButton onClick={() => setPageState((prev) => ({ ...prev, list_view: !prev.list_view }))} style={{ fontSize: '16px', cursor: 'pointer' }}>
                                    {!pageState.student_summary ? !pageState.list_view ? <DnsOutlined sx={{ color: Color.primary1 }} /> : <EqualizerOutlined sx={{ color: Color.primary1 }} /> : <></>}
                                </IconButton>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start' }}>
                                <Typography
                                    onClick={() => setPageState((prev) => ({ ...prev, student_summary: !prev.student_summary }))}
                                    style={{
                                        fontSize: '16px',
                                        color: Color.primary1,
                                        cursor: 'pointer',
                                        textDecoration: 'none', // Remove default underline
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}>
                                    {!pageState.student_summary ? "View student summary" : "View batch summary"}
                                </Typography>

                            </Box>
                            {pageState.student_summary ?
                                <StudentSummaryModule process_id={process_id} studentDetails={studentDetails} pageData={pageData} setStudentDetails={setStudentDetails} getStudentDetails={getStudentDetails} specializationData={specializationData} />
                                : !pageState.list_view ?
                                    <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', width: '60%', justifyContent: 'flex-end' }}>
                                            <Typography style={{ fontSize: '16px', color: Color.primary1, }}>Total Students: {studentSummaryData.total_count}</Typography>
                                        </Box>
                                        <ReactApexChart
                                            options={itAllAboutNumbers.options}
                                            series={itAllAboutNumbers.options.series}
                                            type="bar"
                                            height={itAllAboutNumbers.options.series[0].data.length * 70}
                                            width={950}
                                        />
                                    </Box>
                                    : <Box

                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-start",
                                            width: "600px",
                                            '& .super-app-theme--header': {
                                                backgroundColor: '#EFF6FF',
                                            },
                                        }} >

                                        <DataGrid columns={[
                                            {
                                                field: 'Specialization',
                                                headerClassName: 'super-app-theme--header',
                                                headerAlign: 'left',
                                                align: "left",
                                                flex: 0.7,
                                                // width: "100px",
                                            }, {
                                                field: 'Applicants',
                                                headerClassName: 'super-app-theme--header',
                                                flex: 0.5,
                                                width: "40vw",
                                            },]}
                                            apiRef={apiRef}
                                            getRowClassName={(params) =>
                                                params.isLastVisible ? 'super-app-theme--header' : params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                            }
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            rows={[...studentSummaryData.spe_count, { spe_name: "Total students", student_count: studentSummaryData.total_count }].map((spe, spe_index) => ({
                                                id: spe_index,
                                                Specialization: (spe.total_student_not_interested || spe.total_student_not_interested == 0) ? "Students not interested" : spe.spe_name ?? 'Not yet selected',
                                                Applicants: spe.student_count ?? spe.total_student_not_interested
                                            }))} />

                                        <div>
                                            <Button
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                                variant="text"

                                                sx={{ marginBottom: 2, color: Color.darkerGrey }}>
                                                <MenuIcon />
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            // MenuListProps={{
                                            //     'aria-labelledby': 'basic-button',
                                            // }}
                                            >
                                                <MenuItem onClick={() => { handleDownloadCSV(); handleClose(); }}>Download CSV</MenuItem>
                                            </Menu>
                                        </div>

                                    </Box>
                                // rows={studentSummaryData.spe_count.map((spe, spe_index) =>{})}  />

                                //  <Box sx={{ ...DivCenter, width: '60%', flexDirection: 'column', pt: '48px' }}>
                                //     <Box sx={{ ...DivCenter, width: '50%', height: '43px', background: 'rgba(239, 246, 255, 1)' }}>
                                //         <Box sx={{ ...DivCenter, width: '50%', height: '100%' }}><Typography style={{ textAlign: 'center', width: '100%' }}>Specialization</Typography></Box>
                                //         <Box sx={{ ...DivCenter, width: '50%', height: '100%' }}><Typography style={{ textAlign: 'center', width: '100%' }}>Applicants</Typography></Box>
                                //     </Box>
                                //     {studentSummaryData.spe_count.map((spe, spe_index) =>
                                //         <Box sx={{ ...DivCenter, width: '50%', height: '43px', background: spe_index % 2 ? 'rgba(250, 250, 250, 1)' : '' }}>
                                //             <Box sx={{ ...DivCenter, width: '50%', height: '100%' }}><Typography style={{ textAlign: 'center', width: '100%' }}>{spe.spe_name ?? 'Not yet selected'}</Typography></Box>
                                //             <Box sx={{ ...DivCenter, width: '50%', height: '100%' }}><Typography style={{ textAlign: 'center', width: '100%' }}>{spe.student_count}</Typography></Box>
                                //         </Box>)}
                                //     <Box sx={{ ...DivCenter, width: '50%', height: '43px', background: 'rgba(239, 246, 255, 1)' }}>
                                //         <Box sx={{ ...DivCenter, width: '50%', height: '100%' }}><Typography style={{ textAlign: 'center', width: '100%' }}>Total Students</Typography></Box>
                                //         <Box sx={{ ...DivCenter, width: '50%', height: '100%' }}><Typography style={{ textAlign: 'center', width: '100%' }}>{studentSummaryData.total_count}</Typography></Box>
                                //     </Box>
                                // </Box>
                            }
                            <SetDeadlineModal
                                open={deadlineModalData.open}
                                onClose={deadlineModalData.onClose}
                                extraData={deadlineModalData.extraData}
                            />
                            <DeadlineTimeCalendarModal
                                open={deadlineModalData.timeCalendarmodal.open}
                                onClose={deadlineModalData.timeCalendarmodal.onClose}
                                extraData={{
                                    heading: "Choose Deadline",
                                    action_button: "Save",
                                    handleAction: (a, b) => handleDeadlineTimeCalendarSave(a, b)
                                }}
                            />
                        </Box>
                        : activeStep.step === 2 ?
                            <MentorInterest
                                mentorInterestData={mentorInterestData}
                                setMentorInterestData={setMentorInterestData}
                                mentorCities={mentorCities}
                                process_id={process_id}
                                onStepChange={(z, a, b) => handleStepChange(z, a, b)} />
                            : activeStep.step === 3 ?
                                <MentorShownInterest
                                    specializationData={specializationData}
                                    process_id={process_id}
                                    mentor_interest_deadline={mentorInterestData.mentor_interest_deadline}
                                    studentSummaryData={studentSummaryData}
                                    onStepChange={(z, a, b) => handleStepChange(z, a, b)} />
                                : activeStep.step === 4 ?
                                    activeStep.sub_page === 0 ?
                                        <div>
                                            <div>{activeStep.sub_page}</div>
                                            <LaunchStudentAvailabilityPage1
                                                specializationData={specializationData}
                                                student_availability_deadline={mentorInterestData.student_availability_deadline}
                                                setMentorInterestData={setMentorInterestData}
                                                process_id={process_id}
                                                onStepChange={(z, a, b) => handleStepChange(z, a, b)} /></div>
                                        : <LaunchStudentAvailabilityPage2
                                            process_id={process_id}
                                            pageData={pageData}
                                            college_details={pageData}
                                            mentorInterestData={mentorInterestData}
                                            orderDetails={studentDetails}
                                            setOrderDetails={setStudentDetails}
                                            getStudentDetails={getStudentDetails}
                                            specilizationCountData={studentSummaryData}
                                            onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                                        />
                                    : activeStep.step === 5 ?
                                        activeStep.sub_page === 0 ?
                                            <LaunchScheduleDeadline
                                                process_id={process_id}
                                                onStepChange={(z, a, b) => handleStepChange(z, a, b)} />
                                            : activeStep.sub_page === 1 ?
                                                <LaunchScheduleSlots
                                                    process_id={process_id}
                                                    slot_count_mapping={mentorInterestData.session_date_slot_count_mapping}
                                                    onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                                                    slot={slot}
                                                    mentor_id={mentor_id}
                                                    mentor_name={mentor_name}
                                                    handlementor={handlementor} />
                                                : activeStep.sub_page === 2 ?
                                                    <LaunchScheduleStatus
                                                        process_id={process_id}
                                                        onStepChange={(z, a, b) => handleStepChange(z, a, b)} />
                                                    : <></>
                                        : activeStep.step === 6 ?
                                            <ReleaseNextList
                                                process_id={process_id}
                                                releaseNextListData={releaseNextListData}
                                                getStatLists={getStatLists}
                                                onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                                            />
                                            : <></>}
                    <DialogueBox
                        open={dialogue.open}
                        onClose={dialogue.onClose}
                        title={dialogue.title}
                        actions={dialogue.actions}
                        content={<>
                            <p>{dialogue.content}</p>
                        </>}
                    />
                </Box >}
        </>
    )
}

export default StudentSummary