import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import axios from 'axios';

// API call to fetch mentor role and specialization details
const roleAndSpecializationMentorGetter = async (page, process_id) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_RD_API_URL}/superAdmin/processRoleCount?process_id=${process_id}`);
        console.log("batch card details response:", res.data);
        return res.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

// API call to fetch role types

// API call to update max_count_allowed
const updateMaxCountAllowed = async (processId, roleId, newCount) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_RD_API_URL}/superAdmin/processRoleCount`, {
            process_id: processId,
            role_ids: [roleId],
            max_count_allowed: parseInt(newCount)
        });
        console.log("Update response:", res.data);
        return res.data;
    } catch (err) {
        console.error(err);
        window.alert(err.response.data.message);
    }
};

const LazyLoadingList = ({ process_id, roleTypess }) => {
    const [items, setItems] = useState([]);
    const [roleTypes, setRoleTypes] = useState(roleTypess);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const containerRef = useRef(null);

    useEffect(() => {
        // Initial load
        loadRoleTypes();
        loadMoreItems();
    }, []);

    const loadRoleTypes = async () => {

        setRoleTypes(roleTypes);
    };

    const loadMoreItems = async () => {
        setLoading(true);
        const newItems = await roleAndSpecializationMentorGetter(page, process_id);
        console.log("newItems", newItems);
        if (Array.isArray(newItems.data)) {
            setItems((prevItems) => [...prevItems, ...newItems.data]);
        }
        setLoading(false);
        setPage((prevPage) => prevPage + 1);
    };

    

    const handleInputChange = (index, newValue) => {
        const newItems = [...items];
        newItems[index].max_count_allowed = newValue;
        setItems(newItems);
    };

    const handleBlur = (item) => {
        updateMaxCountAllowed(item.process_id, item.role_id, item.max_count_allowed);
    };

    const getRoleName = (roleId) => {
        const role = roleTypes.find(role => role.id === roleId);
        return role ? role.name : 'Unknown Role';
    };
    console.log("items", items);

    const handleSave = (item) => {
        updateMaxCountAllowed(item.process_id, item.role_id, item.max_count_allowed);
    };

    return (
        <div>
            {items.length > 0 ?
                <div
                    style={{
                        maxHeight: '250px',
                        overflowY: 'scroll',
                        border: '1px solid #ccc',
                        padding: '10px'
                    }}
                >
                    <ul style={{marginLeft:"0px"}}>
                        {items.map((item, index) => (
                            <li key={item.role_id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <span style={{ marginRight: '10px', width:"70%" }}>{getRoleName(item.role_id)}</span>
                                <input
                                    type="number"
                                    value={item.max_count_allowed}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    style={{ marginRight: '10px', width:"15%" }}
                                />
                                <button style={{width:'15%', height:"auto", borderRadius:"3px"}} type="button" className='submitBtn'  onClick={() => handleSave(item)}>Save</button>
                            </li>
                        ))}
                    </ul>
                    {loading && <p>Loading more items...</p>}
                </div> : <>
                <p className="feedbackNotProvided">Role has not been assigned yet</p>
                </>}
        </div>

    );
};

export default LazyLoadingList;
