import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, IconButton, Modal, Slide, Typography } from "@mui/material"
// import CloseIcon from '@mui/icons-material/Close';
import { Close, LocationOn, PaymentsOutlined, PeopleAltOutlined, Person, RadioButtonChecked, RadioButtonUncheckedTwoTone } from '@mui/icons-material';
import { Color, DivCenter } from "../../../../GlobalStyles";
import './SlideInRightModal.css'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import { makeTextBold } from '../../../Reusable_functions/htmlParser/htmlParser';
import TimeService from '../../../../functions/epochTime';

export const SlideInRightModal = ({ open, onClose, data, onHoldRemarks, setOnHoldRemarks }) => {
    console.log("data:", data)
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });
    const style = {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100vh', // Set the height to cover the entire screen vertically
        width: '50%', // Set the width to cover half of the screen
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: '5px 0px 0px 5px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        transform: open ? 'translateX(0%)' : 'translateX(100%)', // Slide in from the right
        transition: 'transform 0.3s ease-out', // Add a smooth transition
    };


    // DATA
    const { company_name, date = 'No date', status = 'N/A', time, role, specialization, closed_timing, responsibilities, job_id, applications, buttons } = data;
    console.log("job_id:", job_id);
    // const { applied, process, shortlist, hired } = applications;
    const services = new CCService();
    const timeServices = new TimeService();
    const [loading, setLoading] = useState(false);
    // const jdData = {
    //     job_type_name: 'FTE',
    //     roles: 'FrontEnd',
    //     spe: 'FullStack',
    //     location: 'Bangalore',
    //     fixed_component: '5000',
    //     openings: '2',
    //     key_responsibilities: [
    //         'Acquisition of large customer accounts through multilevel engagements',
    //         'Manage multiple large and mid - size customer accounts.Identify and evaluate the key business issues in the areas of logistics and supply chain financing and present relevant solutions',
    //         'Develop comprehensive understanding on customer business, products and logistics and transportation requirements to generate and expand business engagement through one or more services offered by 4TiGO',
    //         'Develop and execute customer account strategy',
    //         'Monitor, measure, and report the sales metrics of the customers for different services and identify the growth opportunities',
    //         'Customer Relationship Management – Communicate effectively and develop relationship with customer stakeholders at various levels of the customer hierarchy'
    //     ],
    //     role_proficiencies: [
    //         'More than 2 years (In the areas of Sales & Business Development) • You have strong communication and interpersonal skills',
    //         'You have a way with people, and you are particularly good at building rapport with internal stakeholders and customers ',
    //         'You are intrigued by the prospect of making your way through learning and implementing emerging technologies and business processes ',
    //         'You have Data- driven decision mentality and sound business judgment through strong analytical thinking'
    //     ],
    //     skills: ['Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Skill', 'Skill'],
    //     responsibilities: responsibilities
    // }

    const initial_jdData = {
        "responsibilities": null,
        "job_published_time": "",
        "roles": "",
        "spe": "",
        "job_status": "",
        "jd": {
            "key_responsibilities": [],
            "role_proficiencies": []
        },
        "openings": null,
        "fixed_component": null,
        "job_type_name": "",
        "location": null,
        "assessment_name": [],
        "assessments": null,
        "perks": null,
        "variable_component": 0,
        "designation": null,
    };
    const [jdData, setJdData] = useState({ ...initial_jdData });
    console.log("jdData:", jdData)

    //UseEffect
    useEffect(() => {
        const handleMainUseEffect = async () => {
            const jobTable_data = await fetchJobTableData();
            console.log("jobTable_data:", jobTable_data);
            let temp_data = { ...jobTable_data };
            temp_data.assessment_name = temp_data.assessment_name ? temp_data.assessment_name.split(",") : [];
            if (!temp_data.variable_component) temp_data.variable_component = null;
            if (!temp_data.perks || temp_data.perks === "") temp_data.perks = null;
            console.log("temp_data:", temp_data);
            if (temp_data) setJdData(temp_data);
            setLoading(false);
        }
        handleMainUseEffect();
    }, [job_id]);

    const fetchJobTableData = async () => {
        if (job_id) {
            try {
                setLoading(true);
                const response = await services.get(`/jobs/admin/jobById?job_id=${job_id}`);
                console.log("response:", response);
                return response.details[0];
            } catch (error) {
                console.error("Failed to fetch job table data:", error);
            }
        }
    }


    //clicking accept - calls put req - closes modal
    const handleAcceptClick = async () => {
        if (status === 'In review') {
            //show the remarks modal
            setOnHoldRemarks((prev) => ({ ...prev, flag: 'Accept', show: true, content: "" }));
        } else {
            window.alert(`job_status is not in review, it is: ${status}`)
        }
    }

    //clicking on hold - takes remarks input - calls put req - closes modal
    const handleOnHoldClick = async () => {
        if (status === 'In review') {
            //show the remarks modal
            setOnHoldRemarks((prev) => ({ ...prev, flag: 'On hold', show: true, content: "" }));

        } else {
            window.alert(`job_status is not in review, it is: ${status}`);
        }
    }



    return (
        <Modal
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ ...style, height: '100%', background: 'white' }}>
                <Box sx={{ width: '100%', height: '8%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '16px' }}>
                    <Typography sx={{ fontSize: '32px' }}>{company_name}</Typography>
                    <IconButton
                        onClick={onClose}
                        color="inherit"
                        aria-label="close">
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ width: '100%', height: '92%', display: 'flex', flexDirection: 'column', gap: '16px', overflowY: 'auto' }}>
                    {(status.toLowerCase() === 'in review' && buttons) &&
                        // {status.toLowerCase() === 'on hold' &&
                        <Box sx={{ display: 'flex', width: '100%', gap: '16px', mb: '16px' }}>
                            <Button variant='outlined'
                                onClick={() => { handleAcceptClick() }}
                                sx={{ width: '151px', color: Color.green, background: 'rgba(8, 171, 63, 0.10)', borderColor: Color.green, borderRadius: '10px', textTransform: 'none', fontSize: '16px', fontWeight: 400, '&:hover': { border: Color.green, color: 'white', background: 'green' } }}>Accept</Button>
                            <Button variant='outlined'
                                onClick={() => { handleOnHoldClick() }}
                                sx={{ width: '151px', color: Color.accent, background: 'rgba(245, 165, 54, 0.20)', borderColor: Color.accent, borderRadius: '10px', textTransform: 'none', fontSize: '16px', fontWeight: 400, '&:hover': { border: Color.accent, color: 'white', background: Color.accent } }}>On Hold</Button>
                        </Box>
                    }
                    {(date === 'No date' && status === 'N/A' && !time && !role && !specialization && !closed_timing && !responsibilities) ? <></> :
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}>
                                <Box sx={{ ...DivCenter }}>
                                    <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Date:</Typography>
                                    <Typography sx={{ width: '70%', color: Color.neutralBlack, fontSize: '16px' }}>{date}</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter }}>
                                    <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Time:</Typography>
                                    <Typography sx={{ width: '70%', color: Color.neutralBlack, fontSize: '16px' }}>{time}</Typography>
                                </Box>
                                {/* <Box sx={{ ...DivCenter }}>
                            <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Category:</Typography>
                            <Typography sx={{ width: '70%', color: Color.neutralBlack, fontSize: '16px' }}>{date}</Typography>
                        </Box> */}
                                <Box sx={{ ...DivCenter }}>
                                    <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Role:</Typography>
                                    <Typography sx={{ width: '70%', color: Color.neutralBlack, fontSize: '16px' }}>{role}</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter }}>
                                    <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Specialization:</Typography>
                                    <Typography sx={{ width: '70%', color: Color.neutralBlack, fontSize: '16px' }}>{specialization}</Typography>
                                </Box>
                                <Box sx={{ ...DivCenter }}>
                                    <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Status:</Typography>
                                    <Typography sx={{ width: '70%', fontSize: '16px', color: status === 'In review' ? Color.accent : status === 'Accept' ? '#08AB3F' : status === 'Closed' ? Color.primary1 : 'red' }}>
                                        {status === 'Accept' ? 'Accepted' : status}
                                        {(![null, undefined].includes(closed_timing) && status === 'Closed') && <span>{" on " + timeServices.convertEpochToDate(parseInt(closed_timing))}</span>}
                                    </Typography>
                                </Box>
                                {/* {onHoldRemarks.content !== '' && <Box sx={{ ...DivCenter }}>
                            <Typography sx={{ width: '30%', color: Color.neutralMidGrey, fontSize: '16px' }}>Remarks:</Typography>
                            <Typography sx={{ width: '50%', fontSize: '16px', }}>{onHoldRemarks.content}</Typography>
                            <Button variant='text' onClick={() => setOnHoldRemarks((prev) => ({ ...prev, show: true }))}
                                sx={{ width: '20%', }}>Edit</Button>
                        </Box>} */}
                            </Box>
                            <hr />
                        </>}
                    {applications ?
                        <Box sx={{ width: '100%' }}>
                            <Typography mb='24px' fontSize='20px'>Applications</Typography>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', gap: '16px', mb: '24px' }}>
                                {<Typography style={{ display: 'flex', width: '100%', color: Color.neutralMidGrey }}><span style={{ width: '20%' }}>Applied:</span> <span style={{ width: '80%' }}>{![null, undefined].includes(applications.applied) ? applications.applied : 'N/A'}</span></Typography>}
                                {<Typography style={{ display: 'flex', width: '100%', color: Color.neutralMidGrey }}><span style={{ width: '20%' }}>Process:</span> <span style={{ width: '80%' }}>{![null, undefined].includes(applications.process) ? applications.process : 'N/A'}</span></Typography>}
                                {<Typography style={{ display: 'flex', width: '100%', color: Color.neutralMidGrey }}><span style={{ width: '20%' }}>Shortlists:</span> <span style={{ width: '80%' }}>{![null, undefined].includes(applications.shortlist) ? applications.shortlist : 'N/A'}</span></Typography>}
                                {<Typography style={{ display: 'flex', width: '100%', color: Color.neutralMidGrey }}><span style={{ width: '20%' }}>Hired:</span> <span style={{ width: '80%' }}>{![null, undefined].includes(applications.hired) ? applications.hired : 'N/A'}</span></Typography>}
                            </Box>
                        </Box> : <Box></Box>
                    }
                    {loading ?
                        <CircularProgress size={42} sx={{ color: Color.primary1 }} />
                        :
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography mb='24px' fontSize='20px'>JD</Typography>
                            <Typography mb='24px' fontSize='16px' color={Color.neutralMidGrey}>
                                {(jdData.job_type_name ? jdData.job_type_name : 'Job Type N/A') + " | "}
                                {(jdData.designation ? jdData.designation : 'Designation N/A') + " | "}
                                {(jdData.roles ? jdData.roles : 'Role N/A') + " | "}
                                {(jdData.spe ? jdData.spe : 'Specialization N/A')}
                            </Typography>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'flex-start', width: '100%', mb: '24px', gap: '19px' }}>
                                {jdData.location && <Typography className='typography__location_stipend_openings'><LocationOn />{jdData.location}</Typography>}
                                {(jdData.fixed_component || jdData.variable_component) && (
                                    <Typography className='typography__location_stipend_openings'>
                                        <PaymentsOutlined />
                                        ₹{jdData.fixed_component}/
                                        {parseInt(jdData.job_type_id) === 3 ? 'annum' : 'month'}{' '}
                                        {jdData.variable_component && <>| Variable : ₹{jdData.variable_component}/
                                            {parseInt(jdData.job_type_id) === 3 ? 'annum' : 'month'}</>}
                                    </Typography>
                                )}

                                {jdData.openings && <Typography className='typography__location_stipend_openings'><PeopleAltOutlined />Hiring {jdData.openings} Candidate(s)</Typography>}
                            </Box>
                            {jdData.jd ? <Box sx={{ mb: '24px' }}>
                                <Typography fontWeight={500} fontSize={24} mb={'16px'}>Key Responsibilities</Typography>
                                <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {/* <Typography textAlign={'match-parent'}>
                                        <RadioButtonChecked fontSize='14px' sx={{ color: '#D0DEE6' }} /> {key_resp}
                                    </Typography> */}
                                    {typeof jdData.jd.key_responsibilities === 'string' ?
                                        <Box className='box__jdDescription' sx={{ mb: '24px', overflowWrap: 'break-word', hyphens: "auto" }}>
                                            {makeTextBold(jdData.jd.key_responsibilities)}
                                        </Box>
                                        :
                                        <Box className='box__jdDescription'>
                                            <ul>
                                                {jdData.jd.key_responsibilities.map((key_resp, key_resp_index) =>
                                                    <li key={key_resp_index} style={{ overflowWrap: 'break-word', hyphens: "auto" }}>{key_resp}</li>
                                                )}
                                            </ul>
                                        </Box>
                                    }
                                </Box>
                            </Box> : <></>}
                            {jdData.jd ? <Box sx={{ mb: '24px' }}>
                                <Typography fontWeight={500} fontSize={24} mb={'16px'}>Role Proficiencies</Typography>
                                <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {/* <Typography textAlign={'match-parent'}>
                                        <RadioButtonChecked fontSize='14px' sx={{ color: '#D0DEE6' }} /> {key_resp}
                                    </Typography> */}
                                    {typeof jdData.jd.role_proficiencies === 'string' ?
                                        <Box className='box__jdDescription' sx={{ mb: '24px', overflowWrap: 'break-word', hyphens: "auto" }}>
                                            {makeTextBold(jdData.jd.role_proficiencies)}
                                        </Box>
                                        :
                                        <Box className='box__jdDescription'>
                                            <ul>
                                                {jdData.jd.role_proficiencies.map((role_profs, role_profs_index) =>
                                                    <li key={role_profs_index} style={{ overflowWrap: 'break-word', hyphens: "auto" }}>{role_profs}</li>
                                                )}
                                            </ul>
                                        </Box>
                                    }
                                </Box>
                            </Box> : <></>}
                            {jdData.assessment_name.length ?
                                <Box sx={{ mb: '24px' }}>
                                    <Typography sx={{ fontSize: "24px" }}>Selection Process</Typography>
                                    <Typography sx={{ fontSize: "16px", marginBottom: "10px", color: "#8B8B8B" }}>Round - 1: Assessments managed by CareerCarve</Typography>
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
                                        {jdData.assessment_name
                                            .map((option, option_index) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            width: jdData.assessment_name.length % 2 === 0 ? "45%" : jdData.assessments.length - 1 === option_index ? "90%" : "45%",
                                                            border: "1px solid #E6E6E6",
                                                            marginBottom: "1%",
                                                            padding: "15px",
                                                            borderRadius: "15px",
                                                        }}>
                                                        <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{option}</Typography>
                                                        <Typography sx={{ textAlign: "center", color: "#797979", textDecoration: "underline", fontSize: "16px" }}>View Sample Questions</Typography>
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                </Box> : <></>}
                            {jdData.perks ? <Box sx={{ mb: '24px' }}>
                                <Typography mb='24px' fontSize='20px'>Perks and Benefits</Typography>
                                <Box className='box__jdDescription'>
                                    <Typography className='typography__jdDescription' style={{ overflowWrap: 'break-word', hyphens: "auto" }}>
                                        {typeof jdData.perks === 'string' ?
                                            makeTextBold(jdData.perks)
                                            : <ul>
                                                {jdData.perks.map((role_profs, role_profs_index) =>
                                                    <li key={role_profs_index} style={{ overflowWrap: 'break-word', hyphens: "auto" }}>{role_profs}</li>
                                                )}
                                            </ul>
                                            // ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus."
                                        }
                                    </Typography>
                                </Box>
                            </Box> : <></>
                            }
                            {/* <Box sx={{ mb: '24px' }}>
                            <Typography fontWeight={500} fontSize={24} mb={'16px'}>Skills</Typography>
                            <Box sx={{ ...DivCenter, flexWrap: 'wrap', width: '100%', gap: '12px', justifyContent: 'stretch' }}>
                                {jdData.skills.map((sk) =>
                                    <Box className='box__jd_skills'>{sk}</Box>
                                )}
                            </Box>
                        </Box> */}
                            {jdData.responsibilities ?
                                <Box sx={{ mb: '24px' }}>
                                    <Typography mb='24px' fontSize='20px'>Description</Typography>
                                    <Box className='box__jdDescription'>
                                        <Typography className='typography__jdDescription'>
                                            {typeof jdData.responsibilities === 'string' ?
                                                makeTextBold(jdData.responsibilities)
                                                : <ul>
                                                    {jdData.responsibilities.map((role_profs, role_profs_index) =>
                                                        <li key={role_profs_index} style={{ wordBreak: "break-all", hyphens: "auto" }}>{role_profs}</li>
                                                    )}
                                                </ul>
                                                // ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus."
                                            }
                                        </Typography>
                                    </Box>
                                </Box> : <></>}
                        </Box>}
                </Box>

            </Box>
        </Modal >
    )
}