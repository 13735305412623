import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, TextField, Modal, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../../../GlobalStyles';
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import Service from '../../../../services/httpService';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import dayjs from 'dayjs'; // Optional: Install using npm install dayjs
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AnomaliesPastBookings = () => {
    const services = new Service();
    const navigate = useNavigate();
    const [anomaliesPastBookingsDetails, setAnomaliesPastBookingsDetails] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [startingDate, setStartingDate] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD')); // Yesterday's date
    const [endingDate, setEndingDate] = useState('');

    // Fetch anomalies data
    const anomaliesPastBookingsDataGetter = async () => {
        try {
            const queryParams = `?starting_date=${startingDate}` + (endingDate ? `&ending_date=${endingDate}` : '');
            const res = await services.get(`/anomalies/pastActivities${queryParams}`);
            setAnomaliesPastBookingsDetails(res.data);
        } catch (err) {
            window.alert('Some error occurred while fetching data');
        }
    };

    // Call API initially with yesterday's date
    useEffect(() => {
        anomaliesPastBookingsDataGetter();
    }, []); // Empty array means it runs only once on component mount

    const handleAnomaliesItemClick = async (orderIds) => {
        try {
            if (orderIds !== null && orderIds.length > 0) {
                const res = await services.get(`/getOrderDetails?order_ids=[${orderIds}]`);
                if (res.data.length) {
                    navigate('/College1x1StudentDetails', {
                        state: { searched: 'All', isAnomalies: true, studentAnomaliesDetails: res.data },
                    });
                }
            } else {
                window.alert('No data to show');
            }
        } catch (err) {
            window.alert(err.response?.data?.message || 'Error fetching order details');
        }
    };

    // Group anomalies by `anomalies_id`
    const groupedAnomalies = anomaliesPastBookingsDetails.reduce((acc, anomaly) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(anomaly.order_ids);
        } catch (error) {
            parsedValue = anomaly.order_ids;
        }

        const isArray = Array.isArray(parsedValue);
        const count = parsedValue === null ? 0 : isArray ? parsedValue.length : 0;

        if (acc[anomaly.anomalies_id]) {
            acc[anomaly.anomalies_id].order_ids.push(...(isArray ? parsedValue : []));
            acc[anomaly.anomalies_id].totalCount += count;
        } else {
            acc[anomaly.anomalies_id] = {
                ...anomaly,
                order_ids: isArray ? parsedValue : [],
                totalCount: count,
            };
        }

        return acc;
    }, {});

    const combinedAnomalies = Object.values(groupedAnomalies);

    const handleFilterClick = () => setIsFilterOpen(true);
    const handleFilterClose = () => setIsFilterOpen(false);

    // Apply filter and fetch data
    const applyFilter = async () => {
        await anomaliesPastBookingsDataGetter(); // Fetch data when filter is applied
        setIsFilterOpen(false); // Close modal after applying filter
    };

    const goBack = () => {
        console.log("Back Button is Clicked Now")
        navigate(-1);
    }

    return (
        <Box style={{ width: '80%', marginTop: '5%', margin: '0 auto' }}>
            <SideNavigationBar />
            <Box sx={{ marginTop: "5%" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "1rem" }}>
                    <Box sx={{display:"flex", flexDirection:"row", gap:"1rem"}}>
                        <ArrowBackIcon className="Arrow-back-btn" onClick={() => goBack()} />
                        <Typography sx={{ fontSize: 20, alignItems: "center", justifyContent: "center" }}>Past Bookings Anomalies</Typography>
                    </Box>
                    <IconButton onClick={handleFilterClick}>
                        <FilterAltIcon style={{ color: Color.primary1 }} />
                    </IconButton>
                </Box>

                {/* Filter Modal */}
                <Modal open={isFilterOpen} onClose={handleFilterClose} aria-labelledby="filter-modal">
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        padding: '1rem',
                        borderRadius: '8px',
                        boxShadow: 24,
                    }}>
                        <Typography id="filter-modal-title" variant="h6">Filter by Date</Typography>
                        <TextField
                            label="Starting Date"
                            type="date"
                            value={startingDate}
                            onChange={(e) => setStartingDate(e.target.value)}
                            fullWidth
                            sx={{ marginTop: 2, marginBottom: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Ending Date"
                            type="date"
                            value={endingDate}
                            onChange={(e) => setEndingDate(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <button style={{ width: "100%" }} className='batch-button' onClick={applyFilter} fullWidth>
                            Apply Filter
                        </button>
                    </Box>
                </Modal>

                {/* Anomalies Display */}
                <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem', marginBottom: '2rem' }}>
                    {combinedAnomalies.map((value, index) => {
                        const displayValue = value.totalCount;
                        return (
                            <Box
                                key={index}
                                sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '.5rem', marginRight: '.5rem' }}
                                onClick={() => handleAnomaliesItemClick(value.order_ids)}
                            >
                                <button
                                    className="submitBtn"
                                    type="button"
                                    style={{
                                        width: 'auto',
                                        paddingLeft: '.5rem',
                                        paddingRight: '.5rem',
                                        background: 'transparent',
                                        border: `1px solid ${Color.primary1}`,
                                        color: Color.blueBlack,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {value.anomalies_name.replaceAll('_', ' ')} : {displayValue}
                                </button>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default AnomaliesPastBookings;
