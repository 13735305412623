export const calculateAge = (birthYear, birthMonth, birthDate) => {
    // Create a date object from the input values
    birthDate = new Date(birthYear, birthMonth - 1, birthDate);
    // Get the current date
    const currentDate = new Date();
    // Calculate the difference in years between the birth date and current date
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    // Adjust the age if the current month and date are before the person's birth month and date
    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}