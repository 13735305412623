import axios from "axios";
import React, { useEffect, useState } from "react";
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
//modal
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Modal from "react-bootstrap/Modal";
import CollegeData from '../../../CollegeDataServices/httpCollege'
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import Service from '../../../services/httpService';
import { useLocation } from 'react-router-dom';
import { saveAs } from "file-saver";
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import Loader from "../../reusables/Loader/Loader";
import { antistringify as JsonParse } from '../../../functions/antistringify.js'
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Cookies from 'js-cookie';




const UserType = () => {

	// const navigate = useNavigate();
	//collecting college id and name from the collage page
	const location = useLocation();
	// const [college_id_Batch, setcollege_id_Batch] = useState();
	// const [college_name, setcollage_name] = useState("");
	// setcollege_id_Batch(location.state?.college_id);
	// setcollage_name(location.state?.college_name);
	// console.log("COllege1 details are:", college_name)
	let college_data_id = location.state?.college_id
	let college_data_name = location.state?.college_name || ""
	const [loading, setLoading] = useState(true);
	const [specializationData, setSpecializationData] = useState([])
	const [specialization_ids, setSpecialization_ids] = useState([])
	const collegeData = new CollegeData()
	const service = new Service()
	const navigate = useNavigate();

	// batch data
	const [BatchData, setBatchData] = useState([])

	const [rowsData, setRowsData] = useState([{ id: null, user_group_name: "", acad_year: "", program_type_id: null, batch_type_id: null, college_id: "", specializations_allowed: [] }])
	const [rowsData_mod_id, setRowsData_mod_id] = useState([])
	const [rowsData_mod_key, setRowsData_mod_key] = useState([])

	//from table index
	const [from_table, setFrom_table] = useState();

	// getter of batch and program
	const [roleData, setRoleData] = useState([])
	const [batchTypeData, setbatchTypeData] = useState([])
	const [programData, setProgramData] = useState([])

	// data manipulator of batch and program
	const [role, setRole] = useState()
	const [batchType, setBatchType] = useState()
	const [program, setProgram] = useState()

	// ids of batch and program
	const [jd_select_id, setJd_select_id] = useState();
	const [jd_batch_id, setJd_batch_id] = useState();
	const [jd_program_id, setJd_program_id] = useState();

	const [jd_input_name, setJd_input_name] = useState(BatchData[from_table?.index]?.user_group_name);


	//get current date and time
	const currentDate = new Date();
	const [specialization_select_data, setSpecialization_select_data] = useState([])


	// academic year

	//modal hooks
	const [batch_select_data, setBatch_select_data] = useState()
	const [program_select_data, setProgram_select_data] = useState()
	// const [count, setCount] = useState()
	let count = 0;

	const currentYear = new Date().getFullYear();
	const isAfterAugust = new Date().getMonth() >= 8;
	const firstAcademicYear = 2021;
	const academicYear =
		currentYear >= firstAcademicYear &&
			(currentYear > firstAcademicYear || isAfterAugust)
			? `${currentYear}-${(currentYear + 1).toString().slice(2)}`
			: `${currentYear - 1}-${currentYear.toString().slice(2)}`;
	const previousYear = `${currentYear - 1}-${currentYear.toString().slice(2)}`;
	const nextYear = `${currentYear + 1}-${(currentYear + 2).toString().slice(2)}`;
	const options =
		currentYear >= firstAcademicYear && isAfterAugust
			? [academicYear, nextYear, `${currentYear + 2}-${(currentYear + 3).toString().slice(2)}`]
			: [
				`${currentYear - 1}-${currentYear.toString().slice(2)}`,
				`${currentYear}-${(currentYear + 1).toString().slice(2)}`,
				`${currentYear + 1}-${(currentYear + 2).toString().slice(2)}`,
			];

	//activity
	const [activity, setActivity] = useState("");

	// const academicYear =
	// 	new Date().getMonth() >= 8
	// 		? `${currentYear}-${(currentYear + 1).toString().slice(2)}`
	// 		: `${currentYear - 1}-${currentYear.toString().slice(2)}`;
	// const previousYear = `${currentYear - 1}-${currentYear.toString().slice(2)}`;
	// const nextYear = `${currentYear + 1}-${(currentYear + 2).toString().slice(2)}`;
	// const options = [previousYear, academicYear, nextYear];
	console.log("specializationData", specializationData,specialization_select_data);
	const [selectedYear, setSelectedYear] = useState(BatchData[from_table?.index]?.acad_year);

	// const handleYearChange = (event) => {
	// 	setSelectedYear(event.target.value);
	// };

	const headCells = [
		{
			id: 'heading1',
			numeric: 'right',
			disablePadding: false,
			label: 'Batch Name',
		},
		{
			id: 'heading2',
			numeric: 'right',
			disablePadding: false,
			label: 'Academic Year',
		},
		{
			id: 'heading2',
			numeric: 'right',
			disablePadding: false,
			label: 'Program Type',
		},
		{
			id: 'heading2',
			numeric: 'right',
			disablePadding: false,
			label: 'Batch Type',
		},


	];

	const handleDeleteMul = (id, index) => {
		console.log("delete", id)
		let list = [...BatchData];

		console.log("listtttt1", list);

		collegeData
			.delete("/userGroup", {
				data: { "ids": id }
			})
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list = list.filter((item) => id.indexOf(item.id) < 0);
				setRowsData(list)
				setBatchData(list)

				console.log("listtttt2", list);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);
			});
	}

	const handleProcessDataPage = (id, name) => {
		console.log("rows in Batch data page is", { id, name, college_data_name });
		navigate(`${"/ProcessData?user_group_id=" + id}`, { state: { batch_id: id, batch_name: name, college_name: college_data_name, } })
	}

	const handleStudentDataPage = (id, name) => {
		console.log("rows in Batch data page is", { id, name, college_data_name });
		navigate(`${"/StudentData?user_group_id=" + id}`, { state: { batch_id: id, batch_name: name, college_name: college_data_name, } })
	}

	const data_getter = (name, id, index, act) => {
		// setRowsData(data)
		console.log("Dude here's your name:", name);
		console.log("Dude here's your id:", id);
		console.log("Dude here's your id:", index);
		setActivity(act);
		if (name === "delete_mul") {
			console.log("inside delete mul if")
			handleDeleteMul(id, index)
		}
		else if (name === "BatchData") {
			console.log("inside delete mul if")
			handleProcessDataPage(id)
		}
		else if (name === "StudentData") {
			handleStudentDataPage(id)
		}
		if (act === "add") {
			setJd_input_name()
			setSelectedYear()
			setJd_batch_id()
			setJd_program_id()
			setSpecialization_select_data()
		}
		else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
			handleDelete(id, index);
		}



		setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
	}

	const batchDataGetter = async () => {
		try {
			const res = await collegeData.get(`/userGroup/byCollege?collegeId=${college_data_id}`)
			console.log("JD RESPONSE:", res.data)
			setBatchData([...res.data])
			setRowsData_mod_id([...res.data])
			setRowsData_mod_key([...res.data])
			setRowsData([...res.data])
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	const batchGetter = async () => {

		try {
			const res = await service.get('/batchType')
			console.log("Response from backend is11:", res.data)
			setbatchTypeData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}
	const programGetter = async () => {
		try {
			const res = await service.get('/programType')
			console.log("Response from backend is:", res.data)
			setProgramData(res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	// specialization
	const specializationGetter = async () => {
		try {
			const res = await service.get('/specialization?is_admin=1')
			console.log("Response from backend for specialization:", res.data)
			let temp = res.data;
			temp = temp.map(bat => {
				bat.key = bat.name;
				bat.id = bat.id;

				delete bat.name;
				return bat;
			});
			setSpecializationData(temp)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	// get request
	useEffect(() => {
		console.log("Brother")
		const handleEffect = async () => {

			if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
				navigate('/');
			}
			//batch data getter
			const batchData = await batchDataGetter();
			//batch getter 
			const batch = await batchGetter();
			//program getter 
			const program = await programGetter();
			// specialization
			const specialization = await specializationGetter();

			// forming the data
			console.log("odonil", { batchData }, { role }, { batch }, { program }, { specialization })
			// data_manupulator_jd(batchData, role, batch, program);
			data_manupulator_batch(batch);
			data_manupulator_program(program);
			setLoading(false)
		}

		handleEffect();
	}, [])

	useEffect(() => {
		const setting_data = () => {

			if (from_table?.index === undefined) {
				console.log("Inside if of useEffect")
				setJd_input_name()
				setSelectedYear()
				setJd_batch_id()
				setJd_program_id()
				setBatch_select_data([])
				setSpecialization_ids([])
				setSpecialization_select_data()
				console.log("specializationData",specializationData);
				setSpecializationData(specializationData)
			}
			else {
				//setting values for Edit Modal

				setJd_input_name(BatchData[from_table?.index]?.user_group_name)
				setSelectedYear(BatchData[from_table?.index]?.acad_year)
				console.log({ BatchData }, { roleData }, { batchTypeData }, { programData })
				setJd_program_id(BatchData[from_table?.index]?.program_type_id)
				setJd_batch_id(BatchData[from_table?.index]?.batch_type_id)
				// setJd_program_id(jdData[from_table?.index]?.program_type_ids)
				const temp_ids = typeof BatchData[from_table?.index]?.specializations_allowed === 'string' ? JSON.parse(BatchData[from_table?.index]?.specializations_allowed) : BatchData[from_table?.index]?.specializations_allowed
				console.log('temp_ids', temp_ids);
				const find_batch = specializationData.filter((ele) => temp_ids?.ids ? temp_ids?.ids?.includes(ele.id) : temp_ids?.includes(ele.id));
				console.log("find batch", find_batch);
				setSpecialization_select_data(find_batch)
				console.log("specializationData",specializationData);
				setSpecializationData(specializationData)
				//setProgram_select_data()
				// setBatch_select_data()
				// clean_rowsData_mod_batch_type_id(rowsData_mod_id[from_table?.index] ? rowsData_mod_id[from_table?.index] : [], rowsData_mod_key[from_table?.index] ? rowsData_mod_key[from_table?.index] : [])
				// clean_rowsData_mod_program_type_id(rowsData_mod_id[from_table?.index] ? rowsData_mod_id[from_table?.index] : [], rowsData_mod_key[from_table?.index] ? rowsData_mod_key[from_table?.index] : [])
			}

		}
		setting_data();
	}, [from_table])






	const data_manupulator_jd = (jd, role, batch, program, from) => {
		console.log("Jd data:", jd)
		console.log("From where", from)
		let temp_rowsData = JSON.parse(JSON.stringify(jd));
		let temp_role = JSON.parse(JSON.stringify(role));
		let temp_batch = JSON.parse(JSON.stringify(batch));
		let temp_program = JSON.parse(JSON.stringify(program));

		//show data - send to Table Component
		temp_rowsData.forEach((row) => {

			row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
			// row.batch_type_id = JSON.parse(row.batch_type_id).ids.map(data => temp_batch.map(bat => data === bat.id ? bat.name : "").filter(value => value !== ""))
			// row.program_type_id = JSON.parse(row.program_type_id).ids.map(data => temp_program.map(pro => data === pro.id ? pro.name : "").filter(value => value !== ""))
			delete row.batch_type_id
			delete row.program_type_id
			delete row.file_version
			delete row.active_flag

		});
		console.log("temp rows:", temp_rowsData)
		setRowsData(temp_rowsData)


		//id - for the batch type
		let temp_rowsData_mod_id = JSON.parse(JSON.stringify(jd));
		temp_rowsData_mod_id.forEach((row) => {

			row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
			row.batch_type_id = JSON.parse(row.batch_type_id).ids?.map(data => temp_batch?.map(bat => data === bat.id ? bat.id : "")?.filter(value => value !== ""))
			row.program_type_id = JSON.parse(row.program_type_id).ids?.map(data => temp_program?.map(pro => data === pro.id ? pro.id : "")?.filter(value => value !== ""))
			delete row.file_url
			delete row.file_version
			delete row.active_flag

		});
		console.log("temp rows mod:", temp_rowsData_mod_id)
		setRowsData_mod_id(temp_rowsData_mod_id)




		//key - for the program type
		let temp_rowsData_mod_key = JSON.parse(JSON.stringify(jd));
		temp_rowsData_mod_key.forEach((row) => {

			row.role_id = temp_role?.map(data => data.id === row.role_id ? data.name : "")?.filter(value => value !== "")[0]
			row.batch_type_id = JSON.parse(row.batch_type_id).ids?.map(data => temp_batch?.map(bat => data === bat.id ? bat.name : "")?.filter(value => value !== ""))
			row.program_type_id = JSON.parse(row.program_type_id).ids?.map(data => temp_program?.map(pro => data === pro.id ? pro.name : "")?.filter(value => value !== ""))
			delete row.file_url
			delete row.file_version
			delete row.active_flag

		});
		console.log("temp rows mod:", temp_rowsData_mod_key)
		setRowsData_mod_key(temp_rowsData_mod_key)

		//JSON parsing jdData
		let temp_jdData = JSON.parse(JSON.stringify(jd));
		temp_jdData.forEach((row) => {

			row.batch_type_id = JSON.parse(row.batch_type_id)?.ids
			row.program_type_id = JSON.parse(row.program_type_id)?.ids

		});
		console.log("temp rows mod:", temp_jdData)
		setBatchData(temp_jdData)




	}

	const data_manupulator_batch = (batch) => {

		let temp = batch;
		temp = temp.map(bat => {
			bat.key = bat.name;
			bat.id = bat.id;
			return bat;
		});
		console.log("temp rows batch:", temp)
		setBatchType(temp)
	}

	console.log("batch key", batchType);
	const data_manupulator_program = (program) => {

		let temp = program;
		temp = temp.map(pro => {
			pro.key = pro.name;
			pro.id = pro.id;
			return pro;
		});
		console.log("temp rows program:", temp)
		setProgram(temp)
	}





	//MODAL
	const Modal_JD = (show, setShow, mode) => {


		const modalSubmit = async () => {
			console.log("Just submitted:", jd_input_name, jd_select_id, jd_batch_id, jd_program_id)
			let temp_jdData
			if (activity === "add")
				temp_jdData = await handleFormSubmission(jd_input_name, selectedYear, jd_program_id, jd_batch_id, college_data_id, specialization_ids);
			else
				temp_jdData = await handlePutRequest(BatchData[from_table?.index].id, jd_input_name, selectedYear, jd_program_id, jd_batch_id, college_data_id, specialization_ids);
			console.log({ temp_jdData })
			setShow(false)
		}

		const onHide = () => {
			setShow(false)
			setJd_input_name()
			setSelectedYear()
			setJd_batch_id()
			setJd_program_id()
		}

		const handleChange = (e, index) => {
			console.log("name from form  is:", e.target.name);
			console.log("value from form  is:", e.target.value);

			if (e.target.name === "jd-input") {
				setJd_input_name(e.target.value)
				console.log("add-specialization-input")
			}

			else if (e.target.name === "batch-select") {
				setJd_batch_id(e.target.value)
				console.log("batch selected id", e.target.value)

			}
			else if (e.target.name === "program-select") {
				setJd_program_id(e.target.value)
				console.log("program selected id", e.target.value)
			}
			else if (e.target.name === "academicYear") {
				setSelectedYear(e.target.value);
			}

		}

		const onSelect_specialization = (e) => {
			console.log("Added an item to multi select - batch -", e)
			setSpecialization_select_data(e);
			let temp = JSON.parse(JSON.stringify(e));
			temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
			setSpecialization_ids(temp)
			console.log("temp inside onSelect:", temp)
		}

		// multiselect on remove method
		const onRemove_specialization = (e) => {
			console.log("Removed an item to multi select")
			setSpecialization_select_data(e);
			let temp = JSON.parse(JSON.stringify(e));
			temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
			//   data.id = data.id;
			//   return data;
			// });
			setSpecialization_ids(temp)
			console.log("temp inside onSelect:", temp)
		}

		//render
		return (
			<Modal
				show={show}
				onHide={() => setShow(false)}
				className="FileUploadModal"
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ backdropFilter: "blur(5px)", minWidth: "80%", maxWidth: "95%", minHeight: "80%", maxHeight: "95%" }}
			>
				<Modal.Body style={{
					position: 'absolute',
					left: '60%',
					height: 'auto',
					transform: 'translate(-50%, -50%)',
					lineHeight: '1.4',
					padding: '14px 28px',
					backgroundColor: '#fff',
					borderRadius: '10px',
					maxWidth: '95%',
					minWidth: '500px',
					minHeight: '400px'
				}}>
					<form className="Entire-form" style={{ justifyContent: 'center' }}>
						{/* right side components */}
						<div className='row full-div' >
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
								<CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
								<p className="compTitle" style={{ fontSize: "32px", textAlign: "center", background: 'transparent' }}>Batch/User Group</p>
							</div>

							<div className="form-group">
								<label className="inputLabel">Batch Name<span>*</span></label>
								<input name="jd-input" disabled={mode === "View" ? true : false} type="text" className="form-control" placeholder="Example input" value={jd_input_name} onChange={(e) => handleChange(e)} />
							</div>
							<div className="form-group">
								<label className="inputLabel" htmlFor="academicYear">Select academic year:</label>
								<select id="academicYear" disabled={mode === "View" ? true : false} name="academicYear" className="form-control inputFields" value={selectedYear} onChange={(e) => handleChange(e)}>
									<option className="options-value" value={""} disabled selected>Select academic year</option>
									{options.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>
							</div>
							<div className="form-group ">
								<label className="inputLabel">Program Type<span>*</span></label>
								{/* <input type="text" className="form-control inputFields" placeholder="Example input" /> */}
								<select name="program-select" disabled={mode === "View" ? true : false} id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={jd_program_id}>
									<option className="options-value" value={""} disabled selected>Select Program Type</option>
									{program?.map((pro, i) => (
										<option key={i} value={pro.id}>{pro.key}</option>
									))}
								</select>
							</div>
							<div className="form-group ">
								<label className="inputLabel">Batch Type<span>*</span></label>
								<select name="batch-select" disabled={mode === "View" ? true : false} id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={jd_batch_id}>
									<option className="options-value" value={""} disabled selected>Select Batch Type</option>
									{batchType?.map((bat, i) => (
										<option key={i} value={bat.id}>{bat.key}</option>
									))}
								</select>
							</div>
							<div className="form-group">
								<label className="inputLabel">Specialization Type<span>*</span></label>
								<Multiselect
									displayValue="key"
									selectedValues={specialization_select_data}
									onRemove={(e) => onRemove_specialization(e)}
									onSelect={(e) => {onSelect_specialization(e)}}
									options={specializationData}
								/>
							</div>
							<div className="form-group ">

								{mode === "View" ? <></> : <div className=" btn-comp " style={{ marginTop: "4%" }}>
									<button type="button" onClick={modalSubmit} className="btn submit-btn">Submit</button>
								</div>}
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		)
	}

	const headers = {
		Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
	};

	// post request
	const handleFormSubmission = async (user_group_name, acad_year, program_type_id, batch_type_id, college_data_id) => {
		let temp_rows = [...rowsData];
		let temp_batchData = JSON.parse(JSON.stringify(BatchData));
		let data = {
			user_group_name: user_group_name,
			acad_year: acad_year,
			program_type_id: parseInt(program_type_id),
			batch_type_id: parseInt(batch_type_id),
			college_id: college_data_id,
			specializations_allowed: specialization_ids
		}

		console.log("User TYpe id not found, sending teh post request", temp_rows);
		await collegeData
			.post("/userGroup", data)
			.then((res) => {
				// const res = collegeData.post("/userGroup", data)
				console.log("Response from backend is", JSON.stringify(res, null, 2));
				console.log(res)
				console.log(res?.data)
				let temp = JSON.parse(JSON.stringify(rowsData));
				let temp_program = programData;
				let temp_batch = batchType

				temp = ([{
					id: res?.data?.id,
					user_group_name: user_group_name,
					acad_year: acad_year,
					program_type_id: parseInt(jd_program_id),
					batch_type_id: parseInt(jd_batch_id),
					college_id: college_data_id,
					specializations_allowed: specialization_ids
				}, ...temp])
				console.log(".then ~ temp", temp)
				setRowsData(temp)


				temp_batchData = ([{
					id: res?.data?.id,
					user_group_name: user_group_name,
					acad_year: acad_year,
					program_type_id: parseInt(jd_program_id),
					batch_type_id: parseInt(jd_batch_id),
					college_id: college_data_id,
					specializations_allowed: specialization_ids
				}, ...temp_batchData])

				setBatchData(temp_batchData)
				console.log("batch data right now", temp_batchData);
			})
			.catch((err) => {
				console.log("Errog Msg: ", err);
				window.alert(err.response.data.message)
			});
		return temp_batchData
	}

	const handlePutRequest = async (id, jd_input_name, selectedYear, jd_program_id, jd_batch_id, college_data_id) => {
		//get request here
		let temp_batchData = JSON.parse(JSON.stringify(BatchData));

		try {
			const res = collegeData.put("/userGroup", {
				id: id,
				user_group_name: jd_input_name,
				acad_year: selectedYear,
				program_type_id: parseInt(jd_program_id),
				batch_type_id: parseInt(jd_batch_id),
				college_id: college_data_id,
				specializations_allowed: specialization_ids
			})
			console.log("Response from backend is", JSON.stringify(res, null, 2));

			let temp = JSON.parse(JSON.stringify(rowsData));
			let temp_program = JSON.parse(JSON.stringify(programData));
			let temp_batch = JSON.parse(JSON.stringify(batchType));
			console.log({ temp_program })
			console.log(jd_select_id)

			temp[from_table?.index].user_group_name = jd_input_name
			temp[from_table?.index].acad_year = selectedYear
			temp[from_table?.index].program_type_id = parseInt(jd_program_id)
			temp[from_table?.index].batch_type_id = parseInt(jd_batch_id)
			temp[from_table?.index].college_id = college_data_id
			temp[from_table?.index].specializations_allowed = specialization_ids

			// temp[from_table?.index].college_id = college_data_id
			console.log("Matey", temp)
			setRowsData(temp)


			temp_batchData[from_table?.index].user_group_name = jd_input_name
			temp_batchData[from_table?.index].acad_year = selectedYear
			temp_batchData[from_table?.index].program_type_id = parseInt(jd_program_id)
			temp_batchData[from_table?.index].batch_type_id = parseInt(jd_batch_id)
			temp_batchData[from_table?.index].college_id = college_data_id
			temp_batchData[from_table?.index].specializations_allowed = specialization_ids
			console.log(temp_batchData)
			setBatchData(temp_batchData)


		} catch (err) {
			console.log("Errog Msg: ", err);
			window.alert(err.response.data.message)
		};
		return temp_batchData
	}

	// delete request
	const handleDelete = async (jd_id, index) => {
		console.log("delete", jd_id)
		let list = [...rowsData];

		console.log("listtttt1", list);
		collegeData
			.delete("/userGroup", {
				data: { "ids": [jd_id] }
			})
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list.splice(index, 1);
				setRowsData(list)
				setBatchData(list)
				console.log("listtttt2", list);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);

				console.log(err.response.data.message)
				window.alert(err.response.data.message)

			});
	}


	const fileDownload = () => {
		console.log("Downloading the file");
		console.log("Rows data now is:  ", rowsData);
		const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
		saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


	}

	const handleStudentNav = (batch_id, batch_name) => {
		//for the url go to the collage data
		navigate(``, { state: { batch_id: batch_id, batch_name: batch_name } })


	}




	// console.log("COllege details are:", location.college_name)
	return (<div >
		<RowsContext.Provider value={rowsData}>
			<TopBar />
			<SideNavigationBar />
			{loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div> : <>
				{console.log("from_table.index", from_table?.index)}
				{console.log({ program_select_data })}
				{console.log({ batch_select_data })}
				{console.log({ jd_batch_id })}
				{console.log({ jd_select_id })}
				{console.log({ rowsData_mod_id })}
				{console.log({ rowsData_mod_key })}
				{console.log({ roleData })}
				{console.log({ from_table })}
				{console.log("Collage Name in Batchdata is :", college_data_name)}
				{
					college_data_name !== "" ?
						(<EnhancedTable batchTypeData={batchTypeData} programData={programData} handleStudentNav={handleStudentNav} college_name={college_data_name} mainFile_modal={Modal_JD} handleProcessDataPage={handleProcessDataPage} handleStudentDataPage={handleStudentDataPage} data_getter={data_getter} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} />) :
						(<EnhancedTable mainFile_modal={Modal_JD} data_getter={data_getter} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} />)

				}
			</>}

		</RowsContext.Provider>

	</div>
	)
}

export default UserType