import React, { useState, useEffect, useRef } from 'react';
import './TableStyling.css';
import ModalSupportTicket from './ModalSupportTicket';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CCService from '../../../services/httpServiceWithoutSuperAdmin';
import { Color } from '../../../GlobalStyles';
import Loader from '../../reusables/Loader/Loader';
import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import { calculateEpochTimeInLocalTime, extractDateFromDateString } from '../../../services/specialCharReplace';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Alert from '@mui/material/Alert';
const CreateTable = () => {
  const service = new CCService();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const tableTitles = ["Category", "Raised by/Flagged by", "Role", "Raised at", "Origin", "Status", "Assignee"];
  const [supportTicketDetails, setSupportTickerDetails] = useState([]);
  const [supportTicketModalDetails, setSupportTickerModalDetails] = useState([]);
  const [supportTicketCategoryNameDetails, setSupportTickerCategoryNameDetails] = useState([]);
  const [supportTicketResolveFeedbackDetails, setSupportTickerResolveFeedbackDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ticket__id, setTicket__id] = useState(null);
  const [category__name, setCategory__name] = useState('');
  const [copyList, setCopyList] = useState(supportTicketDetails);
  const [chatDetails, setChatDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [isUpcomingFetching, setIsUpcomingFetching] = useState(false);
  const [responseDataEmpty, setResponseDataEmpty] = useState(false);
  const [pageSupportTicket, setPageSupportTicket] = useState(0);
  const [searchSupportTicket, setSearchSupportTicket] = useState(0);
  const [filteredPageSupportTicket, setFilteredPageSupportTicket] = useState(0);
  const [searched, setSearched] = useState("")
  const isUpcomingFetchingRef = useRef(isUpcomingFetching);
  console.log("copy lisyt", copyList);
  const [category_id, setCategory_id] = useState([]);
  // let searched = 'All';
  const [filters, setFilters] = useState({
    statusFilters: [
      { label: 'All', value: 'All', isSelected: true },
      { label: 'Open', value: 'Open', isSelected: false },
      { label: 'Closed', value: 'Closed', isSelected: false },
    ],
    issueFilters: [
      { label: 'Signup/Login Related', value: 'Signup/Login Related', isSelected: false },
      { label: 'Scheduling', value: 'Scheduling Issues', isSelected: false },
      { label: 'Resolve feedback', value: 'Resolve feedback by admin', isSelected: false },
      { label: 'Activity', value: 'Activity Issues', isSelected: false },
      { label: 'Payment', value: 'Payment Issues', isSelected: false },
      { label: 'Resumes.Digital', value: 'Resumes.Digital', isSelected: false },
    ],
  });

  const handleStudentsDetailsClick = async (ticket_id, category_name) => {
    console.log("Modal open fucntion called");
    let supportTicketDetails = await supportTicketModalDataGetter(ticket_id);
    let supportTicketChatDetails = await supportTicketChatModalDataGetter(ticket_id);
    setSupportTickerModalDetails(supportTicketDetails);
    setChatDetails(supportTicketChatDetails);
    setTicket__id(ticket_id);
    setCategory__name(category_name);
    console.log("category_name", category_name, supportTicketChatDetails);
    let supportTicketcategory = await supportTicketCategoryDataGetter(category_name);
    console.log("according to category", supportTicketcategory);
    setSupportTickerCategoryNameDetails(supportTicketcategory);
    setOpenModal(true);
  };

  console.log("chat details here", chatDetails, supportTicketModalDetails);
  console.log('searched...', searched, searched.length);
  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
      let supportTicketDetails = await supportTicketDataGetter(1);
      let userCompleteDetails = await userIDDetails();
      let categoryDataGetter = await supportTicketCategoryGetter();
      setCategory_id(categoryDataGetter);
      console.log("datas", { supportTicketDetails });
      setCopyList(supportTicketDetails);
      setUserDetails(userCompleteDetails);
      setLoading(false);
    };
    handleEffect();
  }, []);
  console.log("searchedsearched", searched);
  useEffect(() => {
    const fetchData = async () => {
      if (isUpcomingFetching) {
        if ((getSelectedStatusFilter()?.value !== 'All' || getSelectedIssueFilter()) && searched.length === 0) {
          console.log("where am i");
          await supportTicketDataGetter(filteredPageSupportTicket + 1, true, false);
        } else if (searched.length > 0) {
          console.log("where am i now");
          await supportTicketDataGetter(searchSupportTicket + 1, false, true)
        } else {
          console.log("where am i again");
          await supportTicketDataGetter(pageSupportTicket + 1, false, false);
        }
        setIsUpcomingFetching(false);
      }
    };

    fetchData();
  }, [isUpcomingFetching, filteredPageSupportTicket, pageSupportTicket, searchSupportTicket, searched]);

  const handleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (!responseDataEmpty && windowHeight + scrollTop + 200 >= documentHeight && !isUpcomingFetchingRef.current) {
      setIsUpcomingFetching(true);
      isUpcomingFetchingRef.current = true;
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const supportTicketDataGetter = async (page, isFiltered = false, isSearch = false) => {
    try {
      const selectedStatus = getSelectedStatusFilter();
      const selectedIssue = getSelectedIssueFilter();
      console.log("isSearch", isSearch, isFiltered);
      const params = {
        status: selectedStatus ? selectedStatus.value : 'All',
        category: selectedIssue ? selectedIssue.value : null,
      };
      let temp_category_get = category_id.find(item => item.name === params.category);
      let res;
      if (isSearch) {
        res = await service.get(`/supportTicket/Admin?page=${page}&size=10${isSearch ? `&searchQuery=${searched}` : ''}`);

      } else {

        res = await service.get(`/supportTicket/Admin?page=${page}&size=10${selectedStatus && selectedStatus.value !== 'All' ? `&status=${selectedStatus.value}&orderby=DESC` : ''}${params.category !== null ? `&category_id=${temp_category_get?.id}${selectedStatus && selectedStatus.value === 'All' ? `&orderby=DESC` : ''}` : ''}`);
      }

      let temp_change_short_list = res.data;
      console.log("temp_change_short_list", temp_change_short_list);
      if (temp_change_short_list.length) {
        if (isFiltered) {
          setCopyList(prev => [...prev, ...temp_change_short_list]);
          setFilteredPageSupportTicket(page);
        } else if (isSearch) {
          setCopyList(prev => [...prev, ...temp_change_short_list]);
          setSearchSupportTicket(page)
        } else {
          console.log("iam here", page);
          setCopyList(prev => [...prev, ...temp_change_short_list]);
          setPageSupportTicket(page);
        }
        isUpcomingFetchingRef.current = false;
      } else {
        setResponseDataEmpty(true);
      }
      return temp_change_short_list;
    } catch (err) {
      console.log(err);
    }
  };

  const supportTicketModalDataGetter = async ticket_id => {
    try {
      const res = await service.get(`/supportTicket/Admin/ticketById?ticket_id=${ticket_id}`);
      console.log("details response for ticket id:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const supportTicketChatModalDataGetter = async ticket_id => {
    try {
      const res = await service.get(`/supportTicket/ticket/chat?ticket_id=${ticket_id}`);
      console.log("chat response for ticket id:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const supportTicketCategoryDataGetter = async category_name => {
    try {
      const res = await service.get(`/supportTicket/getExecutivesDetailsForCategoryId?category_name=${category_name}`);
      console.log("category name details for ticket id:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const supportTicketCategoryGetter = async () => {
    try {
      const res = await service.get(`/supportTicket/category`);
      console.log("category name details for ticket id:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };


  const userIDDetails = async () => {
    try {
      const res = await service.get(`/user/userDetail`);
      console.log("category name details for ticket id:", res);
      return res;
    } catch (err) {
      console.log(err);
    }
  };
  console.log("supportTicketDetails", supportTicketDetails);


  const handleFilterClick = (filterType, index) => {
    setSearched("")
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };

      updatedFilters[filterType] = updatedFilters[filterType].map((filter, idx) => ({
        ...filter,
        isSelected: idx === index ? !filter.isSelected : false,
      }));

      if (filterType === 'statusFilters' && !updatedFilters.statusFilters.some(filter => filter.isSelected && filter.value !== 'All')) {
        updatedFilters.statusFilters = updatedFilters.statusFilters.map((filter) => ({
          ...filter,
          isSelected: filter.value === 'All',
        }));
      }

      return updatedFilters;
    });

    setResponseDataEmpty(false); // Reset the empty response flag
    setPageSupportTicket(0);
    setFilteredPageSupportTicket(0);
    setCopyList([]);
    setIsUpcomingFetching(true);
  };

  const getSelectedStatusFilter = () => {
    console.log("what is return", filters.statusFilters.find(filter => filter.isSelected));
    return filters.statusFilters.find(filter => filter.isSelected);
  };

  const getSelectedIssueFilter = () => {
    return filters.issueFilters.find(filter => filter.isSelected);
  };

  console.log("filters", filters);
  const handleSearch = async (searched, event) => {
    setSearched(searched.trim()); // Trim any extra spaces

    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };

      Object.keys(updatedFilters).forEach(key => {
        updatedFilters[key] = updatedFilters[key].map(filter => ({
          ...filter,
          isSelected: false,
        }));
      });

      return updatedFilters;
    });

    if (event.key === 'Enter') {
      await performSearch(searched.trim());
    }
  };

  useEffect(() => {
    if (searched.length === 0) {
      performSearch("");
    }
  }, [searched]);

  const performSearch = async (searchQuery) => {
    setCopyList([]); // Clear previous results
    console.log("searchQuery.length", searchQuery.length > 1);
    if (searchQuery.length > 1) {
      await supportTicketDataGetter(1, false, true);
    } else {
      setSearched("")
      setFilters(prevFilters => {
        const updatedFilters = { ...prevFilters };

        updatedFilters.statusFilters = updatedFilters.statusFilters.map(filter =>
          filter.label === 'All' ? { ...filter, isSelected: true } : filter
        );

        return updatedFilters;
      });
      setResponseDataEmpty(false); // Reset the empty response flag
      setPageSupportTicket(0);
      setFilteredPageSupportTicket(0);
      setCopyList([]);
      setIsUpcomingFetching(true);
    }
    console.log("searched...", searchQuery);
  };


  console.log("searched...", searched);
  console.log("page support ticket", pageSupportTicket);

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <div style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
          <Loader show={loading} />
        </div>
      ) : (
        <div className='student-details-parent' style={{ marginTop: window.location.pathname === '/Dashboard' ? '5rem' : '0px', width: '85%' }}>
          <ToastContainer position='top-center' limit={1} />

          {window.location.pathname === '/Dashboard' ? (
            <>
              <Typography sx={{ fontSize: 20 }}>Support</Typography>
            </>
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: "row", gap: "1rem", alignItems: "center" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
                <div className='support-tickets' style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>Support Tickets</div>
              </div>
            </>
          )}
          <div style={{ display: 'flex', marginTop: '2rem' }}>
            <div className='searchTab' style={{ alignItems: 'center', width: '100%', height: '30px', marginBottom: '.5rem' }}>
              <input
                style={{ border: 'none', height: '30px' }}
                className='searchingText searchTab'
                type='text'
                onChange={(e) => setSearched(e.target.value)} // Update search state on change
                onKeyDown={(e) => handleSearch(e.target.value, e)}
                placeholder='Search for name, roll number, etc'
              />
            </div>

          </div>
          <Box style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ color: Color.neutralMidGrey }}>Status Filters:</Typography>
              <Box>
                {filters.statusFilters?.map((option, index) => (
                  <button
                    key={index}
                    className={option.isSelected ? 'optionsFocusButton' : 'Optionsbutton'}
                    onClick={() => handleFilterClick('statusFilters', index)}
                  >
                    {option.label}
                  </button>
                ))}
              </Box>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ color: Color.neutralMidGrey }}>Issue Filters:</Typography>
              <Box>
                {filters.issueFilters.map((option, index) => (
                  <button
                    key={index}
                    className={option.isSelected ? 'optionsFocusButton' : 'Optionsbutton'}
                    onClick={() => handleFilterClick('issueFilters', index)}
                  >
                    {option.label}
                  </button>
                ))}
              </Box>
            </div>
          </Box>
          {copyList.length > 0 ?
            <TableContainer>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {tableTitles.map((title, index) => (
                      <TableCell
                        align='center'
                        key={index}
                        sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                      >
                        {title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {copyList.map((details, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleStudentsDetailsClick(details.ticket_id, details.category_name)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                        {details.category_name ? details.category_name : '-'}
                      </TableCell>
                      <TableCell align='center' sx={{ fontSize: 16 }} style={{ color: 'black' }}>
                        <p style={{ color: 'black' }}>
                          {details.user_name ? details.user_name : details.user_name}
                        </p>
                        {details.user_email ? details.user_email : details.user_email}
                        <br />
                        {details.user_ph_no ? details.user_ph_no : details.user_ph_no}
                      </TableCell>
                      <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                        <p>
                          {details.user_type === 'mentor' ? (
                            details.user_type ? details.user_type : '-'
                          ) : (
                            <>
                              {details.college_name ? details.college_name : '-'} ({details.user_type ? details.user_type : '-'})
                              <br />
                              {details.user_group_name ? details.user_group_name : '-'}
                              <br />
                              {details.act_type_name ? details.act_type_name : '-'}
                            </>
                          )}
                        </p>
                      </TableCell>
                      <TableCell align='center' sx={{ fontSize: 16 }} style={{ color: 'black' }}>
                        {extractDateFromDateString(details?.time_of_creation)}
                        <br />
                        {calculateEpochTimeInLocalTime(details?.time_of_creation)}
                      </TableCell>
                      <TableCell align='center' sx={{ fontSize: 16 }} style={{ color: 'black' }}>
                        {details.ticket_source ? details.ticket_source : '-'}
                      </TableCell>
                      <TableCell align='center' sx={{ fontSize: 16 }} style={{ color: 'black' }}>
                        <Box
                          className='btn-style-support-ticket'
                          style={{ background: details.ticket_status === 'Open' ? 'rgba(8, 171, 63, 0.5)' : '#eee' }}
                        >
                          {details.ticket_status ? details.ticket_status : '-'}
                        </Box>
                      </TableCell>
                      <TableCell align='center' sx={{ fontSize: 16 }} style={{ color: 'black' }}>
                        {details.executive_name ? details.executive_name : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> : <Box sx={{ width: "80%", margin: "5rem auto auto auto" }}><Alert sx={{ justifyContent: "center" }} severity="info">No Results Found</Alert></Box>}

        </div>
      )}

      <ModalSupportTicket
        show={openModal}
        setShow={setOpenModal}
        supportTicketDetails={supportTicketDetails}
        setSupportTickerDetails={setSupportTickerDetails}
        setSupportTickerModalDetails={setSupportTickerModalDetails}
        supportTicketModalDetails={supportTicketModalDetails}
        supportTicketCategoryNameDetails={supportTicketCategoryNameDetails}
        ticket_id={ticket__id}
        category_name={category__name}
        setSupportTickerCategoryNameDetails={setSupportTickerCategoryNameDetails}
        chatDetails={chatDetails}
        setChatDetails={setChatDetails}
        userDetails={userDetails}
        supportTicketResolveFeedbackDetails={supportTicketResolveFeedbackDetails}
      />
    </>
  );
};

export default CreateTable;
