import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CollegeData from '../../../CollegeDataServices/httpCollege';
import Service from '../../../services/httpService';
import '../../Dashboard/OngoingEvents/OngoingEvents.css';
import Loader from '../../reusables/Loader/Loader';
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import TopBar from '../../reusables/TopBar/Topbar';
import B2CProcessComponent from './B2CProcessComponent';
function B2CProcess() {
    const [loading, setLoading] = useState(true);

    const service = new Service()
    const collegeService = new CollegeData()

    const navigate = useNavigate();

    const [entireGroupSessionData, setEntireGroupSessionData] = useState([])
    const [activityTypeData, setActivityTypeData] = useState([])
    const [roleTypeData, setRoleTypeData] = useState([])
    const [durationData, setDurationData] = useState([])
    const [mentorData, setMentorData] = useState([])
    const [collegeData, setCollegeData] = useState([])
    const [batchData, setBatchData] = useState([])
    const [activity_name, setActivity_name] = useState()
    const [college_name1, setCollege_name1] = useState()
    const [duration_name, setDuration_name] = useState()
    const [mentor_name, setMentor_name] = useState()
    const [batch_name, setBatch_name] = useState()
    const [role_name, setRole_name] = useState()

    const activityTypeGetter = async () => {
        try {
            const res = await service.get('/activityType')
            console.log("Response from backend for activity name:", res.data)
            setActivityTypeData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log("Response activity type data", activityTypeData);
    }

    const DurationGetter = async () => {
        try {
            const res = await service.get('/duration')
            console.log("Response from backend for duration:", res.durations)

            // setDurationData(getTimeFormated(res.durations))
            return res.durations
        } catch (err) {
            console.log(err)
        }
        console.log("Response duration type data", durationData);
    }

    const CollegeGetter = async () => {
        try {
            const res = await collegeService.get('/college')
            console.log("Response from backend for college:", res.data)
            setCollegeData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log(" Response college type data", collegeData);
    }

    const mentorGetter = async () => {
        try {
            const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
            console.log("Response from backend for mentor:", res.data)
            setMentorData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log("Response mentor type data", mentorData);
    }

    const batchGetter = async () => {
        try {
            const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/collegeModule/userGroup`)
            console.log("Response from backend for batch:", res.data)
            setBatchData(res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
        console.log("Response bacth type data", batchData);
    }

    const roleTypeGetter = async () => {
        try {
            const res = await service.get('/role?is_admin=1')
            console.log("roleTypeGetter", res.data)
            setRoleTypeData([...res.data])
            return res.data
        }
        catch (err) {
            console.log(err)
        }
    }

    const data_manupulator_activity = (activityType) => {
        console.log("activity type", activityType);
        // let temp = activityType;
        let temp = activityType.filter(act => act.type === "Group");
        temp = temp.map(bat => {
            bat.key = bat.act_type_name;
            bat.id = bat.id;
            bat.type = bat.type
            return bat;
        });
        console.log("temp rows activity:", temp)
        setActivity_name(temp)
        console.log("activity group names", activity_name);
    }
    const data_manupulator_duration = (duration) => {
        console.log("duration name", duration);
        let temp = duration;
        temp = temp?.map(bat => {
            bat.key = bat.duration_name;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setDuration_name(temp)
    }

    const data_manupulator_mentor = (mentor) => {
        console.log("mentor type", mentor);
        let temp = mentor;
        temp = temp?.map(bat => {
            bat.key = bat.first_name;
            bat.id = bat.user_id;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setMentor_name(temp)
    }

    const data_manupulator_college = (college) => {
        console.log("college type", college);
        let temp = college;
        temp = temp?.map(bat => {
            bat.key = bat.college_name;
            bat.id = bat.id;
            return bat;
        });
        setCollege_name1(temp)
        console.log("temp rows batch:", temp)
    }

    const data_manupulator_batch = (batch) => {
        console.log("bactyh type", batch);
        let temp = batch;
        temp = temp?.map(bat => {
            bat.key = bat.user_group_name;
            bat.id = bat.id;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setBatch_name(temp)
    }

    const data_manupulator_role = (role) => {
        console.log("role type", role);
        let temp = role;
        temp = temp?.map(bat => {
            bat.key = bat.user_group_name;
            bat.id = bat.id;
            return bat;
        });
        console.log("temp rows batch:", temp)
        setRole_name(temp)
    }

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }

            //batch data getter
            const batchData = await batchDataGetter();

            const activityType = await activityTypeGetter();
            const duration = await DurationGetter();
            const mentor = await mentorGetter()
            const college = await CollegeGetter()
            const batch = await batchGetter()
            const roleType = await roleTypeGetter()
            // const batchAccordingToCollege = await batchAccordingToCollegeGetter()

            data_manupulator_activity(activityType);
            data_manupulator_duration(duration);
            data_manupulator_mentor(mentor);
            data_manupulator_college(college);
            data_manupulator_batch(batch);
            data_manupulator_role(roleType)
        }

        handleEffect();
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])
    const batchDataGetter = async () => {
        try {
            const res = await service.get(`/groupSession`)
            console.log("group session info RESPONSE:", res.data)
            setEntireGroupSessionData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            {loading ? (
                <Loader show={loading} />
            ) : (
                <>
                    <TopBar />
                    <SideNavigationBar />
                    <div className='OngoingEventsCard' style={{ marginLeft: "10rem", marginTop: "6rem" }}>
                        <div style={{ position: 'relative' }}>
                            <p className='topHeadings' style={{ marginBottom: "2rem" }}>B2C Process</p>
                            <Typography style={{ marginBottom: "2rem" }} sx={{ fontSize: 20 }}>Ongoing Events</Typography>
                            <B2CProcessComponent />
                        </div>
                    </div>
                </>
            )}

        </div>
    )
}

export default B2CProcess