import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, CircularProgress, IconButton, InputAdornment, MenuItem, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Color, DivCenter, PrimaryButton } from '../../../../GlobalStyles';
import { ellipsisFunction } from '../../../Reusable_functions/TextFunctions/EllipsisFunction';
import '../JobCompanies/main/JobCompanies.css';
// import { ViewDetailsModal } from '../additional/ViewDetailsModal';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TimeService from '../../../../functions/epochTime';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import { ActivityLoader, SmallPageLoader } from '../../../reusables/Loader/Loader';
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import { AddCircleOutline, ControlPointOutlined, Search } from '@mui/icons-material';
const JobColleges = () => {

    //obejcts and instances
    const services = new CCService();
    const timeServices = new TimeService();

    const [loading, setLoading] = useState({ page_loader: false, activity_loader: false, modalLoader: false });
    const navigate = useNavigate();
    const [jobColleges, setJobColleges] = useState([
        {
            id: null,
            college_name: '',
            applicants_count: null,
            jobs_count: null,
        }
    ]);


    const adminType = { selected_value: { id: null, value: '' }, options: [{ id: 1, value: 'N/A' }, { id: 2, value: 'Chairperson' }, { id: 3, value: 'Director' }, { id: 4, value: 'Placement Committee Member' }, { id: 5, value: 'Placement Officer' }, { id: 6, value: 'College Admin' }] }

    const [viewDetails, setViewDetails] = useState({
        open: false,
        onClose: () => { setViewDetails((prev) => ({ ...prev, open: false })) },
        flag: '',
        data: {
            "College Name": 'N/A',
            "Admin Type": 'N/A',
            "First Name": 'N/A',
            "Last Name": 'N/A',
            "Designation": 'N/A',
            "Email": 'N/A',
            "Contact": ''
        },
        extra_data: {
            "Id": null
        }
    });

    //View Users
    const [viewUsers, setViewUsers] = useState({
        open: false,
        onClose: () => { setViewUsers((prev) => ({ ...prev, open: false })) },
        flag: '',
        data: [{}],
        extra_data: { "Id": null }
    });
    console.log("JobColleges ~ viewUsers:", viewUsers)

    const initial_user_data = {
        "job_college_id": null,
        "college_name": '',
        "job_college_user_id": null,
        "college_admin_type": '',
        "first_name": "",
        "last_name": "",
        "mobile": "",
        "designation": "",
        "email_id": ""
    }

    const [modifyCollegeData, setModifyCollegeData] = useState({
        open: false,
        onClose: () => { setModifyCollegeData((prev) => ({ ...prev, open: false })) },
        page: 0,
        data: [{}],
        selected_data: { ...initial_user_data },
        extra_data: { college_id: null, college_name: '' },
    });
    console.log("JobColleges ~ modifyCollegeData:", modifyCollegeData)

    // const [onHoldRemarks, setOnHoldRemarks] = useState({ flag: '', show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });

    //Search module
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredJobColleges, setFilteredJobColleges] = useState([...jobColleges]);

    const handleMainUseEffect = async () => {
        setLoading((prev) => ({ ...prev, page_loader: true }));
        //fetching the data
        const companies_data = await fetchCompaniesData();
        console.log({ companies_data });
        setJobColleges(companies_data);
        setFilteredJobColleges(companies_data);
        setLoading((prev) => ({ ...prev, page_loader: false }));
    }

    useEffect(() => {
        handleMainUseEffect();
    }, []);

    const fetchCompaniesData = async () => {
        try {
            const response = await services.get(`/jobs/admin/allColleges`);
            console.log("response ~ fetchCompaniesData:", response);
            if (response) return response.data;
        } catch (error) {
            console.error('Failed to fetch Colleges Data', error);
            return [];
        }
    };

    const fetchCollegeUserDetails = async (college_id) => {
        try {
            const fetchCollegeUser = await services.get(`/jobs/admin/allCollegeUser?job_college_id=${college_id}`);
            console.log("fetchCollegeUser:", fetchCollegeUser);
            if (fetchCollegeUser) return fetchCollegeUser.data;
        } catch (error) {
            console.error('Failed to fetch Colleges Data', error);
            return [];
        }
    };


    const handleClickViewUsers = async (id, college_name) => {
        setLoading((prev) => ({ ...prev, activity_loader: true }));

        const fetchUser = await fetchCollegeUserDetails(id);

        setModifyCollegeData((prev) => ({
            ...prev,
            open: true,
            page: 2,
            data: fetchUser,
            extra_data: {
                college_id: id ?? null,
                college_name: college_name ?? ''
            },
        }));

        setLoading((prev) => ({ ...prev, activity_loader: false }));
    }

    const handleAddNewCollege = () => {
        setModifyCollegeData((prev) => ({
            ...prev,
            open: true,
            selected_data: { ...initial_user_data },
            page: 0,
            data: [],
            extra_data: {
                college_name: '',
                college_id: null
            }
        }));

    }

    const handleChangeSearch = (event) => {
        setSearchTerm(event.target.value);
        const normalizedTerm = event.target.value.toLowerCase();
        const filteredResults = jobColleges.filter((college) =>
            college.college_name.toLowerCase().includes(normalizedTerm)
        );
        setFilteredJobColleges(filteredResults);
    }


    function AddCollege_and_Users({ open, onClose, data, extra_data }) {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            bgcolor: 'background.paper',
            // border: '1px solid #000',
            border: 'none',
            borderRadius: '20px',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
        };

        const [formInput, setFormInput] = useState({ ...modifyCollegeData.selected_data });
        console.log("AddCollege_and_Users ~ formInput:", formInput)
        const [formError, setFormError] = useState({
            college_name: '',
            first_name: '',
            last_name: '',
            email_id: '',
            mobile: '',
            designation: '',
            college_admin_type: ''
        });

        const handleEditCollegeUser = (user) => {
            console.log("handleEditCollegeUser ~ user:", user)
            setModifyCollegeData((prev) => ({
                ...prev,
                open: true,
                page: 1,
                selected_data: {
                    "job_college_id": user.job_college_id,
                    "college_name": user.college_name ?? modifyCollegeData.extra_data.college_name,
                    "job_college_user_id": user.job_college_user_id,
                    "college_admin_type": user.college_admin_type,
                    "first_name": user.first_name,
                    "last_name": user.last_name,
                    "mobile": user.mobile,
                    "designation": user.designation,
                    "email_id": user.email_id
                }
            }));
        }

        const handleAddCollegeUser = () => {
            setModifyCollegeData((prev) => ({
                ...prev,
                open: true,
                page: 1,
                selected_data: { ...initial_user_data, job_college_id: prev.extra_data.college_id }
            }));
        }

        const handleChangeEditCollegeModal = ({ name, value }) => {
            console.log("name, value:", name, value);
            setFormInput((prev) => ({
                ...prev,
                [name]: value
            }));
            if (name !== 'Last Name')
                setFormError((prev) => ({
                    ...prev,
                    [name]: ''
                }));
        }

        const handleSaveCompany = async () => {

            if (formInput.college_name) {
                try {
                    const body = {
                        "college_name": formInput.college_name
                    }
                    const response = await services.post(`/jobs/admin/allColleges`, body);
                    console.log("handleSaveCompany ~ response:", response)
                    setModifyCollegeData((prev) => ({
                        ...prev,
                        page: 1,
                        selected_data: { ...initial_user_data },
                        extra_data: {
                            college_id: response.data.job_college_id,
                            college_name: formInput.college_name
                        }
                    }));
                } catch (error) {
                    console.error("Error saving company:", error);
                    window.alert(error.response.data.message)
                }
            } else {
                setFormError((prev) => ({ ...prev, college_name: "*This is required" }));
            }
        }

        const handleSaveCompanyUsers = async () => {
            let temp_formError = { ...formError };
            for (const error of Object.keys(formError)) {
                if (["", null, 0].includes(formInput[error]) && !['college_name', 'last_name', 'mobile'].includes(error)) {
                    console.log("error to be checked:", error);
                    temp_formError[error] = '*This is required';
                    console.log("temp_formError:", temp_formError);
                    // setFormError((prev) => ({ ...prev, [error]: 'Please fill this' }));
                }
                else if (error === "mobile") {
                    // if (typeof formInput[error] === 'string')
                    //     temp_formError["Contact"] = 'This should be a number';
                    if ((formInput[error]) > 0 && (formInput[error]).length < 7)
                        temp_formError["mobile"] = 'This should be atleast 7 characters long';
                }
            }
            console.log("body.formInput:", formInput);
            if (Object.values(temp_formError).every(value => value === "")) {
                try {
                    const body = {
                        "job_college_user_id": formInput.job_college_user_id ?? undefined,
                        "job_college_id": formInput.job_college_id ?? modifyCollegeData.extra_data.college_id,
                        "first_name": formInput.first_name,
                        "last_name": formInput.last_name ?? "",
                        "email_id": formInput.email_id,
                        "mobile": formInput.mobile ?? "",
                        "designation": formInput.designation,
                        "college_admin_type": formInput.college_admin_type
                    };

                    // Perform the API call to save the data
                    const response = await services.post(`/jobs/admin/allCollegeUser`, body);
                    console.log("handleSaveCompany ~ response:", response);

                    //in case of edit
                    if (formInput.job_college_user_id) {
                        // Update existing data
                        const updatedData = modifyCollegeData.data.map((item) => {
                            if (item.job_college_user_id === formInput.job_college_user_id) {
                                return { ...item, ...body };
                            }
                            return item;
                        });

                        setModifyCollegeData((prev) => ({
                            ...prev,
                            page: 2,
                            data: updatedData,
                            selected_data: { ...initial_user_data }
                        }));
                    }
                    //in case of add
                    else {
                        // Update job_college_user_id from the response
                        const updatedBody = {
                            ...body,
                            "job_college_user_id": response.data.job_college_user_id
                        };
                        // Add new data at the end
                        setModifyCollegeData((prev) => ({
                            ...prev,
                            page: 2,
                            data: [...prev.data, updatedBody],
                            selected_data: { ...initial_user_data }
                        }));
                    }
                } catch (error) {
                    console.error("Error saving company:", error);
                    window.alert(error.response.data.message)
                }
            } else {
                setFormError(temp_formError);
            }

        };


        const handleCancelModifyModal = () => {
            setModifyCollegeData((prev) => ({
                ...prev,
                open: false,
                page: 0,
            }));
            handleMainUseEffect();
        }

        return (
            <Modal
                open={open}
                onClose={handleCancelModifyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{ ...style, height: 'auto', width: '700px', background: 'white', }}>
                    <Box className='box__companyName' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className='typography__companyName' sx={{ height: '100%' }}>
                            {modifyCollegeData.extra_data.college_name ? ellipsisFunction(modifyCollegeData.extra_data.college_name, 50) : "Add New College"}
                        </Typography>
                        <IconButton
                            onClick={handleCancelModifyModal}
                            color="inherit"
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {/* {loading.modalLoader ?
                        <Box sx={{ ...DivCenter, width: '100%', minWidth: '500px', height: '100%', minHeight: '200px' }}>
                            <CircularProgress size={50} sx={{ color: Color.primary1 }} />
                        </Box>
                        :  */}
                    {console.log("AddCollege_and_Users ~ modifyCollegeData:", modifyCollegeData)}
                    <Box sx={{ ...DivCenter, justifyContent: 'flex-start', gap: '16px', flexWrap: 'wrap', pt: '20px', pb: !modifyCollegeData.page ? '20px' : '0px' }}>
                        {modifyCollegeData.page === 0 ?
                            <Box sx={{ width: '100%', height: '100%', gap: '5%' }}>
                                <Box sx={{ display: 'flex', width: '100%', height: '100%', gap: '5%' }}>
                                    <TextField name="add_new_college" label="Enter college name" autoComplete='false' inputProps={{ autocomplete: 'off' }} value={formInput.college_name} onChange={(e) => {
                                        setFormInput((prev) => ({ ...prev, college_name: e.target.value }));
                                        setFormError((prev) => ({ ...prev, college_name: "" }));
                                    }}
                                        sx={{ width: '80%' }} />
                                    <PrimaryButton variant='contained' onClick={() => handleSaveCompany()} sx={{ minWidth: '80px', height: '56px' }}>Save</PrimaryButton>
                                </Box>
                                <Typography sx={{ color: 'red', fontSize: '12px' }}>{formError.college_name}</Typography>
                            </Box>
                            : modifyCollegeData.page === 1 ?
                                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', justifyContent: 'space-between', gap: '16px' }}>
                                    {/* Full Name */}
                                    <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '45%' }}>
                                            <Typography sx={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}> First Name<span style={{ color: 'red' }}>*</span> : </Typography>
                                            <TextField
                                                name={'first_name'}
                                                value={formInput.first_name}
                                                onChange={(e) => handleChangeEditCollegeModal(e.target)}
                                                sx={{ width: '100%', color: Color.neutralBlack, fontSize: '16px' }}>
                                            </TextField>
                                            <Typography sx={{ width: '100%', textAlign: 'left', color: 'red', fontSize: '12px' }}>{formError.first_name}</Typography>
                                        </Box>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '45%' }}>
                                            <Typography sx={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}> Last Name : </Typography>
                                            <TextField
                                                name={'last_name'}
                                                value={formInput.last_name}
                                                onChange={(e) => handleChangeEditCollegeModal(e.target)}
                                                sx={{ width: '100%', color: Color.neutralBlack, fontSize: '16px' }}>
                                            </TextField>
                                        </Box>
                                    </Box>
                                    {/* Email and Mobile */}
                                    <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '45%' }}>
                                            <Typography sx={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>Email<span style={{ color: 'red' }}>*</span> : </Typography>
                                            <TextField
                                                name={'email_id'}
                                                value={formInput.email_id}
                                                onChange={(e) => handleChangeEditCollegeModal(e.target)}
                                                sx={{ width: '100%', color: Color.neutralBlack, fontSize: '16px' }}>
                                            </TextField>
                                            <Typography sx={{ width: '100%', textAlign: 'left', color: 'red', fontSize: '12px' }}>{formError.email_id}</Typography>
                                        </Box>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '45%' }}>
                                            <Typography sx={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>Mobile : </Typography>
                                            <TextField
                                                name={'mobile'}
                                                value={formInput.mobile}
                                                onChange={(e) => handleChangeEditCollegeModal(e.target)}
                                                sx={{ width: '100%', color: Color.neutralBlack, fontSize: '16px' }}>
                                            </TextField>
                                            <Typography sx={{ width: '100%', textAlign: 'left', color: 'red', fontSize: '12px' }}>{formError.mobile}</Typography>
                                        </Box>
                                    </Box>
                                    {/* Designation and Admin Type */}
                                    <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '45%' }}>
                                            <Typography sx={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>Designation<span style={{ color: 'red' }}>*</span> : </Typography>
                                            <TextField
                                                name={'designation'}
                                                value={formInput.designation}
                                                onChange={(e) => handleChangeEditCollegeModal(e.target)}
                                                sx={{ width: '100%', color: Color.neutralBlack, fontSize: '16px' }}>
                                            </TextField>
                                            <Typography sx={{ width: '100%', textAlign: 'left', color: 'red', fontSize: '12px' }}>{formError.designation}</Typography>
                                        </Box>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '45%' }}>
                                            <Typography sx={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>Admin Type<span style={{ color: 'red' }}>*</span> : </Typography>
                                            <TextField
                                                select
                                                name='college_admin_type'
                                                value={formInput.college_admin_type ?? 'N/A'}
                                                onChange={(e) => handleChangeEditCollegeModal(e.target)}
                                                sx={{ width: '100%', color: Color.neutralBlack, fontSize: '16px' }}>
                                                {adminType.options.map((option) => (
                                                    <MenuItem key={option.id} value={option.value} disabled={option.value === 'N/A' ? true : false}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <Typography sx={{ width: '100%', textAlign: 'left', color: 'red', fontSize: '12px' }}>{formError.college_admin_type}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', justifyContent: 'flex-end', gap: '16px', mt: '16px' }}>
                                        {formInput.job_college_id ?
                                            <Button variant='outlined' onClick={() => setModifyCollegeData((prev) => ({ ...prev, page: 2 }))} sx={{ minWidth: '100px', height: '50px', }}>Back</Button>
                                            : <Button variant='outlined' onClick={() => handleCancelModifyModal()} sx={{ minWidth: '100px', height: '50px', }}>Cancel</Button>
                                        }
                                        <PrimaryButton variant='contained' onClick={() => handleSaveCompanyUsers()} sx={{ minWidth: '100px', height: '50px' }}>Save</PrimaryButton>
                                    </Box>
                                </Box>
                                : modifyCollegeData.page === 2 ?
                                    <Box sx={{ ...DivCenter, flexDirection: 'row', width: '100%', justifyContent: 'flex-start', gap: '16px', flexWrap: 'wrap' }}>
                                        {modifyCollegeData.data.map((user) =>
                                            <Card className='card__companyCards'
                                                onClick={() => handleEditCollegeUser(user)}
                                                sx={{ minHeight: '196px', minWidth: '200px!important', cursor: 'pointer' }}>
                                                <Box sx={{ height: '100%', width: '100%' }}>
                                                    <Box className='box__companyCardContent' sx={{ justifyContent: 'space-evenly' }}>
                                                        <Box className='box__companyDetails' sx={{ height: 'auto', overflowWrap: 'break-word', hyphens: "auto" }}> {/* from 70% */}
                                                            <Typography className='typography__box_companyCardContent'>
                                                                {user.first_name + " " + ([null, " ", "", undefined, "null", "undefined"].includes(user.last_name) ? "" : user.last_name)}
                                                            </Typography>
                                                            <Typography className='typography__box_companyCardContent'>
                                                                {user.email_id}
                                                            </Typography>
                                                            <Typography className='typography__box_companyCardContent'>
                                                                {user.mobile}
                                                            </Typography>
                                                            <Typography className='typography__box_companyCardContent'>
                                                                {user.designation}
                                                            </Typography>
                                                            <Typography className='typography__box_companyCardContent'>
                                                                {user.college_admin_type}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        )}
                                        <Card className='card__companyCards'
                                            onClick={() => handleAddCollegeUser()}
                                            sx={{ ...DivCenter, minHeight: '196px', minWidth: '200px!important', cursor: 'pointer' }}>
                                            <Box sx={{ ...DivCenter, width: '100%', height: '100%' }}>
                                                <ControlPointOutlined sx={{ width: '60px', height: '60px', color: Color.neutralDarkGrey }} />
                                            </Box>
                                        </Card>
                                    </Box>
                                    : <Box sx={{ ...DivCenter, }}>Invalid Page Number</Box>
                        }
                        {/* {viewUsers.data.map((user) =>
                            <Card className='card__companyCards'
                                onClick={() => handleEditCollegeUser(user)}
                                sx={{ minHeight: '196px', minWidth: '200px!important', cursor: 'pointer' }}>
                                <Box sx={{ height: '100%', width: '100%' }}>
                                    <Box className='box__companyCardContent' sx={{ justifyContent: 'space-evenly' }}>
                                        <Box className='box__companyDetails' sx={{ height: 'auto' }}>
                                            <Typography className='typography__box_companyCardContent'>
                                                {user.first_name + " " + user.last_name}
                                            </Typography>
                                            <Typography className='typography__box_companyCardContent'>
                                                {user.email_id}
                                            </Typography>
                                            <Typography className='typography__box_companyCardContent'>
                                                {user.mobile}
                                            </Typography>
                                            <Typography className='typography__box_companyCardContent'>
                                                {user.designation}
                                            </Typography>
                                            <Typography className='typography__box_companyCardContent'>
                                                {user.college_admin_type}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        )} */}
                    </Box>
                    {/* } */}
                </Box>
            </Modal>
        )
    }

    return (
        <>
            {loading.page_loader ?
                <SmallPageLoader show={loading} /> :
                <Box className='main_box'>
                    <ActivityLoader show={loading.activity_loader} />
                    <SideNavigationBar />
                    <Box sx={{ width: '10%' }}></Box>
                    <Box sx={{ width: '85%', height: '100%', display: 'flex', flexDirection: 'column', gap: '33px', alignItems: 'flex-start' }}>
                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', mt: '10px', gap: '24px' }}>
                            <Typography sx={{ width: '10%', fontSize: '32px', color: 'black' }}>Colleges</Typography>
                            {/* <Box sx={{ width: '20%' }}> */}
                            <Button variant='outlined'
                                sx={{ border: `1px solid ${Color.primary1}`, color: Color.primary1, textTransform: 'none', gap: '10px' }}
                                // onClick={() => handleAddNewCollege()}>
                                onClick={() => handleAddNewCollege()}>
                                <AddCircleOutline />Add New College
                            </Button>
                            {/* </Box> */}
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'flex-start', gap: '8px', width: '100%' }}>
                            {/* <Typography>Search Colleges</Typography> */}
                            <TextField
                                name="search_colleges"
                                label="Search Colleges"
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleChangeSearch}
                                fullWidth
                                sx={{ width: '50%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className='box__companyCards'>
                            {filteredJobColleges.length ?
                                filteredJobColleges.map((job_college) =>
                                    <Card className='card__companyCards' sx={{ height: '150px', minHeight: '' }}>
                                        <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'space-between', height: '100%', width: '100%' }}>
                                            {/* <Box className='box__companyName'> */}
                                            <Typography className='typography__companyName' sx={{ textAlign: 'left', height: '100%', overflowWrap: 'break-word', hyphens: "auto" }}>{ellipsisFunction(job_college.college_name, 60)}</Typography>
                                            {/* </Box> */}

                                            {/* <Box className='box__companyCardContent' sx={{ justifyContent: 'space-evenly' }}> */}
                                            {/* <Box className='box__companyDetails' sx={{ height: '70%' }}> */}
                                            {/* <Typography className='typography__box_companyCardContent'>Number of Applicants: {job_college.applicants_count ?? 'Coming soon'}</Typography>
                                                <Typography className='typography__box_companyCardContent'>Number of jobs applied to: {job_college.jobs_count ?? 'Coming soon'}</Typography> */}
                                            {/* </Box> */}
                                            {/* <Box className='box__actionButtons' sx={{ height: '100%' }}> */}
                                            <Button variant='contained' className='button__actionButtons'
                                                onClick={() => { handleClickViewUsers(job_college.job_college_id, job_college.college_name) }}
                                                sx={{ background: Color.primary_gradient, height: '56px', '&:hover': { background: Color.primary_gradient, boxShadow: 'none' } }}>
                                                <Typography sx={{ color: 'white', textTransform: 'none' }}>View Users</Typography>
                                            </Button>
                                            {/* </Box> */}
                                            {/* </Box> */}
                                        </Box>
                                    </Card>)
                                : <Typography sx={{ color: Color.neutralMidGrey }}>No Colleges available</Typography>
                            }
                        </Box>
                    </Box>
                    <Box sx={{ width: '5%' }}></Box>
                    {/* <CollegeModalAddEdit
                        open={viewDetails.open}
                        onClose={viewDetails.onClose}
                        data={viewDetails.data}
                        extra_data={viewDetails.extra_data}
                    /> */}
                    {/* <ViewUsersModal
                        open={viewUsers.open}
                        onClose={viewUsers.onClose}
                        data={viewUsers.data}
                        extra_data={viewUsers.extra_data}
                    /> */}
                    <AddCollege_and_Users
                        open={modifyCollegeData.open}
                        onClose={modifyCollegeData.onClose}
                        data={modifyCollegeData.data}
                        extra_data={modifyCollegeData.extra_data}
                    />
                </Box >}
        </>
    )
}

export default JobColleges;