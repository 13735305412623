import { Box, Button, CircularProgress, MenuItem, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Color, DivCenter, PrimaryButton, PrimaryButtonOutlined } from '../../../../GlobalStyles';
import { Circle, DeleteOutlined, UploadFile, UploadFileOutlined } from '@mui/icons-material';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import './StudentSummary.css'
import Cookies from 'js-cookie';
import axios from 'axios';

export const EditStudentSpecilization = ({ open, onClose, extraData, getStudentDetails, setStudentDetails }) => {
    console.log("EditStudentSpecilization ~ extraData:", extraData)
    const {
        is_edit = false,
        details: { first_name = "", last_name = "", provided_spe_name = "", sku_name = "", college_name = "", batch_name = "", batch_type = "",
        },
        order,
        spe: {
            selected,
            options,
        }
    } = extraData;
    console.log("EditStudentSpecilization ~ order:", order)
    const service = new CCService();
    const [loading, setLoading] = useState({ modal: false, button: false });
    const [formInput, setFormInput] = useState({
        spe: { id: null, name: '' },
        resume: { url: "", name: "" },
        other_docs: []
    })
    const modalBoxStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        padding: "1.5rem 2rem",
        borderRadius: "20px",
        minWidth: "800px",
        height: "auto",
        minHeight: '250px'
    };

    const styles = {
        button_styles: {
            contained: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.primary1,
                color: Color.white,
                borderRadius: '12px',
                textTransform: 'none',
                '&:hover': {
                    background: Color.primary1,
                    color: Color.white,
                }
            },
            outlined: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.white,
                color: Color.primary1,
                borderRadius: '12px',
                border: `1px solid ${Color.primary1}`,
                textTransform: 'none',
                '&:hover': {
                    background: Color.white,
                    color: Color.primary1,
                }
            }
        }
    }
    console.log("EditStudentSpecilization ~ formInput:", formInput, formInput.spe, options)

    //FUNCTIONS

    useEffect(() => {
        const handleEffect = () => {
            setLoading((prev) => ({ ...prev, modal: true }));
            console.log("setFormInput ~ spe_name:", selected, options)
            setFormInput((prev) => ({
                ...prev, spe: selected
            }))
            setLoading((prev) => ({ ...prev, modal: false }));
        }
        handleEffect();
    }, [])


    const handleSelectSpecialization = ({ target }) => {
        const { name, value } = target;
        console.log("handleSelectSpecialization ~ value:", value);
        setFormInput((prev) => ({
            ...prev,
            spe: value
        }));
    }

    const handleFileChange = async (event) => {
        const files = event.target.files;
        const { name } = event.target;
        console.log("files...", files);
        if (files) {
            try {
                const fileUrl_response = await uploadFile(files, name);
                console.log("fileUrl", fileUrl_response);
                let temp_formInput = { ...formInput };
                if (name === 'other_docs') {
                    // setFormInput((prev) => ({ ...prev, resume: fileUrl_response.resume }));
                    temp_formInput.other_docs.push(fileUrl_response.other_docs.files[0]);
                } else {
                    if (fileUrl_response.resume) {
                        // setFormInput((prev) => ({ ...prev, resume: fileUrl_response.resume }))
                        temp_formInput.resume = fileUrl_response.resume
                    }
                }
                setFormInput(temp_formInput);
            } catch (error) {
                console.error("Error handling image upload:", error);
            }
        }
    };

    const uploadFile = async (selectedFileName, key) => {
        console.log("selectedFileName", selectedFileName, key);
        const headers = {
            Authorization: `Bearer ${Cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
        };

        const blob_image = new Blob([selectedFileName[0]], {
            type: 'application/pdf'
        });

        const image_data = new FormData();
        if (key.toLowerCase() === 'other_docs')
            image_data.append(
                key + ("_" + formInput.other_docs.length + 1) + `${selectedFileName[0].name}`,
                blob_image,
                `${selectedFileName[0].name}`
            );
        else image_data.append(
            key + `${selectedFileName[0].name}`,
            blob_image,
            `${selectedFileName[0].name}`
        );

        try {
            const imageRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/os/superadmin/order/file?user_id=${order.user_id}`,
                image_data,
                { headers }
            );
            console.log("uploadFile ~ imageRes:", imageRes)
            if (imageRes?.data) return imageRes.data;
            else return null;

        } catch (err) {
            console.error("Error uploading image:", err);
            // Handle the error as needed
            return null; // or throw an error
        }

    }

    const handleRemoveResume = () => {
        setFormInput((prev) => ({ ...prev, resume: { name: '', url: '' } }))
    }

    const handleRemoveOtherDocs = (name, index) => {
        if (formInput.other_docs.length === 1) {
            setFormInput((prev) => ({ ...prev, other_docs: [] }));
        } else if (formInput.other_docs.length > 1)
            setFormInput((prev) => ({ ...prev, other_docs: prev.other_docs.filter((doc, doc_index) => doc.name.toLowerCase() === name.toLowerCase() && doc_index === index) }));
    }

    const handleSubmit = async () => {
        setLoading((prev) => ({ ...prev, button: true }));
        try {
            if (is_edit) {
                const put_body = {
                    "order_id": order.id,
                    "spe_id": formInput.spe.id,
                }
                await service.put(`/os/superadmin/order`, put_body);
            } else {
                const put_body = {
                    "order_id": order.id,
                    "spe_id": formInput.spe.id,
                    "resume": formInput.resume,
                    "other_docs": formInput.other_docs
                }
                await service.put(`/os/superadmin/order`, put_body);
            }
            const student_details = await getStudentDetails();    //ORDER
            console.log("student details", student_details)
           
            setStudentDetails(student_details);
            onClose();
            setLoading((prev) => ({ ...prev, button: false }));
        } catch (error) {
            console.error('Error while editing student specialization:', error);
            setLoading((prev) => ({ ...prev, button: false }));
        }
    }



    return (
        <Modal open={open} onClose={loading.button ? {} : onClose}>
            {loading.modal ?
                <Box sx={{ ...modalBoxStyle, ...DivCenter, justifyContent: 'flex-start', alignItems: 'flex-start', gap: '16px' }}>
                    <CircularProgress size={24} sx={{ color: Color.primary1 }} />
                </Box>
                : 
                <Box sx={{ ...modalBoxStyle, maxHeight:"600px"}}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '90%', alignItems: "center", justifyContent: "space-between", marginBottom: "8px", }}>
                        <Typography style={{ fontSize: "20px", fontWeight: 'bold' }}>
                            {is_edit ? "Edit Student Specialization" : "Add Student Specialization"}
                        </Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, justifyContent: 'space-between', width: '100%',  }}>
                        <Box sx={{ ...DivCenter, alignItems: 'flex-start', flexDirection: 'column', width: '50%', gap: '12px', }}>
                            <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>{first_name + " " + last_name}</Typography>
                            {is_edit ? <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>Provided Specialization: {provided_spe_name}</Typography> : <></>}
                        </Box>
                        <Box sx={{ ...DivCenter, alignItems: 'flex-end', flexDirection: 'column', width: '50%', }}>
                            <Typography style={{ fontSize: '18px', }}>{sku_name ?? 'SKU'}</Typography>
                            <Typography style={{ color: Color.neutralMidGrey, fontSize: '12px' }}>{(college_name ?? 'College') + ", " + (batch_name ?? 'Batch') + ", " + (batch_type ?? "Batch Type") + ", "}</Typography>
                        </Box>
                    </Box>
                    <hr style={{ width: '100%', margin: '8px 0' }} />
                    <Box sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: '16px',  width:"100%", maxHeight:"550px", overflowY:"scroll"}}>
                   
                    <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', width: '100%', gap: '12px', minHeight: is_edit ? "150px" : '100px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 'bold' , alignSelf:"flex-start"}}>Select a Specialization</Typography>
                        <TextField select value={formInput.spe} onChange={handleSelectSpecialization} sx={{
                            width: '100%',
                            '&:focus': {
                                borderColor: 'pink',
                            },
                            '&:active': {
                                borderColor: 'pink',
                            }
                        }}>
                            {options.map((s) =>
                                <MenuItem key={s.id} value={s}>
                                    {s.name}
                                </MenuItem>)}
                        </TextField>
                    </Box>
                    {!is_edit ?
                        <>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '12px' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 'bold', alignSelf:"flex-start" }}>Upload resume</Typography>
                                {formInput.resume.name ?
                                    <Box className='box__fileUpload'>
                                        <Typography sx={{ ml: '5px', textTransform: 'none', color: Color.neutralBlack, fontSize: '14px' }}>
                                            {formInput.resume.name}
                                        </Typography>
                                        <DeleteOutlined onClick={() => handleRemoveResume()} sx={{ cursor: 'pointer' }} />
                                    </Box>
                                    : <>
                                        <Box
                                            onClick={() => {
                                                document.getElementById('fileInput1').click();
                                            }}
                                            sx={{ ...DivCenter, flexDirection: 'column', border: `1px solid ${Color.primary1}`, borderRadius: '12px', width: '100%', height: '126px', cursor: 'pointer' }}>
                                            <input
                                                name='resume'
                                                type="file"
                                                accept=".pdf"
                                                multiple
                                                onChange={(e) => handleFileChange(e)}
                                                style={{ display: 'none' }}
                                                id="fileInput1"
                                            />
                                            <UploadFileOutlined sx={{ width: '44px', height: '44px', color: Color.neutralMidGrey }} />
                                            <Typography sx={{ fontSize: '12px' }}>Upload resume</Typography>
                                        </Box>
                                    </>}
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '12px' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 'bold',  alignSelf:"flex-start" }}>Upload document</Typography>
                                {formInput.other_docs.length ?
                                    <>
                                        {formInput.other_docs.map((doc, doc_index) =>
                                            <Box className='box__fileUpload'>
                                                <Typography sx={{ ml: '5px', textTransform: 'none', color: Color.neutralBlack, fontSize: '14px' }}>
                                                    {doc_index + 1}. {doc.name}
                                                </Typography>
                                                <DeleteOutlined onClick={() => handleRemoveOtherDocs(doc.name, doc_index)} sx={{ cursor: 'pointer' }} />
                                            </Box>)}
                                        <input
                                            name='other_docs'
                                            type="file"
                                            accept=".pdf"
                                            multiple
                                            onChange={(e) => handleFileChange(e)}
                                            style={{ display: 'none' }}
                                            id="fileInput2"
                                        />
                                        <Button
                                            sx={{ textTransform: 'none', color: Color.primary1 }}
                                            onClick={() => {
                                                document.getElementById('fileInput2').click();
                                            }}>
                                            + Add New File
                                        </Button>
                                    </>
                                    : <>
                                        <Box
                                            onClick={() => {
                                                document.getElementById('fileInput2').click();
                                            }}
                                            sx={{ ...DivCenter, flexDirection: 'column', border: `1px solid ${Color.primary1}`, borderRadius: '12px', width: '100%', height: '126px', cursor: 'pointer' }}>
                                            <input
                                                name='other_docs'
                                                type="file"
                                                accept=".pdf"
                                                multiple
                                                onChange={(e) => handleFileChange(e)}
                                                style={{ display: 'none' }}
                                                id="fileInput2"
                                            />
                                            <UploadFileOutlined sx={{ width: '44px', height: '44px', color: Color.neutralMidGrey }} />
                                            <Typography sx={{ fontSize: '12px'}}>Upload document</Typography>
                                        </Box>
                                    </>
                                }
                            </Box>
                        </> : <></>}
                    <Box sx={{ ...DivCenter, width: '100%', gap: '12px' , marginTop:"12px"}}>
                        <Button disabled={loading.button} onClick={onClose} sx={{ ...styles.button_styles.outlined, width: '45%' }}>Cancel</Button>
                        <Button disabled={loading.button} onClick={handleSubmit} sx={{ ...styles.button_styles.contained, width: '45%' }}>
                            {loading.button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Submit"}
                        </Button>
                    </Box>
                   </Box>
                </Box>}
        </Modal >
    )
}