import { Box, Typography, Modal } from "@mui/material";
import { Color, DivCenter } from "../../../../GlobalStyles";
import { formatConvertermonthfirst } from "../../../../services/specialCharReplace";
import { useState } from "react";
import { StudentsSwapModal } from "./ReleaseNextListModals";

export function StudentStatsListView({ studentStatList, waitingSwapped, process_id }) {

    //data varaible
    const [swappedModal, setSwappedModal] = useState({
        open: false,
        onClose: () => setSwappedModal((prev) => ({ ...prev, open: false })),
        extraData: {}
    })

    const handleClickStudentsSwapped = () => {
        setSwappedModal((prev) => ({ ...prev, open: true, extraData: { heading: 'Total Students Swapped', process_id: process_id } }))
    }


    return (
        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', p: '16px', gap: '16px' }}>
            <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 600, width: '100%' }}>Student schedule summary from list</Typography>
            <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: 'rgba(249, 253, 254, 1)', height: '62px' }}>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>List</Typography>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Scheduled</Typography>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Yet to schedule</Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                {studentStatList.map((student, index) =>
                    <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: index % 2 ? 'rgba(249, 253, 254, 1)' : '', height: '62px' }}>
                        {console.log("StudentStatsListView ~ student:", student)}
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{student.list_no}</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>{student.scheduled}</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>{student.yet_to_schedule}</Typography>
                    </Box>)}

            </Box>
            <Box sx={{ ...DivCenter, boxShadow: '0px 6px 11px 0px rgba(0, 0, 0, 0.11)', p: '20px', borderRadius: '12px' }}>
                <Box sx={{ ...DivCenter, flexDirection: 'column', gap: '31px', p: '20px 35px' }}>
                    <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Students waiting</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '32px', color: Color.primary1 }}>{[null, undefined].includes(waitingSwapped[0]?.students_waiting) ? 'N/A' : waitingSwapped[0]?.students_waiting}</Typography>
                </Box>
                <div style={{ background: Color.neutralMidGrey, height: '140px', width: '1px' }} />
                <Box onClick={() => handleClickStudentsSwapped()} sx={{ ...DivCenter, flexDirection: 'column', gap: '31px', p: '20px 35px', cursor: 'pointer' }}>
                    <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Students swapped</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: '32px', color: Color.primary1 }}>{[null, undefined].includes(waitingSwapped[1]?.swapped_orders) ? 'N/A' : waitingSwapped[1]?.swapped_orders}</Typography>
                </Box>
            </Box>
            {swappedModal.open && <StudentsSwapModal
                open={swappedModal.open}
                onClose={swappedModal.onClose}
                extraData={swappedModal.extraData}
            />}
        </Box>
    )
}



export function MentorStatsListView({ mentorStatList }) {
    return (
        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', p: '16px', justifyContent: 'flex-start', alignContent: "flex-start", gap: '16px' }}>
            <Typography style={{ textAlign: 'left', fontSize: '16px', fontWeight: 600, width: '100%' }}>Mentor schedule summary</Typography>
            <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: 'rgba(250, 254, 249, 1)', height: '62px' }}>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Mentor</Typography>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Specialization</Typography>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Total interviews</Typography>
                <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%' }}>Total scheduled</Typography>
            </Box>
            <Box sx={{ width: '100%', marginLeft: "12px" }}>
                {mentorStatList.map((mentor, index) =>
                    <Box sx={{ ...DivCenter, justifyContent: 'space-evenly', width: '100%', background: index % 2 ? 'rgba(250, 254, 249, 1)' : '', height: '62px' }}>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{mentor.mentor_name}</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{JSON.parse(mentor.spe_name).map((spe) => spe + ', ')}</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.neutralMidGrey }}>{mentor.total_interview} (<span style={{ color: Color.red }}>{mentor.pending_interviews}</span>)</Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: 500, width: '100%', color: Color.primary1 }}>{mentor.scheduled_interviews}</Typography>
                    </Box>)}

            </Box>
        </Box>)
}