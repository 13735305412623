import axios from "axios"
import Cookies from "js-cookie";

const api = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}`,


   headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*"
   }
})

const file = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}`,
   headers: {
      "Content-Type": "multipart/form-data"
   }
})


class CCService {
   post = async (endpoint, data) => {
      let jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.post(endpoint, data);

      return res;
   }

   get = async (endpoint) => {
      console.log(endpoint)
      let jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.get(endpoint);
      return res.data;
   }

   patch = async (endpoint, data) => {
      let res = await api.patch(endpoint, data)
      return res.data;
   }

   delete = async (endpoint, data) => {
      let jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.delete(endpoint, data);
      return res.data;
   }
   put = async (endpoint, data) => {
      let jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.put(endpoint, data);
      return res;
   }
   postFile = async (endpoint, data) => {
      let res = await file.post(endpoint, data)
      return res;
   }
}

export default CCService;