import React from "react";
function ExtraCurricular(props) {
const extracurricularData =[
    {
        "basic_info": {
            "tag": "Extra curricular basic info"
        },
        "sub_section_detail": {
            "sub_section_id": 681,
            "sub_section_order": 1,
            "is_on": 1
        },
        "bullets": [
            {
                "bullet_id": 1233,
                "bullet_content": "Extra curricular details",
                "achievement_year": "Year",
                "bullet_order": 1
            }
        ]
    }
]
console.log("extracurricularData",extracurricularData);
let Details=[]
if ((extracurricularData && extracurricularData?.length > 0)) {
    extracurricularData?.map((value,index)=>
    Details.push({
    Role: (
        <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
            {value?.basic_info? value.basic_info.tag:""}
        </p>
    ),
    Desc:(
    <p id="resumeTable-p" style={{ textAlign: "left", fontFamily: props.fontS,fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize }}>
        <ul className="resumeTable-ul"
                            style={{
                                marginLeft: "17px",
                                listStyleType: props.Bullet ? "square" : "",
                                lineHeight: props.LineHeight,
                            }}
                        >
                        {
                            extracurricularData[index]?.bullets.map((bullet)=>{
                            return bullet.bullet_content?.length>0?
                            <li style={{ listStyleType: bullet.bullet_content?.length>0 ? "" : 'none', fontFamily: props.fontS }}>
                                {bullet?.bullet_content}{bullet.achievement_year ? ", " + bullet?.achievement_year : ""}
                            </li>:<></>
                            })
                        }
                        </ul>
    </p>
    )
    }))
}
else{
    Details = [
        {
            Role: (
                <p id="resumeTable-p" style={{ textAlign: "centre", fontFamily: props.fontS }}>
                    {/* Aestimatus 7.0 */}
                    </p>
            ),
            Desc: (
                <p classname="resumeTable-p" style={{ textAlign: "justify" }}>
                    <ul className="resumeTable-ul"
                        style={{
                            marginLeft: "17px",
                            listStyleType: props.Bullet ? "square" : "",
                            lineHeight: props.LineHeight,
                            LineHeight: props.miniLheight
                        }}
                    >
                        <li style={{ fontFamily: props.fontS,listStyleType:"none" }}>
                            {/* 2nd Runner-up / 175+ Teams in Finance Case Competition for valuing B2B E-Commerce Firm @ IIM Kashipur */}
                        </li>

                    </ul>
                </p>
            ),
        },
    ];
}
    return (
        <div>
            <table
                className="IntershipBox overlapping-table ResumeTable"
                style={{
                    width: props.TableWidth ? "105%" : "100%",
                    width: props.minwidth ? props.minwidth : props.TableWidth,
                }}
            // style={{ width: props.widths+"px" }}
            >
                <tr>
                    <th className="Resumetable-th"
                        colspan="6"
                        id="Topic"
                        // style={{
                        //   backgroundColor: isActive ? "salmon" : "",
                        //   color: isActive ? "white" : "",
                        // }}
                        style={{
                            backgroundColor: props.ribbonColor ? props.ribbonColor["BackgroundColor"] : "#d9d9d9",
                            color: props.ribbonColor ? props.ribbonColor["Color"] : "black",
                            // fontSize: props.FontSmall,
                            fontSize: props.FontSize,
                            fontFamily: props.fontS,
                            // border:props.styleAchivements?"none":"",
                            textAlign: props.headerPosition,
                            // borderTop: "none",
                            height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding
                            // fontFamily: props.FontFamily ? "TimesNewRoman" : "Arial",
                            // fontFamily: props.Arial,
                        }}
                    >
                        {/* Achivements */}
                        {props.name ? props.name : 'LeadershipPosition'}
                    </th>
                </tr>

                {Details.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td className="ResumeTable-td"
                                style={{
                                    // fontFamily: props.FontFamily ? "" : "Arial",
                                    // fontFamily: props.Arial,
                                    fontFamily: props.fontS,
                                    width: "16%",
                                    // fontSize: props.FontSmall,
                                    fontSize: props.FontSize,
                                    border: "solid 0.25px #000",
                                    height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding,
                                    maxWidth:props.minimaxWidth?props.minimaxWidth:""
                                }}
                            >
                                {val.Role}
                            </td>
                            <td className="ResumeTable-td"
                                colSpan={4}
                                style={{
                                    // fontSize: props.FontSmall,
                                    fontSize: props.FontSize,
                                    fontFamily: props.fontS,
                                    border: "solid 0.25px #000",
                                    height: props.miniheight, fontSize: props.miniFontSize ? props.miniFontSize : props.FontSize, padding: props.minipadding
                                    // fontFamily: props.FontFamily ? "" : "Arial",
                                    // fontFamily: props.Arial,
                                }}
                            >
                                {val.Desc}
                            </td>
                        </tr>
                    );
                })}
            </table>
            {/* <button onClick={handleClick}>Color</button> */}
        </div>
    );
}

export default ExtraCurricular;
