import React, { useRef, useState, useEffect, useContext } from "react";
import CloseIcon from '@mui/icons-material/Close';
import "./ModalConfirmationStyle.css"
import Modal from 'react-bootstrap/Modal';
// import { RowsContext } from "../../pages/UserType/UserType";
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'



export default function ModalConfirmation(props) {
    // const [formData, setFormData] = useState({})
    const rowsData = useContext(RowsContext);
    const { imageFiles, setImageFiles, setImageName, setUpdatedImage, updatedImage, setDeleteFile, deleteFile, user_type, formData, modalConfirmationShow, setModalConfirmationShow, from_table, action, path, fileType, fileIndex, mentorFiles, studentFiles } = props

    console.log("propssss", props);
    console.log("data getter", props.data_getter);


    const handleOk = () => {
        if (action === "delete") {
            // setDeleteFile(true)
            console.log("delete file")
            props.data_getter(formData.act_type_name, formData.id, formData.type, formData.description, formData.index, mentorFiles, studentFiles, "delete", user_type, fileType, fileIndex, imageFiles);
            let filesArray = [...deleteFile[fileType]];
            filesArray = filesArray.filter((file, index) => (index !== parseInt(fileIndex)))
            setDeleteFile({ ...deleteFile, [fileType]: filesArray });
            console.log("filesa arrat", filesArray, fileType, fileIndex);
        }

        else if (action === "imageDelete") {
            props.data_getter(formData.act_type_name, formData.id, formData.type, formData.description, formData.index, mentorFiles, studentFiles, "imageDelete", user_type, fileType, fileIndex, imageFiles);
            setImageFiles(null);
            setImageName('');
            setUpdatedImage(null)
        }
        // else if (action === "delete" && user_type === "student"){
        //     props.data_getter(formData.id, formData.act_type_name, formData.type, formData.description, formData.index, {}, {}, "delete", "student", fileType, fileIndex);
        // }
        else if (props.is_delete_all) {
            // props.all_ids.map((item,index) => {
            console.log(" bruhhuuhuh")
            console.log(window.location.pathname)
            window.location.pathname === "/ActivityType" ? props.data_getter(props.all_ids, "delete_mul", props.all_index) : props.data_getter("delete_mul", props.all_ids, props.all_index)
            // })
        }

        else {
            if (path === "/JD" || path === "/Durations" || path === "/CollegeData" || path === "/StudentData" || path === "/SKU" || path === "/Bundle" || path === "/Coupon" || path === "/DefaultJD") {
                console.log("this delete is ")
                props.data_getter("delete_mul", [from_table?.id], from_table?.index)
            }
            else {
                console.log('EHEHHEHEH')
                props.data_getter(undefined, from_table?.id, from_table?.index)
            }
        }
        setModalConfirmationShow(false)
        props.setIs_delete_all(false)
        props.setSelected([])
        props.setDeleteAllState(false)

    }

    function HandleEditUser(props) {
        return (
            <Modal
                className="myprofile_modal"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)" }}

            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '50%',
                    height: 'auto',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '603px',
                    minWidth: '400px',
                    minHeight: '200px',
                    border: "2px solid black"
                }}>
                    <form style={{ display: 'flex', flexDirection: 'col', justifyContent: 'space-between' }}>
                        <div className='row'>
                            <div className="headerPart" >
                                <CloseIcon className="closeIcon" onClick={((e) => { setModalConfirmationShow(false); props.setIs_delete_all(false) })} />
                                <p className="DeleteConfirm">Delete Confirmation</p>
                            </div>
                            <div className="form-group msgContain">
                                <p className="message">Are you sure you want to delete this item?</p>
                            </div>
                            <div className="form-group btns">
                                <button type="button" className="btn Btn" onClick={() => { setModalConfirmationShow(false); props.setIs_delete_all(false) }}>Cancel</button>
                                <button type="button" className="btn Btn" onClick={(e) => handleOk(e)}>Ok</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        )
    }

    return (
        <div style={{ margin: 'auto', position: 'absolute', marginLeft: '12%', marginTop: '8%' }}>
            <HandleEditUser
                show={modalConfirmationShow}
                onHide={() => { setModalConfirmationShow(false); props.setIs_delete_all(false) }}

            />
        </div>
    )
}


