import React, { useEffect, useState } from "react";
import MajorsAndMinorsModal from '../../College/ProcessData/MajorsAndMinorsModal';
import CCService from "../../../services/httpServiceWithoutSuperAdmin.js";
import ServiceCM from '../../../services/cartmoduleServices';
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { Color, DivCenter, PrimaryButton } from "../../../GlobalStyles";
import { Button, Modal as MUIModal, Typography } from "@mui/material";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import EditIcon from '@mui/icons-material/Edit';
const PlaceOSettings = (props) => {
    const { batch_id, batch_name, college_name } = props
    const [allowPlaceO, setAllowPlaceO] = useState({
        placeO: false,
        placeOVerification: false,
        modal: false,
        modalClose: () => setAllowPlaceO((prev) => ({ ...prev, modal: false })),
        modalBody: "",
        modalConfirmButton: "",
        is_main: true
    });
    const [majorsAndMinors, setMajorsAndMinors] = useState([{
        "id": null,
        "major": "",
        "minor": "",
        "is_delete": false
    }])
    const ccservice = new CCService();
    const serviceCM = new ServiceCM()
    const [majorsAndMinorsModalOpen, setMajorsAndMinorsModalOpen] = useState(false)
    const handlePlaceOCheckbox = ({ target }) => {
        const { checked } = target;
        if (checked) {
            setAllowPlaceO((prev) => ({
                ...prev,
                modalBody: 'Do you want to allow PlaceO for  this Batch?',
                modalConfirmButton: 'Allow',
                // placeOVerification: checked === false ? false : prev.placeOVerification,
                modal: true,
                is_main: true
            }));
        } else {
            setAllowPlaceO((prev) => ({
                ...prev,
                modalBody: 'Do you want to restrict PlaceO for  this Batch?',
                modalConfirmButton: 'Restrict',
                // placeOVerification: checked === false ? false : prev.placeOVerification,
                modal: true,
                is_main: true
            }));
        }
    }

    useEffect(() => {
		const handleEffect = async () => {;
			const temp_major_and_minor = await majorAndMinorGetter()
			console.log("temp_major_and_minor", temp_major_and_minor);
			temp_major_and_minor.forEach((item) => {
				item.is_delete = false
			})
			setMajorsAndMinors(temp_major_and_minor)

			//placement ops
			const fetched_placeO_allow = await ccservice.get(`/po/ca/poBatchMapping?ug_id=${batch_id}`);
			const { data } = fetched_placeO_allow;
			if (data.length) setAllowPlaceO((prev) => ({ ...prev, placeO: data[0].po_allowed ? true : false, placeOVerification: data[0].profile_verification ? true : false }))
		}

		handleEffect();
	}, [])

    //allow PlacementOps (PlaceO)
    const handlePlaceOVerificationCheckbox = ({ target }) => {
        const { checked } = target;
        if (checked) {
            setAllowPlaceO((prev) => ({
                ...prev,
                // placeOVerification: false,
                modalBody: 'Do you want to allow PlaceO Verification for  this Batch?',
                modalConfirmButton: 'Allow',
                modal: true,
                is_main: false
            }));
        } else {
            setAllowPlaceO((prev) => ({
                ...prev,
                // placeOVerification: false,
                modalBody: 'Do you want to restrict PlaceO Verification for  this Batch?',
                modalConfirmButton: 'Restrict',
                modal: true,
                is_main: false
            }));
        }
    }

    const handleCloseApplicationsModalClose = (type) => {
        setMajorsAndMinorsModalOpen(false)
        if (type === "Cancel") {
            let temp_post_data = majorsAndMinors.filter((item) => item.hasOwnProperty('id'))
            setMajorsAndMinors(temp_post_data)
        }

    }

    // handleMultiDelete()

    const handlePostValues = async () => {
        let temp_post_data = majorsAndMinors.filter((item) => !item.hasOwnProperty('id') && !item.is_delete)
        temp_post_data.forEach((item) => {
            delete item.is_delete
        })
        console.log("temp_post_data", temp_post_data);
        if (temp_post_data.length > 0) {
            try {
                await ccservice
                    .post(`/po/ca/poMajorMinor`, {
                        ug_id: batch_id,
                        major_minor_data: temp_post_data,
                    })
                    .then(res => {
                        console.log("response", res);
                        setMajorsAndMinors(prevState => prevState.map(item => {
                            if (!item.hasOwnProperty('id') && !item.is_delete) {
                                return { ...item, id: Date.now() }; // Assign a temporary id
                            }
                            return item;
                        }));
                        handleCloseApplicationsModalClose("Confirm");
                    });
            } catch (err) {
                console.log(err);
                window.alert(err.response.data.message)
            }
        }
        handleCloseApplicationsModalClose("Confirm");
    }
    const handleCloseApplications = async () => {
        handleMultiDelete()

    }

    const handleMultiDelete = async () => {
        // First, remove items marked for deletion
        const itemsToDelete = majorsAndMinors.filter(item => item.is_delete && item.hasOwnProperty('id')).map(item => item.id);

        if (itemsToDelete.length > 0) {
            try {
                await ccservice
                    .delete(`/po/ca/poMajorMinor?ids=[${itemsToDelete.join(',')}]`)
                    .then(res => {
                        console.log("response", res);
                        setMajorsAndMinors(prevState =>
                            prevState.filter(item => !itemsToDelete.includes(item.id))
                        );
                    });
            } catch (err) {
                console.log(err);
            }
        }
        setMajorsAndMinors(prevState => prevState.filter(item => !item.is_delete));
        handlePostValues()


    };




    function PlaceOConfirmationModal() {
        const placeOmodalStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 350,
            height: 200,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
        }

        //functions
        const handleConfirmModal = async () => {
            if (allowPlaceO.is_main) {
                if (allowPlaceO.placeO) {
                    try {
                        await ccservice.post(`/po/ca/poBatchMapping`, {
                            "ug_id": batch_id,
                            "po_allowed": 0,
                            "profile_verification": 0
                        });
                        setAllowPlaceO((prev) => ({ ...prev, placeO: !prev.placeO, modal: false }));
                    } catch (error) {
                        console.error("Error posting placeO allow", error);
                        window.alert(error.response.data.message)
                    }
                } else {
                    try {
                        await ccservice.post(`/po/ca/poBatchMapping`, {
                            "ug_id": batch_id,
                            "po_allowed": 1,
                            "profile_verification": allowPlaceO.placeOVerification ? 1 : 0
                        });
                        setAllowPlaceO((prev) => ({ ...prev, placeO: !prev.placeO, modal: false }));
                    } catch (error) {
                        console.error("Error posting placeO allow", error);
                        window.alert(error.response.data.message)
                    }
                }
            } else {
                try {
                    await ccservice.post(`/po/ca/poBatchMapping`, {
                        "ug_id": batch_id,
                        "po_allowed": 1,
                        "profile_verification": allowPlaceO.modalConfirmButton === 'Restrict' ? 0 : 1
                    });
                    setAllowPlaceO((prev) => ({ ...prev, placeOVerification: !prev.placeOVerification, modal: false }));
                } catch (error) {
                    console.error("Error posting placeO allow", error);
                    window.alert(error.response.data.message)
                }
            }
        }

        const handleModalCancel = () => {
            if (allowPlaceO.is_main) {
                setAllowPlaceO((prev) => ({ ...prev, placeO: prev.placeO }));
            } else {
                setAllowPlaceO((prev) => ({ ...prev, placeOVerification: prev.placeOVerification }));
            }
            allowPlaceO.modalClose();
        }


        return (
            <MUIModal open={allowPlaceO.modal}
                onClose={allowPlaceO.modalClose}
                // aria-labelledby="modal-modal-title"
                // aria-describedby="modal-modal-description"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description">
                <Box sx={{ ...placeOmodalStyle }}>
                    {/* {!allowPlaceO.placeO ? */}
                    <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'space-between', gap: 5 }}>
                        <Typography style={{ fontSize: '20px' }}>{allowPlaceO.modalBody}</Typography>
                        <Box sx={{ ...DivCenter, gap: '10px' }}>
                            <Button variant="text"
                                onClick={() => handleModalCancel()}
                                sx={{ width: "70%", height: '44px', alignItems: 'right', textTransform: 'none', backgroundColor: 'white', color: Color.primary1 }}>Cancel</Button>
                            <PrimaryButton variant="contained" sx={{ width: "70%", height: '44px', alignItems: 'right', textTransform: 'none', }}
                                onClick={(e) => handleConfirmModal(e)}
                            >{allowPlaceO.modalConfirmButton}</PrimaryButton>
                        </Box>
                    </Box>
                </Box>
            </MUIModal>
        );
    }


    const majorAndMinorGetter = async () => {
        try {
            const res = await ccservice.get(`/po/ca/poMajorMinor?ug_id=${batch_id}`)
            console.log("JD RESPONSE:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }


    const handleEditMajorAndMinor = async () => {
        setMajorsAndMinorsModalOpen(true)
        const temp_major_and_minor = await majorAndMinorGetter()
        temp_major_and_minor.forEach((item) => {
            item.is_delete = false
        })
        console.log("temp_major_and_minor", temp_major_and_minor);
        setMajorsAndMinors(temp_major_and_minor)
    }

    const handleDeleteSpecialization = (index) => {
        setMajorsAndMinors(prevState =>
            prevState.map((item, i) =>
                i === index ? { ...item, is_delete: true } : item
            )
        );
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ DivCenter, justifyContent: 'flex-start', userSelect: 'none' }}>
                <Typography style={{}} sx={{ fontSize: 20, fontWeight: "medium", marginTop: ".5rem" }}>PlaceO Settings </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start', width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allowPlaceO.placeO}
                                    onChange={(e) => handlePlaceOCheckbox(e)}
                                    sx={{
                                        color: "orangered",
                                        '&.Mui-checked': {
                                            color: "orangered",
                                        },
                                        transition: 'black 0.3s ease', // Add transition property for color change
                                    }}
                                />
                            }
                            label={<span style={{ color: Color.neutralBlack }}>Switch on PlaceO</span>}
                        />
                    </Box>
                    {allowPlaceO.placeO ? (
                        <Box sx={{ display: "flex", justifyContent: 'flex-start', ml: 5, opacity: 1, transition: 'opacity 0.5s ease', flexDirection: "column" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={allowPlaceO.placeOVerification}
                                        onChange={(e) => handlePlaceOVerificationCheckbox(e)}
                                        sx={{
                                            color: "orangered",
                                            '&.Mui-checked': {
                                                color: "orangered",
                                            },
                                        }}
                                    />}
                                label={<span style={{ color: Color.neutralBlack }}>Switch on PlaceO verification</span>}
                            />
                            <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                                <WidgetsOutlinedIcon style={{ color: 'orangered', margin: "auto 0" }} />
                                <Typography style={{ color: Color.neutralBlack, margin: "auto 0" }}>Student Specializations:</Typography>
                                {majorsAndMinors.length > 0 ? <>
                                    {majorsAndMinors.filter(item => item.id).map((skills_item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                textAlign: "left",
                                                height: "30px",
                                                paddingTop: ".5rem",
                                                paddingBottom: ".5rem",
                                                borderBottom: `1px solid ${Color.neutralLightGrey}`,
                                                background: "#E6E6E6",
                                                width: "auto",
                                                paddingLeft: '1em',
                                                paddingRight: "1rem",
                                                borderRadius: "30px",
                                                display: "flex",

                                                alignItems: "center", // Align items vertically in the center
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    width: "auto",
                                                    color: Color.neutralMidGrey,
                                                }}
                                            >
                                                {skills_item.major}
                                            </Typography>
                                        </Box>
                                    ))}

                                    <Box onClick={() => handleEditMajorAndMinor()} sx={{ display: "flex", flexDirection: "row", margin: "auto 0", cursor: "pointer", gap: ".5rem" }}>
                                        <Typography>Edit </Typography>
                                        <EditIcon sx={{ width: "16px", height: "16px", marginTop: "2px" }} />
                                    </Box>
                                </> : <>
                                    <Box onClick={() => handleEditMajorAndMinor()} sx={{ display: "flex", flexDirection: "row", margin: "auto 0", cursor: "pointer", gap: ".5rem" }}>
                                        <Typography>Edit </Typography>
                                        <EditIcon sx={{ width: "16px", height: "16px", marginTop: "2px" }} />
                                    </Box>
                                </>}
                                <Box>

                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%', ml: 5, opacity: 0, transition: 'opacity 0.5s ease' }}>
                            <FormControlLabel
                                control={<Checkbox disabled />}
                                label={<span style={{ color: Color.neutralBlack }}>Switch on PlaceO verification</span>}
                            />
                        </Box>
                    )}
                </Box>
                {PlaceOConfirmationModal()}
            </Box>
            <MajorsAndMinorsModal
                modalOpen={majorsAndMinorsModalOpen}
                majorsAndMinors={majorsAndMinors}
                setMajorsAndMinors={setMajorsAndMinors}
                handleModalClose={handleCloseApplicationsModalClose}
                batch_id={batch_id}
                college_name={college_name}
                batch_name={batch_name}
                handleCloseApplications={handleCloseApplications}
                handleDeleteSpecialization={handleDeleteSpecialization}
            />
        </div>
    )
}

export default PlaceOSettings
