import axios from "axios";
import React, { useEffect, useState } from "react";
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
//modal
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Modal from "react-bootstrap/Modal";
import { Button, IconButton, Modal as MUIModal } from "@mui/material";
import CollegeData from '../../../CollegeDataServices/httpCollege'
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import Service from '../../../services/httpService';
import ServiceCM from '../../../services/cartmoduleServices';
import Switch from '@mui/material/Switch';
import { saveAs } from "file-saver";
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import Loader, { SmallPageLoader } from "../../reusables/Loader/Loader";
import { antistringify as JsonParse } from '../../../functions/antistringify.js'
import { useNavigate } from 'react-router-dom';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import Cookies from 'js-cookie';
import { Color, DivCenter, PrimaryButton } from "../../../GlobalStyles";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../../services/specialCharReplace.js";
import { Box, Checkbox, FormControlLabel, Autocomplete, Chip, TextField } from "@mui/material";
import { CheckBox, Close, DateRange } from "@mui/icons-material";
import { Typography } from "antd";
import CCService from "../../../services/httpServiceWithoutSuperAdmin.js";
import { DeadlineTimeCalendarModal } from "../../Reusable_functions/DeadlineTimeCalendarModal/DeadlineTimeCalendarModal.js";
import GridViewIcon from '@mui/icons-material/GridView';
import MajorsAndMinorsModal from "./MajorsAndMinorsModal.js";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import EditIcon from '@mui/icons-material/Edit';
// import { display } from "html2canvas/dist/types/css/property-descriptors/display.js";
const UserType = () => {
	const location = useLocation();

	const [loading, setLoading] = useState(true);

	const collegeData = new CollegeData()
	const service = new Service();
	const ccservice = new CCService();
	const serviceCM = new ServiceCM()
	const navigate = useNavigate();
	const batch_id = location.state?.batch_id
	const batch_name = location.state?.batch_name || ""
	const college_name = location.state?.college_name || ""

	// batch data
	const [BatchData, setBatchData] = useState([])

	const [rowsData, setRowsData] = useState([{ id: null, user_group_id: null, activity_type_id: null, duration_name: "", is_deleted: 0 }])
	console.log("rowsData:", rowsData)

	//from table index
	const [from_table, setFrom_table] = useState();

	// getter of batch and program
	const [programData, setProgramData] = useState([])
	const [skuData, setSKUData] = useState([])
	const [processData, setProcessData] = useState([])
	const [majorsAndMinors, setMajorsAndMinors] = useState([{
		"id": null,
		"major": "",
		"minor": "",
		"is_delete": false
	}])
	const [itemsToDelete, setItemsToDelete] = useState([]);
	// ids of batch and program
	const [jd_select_id, setJd_select_id] = useState();
	const [selectSkuId, setSelectSkuId] = useState({ id: null, name: "" });
	const [formInputs, setFormInputs] = useState({ start_date: null, end_date: null, recording: 1, timeZone: 'Asia/Kolkata', is_offline: 0 })
	const [jd_program_id, setJd_program_id] = useState();

	const [jd_input_name, setJd_input_name] = useState(processData[from_table?.index]?.user_group_name);

	const [majorsAndMinorsModalOpen, setMajorsAndMinorsModalOpen] = useState(false)
	//get current date and time
	const currentDate = new Date();

	// const [count, setCount] = useState()
	let count = 0;

	//activity
	const [activity, setActivity] = useState("");

	// toggle switch 
	const [is_toggled, setIs_toggled] = useState(1);
	const [isOffline, setIsOffline] = useState(0);

	//PlacementOps
	const [allowPlaceO, setAllowPlaceO] = useState({
		placeO: false,
		placeOVerification: false,
		modal: false,
		modalClose: () => setAllowPlaceO((prev) => ({ ...prev, modal: false })),
		modalBody: "",
		modalConfirmButton: "",
		is_main: true
	});

	// console.log("UserType ~ allowPlaceO:", allowPlaceO)

	//set deadline modal
	const [deadlineModalData, setDeadlineModalData] = useState({
		open: false,
		onClose: () => setDeadlineModalData((prev) => ({ ...prev, open: false })),
		value: null,
		timeCalendarmodal: {
			open: false,
			onClose: () => setDeadlineModalData((prev) => ({ ...prev, timeCalendarmodal: { ...prev.timeCalendarmodal, open: false } }))
		},
		extraData: {
			process_id: null
		}
	})
	const headCells = [
		{
			id: 'heading1',
			numeric: 'right',
			disablePadding: false,
			label: 'SKU Name',
		},
		{
			id: 'heading2',
			numeric: 'right',
			disablePadding: false,
			label: 'Start Date',
		},
		{
			id: 'heading3',
			numeric: 'right',
			disablePadding: false,
			label: 'End Date',
		},

	];

	const handleDeleteMul = (id, index) => {
		console.log("delete", id)
		let list_processData = [...processData];
		let list_rowsData = [...rowsData];

		console.log("listtttt1", list_processData);
		console.log("listtttt2", list_rowsData);


		collegeData
			.delete("/process", {
				data: { "ids": id }
			})
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list_processData = list_processData.filter((item) => id.indexOf(item.id) < 0);
				setProcessData(list_processData)
				list_rowsData = list_rowsData.filter((item) => id.indexOf(item.id) < 0);
				setRowsData(list_rowsData)

				console.log("listtttt3", list_processData);
				console.log("listtttt4", list_rowsData);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);
			});
	}
	const data_getter = (name, id, index, act) => {
		// setRowsData(data)
		console.log("Dude here's your name:", name);
		console.log("Dude here's your id:", id);
		console.log("Dude here's your id:", index);
		setActivity(act);
		if (name === "delete_mul") {
			console.log("inside delete mul if")
			handleDeleteMul(id, index)
		}
		else if ((name === undefined || name === null) && (id !== undefined || id !== null || id !== 0)) {
			handleDelete(id, index);
		}

		if (act === "add") {
			setIs_toggled(1)
			setSelectSkuId()
			setJd_program_id()
		}

		setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
	}

	// const processDataGetter = async () => {
	// 	try {
	// 		const res = await collegeData.get(`/process`)
	// 		console.log("JD RESPONSE:", res.data)
	// 		// setProcessData(res.data)
	// 		return res.data
	// 	} catch (err) {
	// 		console.log(err)
	// 	}
	// }

	const skuGetter = async () => {

		try {
			const res = await serviceCM.get('sku')
			console.log("Response from backend is11:", res.data)
			setSKUData(res.data.map(rows => ({ id: rows.id, name: rows.name })))
			return res.data.map(rows => ({ id: rows.id, name: rows.name }))
		} catch (err) {
			console.log(err)
		}
	}
	const processDataGetter = async () => {
		try {
			const res = await collegeData.get(`/process/byUserGroup?userGroupId=${batch_id}`)
			console.log("JD RESPONSE:", res.data)
			setProcessData([...res.data])
			setRowsData(res.data)

			return res.data
		} catch (err) {
			console.log(err)
		}
	}
	const majorAndMinorGetter = async () => {
		try {
			const res = await ccservice.get(`/po/ca/poMajorMinor?ug_id=${batch_id}`)
			console.log("JD RESPONSE:", res.data)
			return res.data
		} catch (err) {
			console.log(err)
		}
	}
	console.log("formInputs in process data", formInputs);
	// get request
	useEffect(() => {
		handleEffect();
	}, [])

	const handleEffect = async () => {

		if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
			navigate('/');
		}
		//process data getter
		const temp_processData = await processDataGetter();

			//sku getter 
			const temp_skuData = await skuGetter();

			const temp_major_and_minor = await majorAndMinorGetter()
			console.log("temp_major_and_minor", temp_major_and_minor);
			temp_major_and_minor.forEach((item) => {
				item.is_delete = false
			})
			setMajorsAndMinors(temp_major_and_minor)

		//batch getter 
		// const temp_batchData = await batchDataGetter();
		const timeZones = Intl.DateTimeFormat().resolvedOptions().timeZone;
		console.log(timeZones);

		// forming the data
		console.log("odonil", { temp_skuData }, { temp_processData }, { timeZones })
		makingRowsData(temp_processData, temp_skuData);

			//placement ops
			const fetched_placeO_allow = await ccservice.get(`/po/ca/poBatchMapping?ug_id=${batch_id}`);
			const { data } = fetched_placeO_allow;
			if (data.length) setAllowPlaceO((prev) => ({ ...prev, placeO: data[0].po_allowed ? true : false, placeOVerification: data[0].profile_verification ? true : false }))
			setLoading(false);
		}
	

	useEffect(() => {
		const setting_data = () => {
			if (from_table?.index === undefined) {
				console.log("Inside if of useEffect")
				setSelectSkuId()
				setFormInputs({ ...formInputs,  start_date: null, end_date: null, recording: 1, timeZone: "Asia/Kolkata",  is_offline: 0 })
			}
			else {
				setSelectSkuId(processData[from_table?.index]?.sku_id)
				setFormInputs({ ...formInputs, start_date: extractDateFromDateString(processData[from_table?.index]?.start_date), end_date: extractDateFromDateString(processData[from_table?.index]?.end_date), recording: processData[from_table?.index]?.recording })
				console.log("what is inside", formInputs);

				setIs_toggled(processData[from_table?.index]?.recording)
			}
		}
		setting_data();
	}, [from_table])

	const makingRowsData = (process, sku) => {
		let temp_process = JSON.parse(JSON.stringify(process))
		let temp_sku = JSON.parse(JSON.stringify(sku))
		for (let i = 0; i < temp_process.length; i++) {
			let matchFound = false;
			for (let j = 0; j < temp_sku.length; j++) {
				if (temp_process[i].sku_id === temp_sku[j].id) {
					temp_process[i].sku_name = temp_sku[j].name;
					matchFound = true;
					break;
				}
			}
			if (!matchFound) {
				temp_process[i].sku_name = null;
			}
		}

		//correcting the arrangement of keys in the json in the array
		temp_process = temp_process.map(obj => ({
			...obj,
			id: obj.id,
			sku_id: obj.sku_id,
			sku_name: obj.sku_name,
			start_date: obj.start_date,
			end_date: obj.end_date,
			user_group_id: obj.user_group_id,
			type: obj.type,
			recording: obj.recording
		}))
		setRowsData(temp_process)
	}


	//MODAL
	const Modal_Process = (show, setShow, mode) => {


		const modalSubmit = async () => {
			console.log("Just submitted:", jd_input_name, jd_select_id, selectSkuId, jd_program_id, activity)
			let temp_jdData
			if (activity === "add")
				temp_jdData = await handleFormSubmission(batch_id, jd_program_id, selectSkuId);
			else
				temp_jdData = await handlePutRequest(processData[from_table.index].id, batch_id, jd_program_id, selectSkuId);
			console.log({ temp_jdData })
			onHide()
		}

		const onHide = () => {
			setJd_input_name()
			setSelectSkuId({ id: null, name: "" })
			setFormInputs({...formInputs, start_date: null, end_date: null })
			setShow(false)
		}


		const handleChange = (e, index) => {
			console.log("name from form  is:", e.target.name);
			console.log("value from form  is:", e.target.value);

			if (e.target.name === "sku-select") {
				setSelectSkuId(e.target.value)
				console.log("batch selected id", e.target.value)
			}
			else if (e.target.name === "start_date") {
				setFormInputs({ ...formInputs, start_date: e.target.value })
				console.log("process start date", e.target.value)
			}
			else if (e.target.name === "end_date") {
				setFormInputs({ ...formInputs, end_date: e.target.value })
				console.log("process end date", e.target.value)
			} else if (e.target.name === "timezone_offset") {
				setFormInputs({ ...formInputs, timeZone: e.target.value })
				console.log("process end date", e.target.value)
			}

		}

		const handleToggle = () => {
			console.log("toggle state currently", is_toggled);
			const temp_toggle_state = !is_toggled
			setIs_toggled(temp_toggle_state)
			setFormInputs({ ...formInputs, recording: temp_toggle_state === true ? 1 : 0 })
		}

		const handleToggleOffline = (e) => {
			console.log("event is offline:", e, e.target.checked);
			console.log("toggle state currently", isOffline);
			const temp_toggle_state = e.target.checked;
			setIsOffline(temp_toggle_state);
			setFormInputs({ ...formInputs, is_offline: temp_toggle_state === true ? 1 : 0 })
		}

		console.log("toggle state currently", is_toggled);
		console.log("formInputs...", formInputs);



		console.log({ formInputs });

		//render
		return (
			<Modal
				show={show}
				onHide={() => onHide()}
				className="FileUploadModal"
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ backdropFilter: "blur(5px)", minWidth: "80%", maxWidth: "95%", minHeight: "80%", maxHeight: "95%" }}
			>
				<Modal.Body style={{
					position: 'absolute',
					left: '60%',
					height: 'auto',
					transform: 'translate(-50%, -50%)',
					lineHeight: '1.4',
					padding: '14px 28px',
					backgroundColor: '#fff',
					borderRadius: '10px',
					maxWidth: '95%',
					minWidth: '500px',
					minHeight: '400px'
				}}>
					<form className="Entire-form" style={{ justifyContent: 'center' }}>

						{/* right side components */}

						<div className='row full-div' >
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
								<CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
								<p className="compTitle" style={{ fontSize: "32px", textAlign: "center", background: 'transparent' }}>Batch/User Group</p>
							</div>

							<div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
							{!isOffline && 	<Box>
									<label className="inputLabel" style={{ textAlign: "center", marginTop: "0.5rem", marginRight: "0.5rem" }}>Recording of the sessions</label>
									<FormControlLabel
										control={
											<Switch
												checked={is_toggled}
												onChange={handleToggle}
												// color="#1c8ea8"
												name="toggleSwitch"
												sx={{ color: Color.primary1 }}
											/>
										}
									/>
								</Box> }
								<Box>
									<label className="inputLabel" style={{ textAlign: "center", marginTop: "0.5rem", marginRight: "0.5rem" }}>Offline</label>
									<FormControlLabel
										control={
											<Switch
												checked={isOffline}
												onChange={handleToggleOffline}
												name="isOfflineToggle"
												sx={{ color: Color.primary1 }}
											/>
										}
									/>
								</Box>
							</div>
							<div className="form-group ">
								<label className="inputLabel">SKUs<span>*</span></label>
								<select name="sku-select" disabled={mode === "View" ? true : false} id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={selectSkuId}>
									<option className="options-value" value={""} selected>Select SKUs</option>
									{skuData?.map((row, i) => {
										console.log("cmon", row)
										return <option key={i} value={row.id} >{row.name}</option>
									})}
								</select>
							</div>
							{!isOffline && <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
								<div className="form-group" style={{ width: "50%" }}>
									<label className="inputLabel">Start Date<span>*</span></label>
									<input name="start_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.start_date} onChange={(e) => handleChange(e)} />
								</div>
								<div className="form-group" style={{ width: "50%" }}>
									<label className="inputLabel">End Date<span>*</span></label>
									<input name="end_date" disabled={mode === "View" ? true : false} type="date" className="form-control" placeholder="Example input" value={formInputs.end_date} onChange={(e) => handleChange(e)} />
								</div>
							</div>}
							<div className="form-group" style={{ width: "100%" }}>
								<label className="inputLabel">Timezone</label>
								<select name="timezone_offset" id="timezone-offset" className="form-control" onChange={(e) => handleChange(e)}>
									<option value="America/Los_Angeles">America/Los_Angeles</option>
									<option value="America/Chicago">America/Chicago </option>
									<option value="America/Denver">America/Denver</option>
									<option value="Europe/Paris">Europe/Paris</option>
									<option value="America/Sao_Paulo">America/Sao_Paulo</option>
									<option value="America/New_York">America/New_York</option>
									<option value="America/Anchorage">America/Anchorage</option>
									<option value="America/Phoenix">America/Phoenix</option>
									<option value="Asia/Shanghai">Asia/Shanghai</option>
									<option value="Asia/Dubai">Asia/Dubai</option>
									<option value="Asia/Kolkata" selected="selected">Asia/Kolkata</option>
									<option value="Asia/Singapore">Asia/Singapore</option>
									<option value="Asia/Tokyo">Asia/Tokyo </option>
									<option value="Asia/Tehran">Asia/Tehran</option>
									<option value="Australia/Melbourne">Australia/Melbourne</option>
									<option value="Pacific/Honolulu">Pacific/Honolulu</option>
									<option value="Africa/Johannesburg">Africa/Johannesburg</option>
									<option value="Europe/Istanbul">Europe/Istanbul</option>
									<option value="Europe/Lisbon">Europe/Lisbon </option>
									<option value="Europe/Berlin">Europe/Berlin</option>
								</select>
							</div>
							<div className="form-group ">
								{mode === "View" ? <></> :
									<div className="btn-comp" style={{ marginLeft: "", marginTop: "4%" }}>
										<button type="button" onClick={modalSubmit} className="btn submit-btn">Submit</button>
									</div>}
							</div>

						</div>


					</form>
				</Modal.Body>
			</Modal>
		)
	}

	// function calculateEpochTimeAtMidnight(selectedDate) {
	// 	const [year, month, day] = selectedDate.split('-').map(Number);
	// 	const date = new Date(Date.UTC(year, month - 1, day));
	// 	date.setUTCHours(0, 0, 0, 0);
	// 	const epochTime = date.getTime()
	// 	return epochTime;
	// }

	// function calculateEpochTimeAfter24Hours(selectedDate, timezone) {
	// 	const [year, month, day] = selectedDate.split('-').map(Number);
	// 	const date = new Date(Date.UTC(year, month - 1, day));
	// 	date.setUTCHours(23, 59, 59);
	// 	const epochTime = date.getTime()
	// 	return epochTime;
	// }

	// function calculateEpochTimeAtMidnight(selectedDate, timezone) {
	// 	console.log("selectedDate right now", selectedDate, timezone);
	// 	const [year, month, day] = selectedDate.split('-').map(Number);
	// 	const date = new Date(Date.UTC(year, month - 1, day));
	// 	const localDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
	// 	console.log("localDate", localDate);
	// 	const localOffset = localDate.getTimezoneOffset() * 60 * 1000;

	// 	date.setUTCHours(0, 0, 0, 0);
	// 	const epochTime = date.getTime() + localOffset;
	// 	return epochTime;
	// }

	// function calculateEpochTimeAfter24Hours(selectedDate, timezone) {
	// 	console.log("selectedDate right now", selectedDate, timezone);
	// 	const [year, month, day] = selectedDate.split('-').map(Number);
	// 	const date = new Date(Date.UTC(year, month - 1, day));
	// 	const localDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
	// 	console.log("localDate", localDate);

	// 	const localOffset = localDate.getTimezoneOffset() * 60 * 1000;
	// 	console.log("localOffset",localOffset);
	// 	date.setUTCHours(23, 59, 59);
	// 	const epochTime = date.getTime() + localOffset;
	// 	return epochTime;
	// }

	function getGmtOffsetInEpoch(timezone) {
		const now = new Date();
		const formatter = new Intl.DateTimeFormat('en-US', { timeZone: timezone, hour: 'numeric', minute: 'numeric', second: 'numeric' });
		const parts = formatter.formatToParts();
		console.log("partsss", parts);
		const hours = parts.find(part => part.type === 'hour').value;
		const minutes = parts.find(part => part.type === 'minute').value;

		const offsetInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);

		const offsetInMilliseconds = 1 * offsetInMinutes * 60 * 1000;
		return offsetInMilliseconds;
	}

	function calculateEpochTimeAtMidnight(selectedDate, timezone) {
		console.log("selectedDate right now", selectedDate, timezone);
		const [year, month, day] = selectedDate.split('-').map(Number);
		const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

		const offsetInMilliseconds = getGmtOffsetInEpoch(timezone);
		console.log("offsetInMilliseconds", offsetInMilliseconds);
		const epochTime = date.getTime();
		console.log("get offset in epoch");
		return epochTime;
	}

	function calculateEpochTimeAfter24Hours(selectedDate, timezone) {
		console.log("selectedDate right now", selectedDate, timezone);
		const [year, month, day] = selectedDate.split('-').map(Number);
		const date = new Date(Date.UTC(year, month - 1, day, 23, 59, 59));

		const offsetInMilliseconds = getGmtOffsetInEpoch(timezone);
		console.log("offsetInMilliseconds", offsetInMilliseconds);
		const epochTime = date.getTime()
		return epochTime;
	}

	// post request
	const handleFormSubmission = async (batch_id) => {
		let temp_rows = [...rowsData];
		let temp_processData = JSON.parse(JSON.stringify(processData));
		let data = {}

		if (formInputs.start_date !== null && formInputs.end_date !== null) {
			const result = calculateEpochTimeAtMidnight(formInputs?.start_date, formInputs?.timeZone);
			const resultAfetr24Hours = calculateEpochTimeAfter24Hours(formInputs?.end_date, formInputs?.timeZone);
			data = {
				user_group_id: batch_id,
				sku_id: parseInt(selectSkuId),
				start_date: result,
				end_date: resultAfetr24Hours,
				recording: formInputs.recording,
				is_offline: formInputs.is_offline
			}
		}
		else
			data = {
				user_group_id: batch_id,
				sku_id: parseInt(selectSkuId),
				recording: formInputs.recording,
				is_offline: formInputs.is_offline
			}


		console.log("User Type id not found, sending teh post request", temp_rows);
		await collegeData
			.post("/process", data)
			.then((res) => {
				// const res = collegeData.post("/userGroup", data)
				console.log("Response from backend is", JSON.stringify(res, null, 2));
				console.log(res)
				console.log(res?.data)
				let temp = JSON.parse(JSON.stringify(rowsData));
				
				temp = ([{
					id: res?.data?.id,
					user_group_id: batch_id,
					sku_id: parseInt(selectSkuId),
					sku_name: skuData.find(obj => obj.id === parseInt(selectSkuId)).name,
					start_date: formInputs.start_date,
					end_date: formInputs.end_date,
					recording: formInputs.recording,
					is_offline: formInputs.is_offline 

				}, ...temp])
				console.log(".then ~ temp", skuData.find(obj => obj.id === parseInt(selectSkuId)))
				setRowsData(temp)


				temp_processData = ([{
					id: res?.data?.id,
					user_group_id: batch_id,
					sku_id: parseInt(selectSkuId),
					sku_name: skuData.find(obj => obj.id === parseInt(selectSkuId)).name,
					start_date: formInputs.start_date,
					end_date: formInputs.end_date,
					recording: formInputs.recording,
					is_offline: formInputs.is_offline
				}, ...temp_processData])

				setProcessData(temp_processData)
				console.log("batch data right now", temp_processData);
			})
			.catch((err) => {
				console.log("Errog Msg: ", err);
				window.alert(err.response.data.message)
			});

		return temp_processData

	}

	// function calculateEpochTimeAtMidnight(selectedDate, timezone) {
	// 	const date = new Date(selectedDate);
	// 	const selectedTime = date.toLocaleString();
	// 	const midnight = new Date(selectedTime);
	// 	midnight.setHours(0, 0, 0, 0);
	// 	const epochTime = midnight.getTime() ;
	// 	return epochTime;
	// }



	const handlePutRequest = async (id, batch_id, jd_program_id, selectSkuId) => {
		//get request here
		let temp_processData = JSON.parse(JSON.stringify(processData));
		console.log("temp_processData",temp_processData);
		const result = calculateEpochTimeAtMidnight(formInputs?.start_date, formInputs?.timeZone);
		const resultAfetr24Hours = calculateEpochTimeAfter24Hours(formInputs?.end_date, formInputs?.timeZone);
		console.log('basically the input', formInputs?.timeZone, result, resultAfetr24Hours);
		try {
			const res = collegeData.put("/process", {
				id: id,
				start_date: result,
				end_date: resultAfetr24Hours,
				recording: formInputs.recording,
				is_offline: formInputs.is_offline
			})
			console.log("Response from backend is", JSON.stringify(res, null, 2));

			let temp = JSON.parse(JSON.stringify(rowsData));

			temp[from_table?.index].user_group_id = batch_id
			temp[from_table?.index].sku_id = selectSkuId
			temp[from_table?.index].sku_name = skuData.find(obj => obj.id === parseInt(selectSkuId)).name
			temp[from_table?.index].start_date = formInputs.start_date
			temp[from_table?.index].end_date = formInputs.end_date
			temp[from_table?.index].recording = formInputs.recording
			temp[from_table?.index].is_offline = formInputs.is_offline

			console.log("Matey", temp)
			setRowsData(temp)

			temp_processData[from_table?.index].user_group_id = batch_id
			temp_processData[from_table?.index].sku_id = selectSkuId
			temp_processData[from_table?.index].sku_name = skuData.find(obj => obj.id === parseInt(selectSkuId)).name
			temp_processData[from_table?.index].start_date = formInputs.start_date
			temp_processData[from_table?.index].end_date = formInputs.end_date
			temp_processData[from_table?.index].recording = formInputs.recording
			temp_processData[from_table?.index].is_offline = formInputs.is_offline
			console.log(temp_processData)
			setProcessData(temp_processData)

		} catch (err) {
			console.log("Errog Msg: ", err);
			window.alert(err.response.data.message)
		};
		return temp_processData
	}

	// delete request
	const handleDelete = async (id, index) => {
		console.log("delete", id)
		let list = [...rowsData];

		console.log("listtttt1", list);
		collegeData
			.delete("/process", {
				data: { "ids": [id] }
			})
			.then((res) => {
				console.log("Response from backend is", res);
				res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
				list.splice(index, 1);
				setRowsData(list)
				setBatchData(list)
				console.log("listtttt2", list);

			})
			.catch((err) => {
				console.log("Errog Msg: ", err);

				console.log(err.response.data.message)
				window.alert(err.response.data.message)

			});
	}


	const fileDownload = () => {
		console.log("Downloading the file");
		console.log("Rows data now is:  ", rowsData);
		const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
		saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
	}

	const navigateToResumesSettings = (process_id) => {
		navigate("/ResumeTemplate?process_id=" + process_id, { state: { process_id: process_id, college_name: college_name, batch_name: batch_name, batch_id: batch_id } })
	}


	//allow PlacementOps (PlaceO)
	const handlePlaceOCheckbox = ({ target }) => {
		const { checked } = target;
		if (checked) {
			setAllowPlaceO((prev) => ({
				...prev,
				modalBody: 'Do you want to allow PlaceO for  this Batch?',
				modalConfirmButton: 'Allow',
				// placeOVerification: checked === false ? false : prev.placeOVerification,
				modal: true,
				is_main: true
			}));
		} else {
			setAllowPlaceO((prev) => ({
				...prev,
				modalBody: 'Do you want to restrict PlaceO for  this Batch?',
				modalConfirmButton: 'Restrict',
				// placeOVerification: checked === false ? false : prev.placeOVerification,
				modal: true,
				is_main: true
			}));
		}
	}

	//allow PlacementOps (PlaceO)
	const handlePlaceOVerificationCheckbox = ({ target }) => {
		const { checked } = target;
		if (checked) {
			setAllowPlaceO((prev) => ({
				...prev,
				// placeOVerification: false,
				modalBody: 'Do you want to allow PlaceO Verification for  this Batch?',
				modalConfirmButton: 'Allow',
				modal: true,
				is_main: false
			}));
		} else {
			setAllowPlaceO((prev) => ({
				...prev,
				// placeOVerification: false,
				modalBody: 'Do you want to restrict PlaceO Verification for  this Batch?',
				modalConfirmButton: 'Restrict',
				modal: true,
				is_main: false
			}));
		}
	}

	const handleCloseApplicationsModalClose = (type) => {
		setMajorsAndMinorsModalOpen(false)
		if (type === "Cancel") {
			let temp_post_data = majorsAndMinors.filter((item) => item.hasOwnProperty('id'))
			setMajorsAndMinors(temp_post_data)
		}

	}

	// handleMultiDelete()

	const handlePostValues = async () => {
		let temp_post_data = majorsAndMinors.filter((item) => !item.hasOwnProperty('id') && !item.is_delete)
		temp_post_data.forEach((item) => {
			delete item.is_delete
		})
		console.log("temp_post_data", temp_post_data);
		if (temp_post_data.length > 0) {
			try {
				await ccservice
					.post(`/po/ca/poMajorMinor`, {
						ug_id: batch_id,
						major_minor_data: temp_post_data,
					})
					.then(res => {
						console.log("response", res);
						setMajorsAndMinors(prevState => prevState.map(item => {
                            if (!item.hasOwnProperty('id') && !item.is_delete) {
                                return { ...item, id: Date.now() }; // Assign a temporary id
                            }
                            return item;
                        }));
						handleCloseApplicationsModalClose("Confirm");
					});
			} catch (err) {
				console.log(err);
				window.alert(err.response.data.message)
			}
		}
		handleCloseApplicationsModalClose("Confirm");
	}
	const handleCloseApplications = async () => {
		handleMultiDelete()

	}



	const handleEditMajorAndMinor = async () => {
		setMajorsAndMinorsModalOpen(true)
		const temp_major_and_minor = await majorAndMinorGetter()
		temp_major_and_minor.forEach((item) => {
			item.is_delete = false
		})
		console.log("temp_major_and_minor", temp_major_and_minor);
		setMajorsAndMinors(temp_major_and_minor)
	}

	const handleDeleteSpecialization = (index) => {
		setMajorsAndMinors(prevState =>
			prevState.map((item, i) =>
				i === index ? { ...item, is_delete: true } : item
			)
		);
	};

	// const handleMultiDelete = async () => {
	//     setMajorsAndMinors(prevState =>
	//         prevState.filter(item => !(item.hasOwnProperty('id') && item.is_delete))
	//     );

	//     const idsToDelete = majorsAndMinors.filter(item => item.hasOwnProperty('id') && item.is_delete).map(item => item.id);
	//     if (idsToDelete.length > 0) {
	//         try {
	//             await ccservice
	//                 .delete(`/po/ca/poMajorMinor?ids=[${idsToDelete.join(',')}]`)
	//                 .then(res => {
	//                     setMajorsAndMinors(prevState =>
	//                         prevState.filter(item => !idsToDelete.includes(item.id))
	//                     );
	//                     console.log("response", res);
	//                 });
	//         } catch (err) {
	//             console.log(err);
	//         }
	//     } else {
	//         console.log("No items to delete.");
	//     }
	// };
	const handleMultiDelete = async () => {
		// First, remove items marked for deletion
		const itemsToDelete = majorsAndMinors.filter(item => item.is_delete && item.hasOwnProperty('id')).map(item => item.id);

		if (itemsToDelete.length > 0) {
			try {
				await ccservice
					.delete(`/po/ca/poMajorMinor?ids=[${itemsToDelete.join(',')}]`)
					.then(res => {
						console.log("response", res);
						setMajorsAndMinors(prevState =>
							prevState.filter(item => !itemsToDelete.includes(item.id))
						);
					});
			} catch (err) {
				console.log(err);
			}
		}
		setMajorsAndMinors(prevState => prevState.filter(item => !item.is_delete));
		handlePostValues()


	};


	function PlaceOConfirmationModal() {
		const placeOmodalStyle = {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 350,
			height: 200,
			bgcolor: 'background.paper',
			// border: '2px solid #000',
			borderRadius: '20px',
			boxShadow: 24,
			p: 4,
		}

		//functions
		const handleConfirmModal = async () => {
			if (allowPlaceO.is_main) {
				if (allowPlaceO.placeO) {
					try {
						await ccservice.post(`/po/ca/poBatchMapping`, {
							"ug_id": batch_id,
							"po_allowed": 0,
							"profile_verification": 0
						});
						setAllowPlaceO((prev) => ({ ...prev, placeO: !prev.placeO, modal: false }));
					} catch (error) {
						console.error("Error posting placeO allow", error);
						window.alert(error.response.data.message)
					}
				} else {
					try {
						await ccservice.post(`/po/ca/poBatchMapping`, {
							"ug_id": batch_id,
							"po_allowed": 1,
							"profile_verification": allowPlaceO.placeOVerification ? 1 : 0
						});
						setAllowPlaceO((prev) => ({ ...prev, placeO: !prev.placeO, modal: false }));
					} catch (error) {
						console.error("Error posting placeO allow", error);
						window.alert(error.response.data.message)
					}
				}
			} else {
				try {
					await ccservice.post(`/po/ca/poBatchMapping`, {
						"ug_id": batch_id,
						"po_allowed": 1,
						"profile_verification": allowPlaceO.modalConfirmButton === 'Restrict' ? 0 : 1
					});
					setAllowPlaceO((prev) => ({ ...prev, placeOVerification: !prev.placeOVerification, modal: false }));
				} catch (error) {
					console.error("Error posting placeO allow", error);
					window.alert(error.response.data.message)
				}
			}
		}

		const handleModalCancel = () => {
			if (allowPlaceO.is_main) {
				setAllowPlaceO((prev) => ({ ...prev, placeO: prev.placeO }));
			} else {
				setAllowPlaceO((prev) => ({ ...prev, placeOVerification: prev.placeOVerification }));
			}
			allowPlaceO.modalClose();
		}


		return (
			<MUIModal open={allowPlaceO.modal}
				onClose={allowPlaceO.modalClose}
				// aria-labelledby="modal-modal-title"
				// aria-describedby="modal-modal-description"
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description">
				<Box sx={{ ...placeOmodalStyle }}>
					{/* {!allowPlaceO.placeO ? */}
					<Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'space-between', gap: 5 }}>
						<Typography style={{ fontSize: '20px' }}>{allowPlaceO.modalBody}</Typography>
						<Box sx={{ ...DivCenter, gap: '10px' }}>
							<Button variant="text"
								onClick={() => handleModalCancel()}
								sx={{ width: "70%", height: '44px', alignItems: 'right', textTransform: 'none', backgroundColor: 'white', color: Color.primary1 }}>Cancel</Button>
							<PrimaryButton variant="contained" sx={{ width: "70%", height: '44px', alignItems: 'right', textTransform: 'none', }}
								onClick={(e) => handleConfirmModal(e)}
							>{allowPlaceO.modalConfirmButton}</PrimaryButton>
						</Box>
					</Box>
				</Box>
			</MUIModal>
		);
	}

	const handleSetProcessDeadline = (process_id) => {
		setDeadlineModalData((prev) => ({ ...prev, open: true, extraData: { process_id: process_id } }));
	}

	const handleDeadlineTimeCalendarModal = () => {
		setDeadlineModalData((prev) => ({ ...prev, timeCalendarmodal: { ...prev.timeCalendarmodal, open: true }, open: false }));
	}

	const handleDeadlineTimeCalendarSave = async (value, time) => {
		console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time)
		// const date = new Date(Date.UTC(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0));
		const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
		const epochTime = date.getTime()
		console.log("handleDeadlineTimeCalendarSave ~ epochTime:", epochTime,);
		setDeadlineModalData((prev) => ({ ...prev, open: true, value: epochTime, timeCalendarmodal: { ...prev.timeCalendarmodal, open: false } }));
	}

	function SetDeadlineModal({ open, onClose, extraData }) {
		const modalBoxStyle = {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			justifyContent: "flex-start",
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			bgcolor: "white",
			padding: "1.5rem 2rem",
			borderRadius: "20px",
			width: "600px",
			height: "350px",
			minHeight: '250px'
		};

		const handlePutOfflineSessionProperties_setDeadline = async () => {
			try {
				const response = await ccservice.put(`/os/superadmin/properties`, {
					"process_id": deadlineModalData.extraData.process_id,
					"specialization_deadline": deadlineModalData.value,
				})
				deadlineModalData.timeCalendarmodal.onClose();
				deadlineModalData.onClose();
				console.log("response:", response);
				handleEffect();
			} catch (err) {
				console.log(err)
			}
		}

		return (
			<MUIModal open={open} onClose={onClose}>
				<Box sx={{ ...modalBoxStyle, justifyContent: 'space-between', gap: '20px' }}>
					<Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
						<Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', alignItems: 'space-between', justifyContent: "space-between", marginBottom: "8px", }}>
							<Typography style={{ fontSize: "24px", fontWeight: 'bold' }}>
								Set deadline
							</Typography>
							<Typography style={{ fontSize: "16px", color: Color.accent }}>
								Specify a deadline for student to select specialization.
							</Typography>
							{/* <hr style={{ width: '100%', margin: '8px 0' }} /> */}
						</Box>
						<Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', width: '100%', gap: '8px' }}>
							<Typography style={{ fontSize: "14px", color: Color.neutralBlack, fontWeight: 'bold' }}>Deadline for choosing specialization<span style={{ color: Color.red }}>*</span></Typography>
							<Box
								onClick={() => deadlineModalData.value ? {} : handleDeadlineTimeCalendarModal()}
								sx={{
									display: 'flex',
									alignItems: 'center',
									border: `1px solid ${Color.neutralMidGrey}`, // Set background color
									borderRadius: 4, // Add border radius
									padding: '8px', // Add padding
									cursor: deadlineModalData.value ? 'default' : 'pointer', // Simulate clickable behavior
									width: '100%'
								}}
							>
								<Box sx={{ flexGrow: 1, color: Color.neutralMidGrey }}>
									{deadlineModalData.value ?
										<Box sx={{ ...DivCenter, justifyContent: 'space-between', p: '8px 12px', cursor: 'default', width: '30%', minWidth: '230px', height: '45px', borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.neutralMidGrey }}>
											<Close
												sx={{ fontSize: '16px', cursor: 'pointer' }}
												onClick={() => setDeadlineModalData((prev) => ({ ...prev, value: null }))} />
											{formatConvertermonthfirst(deadlineModalData.value, true)}
										</Box>
										: "Specify the deadline"}
								</Box>
								<IconButton aria-label="Pick Date" disabled={deadlineModalData.value} onClick={() => { handleDeadlineTimeCalendarModal() }}>
									<DateRange />
								</IconButton>
							</Box>
						</Box>
					</Box>
					<Box sx={{ ...DivCenter, width: '100%' }}>
						<Button variant="contained"
							onClick={() => handlePutOfflineSessionProperties_setDeadline()}
							sx={{ width: '40%', minWidth: '150px', height: '54px', background: Color.primary_gradient }}>Submit</Button>
					</Box>
				</Box>
			</MUIModal>);
	}

	return (
	<div >
		<RowsContext.Provider value={rowsData}>
			<TopBar />
			<SideNavigationBar />
			{loading ?
				<SmallPageLoader show={loading} /> :
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<Box sx={{ DivCenter, justifyContent: 'flex-start', mt: 12, ml: 25, userSelect: 'none' }}>
						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start', width: '100%' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={allowPlaceO.placeO}
										onChange={(e) => handlePlaceOCheckbox(e)}
										sx={{
											color: "orangered",
											'&.Mui-checked': {
												color: "orangered",
											},
											transition: 'black 0.3s ease', // Add transition property for color change
										}}
									/>
								}
								label={<span style={{ color: Color.neutralBlack }}>Switch on PlaceO</span>}
							/>
						</Box>
						{allowPlaceO.placeO ? (
							<Box sx={{ display: "flex", justifyContent: 'flex-start', ml: 5, opacity: 1, transition: 'opacity 0.5s ease', flexDirection: "column" }}>
								<FormControlLabel
									control={
										<Checkbox
											checked={allowPlaceO.placeOVerification}
											onChange={(e) => handlePlaceOVerificationCheckbox(e)}
											sx={{
												color: "orangered",
												'&.Mui-checked': {
													color: "orangered",
												},
											}}
										/>}
									label={<span style={{ color: Color.neutralBlack }}>Switch on PlaceO verification</span>}
								/>
								<Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
									<WidgetsOutlinedIcon style={{ color: 'orangered', margin: "auto 0" }} />
									<Typography style={{ color: Color.neutralBlack, margin: "auto 0" }}>Student Specializations:</Typography>
									{majorsAndMinors.length > 0 ? <>
										{majorsAndMinors.filter(item => item.id).map((skills_item, index) => (
											<Box
												key={index}
												sx={{
													textAlign: "left",
													height: "30px",
													paddingTop: ".5rem",
													paddingBottom: ".5rem",
													borderBottom: `1px solid ${Color.neutralLightGrey}`,
													background: "#E6E6E6",
													width: "auto",
													paddingLeft: '1em',
													paddingRight: "1rem",
													borderRadius: "30px",
													display: "flex",

													alignItems: "center", // Align items vertically in the center
												}}
											>
												<Typography
													style={{
														width: "auto",
														color: Color.neutralMidGrey,
													}}
												>
													{skills_item.major}
												</Typography>
											</Box>
										))}

										<Box onClick={() => handleEditMajorAndMinor()} sx={{ display: "flex", flexDirection: "row", margin: "auto 0", cursor: "pointer", gap: ".5rem" }}>
											<Typography>Edit </Typography>
											<EditIcon sx={{ width: "16px", height: "16px", marginTop: "2px" }} />
										</Box>
									</> : <>
										<Box onClick={() => handleEditMajorAndMinor()} sx={{ display: "flex", flexDirection: "row", margin: "auto 0", cursor: "pointer", gap: ".5rem" }}>
											<Typography>Edit </Typography>
											<EditIcon sx={{ width: "16px", height: "16px", marginTop: "2px" }} />
										</Box>
									</>}
									<Box>

									</Box>
								</Box>
							</Box>
						) : (
							<Box sx={{ width: '100%', ml: 5, opacity: 0, transition: 'opacity 0.5s ease' }}>
								<FormControlLabel
									control={<Checkbox disabled />}
									label={<span style={{ color: Color.neutralBlack }}>Switch on PlaceO verification</span>}
								/>
							</Box>
						)}

					</Box> 
					{PlaceOConfirmationModal()}
					<SetDeadlineModal
						open={deadlineModalData.open}
						onClose={deadlineModalData.onClose}
						extraData={deadlineModalData.extraData}
					/>
					<DeadlineTimeCalendarModal
						open={deadlineModalData.timeCalendarmodal.open}
						onClose={deadlineModalData.timeCalendarmodal.onClose}
						extraData={{
							heading: "Choose Deadline",
							action_button: "Save",
							handleAction: (a, b) => handleDeadlineTimeCalendarSave(a, b)
						}}
					/>
					<EnhancedTable process_data={[college_name, batch_name]} navigateToResumesSettings={navigateToResumesSettings} activityData={programData} mainFile_modal={Modal_Process} data_getter={data_getter} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} noMarginTop={false} handleSetProcessDeadline={handleSetProcessDeadline} />
				</Box>
			}
			<MajorsAndMinorsModal
				modalOpen={majorsAndMinorsModalOpen}
				majorsAndMinors={majorsAndMinors}
				setMajorsAndMinors={setMajorsAndMinors}
				handleModalClose={handleCloseApplicationsModalClose}
				batch_id={batch_id}
				college_name={college_name}
				batch_name={batch_name}
				handleCloseApplications={handleCloseApplications}
				handleDeleteSpecialization={handleDeleteSpecialization}
			/>
		</RowsContext.Provider>

	</div>
	)
}

export default UserType