import React, { useState } from 'react'
import { SmallPageLoader } from '../../../reusables/Loader/Loader'
import { Box } from '@mui/material'
import { styles } from '../../../../GlobalStyles'

const LaunchScheduleStatus = () => {

    const [loading, setLoading] = useState({ page: false, activity: false });


    return (
        <>{loading.page ?
            <SmallPageLoader show={loading.page} />
            : <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                LaunchScheduleStatus
            </Box>
        }</>
    )
}

export default LaunchScheduleStatus