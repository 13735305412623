import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import CardActions from '@mui/material/CardActions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Service from '../../../services/httpService';
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from 'react-bootstrap/Modal';

const MentorAwaited = (props) => {
    const service = new Service()
    const navigate = useNavigate();
    const location = useLocation();
    const { college_id, college_name } = props
    const [tableDetails, setTableDetails] = useState([])
    let activity_name = location.state?.activityTypeData || []
    console.log("activity names", activity_name);
    let activityType = activity_name.filter((item) => item.type === "1 x 1");
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let mentorAwaited = await mentorAwaitedGetter();
            setTableDetails(mentorAwaited)
            console.log("datas", { mentorAwaited })
        }
        handleEffect();
    }, [])

    const mentorAwaitedGetter = async () => {
        try {
            const res = await service.get(`/roleBased/mentorAwaitedCount`)
            console.log("mentor roles awaited:", res.data)
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    console.log("table details", tableDetails);
    console.log("table details", activityType);
    return (
        <div>
            <Typography variant="body2" sx={{ fontSize: 22, marginBottom: "1.5rem", marginLeft: "8rem", marginTop: "2.5rem" }} gutterBottom>
                Mentor Awaited
            </Typography>
            <div className="cardsInsideFlex" style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                <TableContainer component={Paper} style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{""}</TableCell>
                                <TableCell>Total</TableCell>
                                {activityType.map((item) => {
                                    return (
                                        <>
                                            <TableCell align='center'>{item.act_type_name}</TableCell>
                                        </>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableDetails.map((row) => (
                                <TableRow
                                    key={row.role_name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.role_name}
                                    </TableCell>
                                    <TableCell align="center">{row.role_order_count}</TableCell>
                                    {activityType.map((activity, activityIndex) => {
                                        const matchingItem = row.activity_role_data.find(item => item.activity_type_id === activity.id);
                                        return (
                                            <TableCell align='center' key={activityIndex}>
                                                {matchingItem ? matchingItem.activity_order_count : "0"}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default MentorAwaited
