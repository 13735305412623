import { Color } from "../../../GlobalStyles";

export const colorDecider = (status, field) => {
    if (field === "color") {
        if (status === 'Accept')
            return "#1EBE70";
        else if (status === 'Expired')
            return 'red';
        else if (status === 'On hold')
            return 'red';
        else if (status === 'Closed')
            return Color.neutralBlack;
        else if (['In review', 'Paused'].includes(status))
            return '#F5A536';
    } else {
        if (status === 'Accept')
            return 'rgba(30, 190, 112, 0.10)';
        else if (status === 'Expired')
            return 'rgba(255, 14, 14, 0.10)';
        else if (status === 'On hold')
            return 'rgba(255, 14, 14, 0.10)';
        else if (status === 'Closed')
            return '#F2F2F2';
        else if (['In review', 'Paused'].includes(status))
            return 'rgba(245, 165, 54, 0.10)';
    }
}