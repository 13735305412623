import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, IconButton, Rating, Typography } from '@mui/material';
import { Color, DivCenter } from '../../../../GlobalStyles';
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import '../JobAllRequests/JobAllRequests.css'
import { SlideInRightModal } from '../AdditionalComponents/SlideInRightModal';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import TimeService from '../../../../functions/epochTime';
import { ellipsisFunction } from '../../../Reusable_functions/TextFunctions/EllipsisFunction';
import { ArrowBackIos } from '@mui/icons-material';

const JobStudentProcess = () => {

    const services = new CCService();
    const timeServices = new TimeService();
    const navigate = useNavigate();

    const initial_requestData = {
        "user_id": null,
        "first_name": "",
        "last_name": "",
        "college_name": "",
        "assessment_details": [
            {
                "list_number": 1,
                "assessment_name": "",
                "assessment_score": null
            },
            {
                "list_number": 2,
                "assessment_name": "",
                "assessment_score": null
            },
            {
                "list_number": 3,
                "assessment_name": "",
                "assessment_score": null
            },
            {
                "list_number": 4,
                "assessment_name": "",
                "assessment_score": null
            },
            {
                "list_number": 5,
                "assessment_name": "",
                "assessment_score": null
            },
        ]
    }

    const [allRequestData, setAllRequestData] = useState([{ ...initial_requestData }]);
    const [jobProcessDetails, setJobProcessDetails] = useState({
        company_name: '', designation: '', job_type: '', role_name: '', spe_name: '', openings: null
    });
    console.log("allRequestData:", allRequestData);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { company_name, designation, job_type, role_name, specialization_name, openings } = location.state;
    const job_id = searchParams.get("job_id");
    console.log("job_id:", job_id);

    //for filtering data
    const [backup_RequestData, setBackup_RequestData] = useState({ ...allRequestData });
    const [filterButtons, setFilterButtons] = useState({ "Selected": false, "Not selected": false, "Absent": false });

    // useEffect(() => {
    console.log("JobStudentProcess ~ jobProcessDetails:", jobProcessDetails)
    //     const handleUpdateAllRequestData = () => {
    //         const findTrueValue = (filterButtons) => {
    //             for (const key in filterButtons) {
    //                 if (filterButtons.hasOwnProperty(key) && filterButtons[key] === true) {
    //                     return key;
    //                 }
    //             }
    //             return null;
    //         }
    //         const which_one_is_true = findTrueValue(filterButtons);
    //         if (which_one_is_true) {
    //             setAllRequestData(() => ({
    //                 "List 1": backup_RequestData["List 1"].filter((data) => data.status_name === which_one_is_true),
    //                 "List 2": backup_RequestData["List 2"].filter((data) => data.status_name === which_one_is_true),
    //                 "List 3": backup_RequestData["List 3"].filter((data) => data.status_name === which_one_is_true),
    //                 "Extended List": backup_RequestData["Extended List"].filter((data) => data.status_name === which_one_is_true),
    //             }));
    //         } else {
    //             setAllRequestData(backup_RequestData);
    //         }
    //     }
    //     handleUpdateAllRequestData();
    // }, [filterButtons['Selected'], filterButtons['Not selected'], filterButtons['Absent']])

    useEffect(() => {
        const handleMainUseEffect = async () => {
            const { assessment_details, fetched_data } = await shortlistStudent_operations();
            console.log("fetched_data:", assessment_details);
            setAllRequestData(assessment_details);
            setBackup_RequestData(assessment_details);
            setJobProcessDetails(fetched_data);
        }
        handleMainUseEffect();
    }, []);

    const shortlistStudent_operations = async () => {
        const { fetched_data, assessment_details } = await fetchApplicantsbyJobId();
        console.log("fetched_data:", fetched_data, assessment_details);

        //making the data
        return { assessment_details, fetched_data };

        //fake inserting list data for further coding 
        // let temp_data = {};

        // fetched_data.forEach((data) => {
        //     temp_data[data.college_name] = fetched_data.filter((filter_data) => filter_data.college_name === data.college_name);
        // })

        // console.log("temp_data:", temp_data);
        // return temp_data;

        // return fetched_data;
    }

    const fetchApplicantsbyJobId = async () => {
        const response = await services.get(`/jobs/admin/jobProcessOverview?job_id=${job_id}`);
        console.log("response:", response);
        const data = response.data
        if (response)
            return {
                fetched_data: {
                    company_name: data.company_name,
                    openings: data.openings,
                    role_name: data.role_name,
                    spe_name: data.spe_name,
                    total_applied: data.total_applied
                },
                assessment_details: data.assessment_details
            };
        else return {
            fetched_data: {
                company_name: "",
                openings: "",
                role_name: "",
                spe_name: "",
                total_applied: null
            },
            assessment_details: data.assessment_details
        };
    }

    const handleFilterButtonClick = (button) => {
        // let temp = JSON.parse(JSON.stringify(filterButtons));
        // setAllRequestData((prev) => ({ "New Requests": temp.new ? [] : prev["New Requests"], "On Hold": temp.on_hold ? [] : prev["On Hold"], "Recent": temp.recent ? [] : prev["Recent"] }));
        if (button === 'Selected') {
            setFilterButtons((prev) => ({ ...prev, 'Selected': !prev['Selected'], 'Not selected': false, 'Absent': false }));
        } else if (button === 'Not selected') {
            setFilterButtons((prev) => ({ ...prev, 'Selected': false, 'Not selected': !prev['Not selected'], 'Absent': false }));
        } else if (button === 'Absent') {
            setFilterButtons((prev) => ({ ...prev, 'Selected': false, 'Not selected': false, 'Absent': !prev['Absent'] }));
        }
    }

    return (
        <Box className='main_box'>
            <SideNavigationBar />
            <Box className='box__fillerBox' sx={{ width: '12%' }}></Box>
            <Box className='box__mainContent' sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', gap: '33px', alignItems: 'flex-start' }}>
                <Box sx={{ ...DivCenter, mt: '10px', gap: '33px' }}>
                    <IconButton onClick={() => navigate(-1)} style={{ height: "30px", width: "30px", }} >
                        <ArrowBackIos className="Arrow-back-btn" />
                    </IconButton>
                    <Typography sx={{ fontSize: '32px', color: 'black' }}>{jobProcessDetails?.company_name ?? 'No Company Name'}</Typography>
                </Box>
                <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', gap: '16px', mb: '33px' }}>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Typography style={{ width: '18%', color: Color.neutralMidGrey }}>Job Type:</Typography>
                        <Typography style={{ width: '82%' }}>{job_type ?? 'N/A'}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Typography style={{ width: '18%', color: Color.neutralMidGrey }}>Designation:</Typography>
                        <Typography style={{ width: '82%' }}>{designation ?? 'N/A'}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Typography style={{ width: '18%', color: Color.neutralMidGrey, hyphens: 'auto' }}>Specialization:</Typography>
                        <Typography style={{ width: '82%' }}>{jobProcessDetails.spe_name ? ellipsisFunction(jobProcessDetails.spe_name, 20) : 'N/A'}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Typography style={{ width: '18%', color: Color.neutralMidGrey }}>Role:</Typography>
                        <Typography style={{ width: '82%' }}>{jobProcessDetails?.role_name ?? 'N/A'}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Typography style={{ width: '18%', color: Color.neutralMidGrey }}>Number of Hires:</Typography>
                        <Typography style={{ width: '82%' }}>{jobProcessDetails.openings ?? 'N/A'}</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%' }}>
                        <Typography style={{ width: '18%', color: Color.neutralMidGrey }}>Total Applications:</Typography>
                        <Typography style={{ width: '82%' }}>{jobProcessDetails.total_applied ?? 'N/A'}</Typography>
                    </Box>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box className='box__header'>
                        <Typography className='typography__header' style={{ width: '33.33%' }}>Process</Typography>
                        <Typography className='typography__header' style={{ width: '33.33%' }}>Pending</Typography>
                        <Typography className='typography__header' style={{ width: '33.33%' }}>Admissible</Typography>
                    </Box>
                    <hr style={{ color: Color.neutralMidGrey }} />
                    <Box className='box__postings'>
                        <Box sx={{ width: '100%' }}>
                            {allRequestData.length > 0 ?
                                // allRequestData[0].user_id ?
                                    allRequestData.map((request_row, index) => (
                                        <Box key={index}>
                                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-evenly', padding: '16px 0 16px 0' }}>
                                                <Typography style={{ width: '33.33%', textAlign: 'center', color: Color.primary1 }}>
                                                    {request_row.assessment_name}
                                                </Typography>
                                                <Typography style={{ width: '33.33%', textAlign: 'center' }}>
                                                    {jobProcessDetails.total_applied - request_row.completed_assessment}
                                                </Typography>
                                                <Typography style={{ width: '33.33%', textAlign: 'center' }}>
                                                    {request_row.completed_assessment}
                                                </Typography>
                                            </Box>
                                            <hr />
                                        </Box>
                                    ))
                                    // : <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>
                                : <Typography style={{ color: Color.neutralMidGrey }}>No data available</Typography>}
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box className='box__fillerBox' sx={{ width: '8%' }}></Box>
        </Box>
    );
}

export default JobStudentProcess;