import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ActivityLoader, SmallPageLoader } from '../../../reusables/Loader/Loader'
import CCService from '../../../../services/httpServiceWithoutSuperAdmin'
import { DivCenter, Color, styles } from '../../../../GlobalStyles'
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace'
import { Close, DateRange } from '@mui/icons-material'
import { DeadlineTimeCalendarModal } from '../../../Reusable_functions/DeadlineTimeCalendarModal/DeadlineTimeCalendarModal'

const LaunchScheduleDeadline = ({ process_id, onStepChange }) => {

    const service = new CCService()
    const [showSlotPage, setShowSlotPage] = useState(false);
    const [loading, setLoading] = useState({ page: false, activity: false });

    //data
    const [formInputs, setFormInputs] = useState({
        "slot_selection_deadline": null,
        "no_of_student_per_list": null,
        "percent_cutoff_for_new_list": null,
        "time_cutoff_for_new_list": null
    })
    //Modals
    const [deadlineModalData, setDeadlineModalData] = useState({
        open: false,
        onClose: () => setDeadlineModalData((prev) => ({ ...prev, open: false })),
        value: null,
        flag: '',
        extraData: {
            heading: "",
            action_button: "",
            handleAction: {}
        }
    })

    //FUNCTIONS
    useEffect(() => {
        handleEffect();
    }, []);

    const handleEffect = async () => {
        setLoading({ ...loading, page: true });
        const properties_data = await getProperties();
        if(properties_data?.slot_selection_deadline){
            setShowSlotPage(true);
        }
        setFormInputs({
            ...formInputs,
            "slot_selection_deadline": properties_data.slot_selection_deadline,
            "no_of_student_per_list": properties_data.no_of_student_per_list,
            "percent_cutoff_for_new_list": properties_data.percent_cutoff_for_new_list,
            "time_cutoff_for_new_list": properties_data.time_cutoff_for_new_list
        });
        setLoading({ ...loading, page: false });
    }

    const getProperties = async () => {
        try {
            const response = await service.get(`/os/superadmin/properties?process_id=${process_id}`);
            console.log("getProperties ~ response:", response);
            return response.data;
        } catch (error) {
            console.error("Error while getting properties ~ slot deadline:", error);
        }
    }

    const handleDeadlineCalendarModal = () => {
        setDeadlineModalData((prev) => ({ ...prev, open: true }));
    }

    const handleDeadlineTimeCalendarSave = async (value, time) => {
        console.log("handleDeadlineTimeCalendarSave ~ value, time:", value, time)
        const date = new Date(value["$d"].getFullYear(), value["$d"].getUTCMonth(), value["$d"].getDate(), time.slice(0, 2), time.slice(3), 0);
        const epochTime = date.getTime()
        console.log("handleDeadlineTimeCalendarSave ~ epochTime:", epochTime, date.getHours());
        setFormInputs((prev) => ({ ...prev, slot_selection_deadline: epochTime }));
        setDeadlineModalData((prev) => ({ ...prev, open: false }));
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let temp_formInputs = { ...formInputs };
        temp_formInputs[name] = value;
        setFormInputs(temp_formInputs);
    }

    const handleSubmit = async () => {
        try {
            setLoading({ ...loading, activity: true });
            const body = {
                "process_id": process_id,
                "slot_selection_deadline": formInputs.slot_selection_deadline,
                "no_of_student_per_list": formInputs.no_of_student_per_list,
                "percent_cutoff_for_new_list": formInputs.percent_cutoff_for_new_list,
                "time_cutoff_for_new_list": formInputs.time_cutoff_for_new_list
            }
            // console.log("handleSubmit ~ body:", body)
            await service.put(`/os/superadmin/properties`, body);
            onStepChange(null, null, 1);
            setLoading({ ...loading, activity: false });
        } catch (err) {
            console.error("Error putting properties:", err);
            setLoading({ ...loading, activity: false });
        }
    }

    return (
        <>{loading.page ?
            <SmallPageLoader show={loading.page} />
            : <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                <Typography style={{ fontSize: '24px', fontWeight: 500 }}>Launch Scheduling</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
                    <Box sx={{ ...styles.page, gap: '16px', width: '100%' }}>
                        <Typography style={{ fontSize: '18px', fontWeight: 400 }}>Set deadline for students to choose their time slot</Typography>
                        <Box
                            onClick={() => formInputs.slot_selection_deadline ? {} : handleDeadlineCalendarModal()}
                            sx={{
                                background: Color.white,
                                display: 'flex',
                                alignItems: 'center',
                                border: `1px solid ${Color.neutralMidGrey}`, // Set background color
                                borderRadius: 4, // Add border radius
                                padding: '8px', // Add padding
                                cursor: formInputs.slot_selection_deadline ? 'default' : 'pointer', // Simulate clickable behavior
                                // cursor: 'pointer', // Simulate clickable behavior
                                width: '100%'
                            }}>
                            <Box sx={{ ...DivCenter, justifyContent: 'flex-start', flexGrow: 1, color: Color.neutralMidGrey, gap: '8px', flexWrap: 'wrap' }}>
                                {formInputs.slot_selection_deadline ?
                                    <>
                                        <Box onClick={(e) => e.stopPropagation()} sx={{ ...DivCenter, justifyContent: 'space-between', p: '8px 12px', cursor: 'default', width: '15%', minWidth: '200px', height: 'auto', minHeight: '45px', borderRadius: '12px', background: 'rgba(56, 193, 223, 0.3)', color: Color.neutralMidGrey }}>
                                            <Close
                                                sx={{ fontSize: '16px', cursor: 'pointer' }}
                                                onClick={() => { setFormInputs((prev) => ({ ...prev, slot_selection_deadline: null })) }} />
                                            {formatConvertermonthfirst(formInputs.slot_selection_deadline, false, true)}
                                        </Box>
                                        Specify the deadline
                                    </>
                                    :
                                    "Specify the deadline"
                                }
                            </Box>
                            <IconButton aria-label="Pick Date"
                                disabled={formInputs.slot_selection_deadline}
                                onClick={() => { handleDeadlineCalendarModal() }}>
                                <DateRange />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%', gap: '8px' }}>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', alignItems: 'flex-start', gap: '16px' }}>
                            <Typography style={{ fontSize: '18px', fontWeight: 400 }}>Number of students per list</Typography>
                            <TextField name='no_of_student_per_list' placeholder='Specify the number of students for a list' value={formInputs.no_of_student_per_list} onChange={handleChange} sx={{ width: '100%' }} />
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', alignItems: 'flex-start', gap: '16px' }}>
                            <Typography style={{ fontSize: '18px', fontWeight: 400 }}>Cut off for new list (in %)</Typography>
                            <TextField name='percent_cutoff_for_new_list' placeholder='Specify the cut-off for the new list' value={formInputs.percent_cutoff_for_new_list} onChange={handleChange} sx={{ width: '100%' }} />
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', alignItems: 'flex-start', gap: '16px' }}>
                            <Typography style={{ fontSize: '18px', fontWeight: 400 }}>Cut off for waiting time (in hrs)</Typography>
                            <TextField name='time_cutoff_for_new_list' placeholder='Specify the cut-off for the waiting list' value={formInputs.time_cutoff_for_new_list} onChange={handleChange} sx={{ width: '100%' }} />
                        </Box>
                    </Box>
                    <Box sx={{ ...DivCenter, }}>
                        <Button variant='contained' onClick={() => handleSubmit()} sx={{ ...styles.button_styles.contained, height: '51px', width: '60%', fontSize: '20px' }}>Review Slots</Button>
                    </Box>
                </Box>
               { showSlotPage && <Button variant='outlined' onClick={() => onStepChange(null, null, 1)}>Slots Page</Button> }
                <DeadlineTimeCalendarModal
                    open={deadlineModalData.open}
                    onClose={deadlineModalData.onClose}
                    extraData={{
                        ...deadlineModalData.extraData,
                        heading: "Choose Deadline",
                        action_button: "Save",
                        handleAction: (a, b) => handleDeadlineTimeCalendarSave(a, b),
                    }}
                />
                <ActivityLoader show={loading.activity} />
            </Box >}
        </>
    )
}

export default LaunchScheduleDeadline