import axios from "axios";
import React, { useEffect, useState } from "react";
import Service from '../../../services/httpService';
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
//modal
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Modal from "react-bootstrap/Modal";
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import Loader from "../../reusables/Loader/Loader";
import { saveAs } from "file-saver";

import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';

const UserType = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [rowsData, setRowsData] = useState([])
    const [formInputs, setFormInputs] = useState([{ durations_name: "", durations_values: "" }])
    const [rowsData_mod_id, setRowsData_mod_id] = useState([])
    const [rowsData_mod_key, setRowsData_mod_key] = useState([])
    const [showData, setShowData] = useState([{ company_name: "", role_id: "", batch_type_id: "", college_type_id: "" }])
    const [jdData, setJDData] = useState()
    //from table index
    const [from_table, setFrom_table] = useState();

    // let jdData = []
    const [roleData, setRoleData] = useState([])
    const [batchData, setBatchData] = useState([])
    const [programData, setProgramData] = useState([])

    const [role, setRole] = useState()
    const [batch, setBatch] = useState()
    const [program, setProgram] = useState()

    const [jd_select_id, setJd_select_id] = useState();
    const [jd_batch_id, setJd_batch_id] = useState([]);
    const [jd_program_id, setJd_program_id] = useState([]);

    const [jd_input_name, setJd_input_name] = useState(rowsData[from_table?.index]?.company_name);
    const [jd_select_name, setJd_select_name] = useState();
    const [jd_batch_name, setJd_batch_name] = useState([]);
    const [jd_program_name, setJd_program_name] = useState([]);

    //sending the batch_type_ids and program_type_ids in PUT
    const [batch_type_ids, setBatch_type_ids] = useState([]);
    const [program_type_ids, setProgram_type_ids] = useState([]);



    //modal hooks
    const [batch_select_data, setBatch_select_data] = useState([])
    const [program_select_data, setProgram_select_data] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [JDfiles, setJDFiles] = useState([]);
    // const [count, setCount] = useState()
    const service = new Service()
    let count = 0;
    const headCells = [
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'Duration Names',
        },
        {
            id: 'heading2',
            numeric: 'right',
            disablePadding: false,
            label: 'Duration Values',
        }

    ];

    const handleDeleteMul = (index, id) => {
        console.log("delete_id", id)
        console.log("delete_index", index)
        console.log("deleting this:", rowsData[index[0]]?.duration_name)
        let list = [...rowsData];

        console.log("listtttt1", list);


        service
            .delete("/duration", {
                data: { "duration_name": rowsData[index]?.duration_name }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list = list.filter((item) => id.indexOf(item.duration_value) < 0);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                // window.alert("Errog Msg: ", (err.response.data.errMess).toString());
            });
    }

    const data_getter = (name, id, index, activity) => {
        // setRowsData(data)
        console.log("Dude here's your name:", name);
        console.log("Dude here's your id:", id);
        console.log("Dude here's your index:", index);

        if (name === "delete_mul") {
            console.log("inside delete mul if")
            handleDeleteMul(index, id)
        }
        if (activity === "add") {
            console.log("UserType ~ activity", activity)
            setFormInputs({})
        }
        // if (activity === "delete") {
        // 	handleDelete(id, index)
        // }

        setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
    }

    const durationGetter = async () => {
        try {
            const res = await service.get('/duration')
            console.log("Duration RESPONSE:", res.durations)
            setRowsData([...res.durations])
            return res.durations
        } catch (err) {
            console.log(err)
        }
    }

    // get request
    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //jd getter
            let duration = await durationGetter();

            // forming the data
            console.log("odonil", { duration })
            data_manupulator_duration(duration);
            setLoading(false)
        }

        handleEffect();
    }, [])

    useEffect(() => {
        const setting_data = () => {

            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setFormInputs({})
            }
            else {
                //setting values for Edit Modal
                console.log("Inside else of useEffect")
                console.log({ rowsData })
                setFormInputs({ ...formInputs, duration_name: rowsData[from_table?.index]?.duration_name, duration_value: rowsData[from_table?.index]?.duration_value })
            }

        }
        setting_data();
    }, [from_table])

    const data_manupulator_duration = (duration) => {
        console.log("Duration data:", duration)
        let temp_rowsData = JSON.parse(JSON.stringify(duration));

        //show data - send to Table Component
        temp_rowsData.forEach((row) => {

            delete row.is_deleted

        });
        console.log("temp rows:", temp_rowsData)
        setRowsData(temp_rowsData)

    }



    //MODAL
    const Modal_Durations = (show, setShow) => {
        console.log("aaaaaaaaaaaaaaaaaaaaaaa-program", program)
        //variables
        // const fileInputRef = useRef(null);

        // function handleCloudClick() {
        //   fileInputRef.current.click();
        // }

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }

        const modalSubmit = () => {
            if (from_table.index === undefined)
                handlePostRequest();
            else
                handlePutRequest();

            setShow(false)
        }



        const handleFileInput = (event) => {
            const f = event.target.files;
            let filesArray = [...JDfiles];
            for (let i = 0; i < f.length; i++) {
                filesArray.push(f[i]);
            }
            setJDFiles(filesArray);
        };

        const handleRemoveFile = (fileIndex) => {
            console.log("Removed id:", rowsData[from_table?.index]?.id)
            // if () {
            try {
                let res = service.delete("/job_name/file",
                    {
                        data: {
                            jd_id: rowsData[from_table?.index]?.id
                        }
                    })

                // console.log("Response from backend is", res.data);
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                const filesArray = [...JDfiles];
                filesArray.splice(fileIndex, 1);
                setJDFiles(filesArray);

            } catch (err) {
                console.log("Errog Msg: ", err);

            }
            // }


            // const filesArray = [...JDfiles];
            // filesArray.splice(fileIndex, 1);
            // setJDFiles(filesArray);
        };

        // function handleCloudClick() {
        //   fileInputRef.current.click();
        // }

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }



        const onSelect_batch = (e) => {
            console.log("Added an item to multi select - batch -", e)
            setBatch_select_data(e);
            let temp = e;
            temp = temp.filter(data => typeof data.id === "number").map(obj => obj.id)
            //   data.id = data.id;

            //   return data;
            // });
            setJd_batch_id(temp)
            console.log("temp inside onSelect:", temp)
        }
        const onSelect_program = (e) => {
            console.log("Added an item to multi select - program -", e)
            setProgram_select_data(e);

            let temp_key = e;
            let temp_id = e;
            temp_id = temp_id.filter(data => typeof data.id === "number").map(obj => obj.id)
            temp_key = temp_key.filter(data => typeof data.key !== "number").map(obj => obj.key)
            console.log("temp inside onSelect:", temp_id)
            console.log("temp inside onSelect:", temp_key)
            setJd_program_id(temp_id)
            setJd_program_name(temp_key)
        }

        const onRemove_batch = (e) => {
            console.log("Removed an item to multi select")
            setBatch_select_data(e);


        }
        const onRemove_program = (e) => {
            console.log("Removed an item to multi select")
            setProgram_select_data(e);

        }
        const onHide = () => {
            setShow(false)
            setJd_input_name()
            setBatch_select_data()
            setProgram_select_data()
            setJd_select_id("Select role type")
            setJd_select_name("Select role type")
        }


        const handleChange = (e, index) => {
            //Logs for entering values
            console.log("name from form  is:", e.target.name);
            console.log("value from form  is:", e.target.value);

            //Conditions for storing values
            if (e.target.name === "durations_name") {
                console.log("durations_name")
                setFormInputs({ ...formInputs, duration_name: e.target.value })
            }
            else if (e.target.name === "durations_values") {
                console.log("durations_values")
                setFormInputs({ ...formInputs, duration_value: e.target.value })
            }
        }


        //render
        return (
            <Modal
                show={show}
                onHide={() => onHide()}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '95%',
                    minWidth: '500px',
                    minHeight: '300px'
                }}>
                    <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>

                        <div className='right-side-buttons-jd row'>
                            <div className="headerComp" >
                                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                <p className="compTitle">Durations</p>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Duration Name<span>*</span></label>
                                <input name="durations_name" type="text" className="form-control" placeholder="Example input" value={formInputs.duration_name} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Duration Value<span>*</span></label>
                                <input name="durations_values" type="text" className="form-control" placeholder="Example input" value={formInputs.duration_value} onChange={(e) => handleChange(e)} />

                            </div>

                            <div className=" btn-comp " style={{ marginTop: "4%" }}>
                                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
                            </div>

                        </div>

                    </form>
                </Modal.Body>
            </Modal>

        )
    }

    const headers = {
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
    };

    // post request
    const handlePostRequest = async () => {
        let data = {
            duration_name: formInputs.duration_name,
            duration_value: formInputs.duration_value
        }

        console.log("User TYpe id not found, sending teh post request", data);
        await service
            .post("/duration", data)
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")


                let temp = JSON.parse(JSON.stringify(rowsData));

                temp = ([{
                    duration_name: formInputs.duration_name,
                    duration_value: formInputs.duration_value,
                    // is_deleted: 0
                }, ...temp])
                setRowsData(temp)
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }

    const handlePutRequest = async () => {
        console.log("")
        //get request here
        service
            .put("/duration", {
                duration_name: rowsData[from_table?.index].duration_name,
                new_duration_name: formInputs.duration_name,
                duration_value: formInputs.duration_value
            })
            .then((res) => {
                // console.log("Response from backend is", res.data);
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")



                let temp = JSON.parse(JSON.stringify(rowsData));
                console.log(jd_select_id)

                temp[from_table?.index].duration_name = formInputs.duration_name
                temp[from_table?.index].duration_value = formInputs.duration_value
                // temp[from_table?.index].is_deleted = rowsData[from_table?.index].is_deleted

                console.log("Put temp value:", temp)
                setRowsData(temp)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }

    // delete request
    const handleDelete = async (jd_id, index) => {
        console.log("delete", jd_id)
        let list = [...rowsData];

        console.log("listtttt1", list);
        service
            .delete("/duration", {
                data: { "jd_id": jd_id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);

                console.log(err.response.data.message)
                window.alert(err.response.data.message)

            });
    }



    return (<div >
        <RowsContext.Provider value={rowsData}>
            <TopBar />
            <SideNavigationBar />
            {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto'  }}><Loader show={loading} /></div> : <>
                {console.log("from_table.index", from_table?.index)}
                {console.log({ formInputs })}
                {console.log({ rowsData })}

                <EnhancedTable mainFile_modal={Modal_Durations} data_getter={data_getter} headCells={headCells} count={count} path={window.location.pathname} />
            </>}

        </RowsContext.Provider>

    </div>
    )
}
// value={{rowsData, handleFormSubmission : (e,user_type_name) => handleFormSubmission(e,user_type_name)}}

export default UserType