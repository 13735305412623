import React from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { Color } from '../../../GlobalStyles';

// Styled component for the textfield
// Styled component for the textfield
const StyledTextField = styled(TextField)`
  margin-bottom: 1rem; // Add spacing between fields

  &:focus {
    outline: none; // Remove default outline on focus
    border: ${({ focusBorder }) =>
        focusBorder ? `${focusBorder.width}px solid ${focusBorder.color}` : 'none'};
  }

  &:hover {
    outline: none;
  }

  .MuiInputLabel-root {
    color: ${({ theme }) => Color.primary1}; // Theme-based label color
  }
`;

const PrimaryTextfield = ({ label, id, type, ...props }) => {
    return (
        <StyledTextField
            label={label}
            id={id}
            type={type}
            {...props}
        />
    );
};

export default PrimaryTextfield;
