import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { Color, DivCenter } from "../../../../GlobalStyles";
import { useEffect, useState } from "react";
import { ArrowBackIosNew, DeleteOutlined } from "@mui/icons-material";
import { formatConvertermonthfirst } from "../../../../services/specialCharReplace";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";

export const DecideInterviewsModal = ({ open, onClose, current, action_function, allData, onDelete, extraData }) => {
    const { handleDecideInterviews_Submit, handleDecideInterviews_AddMore } = action_function;
    const { setDeadlineModalData } = extraData;
    //data variables
    const service = new CCService();
    const [loading, setLoading] = useState({ modal: false, button: false });
    const [interviewCount, setInterviewCount] = useState(current.per_mentor_slot_count);
    const modalBoxStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        padding: "1.5rem 2rem",
        borderRadius: "20px",
        minWidth: "400px",
        height: "auto",
        minHeight: '250px'
    };

    const styles = {
        button_styles: {
            contained: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.primary1,
                color: Color.white,
                borderRadius: '12px',
                textTransform: 'none',
                '&:hover': {
                    background: Color.primary1,
                    color: Color.white,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            },
            outlined: {
                minWidth: '100px',
                width: 'auto',
                height: '44px',
                background: Color.white,
                color: Color.primary1,
                borderRadius: '12px',
                border: `1px solid ${Color.primary1}`,
                textTransform: 'none',
                '&:hover': {
                    background: Color.white,
                    color: Color.primary1,
                },
                '&:disabled': {
                    background: 'rgba(28, 142, 168, 0.4)',
                    color: Color.white,
                }
            }
        }
    }

    //FUNCTIONS
    // useEffect(() => {
    //     const handleEffect = async () => {

    //     }
    //     handleEffect();
    // }, [])
    //this function will close this modal, take the data to MentorInterest and set it inside formInputs



    const handleSubmit = () => {
        handleDecideInterviews_Submit(current.session_date, interviewCount);    //passed from MentorInterest
        setInterviewCount(null);
    }

    //this function will close this modal, take the data to DeadlineCalendarModal, open the DeadlineCalendarModal and show the data there
    const handleAddMore = () => {
        handleDecideInterviews_AddMore(current.session_date, interviewCount);  //passed from MentorInterest
        setInterviewCount(null);
    }

    const handleBack = () => {
        onClose();
        setDeadlineModalData((prev) => ({ ...prev, open: true, }));
    }
  console.log("sessions alldata:", allData)
    return (
        <Modal open={open} onClose={loading.button ? {} : onClose}>
            <Box sx={{ ...modalBoxStyle, justifyContent: 'flex-start', alignItems: 'space-between', gap: '20px', height: 'auto' }}>
                <Box sx={{ ...DivCenter, width: '100%' }}>
                    <Box sx={{ ...DivCenter, width: '10%', justifyContent: 'flex-start', }}>
                        <ArrowBackIosNew onClick={() => { handleBack() }} sx={{ color: Color.neutralBlack, width: '20px', height: '20px', cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ ...DivCenter, width: '90%', justifyContent: 'flex-start', }}>
                        <Typography style={{ color: Color.primary1, fontSize: '20px' }}>{formatConvertermonthfirst(current.session_date, false, true)}</Typography>
                    </Box>
                </Box>
                <Box sx={{ ...DivCenter, flexDirection: 'column', gap: '24px' }}>
                    <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'column', gap: '8px' }}>
                        <Typography style={{ color: Color.primary1, fontSize: '20px' }}>Number of interviews to be taken<span style={{ color: Color.red }}>*</span></Typography>
                        <TextField type="number" name="interview_count" value={interviewCount} onChange={(e) => setInterviewCount(e.target.value)} sx={{ textAlign: 'center' }} />
                    </Box>
                    {allData.length ?
                        <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', }}>
                            <Box sx={{ ...DivCenter, width: '100%', background: 'rgba(126, 174, 190, 0.2)', height: 'auto', minHeight: '37px' }}>
                                <Typography style={{ textAlign: 'center', width: '50%', fontSize: '14px' }}>Date </Typography>
                                <Typography style={{ textAlign: 'center', width: '50%', fontSize: '14px' }}>Interviews to be taken</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%' }}>
                                {allData.map((data, index) =>
                                    <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'row', gap: '8px', height: 'auto', minHeight: '28px' }}>
                                        <Typography style={{ textAlign: 'center', width: '50%', fontSize: '14px' }}>{formatConvertermonthfirst(data.session_date)}</Typography>
                                        <Box sx={{ ...DivCenter, width: '50%', justifyContent: 'space-between' }}>
                                            <Typography style={{ textAlign: 'center', width: '90%', fontSize: '14px', }}>
                                                {data.per_mentor_slot_count}
                                            </Typography>
                                            <span onClick={()=> {onDelete(index); console.log("allData", allData)}} style={{ width: '10%', }}><DeleteOutlined  sx={{ color: Color.red, width: '18px', height: '18px' }} /></span>
                                        </Box>
                                    </Box>)}
                            </Box>
                        </Box> : <></>}
                    <Box sx={{ ...DivCenter, width: '100%', gap: '16px' }}>
                        <Button disabled={!interviewCount} sx={{ ...styles.button_styles.contained, width: '50%' }} onClick={(e) => handleSubmit()}>Submit</Button>
                        <Button disabled={!interviewCount} sx={{ ...styles.button_styles.contained, width: '50%' }} onClick={(e) => handleAddMore()}>Add more</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}