import React, { useRef, useState, useEffect, useContext, Fragment } from "react";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import Service from "../../../services/httpService";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { SkeletonLoader1 } from "../../reusables/SkeletonLoader/SkeletonLoader";
import "../Analytics.css";
import ReactDOM from 'react-dom';
// import ReactDOMServer from 'react-dom/server';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ActivityReport from "./ActivityReport";
import InterviewWiseAnalytics from "../InterviewWiseAnalytics/InterviewWiseAnalytics";
import BatchProgressTracker from "./BatchProgressTracker";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { Select, MenuItem } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useReactToPrint } from 'react-to-print';
import ScrollButton from "../../reusables/ScrollButton";
import ResumesDigitalAnalysis from '../ResumesDigitalAnalytics/ResumesDigitalAnalytics'
// import { Fragment } from 'react'; 

const BatchWiseAnalytics = () => {
  const divToPrintRef = useRef(null);
  const [batchId, setBatchId] = useState(null);
  const [pdfContent, setPdfContent] = useState(null);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false)
  const service = new CCService();
  const superAdminService = new Service();
  const location = useLocation();
  const navigate = useNavigate();
  const batchDetails = location.state?.BatchData;
  const college_id = location.state?.college_id;
  const college_name = location.state?.college_name;
  const program_id = batchId ? batchId : location.state?.program_id;
  const program_name = location.state?.program_name;
  const activated_students = location.state?.activated_students;
  const non_activated_students = location.state?.non_activated_students;
  console.log("batch deails in batch wise analytics", batchDetails, college_id, college_name, program_id, program_name);
  const [batchProcessDataGetter, setBatchProcessDataGetter] = useState([]);
  const [batchAnalyticsData, setBatchAnalyticsData] = useState([]);
  const [batchActivityReport, setBatchActivityReport] = useState([]);
  const [batchProcessOverviewActivityReport, setBatchProcessOverviewActivityReport] = useState({});
  const [loader, setLoader] = useState(true);
  const [BatchesData, setBatchesData] = useState([]);
  const [activityReportSkeletonLoader, setActivityReportSkeletonLoader] = useState(true);
  const [resumesDigitalSkeletonLoader, setResumesDigitalSkeletonLoader] = useState(true);
  const [resumesDigitalAnalyticsData, setResumesDigitalAnalyticsData] = useState([]);
  const [interviewWiseSkeletonLoader, setInterviewWiseSkeletonLoader] = useState(true);
  const [resumesDigitalVisible, setResumesDigitalVisible] = useState(true)
  const skeletonLoading = true;
  const [countElement, setCountElement] = useState(-1);
  console.log("batch id currently", program_id);
  const componentRef = useRef();

  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      window.scrollTo(0, 0);
      let batchProcessesDetails = await batchProcessGetter();
      let batchActivityReportGetter = await batchActivityReportDataGetter();
      let batchResumesDigitalGetter = await batchResumesDigitalProcessGetter()

      const processIds = batchResumesDigitalGetter.map(item => item.process_id);
      console.log("processIds",processIds);
      if(processIds.length > 0) {
        const resumeDigitalResult = await batchResumesDigitalAnalyticsDataGetter(processIds)
        if(resumeDigitalResult.length === 0) {
          setResumesDigitalVisible(false)
        }
        setResumesDigitalAnalyticsData(resumeDigitalResult)
      }

      const batchData = await batchDataGetter(college_id);
      setBatchesData(batchData);

      // setBatchAnalyticsData(batchAnalysisResults);
      setBatchActivityReport(batchActivityReportGetter);
      setBatchProcessDataGetter(batchProcessesDetails);
      console.log("index length", batchProcessesDetails.length, batchProcessesDetails.length - 1);
      setBatchId(program_id);
      setLoader(false);

      if (downloadButtonClicked === true) {
        handlePrint()
      }
    };
    handleEffect();
  }, [batchId, downloadButtonClicked]);



  const handlePrintFunction = () => {
    setDownloadButtonClicked(true)
  }

  console.log("setDownloadButtonClicked", downloadButtonClicked);

  // const handlePrint =
  //   useReactToPrint({
  //     pageStyle: `@media print and (width: 21cm) and (height: 29.7cm) {
  //             .page-break {
  //               page-break-after: always !important;
  //             }
  //             .page-break:first {
  //               page-break-after: always !important;
  //             }
  //             @page:first {
  //               size: auto;
  //               margin-top: 0;
  //               margin-bottom: 1rem;
  //             }
  //             @page {
  //               size: auto;
  //               margin-top: 4.2%;
  //               margin-bottom: 1rem;
  //             }

  //           }`,
  //     content: () => componentRef.current,
  //     onAfterPrint: () => {
  //       setDownloadButtonClicked(false)
  //     },
  //   });

  const handlePrint = useReactToPrint({
    pageStyle: `
      @media print {
        .page-break {
          page-break-after: always !important;
        }
        .page-break:first-of-type {
          page-break-before: always !important;
        }
        
      }`,
    content: () => componentRef.current,
    onAfterPrint: () => {
      setDownloadButtonClicked(false);
    },
  });

  console.log("pdfContent", pdfContent);

  // batch list of particular college
  const batchDataGetter = async () => {
    console.log("index and college id", college_id);
    try {
      const res = await superAdminService.get(`/programs?college_id=${college_id}`);
      console.log("1x1 data RESPONSE:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  // batch processes endpoint
  const batchProcessGetter = async () => {
    try {
      const res = await service.get(`/analysis/processByUg_id?user_group_id=${program_id}`);
      console.log("batch processes response:", res.getProcessAndSku);
      return res.getProcessAndSku;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      console.log("elemnt reached Bottom", countElement);
      // Check if the user has scrolled to the bottom of the page
      if (windowHeight + scrollTop + 10 > documentHeight) {
        // Increment the count by one when the user reaches the bottom
        setCountElement((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [countElement]);

  // batch progress tracker endpoint

  // interview wise data endpoint
  const batchAnalyticsDataGetter = async (program_id) => {
    try {
      const res = await service.get(`/analytics/user/batchAnalysis?process_id=${program_id}`);
      console.log("analytics data response:", res.batchAnalysisData);

      return res.batchAnalysisData;
    } catch (err) {
      console.log(err);
    }
  };

  const batchResumesDigitalAnalyticsDataGetter = async (process_ids) => {
    try {
      const res = await service.get(`/analytics/user/alResumeProcess?process_ids=[${process_ids.join(',')}]`);
      console.log("analytics data response:", res.batchAnalysisData);
      return res.data;
    } catch (err) {
      console.log(err);
      window.alert(err.response.data.message)
    }
  };
  // activity report endpoint
  const batchActivityReportDataGetter = async () => {
    try {
      const res = await service.get(`/analytics/user/batchProcesses?user_group_id=${program_id}`);
      console.log("analytics data response:", res.prepared_data);
      setActivityReportSkeletonLoader(false);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const batchResumesDigitalProcessGetter = async () => {
    try {
      const res = await service.get(`/user/processIdsForDashboard?ug_id=${program_id}`);
      console.log("analytics data response:", res.prepared_data);
      setResumesDigitalSkeletonLoader(false);
      return res.data;
    } catch (err) {
      console.log(err);
      window.alert(err.response.data.message)
    }
  };

  const handleChange = (e, index) => {
    console.log("name from form  is:", e.target.name);
    console.log("value from form  is:", e.target.value);
    if (e.target.name === "mentor_id") {
      console.log("batch id selected", e.target.value);
      setBatchId(e.target.value);
    }
  };

  // use effect 
  useEffect(() => {
    // const generatePdfContent = async () => {
    if (downloadButtonClicked) {
      <Fragment>
        <div ref={componentRef} >
          <div className="page-break" >
            <ActivityReport college_id={college_id} college_name={college_name} program_id={program_id} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
          </div>
          <div className="page-break">
            {
              batchProcessDataGetter.map((item, index) => <InterviewWiseAnalytics countElement={countElement} setCountElement={setCountElement} skeletonLoading={skeletonLoading} interviewWiseSkeletonLoader={interviewWiseSkeletonLoader} batchProcessDataGetter={batchProcessDataGetter} batchAnalyticsData={batchAnalyticsData} college_id={college_id} college_name={college_name} program_id={program_id} process_id={item.process_id} sku_name={item.sku_name} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />)
            }
          </div>
          <div className="page-break">
            <BatchProgressTracker college_id={college_id} college_name={college_name} program_id={program_id} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
          </div>

        </div>
      </Fragment>
    }

    // };

    // generatePdfContent();
  }, [downloadButtonClicked]);

  // activity report process Overview endpoint

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  const handleBatchIdChange = (e, program_id) => {
    console.log("program id", program_id);
    setBatchId(program_id);
    // window.relo
  };

  const handleStudentDataAnalyticsClick = () => {
    console.log("batchData", BatchesData);
    const batchData = BatchesData.filter((batch) => batch.program_id === program_id);
    navigate("/StudentProcess", { state: { college_id: college_id, college_name: college_name, program_id: program_id, program_name: program_name, batch: batchData[0], non_activated_students: non_activated_students, activated_students: activated_students } });
  };

  console.log("batch id selected", batchId);

  return (
    <div style={{ width: "100%", marginTop: "2rem" }}>
      <SideNavigationBar />
      <div className="BatchWiseANalytics">
        <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2">Batch Analytics Report</Typography>
          <button
            onClick={() => {
              handleStudentDataAnalyticsClick();
            }}
            className="batch-button"
            style={{ width: "auto", paddingLeft: ".5rem", paddingRight: ".5rem" }}
          >
            View Student Data Analytics
          </button>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <PersonPinIcon style={{ fontSize: "3rem" }} />
            <Select
              sx={{
                width: 250,
                height: 50,
              }}
              name="mentor_id"
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              value={batchId}
              label="Select Batch"
              onChange={(e) => handleChange(e)}
            >
              {BatchesData?.map((item, index) => (
                <MenuItem value={item?.program_id} key={item?.program_id}>
                  {item?.program_name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" float="right" alignItems="center">
            <Box display="flex" marginRight="1rem" border="1px solid #41B8D5" borderRadius="5px" paddingRight=".5rem" paddingLeft=".5rem">
              <GetAppIcon style={{ color: "#41B8D5" }} />
              <Typography style={{ color: "#41B8D5", margin: "auto" }}>Data CSV</Typography>
            </Box>
            <Box display="flex" border="1px solid #41B8D5" borderRadius="5px" paddingRight=".5rem" paddingLeft=".5rem" style={{ cursor: "pointer" }} onClick={handlePrintFunction}>
              <GetAppIcon style={{ color: "#41B8D5" }} />
              <Typography style={{ color: "#41B8D5", margin: "auto" }} >Batch Report</Typography>
            </Box>
          </Box>
        </Box>
        {downloadButtonClicked ? <>
          <Fragment>
            <div ref={componentRef}>
              <div className="page-break" style={{ marginBottom: "3.8rem" }}>
                <ActivityReport college_id={college_id} college_name={college_name} program_id={program_id} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
              </div>
              {
                batchProcessDataGetter.map((item, index) =>
                  <div className="page-break" style={{ marginBottom: "3.7rem" }}>
                    <InterviewWiseAnalytics downloadButtonClicked={downloadButtonClicked} countElement={countElement} setCountElement={setCountElement} skeletonLoading={skeletonLoading} interviewWiseSkeletonLoader={interviewWiseSkeletonLoader} batchProcessDataGetter={batchProcessDataGetter} batchAnalyticsData={batchAnalyticsData} college_id={college_id} college_name={college_name} program_id={program_id} process_id={item.process_id} sku_name={item.sku_name} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
                  </div>
                )}
              <div className="page-break" style={{ marginBottom: "2rem" }}>
                <BatchProgressTracker college_id={college_id} college_name={college_name} program_id={program_id} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
              </div>
            </div>
          </Fragment>
        </> : <>
          <Fragment>
            <div>
              <div>
                <ActivityReport college_id={college_id} college_name={college_name} program_id={program_id} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
              </div>
              <div>
                {countElement >= 0  && resumesDigitalVisible && resumesDigitalAnalyticsData &&
                  <ResumesDigitalAnalysis countElement={countElement} setCountElement={setCountElement} skeletonLoading={skeletonLoading} interviewWiseSkeletonLoader={resumesDigitalSkeletonLoader} batchProcessDataGetter={batchProcessDataGetter} resumesDigitalAnalyticsData={resumesDigitalAnalyticsData} college_id={college_id} college_name={college_name} program_id={program_id}  sku_name={"Resumes.Digital"} batch_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
                }
              </div>
              <div>
                {
                  batchProcessDataGetter.length > 0 &&
                  batchProcessDataGetter.map((item, index) => countElement >= index && <InterviewWiseAnalytics countElement={countElement} setCountElement={setCountElement} skeletonLoading={skeletonLoading} interviewWiseSkeletonLoader={interviewWiseSkeletonLoader} batchProcessDataGetter={batchProcessDataGetter} batchAnalyticsData={batchAnalyticsData} college_id={college_id} college_name={college_name} program_id={program_id} process_id={item.process_id} sku_name={item.sku_name} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />)
                }
              </div>
              <div>
                {countElement > batchProcessDataGetter.length &&
                  <BatchProgressTracker college_id={college_id} college_name={college_name} program_id={program_id} program_name={program_name} activated_students={activated_students} non_activated_students={non_activated_students} />
                }
              </div>
            </div>
          </Fragment>
        </>}
        <ScrollButton />
      </div>
    </div>
  );
};

export default BatchWiseAnalytics;
