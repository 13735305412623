import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "../../pages/SupportTicket/TableStyling.css"
import ModalMentorDetails from './ModalMentorDetails';
import { Typography } from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles';
import Service from '../../../services/httpService';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { saveAs } from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import CardActions from '@mui/material/CardActions';
import Loader from '../../reusables/Loader/Loader';
// import data from './sampleFeedback';
import ExcelJS from 'exceljs';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import exportFromJSON from 'export-from-json'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
const MentorActivityTable = (props) => {
  const service = new Service()
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [studentDetails, setStudentDetails] = useState([])
  const { batchid, batchName, college_data_name, college_data_id, process_id, sku_name, searched, mentorApprovalAwaitedData, mentorNotActivatedData } = props
  console.log("process_id in create table", process_id);
  console.log("sku_name in create table", sku_name);
  console.log("searched in create table", searched);
  console.log("mentorApprovalAwaitedData in create table", mentorApprovalAwaitedData);
  console.log("mentorNotActivatedData in create table", mentorNotActivatedData);
  const [openModal, setOpenModal] = useState(false)
  const tableTitles = ["Details", "Roles Mapped", "Activities Mapped", "Activity Readiness Complete", " "];
  const [studentsDetailsClick, setStudentsDetailsClick] = useState({})
  const callModalToOpen = () => {
    console.log("Modal open fucntion called")
    setOpenModal(true);
  }
  const [detailsToShow, setDetailsToShow] = useState(location.state?.detailsToShow)
  // let detailsToShow = location.state?.detailsToShow
  let activityTypeData = location.state?.activityTypeData
  let roleTypeData = location.state?.roleTypeData
  console.log("details to shoe", detailsToShow, activityTypeData, roleTypeData);
  // const [studentsDetails, setstudentsDetails] = useState([])

  const [copyList, setCopyList] = useState(detailsToShow)
  const [loading, setLoading] = useState(true);


  const [mentorStatus, setMentorStatus] = useState()
  // const [selectedOptions, setSelectedOptions] = useState(false)
  const [options, setOptions] = useState([
    { label: 'All', value: 'All', isSelected: true },
    { label: 'Deactivated', value: 'Deactivated', isSelected: false },
    { label: 'Restricted', value: 'Restricted', isSelected: false },
    { label: 'Suspended - 48 hours', value: 'Suspended - 48 hours', isSelected: false },
    { label: 'Suspended - 72 hours', value: 'Suspended - 72 hours', isSelected: false },
  ]);

  const [activeMentor, setActiveMentor] = useState()
  const [suspendedMentor, setSuspendedMentor] = useState()
  const [restrictedMentor, setRestrictedMentor] = useState()
  const [activityActiveMentor, setActivityActiveMentor] = useState()


  // opening modal 
  const handleStudentsDetailsClick = async (mentor_id) => {
    console.log("modal open true or false", openModal);
    try {
      let mentorStatusDetails = await handleStatusDetails(mentor_id)
      console.log(":mentor response", mentorStatusDetails,mentorStatusDetails.active_flag);
      setActivityActiveMentor(mentorStatusDetails.active_flag)
      setActiveMentor(mentorStatusDetails.active)
      setRestrictedMentor(mentorStatusDetails.restricted)
      setSuspendedMentor(mentorStatusDetails.suspended)
      const res = await service.get(`/mentor/sessionDetails?mentor_id=${mentor_id}`)
      console.log("mentor session", res.mentorList)
      const temp_data = {
        ...res.mentorList[0],
        mentor_id: mentor_id
      };
      setStudentsDetailsClick(temp_data)
      setMentorStatus(mentorStatusDetails)

      callModalToOpen()
    } catch (err) {
      console.log(err)
    }
  }
  console.log("modal open true or false", openModal, studentsDetailsClick);
  console.log("activityActiveMentor",activityActiveMentor,mentorStatus);
  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      setLoading(false)
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    handleEffect();
  }, [])


  const handleStatusDetails = async (mentor_id) => {
    try {
      const res = await service.get(`/mentor/status?user_id=${mentor_id}`)
      console.log("mentor status result", res.data)
      return res;
    } catch (err) {
      console.log(err)
    }
  }

  const requestSearch = (searched, isSelected) => {
    console.log("searched", searched);
    let filteredList;
    const updatedOptions = options.map(option => ({
      ...option,
      isSelected: option.value === searched ? !option.isSelected : false
    }));
    console.log("options selected", updatedOptions);
    setOptions(updatedOptions);
    if (searched === "Deactivated") {
      filteredList = detailsToShow.filter((item) =>
        item.active_flag === 0
      );
    } else if (searched === "Restricted") {
      filteredList = detailsToShow.filter((item) =>
        JSON.parse(item.mentor_status).total <= JSON.parse(item.mentor_status).missed + JSON.parse(item.mentor_status).cancelled
      );
    } else if (searched === "Suspended - 72 hours") {
      filteredList = detailsToShow.filter((item) =>
        item.suspended === "Blocked"
      );
    } else if (searched === "Suspended - 48 hours") {
      filteredList = detailsToShow.filter((item) =>
        item.suspended === "Partial"
      );
    }else if (searched === "All") {
      filteredList = detailsToShow
    }
    else {
      filteredList = detailsToShow.filter((item) =>
        item.first_name?.toLowerCase().includes(searched) ||
        item.last_name?.toLowerCase().includes(searched) ||
        item.ph_no?.includes(searched) || item.first_name?.includes(searched) ||
        item.last_name?.includes(searched) ||
        item.ph_no?.includes(searched) || item.first_name?.toUpperCase().includes(searched) ||
        item.last_name?.toUpperCase().includes(searched)
      );
    }
    console.log("filtered list", filteredList);
    if (filteredList.length === 0) {
      console.log("i'm here");
      toast.dismiss();
      setCopyList([]);
    } else {
      console.log("now, i'm here");
      toast.dismiss();
      setCopyList(filteredList);
    }
    console.log("filtered list", filteredList);
    if (filteredList.length === 0) {
      console.log("i'm here");
      toast.dismiss();
      setCopyList([]);
    } else {
      toast.dismiss();
      console.log("now, i'm here");
      setCopyList(filteredList);
    }
  }

  console.log("click students data", studentsDetailsClick);
  console.log("copyList", copyList);

  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is: ", studentDetails);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    const hardcodedRow = ['mentor_id', 'first_name', 'last_email', 'email_id', 'phone_no', 'role_type_ids', 'activity_type_ids', 'allowed_activity_ids', 'mentor_phone', 'mentor_status'];
    worksheet.addRow([...hardcodedRow]);
    (copyList.length > 0 ? copyList : studentDetails).forEach((row, index) => {
      const rowData = [...Object.values(row)];
      worksheet.addRow(rowData);
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const currentDate = new Date();
      const filename = `${college_data_name}_${batchName}_${sku_name}_${searched}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      saveAs(blob, filename);
    });
  };

  const goBack = () => {
    console.log("Back Button is Clicked Now")
    navigate(-1);
  }


  return (
    <>
      <div className="student-details-parent" style={{ marginTop: "3rem" }}>
        <ToastContainer position="top-center" limit={1} />
        <SideNavigationBar />
        <div className="topHeadings" style={{ display: "flex" }}>
          <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />

          <p style={{ color: Color.neutralMidGrey }}>Mentors</p>

        </div>
        {loading ? (<div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto', marginLeft: "40%", marginTop: "10%" }}><Loader show={loading} /></div>) : (
          <>
            <div style={{ display: "flex", marginTop: "2rem" }}>
              <div className='searchTab' style={{ alignItems: 'center', width: "100%", height: "30px", marginBottom: ".5rem" }}>
                <input style={{ border: "none", height: "30px", width: "100%" }}
                  className='searchingText searchTab'
                  type='text'
                  onChange={(e) => requestSearch(e.target.value)}
                  placeholder='Search for name, roll number, etc'
                />
              </div>
              <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
            </div>
            <div>
              {options.map((option, index) => (
                <button
                  key={index}
                  className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
                  style={{}}
                  onClick={() => {
                    requestSearch(option.value, !option.isSelected)
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableTitles.map((title, index) => (
                      <TableCell align='center'
                        key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                      >
                        {title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(copyList)?.map((details, index) => {
                    return (
                      <>
                        <TableRow
                          // onClick={() => handleStudentsDetailsClick(details.order_id)}
                          style={{ cursor: "pointer" }}>
                          <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                            <p style={{ color: "black" }}>{details.first_name} {details.last_name}</p>
                            {details.email_id}<br />
                            {details.ph_no}
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16, width: "200px" }}>
                            {details?.role_type
                              ? roleTypeData
                                .filter(item => {
                                  const roleTypeIds = JSON.parse(details.role_type).role_type_ids;
                                  return roleTypeIds.includes(item.id);
                                })
                                .map(item => item.name)
                                .join(", ")
                              : "-"}
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16 }}>
                            {details?.activity_type
                              ? activityTypeData
                                .filter(item => {
                                  const roleTypeIds = JSON.parse(details.activity_type).activity_type_ids;
                                  return roleTypeIds.includes(item.id);
                                })
                                .map(item => item.act_type_name)
                                .join(", ")
                              : "-"}
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16 }}>
                            {details?.allowed_activity_type
                              ? activityTypeData
                                .filter(item => {
                                  const roleTypeIds = JSON.parse(details.allowed_activity_type).allowed_activity_type;
                                  return roleTypeIds.includes(item.id);
                                })
                                .map(item => item.act_type_name)
                                .join(", ")
                              : "-"}
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16, width: "200px" }}>
                            <CardActions sx={{ justifyContent: 'flex-end', marginTop: "1rem" }}>
                              <button
                                className="submitBtn"
                                type="button"
                                style={{ height: "44px", bottom: 0, marginTop: "0" }}
                                onClick={() => handleStudentsDetailsClick(details.mentor_id)}
                              >
                                Edit
                              </button>
                            </CardActions>
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      {openModal ? <ModalMentorDetails roleTypeData={roleTypeData} activityTypeData={activityTypeData} studentsDetailsClick={studentsDetailsClick} setStudentsDetailsClick={setStudentsDetailsClick} show={openModal} setShow={setOpenModal} activeMentor={activeMentor} suspendedMentor={suspendedMentor} restrictedMentor={restrictedMentor} activityActiveMentor={activityActiveMentor}/> : <></>}
    </>
  );
};

export default MentorActivityTable;